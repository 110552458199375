import * as COMMON from "../../../store/constant";

const FINAL_DETAILS = "FINAL_DECIDION_DETAILS";
const DECISION_DETAILS = "DECISION_DETAILS";
const GET_DECISION = "Get_Decision";
const SET_DECISION_DETAILS = "Set_DECISION_DETAILS";
const GET_DECISION_CODE = "Get_Decision_Code";
const GET_RCU_DOCUMENT_TYPE = "Get_RCU_Document_Type";
const GET_RCU_AGENCY_MASTER = "Get_RCU_Agency_Master";
const GET_DEVIATION_REASON_MASTER = "Get_Deviation_Reason_Master";
const GET_DEVIATIONS = "Get_Deviations";
const CHANGE_VALUE = "ChangeValue";
const CHANGE_ADDRESS_BOX_INPUT_VALUE = "ChangeAddressBoxInputValue";
const GET_SPOC = "Get_Spoc";
const GET_CAPACITY_DETAILS = "Get_Capacity_Details";
const GET_SUMMARY_APPLICANT = "Get_Summary_Applicant";
const GET_BANKING_DETAILS_FOR_FINAL_DECISION = "GetBankingDetailsForFinalDecision";
const GET_CASE_HIGHLIGHTS = "GetCaseHighlights";
const GET_LIMIT_UTILIZATION_ANALYSIS = "LimitUtilizationAnalysis";
const GET_FI_AGENCY_MASTER = "giAgencyMaster";
const GET_DOCUMENT_LIST="Get_Document_List";

export {
  COMMON,
  GET_DOCUMENT_LIST,
  FINAL_DETAILS,
  DECISION_DETAILS,
  CHANGE_VALUE,
  SET_DECISION_DETAILS,
  GET_DECISION_CODE,
  GET_RCU_DOCUMENT_TYPE,
  GET_RCU_AGENCY_MASTER,
  GET_DEVIATION_REASON_MASTER,
  CHANGE_ADDRESS_BOX_INPUT_VALUE,
  GET_FI_AGENCY_MASTER,
  GET_DEVIATIONS,
  GET_DECISION,
  GET_SPOC,
  GET_CAPACITY_DETAILS,
  GET_SUMMARY_APPLICANT,
  GET_BANKING_DETAILS_FOR_FINAL_DECISION,
  GET_CASE_HIGHLIGHTS,
  GET_LIMIT_UTILIZATION_ANALYSIS,
};
