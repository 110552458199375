import {
  API_FETCH_DOCUMENTS_NAME,
  API_FETCH_ALL_DOCUMENTS,
  API_DELETE_DOCUMENT
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as DocumentsConstants from "./Document.actions.constant";

const getdocument = applicationNumber => {
  return dispatch => {
    return GET(API_FETCH_DOCUMENTS_NAME(applicationNumber))
      .then(({ data }) => {
      
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const fetchDocuments = applicationNumber => {
  return dispatch => {
    return GET(API_FETCH_ALL_DOCUMENTS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: DocumentsConstants.FETCH_DOCUMENT_LIST,
            data: data.data.documents
          });
        } else {
          dispatch({ type: DocumentsConstants.FETCH_DOCUMENT_LIST, data: [] });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const deleteDocument = docId => {
  return dispatch => {
    return GET(API_DELETE_DOCUMENT(docId)).then(({ data }) => {
      return data
    });
  };
};

export { getdocument, fetchDocuments, deleteDocument };
