import * as ACTION_TYPE from "./dashboard.action.constant";
import { _updateFormValues } from "../../../utils/utitlity";
import * as DashboardSelector from "../../Sales/Dashboard/dashboard.selectors";

const initialState = {
  allApplications: [],
  applicationError: null,
  applicationLoader: false,
  searchByFilters: {
    byTabs: true,
    byDate: false
  },
  form: {
    values: null,
    error: null
  },
  dataToSend: {
    data: {
      dashboard: "UW",
      pageNumber: 1,
      param: {
        key: "Mamta",
        startDate: "2019-10-01",
        endDate: "2019-11-11"
      },
      statusFilter: "Submit",
      type: "Primary Applicant"
    }
  }
};

export const dashBoardReducer = (state = initialState, action) => {
  const { type, name, userList, dashboardData, data } = action;
  switch (type) {
    case ACTION_TYPE.GET_ALL_APPLICATIONS_START:
      return { ...state, applicationLoader: true, applicationError: null };

    case ACTION_TYPE.GET_ALL_APPLICATIONS:
      return { ...state, allApplications: action.data };

    case ACTION_TYPE.GET_ALL_APPLICATIONS_FINISHED:
      return { ...state, applicationLoader: false };

    case ACTION_TYPE.GET_ALL_APPLICATIONS_ERROR:
      return { ...state, applicationError: action.error };
    case "DASHBOARD_DATA":
      return {
        ...state,
        dashboardData: dashboardData
      };
    case ACTION_TYPE.CHANGE_VALUE:
      return {
        ...state,
        form: _updateFormValues(state, action.name, action.data)
      };
    case ACTION_TYPE.RESET_DATA:
      return {
        ...state,
        dashboardData: data
      };
    case ACTION_TYPE.SET_SEARCH_BY_STATUS:
      return DashboardSelector._changeSearchByFiltersStataus(state, name);
    case ACTION_TYPE.USER_LIST:
      return { ...state, userList: userList };
    default:
      return state;
  }
};
