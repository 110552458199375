import { get, set } from "lodash";

const _getValue = (state, name) => {
  return get(state, `businessDetailsData.${name}`);
};

const _updateFormValues = (state, value, name) => {
  const _preValue = _getValue(state, name);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(set(_changedState, `businessDetailsData.${name}`, value), "businessDetailsData");
    return { ..._changedState, businessDetailsData: { ..._changedValue } };
  }
  return state;
};
const _addAddress = (state, index) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _changedValue = get(
      set(_changedState, `businessDetailsData.gstDetails[${index}].address`, [
        ..._changedState.businessDetailsData.gstDetails[index].address,
        { line1: "" },
      ]),
      "businessDetailsData"
    );
  return { ..._changedState, businessDetailsData: { ..._changedValue } };
};
const _updateAddress = (state, gstIndex, addressIndex, value, name) => {
  const _preValue = _getValue(state, name);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(
        set(_changedState, `businessDetailsData.gstDetails[${gstIndex}].address[${addressIndex}].${name}`, value),
        "businessDetailsData"
      );
    return { ..._changedState, businessDetailsData: { ..._changedValue } };
  }
  return state;
};

const _deleteAddress = (state, gstIndex, addressIndex) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _changedValue = get(
      set(
        _changedState,
        `businessDetailsData.gstDetails[${gstIndex}].address`,
        _changedState.businessDetailsData.gstDetails[gstIndex].address.filter((_, index) => addressIndex !== index)
      ),
      "businessDetailsData"
    );
  return { ..._changedState, businessDetailsData: { ..._changedValue } };
};
export { _updateFormValues, _addAddress, _updateAddress, _deleteAddress };
