import React, { Component } from "react";
import { CheckBox, InputLabelAnimation, DatePicker } from "../../../components";
import "react-datepicker/dist/react-datepicker.css";
import { getDMY, setMaxDate } from "../../../utils/utitlity";
import { connect } from "react-redux";
import Select from "react-select";
import { GET } from "../../../utils/webAPI.service";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import { changeInputValue } from "./applicants.actions";
import { updateErrorMessage } from "../../../store/actions";
import { getProfile } from "./applicants.actions";

class ApplicantForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDate: setMaxDate("dob"),
      currentPinCodes: [],
      currentPinCodeLoader: false,
      permanentPinCodes: [],
      permanentPinCodeLoader: false,
      durations: [
        "Select Duration",
        "0 - 6 Months",
        "6 - 12 Months",
        "1 - 2 yrs",
        "2 - 4 yrs",
        "4+ yrs",
      ],
    };
    this.searchCurrentPinCode = null;
    this.searchPermanentPinCode = null;
  }

  setCurrentPinCode = (e) => {
    let { actions, index, profileDetails } = this.props,
      { changeInputValue } = actions,
      count = index - 1,
      address = get(profileDetails, `[${count}].address`) || [],
      currentAddressIndex =
        address?.findIndex((el) => el.addressType === "CURRENT") > 0
          ? address?.findIndex((el) => el.addressType === "CURRENT")
          : 0;
    changeInputValue(e.value, `address[${currentAddressIndex}].pinCode`, count);
    changeInputValue(
      e.district,
      `address[${currentAddressIndex}].district`,
      count
    );
    changeInputValue(e.city, `address[${currentAddressIndex}].city`, count);
    changeInputValue(e.state, `address[${currentAddressIndex}].state`, count);
  };

  setCurrentInputPinCode = (e) => {
    clearTimeout(this.searchCurrentPinCode);
    this.searchCurrentPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            currentPinCodeLoader: true,
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  currentPinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    currentPinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      district: list[i].district,
                      state: list[i].state,
                    });
                  }
                }
                this.setState({
                  currentPinCodes,
                  currentPinCodeLoader: false,
                });
              } else {
                this.setState({ currentPinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };

  setPermanentPinCode = (e) => {
    let { actions, index, profileDetails } = this.props,
      { changeInputValue } = actions,
      count = index - 1,
      address = get(profileDetails, `[${count}].address`) || [],
      permanentAddressIndex =
        address?.findIndex((el) => el.addressType === "PERMANENT") > 0
          ? address?.findIndex((el) => el.addressType === "PERMANENT")
          : 1;
    changeInputValue(
      e.value,
      `address[${permanentAddressIndex}].pinCode`,
      count
    );
    changeInputValue(
      e.district,
      `address[${permanentAddressIndex}].district`,
      count
    );
    changeInputValue(e.city, `address[${permanentAddressIndex}].city`, count);
    changeInputValue(e.state, `address[${permanentAddressIndex}].state`, count);
  };

  setPermanentInputPinCode = (e) => {
    clearTimeout(this.searchPermanentPinCode);
    this.searchPermanentPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            permanentPinCodeLoader: true,
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  permanentPinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    permanentPinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      district: list[i].district,
                      state: list[i].state,
                    });
                  }
                }
                this.setState({
                  permanentPinCodes,
                  permanentPinCodeLoader: false,
                });
              } else {
                this.setState({ permanentPinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };

  setSameAddressStatus = (e) => {
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      count = index - 1,
      name = "sameAddress",
      value = e.target.checked;
    changeInputValue(value, name, count);
    updateErrorMessage("");
  };

  inputHandler = (e) => {
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      count = index - 1,
      value = e.target.value,
      name = e.target.name;
    changeInputValue(value, name, count);
    updateErrorMessage("");
  };

  inputNumberHandler = (e) => {
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      value = e.target.value,
      name = e.target.name,
      count = index - 1,
      reg = /^[0-9]*$/;
    if (reg.test(value)) {
      changeInputValue(value, name, count);
    }
    updateErrorMessage("");
  };

  dateHandler = (e) => {
    if (e !== null) {
      let { actions, index } = this.props,
        { changeInputValue } = actions,
        count = index - 1,
        name = "dateOfBirth",
        value = getDMY(new Date(e.getTime()));
      changeInputValue(value, name, count);
    }
  };

  selectHandler = (i, name) => {
    let { actions, index, profileDetails } = this.props,
      count = index - 1,
      { changeInputValue, updateErrorMessage } = actions,
      value = "",
      address = get(profileDetails, `[${count}].address`) || [],
      currentAddressIndex =
        address?.findIndex((el) => el.addressType === "CURRENT") > 0
          ? address?.findIndex((el) => el.addressType === "CURRENT")
          : 0,
      permanentAddressIndex = currentAddressIndex === 1 ? 0 : 1;
    updateErrorMessage("");
    switch (name) {
      case "gender":
        value = i.target.value;
        break;
      case "qualification":
        value = i.target.value;
        break;
      case "religion":
        value = i.target.value;
        break;
      case "category":
        value = i.target.value;
        break;
      case `address[${currentAddressIndex}].ownership`:
      case `address[${permanentAddressIndex}].ownership`:
        value = i.target.value;
        break;
      case `address[${currentAddressIndex}].durationOfStay`:
      case `address[${permanentAddressIndex}].durationOfStay`:
        value = i.target.value;
        break;
      case "maritalStatus":
        value = i.target.value;
        break;
      default:
        value = "";
    }
    changeInputValue(value, name, count);
  };
  componentDidMount() {
    let { index, actions, match } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      { changeInputValue, getProfile } = actions;
    getProfile(applicationNumber);
    let { profileDetails } = this.props,
      count = index - 1,
      address = get(profileDetails, `[${count}].address`) || [],
      currentAddressIndex =
        address?.findIndex((el) => el.addressType === "CURRENT") > 0
          ? address?.findIndex((el) => el.addressType === "CURRENT")
          : 0,
      permanentAddressIndex = currentAddressIndex === 1 ? 0 : 1,
      currentPinCode = get(
        profileDetails,
        `[${count}].address[${currentAddressIndex}].pinCode`
      ),
      permanentPinCode = get(
        profileDetails,
        `[${count}].address[${permanentAddressIndex}].pinCode`
      ),
      dateOfBirth = get(profileDetails, `[${index - 1}].dateOfBirth`);
    currentPinCode && this.setCurrentInputPinCode(currentPinCode);
    permanentPinCode && this.setPermanentInputPinCode(permanentPinCode);
    !dateOfBirth && changeInputValue(null, "dateOfBirth", count);
  }

  render() {
    let {
        currentPinCodeLoader,
        currentPinCodes,
        permanentPinCodeLoader,
        permanentPinCodes,
        maxDate,
        durations,
      } = this.state,
      {
        name,
        index,
        genderList,
        qualificationList,
        religionList,
        categoryList,
        residenceStatus,
        maritalStatusList,
        profileDetails,
      } = this.props,
      count = index - 1,
      sameAddress = get(profileDetails, `[${count}].sameAddress`, false),
      dateOfBirth = get(profileDetails, `[${count}].dateOfBirth`) || "",
      address = get(profileDetails, `[${count}].address`) || [],
      currentAddressIndex =
        address?.findIndex((el) => el.addressType === "CURRENT") > 0
          ? address?.findIndex((el) => el.addressType === "CURRENT")
          : 0,
      permanentAddressIndex = currentAddressIndex === 1 ? 0 : 1,
      currentPinCode = get(
        profileDetails,
        `[${count}].address[${currentAddressIndex}].pinCode`
      ),
      permanentPinCode = get(
        profileDetails,
        `[${count}].address[${permanentAddressIndex}].pinCode`
      );
    return (
      <div className={"form-box"}>
        <h4>
          {name === "app" ? "Applicant" : "Co-applicant"}{" "}
          {name === "app" ? index : count}{" "}
        </h4>
        <form>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <InputLabelAnimation
                labelText={`Name of the ${
                  name === "app" ? "Applicant" : "Co-applicant"
                }`}
                name={"name"}
                onChange={this.inputHandler}
                tabIndex={count * 23 + 1}
                required={true}
                disabled={true}
                inputValue={get(profileDetails, `[${count}].name`) || ""}
              />
            </div>
            <div className={"col-md-6"}>
              <InputLabelAnimation
                labelText={"Father's Name"}
                name={"fatherName"}
                required={true}
                onChange={this.inputHandler}
                tabIndex={count * 23 + 2}
                inputValue={get(profileDetails, `[${count}].fatherName`) || ""}
              />
            </div>
            <div className={"col-md-4"}>
              <InputLabelAnimation
                labelText={"Mother's Name"}
                name={"motherName"}
                // required={true}
                onChange={this.inputHandler}
                tabIndex={count * 23 + 3}
                inputValue={get(profileDetails, `[${count}].motherName`) || ""}
              />
            </div>
            <div className={"col-md-4 "}>
              <InputLabelAnimation
                labelText={"Mobile Number"}
                name={"mobile"}
                required={true}
                onChange={this.inputNumberHandler}
                tabIndex={count * 23 + 4}
                inputValue={get(profileDetails, `[${count}].mobile`) || ""}
                maxLength={10}
                minLength={10}
              />
            </div>
            <div className={"col-md-4"}>
              <DatePicker
                required={true}
                tabIndex={count * 23 + 5}
                selected={
                  dateOfBirth
                    ? new Date(dateOfBirth.split("-").reverse())
                    : null
                }
                onChange={this.dateHandler}
                maxDate={new Date(maxDate)}
                defaultValue={null}
                name={"dateOfBirth"}
                labelText={"Date of Birth (DD/MM/YYYY)"}
              />
            </div>
            <div className={"col-md-4"}>
              <select
                className={"select-box1"}
                id={"gender-select"}
                value={
                  get(profileDetails, `[${count}].gender`) || "Select Gender"
                }
                onChange={(i) => this.selectHandler(i, "gender")}
              >
                {genderList.map((x, y) => (
                  <option key={y + 1}>{x}</option>
                ))}
              </select>
            </div>
            <div className={"col-md-4"}>
              <select
                className={"select-box1"}
                id={"marital-status-select"}
                value={
                  get(profileDetails, `[${count}].maritalStatus`) ||
                  "Select Marital Status"
                }
                onChange={(i) => this.selectHandler(i, "maritalStatus")}
              >
                {maritalStatusList.map((x, y) => (
                  <option key={y + 1}>{x}</option>
                ))}
              </select>
            </div>
            <div className={"col-md-4"}>
              <InputLabelAnimation
                labelText={"Spouse Name"}
                name={"spouseName"}
                // required={(get(profileDetails, `[${count}].maritalStatus`) || "").toLowerCase() !== "single"}
                onChange={this.inputHandler}
                tabIndex={count * 23 + 8}
                inputValue={get(profileDetails, `[${count}].spouseName`) || ""}
                disabled={
                  (
                    get(profileDetails, `[${count}].maritalStatus`) || ""
                  ).toLowerCase() === "single"
                }
              />
            </div>
            <div className={"col-md-4"}>
              <select
                className={"select-box1"}
                id={"category-select"}
                value={
                  get(profileDetails, `[${count}].category`) ||
                  "Select Category"
                }
                onChange={(i) => this.selectHandler(i, "category")}
              >
                {categoryList.map((x, y) => (
                  <option key={y + 1}>{x}</option>
                ))}
              </select>
            </div>
            <div className={"col-md-4"}>
              <select
                className={"select-box1"}
                id={"religion-select"}
                value={
                  get(profileDetails, `[${count}].religion`) ||
                  "Select Religion"
                }
                onChange={(i) => this.selectHandler(i, "religion")}
              >
                {religionList.map((x, y) => (
                  <option key={y + 1}>{x}</option>
                ))}
              </select>
            </div>
            <div className={"col-md-4"}>
              <select
                className={"select-box1"}
                id={"qualification-select"}
                value={
                  get(profileDetails, `[${count}].qualification`) ||
                  "Select Qualification"
                }
                onChange={(i) => this.selectHandler(i, "qualification")}
              >
                {qualificationList.map((x, y) => (
                  <option key={y + 1}>{x}</option>
                ))}
              </select>
            </div>
            <div className={"col-md-4"}>
              <InputLabelAnimation
                labelText={"PAN"}
                name={"pan"}
                onChange={this.inputHandler}
                inputClass={"input-field text-uppercase"}
                maxLength={10}
                tabIndex={count * 23 + 12}
                required={true}
                inputValue={get(profileDetails, `[${count}].pan`) || ""}
              />
            </div>
            <div className={"col-md-8"}>
              <InputLabelAnimation
                labelText={"Email Address"}
                name={"email"}
                onChange={this.inputHandler}
                inputValue={get(profileDetails, `[${count}].email`) || ""}
                tabIndex={count * 23 + 13}
                required={true}
              />
            </div>
          </div>
          <div className={"address-box"}>
            <label className={"input-label"}>Current Address</label>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <InputLabelAnimation
                  labelText={"Enter Floor/Building Number"}
                  tabIndex={count * 23 + 14}
                  required={true}
                  name={`address[${currentAddressIndex}].line1`}
                  onChange={this.inputHandler}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].line1`
                    ) || ""
                  }
                />
              </div>
              <div className={"col-md-6"}>
                <InputLabelAnimation
                  labelText={"Enter Street/Landmark"}
                  name={`address[${currentAddressIndex}].line2`}
                  onChange={this.inputHandler}
                  tabIndex={count * 23 + 15}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].line2`
                    ) || ""
                  }
                />
              </div>
              <div className={"col-md-6"}>
                <select
                  className={"select-box1"}
                  id={"current-ownership-select"}
                  value={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].ownership`
                    ) || "Select Residence Status"
                  }
                  onChange={(i) =>
                    this.selectHandler(
                      i,
                      `address[${currentAddressIndex}].ownership`
                    )
                  }
                >
                  {residenceStatus.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
              <div className={"col-md-6"}>
                <select
                  className={"select-box1"}
                  id={"current-duration-select"}
                  value={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].durationOfStay`
                    ) || "Select Duration"
                  }
                  onChange={(i) =>
                    this.selectHandler(
                      i,
                      `address[${currentAddressIndex}].durationOfStay`
                    )
                  }
                >
                  {durations.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
              <div className={"col-md-3"}>
                <Select
                  tabIndex={count * 23 + 18}
                  value={currentPinCodes.find(
                    (option) => option.value === currentPinCode
                  )}
                  title={"Current Address Pin Code"}
                  placeholder={"Pin Code"}
                  isSearchable={true}
                  name="current_pin_code"
                  options={currentPinCodes}
                  className="pin-code-select-search"
                  classNamePrefix="select"
                  onInputChange={this.setCurrentInputPinCode}
                  onChange={this.setCurrentPinCode}
                  isLoading={currentPinCodeLoader}
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"District"}
                  name={`address[${currentAddressIndex}].district`}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].district`
                    ) || ""
                  }
                  disabled={true}
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"City"}
                  name={`address[${currentAddressIndex}].city`}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].city`
                    ) || ""
                  }
                  disabled={true}
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"State"}
                  name={`address[${currentAddressIndex}].state`}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${currentAddressIndex}].state`
                    ) || ""
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className={"row"}>
              {!sameAddress ? (
                <div className={"col-md-6"}>
                  <label className={"input-label"}>Permanent Address</label>
                </div>
              ) : null}
              <div className={!sameAddress ? "col-md-6" : "col-xs-12"}>
                <CheckBox
                  labelColor={"#1d1d1d"}
                  checkboxColor={"#4687f4"}
                  name={"same_address"}
                  htmlFor={
                    name === "app"
                      ? `appSameAddress${index}`
                      : `coSameAddress${index}`
                  }
                  label={"Permanent Address Same as Current Address"}
                  checked={sameAddress}
                  onChange={this.setSameAddressStatus}
                />
              </div>
            </div>
          </div>
          {!sameAddress ? (
            <div className={"row address-box"}>
              <div className={"col-md-6"}>
                <InputLabelAnimation
                  labelText={"Enter Floor/Building Number"}
                  tabIndex={count * 23 + 19}
                  required={true}
                  name={`address[${permanentAddressIndex}].line1`}
                  onChange={this.inputHandler}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].line1`
                    ) || ""
                  }
                />
              </div>
              <div className={"col-md-6"}>
                <InputLabelAnimation
                  labelText={"Enter Street/Landmark"}
                  name={`address[${permanentAddressIndex}].line2`}
                  onChange={this.inputHandler}
                  tabIndex={count * 23 + 20}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].line2`
                    ) || ""
                  }
                />
              </div>
              <div className={"col-md-6"}>
                <select
                  className={"select-box1"}
                  id={"permanent-ownership-select"}
                  value={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].ownership`
                    ) || "Select Residence Status"
                  }
                  onChange={(i) =>
                    this.selectHandler(
                      i,
                      `address[${permanentAddressIndex}].ownership`
                    )
                  }
                >
                  {residenceStatus.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
              <div className={"col-md-6"}>
                <select
                  className={"select-box1"}
                  id={"permanent-duration-select"}
                  value={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].durationOfStay`
                    ) || "Select Duration"
                  }
                  onChange={(i) =>
                    this.selectHandler(
                      i,
                      `address[${permanentAddressIndex}].durationOfStay`
                    )
                  }
                >
                  {durations.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
              <div className={"col-md-3"}>
                <Select
                  tabIndex={count * 23 + 23}
                  value={permanentPinCodes.find(
                    (option) => option.value === permanentPinCode
                  )}
                  title={"Current Address Pin Code"}
                  placeholder={"Pin Code"}
                  isSearchable={true}
                  name="permanent_pin_code"
                  options={permanentPinCodes}
                  className="pin-code-select-search"
                  classNamePrefix="select"
                  onInputChange={this.setPermanentInputPinCode}
                  onChange={this.setPermanentPinCode}
                  isLoading={permanentPinCodeLoader}
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"District"}
                  name={`address[${permanentAddressIndex}].district`}
                  required={true}
                  disabled={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].district`
                    ) || ""
                  }
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"City"}
                  name={`address[${permanentAddressIndex}].city`}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].city`
                    ) || ""
                  }
                  disabled={true}
                />
              </div>
              <div className={"col-md-3"}>
                <InputLabelAnimation
                  labelText={"State"}
                  name={`address[${permanentAddressIndex}].state`}
                  required={true}
                  inputValue={
                    get(
                      profileDetails,
                      `[${count}].address[${permanentAddressIndex}].state`
                    ) || ""
                  }
                  disabled={true}
                />
              </div>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ listData, applicantsReducers }) => {
  return {
    maritalStatusList: listData.maritalStatusList,
    genderList: listData.genderList,
    residenceStatus: listData.residenceStatusList,
    categoryList: listData.categoryList,
    religionList: listData.religionList,
    qualificationList: listData.qualificationList,
    profileDetails: applicantsReducers.profileDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeInputValue,
      updateErrorMessage,
      getProfile,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantForm);
