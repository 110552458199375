import React, {Component} from 'react';
import ContentLoader from 'react-content-loader';

class FormsPreviewContentLoader extends Component{
    render() {
        return (
            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={200} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={200} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
                <div className={'col-md-12'}>
                    <div style={{marginBottom: '25px'}}>
                        <ContentLoader height={400} type={'facebook'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default FormsPreviewContentLoader;
