import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import ToggleButton from "../../../components/ToogleButton/ToggleButton";
import Select from "react-select";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getList } from "../../../store/listData.actions";
import { loaderModal } from "../../../store/actions";
import {
  API_GET_MEMBERS,
  API_GET_CREDIT_CYCLE,
  API_GET_PURCHASE_DISCOUNT,
  API_GET_INTEREST_PAID,
  API_GET_OWNERSHIP_YEARS,
  API_GET_GROWTH_REQUIREMENTS,
  API_POST_ADD_BUSINESS,
  API_GET_BUSINESS_SIZE,
} from "../../../utils/APIUrls";
import { GET, POST } from "../../../utils/webAPI.service";
import { Link } from "react-router-dom";
import { Header } from "../../../components";
import { numericInput } from "../../../utils/utitlity";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
class BusinessSizeAnalysis extends Component {
  state = {
    requirementToGrowBusiness: [],
    employeeDetails: {},
    ownershipYearsList: [],
    anyOtherIncomeSource: false,
    isSoleEarner: false,
    additionalDetails: {},
    fundAndObligationDetails: {},
    isBusinessSelfStarted: false,
    fundsBorrowedFromFamilyFriends: false,
    assetsDetails: [{}],
  };
  inputHandler = (e) => {
    let { name, value } = e.target;
    numericInput(value, (value) => this.setState({ [name]: value }));
  };
  employeeInputHandler = (e) => {
    let { name, value } = e.target;
    numericInput(value, () =>
      this.setState({
        employeeDetails: { ...this.state.employeeDetails, [name]: value },
      })
    );
  };
  otherDetailsInputHandler = async (e) => {
    let { name, value } = e.target;
    await this.setState({
      additionalDetails: { ...this.state.additionalDetails, [name]: value },
    });
  };
  otherDetailsButtonHandler = async (name, value) => {
    await this.setState({
      additionalDetails: { ...this.state.additionalDetails, [name]: value },
    });
  };
  obligationsInputHandler = (e) => {
    let { name, value } = e.target;
    numericInput(value, (value) =>
      this.setState({
        fundAndObligationDetails: {
          ...this.state.fundAndObligationDetails,
          [name]: value,
        },
      })
    );
  };
  assetsInputHandler = (index, e) => {
    let { name, value } = e.target;
    let { assetsDetails } = this.state,
      assetsDetailsCopy = [...assetsDetails];
    assetsDetailsCopy[index] = { ...assetsDetailsCopy[index], [name]: value };
    assetsDetails = assetsDetailsCopy;
    if (name === "monthlyEmi") {
      let reg = /^[0-9]*$/;
      if (reg.test(value)) {
        this.setState({ assetsDetails });
      }
    } else {
      this.setState({ assetsDetails });
    }
  };

  deleteAssetsRow = (rowIndex) => {
    let { assetsDetails } = this.state,
      assetsDetailsCopy = [...assetsDetails];
    assetsDetailsCopy = assetsDetailsCopy.filter((_, index) => rowIndex !== index);
    this.setState({ assetsDetails: assetsDetailsCopy });
  };

  toggleHandler = (name) => {
    this.setState({ [name]: !this.state[name] });
  };
  saveApplication = (actionType) => {
    let { actions, history, match } = this.props;
    actions.loaderModal(true);
    let {
        averageSalesPerMonth,
        averageMargin,
        monthlyIncome,
        otherSourceMonthlyIncome,
        employeeDetails,
        anyOtherIncomeSource,
        isSoleEarner,
        otherEarningMembers,
        additionalDetails,
        fundAndObligationDetails,
        isBusinessSelfStarted,
        fundsBorrowedFromFamilyFriends,
        assetsDetails,
      } = this.state,
      assetsDetailsArray = assetsDetails.filter(value => Object.keys(value).length !== 0),
      dataToSend = {
        data: {
          additionalDetails,
          anyOtherIncomeSource,
          assetsDetails :assetsDetailsArray,
          averageMargin,
          averageSalesPerMonth,
          customerId: match.params.custId,
          employeeDetails,
          fundAndObligationDetails: {
            fundsBorrowedFromFamilyFriends,
            isBusinessSelfStarted,
            ...fundAndObligationDetails,
          },
          isSoleEarner,
          monthlyIncome,
          otherEarningMembers,
          otherSourceMonthlyIncome,
        },
      };
     
    POST(API_POST_ADD_BUSINESS, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/cluster/${match.params.custId}/photo/${match.params.clusterType}`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else if (data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  render() {
    let {
        averageSalesPerMonth,
        averageMargin,
        monthlyIncome,
        otherSourceMonthlyIncome,
        employeeDetails,
        ownershipYearsList,
        anyOtherIncomeSource,
        isSoleEarner,
        additionalDetails,
        fundAndObligationDetails,
        assetsDetails,
      } = this.state,
      { relationList, growthRequirementsList, match, purchaseDiscountList, interestPaidList } = this.props,
      { clusterType } = match.params;
    return (
      <>
        <Header {...this.props} />
        <div className="business-size">
          <div className="container">
            <Link
              to={`/cluster/${this.props.match.params.custId}/business-account-details/${match.params.clusterType}`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading"> Business Size Analysis </h1>
            <form>
              <div className="container  p-top--sub">
                <h2 className="sub-heading">Average sales per month </h2>
                <div className={"row "}>
                  <div className={"col-sm-6 m-extra col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={" Amount (in ₹)"}
                      required={true}
                      name="averageSalesPerMonth"
                      inputValue={averageSalesPerMonth}
                      onChange={this.inputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-extra col-xs-12"}>
                    <InputLabelAnimation
                      labelText={" Percentage margin (in %)"}
                      required={true}
                      name="averageMargin"
                      inputValue={averageMargin}
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="container p-top--sub">
                <h2 className="sub-heading">Monthly family income in this business</h2>
                <div className={"row"}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Amount (in ₹)"}
                      required={true}
                      name="monthlyIncome"
                      inputValue={monthlyIncome}
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="container p-top--sub">
                <h2 className="sub-heading m-bottom">Other source of monthly income</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 col-xs-6 p-left"}>
                    <h5 className="text-decor">Do you have any other source of monthly income?</h5>
                  </div>
                  <div className={"col-sm-5 col-xs-6"}>
                    <div className="btn-decor">
                      <ToggleButton toggleHandler={this.toggleHandler} name="anyOtherIncomeSource" />
                    </div>
                  </div>
                </div>
                {anyOtherIncomeSource ? (
                  <div className={"row "}>
                    <div className={"col-sm-6 m-top--main col-xs-12"}>
                      <InputLabelAnimation
                        labelText={"Monthly income (in ₹)"}
                        required={true}
                        type="number"
                        name="otherSourceMonthlyIncome"
                        inputValue={otherSourceMonthlyIncome}
                        onChange={this.inputHandler}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="container  p-top--sub">
                <h2 className="sub-heading m-bottom">Sole earner</h2>
                <div className={"row"}>
                  <div className={"col-sm-6 text-decor col-xs-6"}>Are you the sole earner in family?</div>
                  <div className={"col-sm-5 col-xs-6"}>
                    <div className="btn-decor">
                      <ToggleButton toggleHandler={this.toggleHandler} name="isSoleEarner" />
                    </div>
                  </div>
                </div>
                {!isSoleEarner ? (
                  <div className={"row"}>
                    <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        options={relationList.map((el) => ({
                          label: el,
                          value: el,
                        }))}
                        onChange={(e) =>
                          this.setState({
                            otherEarningMembers: e.map((el) => el.value),
                          })
                        }
                        name="otherEarningMembers"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="container  p-top--sub">
                <h2 className="sub-heading">Employee details</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--sub col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Number of fulltime workers"}
                      required={true}
                      inputValue={employeeDetails.noOfFullTimeWorker}
                      name={"noOfFullTimeWorker"}
                      onChange={this.employeeInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Number of seasonal workers"}
                      required={true}
                      inputValue={employeeDetails.noOfSeasonalWorker}
                      name={"noOfSeasonalWorker"}
                      onChange={this.employeeInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Number of working days in a month"}
                      required={true}
                      inputValue={employeeDetails.workingDaysInMonth}
                      name={"workingDaysInMonth"}
                      onChange={this.employeeInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Average employee wage per month (in ₹)"}
                      required={true}
                      inputValue={employeeDetails.averageWagePerMonth}
                      name={"averageWagePerMonth"}
                      onChange={this.employeeInputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="container p-top--sub">
                <h2 className="sub-heading">Other details</h2>
                <h6 className="text-decor">Do you supply for:</h6>
                <div
                  onClick={() => this.otherDetailsButtonHandler("marketType", "Export Market")}
                  className={additionalDetails.marketType === "Export Market" ? "btn-active" : "btn-normal"}
                >
                  Export Market
                </div>
                <div
                  onClick={() => this.otherDetailsButtonHandler("marketType", "Domestic Market")}
                  className={additionalDetails.marketType === "Domestic Market" ? "btn-active" : "btn-normal"}
                >
                  Domestic Market
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--sub col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={" Number of units produced/sold in a day"}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.unitsSoldPerDay}
                      name="unitsSoldPerDay"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12"}>
                    <InputLabelAnimation
                      labelText={" Average selling price per unit (in ₹) "}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.averageSellingPricePerUnit}
                      name="averageSellingPricePerUnit"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--sub p-left col-xs-12"}>
                    <InputLabelAnimation
                      labelText={"  Purchase credit cycle (in days) "}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.purchaseCreditCycle}
                      name="purchaseCreditCycle"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12"}>
                    <InputLabelAnimation
                      labelText={"  Receivable credit cycle (in days) "}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.receivableCreditCycle}
                      name="receivableCreditCycle"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--sub p-left col-xs-12"}>
                    <select
                      className={"select-box"}
                      value={additionalDetails.discountOnParcha}
                      name="discountOnParcha"
                      onChange={this.otherDetailsInputHandler}
                    >
                      {purchaseDiscountList.map((el, index) => {
                        return <option key={`purchase-discount-list-${index}`}>{el}</option>;
                      })}
                    </select>
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12"}>
                    <select
                      className={"select-box"}
                      value={additionalDetails.percentageInterestPaid}
                      name="percentageInterestPaid"
                      onChange={this.otherDetailsInputHandler}
                    >
                      {interestPaidList.map((el, index) => {
                        return <option key={`interest-paid-list-${index}`}> {el}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--sub p-left col-xs-12"}>
                    <InputLabelAnimation
                      labelText={"Investment in Plants & machinery (in ₹) "}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.plantAndMachineryInvestment}
                      name="plantAndMachineryInvestment"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--sub col-xs-12"}>
                    <InputLabelAnimation
                      labelText={" Monthly Electricity bill (business) (in ₹)"}
                      required={true}
                      type="number"
                      inputValue={additionalDetails.electricityBillPerMonth}
                      name="electricityBillPerMonth"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                </div>
                <h6 className="text-decor">What is required to grow your business:</h6>
                <div className={"row "}>
                  <div>
                    {growthRequirementsList.map((el, index) => {
                      return (
                        <div
                          onClick={() => this.otherDetailsButtonHandler("requirementToGrowBusiness", el)}
                          className={additionalDetails.requirementToGrowBusiness === el ? "btn-active" : "btn-normal"}
                          key={`growth-requirements-list-${index}`}
                        >
                          {el}
                        </div>
                      );
                    })}
                  </div>
                  <div className={"col-sm-6 m-top--main p-left col-xs-12"}>
                    <InputLabelAnimation
                      labelText={" Loan amount if required (in ₹) "}
                      required={true}
                      inputValue={additionalDetails.requiredLoanAmount}
                      name="requiredLoanAmount"
                      type="number"
                      onChange={this.otherDetailsInputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="container p-top--sub ">
                <div className="sub-heading ">
                  <div className="flex-box  ">
                    <h2 className=" sub-heading_basic">Asset Ownership</h2>
                    <div
                      className="btn-add"
                      onClick={() =>
                        this.setState((prevState) => ({
                          assetsDetails: [...prevState.assetsDetails, {}],
                        }))
                      }
                    >
                      + Add an asset
                    </div>
                  </div>
                </div>
                {assetsDetails?.map((el, index) => {
                  return (
                    <div className="container   p-top--sub" key={`assets-details-${index}`}>
                      <div className={"flex-box-basic "}>
                        <h2 className="color-decor">Asset {index + 1}</h2>
                        {index > 0 ? (
                          <span onClick={() => this.deleteAssetsRow(index)} className={"remove-icon"}>
                            <Icon icon={ic_delete_forever} size={28} />
                          </span>
                        ) : null}
                      </div>
                      <div className={"row mt-2 "}>
                        <div className={"col-sm-6 mt-2 col-xs-12 p-left"}>
                          <InputLabelAnimation
                            labelText={"Asset name"}
                            inputValue={el.assetName}
                            name="assetName"
                            onChange={(e) => this.assetsInputHandler(index, e)}
                          />
                        </div>
                        <div className={"col-sm-6 mt-2 col-xs-12 p-left"}>
                          <select
                            className={"select-box"}
                            value={el.yearOfOwnership}
                            name="yearOfOwnership"
                            onChange={(e) => this.assetsInputHandler(index, e)}
                          >
                            <option value="">Year of ownership</option>
                            {ownershipYearsList.map((el, index) => (
                              <option key={`ownership-year-list-${index}`}> {el}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className={"row mt-2"}>
                        <div className={"col-sm-6 text-decor-sub"}>Payment done via:</div>
                        <div className={"col-sm-6 p-left"}>
                          <select
                            className={"select-box mt-2 "}
                            value={el.paymentMethod}
                            name="paymentMethod"
                            onChange={(e) => this.assetsInputHandler(index, e)}
                          >
                            {["Select Type of Payment", "Credit", "Cash"].map((el, index) => {
                              return <option key={`payment-method-${index}`}> {el}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                      {el.paymentMethod === "Credit" && (
                        <div className={"row  mt-2"}>
                          <div className={"col-sm-6 p-left col-xs-12"}>
                            <InputLabelAnimation
                              labelText={"Monthly EMI (in ₹)"}
                              required={true}
                              inputValue={el.monthlyEmi}
                              name="monthlyEmi"
                              type="number"
                              onChange={(e) => this.assetsInputHandler(index, e)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="container p-top--sub">
                <h2 className="sub-heading">Funds and Obligations details</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 text-decor col-xs-6"}>Have you started this business by yourself</div>
                  <div className={"col-sm-5 col-xs-6"}>
                    <div className="btn-decor">
                      <ToggleButton toggleHandler={this.toggleHandler} name="isBusinessSelfStarted" />
                    </div>
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 text-decor  col-xs-6"}>
                    Have you borrowed funds from your friends and relatives:
                  </div>
                  <div className={"col-sm-5 col-xs-6"}>
                    <div className="btn-decor">
                      <ToggleButton
                        toggleHandler={(name) => this.toggleHandler(name)}
                        name="fundsBorrowedFromFamilyFriends"
                      />
                    </div>
                  </div>
                </div>

                {this.state.fundsBorrowedFromFamilyFriends && (
                  <div className={"row "}>
                    <div className={"col-sm-6 m-top--main p-left col-xs-12 "}>
                      <InputLabelAnimation
                        labelText={"Total amount borrowed (in ₹) "}
                        inputValue={fundAndObligationDetails.amountBorrowedFromFamilyandFriends}
                        name="amountBorrowedFromFamilyandFriends"
                        onChange={this.obligationsInputHandler}
                        type="number"
                      />
                    </div>
                    <div className={"col-sm-6 m-top--main col-xs-12"}>
                      <InputLabelAnimation
                        labelText={"Monthly obligation towards them (in ₹) "}
                        inputValue={fundAndObligationDetails.monthlyObligation}
                        name="monthlyObligation"
                        onChange={this.obligationsInputHandler}
                        type="number"
                      />
                    </div>
                  </div>
                )}

                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main p-left col-xs-12 "}>
                    <InputLabelAnimation
                      labelText={"Monthly expense at home (in ₹) "}
                      inputValue={fundAndObligationDetails.monthlyHouseholdExpenses}
                      name="monthlyHouseholdExpenses"
                      onChange={this.obligationsInputHandler}
                      type="number"
                    />
                  </div>
                  <div className={"col-sm-6 m-top--main col-xs-12"}>
                    <InputLabelAnimation
                      labelText={"  Any other fixed obligation per month (in ₹) "}
                      inputValue={fundAndObligationDetails.otherFixedObligationsMonthly}
                      name="otherFixedObligationsMonthly"
                      onChange={this.obligationsInputHandler}
                      type="number"
                    />
                  </div>
                </div>
              </div>
              <div className="btn-box">
                <span onClick={() => this.saveApplication("draft")} className={"btn-draft"}>
                  Save draft
                </span>
                <span className={"btn-save"} onClick={() => this.saveApplication("save")}>
                  Save & Next
                </span>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    let {
        actions,
        relationList,
        creditCycleList,
        purchaseDiscountList,
        interestPaidList,
        growthRequirementsList,
        match,
      } = this.props,
      { getList, loaderModal } = actions;
    loaderModal(true);
    !relationList.length && getList("SET_RELATION_LIST", API_GET_MEMBERS, "Select Relation");
    !creditCycleList.length && getList("SET_CREDIT_CYCLE_LIST", API_GET_CREDIT_CYCLE);
    !purchaseDiscountList.length &&
      getList("SET_PURCHASE_DISCOUNT_LIST", API_GET_PURCHASE_DISCOUNT, "How often do you discount the Invoice");
    !interestPaidList.length &&
      getList("SET_INTEREST_PAID_LIST", API_GET_INTEREST_PAID, "Interest paid for Invoice discount (in%)");
    GET(API_GET_OWNERSHIP_YEARS)
      .then(({ data }) => {
        if (data.data) {
          this.setState({ ownershipYearsList: data.data });
        }
      })
      .catch((err) => {
        throw err;
      });
    !growthRequirementsList.length && getList("SET_GROWTH_REQUIREMENTS_LIST", API_GET_GROWTH_REQUIREMENTS);
    GET(API_GET_BUSINESS_SIZE(match.params.custId)).then(({ data }) => {
      if (data.data) {
        let {
          additionalDetails,
          anyOtherIncomeSource,
          assetsDetails,
          averageMargin,
          averageSalesPerMonth,
          employeeDetails,
          fundAndObligationDetails,
          isSoleEarner,
          monthlyIncome,
          otherEarningMembers,
          otherSourceMonthlyIncome,
        } = data.data;
        this.setState({
          averageSalesPerMonth,
          averageMargin,
          monthlyIncome,
          otherSourceMonthlyIncome,
          employeeDetails,
          anyOtherIncomeSource,
          isSoleEarner,
          otherEarningMembers,
          additionalDetails,
          fundAndObligationDetails,
          isBusinessSelfStarted: fundAndObligationDetails.isBusinessSelfStarted,
          fundsBorrowedFromFamilyFriends: fundAndObligationDetails.fundsBorrowedFromFamilyFriends,
          assetsDetails,
        });
      }
    });

    loaderModal(false);
  };
}
const mapStateToProps = ({ listData }) => ({
  relationList: listData.relationList,
  creditCycleList: listData.creditCycleList,
  purchaseDiscountList: listData.purchaseDiscountList,
  interestPaidList: listData.interestPaidList,
  growthRequirementsList: listData.growthRequirementsList,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getList,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSizeAnalysis);
