import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { loaderModal } from "../../store/actions";
import { connect } from "react-redux";
import { Error, Header, LoaderModal } from "../../components";
import WebDashboard from "./Dashboard/WebDashboard";
import MobileDashboard from "./Dashboard/MobileDashboard";
import LoanTenure from "./LoanTenure/LoanTenure";
import GstnDetails from "./GSTNDetailsPage/GSTNDetails";
import GstnAddress from "./GstnUpdatedAddress/GSTNAddress";
import Documents from "./Documents/Documents";
import Applicants from "./Applicants/Applicants";
import SubmitApp from "./SubmitApp/SubmitApp";
import PostLogin from "./PostLogin/PostLogin";
import FormsPreview from "./FormsPreview/FormsPreview";
import Banking from "./Banking/Banking";
import ApplicantGuide from "./ApplicantGuide/ApplicantGuide";
import References from "./References/References";
import { bindActionCreators } from "redux";
import SourcingDetails from "./SourcingDetails/SourcingDetails";
import { SCREEN_BREAKPOINT } from "../../utils/constants";

class SalesRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentWillMount() {
    let { userDetails, history } = this.props;
    userDetails.roleName === "UW" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_USER" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_ADMIN" && history.push("/Credit/dashboard");
  }

  render() {
    let { loaderModalStatus, actions, userDetails, match } = this.props,
      { loaderModal } = actions;
    const { width } = this.state;
    const isMobileScreen = width < SCREEN_BREAKPOINT;
    let { url } = match;

    return (
      <Fragment>
        {url?.includes("/Sales/dashboard") ? null : <Header {...this.props} />}

        <LoaderModal status={loaderModalStatus} />
        {userDetails.roleName === "AGENT" ||
        userDetails.roleName === "CLUSTER_USER" ? (
          <Switch>
            <Route
              path="/Sales/dashboard"
              exact
              component={WebDashboard}
              // component={isMobileScreen ? MobileDashboard : WebDashboard}
            />
            <Route path="/Sales/dashboard" exact component={MobileDashboard} />
            <Route path="/Sales/post_login" exact component={PostLogin} />
            <Route
              path="/Sales/:custId/post_login"
              exact
              component={PostLogin}
            />
            <Route
              path="/Sales/:appNo/sourcingDetails"
              exact
              component={SourcingDetails}
            />
            <Route path="/Sales/gstn" exact component={LoanTenure} />
            <Route path="/Sales/:appNo/gstn" exact component={LoanTenure} />
            <Route
              path="/Sales/:appNo/gstndetails"
              exact
              component={GstnDetails}
            />
            <Route
              path="/Sales/:appNo/gstnaddress"
              exact
              component={GstnAddress}
            />
            <Route
              path="/Sales/:appNo/applicantGuide"
              exact
              component={ApplicantGuide}
            />
            <Route
              path="/Sales/:appNo/applicants"
              exact
              component={Applicants}
            />
            <Route path="/Sales/:appNo/banking" exact component={Banking} />
            <Route path="/Sales/:appNo/documents" exact component={Documents} />
            <Route
              path="/Sales/:appNo/references"
              exact
              component={References}
            />
            <Route path="/Sales/:appNo/submit" exact component={SubmitApp} />
            <Route
              path="/Sales/:appNo/forms_preview"
              exact
              component={FormsPreview}
            />
            <Redirect from={"/Sales/:appNo"} exact to={`/Sales/:appNo/gstn`} />
            <Route
              path="/Sales/"
              render={(props) => (
                <Error
                  loaderModal={(status) => loaderModal(status)}
                  {...props}
                />
              )}
            />
          </Switch>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesRoutes);
