import React, { Component } from "react";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import EntityRow from "./EntityRow";
import { connect } from "react-redux";
import get from "lodash/get";
import { deleteRow, addRow } from "./applicantGuide.actions";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { InputLabelAnimation } from "../../../components";

class EntityForm extends Component {
  state = {
    btnStatus: false,
  };
  deleteRow = async (i) => {
    let { shareholdingDetails, actions } = this.props,
      { deleteRow, loaderModal } = actions,
      id = get(shareholdingDetails, `shareholdingList[${i}].id`, "");
    if (id) {
      loaderModal(true);
      await deleteRow(i, id).catch((err) => {
        console.log(err);
        window.alert("Something went wrong please try again later.");
      });
      loaderModal(false);
    } else {
      deleteRow(i);
    }
  };

  render() {
    const { shareholdingDetails, entityDetails, actions } = this.props,
      { shareholdingList } = shareholdingDetails,
      { addRow } = actions;
    return (
      <div className={"form-box"}>
        <div className={"row"}>
          <div className={"col-md-5 col-md-offset-1"}>
            <InputLabelAnimation
              labelText={"Entity Name"}
              name={"entity"}
              readOnly={true}
              inputValue={get(entityDetails, "entityName", "").toUpperCase()}
            />
          </div>
          <div className={"col-md-5"}>
            <InputLabelAnimation
              labelText={"Constitution"}
              name={"business"}
              readOnly={true}
              inputValue={get(
                entityDetails,
                "businessConstitution",
                ""
              ).toUpperCase()}
            />
          </div>
        </div>
        <div className={"rows-wrapper"}>
          {shareholdingList.map((item, index) => {
            return (
              <EntityRow
                key={index}
                name={index ? "co" : "app"}
                index={index}
                deleteRow={(index) => this.deleteRow(index)}
              />
            );
          })}
        </div>
        <div className={"text-right"}>
          <button type={"button"} onClick={addRow} className={"add-btn"}>
            <span>
              <Icon icon={ic_add} size={22} />
            </span>
            <span>
              {shareholdingDetails.shareholdingList.length === 1
                ? "Add Co-applicant"
                : "Add More Co-applicant"}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ applicantGuideReducers }) => ({
  shareholdingDetails: applicantGuideReducers.shareholdingDetails,
  entityDetails: applicantGuideReducers.entityDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      deleteRow,
      addRow,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityForm);
