import React, { Component } from "react";
import { Header } from "../../../components";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";

export default class TermsAndCondition extends Component {
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="onBoarding-wrapper">
          <div className="container">
            <Link to={`/cluster/onboarding`} className={"back-btn-link"}>
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Terms and Conditions</h1>
            <p className="container text-justify">
              <span className="font-bold ">
                In connection with submission of the application for my credit
                information (“Consumer Credit Information”) offered by CRIF High
                Mark (“CIC”) through Pemant (referred to as the
                “Company”) and delivery of the Consumer Credit Information to
                the Company, I hereby acknowledge and agree to the following:
              </span>
              <br />
              The Company is my lawfully appointed agent and he / it has agreed
              to be my agent for the purposes, including, without limitation, to
              receive the Consumer Credit Information from CIC on my behalf and
              use it in the manner consistent with the Agreement entered into
              between the Company and CIC, and the Company has granted its
              consent for being appointed for the aforesaid purpose.
              <br />
              <br />
              I grant my unconditional consent to the Company to receive the
              Consumer Credit Information from CIC on my behalf and use it in
              the manner consistent with the consistent with the Agreement
              entered into between the Company and CIC, as the case may be, and
              the Company has granted its consent for being appointed for the
              aforesaid purpose. I hereby represent and acknowledge that: (a) I
              have carefully read the terms and conditions of the Agreement
              entered into between the Company and CIC understood the same; or
              (b) the Terms of Understanding in relation to the use of the
              Consumer Credit Information has been agreed between me and the
              Company.
              <br />
              <br />
              I hereby expressly grant unconditional consent to, and direct, CIC
              to deliver and / or transfer my Consumer Credit Information to the
              Company on my behalf.
              <br />
              <br />
              I shall not hold CIC responsible or liable for any loss, claim,
              liability, or damage of any kind resulting from, arising out of,
              or in any way related to: (a) delivery of my Consumer Credit
              Information to the Company; (b) any use, modification or
              disclosure by the Company of the contents, in whole or in part, of
              my Consumer Credit Information, whether authorized or not; (c) any
              breach of confidentiality or privacy in relation to delivery of my
              Consumer Credit Information to the Company ; (d) for any use made
              by the Company which is contrary to the Agreement entered into
              between the Company and CIC
              <br />
              <br /> I acknowledge and accept that: (a) CIC has not made any
              promises or representations to me in order to induce me to provide
              my Consumer Credit Information or seek any consent or
              authorization in this regard; and (b) the implementation of the
              Agreement between CIC and the Company is solely the responsibility
              of the Company.
              <br />
              <br /> I agree that I may be required to record my consent /
              provide instructions electronically and in all such cases I
              understand that by clicking on the "I Accept" button below, I am
              providing "written instructions" to Company authorizing Company to
              obtain my Consumer Credit Information from my personal credit
              profile from CRIF High Mark. I further authorize Company to obtain
              such information solely to confirm my identity and display my
              Consumer Credit Information to me. Further in all such cases by
              checking this box and clicking on the Authorize button, I agree to
              the terms and conditions, acknowledge receipt of CIC privacy
              policy and agree to its terms, and confirm my authorization for
              Company to obtain my Consumer Credit Information.
              <br />
              <br />
              I understand that in order to deliver the product to me, I hereby
              authorize Company, to obtain my Consumer Credit Information from
              CIC.
              <br />
              <br /> By submitting this registration form, I understand that I
              am providing express written instructions for Company to request
              and receive information about me from third parties, including but
              not limited to a copy of my consumer credit report and score from
              consumer reporting agencies, at any time for so long as I have an
              active Company account. I further authorize Company to retain a
              copy of my information for use in accordance with Company’s Terms
              of Use and Privacy Policy.
              <br />
              <br />
              I UNDERSTAND THAT THE PRODUCT IS PROVIDED ON AN “AS-IS”, “AS
              AVAILABLE” BASIS AND CIC EXPRESSLY DISCLAIMS ALL WARRANTIES,
              INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
              <br />
              <br /> I shall not sue or otherwise make or present any demand or
              claim, and I irrevocably, unconditionally and entirely release,
              waive and forever discharge CIC, its officers, directors,
              employees, agents, licensees, affiliates, successors and assigns,
              jointly and individually (hereinafter “Releasee”), from any and
              all manner of liabilities, claims, demands, losses, claims, suits,
              costs and expenses (including court costs and reasonable attorney
              fees) (“Losses”), whatsoever, in law or equity, whether known or
              unknown, which I ever had, now have, or in the future may have
              against the Releasee with respect to the submission of my Consumer
              Credit Information and / or my decision to provide CIC with the
              authority to deliver my Consumer Credit Information to the
              Company. I agree to defend, indemnify, and hold harmless the
              Releasee from and against any and all Losses resulting from claims
              made against CIC L by third parties arising from and in connection
              with this letter.
              <br /> <br />I agree that the terms of this confirmation letter
              shall be governed by the laws of India and shall be subject to the
              exclusive jurisdiction of the courts located in Mumbai in regard
              to any dispute arising hereof. CIC is entitled to assign its
              rights hereunder to any third person without taking my prior
              written consent.
            </p>
            <div className="text-center">
              <div
                className="btn btn-save"
                onClick={() => {
                  this.props.history.push("/cluster/onboarding");
                }}
              >
                Back to Onboarding
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
