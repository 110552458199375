import React, { useState } from "react";
import Webcam from "react-webcam";
import { GiPlainCircle } from "react-icons/gi";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_CAPTURE } from "../../../utils/APIUrls";
import qs from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
const videoConstraints = {
  width: 360,
  height: 180,
  facingMode: { exact: "environment" },
};
function ImageCapture(props) {
  const [imageBase64, setImageBase64] = useState("");
  const webcamRef = React.useRef(null);
  var image = new Image();
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageBase64(imageSrc);
    image.src = imageSrc;
    document.getElementById("image-place").appendChild(image);
  }, [webcamRef, image]);
  const postImageHandler = () => {
    let { match, location, history, actions, userLocation } = props;
    actions.loaderModal(true);
    const parsedProps = qs.parse(location.search);
    let { docName, docType, entity } = parsedProps;
    let date = new Date();
    let fileName = `${docType
      .slice(0, 10)
      .split(" ")
      .join("_")}_${date.getDate()}${date.getMonth()}${date.getFullYear()}`;
    let dataToSend = {
      data: {
        base64File: imageBase64.slice(23),
        customerId: match.params.custId,
        docName,
        docType,
        entity,
        fileName,
        latitude: userLocation?.lat,
        location: "India",
        longitude: userLocation?.long,
      },
    };
    POST(API_POST_CAPTURE, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          history.push(`/cluster/${match.params.custId}/photo/${match.params.clusterType}`);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  return (
    <div className="image__wrapper">
      <div id="image-place" />
      {!imageBase64 ? (
        <>
          <Webcam
            audio={false}
            height={120}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          />
          <button className="image-capture__footer">
            <GiPlainCircle onClick={capture} />
          </button>
        </>
      ) : (
        <div className="upload-image__wrapper">
          <div
            className="delete-image"
            onClick={() => {
              setImageBase64("");
              document.getElementById("image-place").innerHTML = "";
            }}
          >
            <FiXCircle size={24} onClick={postImageHandler} />
          </div>
          <div className="upload-image">
            <FiCheckCircle size={50} onClick={postImageHandler} />
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ ClusterPhoto }) => ({
  userLocation: ClusterPhoto.userLocation,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageCapture);
