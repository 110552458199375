import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchDocumentList } from "./documents.actions";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_CO_APPLICANT } from "../../../utils/APIUrls";
import { InputLabelAnimation,DatePicker } from "../../../components";
import { getDMY, setMaxDate } from "../../../utils/utitlity";

export class CoApplicantModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      middleName: "",
      pan: "",
      dob: "",
      relation: "",
      maxDate: new Date(),
      birthDate:""
      
    };
  }


  submitForm = (e) => {
    e.preventDefault();
    let { firstName, lastName, middleName, pan, dob, relation } = this.state;
    let { PropsData, toggleModal, fetchDetails } = this.props;

    const data = {
      applicationNumber: PropsData.match.params.appNo,
      shareholdingList: [
        {
          aadhar: "",
          dob,
          firstName,
          id: 0,
          lastName,
          middleName,
          pan,
          profileType: "CO-APPLICANT",
          relation,
          shareholding: 0,
        },
      ],
      throughPemant: false,
    };
    POST(API_POST_CO_APPLICANT, { data }).then(async ({ data }) => {
      if (data.status === "success") {
        window.location.reload();
        toggleModal("");
      } else {
        alert(`${data?.message}`);
      }
    });
  };

  handleDateChange = d => {
   let date=getDMY(new Date(d.getTime()).toLocaleDateString())
   this.setState({
     birthDate: date,
    dob:date.replace(/-/g, '/')
     });
 
  };

  

  fetchRelation = async () => {
    let { actions } = this.props;
    actions.fetchDocumentList();
  };
  inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  componentDidMount() {
    this.fetchRelation();
  }
  render() {
    let { documentRelation } = this.props;
    let { dob,birthDate} = this.state;
  
    return (
      <>
        <form onSubmit={this.submitForm}>
          <div className="co-applicant-wrapper">
            <div className="heading">Add Co-Applicant</div>
            <div className="input-box mt-5 row">
              <div className="input col-md-3 ">
                <InputLabelAnimation
                  labelText={"First Name"}
                  required={true}
                  type={"string"}
                  inputValue={this.state["firstName"]}
                  onChange={this.inputHandler}
                  name={"firstName"}
                />
              </div>
              <div className="input col-md-3 ">
                <InputLabelAnimation
                  labelText={"Middle Name"}
                  type={"string"}
                  inputValue={this.state["middleName"]}
                  onChange={this.inputHandler}
                  name={"middleName"}
                />
              </div>
              <div className="input col-md-3 ">
                <InputLabelAnimation
                  labelText={"Last Name"}
                  type={"text"}
                  inputValue={this.state["lastName"]}
                  onChange={this.inputHandler}
                  name={"lastName"}
                />
              </div>
              <div className={"input col-md-3"}>
                <select
                  className={"select-box_main"}
                  name={"relation"}
                  onChange={this.inputHandler}
                >
                  <option value="">Select Relation</option>
                  {documentRelation?.map((element, index) => {
                    return <option key={index}>{element}</option>;
                  })}
                </select>
              </div>
              <div className="input col-md-3 ">
                <InputLabelAnimation
                  autoCapitalize={"characters"}
                  maxLength={10}
                  minLength={10}
                  labelText={"PAN"}
                  required={true}
                  type={"string"}
                  inputValue={this.state["pan"]}
                  onChange={this.inputHandler}
                  name={"pan"}
                />
              </div>
              <div className="input col-md-3 ">
              <DatePicker
                required={true}
                labelText={"Date of Birth (DD/MM/YYYY)"}
                selected={this.state.birthDate ? new Date(this.state.birthDate.split("-").reverse()) : null}
                onChange={this.handleDateChange}
                maxDate={new Date(this.state.maxDate)}
              />
                
              </div>
            </div>
            <div className="co-applicant-button row">
              <button
                className="button_Generic blue-btn mt-3 ml-3 bolder-text"
                type={"submit"}
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = ({ cpoDocumentsReducers }) => ({
  documentRelation: cpoDocumentsReducers.documentRelation,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchDocumentList,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(CoApplicantModal);
