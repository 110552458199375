import React, { Fragment } from "react";

const applicantsData = [
  {
    Header: "Borrower Type",
    accessor: "caseRole",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "FI Status",
    accessor: "fiDetails",
    Cell: props => {
      if (props.value) {
        return props.value.map((el, index) => {
          return (
            <div className="row fiWrapper" style={{ margin: "0em 0em 1em 0em" }} key={index}>
              <div className="col-sm-9">
                <div style={{ color: "#00abad" }}>{el.addressType}</div>
                <div>{el.address}</div>
              </div>
              <div className="col-sm-3 fiStatus">{el.status}</div>
            </div>
          );
        });
      }
    }
  },
  {
    Header: "CIBIL Score",
    accessor: "cibilScore",
  }
];
export default applicantsData;
