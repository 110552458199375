import React, { Component } from 'react';
import UnitAnalysis from './UnitAnalysis';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPromoterDetails,
  getEmployeeDetails,
  getMonthlyExpenses,
  getInvestmentsAndFunds,
  getIncomeDetails,
  getBusinessMembers,
  getAssetDetails,
  getAddresses,
  getBusinessDetails,
  getBankAccounts,
  getDocumentsImages,
  getLocation,
  getGatingStatus,
} from './unitAnalysis.action';
import { loaderModal } from '../../../store/actions';
import { BASE_URL, GET } from '../../../utils/webAPI.service';
import {
  API_GET_PROMOTER_DETAILS,
  API_GET_EMPLOYEE_DETAILS,
  API_GET_MONTHLY_EXPENSES,
  API_GET_ADDRESSES,
  API_GET_ASSET_DETAILS,
  API_GET_BUSINESS_MEMBERS,
  API_GET_INCOME_DETAILS,
  API_GET_INVESTMENTS_AND_FUNDS,
  API_BUSINESS_DETAILS,
  API_GET_BANK_ACCOUNTS,
  API_GET_DOWNLOAD_DOCUMENT,
  API_GET_LOCATION,
  API_GET_GATING_STATUS,
  API_GET_FETCH_ALL,
} from '../../../utils/APIUrls';
class Overview extends Component {
  componentDidMount() {
    const { actions, match } = this.props,
      { custId } = match.params;
    actions.loaderModal(true);
    actions.getPromoterDetails(API_GET_PROMOTER_DETAILS, custId);
    actions.getEmployeeDetails(API_GET_EMPLOYEE_DETAILS, custId);
    actions.getMonthlyExpenses(API_GET_MONTHLY_EXPENSES, custId);
    actions.getInvestmentsAndFunds(API_GET_INVESTMENTS_AND_FUNDS, custId);
    actions.getIncomeDetails(API_GET_INCOME_DETAILS, custId);
    actions.getBusinessMembers(API_GET_BUSINESS_MEMBERS, custId);
    actions.getAssetDetails(API_GET_ASSET_DETAILS, custId);
    actions.getAddresses(API_GET_ADDRESSES, custId);
    actions.getBusinessDetails(API_BUSINESS_DETAILS, custId);
    actions.getBankAccounts(API_GET_BANK_ACCOUNTS, custId);
    actions.getLocation(API_GET_LOCATION, custId);
    actions.getGatingStatus(API_GET_GATING_STATUS, custId);
    actions.loaderModal(false);
    GET(API_GET_FETCH_ALL(custId))
      .then(({ data }) => {
        if (data.data) {
          let { entityDocs, itemDocs, memberDoc } = data.data;
          const downloadDocumentUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}/${API_GET_DOWNLOAD_DOCUMENT}`;
          actions.getDocumentsImages(
            downloadDocumentUrl,
            memberDoc?.docId,
            'FETCH_ALL_MEMBER_PHOTO'
          );
          entityDocs.forEach((entityDoc) =>
            actions.getDocumentsImages(
              downloadDocumentUrl,
              entityDoc?.docId,
              'FETCH_ALL_ENTITY_PHOTO'
            )
          );
          itemDocs.forEach((itemDoc) =>
            actions.getDocumentsImages(
              downloadDocumentUrl,
              itemDoc?.docId,
              'FETCH_ALL_ITEM_PHOTO'
            )
          );
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
  render() {
    return (
      <div className='lead-manager-overview'>
        <UnitAnalysis custId={this.props.match.params.custId} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = ({ leadManagerReducer }) => ({
  promoterDetails: leadManagerReducer.promoterDetails,
  employeeDetails: leadManagerReducer.employeeDetails,
  monthlyExpenses: leadManagerReducer.monthlyExpenses,
  addresses: leadManagerReducer.addresses,
  assets: leadManagerReducer.assets,
  businessMembers: leadManagerReducer.businessMembers,
  incomeDetails: leadManagerReducer.incomeDetails,
  investmentsAndFunds: leadManagerReducer.investmentsAndFunds,
  businessDetails: leadManagerReducer.businessDetails,
  bankAccounts: leadManagerReducer.bankAccounts,
  memberLocation: leadManagerReducer.memberLocation,
  gatingStatus: leadManagerReducer.gatingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPromoterDetails,
      getEmployeeDetails,
      getMonthlyExpenses,
      getInvestmentsAndFunds,
      getIncomeDetails,
      getBusinessMembers,
      getAssetDetails,
      getAddresses,
      getBusinessDetails,
      getBankAccounts,
      loaderModal,
      getDocumentsImages,
      getLocation,
      getGatingStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
