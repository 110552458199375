import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { loaderModal } from "../../store/actions";
import { connect } from "react-redux";
import { Error, Header, LoaderModal } from "../../components";
import { bindActionCreators } from "redux";
import CPOPanelAdmin from "./cpoPanelAdmin";

class CpoRoutes extends Component {
  componentWillMount() {
    let { userDetails, history } = this.props;
    userDetails.roleName === "UW" && history.push("/Credit/dashboard");
    userDetails.roleName === "UW_ADMIN" && history.push("/Credit/dashboard");
    userDetails.roleName === "AGENT" && history.push("/Sales/dashboard");
    userDetails.roleName === "CPO_USER" && history.push("/CPODashboard/User?activetab=new");
    userDetails.roleName === "CPO_ADMIN" && history.push("/cpoPanelAdmin?activetab=Unassigned");
  }

  render() {
    let { loaderModalStatus, actions, userDetails } = this.props,
      { loaderModal } = actions;

    return (
      <Fragment>
        <Header {...this.props} />
        <LoaderModal status={loaderModalStatus} />
        {userDetails.roleName === "CPO_ADMIN" ? (
          <Switch>
            <Route path="/cpoPanelAdmin" exact component={CPOPanelAdmin} />
            <Route
              path=""
              render={props => (
                <Error loaderModal={status => loaderModal(status)} {...props} />
              )}
            />
          </Switch>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
  userDetails: data.userDetails
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpoRoutes);
