import React, {Component} from 'react';
import {BarLoader} from 'react-spinners';
import PropTypes from 'prop-types';

class ContentLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: false,
        }
    }

    // componentDidMount() {
    //     let {contentFunction} = this.props;
    //     contentFunction.then(({status, renderFunction}) => {
    //         status && this.setState({response: true}, () => {
    //             renderFunction()
    //         });
    //     })
    // }

    render() {
        let {response} = this.state;
        let {children, color, width, height, margin} = this.props;
        return (
            response
                ? children
                : <BarLoader css={{margin}} sizeUnit={"px"} width={width} height={height} color={color}
                             loading={true}/>
        )
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let {renderContent} = this.props;
        let {response} = this.state;
        if (renderContent !== response) {
            renderContent && this.setState({response: renderContent})
        }
    }
}

ContentLoader.defaultProps = {
    color: '#001061',
    height: 5,
    width: 150,
    margin: '0 auto',
    contentFunction: () => {}
};

ContentLoader.propTypes = {
    children: PropTypes.node.isRequired,
    contentFunction: PropTypes.func,
    renderContent: PropTypes.bool,
    width: PropTypes.number,
    margin: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.number
};

export default ContentLoader;
