import React, { Component } from "react";
import { InputLabelAnimation } from "../../../components";
import get from "lodash/get";

class ThirdPartyChannel extends Component {
  render() {
    let { sourcingDetails , inputHandler } = this.props;
    return (
      <>
        <div className="row">
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={" Channel Partner Name"}
              required={true}
              type={"text"}
              name={"partnerName"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "partnerName", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Partner Code"}
              required={true}
              type={"text"}
              name={"partnerCode"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "partnerCode", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Sub-DSA Code"}
              required={true}
              type={"text"}
              name={"subDsaCode"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "subDsaCode", "")}
            />
          </div>
        </div>
        <div className="row">
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={" Promo Code "}
              required={true}
              type={"text"}
              name={"promoCode"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "promoCode", "")}
            />
          </div>
        </div>
        <h5 className="sub-heading"> Sourcing person details</h5>
        <div className="row">
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Sourcing Person"}
              required={true}
              type={"text"}
              name={"sourcingPersonName"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "sourcingPersonName", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Mobile No."}
              required={true}
              type={"number"}
              name={"mobile"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "mobile", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={" Email ID "}
              required={true}
              type={"text"}
              name={"email"}
              onChange={inputHandler}
              inputValue={get(sourcingDetails, "email", "")}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ThirdPartyChannel;
