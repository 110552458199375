import React, { Component } from 'react';
import UnitAnalysis from '../../LeadManagement/Overview/UnitAnalysis';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getPromoterDetails,
  getEmployeeDetails,
  getMonthlyExpenses,
  getInvestmentsAndFunds,
  getIncomeDetails,
  getBusinessMembers,
  getAssetDetails,
  getAddresses,
  getBusinessDetails,
  getBankAccounts,
  getDocumentsImages,
  getLocation,
  getGatingStatus,
} from '../../LeadManagement/Overview/unitAnalysis.action';
import { loaderModal } from '../../../store/actions';
import { BASE_URL, GET } from '../../../utils/webAPI.service';
import Icon from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';
import {
  API_GET_PROMOTER_DETAILS_OVERVIEW,
  API_GET_EMPLOYEE_DETAILS_OVERVIEW,
  API_GET_MONTHLY_EXPENSES_OVERVIEW,
  API_GET_ADDRESSES_OVERVIEW,
  API_GET_ASSET_DETAILS_OVERVIEW,
  API_GET_BUSINESS_MEMBERS_OVERVIEW,
  API_GET_INCOME_DETAILS_OVERVIEW,
  API_GET_INVESTMENTS_AND_FUNDS_OVERVIEW,
  API_BUSINESS_DETAILS_OVERVIEW,
  API_GET_BANK_ACCOUNTS_OVERVIEW,
  API_GET_DOWNLOAD_DOCUMENT_OVERVIEW,
  API_GET_LOCATION_OVERVIEW,
  API_GET_FETCH_ALL_OVERVIEW,
} from '../../../utils/APIUrls';

class UnitAnalysisA extends Component {
  componentDidMount = async () => {
    const { actions, match } = this.props,
      { appNo } = match.params;
    actions.loaderModal(true);
    actions.getPromoterDetails(API_GET_PROMOTER_DETAILS_OVERVIEW, appNo);
    actions.getEmployeeDetails(API_GET_EMPLOYEE_DETAILS_OVERVIEW, appNo);
    actions.getMonthlyExpenses(API_GET_MONTHLY_EXPENSES_OVERVIEW, appNo);
    actions.getInvestmentsAndFunds(
      API_GET_INVESTMENTS_AND_FUNDS_OVERVIEW,
      appNo
    );
    actions.getIncomeDetails(API_GET_INCOME_DETAILS_OVERVIEW, appNo);
    actions.getBusinessMembers(API_GET_BUSINESS_MEMBERS_OVERVIEW, appNo);
    actions.getAssetDetails(API_GET_ASSET_DETAILS_OVERVIEW, appNo);
    actions.getAddresses(API_GET_ADDRESSES_OVERVIEW, appNo);
    actions.getBusinessDetails(API_BUSINESS_DETAILS_OVERVIEW, appNo);
    actions.getBankAccounts(API_GET_BANK_ACCOUNTS_OVERVIEW, appNo);
    actions.getLocation(API_GET_LOCATION_OVERVIEW, appNo);
    await GET(API_GET_FETCH_ALL_OVERVIEW(appNo))
      .then(({ data }) => {
        if (data.data) {
          let { entityDocs, itemDocs, memberDoc } = data.data;
          const downloadDocumentUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}/${API_GET_DOWNLOAD_DOCUMENT_OVERVIEW}`;
          actions.getDocumentsImages(
            downloadDocumentUrl,
            memberDoc?.docId,
            'FETCH_ALL_MEMBER_PHOTO'
          );
          entityDocs.forEach((entityDoc) =>
            actions.getDocumentsImages(
              downloadDocumentUrl,
              entityDoc?.docId,
              'FETCH_ALL_ENTITY_PHOTO'
            )
          );
          itemDocs.forEach((itemDoc) =>
            actions.getDocumentsImages(
              downloadDocumentUrl,
              itemDoc?.docId,
              'FETCH_ALL_ITEM_PHOTO'
            )
          );
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
    actions.loaderModal(false);
  };
  render() {
    let { screenRenderHandler, businessDetails } = this.props;
    return (
      <div className='lead-manager-overview'>
        <div className='container_top'>
          <div
            className={'back-btn-link'}
            onClick={() => screenRenderHandler('overview-details')}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>
          <h3 className='container_heading'>
            {businessDetails?.businessName} - Unit Analysis
          </h3>
        </div>
        <UnitAnalysis custId={this.props.match.params.appNo} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = ({ leadManagerReducer }) => ({
  promoterDetails: leadManagerReducer.promoterDetails,
  employeeDetails: leadManagerReducer.employeeDetails,
  monthlyExpenses: leadManagerReducer.monthlyExpenses,
  addresses: leadManagerReducer.addresses,
  assets: leadManagerReducer.assets,
  businessMembers: leadManagerReducer.businessMembers,
  incomeDetails: leadManagerReducer.incomeDetails,
  investmentsAndFunds: leadManagerReducer.investmentsAndFunds,
  businessDetails: leadManagerReducer.businessDetails,
  bankAccounts: leadManagerReducer.bankAccounts,
  memberLocation: leadManagerReducer.memberLocation,
  gatingStatus: leadManagerReducer.gatingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPromoterDetails,
      getEmployeeDetails,
      getMonthlyExpenses,
      getInvestmentsAndFunds,
      getIncomeDetails,
      getBusinessMembers,
      getAssetDetails,
      getAddresses,
      getBusinessDetails,
      getBankAccounts,
      loaderModal,
      getDocumentsImages,
      getLocation,
      getGatingStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitAnalysisA);
