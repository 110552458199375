import { get } from "lodash";
import * as ACTION_TYPE from "./dashboard.action.constant";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_GET_ALL_APPLICATIONS,
  API_GET_SEARCHBY_FILTERS,
  API_GET_USER_BY_ROLE_ID,
  API_DASHBOARD_FETCH_DATA
} from "../../../utils/APIUrls";
import { UPDATE_FILTER_OPTIONS } from "../../../components/Header/header.action.constant";
import { getDateString, getYMD } from "../../../utils/utitlity";
import { changeValue as headerChangeValue } from "../../../components/Header/header.action";

const fetchAllApplications = () => async (dispatch, getState) => {
  dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_START });
  const reduxState = getState();
  const headerSearch = get(reduxState, "header.form.values"),
    searchBy = get(headerSearch, "searchFilter.value", ""),
    searchString = searchBy === "" ? "" : get(headerSearch, "searchInput", ""),
    startDate = getYMD(
      new Date(
        get(
          reduxState.creditDashboard,
          "form.values.startDate",
          getDateString(new Date())
        )
      ).toLocaleDateString()
    ),
    endDate = getYMD(
      new Date(
        get(
          reduxState.creditDashboard,
          "form.values.endDate",
          getDateString(new Date())
        )
      ).toLocaleDateString()
    ),
    activeTabApplicant = get(
      reduxState.creditDashboard,
      "form.values.activeTabApplicant",
      "All"
    ),
    currentPage = get(reduxState.creditDashboard, "form.values.currentPage", 1),
    searchByFilters = get(reduxState.creditDashboard, "searchByFilters");

  const requestPayload = { pageNumber: currentPage };
  if (searchString) {
    dispatch({
      type: ACTION_TYPE.CHANGE_VALUE,
      data: "All",
      name: "activeTabApplicant"
    });
    requestPayload.searchBy = searchBy;
    requestPayload.searchString = searchString;
    requestPayload.statusFilter = "";
    requestPayload.endDate = "";
    requestPayload.startDate = "";
  } else if (searchByFilters.byTabs) {
    requestPayload.statusFilter = activeTabApplicant;
    requestPayload.searchBy = "";
    requestPayload.searchString = "";
    requestPayload.endDate = "";
    requestPayload.startDate = "";
  } else if (searchByFilters.byDate) {
    requestPayload.endDate = endDate;
    requestPayload.startDate = startDate;
    requestPayload.searchBy = "";
    requestPayload.searchString = "";
    requestPayload.statusFilter = "";
  }
  try {
    const response = await POST(API_GET_ALL_APPLICATIONS, {
      data: requestPayload
    });
    if (response.data.status === "error") {
      throw response.data;
    } else if (response.data.status === "success") {
      dispatch({
        type: ACTION_TYPE.GET_ALL_APPLICATIONS,
        data: response.data.data
      });
     
    } else if (response.data.message === "No application found.") {
      dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS, data: [] });
    }
  } catch (error) {
    dispatch({
      type: ACTION_TYPE.GET_ALL_APPLICATIONS_FINISHED,
      error: error.message
    });
    return error;
  } finally {
    dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_FINISHED });
    dispatch(headerChangeValue("searchInput", ""));
    dispatch(headerChangeValue("searchFilter", ""));
  }
};

const getSearchByFilters = () => async dispatch => {
  dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_START });
  try {
    const response = await GET(API_GET_SEARCHBY_FILTERS);
    if (response.data.status === "error") {
      throw response.data;
    }
    const filters = get(response, "data.data.responseList", []).map(value => ({
      label: value,
      value
    }));
    dispatch({ type: UPDATE_FILTER_OPTIONS, data: filters });
  } catch (error) {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_ERROR, error: error.message });
    return error;
  } finally {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_FINISHED })
  }
};
export const fetchDashBoardData = data => async dispatch => {
  dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_START });
  try {
    await POST(API_DASHBOARD_FETCH_DATA, data).then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: data.data });
       
      } else {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: [] });
      }
    });
  } catch (error) {
    dispatch({ type: ACTION_TYPE.GET_SEARCH_BY_ERROR, error: error.message });
    return error;
  } finally {
    dispatch({ type: ACTION_TYPE.GET_ALL_APPLICATIONS_FINISHED });
  }
};
export const resetData = (data)=> dispatch => {
  
  dispatch({type:ACTION_TYPE.RESET_DATA , data:data})
}
const getUserList = name => dispatch => {
  GET(API_GET_USER_BY_ROLE_ID(name))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({ type: ACTION_TYPE.USER_LIST, userList: data.data });
      }
    })
    .catch(err => {
      console.log(err);
    });
};
const setSearchFilterStatus = name => ({
  type: ACTION_TYPE.SET_SEARCH_BY_STATUS,
  name
});
const updateDashBoardData = data=>dispatch=>{

}
/**
 * @param name: field Name
 * @param val: field Value
 * @returns {{type: string, data: *, name: string}}
 */
const changeValue = (name, val) => ({
  type: ACTION_TYPE.CHANGE_VALUE,
  data: val,
  name: name
});

export {
  fetchAllApplications,
  getSearchByFilters,
  changeValue,
  setSearchFilterStatus,
  getUserList,
  updateDashBoardData
};
