import React from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { flag } from "react-icons-kit/entypo/flag";
import LimitUtilizationEditForm from "./LimitUtilizationEditForm";
import CashFlowFlags from "./CashFlowFlags";
import { CredoModal } from "../../../components";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
import { getBankAccFlags } from "./cashflow.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class BankAccountDetails extends React.PureComponent {
  state = {
    modalOpen: false,
    flagModalOpen: false,
    modalType: "",
    redFlag: false,
    getBankAccFlagsData: {},
  };
  toggleModal = (modalType, props) => {
    const modalState = !this.state.modalOpen;
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalType: modalState ? modalType : "",
    });
  };

  flagToggleModal = (modalType, props) => {
    const flagModalState = !this.state.flagModalOpen;
    this.setState({
      flagModalOpen: !this.state.flagModalOpen,
      modalType: flagModalState ? modalType : "",
    });
  };
  componentDidMount() {
    let { actions } = this.props;
    let { match, data } = this.props;
    match && actions.getBankAccFlags(match.params.appNo, data.accountNumber);
  }
  render() {
    let dataTosendFlags = {};
    let { getBankAccFlagsData, data, index } = this.props;
    let { accountNumber } = data;
    let className;
    dataTosendFlags = {
      flagsData: getBankAccFlagsData[accountNumber] || [],
      accountNumber: accountNumber,
    };

    let { modalOpen, modalType, flagModalOpen } = this.state;
    return (
      <div className={className ? className : "col-md-6"}>
        <div className="box-content mt-3">
          <div className="box-header">
            <div className={"view-flags"} onClick={() => this.flagToggleModal("redFlag")}>
              <Icon icon={flag} size={22} />
              View Flags
            </div>
            <h3 className="box-heading">Bank Account {index}</h3>
            <div className="actions"></div>
          </div>
          <ul className="bank-details-list">
            <li>
              <label>Account Number</label>
              <span>{data.accountNumber}</span>
            </li>
            <li>
              <label>Type of Account</label>
              <span>{data.accountType}</span>
            </li>
            <li>
              <label>Bank Name</label>
              <span>{data.bankName}</span>
            </li>
            <li>
              <label>Account Holder's Name</label>
              <span>{data.accountHolderName ? data.accountHolderName.trim() : null}</span>
            </li>
            {data.accountType === "CC/OD Account" ? (
              <li>
                <label>Sanction Limit</label>
                <span>
                  <span onClick={() => this.toggleModal("limitUtilization")} className="sanctionLimitEdit">
                    <p>Click Here to View</p>
                  </span>
                </span>
              </li>
            ) : null}

            <li>
              <label>Months Available</label>
              <ul className="months-list">
                {typeof data.monthAvailable !== "undefined" && data.monthAvailable != null ? (
                  data.monthAvailable.map((month, index) => (
                    <li key={index}>
                      {month.split("-")[0]}
                      <br />
                      {month.split("-")[1]}
                    </li>
                  ))
                ) : (
                  <li>NA</li>
                )}
              </ul>
            </li>
          </ul>
        </div>
        <CredoModal
          isOpen={modalOpen}
          className={"edit-ratio-modal"}
          bankingCardDetail={data}
          {...this.props}
          sendData={this.getData}
          sendData1={this.getData1}
          isEditable={true}
          styles={{
            content: {
              borderRadius: "25px",
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
            },
          }}
          title={
            modalType === "editRatio" ? "Add Revised Ratio" : "editRatio1" ? "Update Sanction Limit" : "Add Amount"
          }
          closeAction={() => this.toggleModal("")}
          RenderingComponent={modalType === "limitUtilization" ? LimitUtilizationEditForm : null}
        />
        <CredoModal
          isOpen={flagModalOpen}
          className={"red-flag__wrapper normal-curved-borders"}
          dataTosendFlags={dataTosendFlags}
          accountNumber={accountNumber}
          styles={{
            content: {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
              maxheight: "85vh",
            },
          }}
          closeAction={() => this.flagToggleModal("")}
          RenderingComponent={modalType === "redFlag" ? CashFlowFlags : null}
        />
      </div>
    );
  }
}
const mapStateToProps = ({ cashFlow }) => ({
  getBankAccFlagsData: cashFlow.getBankAccFlagsData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBankAccFlags,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(BankAccountDetails);
