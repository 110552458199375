import React, { Component } from "react";
import { Header, InputLabelAnimation, LoaderModal } from "../../../components";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_GET_BUSINESS_COST,
  API_POST_BUSINESS_COST,
  API_GET_FETCH_AVERAGE_PRICE,
} from "../../../utils/APIUrls";
import { set } from "lodash";
import { MAHTArray, machineryArray } from "./mahtBusinessCost.config";
export default class MAHTBusinessCost extends Component {
  state = {
    machineAndHandToolsCostAnalysis: {
      businessDetails: {},
      machineAndHandTools: {
        forgingWork: null,
        machineAndHandToolList: [],
        manufacturingOfHandTools: null,
        manufacturingOfMotherMachine: null,
      },
      machinery: {},
      productionAndSales: {
        forgingWork: {},
        machineAndHandToolList: [],
        manufacturingOfHandTools: {},
        manufacturingOfMotherMachine: {},
      },
    },
    isLoading: false,
    avgSalesList: [],
  };
  addBicycleCost = async (actionType) => {
    let { machineAndHandToolsCostAnalysis } = this.state,
      { match, history } = this.props,
      dataToSend = {
        data: {
          machineAndHandToolsCostAnalysis,
          customerId: match.params.custId,
        },
      };
    this.setState({ isLoading: true });
    await POST(API_POST_BUSINESS_COST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/Sales/${match.params.custId}/post_login`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else {
          alert(data.message);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        throw err;
      });
  };
  inputHandler = (e) => {
    let { name, value } = e.target,
      { machineAndHandToolsCostAnalysis } = this.state;
    this.setState({
      machineAndHandToolsCostAnalysis: set(
        machineAndHandToolsCostAnalysis,
        name,
        value
      ),
    });
  };
  bicyclePartsHandler = (name, value) => {
    let { machineAndHandToolsCostAnalysis } = this.state,
      inputValue = !value ? " " : null;
    this.setState({
      machineAndHandToolsCostAnalysis: {
        ...machineAndHandToolsCostAnalysis,
        machineAndHandTools: {
          ...machineAndHandToolsCostAnalysis.machineAndHandTools,
          [name]: inputValue,
        },
        productionAndSales: {
          ...machineAndHandToolsCostAnalysis.productionAndSales,
          [name]: inputValue,
        },
      },
    });
  };
  machineryHandler = (name, value) => {
    let { machineAndHandToolsCostAnalysis } = this.state,
      inputValue = !value ? " " : null;
    this.setState({
      machineAndHandToolsCostAnalysis: {
        ...machineAndHandToolsCostAnalysis,
        machinery: {
          ...machineAndHandToolsCostAnalysis.machinery,
          [name]: inputValue,
        },
      },
    });
  };
  addWorkHandler = () => {
    let { machineAndHandToolsCostAnalysis } = this.state;
    this.setState({
      machineAndHandToolsCostAnalysis: {
        ...machineAndHandToolsCostAnalysis,
        machineAndHandTools: {
          ...machineAndHandToolsCostAnalysis.machineAndHandTools,
          machineAndHandToolList: [
            ...machineAndHandToolsCostAnalysis.machineAndHandTools
              .machineAndHandToolList,
            {},
          ],
        },
        productionAndSales: {
          ...machineAndHandToolsCostAnalysis.productionAndSales,
          machineAndHandToolList: [
            ...machineAndHandToolsCostAnalysis.productionAndSales
              .machineAndHandToolList,
            {},
          ],
        },
      },
    });
  };
  addMachineryHandler = () => {
    let { machineAndHandToolsCostAnalysis } = this.state;
    this.setState({
      machineAndHandToolsCostAnalysis: {
        ...machineAndHandToolsCostAnalysis,
        machinery: {
          ...machineAndHandToolsCostAnalysis.machinery,
          others: [
            ...(machineAndHandToolsCostAnalysis.machinery.others || []),
            {},
          ],
        },
      },
    });
  };
  render() {
    let { match } = this.props,
      { machineAndHandToolsCostAnalysis, isLoading, avgSalesList } = this.state,
      {
        machineAndHandTools = [],
        machinery = [],
        businessDetails,
        productionAndSales,
      } = machineAndHandToolsCostAnalysis;
    return (
      <>
        <Header {...this.props} />
        <div className="business-cost">
          <LoaderModal status={isLoading} />
          <div className="container">
            <Link
              to={`/cluster/${match.params.custId}/photo/Ludhiana-Machine-And-Hand-Tools`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Business Cost Analysis</h1>
            <form>
              <div className="container m-top--main mt-2">
                <div className=" flex-box">
                  <h2 className="basic-heading">Machine and Hand Tools</h2>
                  <button
                    className="btn-add"
                    type="button"
                    onClick={this.addWorkHandler}
                  >
                    + Add another work
                  </button>
                </div>
                <p>Type of Manufacturing your entity does</p>
                {MAHTArray.map((bicyclePart) => {
                  const { name, label } = bicyclePart;
                  return (
                    <div className="row" key={name}>
                      <div
                        className="col-xs-9 col-md-9 "
                        onClick={() =>
                          this.bicyclePartsHandler(
                            name,
                            machineAndHandTools[name]
                          )
                        }
                      >
                        <div
                          className={
                            machineAndHandTools[name]
                              ? "active bicycle-part-box"
                              : "bicycle-part-box"
                          }
                        >
                          <img
                            src={require(`../../../assets/images/MAHT/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Margin %"}
                          onChange={this.inputHandler}
                          name={`machineAndHandTools.${name}`}
                          inputValue={machineAndHandTools[name] || ""}
                          disabled={!machineAndHandTools[name]}
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
                {machineAndHandTools?.machineAndHandToolList?.map(
                  (machineAndHandTool, index) => {
                    const { name, profitMargin } = machineAndHandTool;
                    return (
                      <div className="row" key={`machine-and-tools-${index}`}>
                        <div className="col-xs-9 col-md-9 ">
                          <InputLabelAnimation
                            labelText={`Other Work ${index + 1}`}
                            onChange={(e) => {
                              let { value } = e.target,
                                {
                                  machineAndHandToolsCostAnalysis,
                                } = this.state;
                              this.setState({
                                machineAndHandToolsCostAnalysis: set(
                                  machineAndHandToolsCostAnalysis,
                                  `productionAndSales.machineAndHandToolList.[${index}].name`,
                                  value
                                ),
                              });
                              this.inputHandler(e);
                            }}
                            name={`machineAndHandTools.machineAndHandToolList.[${index}].name`}
                            inputValue={name || ""}
                          />
                        </div>
                        <div className="col-xs-3 col-md-3 pl-2">
                          <InputLabelAnimation
                            labelText={"Margin %"}
                            onChange={this.inputHandler}
                            name={`machineAndHandTools.machineAndHandToolList.[${index}].profitMargin`}
                            inputValue={profitMargin || ""}
                            type="number"
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="container m-top--main mt-2">
                <div className=" flex-box">
                  <h2 className="basic-heading">Machinery</h2>
                  <button
                    className="btn-add"
                    type="button"
                    onClick={this.addMachineryHandler}
                  >
                    + Add Machinery
                  </button>
                </div>
                <p>Type and Number of Machinery you have</p>
                {machineryArray.map((machineryPart) => {
                  const { name, label } = machineryPart;
                  return (
                    <div className="row" key={name}>
                      <div
                        className="col-xs-6 col-md-7 "
                        onClick={() =>
                          this.machineryHandler(name, machinery[name])
                        }
                      >
                        <div
                          className={
                            machinery[name]
                              ? "active bicycle-part-box"
                              : "bicycle-part-box"
                          }
                        >
                          <img
                            src={require(`../../../assets/images/MAHT/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-2 pl-2">
                        <InputLabelAnimation
                          labelText={"Number"}
                          onChange={this.inputHandler}
                          name={`machinery.${name}.number`}
                          inputValue={machinery[name]?.number || ""}
                          disabled={!machinery[name]}
                          type="number"
                        />
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Tentative Cost (in ₹)"}
                          onChange={this.inputHandler}
                          name={`machinery.${name}.tentativeCost`}
                          inputValue={machinery[name]?.tentativeCost || ""}
                          disabled={!machinery[name]}
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
                {machinery?.others?.map((other, index) => {
                  const { name, tentativeCost, number } = other;
                  return (
                    <div className="row" key={`machinery-others-${index}`}>
                      <div className="col-xs-6 col-md-7 ">
                        <InputLabelAnimation
                          labelText={`Other Work ${index + 1}`}
                          onChange={this.inputHandler}
                          name={`machinery.others.[${index}].name`}
                          inputValue={name || ""}
                        />
                      </div>
                      <div className="col-xs-3 col-md-2 pl-2">
                        <InputLabelAnimation
                          labelText={"Number"}
                          onChange={this.inputHandler}
                          name={`machinery.others.[${index}].number`}
                          inputValue={number || ""}
                          type="number"
                        />
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Tentative Cost (in ₹)"}
                          onChange={this.inputHandler}
                          name={`machinery.others.[${index}].tentativeCost`}
                          inputValue={tentativeCost || ""}
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">
                  Production level and Sales number
                </h2>
                {MAHTArray.map((MAHTPart) => {
                  const { name, label } = MAHTPart;
                  return machineAndHandTools[name] ? (
                    <div className="container" key={name}>
                      <div className="blue-label">{label}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputLabelAnimation
                            labelText={"Consumption of Metal In (in kg)"}
                            onChange={this.inputHandler}
                            required={true}
                            type="number"
                            name={`productionAndSales.${name}.metalConsumption`}
                            inputValue={
                              productionAndSales[name]?.metalConsumption
                            }
                          />
                        </div>
                        <div className="col-sm-6 pl-1">
                          <InputLabelAnimation
                            labelText={"Monthly sales / production (units)"}
                            onChange={this.inputHandler}
                            required={true}
                            type="number"
                            name={`productionAndSales.${name}.monthlySalesProduction`}
                            inputValue={
                              productionAndSales[name]?.monthlySalesProduction
                            }
                          />
                        </div>
                        <div className="col-sm-6 mt-2">
                          <select
                            className={"select-box"}
                            onChange={this.inputHandler}
                            name={`productionAndSales.${name}.averageSalesPricePerUnit`}
                            value={
                              productionAndSales[name]?.averageSalesPricePerUnit
                            }
                          >
                            {avgSalesList?.map((el, index) => (
                              <option value={el}>{el}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
                {productionAndSales?.machineAndHandToolList?.map(
                  (MAHTPart, index) => {
                    const {
                      name,
                      metalConsumption,
                      monthlySalesProduction,
                      averageSalesPricePerUnit,
                    } = MAHTPart;
                    return (
                      <div className="container" key={name}>
                        <div className="blue-label">{name}</div>
                        <div className="row">
                          <div className="col-sm-6">
                            <InputLabelAnimation
                              labelText={"Consumption of Metal In (in kg)"}
                              onChange={this.inputHandler}
                              required={true}
                              type="number"
                              name={`productionAndSales.machineAndHandToolList.[${index}].metalConsumption`}
                              inputValue={metalConsumption}
                            />
                          </div>
                          <div className="col-sm-6 pl-1">
                            <InputLabelAnimation
                              labelText={"Monthly sales / production (units)"}
                              onChange={this.inputHandler}
                              required={true}
                              type="number"
                              name={`productionAndSales.machineAndHandToolList.[${index}].monthlySalesProduction`}
                              inputValue={monthlySalesProduction}
                            />
                          </div>
                          <div className="col-sm-6 mt-2">
                            <select
                              className={"select-box"}
                              onChange={this.inputHandler}
                              name={`productionAndSales.machineAndHandToolList.[${index}].averageSalesPricePerUnit`}
                              value={averageSalesPricePerUnit}
                            >
                              {avgSalesList?.map((el, index) => (
                                <option value={el}>{el}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Business details</h2>
                <div className="container">
                  <div className="blue-label">Manufacturing Unit</div>
                  <InputLabelAnimation
                    labelText={"Size of Manufacturing Unit (in sq. Yard)"}
                    onChange={this.inputHandler}
                    required={true}
                    type="number"
                    name={
                      "businessDetails.manufacturingUnit.sizeOfManufacturingUnit"
                    }
                    inputValue={
                      businessDetails?.manufacturingUnit
                        ?.sizeOfManufacturingUnit
                    }
                  />
                </div>
              </div>
              <div className="btn-box">
                <div
                  className="btn-draft "
                  onClick={() => this.addBicycleCost("draft")}
                >
                  Save for later
                </div>
                <div
                  className="btn-save"
                  onClick={() => this.addBicycleCost("save")}
                >
                  Save and Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    const { match } = this.props;
    this.setState({ isLoading: true });
    GET(API_GET_BUSINESS_COST(match.params.custId)).then(({ data }) => {
      if (data.data) {
        let { machineAndHandToolsCostAnalysis } = data.data;
        this.setState({ machineAndHandToolsCostAnalysis });
      }
      this.setState({ isLoading: false });
    });
    GET(API_GET_FETCH_AVERAGE_PRICE).then(({ data }) => {
      if (data.data) {
        let { responseList } = data.data;
        this.setState({ avgSalesList: ["Average sales price per unit (in ₹)",...responseList] });
      }
      this.setState({ isLoading: false });
    });
  };
}
