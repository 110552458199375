import * as ITRConstant from "./ITR.actions.constant";
import { GET } from "../../../utils/webAPI.service";
import {
  API_FETCH_BALANCE_SHEET,
  API_FETCH_PROFIT_AND_LOSS_ACCOUNT,
  API_GET_ASSESSEE_DETAILS,
  API_GET_RATIOS,
} from "../../../utils/APIUrls";

const fetchBalanceSheet = (applicationNumber) => (dispatch) => {
  return GET(API_FETCH_BALANCE_SHEET(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ITRConstant.GET_ALL_BALANCE_SHEET, data: data.data });
      }
    })
    .catch((err) => {
      throw err;
    });
};

const fetchProfitAndLossAccount = (applicationNumber) => (dispatch) => {
  return GET(API_FETCH_PROFIT_AND_LOSS_ACCOUNT(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ITRConstant.GET_ALL_PROFIT_AND_LOSS_ACCOUNT,
          data: data.data,
        });
      }
    })
    .catch((err) => {
      throw err;
    });
};

const getAssesseeDetailsAndOtherAnalytics = (applicationNumber) => (
  dispatch
) => {
  return GET(API_GET_ASSESSEE_DETAILS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ITRConstant.ASSESSEE_DETAILS,
          data: data.data,
        });
      }

      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getRatios = (applicationNumber) => (dispatch) => {
  return GET(API_GET_RATIOS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ITRConstant.RATIOS,
          data: data.data.allRatios,
        });
      }

      return data;
    })
    .catch((err) => {
      throw err;
    });
};
export {
  fetchBalanceSheet,
  fetchProfitAndLossAccount,
  getAssesseeDetailsAndOtherAnalytics,
  getRatios,
};
