const DELETE_FORM_BOX = Symbol("DeleteFormBox"),
  GET_BUSINESS = Symbol("getBusiness"),
  CHANGE_VALUE = Symbol("changeValue"),
  ADD_ADDRESS = Symbol("addAddress"),
  UPDATE_ADDRESS = Symbol("updateAddress"),
  DELETE_ADDRESS = Symbol("deleteAddress"),
  GET_PARTY_DETAILS = Symbol("getPartyDetails"),
  ADD_PARTY_ROW = Symbol("addPartyRow"),
  DELETE_PARTY_ROW=Symbol("deletePartyRow"),
  PARTY_CHANGE_HANDLER=Symbol("partyChangeHandler"),
  RESET_REDUX_STATE=Symbol("resetRedux");
export {
  DELETE_FORM_BOX,
  GET_BUSINESS,
  CHANGE_VALUE,
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  GET_PARTY_DETAILS,
  ADD_PARTY_ROW,
  DELETE_PARTY_ROW,
  PARTY_CHANGE_HANDLER,
  RESET_REDUX_STATE
};
