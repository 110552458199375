import * as CreditCapacityConstant from "./creditCapacity.actions.constant";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_GET_EMI_DETAILS,
  API_GET_CURRENT_OBLIGATIONS,
  API_GET_MARGIN_DATA,
  API_GET_TURNOVER_DETAILS,
  API_GET_CREDIT_CAPACITY_DETAILS,
  API_POST_UPDATE_BUREAU_TRADE_LINE,
  API_GET_LOAN_AMOUNT_TENURE_INTEREST
} from "../../../utils/APIUrls";
import { get } from "lodash";

const updateInputValue = (name, val) => ({
  type: CreditCapacityConstant.CHANGE_FORM_INPUT_VALUE,
  name,
  val
});

const updateModalInputValue = (name, val) => ({
  type: CreditCapacityConstant.CHANGE_FORM_MODAL_INPUT_VALUE,
  name,
  val
});

const getEMIDetails = applicationNumber => {
  return dispatch => {
    return GET(API_GET_EMI_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          let bankAccounts = data.data.bankAccounts.map(account => ({
            ...account,
            emiDetails: account.emiDetails.map(item => ({
              ...item,
              selected: false
            }))
          }));
          dispatch({
            type: CreditCapacityConstant.SET_EMI_DETAILS,
            data: bankAccounts
          });
        } else {
          dispatch({ type: CreditCapacityConstant.SET_EMI_DETAILS, data: [] });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getObligationsData = applicationNumber => {
  return dispatch => {
    return GET(API_GET_CURRENT_OBLIGATIONS(applicationNumber))
      .then(({ data }) => {
        let obligationsData = [];
        if (data.status === "success") {
          let {
            loanDetails,
            tradeLines,
            toatalEmiAmount,
            totalEmiImputed,
            totalEmiObligated
          } = data.data;
          obligationsData = loanDetails.map(item => ({
            ...item,
            emiObligated: true,
            final6Months: false
          }));
          if (Array.isArray(tradeLines) && tradeLines.length) {
            tradeLines.forEach(item => {
              obligationsData.push({
                ...item.loanDetails,
                emiObligated: item.emiObligated,
                final6Months: item.final6Months
              });
            });
          }
          dispatch({
            type: CreditCapacityConstant.SET_OBLIGATIONS_DATA,
            data: { toatalEmiAmount, totalEmiImputed, totalEmiObligated }
          });
          dispatch({
            type: CreditCapacityConstant.GET_CURRENT_OBLIGATIONS,
            data: obligationsData
          });
          dispatch({
            type: CreditCapacityConstant.SET_FILTERED_OBLIGATIONS,
            data: obligationsData
          });
        }
        return { ...data, data: obligationsData };
      })
      .catch(err => {
        throw err;
      });
  };
};
const getLoanAmountAndTenure = appNo => dispatch => {
  GET(API_GET_LOAN_AMOUNT_TENURE_INTEREST(appNo))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({
          type: CreditCapacityConstant.GET_LOAN_TENURE_INTEREST,
          data: data.data
        });
      }
    })
    .catch(err => {
      throw err;
    });
};
const setActiveAccount = num => ({
  type: CreditCapacityConstant.SET_ACTIVE_ACCOUNT,
  num
});

const selectAccountEMI = index => ({
  type: CreditCapacityConstant.SELECT_ACCOUNT_EMI,
  index
});

const getMarginData = applicationNumber => {
  return dispatch => {
    return GET(API_GET_MARGIN_DATA(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: CreditCapacityConstant.GET_MARGIN_DATA,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const selectObligationData = (index, name) => ({
  type: CreditCapacityConstant.SELECT_OBLIGATIONS_DATA,
  index,
  name
});

const setFilteredObligationsData = list => ({
  type: CreditCapacityConstant.SET_FILTERED_OBLIGATIONS,
  list
});

const getTurnoverDetails = applicationNumber => {
  return dispatch => {
    return GET(API_GET_TURNOVER_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          let dataObj = {
            totalBankingTurnover: get(data, "data.totalBankingTurnover") || 0,
            totalGSTTurnover: get(data, "data.totalGSTTurnover") || 0,
            imputedGSTTurnover : get(data,"data.imputedGSTTurnover") || 0
          };
          dispatch({
            type: CreditCapacityConstant.SET_TURNOVER_DETAILS,
            data: dataObj
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getCreditCapacityDetails = applicationNumber => {
  return dispatch => {
    return GET(API_GET_CREDIT_CAPACITY_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: CreditCapacityConstant.SET_CREDIT_CAPACITY_DETAILS,
            data: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};
const updateBureauTradeLine = data => dispatch => {
  POST(API_POST_UPDATE_BUREAU_TRADE_LINE, data)
    .then(({ data, status }) => {
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const resetFormModal = () => ({
  type: CreditCapacityConstant.RESET_FORM_MODAL
});

export {
  updateInputValue,
  updateModalInputValue,
  getEMIDetails,
  setActiveAccount,
  selectAccountEMI,
  getObligationsData,
  getMarginData,
  selectObligationData,
  setFilteredObligationsData,
  getTurnoverDetails,
  getCreditCapacityDetails,
  resetFormModal,
  updateBureauTradeLine,
  getLoanAmountAndTenure
};
