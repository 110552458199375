import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { GET, POST } from "../../../utils/webAPI.service";
import { API_GET_BUSINESS_COST, API_GET_CLUSTER_QUESTIONS, API_POST_BUSINESS_COST } from "../../../utils/APIUrls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { Header } from "../../../components";

class FootwearBusinessCost extends Component {
  state = {
    agraCostAnalysis: {},
  };
  addApplication = () => {
    let { match, history, actions } = this.props,
      { agraCostAnalysis } = this.state;
    actions.loaderModal(true);
    let dataToSend = {
      data: {
        customerId: match.params.custId,
        agraCostAnalysis,
      },
    };
    POST(API_POST_BUSINESS_COST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          history.push(`/Sales/${match.params.custId}/post_login`);
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  inputHandler = (e) => {
    let { name, value } = e.target;
    let { agraCostAnalysis } = this.state;
    this.setState({ agraCostAnalysis: { ...agraCostAnalysis, [name]: value } });
  };

  render() {
    let { match } = this.props,
      { agraCostAnalysis } = this.state,
      {
        costPerBox,
        costPerPairSole,
        costPerPair,
        upperMaterialCostPerUnit,
        upperMaterialBoughtMonthly,
        solesPairBoughtMonthly,
        packagingBoxBoughtMonthly
      } = agraCostAnalysis;
    return (
      <>
        <Header {...this.props} />
        <div className="business-cost">
          <div className="container">
            <Link
              to={`/cluster/${match.params.custId}/photo/Agra-Footwear-Cluster`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Business Cost Analysis</h1>
            <form>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Shoe Cluster</h2>
                <h5 className="sub-qsn">
                  How much sq. meter upper material do you buy monthly and cost per square meter?
                </h5>
                <div className="row">
                  <div className="col-xs-6">
                    <InputLabelAnimation
                      labelText={"Number (in sq.metre)"}
                      required={true}
                      inputValue={upperMaterialBoughtMonthly}
                      name="upperMaterialBoughtMonthly"
                      onChange={this.inputHandler}
                    />
                  </div>
                  <div className="col-xs-6 pl-1">
                    <InputLabelAnimation
                      labelText={"Cost per square meter (in ₹)"}
                      required={true}
                      inputValue={upperMaterialCostPerUnit}
                      name="upperMaterialCostPerUnit"
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
                <h5 className="sub-qsn">How many pair of sole do you buy monthly and average cost per pair of sole?</h5>
                <div className="row">
                  <div className="col-xs-6">
                    <InputLabelAnimation
                      labelText={"Number"}
                      required={true}
                      inputValue={solesPairBoughtMonthly}
                      name="solesPairBoughtMonthly"
                      onChange={this.inputHandler}
                    />
                  </div>
                  <div className="col-xs-6 pl-1">
                    <InputLabelAnimation
                      labelText={"Price (in ₹)"}
                      required={true}
                      inputValue={costPerPairSole}
                      name="costPerPairSole"
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
                <h5 className="sub-qsn">How much footwear packing box you buy monthly and cost per box?</h5>
                <div className="row">
                  <div className="col-xs-6">
                    <InputLabelAnimation
                      labelText={"Number"}
                      required={true}
                      inputValue={packagingBoxBoughtMonthly}
                      name="packagingBoxBoughtMonthly"
                      onChange={this.inputHandler}
                    />
                  </div>
                  <div className="col-xs-6 pl-1">
                    <InputLabelAnimation
                      labelText={"Price (in ₹)"}
                      required={true}
                      inputValue={costPerBox}
                      name="costPerBox"
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
                <h5 className="sub-qsn">
                  How much average cost incurred on manufacturing of a single pair of footwear?
                </h5>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <InputLabelAnimation
                      labelText={"Cost per pair of footwear (in ₹)"}
                      required={true}
                      inputValue={costPerPair}
                      name="costPerPair"
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className="btn-save" onClick={this.addApplication}>
                  Save & Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_COST(match.params.custId)).then(({ data }) => {
      if (data.data) {
        let { agraCostAnalysis } = data.data;
        this.setState({ agraCostAnalysis });
      }
    });
  };
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(FootwearBusinessCost);
