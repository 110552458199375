import React, { Component } from "react";
import { API_POST_OVERRIDE_GATING } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";

export default class GatingOverride extends Component {
  gatingOverrideHandler = (item) => {
    let { memberId, closeAction, fetchDetails } = this.props;
    let dataToSend = {
      data: {
        customerId: memberId,
        gatingStatus: item,
      },
    };
    POST(API_POST_OVERRIDE_GATING, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          closeAction();
          fetchDetails();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    let { memberId } = this.props;

    return (
      <div className=" gating-wrapper">
        <h3 className="mb-2">Gating Override</h3>
        Override the gating for
        <div className="primary-color">Member Id {memberId} ?</div>
        <div className="text-center">
          <button
            className="btn reject-btn"
            onClick={() => this.gatingOverrideHandler("Rejected")}
          >
            Reject
          </button>
          <button
            className="btn approve-btn"
            onClick={() => this.gatingOverrideHandler("Approved")}
          >
            Approve
          </button>
        </div>
      </div>
    );
  }
}
