import React from "react";
import PropTypes from 'prop-types';

class GradientLoader extends React.Component {
    render() {
        let {children, initialRender, loaderComponent} = this.props;
        return (
            initialRender
                ? {children}
                : {loaderComponent}
        )
    }
}

GradientLoader.defaultProps = {
    initialRender: false,
};

GradientLoader.propTypes = {
    initialRender: PropTypes.bool,
    children: PropTypes.node,
    loaderComponent: PropTypes.node.isRequired
};

export default GradientLoader;
