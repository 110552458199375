import React, { Component, Fragment } from 'react';
import DocumentBox from './DocumentBox';
import Icon from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';
import { ProgressBar } from '../../../components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../../store/actions';

import {
  getdocument,
  fetchDocuments,
  deleteDocument,
} from './documents.actions';
import ContentLoader from 'react-content-loader';
import { get } from 'lodash';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { angleUp } from 'react-icons-kit/fa/angleUp';
import { angleDown } from 'react-icons-kit/fa/angleDown';
import { Helmet } from 'react-helmet';
// import UploadITR from './UploadITR';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentList: [],
    };
  }
  submitDocuments = async () => {
    let { history, match } = this.props,
      applicationNumber = get(match, 'params.appNo', '');
    history.push(`/Sales/${applicationNumber}/submit`);
  };

  fetchDetails = async () => {
    let { history, actions, match } = this.props,
      { getdocument, fetchDocuments, loaderModal } = actions,
      applicationNumber = get(match, 'params.appNo', '');
    loaderModal(true);
    if (applicationNumber) {
      await fetchDocuments(applicationNumber).then((data) => {
        if (data.status === 'success') {
          this.setState({
            documentName: data.data,
          });
        }
      });
      this.state.documentList.length === 0 &&
        getdocument(applicationNumber)
          .then((data) => {
            data.status === 'success' &&
              this.setState({
                documentList: data.data,
              });
          })
          .catch((err) => {
            console.log(err);
          });
    } else {
      history.push('/Sales/gstn');
    }
    loaderModal(false);
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.fetchDetails();
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  expandHandler = (type) => {
    this.setState((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };
  deleteDocumentHandler = async (docId) => {
    let { deleteDocument } = this.props.actions;
    await deleteDocument(docId);
    this.fetchDetails();
  };
  render() {
    const { documentList, documentName } = this.state,
      { businessDocs = [], profileDocs = [] } = documentList,
      { match } = this.props,
      applicationNumber = get(match, 'params.appNo', '');
    var dataLoad = null;

    if (documentList) {
      let counter = 0;
      dataLoad = () => {
        return (
          <Fragment>
            <Helmet>
              <meta charSet='utf-8' />
              <title>Documents</title>
            </Helmet>
            <div className={'documents-box'}>
              <div
                className='row'
                onClick={() => this.expandHandler(businessDocs?.businessName)}
              >
                
                <div className='col-xs-11'>
                  <h5>{businessDocs?.businessName}</h5>
                </div>
                <div className='col-xs-1'>
                  <span className={'icon'}>
                    <Icon
                      icon={
                        this.state[businessDocs?.businessName]
                          ? angleUp
                          : angleDown
                      }
                      size={22}
                    />
                  </span>
                </div>
              </div>
              <SlideDown
                className={'my-dropdown-slidedown'}
                closed={!this.state[businessDocs?.businessName]}
              >
                <div className='row'>
                  {businessDocs?.documents?.map((doc, index) => {
                    counter = counter + 1;
                    return (
                      <div className='col-xs-12 col-md-6 '>
                        <DocumentBox
                          slidedownNumber={index}
                          key={index}
                          name={'registration_proof'}
                          labelHead={doc.docType}
                          type={doc.docName}
                          uniqueId={counter}
                          entityName={businessDocs?.businessName}
                          {...this.props}
                          deleteDocument={this.deleteDocumentHandler}
                          refreshPage={this.fetchDetails}
                          isBusinessDoc={true}
                          id={businessDocs?.businessId}
                          documentName={
                            documentName?.businessDocuments?.documentResponse
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </SlideDown>
            </div>
            {profileDocs?.map((profileDoc, index) => {
              return (
                <div className={'documents-box'} key={`profile-doc-${index}`}>
                  <div
                    className='row'
                    onClick={() => this.expandHandler(profileDoc?.name)}
                  >
                    <div className='col-xs-11'>
                      <h5>{profileDoc?.name}</h5>
                    </div>
                    <div className='col-xs-1'>
                      <span className={'icon'}>
                        <Icon
                          icon={
                            this.state[profileDoc?.name] ? angleUp : angleDown
                          }
                          size={22}
                        />
                      </span>
                    </div>
                  </div>
                  <SlideDown
                    className={'my-dropdown-slidedown'}
                    closed={!this.state[profileDoc?.name]}
                  >
                    <div className='row'>
                      {profileDoc?.documents?.map((doc, idx) => {
                        counter = counter + 1;
                        return (
                          <div className='col-xs-12 col-md-6'>
                            <DocumentBox
                              slidedownNumber={idx}
                              key={idx}
                              name={'registration_proof'}
                              labelHead={doc.docType}
                              type={doc.docName}
                              uniqueId={counter}
                              entityName={businessDocs?.businessName}
                              {...this.props}
                              deleteDocument={(docId) =>
                                this.deleteDocumentHandler(docId)
                              }
                              refreshPage={() => this.fetchDetails()}
                              id={profileDoc?.profileId}
                              documentName={
                                documentName?.profileDocuments[index]
                                  ?.documentResponse
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </SlideDown>
                </div>
              );
            })}
          </Fragment>
        );
      };
    }

    return (
      <div className={'documents-wrapper'}>
        <ProgressBar active={5} {...this.props} />
        <div className={'content-box'}>
          <Link
            to={`/Sales/${applicationNumber}/references`}
            className={'back-btn-link'}
          >
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={'box-heading'}>Upload Document and Submit</h4>
          {documentList ? dataLoad() : <ContentLoader type={'facebook'} />}
          <div className={'btns-box'}>
            <button
              onClick={this.submitDocuments}
              type={'button'}
              className={'next-btn'}
            >
              Save & Next
            </button>
          </div>
        </div>
        {/* <UploadITR applicationNumber={applicationNumber} /> */}
      </div>
    );
  }
}

const mapStateToProps = ({ documentsReducers }) => ({
  documentList: documentsReducers.documentList,
  documentName: documentsReducers.documentName,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getdocument,
      fetchDocuments,
      deleteDocument,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
