import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { loaderModal } from "../../store/actions";
import { connect } from "react-redux";
import { Error, LoaderModal } from "../../components";
import { bindActionCreators } from "redux";
import OnBoardingDetails from "./OnboardingDetails/OnBoardingDetails";
import BusinessDetails from "./BusinessDetails/BusinessDetails";
import BusinessCostAnalysis from "./FootwearBusinessCost/FootwearBusinessCost";
import BusinessSizeAnalysis from "./BusinessSizeAnalysis/BusinessSizeAnalysis";
import Photo from "./Photo/Photo";
import ClusterPreview from "./ClusterPreview/ClusterPreview";
import BusinessAccountDetails from "./BusinessAccountDetails/BusinessAccountDetails";
import Dashboard from "./Dashboard/Dashboard";
import ImageCapture from "./Photo/ImageCapture";
import BicycleBusinessCost from "./BicycleBusinessCost/BicycleBusinessCost";
import HosieryBusinessCost from "./BicycleBusinessCost/HosieryBusinessCost";
import SewingMachineBusinessCost from './BicycleBusinessCost/SewingMachine'
import GatingCheck from "./GatingCheck/GatingCheck";
import MAHTBusinessCost from "./MAHTBusinessCost/MAHTBusinessCost";
import TermsAndCondition from "../Cluster-Sales/OnboardingDetails/TermsAndCondition";

class ClusterSalesRoutes extends Component {
  componentWillMount() {
    let { userDetails, history } = this.props;
    userDetails.roleName === "UW" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_USER" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_ADMIN" && history.push("/Credit/dashboard")
  }

  render() {
    let { loaderModalStatus, actions, userDetails } = this.props,
      { loaderModal } = actions;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        {userDetails.roleName === "CLUSTER_USER" ? (
          <Switch>
            <Route path="/cluster/dashboard" exact component={Dashboard} />
            <Route path="/cluster/onboarding" exact component={OnBoardingDetails} />
            <Route path="/cluster/terms-and-condition" exact component={TermsAndCondition} />
            <Route path="/cluster/:custId/onboarding" exact component={OnBoardingDetails} />
            <Route path="/cluster/:custId/business-details/:clusterType" exact component={BusinessDetails} />
            <Route path="/cluster/:custId/business-size/:clusterType" exact component={BusinessSizeAnalysis} />
            <Route path="/cluster/:custId/photo/:clusterType" exact component={Photo} />
            <Route path="/cluster/:custId/capture/:clusterType" exact component={ImageCapture} />
            <Route path="/cluster/:custId/cluster-preview/:clusterType" exact component={ClusterPreview} />
            <Route
              path="/cluster/:custId/business-account-details/:clusterType"
              exact
              component={BusinessAccountDetails}
            />
            <Route path="/cluster/:custId/gating-status/:clusterType" exact component={GatingCheck} />
            <Route path="/cluster/:custId/business-cost/Ludhiana-Hosiery-and-Readymade-Garment-Cluster" exact component={HosieryBusinessCost} />
            <Route path="/cluster/:custId/business-cost/Ludhiana-Sewing-Machine-Cluster" exact component={SewingMachineBusinessCost} />
            <Route path="/cluster/:custId/business-cost/Agra-Footwear-Cluster" exact component={BusinessCostAnalysis} />
            <Route path="/cluster/:custId/business-cost/Ludhiana-Bicycle-and-Parts-cluster" exact component={BicycleBusinessCost} />
            <Route path="/cluster/:custId/business-cost/Ludhiana-Machine-And-Hand-Tools" exact component={MAHTBusinessCost} />
            <Redirect from={"/cluster/"} exact to={`/cluster/onboarding`} />
            <Route
              path="/cluster/"
              render={(props) => <Error loaderModal={(status) => loaderModal(status)} {...props} />}
            />
          </Switch>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterSalesRoutes);
