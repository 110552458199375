import * as CAMConstant from "./cam.actions.constant";
import { GET } from "../../../utils/webAPI.service";
import {
  API_GET_CAM_DATA,
  API_GET_CREDIT_CAPACITY_DETAILS,
  API_GET_SPOC_DETAILS,
  API_BANK_DETAILS,
  API_GET_CASE_HIGHLIGHTS,
  API_GET_DEVIATION_MASTER,
  API_GET_CURRENT_OBLIGATIONS,
  API_FETCH_REFERENCES,
  API_GET_DEVIATIONS,
  API_FETCH_FORM_FILLED_BY,
  API_GET_APPLICANTS_SUMMARY,
  API_GET_BANKING_CHEQUE_ANALYSIS,
  API_GET_BANKING_CONCENTRATION_ANALYSIS,
  API_GET_CONCENTRATION_RISK_DETAILS,
  API_GST_AND_BANKING_DETAILS,
  API_GET_GST_TREND_ANALYSIS,
  API_GET_TURNOVER_AND_PURCHASES_DETAILS,
  API_GET_AGGREGATE_TREND_ANALYSIS,
  API_GET_BUSINESS_DETAILS,
  API_GET_APPLICATION_LOGIN_DETAILS,
  API_GET_APPLICATION_STATUS,
  API_GET_BUSINESS_AND_PROFILE_SUMMARY,
  API_FETCH_TURNOVER_DETAILS,
  API_GET_MARGIN_DATA
} from "../../../utils/APIUrls";

const getCamData = appNo => dispatch => {
  return GET(API_GET_CAM_DATA(appNo)).then(({ data, status }) => {
    if (data) {
      dispatch({ type: CAMConstant.GET_CAM_DATA, data: data.data });
      return data;
    } else {
      throw data;
    }
  });
};
const getCreditCapacityDetails = applicationNumber => {
  return dispatch => {
    return GET(API_GET_CREDIT_CAPACITY_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.data) {
          dispatch({
            type: CAMConstant.SET_CREDIT_CAPACITY_DETAILS,
            creditCapacityData: data.data
          });
        }
        return data;
      })
      .catch(err => {
        throw err;
      });
  };
};

const getSpocDetails = applicationNumber => dispatch => {
  return GET(API_GET_SPOC_DETAILS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_SPOC_DETAILS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getBankDetails = applicationNumber => dispatch => {
  return GET(API_BANK_DETAILS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_BANK_DETAILS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getCaseHighLights = applicationNumber => dispatch => {
  return GET(API_GET_CASE_HIGHLIGHTS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_CASE_HIGHLIGHTS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getDecisionSummary = applicationNumber => dispatch => {
  return GET(API_GET_DEVIATION_MASTER(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_DECISION_SUMMARY, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getCurrentObligations = applicationNumber => dispatch => {
  return GET(API_GET_CURRENT_OBLIGATIONS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_CURRENT_OBLIGATIONS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getReferenceDetails = applicationNumber => dispatch => {
  return GET(API_FETCH_REFERENCES(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_REFERENCES_DETAILS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getDeviations = applicationNumber => dispatch => {
  return GET(API_GET_DEVIATIONS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.GET_DEVIATIONS, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getApplicationFilledBy = applicationNumber => dispatch => {
  return GET(API_FETCH_FORM_FILLED_BY(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: CAMConstant.APPLICATION_FILLED_BY, data: data.data });
      }
    })
    .catch(err => {
      throw err;
    });
};
const getApplicantsSummary = appNo => dispatch => {
  return GET(API_GET_APPLICANTS_SUMMARY(appNo))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: CAMConstant.GET_APPLICANTS_SUMMARY,
          data: data.data
        });
        return data;
      } else {
        throw data;
      }
    })
    .catch(err => {
      throw err;
    });
};
const getBankingChequeAnalysis = appNo =>dispatch=>{
  return GET(API_GET_BANKING_CHEQUE_ANALYSIS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_BANKING_CHEQUE_ANALYSIS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getBankingConcentrationAnalysis = appNo =>dispatch=>{
  return GET(API_GET_BANKING_CONCENTRATION_ANALYSIS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_BANKING_CONCENTRATION_ANALYSIS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getConcentrationRiskDetails = appNo =>dispatch=>{
  return GET(API_GET_CONCENTRATION_RISK_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_CONCENTRATION_RISK_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getGSTAndBankingDetails = appNo =>dispatch=>{
  return GET(API_GST_AND_BANKING_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_GST_AND_BANKING_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getGstTrendDetails = appNo =>dispatch=>{
  return GET(API_GET_GST_TREND_ANALYSIS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_GST_TREND_ANALYSIS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getTurnoverAndPurchaseDetails = appNo =>dispatch=>{
  return GET(API_GET_TURNOVER_AND_PURCHASES_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_TURNOVER_AND_PURCHASES_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getAggregateTrendAnalysis = appNo =>dispatch=>{
  return GET(API_GET_AGGREGATE_TREND_ANALYSIS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_AGGREGATE_TREND_ANALYSIS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getBusinessDetails = appNo =>dispatch=>{
  return GET(API_GET_BUSINESS_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_BUSINESS_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getApplicationLoginDetails = appNo =>dispatch=>{
  return GET(API_GET_APPLICATION_LOGIN_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_APPLICATION_LOGIN_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getApplicationStatus = appNo =>dispatch=>{
  return GET(API_GET_APPLICATION_STATUS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_APPLICATION_STATUS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getBusinessAndProfileSummary = appNo =>dispatch=>{
  return GET(API_GET_BUSINESS_AND_PROFILE_SUMMARY(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_BUSINESS_AND_PROFILE_SUMMARY , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getTurnoverDetails = appNo =>dispatch=>{
  return GET(API_FETCH_TURNOVER_DETAILS(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_TURNOVER_DETAILS , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
const getMarginData = appNo =>dispatch=>{
  return GET(API_GET_MARGIN_DATA(appNo)).then(({data})=>{
    if(data.data){
      dispatch({type:CAMConstant.GET_MARGIN_DATA , data:data.data})
    }
  }).catch(err=>{
    throw err
  })
}
export {
  getCamData,
  getCreditCapacityDetails,
  getSpocDetails,
  getBankDetails,
  getCaseHighLights,
  getDecisionSummary,
  getCurrentObligations,
  getReferenceDetails,
  getDeviations,
  getApplicationFilledBy,
  getApplicantsSummary,
  getBankingChequeAnalysis,
  getBankingConcentrationAnalysis,
  getConcentrationRiskDetails,
  getGSTAndBankingDetails,
  getGstTrendDetails,
  getTurnoverAndPurchaseDetails,
  getAggregateTrendAnalysis,
  getBusinessDetails,
  getApplicationLoginDetails,
  getApplicationStatus,
  getBusinessAndProfileSummary,
  getTurnoverDetails,
  getMarginData
};
