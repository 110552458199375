import React, { Component } from "react";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import InHouseSales from "./InHouseSales";
import ThirdPartyChannel from "./ThirdPartyChannel";
import { API_POST_ADD_SOURCING_DETAILS } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { getSourcingDetails } from "./sourcingDetails.actions";
import { get } from "lodash";
import { changeInputValue } from "./sourcingDetails.actions";
import { resetLoanTenureState } from "../LoanTenure/loanTenure.actions";
import { resetGstnDetailsState } from "../GSTNDetailsPage/GSTNDetails.actions";
import { resetGstnAddressState } from "../GstnUpdatedAddress/GstnAddress.actions";
import { resetApplicantGuideState } from "../ApplicantGuide/applicantGuide.actions";
import { resetApplicantsState } from "../Applicants/applicants.actions";
import { resetBankingState } from "../Banking/banking.actions";
import { resetSourcingDetailsState } from "../SourcingDetails/sourcingDetails.actions";

class SourcingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      hierarchy: "In House Sales Team",
      rmName: "",
    };
  }
  inputHandler = (e) => {
    let { actions } = this.props,
      { changeInputValue } = actions;
    let value = e.target.value;
    let name = e.target.name;
    changeInputValue(value, name);
  };
  submitForm = (e) => {
    e.preventDefault();
    const { history, match, sourcingDetails, actions } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      { rmName, hierarchy } = this.state;
    let data = {
      rmName,
      applicationNumber,
      sourcingHierarchy: hierarchy,
      ...sourcingDetails,
    };
    actions.loaderModal(true);
    POST(API_POST_ADD_SOURCING_DETAILS, { data })
      .then(({ data }) => {
        if (data.status === "success") {
          history.push(`/Sales/${applicationNumber}/gstn`);
        } else if (data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
        actions.loaderModal(false);

        throw err;
      });
  };

  componentWillMount() {
      const {
        actions,
        loanDetails,
        gstnDetails,
        businessDetailsData,
        shareholdingDetails,
        bankingDetails,
        profileDetails,
        sourcingDetails,
      } = this.props,
      {
        resetLoanTenureState,
        resetGstnDetailsState,
        resetGstnAddressState,
        resetApplicantGuideState,
        resetApplicantsState,
        resetBankingState,
        resetSourcingDetailsState,
      } = actions,
      newApplication = get(this, "props.location.state.newApplication", false),
      loanAmount = get(loanDetails, "loanAmount", 0),
      shareholdingList = get(shareholdingDetails, "shareholdingList", []);
    if (!newApplication) {
      sourcingDetails &&  resetSourcingDetailsState();
      loanAmount && resetLoanTenureState();
      gstnDetails?.length && resetGstnDetailsState();
      businessDetailsData && resetGstnAddressState();
      shareholdingList?.length && resetApplicantGuideState();
      profileDetails?.length && resetApplicantsState();
      bankingDetails?.length && resetBankingState();
      
    }
    window.scroll(0, 0);
    
  }

  componentDidMount = async () => {
    const { actions, match, sourcingDetails, userDetails } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    actions.loaderModal(true);
    await actions.getSourcingDetails(applicationNumber);
    sourcingDetails.hierarchy &&
      this.setState({
        hierarchy: sourcingDetails.hierarchy,
      });
    this.setState({
      rmName: `${userDetails.firstName} ${userDetails.lastName}`,
    });
    actions.loaderModal(false);
  };

  render() {
    let { sourcingDetails } = this.props,
      { hierarchy } = this.state;
    return (
      <div className="sourcing_details-wrapper">
        <div className={"content-box"}>
          <Link to={"/Sales/post_login"} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>Add sourcing details</h4>
          <h4 className="sub-heading">Select hierarchy</h4>
          <div className="toggle__wrapper">
            <div
              className={
                hierarchy === "In House Sales Team"
                  ? "active-button"
                  : "normal-button"
              }
              onClick={() =>
                this.setState({ hierarchy: "In House Sales Team" })
              }
            >
              In-house Sales Team
            </div>
            <div
              className={
                hierarchy === "Third Party Channel"
                  ? "active-button"
                  : "normal-button"
              }
              onClick={() =>
                this.setState({ hierarchy: "Third Party Channel" })
              }
            >
              Third Party Channel
            </div>
          </div>
          <form onSubmit={this.submitForm}>
            <div>
              <h4 className="sub-heading">Fill Details</h4>
              {hierarchy === "In House Sales Team" ? (
                <InHouseSales
                  inputHandler={this.inputHandler}
                  inputs={this.state}
                  sourcingDetails={sourcingDetails}
                />
              ) : (
                <ThirdPartyChannel
                  inputHandler={this.inputHandler}
                  inputs={this.state}
                  sourcingDetails={sourcingDetails}
                />
              )}
            </div>
            <div className={"text-center"}>
              <button className={"save-btn"} type={"submit"}>
                Save & Next
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  sourcingDetailsReducer,
  data,
  loanTenure,
  gstnDetailsReducers,
  gstnAddressReducers,
  applicantsReducers,
  applicantGuideReducers,
  bankingReducers,
}) => ({
  userDetails: data.userDetails,
  sourcingDetails: sourcingDetailsReducer.sourcingDetails,
  loanDetails: loanTenure.loanDetails,
  gstnDetails: gstnDetailsReducers.gstnDetails,
  businessDetailsData: gstnAddressReducers.businessDetailsData,
  profileDetails: applicantsReducers.profileDetails,
  shareholdingDetails: applicantGuideReducers.shareholdingDetails,
  bankingDetails: bankingReducers.bankingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSourcingDetails,
      loaderModal,
      changeInputValue,
      resetLoanTenureState,
      resetGstnDetailsState,
      resetGstnAddressState,
      resetApplicantGuideState,
      resetApplicantsState,
      resetBankingState,
      resetSourcingDetailsState,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SourcingDetails);
