import * as DashboardConstant from './dashboard.actions.constant';
import { UPDATE_FILTER_OPTIONS } from "../../../components/Header/header.action.constant";

const setSearchFilters = (list) => ({
    type: UPDATE_FILTER_OPTIONS,
    data: list
});

const setSearchFilterStatus = (name) => ({
    type: DashboardConstant.SET_SEARCH_BY_STATUS,
    name
});

export {
    setSearchFilters,
    setSearchFilterStatus
}
