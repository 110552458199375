import React, { Component } from "react";
import Icon from "react-icons-kit";
import { ic_check } from "react-icons-kit/md/ic_check";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import { connect } from "react-redux";
import { get } from "lodash";
import { changeFileValues, deleteFileRow } from "./banking.actions";
import { bindActionCreators } from "redux";
import { updateErrorMessage } from "../../../store/actions";
import { CheckBox } from "../../../components";
class BankingRow extends Component {
  state = {
    isScannedPDF: false,
    isPrimaryBankAccount: false,
  };
  selectFile = (e) => {
    this.props.actions.updateErrorMessage("");
    let { formIndex, fileIndex, actions } = this.props,
      { changeFileValues, updateErrorMessage } = actions,
      file = e.target.files,
      fileName =
        file.length > 1
          ? `${file.length} Files Selected`
          : e.target.value.replace(/.*[\\]/, "");
    changeFileValues("file", file, formIndex, fileIndex);
    changeFileValues("fileName.fileName", fileName, formIndex, fileIndex);
    changeFileValues("isUploaded", false, formIndex, fileIndex);

    updateErrorMessage("");
  };
  inputHandler = (e) => {
    this.props.actions.updateErrorMessage("");
    const { actions, formIndex, fileIndex } = this.props,
      { changeFileValues, updateErrorMessage } = actions,
      name = e.target.name,
      value = e.target.value;
    changeFileValues(name, value, formIndex, fileIndex);
    updateErrorMessage("");
  };
  checkHandler = (e) => {
    const { name, checked } = e.target;
    this.setState({
      [name]: checked,
    });

    let { actions, formIndex, fileIndex } = this.props;
    let { changeFileValues } = actions;
    changeFileValues(name, checked, formIndex, fileIndex);
  };
  deleteRow = () => {
    let { actions, formIndex, fileIndex } = this.props,
      { deleteFileRow } = actions;
    deleteFileRow(formIndex, fileIndex);
  };
  render() {
    let { isScannedPDF } = this.state;
    let { bankingDetails, fileIndex, formIndex } = this.props,
      fileName = get(
        bankingDetails,
        `[${formIndex}].files[${fileIndex}].fileName.fileName`,
        ""
      ),
      isUploaded = get(
        bankingDetails,
        `[${formIndex}].files[${fileIndex}].isUploaded`,
        false
      );
    isScannedPDF = get(
      bankingDetails,
      `[${formIndex}].files[${fileIndex}].isScannedPDF`,
      false
    );
    const isPrimaryBankAccount = get(
      bankingDetails,
      `[${formIndex}].files[${fileIndex}].isPrimaryBankAccount`,
      false
    );

    return (
      <div>
        <div className={"inputs-row"}>
          <input
            onClick={() => this.inputFile.click()}
            type={"text"}
            className={"upload-button"}
            placeholder={"Browse from files"}
            readOnly
            value={fileName.slice(0, 25)}
          />
          <input
            ref={(ref) => (this.inputFile = ref)}
            onChange={this.selectFile}
            type={"file"}
            accept="application/pdf"
            multiple
          />
          <input
            type={"password"}
            className={"input-field"}
            onChange={this.inputHandler}
            placeholder={"Password for PDF"}
            name={"password"}
            disabled={isUploaded}
          />
          {/* <button disabled type={'button'}>Upload</button> */}
          <div className={"actions-box"}>
            <span className={`success-icon ${isUploaded ? "visible" : ""}`}>
              <Icon icon={ic_check} size={20} />
            </span>
            <span onClick={() => this.deleteRow()} className={"delete-icon"}>
              <Icon icon={ic_delete_forever} size={28} />
            </span>
          </div>
        </div>
        <input
          type={"password"}
          className={"input-field mobile-password"}
          onChange={this.inputHandler}
          placeholder={"Password for PDF"}
          name={"password"}
          disabled={isUploaded}
        />
        <div className=" row  ">
          <div className={" col-md-6 mt-5"}>
            <ul>
              <CheckBox
                labelColor={"#000000"}
                checkboxColor={"#000000"}
                htmlFor={"filter1"}
                label={"Is this pdf file scanned ?"}
                name="isScannedPDF"
                value={true}
                checked={isScannedPDF}
                onChange={this.checkHandler}
              />
            </ul>
          </div>
        </div>
        <div className=" row  ">
          <div className={" col-md-6 mt-5"}>
            <ul>
              <CheckBox
                labelColor={"#000000"}
                checkboxColor={"#000000"}
                htmlFor={"filter2"}
                label={"Is primary bank account ?"}
                name="isPrimaryBankAccount"
                value={true}
                checked={isPrimaryBankAccount}
                onChange={this.checkHandler}
              />
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ bankingReducers }) => ({
  bankingDetails: bankingReducers.bankingDetails,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteFileRow,
      changeFileValues,
      updateErrorMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankingRow);
