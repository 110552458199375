import React, { Component, Fragment } from "react";
import { Icon } from "react-icons-kit";
import { camera } from "react-icons-kit/ikons/camera";
import { withMinus } from "react-icons-kit/entypo/withMinus";
import { GET } from "../../../utils/webAPI.service";
import { API_GET_DELETE_DOCUMENT } from "../../../utils/APIUrls";
export default class DocumentBox extends Component {
  state = {
    docName: null,
  };
  captureImage = () => {
    let { match, history, docType, entity } = this.props,
      { docName } = this.state;
    history.push(`/cluster/${match.params.custId}/capture/${match.params.clusterType}?docName=${docName}&docType=${docType}&entity=${entity}`);
  };
  deleteAccount = (docId) => {
    let { match, actions } = this.props;
    actions.loaderModal(true);
    GET(API_GET_DELETE_DOCUMENT(match.params.custId, docId))
      .then(({ data }) => {
        if (data.status === "success") {
          actions.getDocuments(match.params.custId);
        }
        alert(data.message)
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  render() {
    let { docType, docsName, fileDetails } = this.props;
    return (
      <div className={"document-box"}>
        <div className={"label1"}>
          <p>{docType}</p>
        </div>
        {docsName && (
          <select
            className={"select-box"}
            onChange={(e) => this.setState({ docName: e.target.value })}
            name="bankName"
          >
            <option>Select Document Type</option>
            {docsName.map((el, index) => {
              return (
                <option key={`bank-list-${index}`} value={el}>
                  {el}
                </option>
              );
            })}
          </select>
        )}

        <Fragment>
          {fileDetails &&
            fileDetails.map((el, index) => {
              return el ? (
                <div className="fileContainer" key={el.docId}>
                  <div className="file-wrapper">
                    <p className="file-text">{el.fileName}</p>
                  </div>
                  <Icon
                    size={24}
                    icon={withMinus}
                    className="remove-icon"
                    onClick={() => this.deleteAccount(el.docId)}
                  />
                </div>
              ) : null;
            })}

          <label className="custom-file-upload btn upload-button" onClick={this.captureImage}>
            <Icon size={21} icon={camera} style={{ color: "#FFFFFF", paddingRight: "10px" }} />
            Upload photo
          </label>
        </Fragment>
      </div>
    );
  }
}
