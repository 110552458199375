import React, { Component } from "react";
import { FaRupeeSign } from "../../../../node_modules/react-icons/fa";
import { fetchBalanceSheet } from "./ITR.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { BalanceSheetColumns, BalanceSheetSubColumns } from "./ITR.config.js";
import Highcharts from "highcharts";
import { get } from "lodash";
import { loaderModal } from "../../../store/actions";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { forward } from "react-icons-kit/entypo/forward";
import Icon from "react-icons-kit";

class BalanceSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFigure: "lacs",
    };
  }
  componentDidUpdate() {
    let { balanceSheetData } = this.props;
    balanceSheetData && this.highChartRenderer(balanceSheetData);
  }
  highChartRenderer(balanceSheetData) {
    balanceSheetData.map((rowData) => {
      Highcharts.chart(`linechart${rowData.Figures}`, {
        chart: {
          type: "line",
          height: 50,
          width: 95,
          backgroundColor: "transparent",
        },
        title: false,
        credits: {
          enabled: false,
        },

        yAxis: {
          visible: false,
          title: false,
        },
        tooltip: {
          enabled: true,
          style: {
            width: 100,
            "white-space": "normal !important",
          },
        },

        xAxis: {
          visible: false,
        },
        legend: {
          enabled: false,
        },

        plotOptions: {
          series: {
            marker: {
              radius: 3,
            },
            label: {
              connectorAllowed: false,
            },
            pointStart: 2018,
          },
        },
        series: [
          {
            name: "",
            color: "#3670CC",
            data: Object.keys(rowData.yearlyDetails).map(
              (el) => rowData.yearlyDetails[el]
            ),
          },
        ],
      });
    });
  }

  componentDidMount = async () => {
    const { actions, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    actions.loaderModal(true);
    actions.fetchBalanceSheet(applicationNumber);

    actions.loaderModal(false);
  };

  render() {
    let { activeFigure } = this.state;
    let { balanceSheetData } = this.props;
    return (
      <div className="profitloss-wrapper">
        <nav className="profitloss-nav">
          <div className="profitloss-nav-left">Balance Sheet</div>
          <div className="profitloss-nav-right">
            <span className="profitloss-nav-right_figures">
              All figures in
              <FaRupeeSign className="profitloss-nav-right_figures_size" />
            </span>
            <div className="profitloss-nav-right_flex-buttons">
              <div
                onClick={() => {
                  this.setState({ activeFigure: "units" });
                }}
                className={
                  activeFigure === "units" ? "active-button" : "normal-button"
                }
              >
                units
              </div>
              <div
                onClick={() => {
                  this.setState({ activeFigure: "lacs" });
                }}
                className={
                  activeFigure === "lacs" ? "active-button" : "normal-button"
                }
              >
                lacs
              </div>
              <div
                onClick={() => {
                  this.setState({ activeFigure: "crores" });
                }}
                className={
                  activeFigure === "crores" ? "active-button" : "normal-button"
                }
              >
                crores
              </div>
            </div>
            <span className="profitloss-nav-right_logo">
              <Icon icon={forward} size={22} />
            </span>
            <span className="profitloss-nav-right_logo profitloss-nav-right_logo_size">
              <Icon icon={ic_mode_edit} size={22} />
            </span>
          </div>
        </nav>
        <div className={"balanceSheet-main-table "}>
          <ReactTable
            data={balanceSheetData || []}
            columns={
              balanceSheetData ? BalanceSheetColumns(balanceSheetData) : []
            }
            minRows={0}
            showPagination={false}
            SubComponent={(row) => {
              return (
                <div className={"balanceSheet-sub-table"}>
                  <ReactTable
                    data={row.original.subRows}
                    columns={
                      row.original.subRows
                        ? BalanceSheetSubColumns(row.original.subRows)
                        : []
                    }
                    minRows={0}
                    showPagination={false}
                  />
                  {row.original.subRows?.length === 0 && (
                    <div className="no-rows-div">No Rows Found</div>
                  )}
                </div>
              );
            }}
          />
          {balanceSheetData.length === 0 && (
            <div className="no-rows-div">No Rows Found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ itrReducer }) => ({
  balanceSheetData: itrReducer.balanceSheetData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchBalanceSheet,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
