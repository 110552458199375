import React, { PureComponent } from "react";
import Modal from "react-modal";
import { CheckBox } from "../../../components";
import { cross } from "react-icons-kit/metrize/cross";
import Icon from "react-icons-kit";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addUserDetails, sendMessage, updateUserStatus, fetchUserDetails, getActiveLenderRoles } from "../admin.action";
import { loaderModal } from "../../../store/actions";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_RESET_PASSWORD } from "../../../utils/APIUrls";
class RoleuserModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      switchStatus: false,
      addUserModal: false,
      emailInvitation: false,
      email: "",
      roleName: "",
      password: "",
      mobile: "",
      middleName: "",
      lastName: "",
      firstName: "",
      designation: "",
      employeeId: "",
      subject: "",
      message: "",
    };
  }
  setEmailInvitation = () => {
    let status = this.state.emailInvitation;
    this.setState({
      emailInvitation: !status,
    });
  };
  modalFormSubmit = async (e) => {
    e.preventDefault();
    let { loaderModal } = this.props.actions;
    let { addUserDetails } = this.props.actions;
    let {
      email,
      roleName,
      password,
      mobile,
      middleName,
      lastName,
      firstName,
      designation,
      employeeId,
      emailInvitation,
      hierarchyLevel,
    } = this.state;
    let data = {
      data: {
        addUserRequest: {
          email,
          roleName,
          password,
          mobile,
          middleName,
          lastName,
          isNotifyEmail: null,
          firstName,
        },
        designation,
        employeeId,
        hierarchyLevel,
        isEmailInvite: emailInvitation,
      },
    };
    loaderModal(true);
    await addUserDetails(data).then((data) => {
      if (data.status === "success") {
        this.props.modalHandler(null, "addUser", false);
      } else {
        alert(data.message);
      }
    });
    loaderModal(false);
  };
  inputHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === "mobile") {
      let reg = /^[0-9]*$/;
      if (reg.test(value)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  sendMessageHandler = () => {
    let { sendMessage, loaderModal } = this.props.actions;
    let { subject, message } = this.state;
    let data = {
      data: {
        cc: this.props.userValue.email,
        subject,
        contents: message,
        userId: [this.props.userValue.userId],
      },
    };
    loaderModal(true);
    sendMessage({ ...data }).then(({ message }) => {
      alert(message);
      this.props.modalHandler(null, "message", false);
      loaderModal(false);
    });
  };
  userStatusHandler = (email, status) => {
    let { updateUserStatus, loaderModal, fetchUserDetails } = this.props.actions;
    let data = {
      data: {
        email,
        status,
      },
    };
    loaderModal(true);
    updateUserStatus({ ...data }).then(({ message }) => {
      alert(message);
      this.props.modalHandler(null, "suspend", false);
      fetchUserDetails().then(() => {
        loaderModal(false);
      });
    });
  };
  updatePasswordHandler = () => {
    let { password } = this.state,
      { userValue } = this.props,
      { email, mobile } = userValue;
    var dataToSend = {
      data: {
        email,
        mobile,
        password,
      },
    };
    POST(API_POST_RESET_PASSWORD, dataToSend)
      .then(({ data }) => {
        alert(data.message);
        if (data.status === "success") {
          this.props.modalHandler(null, "changePassword", false);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    let { activeLenderRolesData, userValue, hierarchyData } = this.props;
    return (
      <div>
        <Modal
          parentSelector={() => document.querySelector(".admin-wrapper")}
          isOpen={this.props.addUserModal}
          onRequestClose={null}
          className={"roles-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4 style={{ marginLeft: "16px" }}>User Details</h4>
            <span onClick={() => this.props.modalHandler(null, "addUser", false)} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <form className={"form-box"} onSubmit={this.modalFormSubmit}>
            <div className={"input-row"}>
              <label className={"input-label"} style={{ marginLeft: "20px" }}>
                User's Name
              </label>

              <div className={"row"}>
                <div className={"col-md-4"}>
                  <input
                    type={"text"}
                    className={"input-field"}
                    required
                    name={"firstName"}
                    placeholder={"Enter First Name"}
                    onChange={this.inputHandler}
                  />
                </div>
                <div className={"col-md-4"}>
                  <input
                    type={"text"}
                    className={"input-field"}
                    name={"middleName"}
                    placeholder={"Enter Middle Name"}
                    onChange={this.inputHandler}
                  />
                </div>
                <div className={"col-md-4"}>
                  <input
                    type={"text"}
                    className={"input-field"}
                    required
                    name={"lastName"}
                    placeholder={"Enter Last Name"}
                    onChange={this.inputHandler}
                  />
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>E-mail Address</label>
                <input
                  type={"email"}
                  className={"input-field"}
                  required
                  name={"email"}
                  placeholder={"Enter E-mail Address"}
                  onChange={this.inputHandler}
                />
              </div>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Password</label>
                <input
                  type={"password"}
                  className={"input-field"}
                  required
                  name={"password"}
                  placeholder={"Enter Password"}
                  onChange={this.inputHandler}
                />
              </div>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Mobile Number</label>
                <input
                  type={"text"}
                  className={"input-field"}
                  required
                  name={"mobile"}
                  placeholder={"Enter Mobile Number"}
                  onChange={this.inputHandler}
                  value={this.state.mobile}
                  maxLength={10}
                />
              </div>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Employee ID</label>
                <input
                  type={"text"}
                  className={"input-field"}
                  required
                  name={"employeeId"}
                  placeholder={"Enter Employee ID"}
                  onChange={this.inputHandler}
                />
              </div>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Designation</label>
                <input
                  type={"text"}
                  className={"input-field"}
                  required
                  name={"designation"}
                  placeholder={"Enter Designation"}
                  onChange={this.inputHandler}
                />
              </div>
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Roles</label>
                <select className={"select-box"} name={"roleName"} required onChange={this.inputHandler}>
                  <option value={""}>Select Role</option>
                  {activeLenderRolesData.map((el, index) => {
                    return <option value={el.roleName}>{el.roleName}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-6 input-row"}>
                <label className={"input-label"}>Hierarchy Level</label>
                <select className={"select-box"} name={"hierarchyLevel"} required onChange={this.inputHandler}>
                  <option value={""}>Select Hierarchy Level</option>
                  {hierarchyData.map((el, index) => {
                    return <option value={el}>{el}</option>;
                  })}
                </select>
              </div>
              <div style={{ marginTop: "40px" }} className={"col-md-6 input-row"}>
                <CheckBox
                  labelColor={"#555555"}
                  checkboxColor={"#001061"}
                  checked={this.state.emailInvitation}
                  labelClass={"input-label"}
                  name={"isNotifyEmail"}
                  htmlFor={"email_invite"}
                  label={"Send e-mail Invite"}
                  onChange={this.setEmailInvitation}
                />
              </div>
            </div>
            <div className={"btns-box"}>
              <button type={"submit"} className={"confirm-btn"}>
                Add User
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          parentSelector={() => document.querySelector(".admin-wrapper")}
          isOpen={this.props.sendMessageModal}
          onRequestClose={null}
          className={"roles-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4>Send Message</h4>
            <span onClick={() => this.props.modalHandler(null, "message", false)} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <div className="userName">
            User: {this.props.userValue.firstName} {this.props.userValue.lastName}
          </div>
          <input
            type={"text"}
            className={"input-field"}
            required
            name={"subject"}
            placeholder={"Subject"}
            onChange={this.inputHandler}
          />
          <textarea
            rows={6}
            name={"message"}
            className={"input-field mt-3"}
            style={{ resize: "none" }}
            placeholder={"Write your message here..."}
            onChange={this.inputHandler}
          />
          <div className="btn-wrapper">
            <button className="btn" onClick={() => this.sendMessageHandler()}>
              Send Message
            </button>
          </div>
        </Modal>
        <Modal
          parentSelector={() => document.querySelector(".admin-wrapper")}
          isOpen={this.props.suspendModal}
          onRequestClose={() => this.props.modalHandler(null, "suspend", false)}
          className={"roles-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4>Status</h4>
            <span onClick={() => this.props.modalHandler(null, "suspend", false)} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <div className="userName">
            User: {this.props.userValue.firstName} {this.props.userValue.lastName}
          </div>
          <div>
            <p>
              Do you want to change status from <span style={{ color: "#E2574C" }}>{userValue.status}</span> to{" "}
              <span style={{ color: "#00CDBE" }}>{userValue.statusValue}</span>?
            </p>
            <div className="btn-wrapper">
              <button className="btn" onClick={() => this.userStatusHandler(userValue.email, userValue.statusValue)}>
                {userValue.statusValue}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          parentSelector={() => document.querySelector(".admin-wrapper")}
          isOpen={this.props.changePassModal}
          onRequestClose={null}
          className={"roles-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <div className={"box-header"}>
            <h4>Change Password</h4>
            <span onClick={() => this.props.modalHandler(null, "changePassword", false)} className={"cross-icon"}>
              <Icon icon={cross} size={26} />
            </span>
          </div>
          <div className="userName">
            User: {this.props.userValue.firstName} {this.props.userValue.lastName}
          </div>
          <input
            type={"text"}
            className={"input-field"}
            required
            name={"password"}
            placeholder={"Please Enter Password"}
            onChange={this.inputHandler}
          />
          <div className="password-validation__text">Minimum 8 characters</div>
          <div className="btn-wrapper">
            <button className="btn" onClick={() => this.updatePasswordHandler()}>
              Confirm
            </button>
          </div>
        </Modal>
      </div>
    );
  }
  componentDidMount = () => {
    let { getActiveLenderRoles } = this.props.actions;
    getActiveLenderRoles();
  };
}
const mapStateToProps = ({ AdminResponse }) => ({
  updateRoleStatusData: AdminResponse.updateRoleStatusData,
  activeLenderRolesData: AdminResponse.activeLenderRolesData,
  hierarchyData: AdminResponse.hierarchyData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addUserDetails,
      loaderModal,
      sendMessage,
      updateUserStatus,
      fetchUserDetails,
      getActiveLenderRoles,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleuserModal);
