import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import {
  API_GET_BUSINESS_COST_ANALYSIS,
  API_GET_BUSINESS_DETAILS,
} from "../../../../../utils/APIUrls";
import { GET } from "../../../../../utils/webAPI.service";
import { bicyclePartsArray } from "../businessCostAnalysis.config";

export default class BicycleBusinessCostAnalysis extends Component {
  state = {};
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_DETAILS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.businessName) {
        let { businessName } = data.data;
        this.setState({ businessName });
      }
    });
    GET(API_GET_BUSINESS_COST_ANALYSIS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.bicycleCostAnalysis) {
        let { bicycleCostAnalysis } = data.data;
        this.setState({ bicycleCostAnalysis });
      }
    });
  };

  render() {
    let { bicycleCostAnalysis = {}, businessName } = this.state,
      {
        bicycleParts,
        customerAndSupplyLinkage,
        manufacturingUnit,
        materialProportion,
        monthlyProduction,
      } = bicycleCostAnalysis,
      { screenRenderHandler } = this.props;

    const monthlyProductionList = {},
      materialProportionList = {},
      bicyclePartsList = {};
    Object.entries(monthlyProduction || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (monthlyProductionList[key] = value));

    Object.entries(materialProportion || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (materialProportionList[key] = value));

    Object.entries(bicycleParts || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (bicyclePartsList[key] = value));

    var materialProportionListItems = Object.keys(
      materialProportionList || {}
    ).map((key) => {
      return (
        <div key={key}>
          {bicyclePartsArray.map((part, i) => {
            if (key === part.name) {
              return (
                <div className={"box-wrapper mt-20"}>
                  <h4 className="box_sub_heading mt-0" key={i}>
                    {part.label}
                  </h4>
                  <div className={"row"}>
                    <div className={"col-sm-4 bold"}>
                      Monthly sales / production
                      <div>
                        {materialProportionList[key]?.steelPurchased} units
                      </div>
                    </div>
                    <div className={"col-sm-4 bold"}>
                      Average sales price per unit
                      <div>
                        {" "}
                        ₹ {materialProportionList[key]?.averagePurchase}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return null
          })}
        </div>
      );
    });

    var monthlyProductionItemsList = Object.keys(
      monthlyProductionList || {}
    ).map((key) => {
      return (
        <div className={"box-wrapper mt-20"} key={key}>
          {bicyclePartsArray.map((part, i) => {
            if (key === part.name) {
              return (
                <h4 className="box_sub_heading mt-0" key={i}>
                  {part.label}
                </h4>
              );
            }
            return null

          })}

          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Monthly sales / production
              <div>{monthlyProductionList[key]?.monthlySales} units</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Average sales price per unit
              <div> ₹ {monthlyProductionList[key]?.averageSales}</div>
            </div>
          </div>
        </div>
      );
    });

    var bicyclePartsListItems = Object.keys(bicyclePartsList || {}).map(
      (key, index) => {
        return bicyclePartsArray.map((part, i) => {
          if (key === part.name) {
            return (
              <div className={" box-wrapper mt-20 w-50"} key={key}>
                <h4 className="box_sub_heading mt-0" key={i}>
                  {part.label}
                </h4>
                <div className={"row"}>
                  <div className={"col-sm-4 bold"}>
                    <img
                      src={require(`../../../../../assets/images/Bicycle/${part.label}.png`)}
                      alt={key}
                      className="bicycle-part-image"
                    />
                  </div>
                  <div className={"col-sm-5 bold"}>
                    Min Net Profit Margin %<div>{bicyclePartsList[key]} %</div>
                  </div>
                </div>
              </div>
            );
          }
          return null

        });
      }
    );

    //Bicycle Parts

    return (
      <div className={"bicycle-analysis-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bicycle Business Cost</title>
        </Helmet>
        <div className="container_top">
          <div
            className={"back-btn-link"}
            onClick={() => screenRenderHandler("overview-details")}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>
          <h3 className="container_heading">
            {businessName} - Operational Details
          </h3>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Bicycle parts manufactured</h4>
          </div>
          {bicyclePartsListItems}
        </div>
        <div className={"box-wrapper"}>
          <div className="box-header">
            <h4 className="box-header_heading">Customer and Supply linkage</h4>
          </div>
          <h4 className="box_sub_heading">
            Percentage of sales directed towards:
          </h4>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Directly supply to Hero{" "}
              <div>
                {customerAndSupplyLinkage?.directlySupplyToHero || "NA"} %
              </div>
            </div>
            <div className={"col-sm-4 bold"}>
              Directly supply to Avon
              <div>
                {customerAndSupplyLinkage?.directlySupplyToAvon || "NA"} %
              </div>
            </div>
            <div className={"col-sm-4 bold"}>
              Supply to vendors of Hero, Avon & OEMs
              <div>{customerAndSupplyLinkage?.supplyToVendors || "NA"} %</div>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Replacement (spares) market
              <div>{customerAndSupplyLinkage?.replacementMarket || "NA"} %</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Other<div>{customerAndSupplyLinkage?.other || "NA"} %</div>
            </div>
          </div>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Monthly Production</h4>
          </div>
          {monthlyProductionItemsList}
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Proportion of material in manufacturing of single unit
            </h4>
          </div>
          {materialProportionListItems}
          <div className={"box-wrapper mt-20"}>
            <h4 className="box_sub_heading mt-0">
              Total monthly purchase (in kg)
            </h4>
            <div className={"row"}>
              <div className={"col-sm-4 bold"}>
                Total monthly Steel purchase (in kg)
                <div>
                  {materialProportion?.totalMonthlySteelPurchase || "NA"} kg
                </div>
              </div>
              <div className={"col-sm-4 bold"}>
                Total monthly Rubber purchase (in kg)
                <div>
                  {materialProportion?.totalMonthlyRubberPurchase || "NA"} kg
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Manufacturing Unit</h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Size of Manufacturing Unit (in sq. Yard)
              <div>
                {manufacturingUnit?.sizeOfManufacturingUnit || "NA"} sq. Yard
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
