import React, { Component } from "react";
import { get } from "lodash";
import { API_VERIFY_REFERENCE } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import Edit from "../../../assets/images/edit.svg";

class ReferencesCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vrifiedBtn: -1,
    };
    this.searchPinCode = null;
  }

  verify = (index) => {
    const { props, profileDetail } = this.props,
      { loaderModal } = props.actions,
      { match } = props;

    let referenceId = get(profileDetail[index], "id", "");
    let applicationNumber = get(match, "params.appNo", "");

    loaderModal(true);
    const headers = { "Content-Type": "application/json" };
    POST(API_VERIFY_REFERENCE(referenceId), { data: "" }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          this.setState({
            vrifiedBtn: index,
          });
          props.actions.getReferences(applicationNumber);
        }
      })
      .catch((err) => {
        console.log(err);
        loaderModal(false);
        alert("Something went wrong please try again later.");
      });
  };

  editButton = (index) => {
    this.props.editButton("editRatio", index);
  };

  render() {
    const profileList = get(this, "props.profileDetail", []);
    return (
      <div className={"row referenceMargin"}>
        {profileList.map((profile, index) => (
          <div key={index} className={"col-md-4"}>
            <div className={"row references-box"}>
              <div className={"col-xs-12 reference1"}>
                <div className={"col-md-6"}>
                  <h5>{get(profile, "name", "")}</h5>
                </div>
                <div className={"col-md-6 buttonRow"}>
                  {profile.isVerified ? (
                    <button className="verified"> Verified</button>
                  ) : this.state.vrifiedBtn !== index ? (
                    <div>
                      <div className="editrow">
                        <button onClick={() => this.editButton(index)} className="edit">
                          <img src={Edit} alt="Cross Icon" style={{ height: "18px" }} />
                        </button>
                      </div>
                      <div className="veribtn">
                        <button onClick={() => this.verify(index)} className="verifying">
                          Click to Verify
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button className="verified"> Verified</button>
                  )}
                </div>
              </div>
              <div className={"col-xs-12 reference"}>
                <div className={"col-md-6"}>
                  <label>Relationship:</label>
                </div>
                <div className={"col-md-6"}>
                  <span>{get(profile, "relation", "")}</span>
                </div>
              </div>
              <div className={"col-xs-12 reference"}>
                <div className={"col-md-6"}>
                  <label>Phone Number:</label>
                </div>
                <div className={"col-md-6"}>
                  <span>{get(profile, "mobile", "")}</span>
                </div>
              </div>
              <div className={"col-xs-12 reference"}>
                <div className={"col-md-6"}>
                  <label>Address:</label>
                </div>
                <span className={"col-md-6"}>
                  <address className={"m-0"}>
                    {get(profile, "address.line1") || ""} {get(profile, "address.line2") || ""}
                    <br />
                    {get(profile, "address.city") || ""} {get(profile, "address.pinCode") || ""}
                    <br />
                  </address>
                </span>
              </div>
              <div className={"col-xs-12 reference"}>
                <div className={"col-md-6"}>
                  <label>Email:</label>
                </div>
                <div className={"col-md-6"}>
                  <span>{get(profile, "email", "")}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ReferencesCard;
