import {API_FETCH_REFERENCES, API_REMOVE_REFERENCE} from '../../../utils/APIUrls';
import {GET} from "../../../utils/webAPI.service";
import * as ReferencesConstants from "./references.actions.constant";

const getReferences = (applicationNumber) => {
    return (dispatch) => {
        return GET(API_FETCH_REFERENCES(applicationNumber))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({type: ReferencesConstants.SET_REFERENCES, data: data.data.referenceList})
                } else {
                    dispatch({type: ReferencesConstants.SET_REFERENCES, data: []})
                }
                dispatch({type: ReferencesConstants.SET_INITIAL_RESPONSE, status: true});
                return data;
            })
            .catch(err => {
                throw err
            })
    };
};

const getSalesReferences = (applicationNumber) => {
    return (dispatch) => {
        return GET(API_FETCH_REFERENCES(applicationNumber))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({type: ReferencesConstants.SET_REFERENCES, data: data.data.referenceList})
                } else {
                    dispatch({type: ReferencesConstants.SET_REFERENCES, data: [{},{}]})
                }
                dispatch({type: ReferencesConstants.SET_INITIAL_RESPONSE, status: true});
                return data;
            })
            .catch(err => {
                throw err
            })
    };
};

const changeInputValue = (val, name, index) => ({
    type: ReferencesConstants.CHANGE_VALUE,
    val,
    name,
    index
});

const addRow = () => ({type: ReferencesConstants.ADD_ROW});

const deleteRow = (index, id = '') => {
    return (dispatch) => {
        if (id) {
            return GET(API_REMOVE_REFERENCE(id))
                .then(({data}) => {
                    if (data.status === 'success') {
                        dispatch({type: ReferencesConstants.DELETE_ROW, index})
                    }
                    return data
                })
                .catch(err => {
                    throw err;
                })

        } else {
            dispatch({type: ReferencesConstants.DELETE_ROW, index})
        }
    }
};

const setInitialResponse = (status) => ({type: ReferencesConstants.SET_INITIAL_RESPONSE, status});

export {
    getReferences,
    setInitialResponse,
    changeInputValue,
    addRow,
    deleteRow,
    getSalesReferences
}
