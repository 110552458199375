import { API_GET_CLUSTER_OVERVIEW } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as ClusterPreviewConstants from "./clusterPreview.actions.constant";

const getClusterOverview = (customerId) => {
  return (dispatch) => {
    return GET(API_GET_CLUSTER_OVERVIEW(customerId))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ClusterPreviewConstants.FETCH_CLUSTER_OVERVIEW,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const resetClusterOverviewDetails = () => ({ type: ClusterPreviewConstants.RESET_REDUX_STATE });

export { getClusterOverview , resetClusterOverviewDetails };
