import {get, set} from "lodash";

const _getValue = (state, name, index) => {
    return get(state, `profileDetails[${index}].${name}`);
};

const _updateFormValues = (state, value, name, index) => {
    const _preValue = _getValue(state, name, index);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state)),
            _changedValue = get(set(_changedState, `profileDetails[${index}].${name}`, value), 'profileDetails');
        return {..._changedState, profileDetails: [..._changedValue]};
    }
    return state;
};

export {
    _updateFormValues
}
