import React, { Component, Fragment } from "react";
import { Header, LoaderModal, InputLabelAnimation } from "../../components";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import {
  loaderModal,
  setUserDetails,
  setUserLoginStatus,
} from "../../store/actions";
import { API_GENERATE_TOKEN, API_LOGIN } from "../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { validateEmail } from "../../utils/utitlity";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { BASE_URL } from "../../utils/webAPI.service";
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isFlipped: false,
      error: "",
    };
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };
  loginSubmitForm = (e) => {
    e.preventDefault();
    let { history, actions } = this.props,
      { loaderModal, setUserDetails, setUserLoginStatus } = actions,
      { email, password } = this.state;
    const baseUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}`;
    if (validateEmail(email)) {
      loaderModal(true);
      axios
        .get(`${baseUrl}${API_GENERATE_TOKEN}`)
        .then(({ data }) => {
          document.cookie = `token=${data.data.tokenId}; path=/`;
          const headers = {
            "Content-Type": "application/json",
            tokenId: data.data.tokenId,
          };
          axios
            .post(
              `${baseUrl}${API_LOGIN}`,
              {
                data: {
                  email: email,
                  password: password,
                  tokenId: data.data.tokenId,
                },
              },
              { headers }
            )
            .then(async ({ data }) => {
              loaderModal(false);
              if (data.status === "success") {
                setUserDetails(data.data);
                setUserLoginStatus(true);
                if (
                  data.data.roleName === "UW" ||
                  data.data.roleName === "UW_ADMIN"
                ) {
                  history.push("/Credit/dashboard");
                } else if (data.data.roleName === "CPO_USER") {
                  history.push("/CPODashboard/User?activetab=new");
                } else if (data.data.roleName === "ADMIN") {
                  history.push("/Admin");
                } else if (data.data.roleName === "CLUSTER_USER") {
                  history.push("/cluster/dashboard");
                } else if (data.data.roleName === "LEAD_MANAGER") {
                  history.push("/lead_manager/dashboard");
                } else if (data.data.roleName === "CPO_ADMIN") {
                  history.push("/cpopanelAdmin?activetab=Unassigned");
                } else {
                  history.push("/Sales/dashboard");
                }
              } else {
                this.setState({ error: data.message });
              }
            })
            .catch((err) => {
              loaderModal(false);
              console.log(err);
              this.setState({
                error: "Something went wrong please try again later.",
              });
            });
        })
        .catch((err) => {
          loaderModal(false);
          console.log(err);
          this.setState({
            error: "Something went wrong please try again later.",
          });
        });
    } else {
      this.setState({
        error: "Please Enter Correct Email Id or Mobile Number",
      });
    }
  };

  // componentWillMount() {
  //   window.scroll(0, 0);
  //   let { isLogin, history } = this.props;
  //   isLogin && history.push('/Sales/dashboard');
  // }

  render() {
    let { email, password } = this.state,
      { loaderModalStatus } = this.props;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <Header {...this.props} />
        <div className={"content-wrapper login-wrapper"}>
          <div className={"content-box"}>
            <h2 className={"heading"}>Login to your Account</h2>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <form onSubmit={this.loginSubmitForm} className={"form-box"}>
                  <InputLabelAnimation
                    labelText={"Enter Login Id or Mobile Number"}
                    onChange={this.setEmail}
                    name={"email"}
                    tabIndex={1}
                    inputValue={email}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={"Password"}
                    name={"password"}
                    tabIndex={2}
                    onChange={this.setPassword}
                    type={"password"}
                    inputValue={password}
                    required={true}
                  />
                  <div className={"actions-box"}>
                    {this.state.error ? (
                      <ErrorMessage>{this.state.error}</ErrorMessage>
                    ) : null}
                    <button tabIndex={3} type={"submit"}>
                      Login
                    </button>
                    <Link to={"/forgot_password"} className={"primary-link"}>
                      Forgot Password?
                    </Link>
                    <Link to={"/signup"} className={"primary-link"}>
                      Don’t have an account? Sign Up here.
                    </Link>
                  </div>
                </form>
              </div>
              <div className={"col-md-6"}>
                <img
                  src={require("../../assets/images/laptop.svg")}
                  alt={"laptop_img"}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      setUserDetails,
      setUserLoginStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
