import * as DocumentsConstants from "./Document.actions.constant";
const initialState = {
  documentList: [],
  documentName: []
};
export const documentsReducers = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case DocumentsConstants.SET_DOCUMENT_DATA:
      return { ...state, documentList: data };
    case DocumentsConstants.RESET_REDUX_STATE:
      return { ...state, documentList: [] };
    case DocumentsConstants.FETCH_DOCUMENT_LIST:
      return { ...state, documentName: data };

    default:
      return state;
  }
};
