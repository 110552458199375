import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-modal";
import { checkUserLogin, setAuthenticationStatus } from "./store/actions";
import { getCookie } from "./utils/utitlity";
import { LoaderModal, Error } from "./components";
import Index from "./views/index";
import LogIn from "./views/LogIn/Login";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import SignUp from "./views/SignUp/SignUp";
import Help from "./views/Help/Help";
import FAQ from "./views/FAQ/FAQ";
import CreditRoutes from "./views/Credit/index";
import SalesRoutes from "./views/Sales/index";
import CamPage from "./views/Credit/CAM/cam";
import Admin from "./views/Admin/index";
import validateOTP from "./views/Sales/GSTNDetailsPage/validateOTP";
import CpoAdminRoutes from "./views/CPOPanel/index";
import passwordReset from "../src/views/PasswordReset/passwordReset";
import CPOUserException from "../src/views/NewCpoPanel/newCpoPanel";
import CpoDashboard from "../src/views/NewCpoPanel/DashBoard/CpoDashboard";
import ClusterSales from "./views/Cluster-Sales/index";
import LeadManager from "./views/LeadManagement/index";
import Overview from "./views/LeadManagement/Overview/Overview";

export const hist = createBrowserHistory();

class App extends Component {
  componentWillMount() {
    Modal.setAppElement("Body");
    let { isAuthenticated } = this.props;
    let { checkUserLogin, setAuthenticationStatus } = this.props.actions;
    let { pathname } = window.location;
    let tokenId = getCookie("token");
    if (!isAuthenticated) {
      if (tokenId) {
        checkUserLogin()
          .then(({ status, data }) => {
            if (status === "success") {
              if (pathname === "/" || pathname === "/signup" || pathname === "/login") {
                if (data.roleName === "UW_ADMIN" || data.roleName === "UW") {
                  hist.push("/Credit/dashboard");
                } else if (data.roleName === "CPO_USER") {
                  hist.push("/cpoPanel");
                } else if (data.roleName === "CPO_ADMIN") {
                  hist.push("/cpoPanelAdmin");
                } else if (data.roleName === "ADMIN") {
                  hist.push("/Admin");
                } else if (data.roleName === "CLUSTER_USER") {
                  hist.push("/cluster/dashboard");
                } else if (data.roleName === "LEAD_MANAGER") {
                  hist.push("/lead_manager/dashboard");
                } else {
                  hist.push("/Sales/dashboard");
                }
              }
            } else {
              // deleteCookie("token");
              // hist.push("/");
              // alert("Session Expired");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (pathname === "/signup" || pathname === "/login" || pathname.includes("/validateotp/")) {
          hist.push(pathname);
        } else {
          hist.push("/");
        }
        setAuthenticationStatus(true);
      }
    }
  }

  render() {
    let { isAuthenticated } = this.props;

    return isAuthenticated ? (
      <Router history={hist}>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/login" exact component={LogIn} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/forgot_password" exact component={ForgotPassword} />
          <Route path="/cpopanelAdmin" exact component={CpoAdminRoutes} />
          <Route path="/CPODashboard/User" exact component={CpoDashboard} />
          <Route path="/CPODashboard/:appNo/User/Exception" exact component={CPOUserException} />
          <Route path={"/cluster"} component={ClusterSales} />
          <Route path={"/lead_manager/:custId"} component={LeadManager} />
          <Route path="/cam/:appNo" exact component={CamPage} />
          <Route path="/login" exact component={LogIn} />
          <Route path="/forgot_password" exact component={ForgotPassword} />
          <Route path="/help" exact component={Help} />
          <Route path="/faq" exact component={FAQ} />
          <Route path={"/Sales/:appNo"} component={SalesRoutes} />
          <Route path={"/Credit/:appNo"} component={CreditRoutes} />
          <Route path="/Admin" exact component={Admin} />
          <Route path="/validateotp/:uuid" exact component={validateOTP} />
          <Route path="/password-reset" exact component={passwordReset} />
          <Route path="/overview" exact component={Overview} />
          <Route path="/" render={(props) => <Error header={true} {...props} />} />
        </Switch>
      </Router>
    ) : (
      <LoaderModal status={true} />
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isAuthenticated: data.isAuthenticated,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      checkUserLogin,
      setAuthenticationStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
