import React, { Component, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { loaderModal } from "../../store/actions";
import { connect } from "react-redux";
import { Error, Header, LoaderModal } from "../../components";
import { bindActionCreators } from "redux";
import LeadManagerDashboard from "../LeadManagement/Dashboard/Dashboard";
import Overview from "../LeadManagement/Overview/Overview";
import LeadCreditBehaviour from "../LeadManagement/CreditBehaviour/CreditBehaviour";
import LeadManagementNavs from "./LeadManagementNavs/LeadManagementNavs";
import { get } from "lodash";

class LeadManagementRoutes extends Component {
  componentWillMount() {
    let { userDetails, history } = this.props;
    userDetails.roleName === "UW" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_USER" && history.push("/Credit/dashboard");
    userDetails.roleName === "CPO_ADMIN" && history.push("/Credit/dashboard");
    userDetails.roleName === "CLUSTER_USER" && history.push("/cluster/dashboard");
  }

  render() {
    let { loaderModalStatus, actions, userDetails } = this.props,
      { loaderModal } = actions;
    const arrPath = get(this, "props.location.pathname", "").split("/");
    const activeTab = arrPath.length ? arrPath[arrPath.length - 1] : "overview";
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <Header {...this.props} />
        {activeTab !== "dashboard" ? <LeadManagementNavs active={activeTab} {...this.props} /> : null}
        {userDetails.roleName === "LEAD_MANAGER" ? (
          <Switch>
            <Route path="/lead_manager/dashboard" exact component={LeadManagerDashboard} />
            <Redirect from={"/lead_manager"} exact to={`/lead_manager/dashboard`} />
            <Route path="/lead_manager/:custId/overview" exact component={Overview} />
            <Route path="/lead_manager/:custId/leadCreditBehaviour" exact component={LeadCreditBehaviour} />
            <Route
              path="/lead_manager/"
              render={(props) => <Error loaderModal={(status) => loaderModal(status)} {...props} />}
            />
          </Switch>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadManagementRoutes);
