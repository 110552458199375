import get from "lodash/get";
import set from "lodash/set";

const _updateAddressValues = (state, value, name, formIndex, index) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _changedValue = get(
      set(_changedState, `businessDetails[${formIndex}].addressDetails[].address.${name}`, value),
      "businessDetails"
    );
  return { ..._changedState, businessDetails: [..._changedValue] };
};
export { _updateAddressValues };
