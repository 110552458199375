import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ProfitAndLoss from "./profitAndLoss";
import BalanceSheet from "./balanceSheet";
import FinancialAnalysisRatios from "./FinancialAnalysisRatios";
import AssesseeDetails from "./AssesseeDetails";
import { LoaderModal } from "../../../components";
import { connect } from "react-redux";

class ITR extends Component {
  render() {
    return (
      <div className="container">
        <LoaderModal status={this.props.loaderModal} />
        <Tabs
          defaultActiveKey={"profitAndLoss"}
          id="adminTabs"
          onSelect={this.selectedTab}
        >
          <Tab eventKey={"profitAndLoss"} title={"Profit and loss account"}>
            <ProfitAndLoss {...this.props} />
          </Tab>
          <Tab eventKey={"balanceSheet"} title={"Balance Sheet"}>
            <BalanceSheet {...this.props} />
          </Tab>
          <Tab
            eventKey={"financialAnalysisRatios"}
            title={"Financial Analysis Ratios"}
          >
            <FinancialAnalysisRatios {...this.props} />
          </Tab>
          <Tab
            eventKey={"assesseeDetails"}
            title={"Assessee Details and Other analytics"}
          >
            <AssesseeDetails {...this.props} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModal: data.loaderModal,
});

export default connect(mapStateToProps, null)(ITR);
