import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import {
  API_GET_BUSINESS_COST_ANALYSIS,
  API_GET_BUSINESS_DETAILS,
} from "../../../../../utils/APIUrls";
import { GET } from "../../../../../utils/webAPI.service";

export default class FootwearBusinessCostAnalysis extends Component {
  state = {};
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_DETAILS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.businessName) {
        let { businessName } = data.data;
        this.setState({ businessName });
      }
    });
    GET(API_GET_BUSINESS_COST_ANALYSIS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.agraCostAnalysis) {
        let { agraCostAnalysis } = data.data;
        this.setState({ agraCostAnalysis });
      }
    });
  };

  render() {
    let { agraCostAnalysis = {}, businessName } = this.state,
      { screenRenderHandler } = this.props;
    return (
      <div className={"bicycle-analysis-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Footwear Business Cost</title>
        </Helmet>
        <div className="container_top">
          <div
            className={"back-btn-link"}
            onClick={() => screenRenderHandler("overview-details")}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>
          <h3 className="container_heading">
            {businessName} - Operational Details
          </h3>
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading"> Nature of Business</h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Nature of business<div>NA</div>
            </div>
          </div>
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Cost Analysis</h4>
          </div>
          <div className={"box-wrapper mt-20"}>
            <h4 className="box_sub_heading mt-0">Upper material </h4>
            <div className={"row"}>
              <div className={"col-sm-5 bold"}>
                Monthly purchase
                <div>
                  {agraCostAnalysis?.upperMaterialBoughtMonthly || "NA"} units
                </div>
              </div>
              <div className={"col-sm-5 bold"}>
                Cost per square meter
                <div>
                  {" "}
                  ₹ {agraCostAnalysis?.upperMaterialCostPerUnit || "NA"}
                </div>
              </div>
            </div>
          </div>
          <div className={"box-wrapper mt-20"}>
            <h4 className="box_sub_heading mt-0">Sole </h4>
            <div className={"row"}>
              <div className={"col-sm-5 bold"}>
                Monthly pair of sole purchase
                <div>
                  {agraCostAnalysis?.solesPairBoughtMonthly || "NA"} units
                </div>
              </div>
              <div className={"col-sm-5 bold"}>
                Average cost per pair of sole
                <div> ₹ {agraCostAnalysis?.costPerPairSole || "NA"}</div>
              </div>
            </div>
          </div>
          <div className={"box-wrapper mt-20"}>
            <h4 className="box_sub_heading mt-0">Packing box</h4>
            <div className={"row"}>
              <div className={"col-sm-5 bold"}>
                Monthly footwear packing box purchase
                <div>
                  {agraCostAnalysis?.packagingBoxBoughtMonthly || "NA"} units
                </div>
              </div>
              <div className={"col-sm-5 bold"}>
                Cost per box
                <div> ₹ {agraCostAnalysis?.costPerBox || "NA"} </div>
              </div>
            </div>
          </div>
          <div className={"box-wrapper mt-20"}>
            <h4 className="box_sub_heading mt-0">Average cost </h4>
            <div className={"row"}>
              <div className={"col-sm-5 bold"}>
                Average cost for manufacturing a single pair of footwear
                <div> ₹ {agraCostAnalysis?.costPerPair || "NA"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
