import React from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { get } from "lodash";
import { goTo } from "../../../utils/utitlity";

const EntityDetails = ({ data, index, status, applicationNumber }) => {
  let stakeHolderDetails = get(data, "stakeHolderDetails") || [];
  return (
    <div className={"col-md-12"}>
      <div className={"box-wrapper border-top entity-box"}>
        <div className={"box-content"}>
          <div className={"box-header"}>
            <h3 className={"box-heading"}>Entity Details {index + 1}</h3>
            {![
              "Submit",
              "Approve",
              "Escalate",
              "Hold",
              "Pending",
              "Reject",
            ].includes(status) && (
              <span
                onClick={() => goTo(`/Sales/${applicationNumber}/gstnaddress`)}
                className={"edit-icon"}
                title={"Edit"}
              >
                <Icon icon={ic_edit} size={18} />
              </span>
            )}
          </div>
          <div className={"row"}>
            <div className={"col-xs-12 col-md-5 vertical-divider-right"}>
              <div className={"row"}>
                <div className={"col-xs-6 col-md-6 box-details"}>
                  <div>
                    <label>Entity Name</label>
                    <span>{get(data, "entityName", "")}</span>
                  </div>
                  <div>
                    <label>Primary Industry</label>
                    <span>{get(data, "primaryIndustry", "")}</span>
                  </div>
                  <div>
                    <label>Secondary Industry</label>
                    <span>{get(data, "secondryIndustry", "")}</span>
                  </div>
                </div>
                <div className={"col-xs-6 col-md-6 box-details"}>
                  <div>
                    <label>PAN Number</label>
                    <span>{get(data, "panNumber", "")}</span>
                  </div>
                  <div>
                    <label>Business Constitution</label>
                    <span>{get(data, "businessConstitution", "")}</span>
                  </div>
                  {/* <div>
                    <label>Business Type</label>
                    <span>{get(data, "businessType", "")}</span>
                  </div> */}
                  <div>
                    <label>Business Vintage</label>
                    <span>{get(data, "businessVintage", "")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-xs-6 col-md-3 box-details"}>
              {stakeHolderDetails.map((item, index) => {
                return (
                  <div key={index}>
                    <label>{get(item, "stakeHolderType", "")}</label>
                    <span>{get(item, "stakeHolderName", "")}</span>
                  </div>
                );
              })}
            </div>
            <div className={"col-xs-6 col-md-4  vertical-divider-left"}>
              <div className={"row box-details"}>
                <div>
                  <label>Address</label>
                  <span>{get(data, "address", "")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityDetails;
