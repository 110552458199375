import React, { Component } from "react";
import GSTAnalysis from "./GSTAnalysis";
import ITR from "../ITR/ITR";

export default class BusinessRisk extends Component {
  state = {
    activeButton: "gstAnalysis",
  };
  render() {
    let { activeButton } = this.state;
    return (
      <div className="business-risk__container">
        <div className="toggle__wrapper">
          <div
            className={activeButton === "gstAnalysis" ? "active-button" : "normal-button"}
            onClick={() => this.setState({ activeButton: "gstAnalysis" })}
          >
            GST Analysis
          </div>
          <div
            className={activeButton === "financialAnalysis" ? "active-button" : "normal-button"}
            onClick={() => this.setState({ activeButton: "financialAnalysis" })}
          >
            Financial Analytics
          </div>
        </div>
        {activeButton === "gstAnalysis" ? <GSTAnalysis {...this.props} /> : <ITR {...this.props}/>}
      </div>
    );
  }
}
