import { set, get } from "lodash";

const _getValue = (state, name, index) => {
  return get(state, `form.rows[$]${index}.${name}`);
};

const _updateRowValues = (state, name, value = "", index) => {
  const _preValue = _getValue(state, name, index);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(
        set(
          _changedState,
          `shareholdingDetails.shareholdingList[${index}].${name}`,
          value
        ),
        "shareholdingDetails"
      );
    return { ..._changedState, shareholdingDetails: _changedValue };
  }
  return state;
};

const _deleteRow = (state, index) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _rowsList = get(_changedState, "shareholdingDetails.shareholdingList", []);
  _rowsList.splice(index, 1);
  return {
    ..._changedState,
    shareholdingDetails: {
      ..._changedState.shareholdingDetails,
      shareholdingList: _rowsList,
    },
  };
};

const _addRow = (state) => {
  let _changedState = JSON.parse(JSON.stringify(state));

  return {
    ..._changedState,
    shareholdingDetails: {
      ..._changedState.shareholdingDetails,
      shareholdingList: [
        ..._changedState.shareholdingDetails.shareholdingList,
        {},
      ],
    },
  };
};

export { _updateRowValues, _deleteRow, _addRow };
