import React, { PureComponent, Fragment } from "react";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { decisionData, riskBasedData, obligationsColumns, cibilColumns } from "./sectionC.config";
import get from "lodash/get";
import {
  getCaseHighLights,
  getDecisionSummary,
  getCurrentObligations,
  getReferenceDetails,
  getDeviations,
  getApplicationFilledBy,
  getApplicantsSummary,
} from "../cam.actions";
class SectionC extends PureComponent {
  componentDidMount = () => {
    let { actions, match } = this.props,
      {
        getCaseHighLights,
        getDecisionSummary,
        getCurrentObligations,
        getReferenceDetails,
        getDeviations,
        getApplicationFilledBy,
        getApplicantsSummary,
      } = actions,
      applicationNumber = get(match, "params.appNo", "");
    getCaseHighLights(applicationNumber);
    getDecisionSummary(applicationNumber);
    getCurrentObligations(applicationNumber);
    getReferenceDetails(applicationNumber);
    getDeviations(applicationNumber);
    getApplicationFilledBy(applicationNumber);
    getApplicantsSummary(applicationNumber);
  };
  render() {
    let {
      caseHighlightsData,
      decisionSummaryData,
      currentObligationData,
      referenceDetailsData,
      deviationData,
      applicationFilledBy,
      applicantSummaryData,
    } = this.props;
    let filterObligationData = currentObligationData.loanDetails
      ? currentObligationData.loanDetails.concat(currentObligationData?.tradeLines?.map((item )=> item?.loanDetails))
      : [];

      



    return (
      <Fragment>
        <div className="box-wrapper">
          <div className="box-heading">Case Highlights</div>
          <hr />
          <div className="row">
            <div className="col-sm-6  col-xs-6">
              <div className="strengths bold">Strengths</div>
              <ul>
                {caseHighlightsData &&
                  caseHighlightsData.strengths.map((el, index) => <ol key={index}>{`${index + 1} . ${el}`}</ol>)}
              </ul>
            </div>
            <div className="col-sm-6 col-xs-6">
              <div className="flags bold">Flags</div>
              <ul>
                {caseHighlightsData &&
                  caseHighlightsData.flags.map((el, index) => <ol key={index}>{`${index + 1}. ${el}`}</ol>)}
              </ul>
            </div>
          </div>
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Risk Based Approval</div>
          <hr />
          <ReactTable
            columns={riskBasedData}
            data={deviationData ? deviationData : []}
            resizable={false}
            showPagination={false}
            minRows={0}
          />
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Decision Summary</div>
          <hr />
          <ReactTable
            showPagination={false}
            resizable={false}
            minRows={0}
            data={decisionSummaryData || []}
            columns={decisionData}
          />
        </div>
        <div className="box-wrapper">
          <div className="box-heading">CIBIL Summary of Applicants</div>
          <hr />
          <ReactTable
            data={applicantSummaryData}
            columns={cibilColumns}
            resizable={false}
            showPagination={false}
            className={!applicantSummaryData.length ? "no-data no-pagination m-b-0" : "m-b-0"}
            minRows={0}
          />
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Current Obligations</div>
          <hr />
          <ReactTable
            className={!filterObligationData.length ? "no-data obligations-table" : "obligations-table"}
            data={filterObligationData}
            columns={obligationsColumns}
            resizable={false}
            minRows={0}
            pageSizeOptions={[10, 20, 25, 50, 100]}
            defaultPageSize={10}
          />
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Special Conditions</div>
          <hr />
          <div>NA</div>
        </div>
        <div className="row">
          <div className="col-sm-5 col-xs-5">
            <div className="box-wrapper" style={{ minHeight: "20em" }}>
              <div className="box-heading">Sourcing Partner</div>
              <hr />
              <div className="row">
                <div className="col-sm-6 col-xs-6">
                  <div className="box-subheading mts">Name</div>
                  <div>Random Text</div>
                  <div className="box-subheading mts">Phone</div>
                  <div>Random Text</div>
                  <div className="box-subheading mts">Email</div>
                  <div>Random Text</div>
                </div>
                <div className="col-sm-6 col-xs-6">
                  <div className="box-subheading mts">Address</div>
                  <div>Random Text</div>
                </div>
              </div>
            </div>
          </div>
          {applicationFilledBy ? (
            <div className="col-sm-3 col-xs-3">
              <div className="box-wrapper" style={{ minHeight: "20em" }}>
                <div className="box-heading">Form Filled by</div>
                <hr />
                <div className="row m-0">
                  <div className="box-subheading mts">Name</div>
                  <div>{`${applicationFilledBy.firstName} ${applicationFilledBy.lastName}`}</div>
                </div>
                <div className="row m-0">
                  <div className="box-subheading mts">Phone</div>
                  <div>{applicationFilledBy.mobile}</div>
                </div>
                <div className="row m-0">
                  <div className="box-subheading mts">Email</div>
                  <div>{applicationFilledBy.email}</div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-sm-4 col-xs-4">
            <div className="box-wrapper" style={{ minHeight: "20em" }}>
              <div className="box-heading">RM Hierarchy details</div>
              <hr />
            </div>
          </div>
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Trade Reference</div>
          <hr />
          <div className="row">
            {referenceDetailsData.referenceList &&
              referenceDetailsData.referenceList.map((el, index) => {
                return (
                  <div
                    key={index}
                    className={
                      el.isVerified
                        ? "reference-wrapper verify col-md-3 col-sm-6 mts col-xs-5"
                        : "reference-wrapper notVerify col-md-3 col-sm-6 mts col-xs-5"
                    }
                  >
                    <div className="box-subheading mtl">Name</div>
                    <div>{el.name}</div>
                    <div className="box-subheading mtl">Relationship</div>
                    <div>{el.relation}</div>

                    <div className="box-subheading mtl">Phone</div>
                    <div>{el.mobile}</div>

                    <div className="box-subheading mtl">Phone</div>
                    <div>{el.email}</div>

                    <div className="box-subheading mtl">Residence</div>
                    <div>{`${el.address.line1} , ${el.address.district} , ${el.address.state} , ${el.address.pinCode}`}</div>
                    <div className={el.isVerified ? "verifyText" : "notVerifyText"}>
                      {el.isVerified ? "VERIFIED" : "NOT VERIFIED"}{" "}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ CamResponse }) => ({
  caseHighlightsData: CamResponse.caseHighlightsData,
  decisionSummaryData: CamResponse.decisionSummaryData,
  currentObligationData: CamResponse.currentObligationData,
  referenceDetailsData: CamResponse.referenceDetailsData,
  deviationData: CamResponse.deviationData,
  applicationFilledBy: CamResponse.applicationFilledBy,
  applicantSummaryData: CamResponse.applicantSummaryData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCaseHighLights,
      getDecisionSummary,
      getCurrentObligations,
      getReferenceDetails,
      getDeviations,
      getApplicationFilledBy,
      getApplicantsSummary,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(SectionC);
