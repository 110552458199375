import * as ACTION_CONSTANTS from "./cashFlow.action.constant";
import { _updateFormValues } from "../../../utils/utitlity";

const initialState = {
  bankDetailsLoader: false,
  bankAnalysisLoader: false,
  bankDetails: null,
  bankAnalysisData: null,
  editRatioLoader: false,
  editRatioLoader1: false,
  editRatio: null,
  editRatio1: null,
  getBankAccFlagsData: [],
  form: {
    values: null,
    error: null,
  },
};

export const cashFlow = (state = initialState, action) => {
  const { data, type, entityDetails, accountNumber } = action;
  switch (type) {
    case `${ACTION_CONSTANTS.BANK_DEAILS}${ACTION_CONSTANTS.COMMON.START}`:
      return { ...state, bankDetailsLoader: true };
    case `${ACTION_CONSTANTS.BANK_DEAILS}${ACTION_CONSTANTS.COMMON.FINISHED}`:
      return { ...state, bankDetailsLoader: false };
    case ACTION_CONSTANTS.BANK_DEAILS:
      return { ...state, bankDetails: data };
    case `${ACTION_CONSTANTS.EDIT_RATIO}${ACTION_CONSTANTS.COMMON.START}`:
      return { ...state, editRatioLoader: true };
    case `${ACTION_CONSTANTS.EDIT_RATIO}${ACTION_CONSTANTS.COMMON.FINISHED}`:
      return { ...state, editRatioLoader: false };
    case ACTION_CONSTANTS.EDIT_RATIO:
      return { ...state, editRatio: data };
    case `${ACTION_CONSTANTS.EDIT_RATIO_ACC}${ACTION_CONSTANTS.COMMON.START}`:
      return { ...state, editRatioLoader1: true };
    case `${ACTION_CONSTANTS.EDIT_RATIO_ACC}${ACTION_CONSTANTS.COMMON.FINISHED}`:
      return { ...state, editRatioLoader1: false };
    case ACTION_CONSTANTS.EDIT_RATIO_ACC:
      return { ...state, editRatio1: data };
    case `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.START}`:
      return { ...state, bankAnalysisLoader: true };
    case `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.FINISHED}`:
      return { ...state, bankAnalysisLoader: false };
    case ACTION_CONSTANTS.BANK_ANALYSIS:
      return { ...state, bankAnalysisData: data };
    case ACTION_CONSTANTS.CHANGE_VALUE:
      return {
        ...state,
        form: _updateFormValues(state, action.name, action.data),
      };

    case ACTION_CONSTANTS.API_GET_BANK_ACCOUNT_FLAGS:
      return {
        ...state,
        getBankAccFlagsData: {
          ...state.getBankAccFlagsData,
          [accountNumber]: data,
        },
      };

    case ACTION_CONSTANTS.SET_ENTITY_DETAILS:
      return { ...state, entityDetails: entityDetails };
    default:
      return state;
  }
};
