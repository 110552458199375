import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { loaderModal } from '../../store/actions';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { angleLeft } from 'react-icons-kit/fa/angleLeft';
import { Header, InputLabelAnimation, LoaderModal } from '../../components';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_FORGOT_PASSWORD } from '../../utils/APIUrls';
import { BASE_URL } from '../../utils/webAPI.service';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  forgetPasswordSubmitForm = (e) => {
    e.preventDefault();
    const { actions, history } = this.props,
      { loaderModal } = actions,
      { email } = this.state;
    const baseUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}`;

    const headers = { 'Content-Type': 'application/json' };
    loaderModal(true);
    axios
      .post(
        `${baseUrl}${API_FORGOT_PASSWORD}`,
        { data: { email: email } },
        { headers }
      )
      .then(({ data }) => {
        window.alert(data.message);
        loaderModal(false);
        if (data.status === 'success') {
          history.push('/login');
        }
      })
      .catch((err) => {
        loaderModal(false);
        console.log(err);
        alert('Something went wrong please try again later.');
      });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { isLogin, history } = this.props;
    isLogin && history.push('/Sales/dashboard');
  }

  render() {
    let { email } = this.state,
      { loaderModalStatus } = this.props;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <Header {...this.props} />
        <div className={'content-wrapper forgot-password-wrapper'}>
          <div className={'content-box'}>
            <Link to={'/login'} className={'go-back'}>
              <Icon icon={angleLeft} size={28} /> Back
            </Link>
            <h2 className={'heading'}>Forgot Password?</h2>
            <p>
              Please enter your email id registered for Pemant account and
              we will send you a password reset link.
            </p>
            <form
              onSubmit={this.forgetPasswordSubmitForm}
              className={'form-box'}
            >
              <InputLabelAnimation
                labelText={'Email Address'}
                name={'email'}
                onChange={this.setEmail}
                type={'email'}
                tabIndex={1}
                inputValue={email}
                required={true}
              />
              <div className={'btns-box'}>
                <button tabIndex={2} type={'submit'}>
                  Send Link
                </button>
                <span className={'primary-link'}>Resend Link</span>
              </div>
            </form>
            <Link to={'/signup'} className={'primary-link'}>
              Don’t have an account? Sign Up here.
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
