import React, { Component } from "react";
import { connect } from "react-redux";
import GSTDetailsRow from "./GSTDetailsRow";
import { loaderModal } from "../../../store/actions";
import { bindActionCreators } from "redux";
import { getGSTData, getPanData } from "./GSTNDetails.actions";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_ADD_PAN_FLOW_BUSINESS } from "../../../utils/APIUrls";

export class GSTNDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gstinRowArray: [{}],
      panData: {},
    };
  }
  inputHandler = (e, rowIndex) => {
    let { gstinRowArray } = this.state,
      gstinRow = gstinRowArray[rowIndex],
      name = e.target.name,
      value = e.target.value;
    gstinRow[name] = value;
    gstinRowArray[rowIndex] = gstinRow;
    this.setState({ gstinRowArray });
  };

  deleteRow = (rowIndex) => {
    let { gstinRowArray } = this.state,
      gstinRowArrayCopy = [...gstinRowArray];
    gstinRowArrayCopy = gstinRowArrayCopy.filter((_, index) => rowIndex !== index);
    this.setState({ gstinRowArray: gstinRowArrayCopy });
  };
  panInput = (e) => {
    let value = e.target.value.toUpperCase();
    this.setState({ panData: { ...this.state.panData, businessPan: value, isPanFlow: true } });
  };

  // gstDetailsHandler = () => {
  //   let { panData, isGSTFlow } = this.state,
  //     { actions, match } = this.props,
  //     dataToSend = {
  //       data: {
  //         applicationNumber: match.params.appNo,
  //         businessId: panData.businessId,
  //         businessPan: panData.businessPan,
  //       },
  //     },
  //     regexPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //   if (!(panData.businessPan || isGSTFlow)) {
  //     return alert("Please enter GST Number OR Business Pan");
  //   }
  //   if (!isGSTFlow && panData.isPanFlow) {
  //     if (regexPan.test(panData.businessPan)) {
  //       actions.loaderModal(true);
  //       POST(API_POST_ADD_PAN_FLOW_BUSINESS, dataToSend)
  //         .then(({ data }) => {
  //           if (data.status === "success") {
  //           } else {
  //             alert(data.message);
  //           }
  //           actions.loaderModal(false);
  //         })
  //         .catch((err) => {
  //           actions.loaderModal(false);
  //           throw err;
  //         });
  //     } else {
  //       alert("Please Enter Correct Pan Number");
  //     }
  //   }   
  // };

  render() {
    let { gstinRowArray, panData } = this.state; 
    let {userExceptionData} = this.props;
    let {userType}=userExceptionData;  
    return (
    
      <div className="cpo-gst-wrapper gstn-details__wrappers">
          {userType==="BUYER"?(
                <div className="cpo-content-box content-box ">
                <h4 className={"heading"}>Add GST Details </h4>
                <div className="card__wrapper">
                  <h5>GST Based flow</h5>
                  <button
                    className="button__generic"
                    onClick={() => this.setState((prevState) => ({ gstinRowArray: [...prevState.gstinRowArray, {}] }))}
                  >
                    +Add another GSTN
                  </button>
                  {gstinRowArray.map((gstinRowData, index) => (
                    <GSTDetailsRow
                      {...this.props}
                      key={`GSTIN-row-${index}`}
                      rowIndex={index}
                      deleteRow={this.deleteRow}
                      gstin={gstinRowData.gstin}
                      gstUsername={gstinRowData.gstUsername}
                      inputHandler={this.inputHandler}
                      gstFlowHandler={() => this.setState({ isGSTFlow: true })}
                    />
                  ))}
                </div>  
                {/* <div className="text-center mt-2">
                  <button className="save-btn" onClick={this.gstDetailsHandler}>
                    Save & Next
                  </button>
                </div> */}
              </div> 
          ):null}   
      </div>
    );
  }
  componentDidMount = () => {
    let { actions, match } = this.props;
    actions.getGSTData(match.params.appNo).then(({ data }) => {
      data && this.setState({ gstinRowArray: data });
    });
    actions.getPanData(match.params.appNo).then(({ data }) => {
      data && this.setState({ panData: data });
    });
  };
}

const mapStateToProps = ({ gstnDetailsReducers }) => ({
  gstnDetails: gstnDetailsReducers.gstnDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getGSTData,
      getPanData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTNDetails);
