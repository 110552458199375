import { API_POST_ADD_RELATED_PARTY_DETAILS } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";

const monthlyMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const quarterlyMonths = ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"];
const years = ["2015", "2016", "2017", "2018", "2019", "2020"];

const postPartyDetails = (dataToSend) => {
    return POST(API_POST_ADD_RELATED_PARTY_DETAILS, dataToSend)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
export { monthlyMonths, years, quarterlyMonths , postPartyDetails };
