import React, {Component} from 'react';
import Header from "../Header/Header";

class Error extends Component {
    render() {
        let {header} = this.props;
        return (
            <div>
                {
                    header
                        ? <Header {...this.props}/>
                        : null
                }
                <div className='content-container text-center' style={{
                    height: '615px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <h1 className={'heading'}>Page Not Found</h1>
                </div>
            </div>
        )
    }
}

Error.defailtProps = {
    header: false
};

export default Error;
