import * as LoanTenureSelectors from "./loanTenure.selectors";
import * as LoanTenureConstant from './loanTenure.actions.constant';

const initialState = {
    loanDetails: {}
};

export const loanTenure = (state = initialState, action) => {
    const { data, type, name } = action;
    switch (type) {
        case LoanTenureConstant.SET_LOAN_DETAILS:
            return { ...state, loanDetails: data};
        case LoanTenureConstant.RESET_REDUX_STATE:
            return { ...state, loanDetails: {}};
        case LoanTenureConstant.CHANGE_VALUE:
            return LoanTenureSelectors._updateFormValues(state, data, name);
        default:
            return state;
    }
};
