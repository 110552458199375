import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
// import ReactDOM from 'react-dom';

class DatePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldActive: !!props.selected,
    };
  }

  // activateField = () => {
  //     this.setState({fieldActive: true})
  // };

  // disableField = () => {
  //     let {selected} = this.props;
  //     !this.dateField.input.value && !selected && this.setState({fieldActive: false})
  // };

  // updateInputValue = (e) => {
  //     const {onChange} = this.props;
  //     if (e !== null) {
  //         onChange(e);
  //         this.activateField();
  //     }
  // };

  componentWillReceiveProps(nextProps) {
    let { fieldActive } = this.state;
    nextProps.selected
      ? !fieldActive && this.setState({ fieldActive: true })
      : fieldActive && this.setState({ fieldActive: false });
  }

  render() {
    const { fieldActive } = this.state,
      {
        datePickerClass,
        labelText,
        required,
        name,
        selected,
        disabled,
        boxClass,
        tabIndex,
        placeholderText,
        maxDate,
        defaultValue,
        onChange,
        minDate,
      } = this.props;
    return (
      <div className={disabled ? `datepicker-box datepicker-disabled ${boxClass}` : `datepicker-box ${boxClass}`}>
        <label onClick={() => this.dateField.input.focus()} className={fieldActive ? "field-active" : ""}>
          {labelText}
          {required ? <span>&nbsp;*&nbsp;</span> : null}
        </label>
        <DatePicker
          dateFormat="dd/MM/yyyy"
          disabled={disabled}
          ref={(ref) => (this.dateField = ref)}
          tabIndex={tabIndex}
          showMonthDropdown
          showYearDropdown
          dropdownMode={"scroll"}
          selected={selected}
          // onChangeRaw={(e) => e.preventDefault()}
          // onFocus={this.activateField}
          // onBlur={this.disableField}
          // onChange={this.updateInputValue}
          onChange={onChange}
          maxDate={maxDate}
          defaultValue={defaultValue}
          name={name}
          placeholderText={placeholderText}
          className={`date-field floating-label ${datePickerClass}`}
        />
      </div>
    );
  }
}

DatePickerComponent.defaultProps = {
  datePickerClass: "",
  labelText: "",
  required: false,
  name: "",
  selected: "",
  disabled: false,
  boxClass: "",
  tabIndex: -1,
  placeholderText: "",
  onChange: () => {},
  maxDate: new Date(),
  defaultValue: null,
};

DatePickerComponent.propTypes = {
  datePickerClass: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  selected: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  boxClass: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  placeholderText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  maxDate: PropTypes.instanceOf(Date).isRequired,
};

export default DatePickerComponent;
