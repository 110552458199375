import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  API_GET_BANK_LIST,
  API_GET_ACCOUNT_TYPE,
  API_POST_ADD_BANK_ACCOUNTS,
  API_GET_BANK_ACCOUNTS,
} from "../../../utils/APIUrls";
import { GET, POST } from "../../../utils/webAPI.service";
import { loaderModal } from "../../../store/actions";
import { Link } from "react-router-dom";
import { Header } from "../../../components";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
class BusinessAccountDetails extends Component {
  state = {
    bankNamesList: [],
    accountTypeList: [],
    bankAccounts: [{}],
  };
  bankingInputHandler = (index, e) => {
    let { name, value } = e.target;
    let { bankAccounts } = this.state,
      bankAccountsCopy = [...bankAccounts];
    bankAccountsCopy[index] = { ...bankAccountsCopy[index], [name]: value };
    bankAccounts = bankAccountsCopy;
    this.setState({ bankAccounts });
  };
  transactionHandler = (index, value) => {
    let { bankAccounts } = this.state,
      bankAccountsCopy = [...bankAccounts];
    bankAccountsCopy[index] = {
      ...bankAccountsCopy[index],
      transactionType: value,
    };
    bankAccounts = bankAccountsCopy;
    this.setState({ bankAccounts });
  };
  deleteBankAccountsRow = (rowIndex) => {
    let { bankAccounts } = this.state,
      bankAccountsCopy = [...bankAccounts];
    bankAccountsCopy = bankAccountsCopy.filter((_, index) => rowIndex !== index);
    this.setState({ bankAccounts: bankAccountsCopy });
  };

  addApplicationHandler = async (e, actionType) => {
    e.preventDefault();
    let { actions, match, history } = this.props;
    actions.loaderModal(true);
    let dataToSend = {
      data: {
        bankAccounts: this.state.bankAccounts,
        customerId: match.params.custId,
      },
    };
    await POST(API_POST_ADD_BANK_ACCOUNTS, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/cluster/${match.params.custId}/business-size/${match.params.clusterType}`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else if (data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  render() {
    let { bankNamesList, accountTypeList, bankAccounts } = this.state,
      transactions = ["Only wages", "Purchases", "Sales inflow", "All kinds", "None"],
      monthsList = ["Select Month", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      years = ["Select Year", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020"],
      { match } = this.props;

    return (
      <>
        <Header {...this.props} />
        <div className="business-account__wrapper">
          <div className="container">
            <Link
              to={`/cluster/${this.props.match.params.custId}/business-details/${match.params.clusterType}`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>

            <h1 className="container__heading"> Business Details </h1>
            <form>
              <div className="container p-top--sub">
                <div className="sub-heading-wrap">
                  <div className="flex-box ">
                    <h2 className="sub-heading_basic"> Bank account details</h2>
                    <div
                      className="btn-add"
                      onClick={() =>
                        this.setState((prevState) => ({
                          bankAccounts: [...prevState.bankAccounts, {}],
                        }))
                      }
                    >
                      + Add account
                    </div>
                  </div>
                  <div></div>
                  {bankAccounts.map((el, bankIndex) => {
                    return (
                      <div className="container p-top--sub" key={`bank-accounts-${bankIndex}`}>
                        <div className={"flex-box-basic "}>
                          <h2 className="color-decor">Account {bankIndex + 1}</h2>
                          {bankIndex > 0 ? (
                            <span onClick={() => this.deleteBankAccountsRow(bankIndex)} className={"remove-icon"}>
                              <Icon icon={ic_delete_forever} size={28} />
                            </span>
                          ) : null}
                        </div>
                        <div className={"row"}>
                          <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                            <select
                              className={"select-box"}
                              onChange={(e) => this.bankingInputHandler(bankIndex, e)}
                              name="bankName"
                              value={el.bankName}
                            >
                              <option>Select Bank</option>
                              {bankNamesList.sort().map((el, index) => {
                                return (
                                  <option key={`bank-list-${index}`} value={el.value}>
                                    {el.bankName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className={"col-sm-6 m-top--main col-xs-12"}>
                            <select
                              className={"select-box"}
                              onChange={(e) => this.bankingInputHandler(bankIndex, e)}
                              name="accountType"
                              value={el.accountType}
                            >
                              {accountTypeList.map((el, index) => {
                                return <option key={`account-type-${index}`}> {el}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className={"row "}>
                          <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                            <InputLabelAnimation
                              labelText={"Account Number"}
                              onChange={(e) => this.bankingInputHandler(bankIndex, e)}
                              name="accountNumber"
                              inputValue={el.accountNumber}
                            />
                          </div>
                          <div className={"col-sm-6 m-top--main col-xs-12"}>
                            <div className={"row "}>
                              <div className={"col-sm-2 m-top--main col-xs-12 p-0"}>
                                <div className="sub-since">Since:</div>
                              </div>
                              <div className={"col-sm-5 col-xs-6 "}>
                                <select
                                  className={"select-box"}
                                  onChange={(e) => this.bankingInputHandler(bankIndex, e)}
                                  name="months"
                                  value={el.months}
                                >
                                  {monthsList.map((el, index) => {
                                    return <option key={`months-${index}`}> {el}</option>;
                                  })}
                                </select>
                              </div>
                              <div className={"col-sm-5 col-xs-6"}>
                                <select
                                  className={"select-box"}
                                  onChange={(e) => this.bankingInputHandler(bankIndex, e)}
                                  name="years"
                                  value={el.years}
                                >
                                  {years.map((el, index) => {
                                    return <option key={`year-${index}`}> {el}</option>;
                                  })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h6 className="sub-qsn">What kind transactions you route from banking </h6>
                        {transactions.map((transaction, index) => {
                          return (
                            <div
                              onClick={() => this.transactionHandler(bankIndex, transaction)}
                              className={el.transactionType === transaction ? "btn-active" : "btn-normal"}
                              key={`transaction-type-${index}`}
                            >
                              {transaction}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                <div className="btn-box">
                  <span onClick={(e, name) => this.addApplicationHandler(e, "draft")} className={"btn-draft"}>
                    Save draft
                  </span>
                  <button type="submit" className={"btn-save"} onClick={(e) => this.addApplicationHandler(e, "save")}>
                    Save & Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    let { actions, match } = this.props;
    actions.loaderModal(true);
    GET(API_GET_BANK_LIST)
      .then(({ data }) => {
        if (data.data) {
          let bankNamesList = data.data.sort((a, b) => a.bankName.localeCompare(b.bankName));
          this.setState({ bankNamesList: bankNamesList });
        }
      })
      .catch((err) => {
        throw err;
      });
    GET(API_GET_ACCOUNT_TYPE)
      .then(({ data }) => {
        if (data.data) {
          this.setState({
            accountTypeList: ["Select Account type", ...data.data],
          });
        }
      })
      .catch((err) => {
        throw err;
      });
    await GET(API_GET_BANK_ACCOUNTS(match.params.custId)).then(({ data }) => {
      if (data.data) {
        let { bankAccounts } = data.data;
        this.setState({ bankAccounts });
      }
    });
    actions.loaderModal(false);
  };
}
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(BusinessAccountDetails);
