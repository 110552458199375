import React, { Component } from "react";
import ReactTable from "react-table";

class OtherBusinessDetails extends Component {
  render() {
    let { PropsData } = this.props;
    const columnsOrganization = [
        {
          Header: "Name",
          accessor: "name",
          headerStyle: {textAlign: 'center'},
          Cell: ({ original }) => (
            <div style={{ textAlign: "center" }}>{original.name}</div>
          ),
        },
        {
          Header: "Registration Number ",
          accessor: "registerationNumber",
          Cell: ({ original }) => <div>{original.registerationNumber}</div>,
        },
      ],
      columnsOther = [
        {
          Header: "Name",
          accessor: "name",
          headerStyle: {textAlign: 'center'},
          Cell: ({ original }) => (
            <div style={{ textAlign: "center" }}>{original.name}</div>
          ),
        },
        {
          Header: "Relation ",
          accessor: "relation",
          Cell: ({ original }) => <div>{original.relation}</div>,
        },
      ],
      dataOrganization = PropsData.businessMembers?.memberOrganizations || [],
      dataOthers = PropsData.businessMembers?.otherBusinessMembers || [];
    return (
      <div className="bank-details-wrapper">
        <h2> Other Business Details</h2>
        <div className="shadow-box m-around">
          <span> Business member organizations</span>
          <ReactTable
            columns={columnsOrganization}
            data={dataOrganization || []}
            defaultPageSize={10}
            showPagination={false}
            resizable={false}
            minRows={0}
            getTrProps={this.rowClicked}
          />
        </div>
        <div className="shadow-box">
          <span> Other members involved in business</span>
          <ReactTable
            columns={columnsOther}
            data={dataOthers || []}
            defaultPageSize={10}
            showPagination={false}
            resizable={false}
            minRows={0}
            getTrProps={this.rowClicked}
          />
        </div>
      </div>
    );
  }
}
export default OtherBusinessDetails;
