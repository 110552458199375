import React, { Component } from "react";
import Slider from "react-rangeslider";
import { getAllThresholds } from "../admin.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_UPDATE_THRESHOLD } from "../../../utils/APIUrls";
class Parameterization extends Component {
  state={}
  sliderChange = (value, name) => {
  
    this.setState({
      [name]: value
    });
  };
  inputHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  componentWillMount() {
    window.scroll(0, 0);
  }
  componentDidMount = () => {
    this.props.actions.getAllThresholds().then(({ data }) => {
      data.map(el => this.setState({ [el.name]: el.limit }));
    });
  };
  updateThresholdHandler = name => {
    var dataToSend = {
      data: {
        limit: this.state[name],
        name
      }
    };
    POST(API_POST_UPDATE_THRESHOLD, dataToSend).then(({ data }) => {
     
    });
  };
  render() {
    
    let { allThresholdData } = this.props;
    return (
      <div className={"content-box parameterization-wrapper"}>
        <div className={"box-header"}>
          <h4>Define Risk Parameters and Thresholds</h4>
        </div>
        <div className={"box-content"}>
          <table>
            <thead>
              <tr>
                <th>Parameter</th>
                {/* <th>Definition</th> */}
                <th>Selected Value</th>
                <th>Current Value</th>
                <th>Update</th>
              </tr>
            </thead>
            <tbody>
              {allThresholdData?.map((el, index) => {
                return (
                  <tr key={index}>
                    <td>{el.name}</td>
                    <td>
                      <Slider
                        min={el.max}
                        max={el.min}
                        value={this.state[el?.name]}
                        onChange={value => this.sliderChange(value, el.name)}
                      />
                    </td>
                    <td>
                      <input name={el?.name} value={this.state[el.name]} onChange={this.inputHandler} className="input-field"/>
                    </td>
                    <td>
                      <button onClick={() => this.updateThresholdHandler(el.name)} className="button_Generic green-btn">Update</button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAllThresholds }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Parameterization);
