import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import Select from "react-select";
import { DatePicker } from "../../../components";
import "react-datepicker/dist/react-datepicker.css";
import { getDMY } from "../../../utils/utitlity";
import { POST } from "../../../utils/webAPI.service";
import { API_ADD_PROFILE } from "../../../utils/APIUrls";

export default class gstException extends Component {
  state = {
    iscibilTrigger: false
  };
  cibilEditDetailsHandler = pan => {
    this.setState({ [pan]: !this.state[pan] });
  };

  inputHandler = (event, index) => {
    const name = event.target.name,
      value = event.target.value;
    this.props.actions.editCibilExceptionData(name, value, index);
  };

  selectHandler = (valueObject, name, index) => {
    var value = valueObject.value;
    this.props.actions.editCibilExceptionData(name, value, index);
  };
  dateHandler = (name, value) => {
    var dateValue = getDMY(new Date(value.getTime()).toLocaleDateString());
    this.setState({ [name]: dateValue });
  };
  triggerCibilHandler = async pan => {
    let { actions, match } = this.props,
      { appNo } = match.params,
      { triggerCibil, loaderModal, getExceptionData } = actions;
    loaderModal(true);
    await triggerCibil(appNo, pan)
      .then(data => {
        if (data.status === "success") {
          getExceptionData(appNo);
        } else {
          window.alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };

  submitCibilExceptionData = async (pan, data) => {
    this.cibilEditDetailsHandler(pan);
    let { dateOfBirth } = this.state,
      { actions, match } = this.props,
      { loaderModal, getExceptionData } = actions,
      { appNo } = match.params,
      dataToSend = {
        data: {
          applicationNumber: appNo,
          profileList: [data.profileDetails]
        }
      };
    loaderModal(true);
    await POST(API_ADD_PROFILE, dataToSend).then(({ data }) => {
      if (data.status === "success") {
        getExceptionData(appNo);
      } else {
        alert(data.message);
      }
    });
    loaderModal(false);
  };
  dateCibilHandler = (e, name, index) => {
    if (e !== null) {
      let value = getDMY(new Date(e.getTime()).toLocaleDateString());
      this.props.actions.editCibilExceptionData(name, value, index);
    }
  };
  render() {
    const genderList = [
      { value: "Female", label: "Female" },
      { value: "Male", label: "Male" },
      { value: "Other", label: "Other" }
    ];
    let { cibilExceptionData } = this.props.userExceptionData,
      { iscibilTrigger } = this.state;
    return (
      <Fragment>
        {cibilExceptionData ? (
          <div className="card__wrapper mt-2">
            <div className="heading">Cibil Exceptions</div>
            {cibilExceptionData &&
              cibilExceptionData.map((el, index) => {
                var id = el.profileDetails.id;
                return (
                  <div className="card__wrapper mt-2" key={id}>
                    <div className="Details_div">
                      <div>
                        <div className="label_heading">Applicant Name</div>
                        {this.state[id] ? (
                          <input
                            type="text"
                            className={"input-field"}
                            placeholder={"Applicant Name"}
                            value={el.profileDetails.name}
                            name={"name"}
                            onChange={e => this.inputHandler(e, index)}
                          />
                        ) : (
                          <div className="value">{el.profileDetails.name}</div>
                        )}
                      </div>
                      <div>
                        <div className="label_heading">PAN Number</div>
                        {this.state[id] ? (
                          <input
                            type="text"
                            className={"input-field"}
                            placeholder={"PAN Number"}
                            name={"pan"}
                            value={el.profileDetails.pan}
                            onChange={e => this.inputHandler(e, index)}
                          />
                        ) : (
                          <div className="value">{el.profileDetails.pan}</div>
                        )}
                      </div>
                      <div>
                        <div className="label_heading">Gender</div>
                        {this.state[id] ? (
                          <Select
                            onChange={value => this.selectHandler(value, "gender", index)}
                            className="select-field"
                            options={genderList}
                            value={genderList ? genderList.find(item => item.label === el.profileDetails.gender) : []}
                          />
                        ) : (
                          <div className="value">{el.profileDetails.gender}</div>
                        )}
                      </div>
                      <div>
                        <div className="label_heading">DOB</div>
                        {this.state[id] ? (
                          <DatePicker
                            selected={
                              el.profileDetails.dateOfBirth
                                ? el.profileDetails.dateOfBirth !== "NA"
                                  ? new Date(el.profileDetails.dateOfBirth.split("-").reverse())
                                  : null
                                : null
                            }
                            onChange={e => this.dateCibilHandler(e, "dateOfBirth", index)}
                            maxDate={new Date(this.state.maxDate)}
                            name="dateOfBirth"
                            labelText={"Date of Birth"}
                            className={"input-field__grey"}
                          />
                        ) : (
                          <div className="value">{el.profileDetails.dateOfBirth}</div>
                        )}
                      </div>
                      {iscibilTrigger ? (
                        <div className=" value cibilTrigger_Success">CIBIL has been triggered successfully</div>
                      ) : (
                        <div className="btn_div">
                          <button
                            className="button_Generic blue-btn bold-btn"
                            type="button"
                            onClick={() => this.triggerCibilHandler(id)}
                          >
                            Trigger CIBIL
                          </button>

                          <div className="circle">
                            {this.state[id] ? (
                              <Icon
                                onClick={e => this.submitCibilExceptionData(id, el)}
                                className="icons"
                                icon={ic_check_circle}
                              />
                            ) : (
                              <Icon onClick={e => this.cibilEditDetailsHandler(id)} className="icons" icon={ic_edit} />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : null}
      </Fragment>
    );
  }
}
