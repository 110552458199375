import React, { PureComponent } from "react";
import ReactTable from "react-table";
import applicantsData from "./sectionA.config";
import Icon from "react-icons-kit";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getSpocDetails, getBusinessDetails, getBusinessAndProfileSummary } from "../cam.actions";
class SectionA extends PureComponent {
  componentDidMount = async () => {
    let { actions, match } = this.props,
      { getSpocDetails, getBusinessDetails, getBusinessAndProfileSummary } = actions,
      applicationNumber = get(match, "params.appNo", "");
    getSpocDetails(applicationNumber);
    getBusinessDetails(applicationNumber);
    await getBusinessAndProfileSummary(applicationNumber);
  };
  render() {
    let { camData, spocDetailsData, businessDetailsData, applicationStatusData, businessAndProfileData } = this.props;
    return (
      <React.Fragment>
        {businessDetailsData ? (
          <div className="box-wrapper">
            <div className="box-heading">Entity Details</div>
            <hr />
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">Name of Entity</div>
                <div>{businessDetailsData.businessName}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">Vintage of Entity</div>
                <div>{businessDetailsData.businessAge}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> CMR Rank </div>
                <div>{businessDetailsData.cmrRank}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">CC Score</div>
                <div>{applicationStatusData.ccScore}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">Email ID </div>
                <div>{businessDetailsData.businessEmail}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> Mob No. </div>
                <div>{businessDetailsData.contactNumber}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">PAN of Entity</div>
                <div>{businessDetailsData.pan}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> CIN (if exists) </div>
                <div>{businessDetailsData.cin}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> Entity Constitution</div>
                <div>{businessDetailsData.businessConstitutionType}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> Primary Industry</div>
                <div>{businessDetailsData.primaryBusinessNature}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> Secondary Industry</div>
                <div>{businessDetailsData.secondryBusinessNature}</div>
              </div>
              {/* <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">Business Type</div>
                <div>{businessDetailsData.businessType}</div>
              </div> */}
            </div>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading"> GST No. 1 </div>
                <div>{businessDetailsData.gstin}</div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="box-subheading">{businessDetailsData.addressType}</div>
                <div>{businessDetailsData.address}</div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="box-wrapper">
          <div className="box-heading">Applicants</div>
          <hr />
          <ReactTable
            columns={applicantsData}
            data={businessAndProfileData ? businessAndProfileData : []}
            resizable={false}
            showPagination={false}
            minRows={0}
          />
        </div>
        <div className="box-wrapper">
          <div className="box-heading">CC Score Spectrum</div>
          {applicationStatusData && businessDetailsData ? (
            <div className={"row cc-score-box"}>
              <div className={"col-md-1 col-sm-9"}>
                <label>CC score</label>
                <div className={"col-sm-9"}>
                  <span>{applicationStatusData.ccScore}</span>
                </div>
                <div className="vl"></div>
              </div>

              <div className={"col-md-2 guageChart"}>
                <div id={"gauge-chart"} />
              </div>
              <div className={"col-md-9 col-sm-9 chart"}>
                <ul>
                  <li>CC Score</li>
                  <hr />
                  <li>
                    below 500
                    {500 >= businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    520
                    {520 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    540
                    {540 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    560
                    {560 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    580
                    {580 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    600
                    {600 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    620
                    {620 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    640
                    {640 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    660
                    {660 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    680
                    {680 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    700
                    {700 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    720
                    {720 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    740
                    {740 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    760
                    {760 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    780
                    {780 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    800
                    {800 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    820
                    {820 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    840
                    {840 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    860
                    {860 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    880
                    {880 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    900
                    {900 === businessDetailsData.ccScore ? (
                      <span>
                        CC Score for {get(businessDetailsData, "businessName")}
                        <Icon icon={caretDown} size={20} />
                      </span>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
        {spocDetailsData ? (
          <div className="box-wrapper">
            <div className="box-heading">FPR/SPOC</div>
            <hr />
            <div className="row">
              <div className="col-lg-4 col-sm-4">
                <div className="box-subheading">Name</div>
                <div>{spocDetailsData.name}</div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="box-subheading">Designation</div>
                <div>{spocDetailsData.designation}</div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="box-subheading">Mob No.</div>
                <div>{spocDetailsData.mobile}</div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ CamResponse }) => ({
  spocDetailsData: CamResponse.spocDetailsData,
  businessDetailsData: CamResponse.businessDetailsData,
  applicationStatusData: CamResponse.applicationStatusData,
  businessAndProfileData: CamResponse.businessAndProfileData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSpocDetails,
      getBusinessDetails,
      getBusinessAndProfileSummary,
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionA);
