import React, { Component, Fragment } from "react";
import { Header } from "../../components";
import { Tabs, Tab } from "react-bootstrap";
import { SlideDown } from "react-slidedown";
import Icon from "react-icons-kit";
import { angleUp } from "react-icons-kit/fa/angleUp";
import { get, set } from "lodash";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general: {
        question1: false,
        question2: false,
        question3: false,
        question4: false,
        question5: false,
        question6: false,
      },
      gst: {
        question1: false,
        question2: false,
        question3: false,
      },
      otp: {
        question1: false,
        question2: false,
        question3: false,
      },
      banking: {
        question1: false,
      },
    };
  }

  slideToggle = (e, name) => {
    let { state } = this,
      objKey = name.split(".")[0],
      objProp = name.split(".")[1];
    Object.keys(get(state, objKey)).forEach((key) => {
      if (key === objProp) {
        set(state, `${objKey}.${key}`, !get(state, `${objKey}.${key}`));
      } else {
        get(state, `${objKey}.${key}`) && set(state, `${objKey}.${key}`, false);
      }
    });
    this.setState({ [objKey]: get(state, objKey) });
  };

  render() {
    const { general, gst, otp, banking } = this.state;
    return (
      <Fragment>
        <Header {...this.props} />
        <div className={"faq-wrapper container"}>
          <h4 className={"heading"}>FAQ's</h4>
          {/*<Tabs defaultActiveKey={'general'} onSelect={this.toggleTab} id="faq-tabs">*/}
          <Tabs defaultActiveKey={"general"} id="faq-tabs">
            <Tab eventKey={"general"} title={"General"}>
              <div className={`slide-wrapper ${general.question1 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question1")} className={"slide-header"}>
                  <h4>Information Checklist to File the Customer Application Form</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question1}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <ul>
                        <li>KYC of the business entity & Promoters associated </li>
                        <li>GST Number, Username & GST OTP</li>
                        <li>
                          12 months Bank Statements of all Business Accounts in PDF format downloaded through Net
                          Banking
                        </li>
                      </ul>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${general.question2 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question2")} className={"slide-header"}>
                  <h4>Summary of the key information required for each of the Application Page</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question2}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <table border="1">
                        <thead>
                          <tr>
                            <th>
                              <b>Sales Application Flow </b>
                            </th>
                            <th>
                              <b>Mandatory Fields</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Enter Loan Details </td>
                            <td> ALL Fields </td>
                          </tr>
                          <tr>
                            <td>GST Details </td>
                            <td>spanALL Fields</td>
                          </tr>
                          <tr>
                            <td>Application Details </td>
                            <td>ALL Fields</td>
                          </tr>
                          <tr>
                            <td>Banking Details </td>
                            <td>
                              All Bank accounts of entity need to upload <br />
                              (PDF format downloadable through net banking)
                            </td>
                          </tr>
                          <tr>
                            <td>Upload Documents </td>
                            <td>All KYC and mentioned Documents – PDF / Scanned / JPG</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${general.question3 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question3")} className={"slide-header"}>
                  <h4>What needs to be done in case entity is not a GST registered entity?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question3}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        You simply need to enter PAN of entity if entity is other than proprietorship and If it’s a
                        proprietorship firm then you need to enter PAN of Proprietor.
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${general.question4 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question4")} className={"slide-header"}>
                  <h4>Whether only one applicant is sufficient on applicant’s details page</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question4}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        Any application which have more than one applicant will always have very high chances of
                        approval in comparison to any application which have only one applicant.
                        <br /> So It is advisable to take any other shareholder, director, partner or any immediate
                        relative on deal..
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${general.question5 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question5")} className={"slide-header"}>
                  <h4>Who is an applicant?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question5}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <ul>
                        <li>
                          Primary Applicant would be the proprietor in case of proprietorship, any partner preferably
                          having the major share in partnership Firm/LLP and managing director or major shareholder in
                          case of Company.
                        </li>
                        <li>In case of a Partnership Firm, all Partners would be considered as Applicants.</li>
                        <li>
                          In case of an LLP/Incorporated Company, shareholders with at least 50% share would be
                          considered as Applicants.
                        </li>
                      </ul>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${general.question6 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "general.question6")} className={"slide-header"}>
                  <h4>Who can be co-applicant?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!general.question6}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <ul>
                        <li>
                          All Partners in case of partnership firm and shareholders and directors in case of a company.
                        </li>
                        <li>An immediate Family member (explained below).</li>
                      </ul>
                      <p>
                        Would be an immediate family member in case of Proprietorship.
                        <br />
                        Would be an immediate family member of partners in case of a partnership.
                        <br />
                        Would be an immediate family Member of any director/shareholder in company in case of Company.
                      </p>
                      <b>Immediate Family Member:</b>
                      <p>Spouse/Father/Mother/Son/Daughter.</p>
                    </div>
                  </div>
                </SlideDown>
              </div>
            </Tab>
            <Tab eventKey={"gst"} title={"GST"}>
              <div className={`slide-wrapper ${gst.question1 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "gst.question1")} className={"slide-header"}>
                  <h4>Is it mandatory to enter GST Number and user name to apply for Business Loan?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!gst.question1}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        Yes, for a GST registered entity it is mandatory to enter GST Number and GST user name to get
                        best <br />
                        Loan eligibility. In case the entity is not GST registered, you simply need to enter PAN of{" "}
                        <br />
                        entity if entity is other than proprietorship and If it’s a proprietorship firm then you need to
                        .<br />
                        enter PAN of Proprietor.
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${gst.question2 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "gst.question2")} className={"slide-header"}>
                  <h4>
                    Whether It is mandatory to enter details of all GST numbers &amp; user names availed by the entity?
                  </h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!gst.question2}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        Yes, It is mandatory and advisable to all applicants that they should enter all GST number and
                        user <br />
                        details along with respective OTP availed for any particular entity. As it will increase Loan{" "}
                        <br />
                        amount eligibility and enhance your Loan eligibility credit score.
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${gst.question3 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "gst.question3")} className={"slide-header"}>
                  <h4>What needs to be done in case entity is not a GST registered entity?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!gst.question3}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        You simply need to enter PAN of entity if entity is other than proprietorship and If it’s a
                        proprietorship firm then you need to enter PAN of Proprietor.{" "}
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
            </Tab>
            <Tab eventKey={"otp"} title={"OTP & API Access"}>
              <div className={`slide-wrapper ${otp.question1 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "otp.question1")} className={"slide-header"}>
                  <h4>What is Role of OTP and How it will be generated?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!otp.question1}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        Before you enter GST number and username on Applications page, you have to enable the API for
                        the respective GST number on the following website - https://services.gst.gov.in/services/login
                        Once API is enabled, then enter your GST number & username on Pemant portal and Click on
                        “Generate OTP” then GST OTP would be delivered on registered Mobile Number at GST portal. After
                        that you need to enter OTP and press “Fetch”. OTP will be Valid for 10 minutes only. OTP will
                        help us to ascertain your GST return details which would further enhance your Loan eligibility.
                        Please note that GST OTP is only used to assess the client’s loan eligibility and utmost
                        confidentiality is maintained.{" "}
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${otp.question2 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "otp.question2")} className={"slide-header"}>
                  <h4>What is role of “API access Not available”?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!otp.question2}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        If there is a message displaying (API access is not available or user expiry Duration is less
                        than or equal to auth token expiry duration), when you pressed “Generate OTP” then it means API
                        access not enabled in your account at GST Portal. You need to login through your GST Account
                        details at GST portal and enable it for 30 days. Now again generate OTP. You will get the OTP
                        this time. Rest process will remain same. Please refer video for further clarification. Link:
                        https://youtu.be/OhcfHufnkTo
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
              <div className={`slide-wrapper ${otp.question3 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "otp.question3")} className={"slide-header"}>
                  <h4>What would happen if OTP not delivered even if API access is enabled?</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!otp.question3}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        OTP may not be delivered if there is heavy load on GST portal or there is some technical error
                        on GST Portal. In that case you simply need to enter all GST user names and GST numbers and
                        click “save & next”. Now you can complete your application. Our executive will connect with you
                        to get OTP once GST portal is working fine.
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
            </Tab>
            <Tab eventKey={"banking"} title={"Banking"}>
              <div className={`slide-wrapper ${banking.question1 ? "open" : "closed"}`}>
                <div onClick={(e) => this.slideToggle(e, "banking.question1")} className={"slide-header"}>
                  <h4>Whether All Bank accounts need to be uploaded on portal.</h4>
                  <span className={"icon"}>
                    <Icon icon={angleUp} size={22} />
                  </span>
                </div>
                <SlideDown closed={!banking.question1}>
                  <div className={"slide-content"}>
                    <div className={"box-header"}>
                      <p>
                        Yes, it is important for compliance, moreover all current & limit accounts of your entity will
                        help us to provide you better loan eligibility scoring for your loan application. It may also
                        help system to enhance your loan eligibility.
                      </p>
                    </div>
                  </div>
                </SlideDown>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

export default FAQ;
