import * as CPOAdminUser from "./CPOAdmin.action.constant";
import * as CPOAdminSelector from "./CPOAdmin.selector";
const initialState = {
  userData: {
    list: [],
    listCount: 0,
    currentPage: 1,
    totalPages: 1,
  },
  cpoAdminData: {},
  roleId: [],
  searchBy: "",
  searchValue: "",
  pageNumber: 1,
};

export const cpoAdminReducers = (state = initialState, action) => {
  const { data, type, index, name, val, formIndex } = action;

  switch (type) {
    case CPOAdminUser.GET_USER_ROLE_ID:
      return { ...state, roleId: data };
    case CPOAdminUser.SET_USER_ADMIN_DATA:
      return {
        ...state,
        userData: {
          list: data?.list,
          listCount: data?.listCount,
          currentPage: data?.currentPage,
          totalPages: data?.totalPages,
        },
      };
    case CPOAdminUser.CHANGE_ADDRESS_BOX_ADDRESS_VALUE:
      return CPOAdminSelector._updateAddressValues(
        state,
        val,
        name,
        formIndex,
        index
      );
    case CPOAdminUser.RESET_REDUX_STATE:
      return { ...state, userData: [] };
    case CPOAdminUser.SET_USER_ADMIN_DATA:
      return { ...state, cpoAdminData: data };
    case CPOAdminUser.SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchBy: action.payload.searchBy,
        searchValue: action.payload.searchValue,
      };
    case CPOAdminUser.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
};
