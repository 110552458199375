import React, { Component } from "react";
import Icon from "react-icons-kit";
import { InputLabelAnimation } from "../../../components";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";

class PartyDetails extends Component {
  render() {
    let { rowIndex, deletePartyRow, changeHandler, partyDetail } = this.props,
      referenceRelations = [
        "Relative of Applicant/Co-Applicant",
        "Parent company",
        "Subsidiary Entity",
        "Associate Entity",
      ];
    return (
      <div className="row row-details">
        <div className={"col-md-5"}>
          <InputLabelAnimation
            labelText={`Name of Related Party #${rowIndex + 1} `}
            required={true}
            type={"text"}
            onChange={(e) => changeHandler(e.target.name, e.target.value)}
            name="relatedPartyName"
            inputValue={partyDetail.relatedPartyName}
          />
        </div>
        <div className={"col-md-5"}>
          <select
            className={"select-box_main box-details"}
            onChange={(e) => changeHandler(e.target.name, e.target.value)}
            name="relatedPartyRelation"
            value={partyDetail.relatedPartyRelation}
          >
            {referenceRelations.map((referenceList) => {
              return <option>{referenceList}</option>;
            })}
          </select>
        </div>
        <div className={"col-md-2"}>
          {rowIndex >= 0 ? (
            <span
              className="remove-icon remove float-right"
              title={"Delete"}
              onClick={() => deletePartyRow(rowIndex)}
            >
              <Icon icon={ic_delete_forever} size={26} />
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

export default PartyDetails;
