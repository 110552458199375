import React, { Component } from "react";
import ReactTable from "react-table";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_FILTER_MEMBERS } from "../../../utils/APIUrls";
import { loaderModal, setUserDetails, setUserLoginStatus } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { checkUserLogin } from "../../../store/actions";
import Icon from "react-icons-kit";
import { calendar } from "react-icons-kit/fa/calendar";
import { caretDown } from "react-icons-kit/fa/caretDown";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { getDateString, getDMY, getYMD } from "../../../utils/utitlity";
import { Helmet } from "react-helmet";
import { CredoModal } from "../../../components";
import { search } from "react-icons-kit/fa/search";
import Gating_Override from "../../../assets/images/gating_override.svg";
import return_bureau from "../../../assets/images/return_bureau.svg";
import GatingOverride from "./GatingOverride";
import RerunBureau from "./RerunBureau";
import { cloudDownload } from "react-icons-kit/icomoon/cloudDownload";
class LeadManagerDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: getYMD(new Date().toLocaleDateString()),
      endDate: getYMD(new Date().toLocaleDateString()),
      modalVisible: false,
      memberCount: {},
      maxDate: new Date(),
      statusFilter: "all",
      membersList: [],
      currentPage: 1,
      totalPages: 1,
      applicationsData: [],
      prevBtn: false,
      nextBtn: false,
      activeFigure: "members",
      allData: {},
      activeTabApplicant: "",
    };
  }
  componentDidMount() {
    this.fetchDetails(1, "");
  }
  getPageData = (type) => {
    let { currentPage, totalPages } = this.state;
    let count = currentPage;
    if (type === "next" && currentPage < totalPages) {
      this.setState({ currentPage: ++count }, () => {
        this.fetchDetails(this.state.currentPage, this.state.activeTabApplicant);
      });
    } else if (type === "prev" && currentPage > 1) {
      this.setState(
        {
          currentPage: currentPage - 1,
        },
        () => {
          this.fetchDetails(this.state.currentPage, this.state.activeTabApplicant);
        }
      );
    }
  };
  handleEvent = (event, picker) => {
    let startdate = new Date(picker.startDate);
    let enddate = new Date(picker.endDate);
    startdate = getDateString(startdate);
    enddate = getDateString(enddate);
    this.setState({
      startDate: getYMD(new Date(startdate).toLocaleDateString()),
      endDate: getYMD(new Date(enddate).toLocaleDateString()),
      activeTabApplicant: "All",
      isDateApplied: true,
    });
  };
  applicantTabToggle = async (item) => {
    let { activeTabApplicant } = this.state;
    this.setState({ currentPage: 1, activeTabApplicant: item }, () => {
      if (activeTabApplicant !== item) {
        this.fetchDetails(this.state.currentPage, item);
      }
    });
  };

  searchByFiltersHandler = () => {
    let { searchFilter, searchInput, endDate, startDate, actions } = this.props;
    if (!this.state.isDateApplied) {
      endDate = startDate = null;
    }
    if (!searchInput) {
      searchInput = null;
    }
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: 1,
        param: {
          key: searchInput,
          startDate: startDate,
          endDate: endDate,
        },
        statusFilter: this.state.statusFilter === "all" ? null : this.state.statusFilter || null,
        type: searchFilter.value || "ALL",
      },
    };
    actions.fetchDashBoardData({ ...dataToSend });
  };
  fetchDetails = async (currentPage, gatingStatusFilter) => {
    let { actions } = this.props,
      { loaderModal } = actions;
    let dataToSend = {
      data: {
        pageNumber: currentPage,
        cluster: "Footwear",
        state: "Uttar Pradesh",
        gatingStatusFilter,
      },
    };
    loaderModal(true);
    await POST(API_POST_FILTER_MEMBERS, dataToSend)
      .then(({ data, status }) => {
        if (data?.data) {
          this.setState({
            allData: data.data,
            membersList: data.data.membersResponse,
            memberCount: data.data.membersCountResult,
            totalPages: data.data.totalPage,
          });
        } else {
          this.setState({
            membersList: [],
          });
        }
      })
      .catch((err) => {
        window.alert("Something went wrong please try again later.");
      });

    loaderModal(false);
  };

  rowClicked = (state, rowInfo, column, instance) => {
    if (rowInfo && column.Header !== "Gating Override" && column.Header !== "Rerun Bureau") {
      return {
        onClick: () => {
          var win = window.open(`/lead_manager/${rowInfo.original.memberId}/overview`, "_blank");
          win.focus();
        },
      };
    }
    return false;
  };
  toggleModal = (name, memberId) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      modalType: name,
      memberId,
    });
  };
  formatddmmyy = (date) => {
    return getDMY(date).split("-").join("-");
  };
  render() {
    let {
      totalPages,
      currentPage,
      memberCount,
      allData,
      activeTabApplicant,
      isModalOpen,
      membersList,
      modalType,
      startDate,
      endDate,
    } = this.state;
    const data = membersList || [];
    const columns = [
      {
        Header: "Date",
        accessor: "date",

        Cell: ({ original }) => <div>{original.date}</div>,
      },
      {
        Header: "Member ID ",
        accessor: "memberId",
      },
      {
        Header: "Business Name",
        accessor: "businessName",
        width: 150,
      },
      {
        Header: "Promoter Name",
        accessor: "promoterName",
        width: 150,
      },
      {
        Header: "Mobile No.",
        accessor: "mobile",
      },
      {
        Header: "GST No.",
        accessor: "gstIn",
        width: 160,
      },
      {
        Header: "Gating",
        accessor: "gatingStatus",
        Cell: ({ original }) => {
          let { gatingStatus } = original;
          return (
            <button
              style={{
                color:
                  gatingStatus === "Approved" || gatingStatus === "Auto Approved" || gatingStatus === "Auto Approved"
                    ? " #00CDBE"
                    : gatingStatus === "Rejected" || gatingStatus === "Auto Rejected" || gatingStatus === "Auto Rejected"
                    ? "#ED5A5A"
                    : gatingStatus === "Refer"
                    ? "#E7B93C"
                    : "#969696",
                backgroundColor:
                  gatingStatus === "Approved" || gatingStatus === "Auto Approved" || gatingStatus === "Auto Approved"
                    ? "#E5FAF8"
                    : gatingStatus === "Rejected" || gatingStatus === "Auto Rejected" || gatingStatus === "Auto Rejected"
                    ? "#F9EAEA"
                    : gatingStatus === "Refer"
                    ? "#FDFAF1"
                    : "#E4E4E4",
                borderRadius: "20px",
                height: "auto",
                outline: "none",
                width: "100px",
                borderStyle: "none",
                fontFamily: "Helvetica",
                lineHeight: "1.19",
                letterSpacing: "0",
                padding: " 7px 6px",
                fontSize: "13px",
              }}
            >
              {gatingStatus}
            </button>
          );
        },
      },
      {
        Header: "Gating Override",
        accessor: "",
        Cell: ({ original }) => {
          let { gatingStatus } = original;
          return (
            <div>
              {gatingStatus === "Refer" ? (
                <img
                  src={Gating_Override}
                  alt="action button"
                  className="gating-status__logo"
                  onClick={() => this.toggleModal(GatingOverride, original.memberId)}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        Header: "Rerun Bureau",
        accessor: "",
        Cell: ({ original }) => {
          return (
            <img
              src={return_bureau}
              alt="action button"
              className="gating-status__logo"
              onClick={() => this.toggleModal(RerunBureau, original.memberId)}
            />
          );
        },
      },
    ];

    return (
      <>
        <div className="dashboard-wrapper">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Dashboard</title>
          </Helmet>
          <div className={"daterange-bar-wrapper"}>
            <div className={"container"}>
              <div className={"daterange-box"}>
                <span>Search by Date</span>
                <DateRangePicker onApply={this.handleEvent} showDropdowns maxDate={this.state.maxDate}>
                  <button>
                    <span className={"icons"}>
                      <Icon icon={calendar} size={16} />
                    </span>
                    <span>
                      {getDateString(startDate)} - {getDateString(endDate)}
                    </span>
                    <span className={"icons"}>
                      <Icon icon={caretDown} size={16} />
                    </span>
                  </button>
                </DateRangePicker>
                <span className={"search-icon"} onClick={this.searchByFiltersHandler}>
                  <Icon icon={search} size={16} />
                </span>
                <div className="excel-download">
                  <a
                    href={`/Backend/download-excel/${this.formatddmmyy(startDate)}/${this.formatddmmyy(endDate)}`}
                    download
                  >
                    <Icon icon={cloudDownload} size={20} className="mr-2" />
                    Excel
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={"container"}>
            <div className={"applicants-list-wrapper"}>
              <ul className={"list-unstyled"}>
                <li onClick={() => this.applicantTabToggle("")} className={activeTabApplicant === "" ? "active" : null}>
                  <span>{allData.allMembersCount || "0"}</span>
                  <span>All Members</span>
                </li>
                <li
                  onClick={() => this.applicantTabToggle("Refer")}
                  className={activeTabApplicant === "Refer" ? "active" : null}
                >
                  <span>{memberCount.referred || "0"}</span>
                  <span>Referred</span>
                </li>
                <li
                  onClick={() => this.applicantTabToggle("Approved")}
                  className={activeTabApplicant === "Approved" ? "active" : null}
                >
                  <span>{memberCount.approved || "0"}</span>
                  <span>Approved</span>
                </li>
                <li
                  onClick={() => this.applicantTabToggle("Pending")}
                  className={activeTabApplicant === "Pending" ? "active" : null}
                >
                  <span>{memberCount.pending || "0"}</span>
                  <span> Pending </span>
                </li>
                <li
                  onClick={() => this.applicantTabToggle("Rejected")}
                  className={activeTabApplicant === "Rejected" ? "active" : null}
                >
                  <span>{memberCount.rejected || "0"}</span>
                  <span>Rejected</span>
                </li>
              </ul>
            </div>

            <div className={"datatable-wrapper container"}>
              <ReactTable
                data={data}
                columns={columns}
                defaultPageSize={10}
                resizable={false}
                showPagination={false}
                minRows={0}
                getTdProps={this.rowClicked}
              />
              <ul className={"datatable-pagination"}>
                <li>
                  <button
                    disabled={!(currentPage > 1)}
                    className={"prev-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("prev", activeTabApplicant)}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {currentPage} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > currentPage)}
                    className={"next-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("next", activeTabApplicant)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <CredoModal
          className={"red-flag__wrapper normal-curved-borders"}
          isOpen={isModalOpen}
          memberId={this.state.memberId}
          styles={{
            content: {
              width: "400px",
            },
          }}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={modalType}
          fetchDetails={() => this.fetchDetails(currentPage, activeTabApplicant)}
        />
      </>
    );
  }
}
const mapStateToProps = ({ data, header }) => ({
  userDetails: data.userDetails,
  isLogin: data.isLogin,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserLoginStatus,
      setUserDetails,
      loaderModal,
      checkUserLogin,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadManagerDashboard);
