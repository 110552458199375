import * as DashboardConstant from './dashboard.actions.constant';
import * as DashboardSelector from './dashboard.selectors';

const initialState = {
    searchByFilters: {
        byTabs: true,
        byDate: false
    }

};

export const salesDashboard = (state = initialState, action) => {
    const {type, name} = action;
    switch (type) {
        case DashboardConstant.SET_SEARCH_BY_STATUS:
            return DashboardSelector._changeSearchByFiltersStataus(state, name);
        default:
            return state;
    }
};
