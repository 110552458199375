import React, { Component, Fragment } from "react";
import { Icon } from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import DocumentBox from "./DocumentBox";
import { Header } from "../../../components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import {
  getEntityDocs,
  getDocuments,
  fetchUserLocation,
} from "./Photo.actions";
import { API_POST_COMMENT } from "../../../utils/APIUrls";
import {  POST } from "../../../utils/webAPI.service";

class Photo extends Component {
  state = {
    activeFile: "save",
    comment:null,
  };
  inputHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  createAppHandler = (actionType) => {
    let { actions, match, history } = this.props;
    let data = {
      comment: this.state.comment,
      customerId: match.params.custId,
      commentedBy:"Sales"
    };
    
    POST(API_POST_COMMENT, { data })
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "submit") {
            history.push(`/cluster/${match.params.custId}/cluster-preview/${match.params.clusterType}`);
          } else if (actionType === "loan") {
            history.push(`/cluster/${match.params.custId}/gating-status/${match.params.clusterType}`);
          }
        } else if (data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };

  render() {
    let {  comment } = this.state,
      {  match, entityDocs, fetchedDocs } = this.props;
    return (
      <>
        <Header {...this.props} />
        <div className="photo-wrapper">
          <form className="container">
            <Link
              to={`/cluster/${match.params.custId}/business-size/${match.params.clusterType}`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading"> Photos</h1>
            <div className="container   p-top--sub ">
              <h2 className="sub-heading"> Photos</h2>
              <div className="row">
                <div className="col-sm-6">
                  <DocumentBox
                    docType={"Member Photo"}
                    {...this.props}
                    entity={"member"}
                    fileDetails={[fetchedDocs.memberDoc]}
                  />
                </div>
                <div className="col-sm-6">
                  <DocumentBox
                    docType={"Unit Photo"}
                    {...this.props}
                    entity={"item"}
                    fileDetails={fetchedDocs.itemDocs}
                  />
                </div>

                {entityDocs.map((el, index) => {
                  let fileDetails = [];
                  let a = fetchedDocs?.entityDocs?.forEach((e) => {
                    if (e.documentType === el.docType) {
                      fileDetails.push(e);
                    }
                  });
                  return (
                    <div className="col-sm-6" key={`document-${index}`}>
                      <DocumentBox
                        docType={el.docType}
                        docsName={el.docName}
                        {...this.props}
                        entity={"Entity"}
                        fileDetails={fileDetails}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="container   p-top--sub ">
              <h2 className="sub-heading"> Comments</h2>
              <textarea
                className="text-area"
                placeholder="  Write here... "
                cols="30"
                rows="6"
                name="comment"
                value={comment}
                onChange={(e) => this.inputHandler(e)}
              ></textarea>
            </div>
            <div className="btn-box">
              <span
                onClick={() => this.createAppHandler("loan")}
                className={"btn-draft"}
              >
                Loan Application
              </span>
              <span
                onClick={() => this.createAppHandler("submit")}
                className={"btn-save"}
              >
                Submit
              </span>
            </div>
          </form>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    let { actions, entityDocs, match, userLocation } = this.props;
    actions.loaderModal(true);
    await (!entityDocs.length && actions.getEntityDocs());
    actions.loaderModal(false);
    actions.getDocuments(match.params.custId);
    !userLocation && actions.fetchUserLocation();
  };
}

const mapStateToProps = ({ ClusterPhoto }) => ({
  entityDocs: ClusterPhoto.entityDocs,
  fetchedDocs: ClusterPhoto.fetchedDocs,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getEntityDocs,
      getDocuments,
      fetchUserLocation,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Photo);
