import React, { useState, useEffect } from "react";
import { PUT } from "../../../utils/webAPI.service";
import { API_CPO_REOPEN_CASE } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import { API_EXCEPTION_LIST } from "../../../utils/APIUrls";
import { get } from "lodash";
export function SelectComponent({
  applicationNumber,
  fetchDetails,
  currentTab,
}) {
  const [selectedVal, setSelectedVal] = useState("");
  const [exceptionList, setExceptionList] = useState("");
  const inputHandler = async (event) => {
    setSelectedVal(event.target.value);
    PUT(API_CPO_REOPEN_CASE(applicationNumber, event.target.value)).then(
      ({ data }) => {
        if (data?.status === "success") {
          fetchDetails();
          setSelectedVal("");
        } else {
          alert(data?.message);
          setSelectedVal("");
        }
      }
    );
  };

  const getDocument = (applicationNumber) => {
    GET(API_EXCEPTION_LIST(applicationNumber))
      .then(({ data }) => {
        setExceptionList(data);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    if (currentTab === "Closed") {
      getDocument(applicationNumber);
    }
  }, [applicationNumber]);
  let { data } = exceptionList || {};
  let { responseList } = data || [];
  return (
    <select
      className={"select-box_main"}
      name={"selectException"}
      value={selectedVal}
      onChange={inputHandler}
    >
      <option>Select Exception</option>
      {responseList?.map((element, index) => {
        return <option key={index}>{element}</option>;
      })}
    </select>
  );
}
