import React, { Component } from "react";
import BankingRow from "./BankingRow";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import { RadioBox } from "../../../components";
import Select from "react-select";
import { InputLabelAnimation } from "../../../components";
import { connect } from "react-redux";
import {
  deleteFormBox,
  addFormBox,
  changeInputValue,
  addFileRow,
} from "./banking.actions";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { updateErrorMessage } from "../../../store/actions";
import { API_BANKING_REMOVE_ACCOUNT } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";

class BankingForm extends Component {
  addFormBox = () => {
    let { addFormBox } = this.props.actions;
    addFormBox();
  };

  inputHandler = (name, value) => {
    const { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions;
    changeInputValue(name, value, index);
    updateErrorMessage("");
  };

  addRow = () => {
    let { actions, index } = this.props,
      { addFileRow } = actions;
    addFileRow(index);
  };

  setSanctionLimit = (e) => this.inputHandler(e.target.name, e.target.value);
  setIfsc = (e) => this.inputHandler(e.target.name, e.target.value);
  setBank = (e) => {
    this.inputHandler("bankName", e.label);
    this.props.actions.updateErrorMessage("");
  };

  render() {
    const { index, bankNames, bankingDetails } = this.props,
      accountType = get(bankingDetails, `[${index}].accountType`, ""),
      sanctionLimit = get(bankingDetails, `[${index}].sanctionLimit`, ""),
      bankName = get(bankingDetails, `[${index}].bankName`, ""),
      files = get(bankingDetails, `[${index}].files`),
      accountNumber = get(bankingDetails, `[${index}].accountNumber`),
      ifscManual = get(bankingDetails, `[${index}].ifscManual`, "");
    return (
      <div className={"form-box"}>
        <div className={"box-header"}>
          <h5>
            Account No. {index + 1}
            <span
              onClick={() => this.props.deleteBox(index, accountNumber)}
              className={"remove-icon"}
              title={"Delete"}
            >
              <Icon icon={ic_delete_forever} size={26} />
            </span>
          </h5>
          {index === bankingDetails.length - 1 ? (
            <button
              onClick={this.addFormBox}
              type={"button"}
              className={"add-btn"}
            >
              <Icon icon={ic_add} size={22} /> Add Account
            </button>
          ) : null}
        </div>

        <Select
          value={
            bankName
              ? bankNames.find((option) => option.label === bankName?.trim())
              : null
          }
          placeholder={"Select Bank"}
          isSearchable={true}
          name={"bankName"}
          options={bankNames}
          className="bank-select-search"
          classNamePrefix="select"
          onChange={this.setBank}
        />

        <div className={"account-type-box"}>
          <div className={"account-type"}>
            <div>
              <RadioBox
                radioColor={
                  accountType === "Savings Account" ? "#4687F4" : "#747474"
                }
                checked={accountType === "Savings Account"}
                labelFor={`savings-account-${index + 1}`}
                name={`account-type-${index + 1}`}
                onChange={() =>
                  this.inputHandler("accountType", "Savings Account")
                }
              />
              <label
                className={"input-label cursor-p"}
                htmlFor={`savings-account-${index + 1}`}
              >
                Saving Account
              </label>
            </div>
            <div className="fields">
              <input
                type={"text"}
                placeholder={"Enter IFSC code"}
                className={"input-field"}
                name={"ifscManual"}
                value={ifscManual}
                onChange={this.setIfsc}
              />
            </div>
          </div>

          <div className={"account-type"}>
            <div>
              <RadioBox
                defaultChecked={true}
                radioColor={
                  accountType === "CURRENT" || accountType === "Current Account"
                    ? "#4687F4"
                    : "#747474"
                }
                checked={
                  accountType === "Current Account" || accountType === "CURRENT"
                }
                labelFor={`current-account-${index + 1}`}
                name={`account-type-${index + 1}`}
                onChange={() =>
                  this.inputHandler("accountType", "Current Account")
                }
              />
              <label
                className={"input-label cursor-p"}
                htmlFor={`current-account-${index + 1}`}
              >
                Current Account
              </label>
            </div>
          </div>

          <div className={"account-type"}>
            <div>
              <RadioBox
                radioColor={
                  accountType === "CC/OD Account" ? "#4687F4" : "#747474"
                }
                checked={accountType === "CC/OD Account"}
                labelFor={`credit-account-${index + 1}`}
                name={`account-type-${index + 1}`}
                onChange={() =>
                  this.inputHandler("accountType", "CC/OD Account")
                }
              />
              <label
                className={"input-label cursor-p"}
                htmlFor={`credit-account-${index + 1}`}
              >
                CC/OD Account
              </label>
            </div>
            <input
              type={"text"}
              placeholder={"Enter Sanctioned Limit (in Lakh Rupees)"}
              className={
                accountType === "CC/OD Account"
                  ? "input-field"
                  : "input-field readonly"
              }
              // readOnly={accountType === "CC/OD Account"}
              name={"sanctionLimit"}
              value={sanctionLimit}
              onChange={this.setSanctionLimit}
            />
          </div>
        </div>
        <div className={"files-wrapper"}>
          <h3>Upload Bank Statements In PDF Format</h3>
          <div className={"files-box"}>
            {files.map((_, i) => (
              <BankingRow key={`rows${i}`} formIndex={index} fileIndex={i} />
            ))}
          </div>
          <button onClick={this.addRow} type={"button"}>
            <span>
              <Icon icon={ic_add} size={24} />
            </span>
            <span>Upload More Statements</span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ listData, bankingReducers }) => ({
  bankNames: listData.bankNamesList,
  bankingDetails: bankingReducers.bankingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      deleteFormBox,
      addFormBox,
      changeInputValue,
      addFileRow,
      updateErrorMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BankingForm);
