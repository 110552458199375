import React, { Component, Fragment } from "react";
import { POST } from "../../../utils/webAPI.service";
import { API_GENERATE_VALIDATE_OTP_LINK } from "../../../utils/APIUrls";

export default class ShareOtpLink extends Component {
  shareOtpHandler = async () => {
    let { actions, match, gstin, gstUsername } = this.props,
      { loaderModal } = actions,
      { appNo } = match.params,
      { email, mobileNumber } = this.state,
      dataToSend = {
        data: {
          applicationNumber: appNo,
          email,
          gstUsername,
          gstin,
          isDataFetched: true,
          mobileNumber
        }
      };
    loaderModal(true);
    await POST(API_GENERATE_VALIDATE_OTP_LINK, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.props.closeAction();
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  inputHandler = e => {
    var name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  render() {
    
    return (
      <Fragment>
        <div className="otpLink-form">
          <label>Please enter phone number or email ID to send tbe OTP link to:</label>
          <div className="row">
            <div className="col-lg-4 p-0">
              <input
                placeholder="Mobile Number"
                name="mobileNumber"
                onChange={this.inputHandler}
                required={true}
                maxLength={10}
                type="number"
                max={9999999999}
              />
            </div>
            <div className="col-lg-5 p-0">
              <input
                placeholder="Email"
                name="email"
                onChange={this.inputHandler}
                id="email"
                required={true}
                tabIndex={2}
              />
            </div>
          </div>
          <div className="row">
            <button className="submit" onClick={this.shareOtpHandler}>
              Send the link
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}
