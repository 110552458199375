import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { getClusterOverview } from "./clusterPreview.actions.js";
import { Table } from "react-bootstrap";
import { CredoModal, Header } from "../../../components";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import {
  API_GET_BUREAU_STATUS,
  API_GET_SUBMIT_PREVIEW,
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
import CRQModal from "./CRQModal";

class ClusterPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFigure: "save member",
      crqData: {},
    };
  }
  componentDidMount() {
    let { actions, match } = this.props;
    const { custId } = match.params;
    actions.getClusterOverview(custId);
  }
  submitApplication = (route) => {
    let { history, match } = this.props;
    GET(API_GET_SUBMIT_PREVIEW(match.params.custId))
      .then(({ data }) => {
        if (data.status === "success") {
          history.push(route);
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  saveMemberHandler = async () => {
    let isNextScreen = await this.crqHandler();
    this.setState({ CTA: "saveMember" });
    if (isNextScreen) {
      this.submitApplication(`/cluster/dashboard`);
    }
  };
  loanApplicationHandler = async () => {
    let { match } = this.props;
    let isNextScreen = await this.crqHandler();
    if (isNextScreen) {
      this.submitApplication(
        `/cluster/${match.params.custId}/gating-status/${match.params.clusterType}`
      );
    }
  };
  crqHandler = () => {
    let { match } = this.props;
    return GET(API_GET_BUREAU_STATUS(match.params.custId))
      .then(({ data }) => {
        if (data.message === "Bureau data available.") {
          return true;
        } else if (data.data.status) {
          this.setState({ crqData: data.data, modalOpen: true });
          return false;
        } else {
          return true;
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    const { clusterOverviewDetails, match } = this.props,
      { modalOpen, crqData, CTA } = this.state;
    return (
      <div className={"cluster-preview-wrapper"}>
        <Header {...this.props} />
        <div className=" preview-navigation-bar">
          <Link
            to={`/cluster/${match.params.custId}/photo/${match.params.clusterType}`}
            className={" d-flex"}
          >
            <Icon className="icon" icon={angleLeft} size={24} />
            <div className="heading">
              {clusterOverviewDetails?.basicDetails?.businessName}
            </div>
          </Link>
          <div>
            <button
              onClick={(e) => this.loanApplicationHandler(e)}
              className={"button__active"}
            >
              Loan Application
            </button>
            <button
              onClick={(e) => this.saveMemberHandler(e)}
              className={" button__generic"}
            >
              Save Member
            </button>
          </div>
        </div>
        <div className="content-wrapper">
          <div className={"row"}>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>Basic Details </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Business Name</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.businessName ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6 "}>
                      <label>Member ID</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.memberId || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Required Loan</label>
                      <span>
                        {" "}
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.basicDetails
                            ?.requiredLoanAmount
                        ) || "-"}{" "}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>State</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.clusterState ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Cluster</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.clusterName ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Commencement Date</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails
                          ?.commencemenDate || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label> PAN of Entity</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.businessPan ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Udyog Aadhar Number</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.businessAadhar ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>GST Number</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.gstin || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label> Operating states</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails
                          ?.operatingStates || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Nature of Business</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.businessNature ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Nature of Entity</label>
                      <span>
                        {clusterOverviewDetails?.basicDetails?.entityNature ||
                          "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>Owner Details </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Business Owner Name</label>
                      <span>
                        {clusterOverviewDetails?.ownerDetails?.ownerName || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Father’s Name</label>
                      <span>
                        {" "}
                        {clusterOverviewDetails?.ownerDetails?.fatherName ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Gender</label>
                      <span>
                        {" "}
                        {clusterOverviewDetails?.ownerDetails?.gender || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>PAN of Main owner</label>
                      <span>
                        {" "}
                        {clusterOverviewDetails?.ownerDetails?.ownerPan || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Mobile Number</label>
                      <span>
                        {" "}
                        {clusterOverviewDetails?.ownerDetails?.mobile || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label> DoB </label>
                      <span>
                        {" "}
                        {clusterOverviewDetails?.ownerDetails?.dob || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>
                      Business member organizations{" "}
                    </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-md-12 p-around"}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th> Name</th>
                            <th> Registration Number </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clusterOverviewDetails?.memberOrganizations?.length >
                            0 &&
                            clusterOverviewDetails.memberOrganizations.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{item.name || "-"}</td>
                                  <td>{item.registerationNumber || "-"} </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>
                      Other members involved in business
                    </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-md-12 p-around"}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th> Name</th>
                            <th>Relation </th>
                          </tr>
                        </thead>
                        <tbody>
                          {clusterOverviewDetails?.otherBusinessMembers
                            ?.length > 0 &&
                            clusterOverviewDetails.otherBusinessMembers.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.name || "-"}</td>
                                    <td>{item.relation || "-"}</td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>
                      Address of Business Premises{" "}
                    </h3>
                  </div>
                  {clusterOverviewDetails?.businesAddresses?.length > 0 &&
                    clusterOverviewDetails.businesAddresses.map(
                      (item, index) => {
                        return (
                          <div
                            key={index}
                            className={"row box-details box-wrapper m-top "}
                          >
                            <div className={"row address"}>
                              Address {index + 1}
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label> Address type </label>
                              <span>{item.addressType || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label> Floor/Building number </label>
                              <span>{item.line1 || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>Street/Landmark </label>
                              <span>{item.line2 || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>PINCODE</label>
                              <span>{item.pinCode || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>City </label>
                              <span>{item.city || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>State </label>
                              <span>{item.state || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>Ownership </label>
                              <span>{item.ownership || "-"}</span>
                            </div>
                            <div className={"col-sm-4 col-xs-6"}>
                              <label>Operating since</label>
                              <span>{item.durationOfStay || "-"}</span>
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}> Bank account details</h3>
                  </div>
                  {clusterOverviewDetails?.bankAccounts?.length > 0 &&
                    clusterOverviewDetails.bankAccounts.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={"row box-details box-wrapper m-top "}
                        >
                          <div className={"row address"}>
                            Account {index + 1}
                          </div>
                          <div className={"col-sm-4 col-xs-6"}>
                            <label> Bank </label>
                            <span>{item.bankName || "-"}</span>
                          </div>
                          <div className={"col-sm-4 col-xs-6"}>
                            <label>Account type </label>
                            <span>{item.accountType || "-"}</span>
                          </div>
                          <div className={"col-sm-4 col-xs-6"}>
                            <label>Account Number</label>
                            <span>{item.accountNumber || "-"}</span>
                          </div>
                          <div className={"col-sm-4 col-xs-6"}>
                            <label>Since </label>
                            <span>
                              {item.months} - {item.years}
                            </span>
                          </div>
                          <div className={"col-sm-4 col-xs-6"}>
                            <label>Transactions kind </label>
                            <span>{item.transactionType || "-"}</span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}> Income Details</h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label> Average sales per month</label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.incomeDetails
                            ?.averageSalesPerMonth
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Percentage margin (in %) </label>
                      <span>
                        {clusterOverviewDetails?.incomeDetails?.averageMargin ||
                          "-"}{" "}
                        %
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Monthly business income </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.incomeDetails?.monthlyIncome
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Other income source </label>
                      {clusterOverviewDetails?.incomeDetails
                        ?.anyOtherIncomeSource === true ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Other monthly income </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.incomeDetails
                            ?.otherSourceMonthlyIncome
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Sole earner </label>
                      {clusterOverviewDetails?.incomeDetails?.isSoleEarner ===
                      true ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                    <div className={"col-sm-4 col-xs-6"}>
                      <label>Other earning members </label>
                      <span>
                        {clusterOverviewDetails?.incomeDetails
                          ?.otherEarningMembers || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}> Employee Details</h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-xs-6"}>
                      <label> Fixed wage employees</label>
                      <span>
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.employeeDetails
                            ?.fixedWageEmployeeCount
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Daily wage employees</label>
                      <span>
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.employeeDetails
                            ?.dailyWageEmployeeCount
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Monthly working days </label>
                      <span>
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.employeeDetails
                            ?.workingDaysInMonth
                        ) || "-"}{" "}
                        days
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Average monthly wage </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.employeeDetails
                            ?.averageWagePerMonth
                        ) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>Asset Ownership </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-md-12 p-around"}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Asset Name</th>
                            <th>Year of ownership </th>
                            <th>Payment method</th>
                            <th> Monthly EMI</th>
                          </tr>
                        </thead>
                        <tbody>
                          {clusterOverviewDetails &&
                            clusterOverviewDetails.assetsDetails &&
                            clusterOverviewDetails?.assetsDetails?.length > 0 &&
                            clusterOverviewDetails.assetsDetails.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.assetName || "-"}</td>
                                    <td>{item.yearOfOwnership || "-"}</td>
                                    <td>{item.paymentMethod || "-"}</td>
                                    <td>
                                      {" "}
                                      ₹{" "}
                                      {rupeeCommaFormatter(item.monthlyEmi) ||
                                        "-"}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}> Other Details</h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-xs-6 "}>
                      <label> Supply for</label>
                      <span>
                        {clusterOverviewDetails?.otherDetails?.marketType ||
                          "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Required to grow business</label>
                      <span>
                        {clusterOverviewDetails?.otherDetails
                          ?.requirementToGrowBusiness || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Number of units produced/sold in a day</label>
                      <span>
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.otherDetails?.unitsSoldPerDay
                        ) || "-"}{" "}
                        units
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Average selling price per unit </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.otherDetails
                            ?.averageSellingPricePerUnit
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Purchase credit cycle</label>
                      <span>
                        {clusterOverviewDetails?.otherDetails
                          ?.purchaseCreditCycle || "-"}{" "}
                        days
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Receivable credit cycle </label>
                      <span>
                        {clusterOverviewDetails?.otherDetails
                          ?.receivableCreditCycle || "-"}{" "}
                        days
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Discount on Parcha issued by Agent </label>
                      <span>
                        {clusterOverviewDetails?.otherDetails
                          ?.discountOnParcha || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Interest paid for Parcha discount </label>
                      <span>
                        {clusterOverviewDetails?.otherDetails
                          ?.percentageInterestPaid || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Investment in Plants & machinery </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.otherDetails
                            ?.plantAndMachineryInvestment
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Electricity bill per month </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.otherDetails
                            ?.electricityBillPerMonth
                        ) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}>
                      {" "}
                      Funds and Obligations details
                    </h3>
                  </div>
                  <div className={"row box-details"}>
                    <div className={"col-xs-6"}>
                      <label> Business started by Self</label>
                      {clusterOverviewDetails?.fundAndObligationDetails
                        ?.isBusinessSelfStarted === true ? (
                        <span>Yes</span>
                      ) : (
                        <span>No</span>
                      )}
                    </div>
                    <div className={"col-xs-6"}>
                      <label> Monthly expense at home </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.fundAndObligationDetails
                            ?.monthlyHouseholdExpenses
                        ) || "-"}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Borrowed funds</label>
                      <span>
                        {clusterOverviewDetails?.fundAndObligationDetails
                          ?.fundsBorrowedFromFamilyFriends === true ? (
                          <span>Yes</span>
                        ) : (
                          <span>No</span>
                        )}
                      </span>
                    </div>
                    <div className={"col-xs-6"}>
                      <label>Monthly obligation </label>
                      <span>
                        ₹{" "}
                        {rupeeCommaFormatter(
                          clusterOverviewDetails?.fundAndObligationDetails
                            ?.monthlyObligation
                        ) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper border-left"}>
                <div className={"box-content"}>
                  <div className={"box-header"}>
                    <h3 className={"box-heading"}> Comments</h3>
                  </div>
                  <div className={"row box-details"}>
                    {clusterOverviewDetails?.clusterComments?.map((comment) =>
                      comment.commentedBy === "Sales" ? (
                        <div className={"col-xs-6"}>
                          {comment?.comment || "NA"}
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          props={this.props}
          title={"CRQ"}
          closeAction={this.toggleModal}
          RenderingComponent={CRQModal}
          crqData={crqData}
          className="crq-modal"
          customerId={match.params.custId}
          crqHandler={
            CTA === "saveMember"
              ? this.saveMemberHandler
              : this.loanApplicationHandler
          }
          submitApplicationHandler={
            CTA === "saveMember"
              ? this.saveMemberHandler
              : this.loanApplicationHandler
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ clusterPreviewReducer }) => ({
  clusterOverviewDetails: clusterPreviewReducer.clusterOverviewDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getClusterOverview,
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterPreview);
