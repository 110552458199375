const MAHTArray = [
  {
    label: "Manufacturing of Mother Machine",
    name: "manufacturingOfMotherMachine",
  },
  {
    label: "Manufacturing of Hand Tools",
    name: "manufacturingOfHandTools",
  },
  {
    label: "Forging Work",
    name: "forgingWork",
  },
];
const machineryArray = [
  {
    label: "Lathe Machine",
    name: "latheMachine",
  },
  {
    label: "Grinding Machine",
    name: "grindingMachine",
  },
  {
    label: "Pressing Machine",
    name: "pressingMachine",
  },
  {
    label: "Casting machinery",
    name: "castingMachine",
  },
  {
    label: "Forging machinery",
    name: "forgingMachinery",
  },
  {
    label: "Drilling machinery",
    name: "drillingMachine",
  },
  {
    label: "CNC Machine",
    name: "cncMachine",
  },
  {
    label: "Cutting Machine",
    name: "cuttingMachine",
  },
];
export { MAHTArray ,machineryArray };
