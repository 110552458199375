import React, { Component } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { API_POST_RERUN_BUREAU } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";

class RerunBureau extends Component {
  state = {
    isLoading: false,
  };
  render() {
    let { memberId } = this.props,
      { isLoading } = this.state;
    return (
      <div className=" mb-3 gating-wrapper">
        <h3 className="mb-2">Rerun Bureau</h3>
        {isLoading ? (
          <>
            <p>Please wait while we rerun Bureau for</p>
            <span className="primary-color">Member Id {memberId}</span>
            <div className="justify-content-center mt-2">
              <FadeLoader size={10} color={"#123abc"} loading={isLoading} />
            </div>
          </>
        ) : (
          <div>
            Bureau rerun successfully for the <span className="primary-color">Member Id {memberId}</span>
          </div>
        )}
      </div>
    );
  }
  componentDidMount = async () => {
    let { memberId } = this.props;
    this.setState({ isLoading: true });
    await POST(API_POST_RERUN_BUREAU(memberId))
      .then(({ data }) => {
        if (data.status === "success") {
        } else {
        }
      })
      .catch((err) => {
        throw err;
      });
    this.setState({ isLoading: false });
  };
}

export default RerunBureau;
