import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { rupee } from "react-icons-kit/fa/rupee";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { connect } from "react-redux";
import { map, uniq, keys, mapValues, get, filter, includes, isEmpty, set } from "lodash";
import {
  changeValue,
  getAccountBankingAnalysis,
  getBankDetails,
  getBankingAnalysis,
  getEditRatio,
  getEditRatioAcc,
  getEntityName,
} from "./cashflow.action";
import * as config from "./cashflow.config";
import CashFlowAccountTabs from "./CashFlowAccountTabs";
import { Popover } from "react-bootstrap";
import { circle } from "react-icons-kit/fa/circle";
import { aggregateData } from "./cashFlow.selector";
import {
  getScrollPosition,
  setHeaderFixed,
  integerToWordFormatter,
  integerToWordFormatterLabels,
  titleCase,
} from "../../../utils/utitlity";
import { CredoModal, CheckBox } from "../../../components";
import EditRatioForm from "./EditRatioForm";
import EditRatioForm1 from "./EditRatioForm1";
import LoaderModal from "../../../components/LoaderModal/LoaderModal";
import { CREDIT_CATEGORIES, DEBIT_CATEGORIES, GRAPHS, Quarters, getColorCode } from "./cashflow.constant";
import { CredoToolTip } from "../../../components/CredoToolTip/CredoToolTip";
import LimitUtilizationEditForm from "./LimitUtilizationEditForm";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
import ContentLoader from "react-content-loader";
import Highcharts from "highcharts";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { angleDown } from "react-icons-kit/fa/angleDown";
import { Tabs, Tab } from "react-bootstrap";
import { deleteCookie } from "../../../utils/utitlity";
import { checkUserLogin, setUserDetails, setUserLoginStatus } from "../../../store/actions";
class CashFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderedCharts: {
        "credit-inflow-chart": false,
        "debit-outflow-chart": false,
        "credit-transaction-chart": false,
        "debit-transaction-chart": false,
        "utilization-analysis-chart": false,
        "inflows-chart": false,
        "financial-chart": false,
        "credit-gst-chart": false,
        "debit-gst-chart": false,
      },
      modalOpen: false,
      modalType: "",
      creditTransactionsData2: [
        {
          repeat_txn: 123,
          frequency: 123,
          total_value: 123123,
          counterparty: "false",
        },
      ],
      debitTransactionsData2: [
        {
          repeat_txn: 123,
          frequency: 123,
          total_value: 123123,
          counterparty: "false",
        },
      ],
      creditTransactionType: "",
      debitTransactionType: "",
      bounceRatio: null,
      bounceRatio1: null,
      debitTransactions: "All",
      creditTransactions: "All",
      aggregateTotalData: "All",
      aggregateFilters: {
        sales: true,
        nonSales: true,
        reversal: true,
      },
      creditFilters: {
        sales: true,
        nonSales: true,
        reversal: true,
      },
      debitFilters: {
        sales: true,
        nonSales: true,
        reversal: true,
      },
    };
  }

  toggleModal = (modalType, props) => {
    const modalState = !this.state.modalOpen;
    this.setState({
      modalOpen: !this.state.modalOpen,
      modalType: modalState ? modalType : "",
    });
  };

  renderQuarterly = (graphObj, value) => {
    const list = this.props.analysisData[graphObj.propsKey];
    const monthsList = Quarters[value];
    const filteredList = filter(list, (detail) => {
      const val = detail.month.split("-")[0];
      return includes(monthsList, val);
    });
    this[graphObj.name].xAxis[0].update({
      categories: map(filteredList, (v) => v.month.replace("-", "<br/>")),
    });
    this[graphObj.name].series[0].setData(map(filteredList, "value").map((el) => Number(el)));
  };

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.props.actions.changeValue(name, value);
    this.renderQuarterly(GRAPHS[name], value);
  };

  setBankingInflowTransactionType = (type, name) => {
    let { analysisData } = this.props;
    let { creditTransactionType, debitTransactionType } = this.state;
    if (name === "credit") {
      if (creditTransactionType !== type) {
        if (type.length) {
          const creditCategories = keys(analysisData.creditsCategorywise);
          if (creditCategories.includes(type[0].value.toUpperCase())) {
            this.setState({ creditTransactionType: type });
            const allowed = type.map((item) => item.value);
            const filtered = Object.keys(analysisData.creditsCategorywise)
              .filter((key) => allowed.includes(key))
              .reduce((obj, key) => {
                obj[key] = analysisData.creditsCategorywise[key];
                return obj;
              }, {});

            let series = [],
              iteratedList = [];
            if (analysisData.monthlyCredits) {
              Object.keys(filtered).forEach((item) => {
                let seriesData = series;
                filtered[item].forEach((i) => {
                  if (!iteratedList.includes(i.category)) {
                    iteratedList.push(i.category);
                    seriesData.push({
                      name: i.category,
                      data: [i.value],
                      color: getColorCode("credit", i.category),
                    });
                  } else {
                    let index = seriesData.findIndex((item) => item.name === i.category);
                    let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                    set(seriesData, `[${index}].data`, value);
                  }
                });
                series = seriesData;
              });
            }
            const creditColumnChartConfig = {
              name: "credit-transaction-chart",
              categories: analysisData.creditsCategorywise[keys(analysisData.creditsCategorywise)[0]].map(
                (item) => item.month
              ),
              series,
              legends: false,
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: false,
                    style: {
                      textOutline: "none",
                    },
                  },
                },
              },
            };
            config.generateStackColumnChart(creditColumnChartConfig);
          } else {
            window.alert("Data is not available for this transaction type.");
          }
        } else {
          this.setState({ creditTransactionType: type });
          const creditCategories = keys(analysisData.monthlyCredits);
          let series = [],
            iteratedList = [];
          Object.keys(analysisData.monthlyCredits).forEach((item) => {
            let seriesData = series;
            analysisData.monthlyCredits[item].forEach((i) => {
              if (!iteratedList.includes(i.category)) {
                iteratedList.push(i.category);
                seriesData.push({
                  name: i.category,
                  data: [i.value],
                  color: getColorCode("credit", i.category),
                });
              } else {
                let index = seriesData.findIndex((item) => item.name === i.category);
                let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                set(seriesData, `[${index}].data`, value);
              }
            });
            series = seriesData;
          });
          const creditColumnChartConfig = {
            name: "credit-transaction-chart",
            categories: creditCategories,
            series,
            legends: false,
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: false,
                  style: {
                    textOutline: "none",
                  },
                },
              },
            },
          };
          config.generateStackColumnChart(creditColumnChartConfig);
        }
      }
    } else if (name === "debit") {
      if (debitTransactionType !== type) {
        if (type.length) {
          const debitCategories = keys(analysisData.debitsCategorywise);
          if (debitCategories.includes(type[0].value.toUpperCase())) {
            this.setState({ debitTransactionType: type });
            const allowed = type.map((item) => item.value);
            const filtered = Object.keys(analysisData.debitsCategorywise)
              .filter((key) => allowed.includes(key))
              .reduce((obj, key) => {
                obj[key] = analysisData.debitsCategorywise[key];
                return obj;
              }, {});

            let series = [],
              iteratedList = [];
            if (analysisData.monthlyDebits) {
              Object.keys(filtered).forEach((item) => {
                let seriesData = series;
                filtered[item].forEach((i) => {
                  if (!iteratedList.includes(i.category)) {
                    iteratedList.push(i.category);
                    seriesData.push({
                      name: i.category,
                      data: [i.value],
                      color: getColorCode("debit", i.category),
                    });
                  } else {
                    let index = seriesData.findIndex((item) => item.name === i.category);
                    let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                    set(seriesData, `[${index}].data`, value);
                  }
                });
                series = seriesData;
              });
            }
            const debitColumnChartConfig = {
              name: "debit-transaction-chart",
              categories: analysisData.debitsCategorywise[keys(analysisData.debitsCategorywise)[0]].map(
                (item) => item.month
              ),
              series,
              legends: false,
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: false,
                    style: {
                      textOutline: "none",
                    },
                  },
                },
              },
            };
            config.generateStackColumnChart(debitColumnChartConfig);
          } else {
            window.alert("Data is not available for this transaction type.");
          }
        } else {
          this.setState({ debitTransactionType: type });
          const debitCategories = keys(analysisData.monthlyDebits);
          let series = [],
            iteratedList = [];
          Object.keys(analysisData.monthlyDebits).forEach((item) => {
            let seriesData = series;
            analysisData.monthlyDebits[item].forEach((i) => {
              if (!iteratedList.includes(i.category)) {
                iteratedList.push(i.category);
                seriesData.push({
                  name: i.category,
                  data: [i.value],
                  color: getColorCode("debit", i.category),
                });
              } else {
                let index = seriesData.findIndex((item) => item.name === i.category);
                let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                set(seriesData, `[${index}].data`, value);
              }
            });
            series = seriesData;
          });
          const debitColumnChartConfig = {
            name: "debit-transaction-chart",
            categories: debitCategories,
            series,
            legends: false,
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: false,
                  style: {
                    textOutline: "none",
                  },
                },
              },
            },
          };
          config.generateStackColumnChart(debitColumnChartConfig);
        }
      }
    }
  };
  getData = (data) => {
    this.setState({ bounceRatio: data });
  };
  getData1 = (data) => {
    this.setState({ bounceRatio1: data });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { setUserDetails, setUserLoginStatus, checkUserLogin } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }
  filterCharts = (name, value) => {
    this.setState({ [name]: value });
    let { analysisData } = this.props;
    var filterArray = [],
      creditCategories,
      debitCategories;
    let { creditFilters, debitFilters } = this.state;
    if (name === "creditTransactions") {
      let prevValue = creditFilters[value];
      creditFilters[value] = !prevValue;
      this.setState({ creditFilters });
      var salesArray = ["CREDIT CASH DEPOSIT", "CREDIT CUSTOMER PAYMENTS", "CREDIT CHEQUE GROUP"];
      var nonSalesArray = ["CREDIT LOAN AND ADVANCES", "CREDIT INVESTMENT MATURED", "CREDIT TAX REFUND"];
      var reversalArray = ["CREDIT INWARD BOUNCE", "CREDIT SELF GROUP", "CREDIT REVERSAL"];
      creditCategories = keys(analysisData.monthlyCredits);
      let series = [],
        iteratedList = [];
      if (analysisData.monthlyCredits) {
        Object.keys(analysisData.monthlyCredits).forEach((item) => {
          let seriesData = series;
          analysisData.monthlyCredits[item].forEach((i) => {
            if (!iteratedList.includes(i.category)) {
              iteratedList.push(i.category);
              seriesData.push({
                name: i.category,
                data: [i.value],
                color: getColorCode("credit", i.category),
              });
            } else {
              let index = seriesData.findIndex((item) => item.name === i.category);
              let value = [...seriesData[index].data, i.value].map((el) => Number(el));
              set(seriesData, `[${index}].data`, value);
            }
          });
          series = seriesData;
        });
      }
      let checkedCreditTransactions = Object.keys(creditFilters).filter((el) => {
        return creditFilters[el];
      });
      checkedCreditTransactions.map((checkedCreditTransactionType) => {
        filterArray = [...filterArray, ...eval(`${checkedCreditTransactionType}Array`)];
        return null;
      });
      const creditColumnChartConfig = {
        name: "credit-transaction-chart",
        categories: creditCategories,
        series: series.filter((el) => filterArray.includes(el.name)),
        legends: false,
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              style: {
                textOutline: "none",
              },
            },
          },
        },
      };
      config.generateStackColumnChart(creditColumnChartConfig);
    }
    if (name === "debitTransactions") {
      let prevValue = debitFilters[value],
        filterArray = [];
      debitFilters[value] = !prevValue;
      this.setState({ debitFilters });
      let salesDebitArray = [
        "DEBIT CHEQUE GROUP",
        "DEBIT VENDOR PAYMENTS",
        "DEBIT EMI REPAYMENT GROUP",
        "DEBIT BANK CHARGES GROUP",
        "DEBIT CASH WITHDRAWL GROUP",
      ];
      let nonSalesDebitArray = [
        "DEBIT INTEREST TO BANK GROUP",
        "DEBIT STATUTORY PAYMENT",
        "DEBIT UTILITIES EXPENSES GROUP",
        "DEBIT INVESTMENT MADE GROUP",
      ];
      let reversalDebitArray = ["DEBIT SELF GROUP", "DEBIT OUTWARD BOUNCE GROUP", "DEBIT REVERSAL GROUP"];
      debitCategories = keys(analysisData.monthlyDebits);
      let series = [],
        iteratedList = [];
      if (analysisData.monthlyDebits) {
        Object.keys(analysisData.monthlyDebits).forEach((item) => {
          let seriesData = series;
          analysisData.monthlyDebits[item].forEach((i) => {
            if (!iteratedList.includes(i.category)) {
              iteratedList.push(i.category);
              seriesData.push({
                name: i.category,
                data: [i.value],
                color: getColorCode("debit", i.category),
              });
            } else {
              let index = seriesData.findIndex((item) => item.name === i.category);
              let value = [...seriesData[index].data, i.value].map((el) => Number(el));
              set(seriesData, `[${index}].data`, value);
            }
          });
          series = seriesData;
        });
      }
      let checkedDebitTransactions = Object.keys(debitFilters).filter((el) => {
        return debitFilters[el];
      });
      checkedDebitTransactions.map((checkedDebitTransactionType) => {
        filterArray = [...filterArray, ...eval(`${checkedDebitTransactionType}DebitArray`)];
        return null;
      });
      const debitColumnChartConfig = {
        name: "debit-transaction-chart",
        categories: debitCategories,
        series: series.filter((el) => filterArray.includes(el.name)),
        legends: false,
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: false,
              style: {
                textOutline: "none",
              },
            },
          },
        },
      };
      config.generateStackColumnChart(debitColumnChartConfig);
    }
  };
  componentDidUpdate(prevProps) {
    const { bankDetails, analysisData } = this.props;
    const self = this;
    if (prevProps !== bankDetails) {
      window.onscroll = () => {
        if (!self.state.renderedCharts["credit-inflow-chart"]) {
          if (getScrollPosition("credit-inflow-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "credit-inflow-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const creditInfoChartConfig = {
                name: "credit-inflow-chart",
                categories: map(analysisData.credits, (v) => v.month.replace("-", "<br/>")),
                series: {
                  name: (uniq(map(analysisData.credits, "category")) || ["ALL"])[0],
                  data: map(analysisData.credits, "value").map((el) => Number(el)),
                },
              };
              self[GRAPHS.creditCashFlowQuarter.name] = config.generateAreaChart(creditInfoChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["debit-outflow-chart"]) {
          if (getScrollPosition("debit-outflow-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "debit-outflow-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const debitInfoChartConfig = {
                name: "debit-outflow-chart",
                categories: map(analysisData.debits, (v) => v.month.replace("-", "<br/>")),
                series: {
                  name: (uniq(map(analysisData.debits, "category")) || ["ALL"])[0],
                  data: map(analysisData.debits, "value").map((el) => Number(el)),
                },
              };
              self[GRAPHS.debitCashFlowQuarter.name] = config.generateAreaChart(debitInfoChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["credit-transaction-chart"]) {
          if (getScrollPosition("credit-transaction-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "credit-transaction-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const creditCategories = keys(analysisData.monthlyCredits);
              let series = [],
                iteratedList = [];
              if (analysisData.monthlyCredits) {
                Object.keys(analysisData.monthlyCredits).forEach((item) => {
                  let seriesData = series;
                  analysisData.monthlyCredits[item].forEach((i) => {
                    if (!iteratedList.includes(i.category)) {
                      iteratedList.push(i.category);
                      seriesData.push({
                        name: i.category,
                        data: [i.value],
                        color: getColorCode("credit", i.category),
                      });
                    } else {
                      let index = seriesData.findIndex((item) => item.name === i.category);
                      let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                      set(seriesData, `[${index}].data`, value);
                    }
                  });
                  series = seriesData;
                });
              }
              const creditColumnChartConfig = {
                name: "credit-transaction-chart",
                categories: creditCategories,
                series,
                legends: false,
                plotOptions: {
                  column: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: "none",
                      },
                    },
                  },
                },
              };
              config.generateStackColumnChart(creditColumnChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["debit-transaction-chart"]) {
          if (getScrollPosition("debit-transaction-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "debit-transaction-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const debitCategories = keys(analysisData.monthlyDebits);
              let series = [],
                iteratedList = [];
              if (analysisData.monthlyDebits) {
                Object.keys(analysisData.monthlyDebits).forEach((item) => {
                  let seriesData = series;
                  analysisData.monthlyDebits[item].forEach((i) => {
                    if (!iteratedList.includes(i.category)) {
                      iteratedList.push(i.category);
                      seriesData.push({
                        name: i.category,
                        data: [i.value],
                        color: getColorCode("debit", i.category),
                      });
                    } else {
                      let index = seriesData.findIndex((item) => item.name === i.category);
                      let value = [...seriesData[index].data, i.value].map((el) => Number(el));
                      set(seriesData, `[${index}].data`, value);
                    }
                  });
                  series = seriesData;
                });
              }
              const debitColumnChartConfig = {
                name: "debit-transaction-chart",
                categories: debitCategories,
                series,
                legends: false,
                plotOptions: {
                  column: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: "none",
                      },
                    },
                  },
                },
              };
              config.generateStackColumnChart(debitColumnChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["financial-chart"]) {
          if (getScrollPosition("financial-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "financial-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const financialChartConfig = {
                name: "financial-chart",
                categories: map(analysisData.financialObligations, (v) => v.month.replace("-", "<br/>")),
                series: [
                  {
                    name: "ABB",
                    data: map(mapValues(analysisData.financialObligations, "abbValue")).map((el) => Number(el)),
                    color: "#00cdbe",
                  },
                  {
                    name: "Obligations As Per Banking",
                    data: map(mapValues(analysisData.financialObligations, "value")).map((el) => Number(el)),
                    color: "#3670cc",
                  },
                ],
                plotOptions: {
                  series: {
                    pointWidth: 20,
                  },
                  column: {
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: "none",
                      },
                    },
                  },
                },
              };
              config.generateColumnChart(financialChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["credit-gst-chart"]) {
          if (getScrollPosition("credit-gst-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "credit-gst-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const creditGstChartConfig = {
                name: "credit-gst-chart",
                categories: map(analysisData.creditSalesComparision, (v) => v.month.replace("-", "<br/>")),
                series: [
                  {
                    name: "GST",
                    data: map(mapValues(analysisData.creditSalesComparision, "gstValue")).map((el) => Number(el)),
                    color: "#00cdbe",
                  },
                  {
                    name: "Banking",
                    data: map(mapValues(analysisData.creditSalesComparision, "bankingValue")).map((el) => Number(el)),
                    color: "#3670cc",
                  },
                ],
                plotOptions: {
                  series: {
                    pointWidth: 20,
                  },
                  column: {
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: "none",
                      },
                    },
                  },
                },
              };
              config.generateColumnChart(creditGstChartConfig);
            });
          }
        }
        if (!self.state.renderedCharts["debit-gst-chart"]) {
          if (getScrollPosition("debit-gst-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "debit-gst-chart": true,
            });
            self.setState({ renderedCharts: charts }, () => {
              const creditGstChartConfig = {
                name: "debit-gst-chart",
                categories: map(analysisData.debitPurchaseComparision, (v) => v.month.replace("-", "<br/>")),
                series: [
                  {
                    name: "GST",
                    data: map(mapValues(analysisData.debitPurchaseComparision, "gstValue")).map((el) => Number(el)),
                    color: "#00cdbe",
                  },
                  {
                    name: "Banking",
                    data: map(mapValues(analysisData.debitPurchaseComparision, "bankingValue")).map((el) => Number(el)),
                    color: "#3670cc",
                  },
                ],
                plotOptions: {
                  series: {
                    pointWidth: 20,
                  },
                  column: {
                    dataLabels: {
                      enabled: false,
                      style: {
                        textOutline: "none",
                      },
                    },
                  },
                },
              };
              config.generateColumnChart(creditGstChartConfig);
            });
          }
        }
        if (true) {
          if (getScrollPosition("utilization-analysis-chart-0")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "utilization-analysis-chart-0": true,
            });
            this.setState({ renderedCharts: charts }, () => {
              analysisData.limitUtilizationAccountwise.map((el, key) => {
                const limitUtilConfig = {
                  name: `utilization-analysis-chart-${key}`,
                  categories: map(el.limitUtilizationInPercentage, (v) => v.month.replace("-", "<br/>")),
                  series: {
                    name: (uniq(map(el.limitUtilizationInPercentage, "category")) || ["ALL"])[0],
                    data: map(el.limitUtilizationInPercentage, "value"),
                  },
                };
                config.generateAreaChartInPercentage(limitUtilConfig);
                return null;
              });
            });
          }
        }
        if (!self.state.renderedCharts["inflows-chart"]) {
          if (getScrollPosition("inflows-chart")) {
            let charts = Object.assign({}, self.state.renderedCharts, {
              "inflows-chart": true,
            });
            this.setState({ renderedCharts: charts }, () => {
              Highcharts.chart(
                "inflows-chart",
                {
                  chart: {
                    type: "area",
                    backgroundColor: "transparent",
                    height: 250,
                  },
                  title: false,
                  xAxis: {
                    categories: map(analysisData.top10CreditTxns, "valueDate"),
                  },
                  yAxis: {
                    title: false,
                    labels: {
                      formatter: function () {
                        return integerToWordFormatterLabels(this.value);
                      },
                    },
                  },
                  credits: { enabled: false },
                  plotOptions: {
                    area: {
                      marker: {
                        enabled: false,
                      },
                    },
                  },
                  legend: false,
                  tooltip: {
                    formatter: function () {
                      return (
                        "<p>" +
                        this.x +
                        "<br/>" +
                        this.series.name +
                        "<b>:  </b>" +
                        integerToWordFormatter(this.y) +
                        "</p>"
                      );
                    },
                  },
                  series: [
                    {
                      name: "ALL",
                      data: map(analysisData.top10CreditTxns, "amount").map((el) => Number(el)),
                      color: {
                        linearGradient: {
                          x1: 0,
                          x2: 0,
                          y1: 0,
                          y2: 1,
                        },
                        stops: [
                          [0, "#00cdbe"],
                          [1, "rgba(139, 213, 245, 0)"],
                        ],
                      },
                    },
                  ],
                },
                function (chart) {
                  if (chart.series.length === 0) {
                    chart.renderer
                      .text("No Data Available", 140, 120)
                      .css({
                        color: "#001061",
                        fontSize: "16px",
                      })
                      .add();
                  }
                }
              );
            });
          }
        }
      };
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", setHeaderFixed);
    let { actions, history, match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      actions.getBankDetails(applicationNumber);
      actions.getBankingAnalysis(applicationNumber);
      actions.getEntityName(applicationNumber);
    } else {
      history.push("/Credit/dashboard");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }
  expandHandler = (type) => {
    if (type === "table1") {
      this.setState({ table1: !this.state.table1 });
    } else {
      this.setState({ table2: !this.state.table2 });
    }
  };
  setAggregateFilters = (name) => {
    let { aggregateFilters } = this.state;
    let prevValue = aggregateFilters[name];
    aggregateFilters[name] = !prevValue;
    this.setState({ aggregateFilters });
  };
  render() {
    let { modalOpen, modalType, bankingCardDetail, aggregateFilters, creditFilters, debitFilters } = this.state;
    const { loader, debitCashFlowQuarter, creditCashFlowQuarter, analysisData, actions } = this.props,
      bankDetails = get(this, "props.bankDetails") || "",
      _analysisData = analysisData ? aggregateData(analysisData, aggregateFilters) : {},
      creditTransactionsData1 = get(analysisData, "highCreditTxns")
        ? get(analysisData, "highCreditTxns").slice(0, 5)
        : [],
      debitTransactionsData1 = get(analysisData, "highDebitTxns") ? get(analysisData, "highDebitTxns").slice(0, 5) : [],
      customerConcentration = get(analysisData, "customerConcentration")
        ? get(analysisData, "customerConcentration")
        : [],
      vendorConcentration = get(analysisData, "vendorConcentration") ? get(analysisData, "vendorConcentration") : [];
    const optionsDebits = Object.keys(DEBIT_CATEGORIES)
      .sort()
      .map((item, index) => {
        return { value: item, label: item };
      });
    const optionsCredits = Object.keys(CREDIT_CATEGORIES)
      .sort()
      .map((item, index) => {
        return { value: item, label: item };
      });
    let aggregateDataHeaders;
    if (analysisData.credits && analysisData.debits && analysisData.credits.length > analysisData.debits.length) {
      aggregateDataHeaders = analysisData.credits;
    } else {
      aggregateDataHeaders = analysisData.debits;
    }
    const _emiAndServicing = [
      ...config.emiAndServicing,
      {
        Header: "",
        accessor: "",
        width: 50,
        Cell: ({ original }) => {
          return (
            <div
              onClick={() => {
                let bankingCardDetail = {
                  sanctionedLimits: original?.emiDetails?.map((el) => {
                    return { limit: el.amount, month: el.valueDate };
                  }),
                };
                this.setState({ bankingCardDetail: bankingCardDetail }, () => {
                  this.toggleModal("limitUtilization");
                });
              }}
            >
              +
            </div>
          );
        },
      },
    ];
    


    return (
      <Fragment>
        <LoaderModal status={loader} />
        <div className={"cash-flow-wrapper "}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Cash Flow</title>
          </Helmet>
          <div className={"row"}>
            <CashFlowAccountTabs
              {...this.props}
              bankDetails={bankDetails}
              actions={actions}
              entityDetails={this.props.entityDetails}
            />
            <div className={"col-md-12"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Aggregates Banking Trend Analysis</h4>
                </div>
                <ul className="checkbox__container">
                  <li>
                    <CheckBox
                      labelColor={"#a4a1fb"}
                      checkboxColor={"#a4a1fb"}
                      htmlFor={"aggregateSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Operational Transactions"}
                      checked={aggregateFilters.sales}
                      onChange={() => this.setAggregateFilters("sales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#36ddd1"}
                      checkboxColor={"#36ddd1"}
                      htmlFor={"aggregateNonSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Non- Operational Transactions"}
                      checked={aggregateFilters.nonSales}
                      onChange={() => this.setAggregateFilters("nonSales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#67caf2"}
                      checkboxColor={"#67caf2"}
                      htmlFor={"aggregateReversal"}
                      inputClass={""}
                      labelClass={""}
                      label={"Reversal, Bounce & Self Transactions"}
                      checked={aggregateFilters.reversal}
                      onChange={() => this.setAggregateFilters("reversal")}
                    />
                  </li>
                </ul>
                {bankDetails ? (
                  <ReactTable
                    className={!_analysisData.length ? "no-data no-pagination" : null}
                    data={_analysisData !== null ? _analysisData : []}
                    columns={config.bankingColumns(aggregateDataHeaders ? aggregateDataHeaders : [])}
                    resizable={false}
                    showPagination={false}
                    minRows={0}
                  />
                ) : (
                  <div style={{ marginTop: "25px" }}>
                    <ContentLoader height={200} type={"facebook"} />
                  </div>
                )}
                <div className={"box-footer"}>
                  <ul className={"list-unstyled"}>
                    <li>
                      <span>
                        Imputed Annual Sales -{" "}
                        <span className={"icon"}>
                          <Icon icon={rupee} size={28} />
                        </span>
                        {rupeeCommaFormatter(analysisData.totalSales)}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Total Credits In Account - Cash Inflow Trend</h4>
                </div>
                <div className={"text-right"}>
                  <select
                    className={"select-box"}
                    value={creditCashFlowQuarter}
                    name={"creditCashFlowQuarter"}
                    onChange={this.handleChange}
                  >
                    <option value={"wholeYear"}>Set To Default</option>
                    <option value={"firstQuarter"}>1 Jan - 31 Mar</option>
                    <option value={"secondQuarter"}>1 Apr - 30 Jun</option>
                    <option value={"thirdQuarter"}>1 Jul- 30 Sep</option>
                    <option value={"fourthQuarter"}>1 Oct - 31 Dec</option>
                  </select>
                </div>
                <div className={"chart-box"} id={"credit-inflow-chart"} />
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Total Debits In Account - Cash Outflow Trend</h4>
                </div>
                <div className={"text-right"}>
                  <select
                    className={"select-box"}
                    value={debitCashFlowQuarter}
                    name="debitCashFlowQuarter"
                    onChange={this.handleChange}
                  >
                    <option value={"wholeYear"}>Set To Default</option>
                    <option value={"firstQuarter"}>1 Jan - 31 Mar</option>
                    <option value={"secondQuarter"}>1 Apr - 30 Jun</option>
                    <option value={"thirdQuarter"}>1 Jul- 30 Sep</option>
                    <option value={"fourthQuarter"}>1 Oct - 31 Dec</option>
                  </select>
                </div>
                <div className={"chart-box"} id={"debit-outflow-chart"} />
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header tool-tip-box"}>
                  <h4>Banking Inflow Analysis - Credit Transactions</h4>
                  <CredoToolTip
                    containerClassName="tool-tip"
                    popover={
                      <Popover id="cash-inflow-trend" title="Credit Transactions Type(s)">
                        {Object.keys(CREDIT_CATEGORIES).map((keyName, index) => {
                          const obj = CREDIT_CATEGORIES[keyName];
                          return (
                            <div key={index}>
                              <span style={{ color: obj.color, paddingRight: 5 }}>
                                <Icon icon={circle} size={20} />
                              </span>
                              <span>{titleCase(obj.label)}</span>
                            </div>
                          );
                        })}
                      </Popover>
                    }
                  />
                </div>
                <ul className="checkbox__container">
                  <li>
                    <CheckBox
                      labelColor={"#a4a1fb"}
                      checkboxColor={"#a4a1fb"}
                      htmlFor={"creditSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Operational Transactions"}
                      checked={creditFilters.sales}
                      onChange={() => this.filterCharts("creditTransactions", "sales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#36ddd1"}
                      checkboxColor={"#36ddd1"}
                      htmlFor={"creditNonSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Non- Operational Transactions"}
                      checked={creditFilters.nonSales}
                      onChange={() => this.filterCharts("creditTransactions", "nonSales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#67caf2"}
                      checkboxColor={"#67caf2"}
                      htmlFor={"creditReversal"}
                      inputClass={""}
                      labelClass={""}
                      label={"Reversal, Bounce & Self Transactions"}
                      checked={creditFilters.reversal}
                      onChange={() => this.filterCharts("creditTransactions", "reversal")}
                    />
                  </li>
                </ul>
                <div className={"chart-box"} id={"credit-transaction-chart"} />
                <div className={"filter-box"}>
                  <Select
                    isMulti
                    name="colors"
                    defaultValue={"hi"}
                    options={optionsCredits}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.setBankingInflowTransactionType(e, "credit")}
                  />
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header tool-tip-box"}>
                  <h4>Banking Outflow Analysis - Debit Transactions</h4>
                  <CredoToolTip
                    containerClassName="tool-tip"
                    placement={"left"}
                    popover={
                      <Popover id="cash-inflow-trend" title="Debit Transactions Type(s)">
                        {Object.keys(DEBIT_CATEGORIES).map((keyName, index) => {
                          const obj = DEBIT_CATEGORIES[keyName];
                          return (
                            <div key={index}>
                              <span style={{ color: obj.color, paddingRight: 5 }}>
                                <Icon icon={circle} size={20} />
                              </span>
                              <span>{titleCase(obj.label)}</span>
                            </div>
                          );
                        })}
                      </Popover>
                    }
                  />
                </div>
                <ul className="checkbox__container">
                  <li>
                    <CheckBox
                      labelColor={"#a4a1fb"}
                      checkboxColor={"#a4a1fb"}
                      htmlFor={"debitSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Operational Transactions"}
                      checked={debitFilters.sales}
                      onChange={() => this.filterCharts("debitTransactions", "sales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#36ddd1"}
                      checkboxColor={"#36ddd1"}
                      htmlFor={"debitNonSales"}
                      inputClass={""}
                      labelClass={""}
                      label={"Non- Operational Transactions"}
                      checked={debitFilters.nonSales}
                      onChange={() => this.filterCharts("debitTransactions", "nonSales")}
                    />
                  </li>
                  <li>
                    <CheckBox
                      labelColor={"#67caf2"}
                      checkboxColor={"#67caf2"}
                      htmlFor={"debitReversal"}
                      inputClass={""}
                      labelClass={""}
                      label={"Reversal, Bounce & Self Transactions"}
                      checked={debitFilters.reversal}
                      onChange={() => this.filterCharts("debitTransactions", "reversal")}
                    />
                  </li>
                </ul>
                <div className={"chart-box"} id={"debit-transaction-chart"} />
                <div className={"filter-box"}>
                  <Select
                    isMulti
                    name="colors"
                    defaultValue={"hi"}
                    options={optionsDebits}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => this.setBankingInflowTransactionType(e, "debit")}
                  />
                </div>
              </div>
            </div>

            <div className={"col-md-12"}>
              <div className={"box-wrapper"}>
                <div
                  className={"box-header"}
                  onClick={() => this.expandHandler("table1")}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>EMI & Loan Servicing Transactions</h4>
                  <span className={"icon"}>
                    <Icon
                      icon={angleDown}
                      size={22}
                      style={this.state.table1 ? { transform: "rotate(180deg)" } : null}
                    />
                  </span>
                </div>
                <SlideDown closed={!this.state.table1}>
                  <ReactTable
                    className={isEmpty(analysisData.emiSummary) ? "no-data no-pagination" : null}
                    data={analysisData.emiSummary || []}
                    columns={_emiAndServicing}
                    resizable={false}
                    showPagination={false}
                    minRows={0}
                  />
                </SlideDown>
              </div>
            </div>
            <div className={"col-md-12"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Average Monthly Balance Compared To Obligations As Per Banking</h4>
                </div>
                <div className={"chart-box"} id={"financial-chart"} />
                <div className={"box-footer"}>
                  <ul className={"list-unstyled"}>
                    <li>
                      <label>Average ABB</label>
                      <span className={"icon"}>
                        <Icon icon={rupee} size={28} />
                        {rupeeCommaFormatter(analysisData.averageABB)}
                      </span>
                    </li>
                    <li>
                      <label>Average Obligations as per Banking</label>
                      <span className={"icon"}>
                        <Icon icon={rupee} size={28} />
                        {rupeeCommaFormatter(analysisData.averageFinancialObligations)}
                      </span>
                    </li>
                    <li>
                      <label>Average Abb/Avg Obligations as per Banking Ratio </label>
                      <span>
                        {get(analysisData, "averageAbbAndObligationRatio", 0)
                          ? Number(get(analysisData, "averageAbbAndObligationRatio", 0)).toFixed(2)
                          : "0"}
                        %
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"row box-header m-0"}>
                  <div className={"col-md-9 p-0"}>
                    <h4>Inward Cheque Bounce Ratio</h4>
                  </div>
                  <div className={"col-xs-3 p-0 text-right edit-ratio"} onClick={() => this.toggleModal("editRatio")}>
                    <span>
                      <Icon icon={ic_edit} size={18} /> Edit Ratio
                    </span>
                  </div>
                </div>
                <div className={"row m-0 ratio"}>
                  {this.state.bounceRatio || this.state.bounceRatio === 0 ? (
                    <span>{this.state.bounceRatio ? Number(this.state.bounceRatio).toFixed(2) : "0.00"}%</span>
                  ) : (
                    <span>
                      {analysisData.inwardChqBounceRatio ? Number(analysisData.inwardChqBounceRatio).toFixed(2) : "0"}%
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"row box-header m-0"}>
                  <div className={"col-md-9 p-0"}>
                    <h4>Outward Cheque Bounce Ratio</h4>
                  </div>
                  <div className={"col-xs-3 p-0 text-right edit-ratio"} onClick={() => this.toggleModal("editRatio1")}>
                    <span>
                      <Icon icon={ic_edit} size={18} /> Edit Ratio
                    </span>
                  </div>
                </div>
                <div className={"row m-0 ratio"}>
                  {this.state.bounceRatio1 || this.state.bounceRatio1 === 0 ? (
                    <span>{this.state.bounceRatio1 ? Number(this.state.bounceRatio1).toFixed(2) : "0.00"}%</span>
                  ) : (
                    <span>
                      {analysisData.outwardChqBounceRatio ? Number(analysisData.outwardChqBounceRatio).toFixed(2) : "0"}
                      %
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-6"}>
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4>Highlighted Credit Transactions</h4>
                  </div>
                  <ReactTable
                    className={!creditTransactionsData1.length ? "no-data no-pagination" : null}
                    data={creditTransactionsData1 || []}
                    columns={config.creditTransactionColumns1}
                    resizable={false}
                    NoDataComponent={() => null}
                    showPagination={false}
                    minRows={0}
                  />
                </div>
              </div>
              <div className={"col-md-6"}>
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4>Highlighted Debit Transactions</h4>
                  </div>
                  <ReactTable
                    className={!debitTransactionsData1.length ? "no-data no-pagination" : null}
                    data={debitTransactionsData1 || []}
                    columns={config.debitTransactionColumns1}
                    resizable={false}
                    NoDataComponent={() => null}
                    showPagination={false}
                    minRows={0}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-6"}>
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4>Customer Concentration Analysis</h4>
                  </div>
                  <ReactTable
                    className={!customerConcentration.length ? "no-data no-pagination" : null}
                    data={customerConcentration || []}
                    columns={config.customerConcentration}
                    resizable={false}
                    NoDataComponent={() => null}
                    showPagination={false}
                    minRows={0}
                  />
                </div>
              </div>
              <div className={"col-md-6"}>
                <div className={"box-wrapper"}>
                  <div className={"box-header"}>
                    <h4> Vendor Concentration Analysis</h4>
                  </div>
                  <ReactTable
                    className={!vendorConcentration.length ? "no-data no-pagination" : null}
                    data={vendorConcentration || []}
                    columns={config.vendorConcentration}
                    resizable={false}
                    NoDataComponent={() => null}
                    showPagination={false}
                    minRows={0}
                  />
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Banking Credit Sales and GST Sales Comparison (For Common Months)</h4>
                </div>
                <div className={"chart-box"} id={"credit-gst-chart"} />
                <div className={"box-footer"}>
                  <ul className={"list-unstyled"}>
                    <li>
                      <label>Banking Credit Turnover / GST Turnover Ratio</label>
                      <span>
                        {get(analysisData, "creditSalesToGSTSalesRatio", 0)
                          ? Number(get(analysisData, "creditSalesToGSTSalesRatio", 0)).toFixed(2)
                          : "0"}
                        %
                      </span>
                    </li>
                    <li>
                      <label>Total common available months</label>
                      <span>
                        {get(analysisData, "creditSalesComparision", 0)
                          ? get(analysisData, "creditSalesComparision", 0).length
                          : "0"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={"col-md-6"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Banking Debit Expenses and GST Purchase Comparison (For Common Months)</h4>
                </div>
                <div className={"chart-box"} id={"debit-gst-chart"} />
                <div className={"box-footer"}>
                  <ul className={"list-unstyled"}>
                    <li>
                      <label>Banking Purchases / GST Purchase Ratio</label>
                      <span>
                        {get(analysisData, "creditPurchaseToGSTPurchaseRatio", 0)
                          ? Number(get(analysisData, "creditPurchaseToGSTPurchaseRatio", 0)).toFixed(2)
                          : "0"}
                        %
                      </span>
                    </li>
                    <li>
                      <label>Total common available months</label>
                      <span>
                        {get(analysisData, "debitPurchaseComparision", 0)
                          ? get(analysisData, "debitPurchaseComparision", 0).length
                          : "0"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {analysisData.isLimitSanctionedPresent ? (
              <div className={"col-md-10 col-md-offset-1"}>
                <div className={"box-wrapper"}>
                  <div className={"row box-header"} style={{ marginBottom: "20px" }}>
                    <div className={"col-xs-6"}>
                      <h4>Limit Utilization Analysis</h4>
                    </div>
                  </div>
                  <Tabs defaultActiveKey={"index0"} id="adminTabs" onSelect={this.selectedTab}>
                    {analysisData &&
                    true &&
                    analysisData.limitUtilizationAccountwise
                      ? analysisData.limitUtilizationAccountwise.map((el, key) => {
                          return (
                            <Tab eventKey={`index${key}`} title={`${el.accountNumber}`}>
                              <div className={"chart-box"} id={`utilization-analysis-chart-${key}`} />
                              <div className={"box-footer"}>
                                <ul className={"list-unstyled"}>
                                  <li>
                                    <label>Average Utilization &lt; 40%</label>
                                    <span>
                                      {el.avgUtilizationLessThan40P}&nbsp;
                                      {""}Times
                                    </span>
                                  </li>
                                  <li>
                                    <label>Average Utilisation &gt; 90%</label>
                                    <span>{el.avgUtilizationGreaterThan90P}&nbsp; Times</span>
                                  </li>
                                </ul>
                              </div>
                            </Tab>
                          );
                        })
                      : null}
                    <div></div>
                  </Tabs>
                </div>
              </div>
            ) : null}

            <div className={"col-md-10 col-md-offset-1"}>
              <div className={"box-wrapper"}>
                <div className={"box-header"}>
                  <h4>Cash Inflows Pattern - Time Elapsed Between Top 10 Credits</h4>
                </div>
                <div className={"chart-box"} id={"inflows-chart"} />
              </div>
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          className={"edit-ratio-modal"}
          props={this.props}
          sendData={this.getData}
          sendData1={this.getData1}
          bankingCardDetail={bankingCardDetail}
          {...this.props}
          styles={{
            content: {
              borderRadius: "25px",
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
            },
          }}
          title={
            modalType === "editRatio"
              ? "Add Revised Ratio"
              : "editRatio1"
              ? "EMI & Loan Servicing Transactions"
              : "Add Amount"
          }
          closeAction={() => this.toggleModal("")}
          RenderingComponent={
            modalType === "editRatio"
              ? EditRatioForm
              : modalType === "editRatio1"
              ? EditRatioForm1
              : modalType === "limitUtilization"
              ? LimitUtilizationEditForm
              : ""
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data, cashFlow }) => ({
  userDetails: data.userDetails,
  bankDetails: cashFlow.bankDetails,
  loader:
    cashFlow.bankDetailsLoader || cashFlow.bankAnalysisLoader || cashFlow.editRatioLoader || cashFlow.editRatioLoader1,
  analysisData: cashFlow.bankAnalysisData || {},
  debitCashFlowQuarter: get(cashFlow, "form.values.debitCashFlowQuarter", ""),
  creditCashFlowQuarter: get(cashFlow, "form.values.creditCashFlowQuarter", ""),
  editRatio: cashFlow.editRatio,
  editRatio1: cashFlow.editRatio1,
  entityDetails: cashFlow.entityDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBankDetails,
      getBankingAnalysis,
      getAccountBankingAnalysis,
      changeValue,
      getEditRatio,
      getEditRatioAcc,
      getEntityName,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashFlow);
