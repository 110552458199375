import {
  API_GET_CLUSTER_STATES,
  API_GET_CUSTOMER_DETAILS,
  API_GET_CLUSTER_TAXPAYER_DATA,
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as OnBoardingDetailsConstants from "./onBoardingDetails.actions.constant";

const getCustomerDetails = (customerId) => {
  return (dispatch) => {
    return GET(API_GET_CUSTOMER_DETAILS(customerId))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: OnBoardingDetailsConstants.FETCH_CUSTOMER_DETAILS,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getClusterTaxpayerData = (gstin) => {
  return (dispatch) => {
    return GET(API_GET_CLUSTER_TAXPAYER_DATA(gstin))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: OnBoardingDetailsConstants.FETCH_TAXPAYER_DATA,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const getClusterStateList = () => {
  return (dispatch) => {
    return GET(API_GET_CLUSTER_STATES)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: OnBoardingDetailsConstants.FETCH_CLUSTER_STATES,
            data: data.data.responseList,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const resetOnboardingState = () => ({ type: OnBoardingDetailsConstants.RESET_REDUX_STATE });

export { getCustomerDetails, getClusterTaxpayerData, getClusterStateList, resetOnboardingState };
