import React from "react";
import { getDMY, rupeeCommaFormatter } from "../../../utils/utitlity";
import Icon from "react-icons-kit";
import { circle } from "react-icons-kit/fa/circle";
const dataTableHeaders = [
  {
    Header: "Date",
    accessor: "",
    Cell: (item) => {
      return <label>{getDMY(new Date(item.original.date))}</label>;
    },
  },
  {
    Header: "Case Id",
    accessor: "caseId",
  },
  {
    Header: "Application Name",
    accessor: "applicationName",
  },
  {
    Header: "Primary Industry",
    accessor: "primaryIndustry",
  },
  {
    Header: "PA (CIBIL Score)",
    accessor: "cibilScore",
  },
  {
    Header: "CC Score",
    accessor: "ccScore",
  },
  {
    Header: "Turnover",
    accessor: "turnOver",
    Cell: (props) => {
      return rupeeCommaFormatter(props.value);
    },
  },
  // {
  //   Header: 'Loan Amount',
  //   accessor: 'loanAmount',
  //   Cell: (props) => {
  //     return rupeeCommaFormatter(props.value);
  //   },
  // },
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => (
      <button
        style={{
          borderRadius: "20px",
          height: "auto",
          width: "106px",
          color:
            row.value === "Incomplete Application"
              ? "#4687F4"
              : row.value === "Submitted"
              ? "#39B73F"
              : row.value === "Rejected"
              ? "#ff6055"
              : "#FF7D1A",
          border: "none",
          fontFamily: "Helvetica",
          lineHeight: "1.19",
          letterSpacing: "0",
          padding: "5px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          fontWeight: "900",
          backgroundColor:
            row.value === "Incomplete Application"
              ? "#ECF3FE"
              : row.value === "Submitted"
              ? "#F4FBF4"
              : row.value === "Rejected"
              ? "#ffefee"
              : "#FFF7EB",
        }}
      >
        <Icon icon={circle} size={6} />
        {row.value === "Incomplete Application"
          ? "Incomplete"
          : row.value === "Submitted"
          ? `Submitted`
          : row.value === "Pending With Credit"
          ? `Pending With Credit`
          : row.value === "Rejected"
          ? `Rejected`
          : row.value === "Approved"
          ? `Approved`
          : row.value}
      </button>
    ),
  },
];
const mobileTableHeaders = [
  {
    Header: "Date",
    accessor: "",
    Cell: (item) => {
      return <label>{getDMY(new Date(item.original.date))}</label>;
    },
    width: 80,
  },
  {
    Header: "Case Id & Name",
    accessor: "caseId",
    width: 120,
    Cell: ({ original }) => {
      const { caseId, applicationName } = original;
      return (
        <div className="text-left" style={{ padding: "0 10px" }}>
          <div>{caseId}</div>
          <div className="text-bold">{applicationName}</div>
        </div>
      );
    },
  },
  {
    Header: "Score",
    accessor: "ccScore",
    width: 70,
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => (
      <button
        style={{
          borderRadius: "5px",
          height: "auto",
          border: "none",
          width: "max-content",
          color: "#ffffff",
          borderStyle: "solid",
          fontFamily: "Helvetica",
          lineHeight: "1.19",
          letterSpacing: "0",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          backgroundColor:
            row.value === "Incomplete Application"
              ? "#001061"
              : row.value === "Submitted"
              ? "#4687f4"
              : "#4687f4",
        }}
      >
        {row.value === "Incomplete Application"
          ? "Incomplete"
          : row.value === "Submitted"
          ? `Submitted`
          : row.value === "Pending With Credit"
          ? `Pending With Credit`
          : row.value === "Rejected"
          ? `Rejected`
          : row.value === "Approved"
          ? `Approved`
          : row.value}
      </button>
    ),
  },
];

const searchByOptions = [
  {
    label: "Case Id",
    value: "case_id",
  },
];

export { dataTableHeaders, searchByOptions, mobileTableHeaders };
