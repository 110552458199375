import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import Switch from "react-switch";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateRoleStatus, addUserDetails, fetchUserDetails, getRoleStatus, getHierarchyLevel } from "../admin.action";
import ReactTable from "react-table";
import { ic_email } from "react-icons-kit/md/ic_email";
import ChangePassword from "../../../assets/images/Privacy.png";
import RoleuserModal from "./RoleuserModal";
import { loaderModal } from "../../../store/actions";
import { userPlus } from "react-icons-kit/icomoon/userPlus";

import { LoaderModal } from "../../../components";
class RoleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchStatus: false,
      addUserModal: false,
      emailInvitation: false,
      email: "",
      roleName: "",
      password: "",
      mobile: "",
      middleName: "",
      lastName: "",
      firstName: "",
      designation: "",
      employeeId: "",
      currentPage: 1,
      totalPages: 1,
      prevBtn: true,
      nextBtn: true,
    };
  }
  componentDidMount = async () => {
    let { actions, allLenderRolesData, userDetails } = this.props,
      { fetchUserDetails, getRoleStatus, getHierarchyLevel } = actions;
    await fetchUserDetails(this.state.currentPage);
    this.setState({
      nextBtn: userDetails.nextFlag,
      prevBtn: userDetails.prevBtn,
    });
    allLenderRolesData.map((el) => this.setState({ [el.roleName]: el.status === "Active" ? true : false }));
    getRoleStatus();
    getHierarchyLevel();
  };
  componentWillMount() {
    window.scroll(0, 0);
  }
  switchToggle = (type) => {
    let { updateRoleStatus } = this.props.actions;
    updateRoleStatus(type, !this.state[type]).then(({ status, message }) =>
      status === "success" ? this.setState({ [type]: !this.state[type] }) : alert(message)
    );
  };

  modalHandler = (value, type, status) => {
    this.setState({ userValue: value });
    switch (type) {
      case "message":
        this.setState({ sendMessageModal: status });
        break;
      case "changePassword":
        this.setState({ changePassModal: status });
        break;
      case "suspend":
        this.setState({ suspendModal: status });
        break;
      case "addUser":
        this.setState({ addUserModal: status });
        break;
      default:
        this.setState({ addUserModal: status });
    }
  };
  handleChange = (e, props) => {
    let { roleStatusData } = this.props;
    this.modalHandler({ ...props.original, statusValue: roleStatusData[e.target.selectedIndex - 1] }, "suspend", true);
  };
  manageUsers = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (props) => {
        return (
          <span>
            {props.original.firstName} {props.original.middleName} {props.original.lastName}
          </span>
        );
      },
    },
    {
      Header: "Role",
      accessor: "roleName",
    },
    {
      Header: "E-mail",
      accessor: "email",
    },
    {
      Header: "Message",
      accessor: "name",
      Cell: (props) => {
        return (
          <Icon
            icon={ic_email}
            size={24}
            className="icons"
            onClick={() => this.modalHandler(props.original, "message", true)}
          />
        );
      },
    },
    {
      Header: "Change Password",
      accessor: "name",
      Cell: (props) => {
        return (
          <img
            src={ChangePassword}
            alt={"Change Password"}
            className="icons"
            onClick={() => this.modalHandler(props.original, "changePassword", true)}
          />
        );
      },
    },
    {
      Header: " Current Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <select value={props.value} onChange={(e) => this.handleChange(e, props)}>
            <option>Select User</option>
            {this.props.roleStatusData.map((el, index) => (
              <option key={index} value={el}>
                {el}
              </option>
            ))}
          </select>
        );
      },
    },
  ];
  getPageData = (type) => {
    let { fetchUserDetails, loaderModal } = this.props.actions;
    let count = this.state.currentPage;
    loaderModal(true);
    if (type === "next") {
      this.setState(
        {
          currentPage: ++count,
        },
        async () => {
          await fetchUserDetails(count);
          loaderModal(false);
        }
      );
    } else if (type === "prev") {
      this.setState(
        {
          currentPage: --count,
        },
        async () => {
          await fetchUserDetails(count);
          loaderModal(false);
        }
      );
    }
  };
  render() {
    let { currentPage } = this.state,
      { allLenderRolesData, userDetails } = this.props,
      { activeLenderRolesData, loaderModalStatus } = this.props,
      { nextFlag, previousFlag, totalPage } = userDetails;
    
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <div className={"content-box"}>
          <div className={"box-header"}>
            <h4>Add roles for configuring platform access</h4>
          </div>
          <hr />
          <div className={"box-content"}>
            <div className={"row"}>
              {allLenderRolesData.map((el, index) => {
                return (
                  <div className="col-md-6">
                    <div className={"content-row"}>
                      <label>{el.roleName}</label>
                      <Switch
                        onChange={() => this.switchToggle(el.roleName)}
                        checked={el.status === "Active" ? true : false }
                        uncheckedIcon={<span />}
                        checkedIcon={<span />}
                        offColor={"#fff"}
                        onColor={"#fff"}
                        offHandleColor={"#9A9A9A"}
                        onHandleColor={"#29A9E0"}
                        activeBoxShadow={"none"}
                        className={this.state[el.roleName] ? "switch-open" : null}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={"content-box"}>
          <div className={"box-header"}>
            <h4>Add roles for configuring platform access</h4>
            <button type={"button"} onClick={() => this.modalHandler("adduser", "addUser", true)}>
              <Icon icon={ic_add} size={24} />
              <p style={{ margin: "4px 4px 0px" }}>Add User</p>
            </button>
          </div>
          <div className={"box-content"}>
            <ReactTable
              columns={this.manageUsers}
              data={this.props.userDetails.userResponse}
              showPagination={false}
              minRows={0}
            />
            <ul className={"datatable-pagination"}>
              <li>
                <button
                  disabled={!previousFlag}
                  className={"prev-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("prev")}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {currentPage} of {totalPage}
                </span>
              </li>
              <li>
                <button
                  disabled={!nextFlag}
                  className={"next-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("next")}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
        {this.state.userValue ? (
          <RoleuserModal
            userValue={this.state.userValue}
            addUserModal={this.state.addUserModal}
            sendMessageModal={this.state.sendMessageModal}
            changePassModal={this.state.changePassModal}
            suspendModal={this.state.suspendModal}
            modalHandler={(name, type) => this.modalHandler(name, type)}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ AdminResponse, data }) => ({
  updateRoleStatusData: AdminResponse.updateRoleStatusData,
  userDetails: AdminResponse.userDetails,
  roleStatusData: AdminResponse.roleStatusData,
  loaderModalStatus: data.loaderModalStatus,
  hierarchyData: AdminResponse.hierarchyData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateRoleStatus,
      addUserDetails,
      fetchUserDetails,
      loaderModal,
      getRoleStatus,
      getHierarchyLevel,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleUser);
