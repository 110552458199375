import {
    API_GET_SHAREHOLDING_DETAILS,
    API_GET_ENTITY_NAME,
    API_GET_CONSTITUTION_RELATIONS,
    API_REMOVE_PROFILE
} from '../../../utils/APIUrls';
import * as ApplicantGuideConstants from './applicantGuide.actions.constant';
import {GET} from "../../../utils/webAPI.service";

const getShareholdingDetails = (applicationNumber) => {
    return (dispatch) => {
        return GET(API_GET_SHAREHOLDING_DETAILS(applicationNumber))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({type: ApplicantGuideConstants.SET_SHAREHOLDING_DETAILS, data: data.data});
                } else {
                    dispatch({
                        type: ApplicantGuideConstants.SET_SHAREHOLDING_DETAILS,
                        data: {applicationNumber, shareholdingList: [{}]}
                    });
                }
                dispatch({type: ApplicantGuideConstants.SET_INITIAL_RESPONSE, status: true});
                return data;
            })
            .catch(err => {
                throw err
            })
    };
};

const getEntityName = (applicationNumber) => {
    return (dispatch) => {
        return GET(API_GET_ENTITY_NAME(applicationNumber))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({type: ApplicantGuideConstants.SET_ENTITY_DETAILS, data: data.data})
                }
                return data;
            })
            .catch(err => {
                throw err
            })
    }
};

const getConstitutionRelations = (constitution) => {
    return (dispatch) => {
        return GET(API_GET_CONSTITUTION_RELATIONS(constitution))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({
                        type: ApplicantGuideConstants.SET_CONSTITUTION_RELATIONS,
                        data: ['Select Relation', ...data.data.responseList]
                    })
                }
                return data;
            })
            .catch(err => {
                throw err
            })
    }
};

const changeRowValues = (name, val, index) => ({
    type: ApplicantGuideConstants.CHANGE_ROW_VALUES,
    data: val,
    name: name,
    index
});

const addRow = () => ({type: ApplicantGuideConstants.ADD_ROW});

const deleteRow = (index, id = '') => {
    return (dispatch) => {
        if (id) {
            return GET(API_REMOVE_PROFILE(id))
                .then(({data}) => {
                    window.alert(data.message);
                    if (data.status === 'success') {
                        dispatch({type: ApplicantGuideConstants.DELETE_ROW, index})
                    }
                    return data
                })
                .catch(err => {
                    throw err;
                })

        } else {
            dispatch({type: ApplicantGuideConstants.DELETE_ROW, index})
        }
    }
};

const setInitialResponse = (status) => ({
    type: ApplicantGuideConstants.SET_INITIAL_RESPONSE,
    status
});

const resetApplicantGuideState = () => ({
    type: ApplicantGuideConstants.RESET_REDUX_STATE
});

export {
    setInitialResponse,
    getShareholdingDetails,
    getEntityName,
    changeRowValues,
    addRow,
    deleteRow,
    getConstitutionRelations,
    resetApplicantGuideState
}
