import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { GET, POST } from "../../../utils/webAPI.service";
import { API_GET_BUSINESS_COST, API_POST_BUSINESS_COST } from "../../../utils/APIUrls";
import { set } from "lodash";
import { hosieryPartsArray, hosieryTopPartsArray } from "./bicycle.config";
import { Header, LoaderModal } from "../../../components";
export default class HosieryBusinessCost extends Component {
  state = {
    isLoading: false,
    hosieryCostAnalysis: {},
  };
  inputHandler = (e) => {
    let { name, value } = e.target,
      { hosieryCostAnalysis } = this.state;
    this.setState({
      hosieryCostAnalysis: set(hosieryCostAnalysis, name, value),
    });
  };
  inputGenderHandler = (name, value) => {
    let { hosieryCostAnalysis } = this.state;
    if (hosieryCostAnalysis?.hosieryAndReadymadeGarments[name]?.category?.includes(value)) {
      this.setState({
        hosieryCostAnalysis: {
          ...hosieryCostAnalysis,
          hosieryAndReadymadeGarments: {
            ...hosieryCostAnalysis?.hosieryAndReadymadeGarments,
            [name]: {
              ...hosieryCostAnalysis?.hosieryAndReadymadeGarments[name],
              category: hosieryCostAnalysis?.hosieryAndReadymadeGarments[name]?.category?.filter((_) => _ !== value),
            },
          },
        },
      });
      return;
    }
    this.setState({
      hosieryCostAnalysis: {
        ...hosieryCostAnalysis,
        hosieryAndReadymadeGarments: {
          ...hosieryCostAnalysis?.hosieryAndReadymadeGarments,
          [name]: {
            ...hosieryCostAnalysis?.hosieryAndReadymadeGarments[name],
            category: [...(hosieryCostAnalysis?.hosieryAndReadymadeGarments[name]?.category || []), value],
          },
        },
      },
    });
  };
  hosieryPartsHandler = (name, value) => {
    let { hosieryCostAnalysis } = this.state,
      inputValue = !value ? " " : null;
    this.setState({
      hosieryCostAnalysis: {
        ...hosieryCostAnalysis,

        hosieryAndReadymadeGarments: {
          ...hosieryCostAnalysis?.hosieryAndReadymadeGarments,
          [name]: inputValue,
        },
        productionLevelAndSales: {
          ...hosieryCostAnalysis?.productionLevelAndSales,
          [name]: inputValue,
        },
      },
    });
  };
  addHosieryCost = async (actionType) => {
    let { hosieryCostAnalysis } = this.state,
      { match, history } = this.props,
      dataToSend = {
        data: {
          hosieryCostAnalysis,
          customerId: match.params.custId,
        },
      };
    this.setState({ isLoading: true });
    await POST(API_POST_BUSINESS_COST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/Sales/${match.params.custId}/post_login`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else {
          return alert(data.message);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        throw err;
      });
  };
  render() {
    let { match } = this.props,
      { hosieryCostAnalysis, isLoading } = this.state,
      { hosieryAndReadymadeGarments = [], productionLevelAndSales = [], businessDetails } = hosieryCostAnalysis;
    return (
      <>
        <Header {...this.props} />
        <div className="business-cost">
          <LoaderModal status={isLoading} />
          <div className="container">
            <Link
              to={`/cluster/${match.params.custId}/photo/Ludhiana-Hosiery-and-Readymade-Garment-Cluster`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Business Analysis</h1>
            <form>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Hosiery and Readymade Garment Cluster</h2>
                <p> Name of Hosiery and Readymade Garments manufactured</p>
                {hosieryTopPartsArray.map((hosieryPart) => {
                  const { name, label, gender } = hosieryPart;

                  return (
                    <div className="row" key={name}>
                      <div
                        className="col-xs-9 col-md-9 "
                        onClick={() => this.hosieryPartsHandler(name, hosieryAndReadymadeGarments[name])}
                      >
                        <div
                          className={hosieryAndReadymadeGarments[name] ? "active bicycle-part-box" : "bicycle-part-box"}
                        >
                          <img
                            src={require(`../../../assets/images/Hosiery/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {hosieryPart.label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Margin %"}
                          onChange={this.inputHandler}
                          type="number"
                          name={`hosieryAndReadymadeGarments.${name}.profitMargin`}
                          inputValue={hosieryAndReadymadeGarments[name]?.profitMargin || ""}
                          disabled={!hosieryAndReadymadeGarments[name]}
                        />
                      </div>
                      <div className="row">
                        <div className="col-xs-9 col-md-9 centre">
                          {hosieryAndReadymadeGarments[name]
                            ? gender.map((person, index) => (
                                <div
                                  key={`gender-${index}`}
                                  required={true}
                                  onClick={() => this.inputGenderHandler(name, person)}
                                  className={
                                    hosieryAndReadymadeGarments[name].category?.includes(person)
                                      ? "col-xs-3 col-md-2 active centre-box bicycle-part-box"
                                      : "col-xs-3 col-md-2  centre-box bicycle-part-box"
                                  }
                                >
                                  {person}
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {hosieryPartsArray.map((hosieryPart) => {
                  const { name, label } = hosieryPart;

                  return (
                    <div className="row" key={`Parts-${name}`}>
                      <div
                        className="col-xs-9 col-md-9 "
                        onClick={() => this.hosieryPartsHandler(name, hosieryAndReadymadeGarments[name])}
                      >
                        <div
                          className={hosieryAndReadymadeGarments[name] ? "active bicycle-part-box" : "bicycle-part-box"}
                        >
                          <img
                            src={require(`../../../assets/images/Hosiery/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {hosieryPart.label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Margin %"}
                          onChange={this.inputHandler}
                          type="number"
                          name={`hosieryAndReadymadeGarments.${name}`}
                          inputValue={hosieryAndReadymadeGarments[name] || ""}
                          disabled={!hosieryAndReadymadeGarments[name]}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Production level and Sales number</h2>
                {hosieryTopPartsArray.map((hosieryPart) => {
                  const { name, label } = hosieryPart;
                  return hosieryAndReadymadeGarments[name] ? (
                    <div className="container">
                      <div className="blue-label">{label}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputLabelAnimation
                            labelText={"Monthly sales / production (units)"}
                            onChange={this.inputHandler}
                            type="number"
                            name={`productionLevelAndSales.${name}.monthlySalesUnits`}
                            inputValue={productionLevelAndSales[name]?.monthlySalesUnits}
                          />
                        </div>
                        <div className="col-sm-6 pl-1">
                          <InputLabelAnimation
                            labelText={"Average sales price per unit (in ₹)"}
                            onChange={this.inputHandler}
                            type="number"
                            name={`productionLevelAndSales.${name}.averagePricePerUnit`}
                            inputValue={productionLevelAndSales[name]?.averagePricePerUnit}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
                {hosieryPartsArray.map((hosieryPart) => {
                  const { name, label } = hosieryPart;
                  return hosieryAndReadymadeGarments[name] ? (
                    <div className="container">
                      <div className="blue-label">{label}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputLabelAnimation
                            labelText={"Monthly sales / production (units)"}
                            onChange={this.inputHandler}
                            type="number"
                            name={`productionLevelAndSales.${name}.monthlySalesUnits`}
                            inputValue={productionLevelAndSales[name]?.monthlySalesUnits}
                          />
                        </div>
                        <div className="col-sm-6 pl-1">
                          <InputLabelAnimation
                            labelText={"Average sales price per unit (in ₹)"}
                            onChange={this.inputHandler}
                            type="number"
                            name={`productionLevelAndSales.${name}.averagePricePerUnit`}
                            inputValue={productionLevelAndSales[name]?.averagePricePerUnit}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Business details</h2>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Machines </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 ">
                      <InputLabelAnimation
                        labelText={" Number of Embroidery Machines"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.machines.embroideryMachines"}
                        inputValue={businessDetails?.machines?.embroideryMachines}
                      />
                    </div>
                    <div className="col-sm-6 pl-1 ">
                      <InputLabelAnimation
                        labelText={" Number of stitching machines"}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.machines.stichingMachines"}
                        inputValue={businessDetails?.machines?.stichingMachines}
                      />
                    </div>
                  </div>
                </div>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Purchase </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 ">
                      <InputLabelAnimation
                        labelText={"Fabric purchased in month (in sq. Meter)"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.purchase.fabricPurchasedMonthly"}
                        inputValue={businessDetails?.purchase?.fabricPurchasedMonthly}
                      />
                    </div>
                    <div className="col-sm-6 pl-1 ">
                      <InputLabelAnimation
                        labelText={"Average expense for fabric in a month (in ₹)"}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.purchase.averageExpenseOnFabricMonthly"}
                        inputValue={businessDetails?.purchase?.averageExpenseOnFabricMonthly}
                      />
                    </div>
                    <div className="col-sm-6 ">
                      <InputLabelAnimation
                        labelText={"Monthly cost of accessories purchased (in ₹)"}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.purchase.monthlyAccessoriesPurchasedCost"}
                        inputValue={businessDetails?.purchase?.monthlyAccessoriesPurchasedCost}
                      />
                    </div>
                  </div>
                </div>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Manufacturing Unit </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 ">
                      <InputLabelAnimation
                        labelText={" Size of Manufacturing Unit (in sq. Yard)"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.manufacturingUnit.sizeOfManufacturingUnit"}
                        inputValue={businessDetails?.manufacturingUnit?.sizeOfManufacturingUnit}
                      />
                    </div>
                  </div>
                </div>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Labour </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 ">
                      <InputLabelAnimation
                        labelText={"Number of labour deployed on machine"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.labour.labourDeployedOnMachine"}
                        inputValue={businessDetails?.labour?.labourDeployedOnMachine}
                      />
                    </div>
                    <div className="col-sm-6 pl-1 ">
                      <InputLabelAnimation
                        labelText={"Number of other Helper "}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.labour.otherHelper"}
                        inputValue={businessDetails?.labour?.otherHelper}
                      />
                    </div>
                    <div className="col-sm-6  ">
                      <InputLabelAnimation
                        labelText={"Total cost of labour per month (in ₹) "}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.labour.totalCostPerMonth"}
                        inputValue={businessDetails?.labour?.totalCostPerMonth}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box">
                <div className="btn-draft " onClick={() => this.addHosieryCost("draft")}>
                  Save for later
                </div>
                <div className="btn-save" onClick={() => this.addHosieryCost("save")}>
                  Save and Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    const { match } = this.props;
    this.setState({ isLoading: true });
    GET(API_GET_BUSINESS_COST(match.params.custId)).then(({ data }) => {
      if (data?.data?.hosieryCostAnalysis) {
        let { hosieryCostAnalysis } = data.data;
        this.setState({ hosieryCostAnalysis });
      }
      this.setState({ isLoading: false });
    });
  };
}
