import React, { Component } from "react";
import ReactTable from "react-table";
import {
  rupeeCommaFormatter,

} from "../../../utils/utitlity";


class AssetOwnership extends Component {
  render() {
    let { PropsData } = this.props;
    const columns = [
      {
        Header: " Asset name",
        accessor: "assetName",
        headerStyle: {textAlign: 'center'},
        Cell: ({ original }) => (
          <div style={{ textAlign: "center" }}>{original.assetName}</div>
        ),
      },
      {
        Header: " Year of ownership",
        accessor: "yearOfOwnership",
        Cell: ({ original }) => <div>{original.yearOfOwnership}</div>,
      },
      {
        Header: "  Payment method",
        accessor: "paymentMethod",
        Cell: ({ original }) => <div>{original.paymentMethod}</div>,
      },
      {
        Header: "Monthly EMI",
        accessor: "monthlyEmi",
        Cell: ({ original }) => <div>₹ {rupeeCommaFormatter(original.monthlyEmi) || "-"}</div>,
      },
    ],
    data=  PropsData?.assets || [];
    return (
      <div className="bank-details-wrapper">
        <h2> Asset Ownership</h2>
        <ReactTable
            columns={columns}
            data={data || []}
            defaultPageSize={10}
            showPagination={false}
            resizable={false}
            minRows={0}
            getTrProps={this.rowClicked}
          />
        {/* <Table striped hover>
          <thead>
            <tr>
              <th> Asset name </th>
              <th> Year of ownership</th>
              <th> Payment method</th>
              <th> Monthly EMI </th>
            </tr>
          </thead>
          <tbody>
            {PropsData?.assets?.length > 0 &&
              PropsData?.assets.map((item, index) => (
                <tr key={index}>
                  <td>{item.assetName || "-"}</td>
                  <td>{item.yearOfOwnership || "-"}</td>
                  <td>{item.paymentMethod || "-"}</td>
                  <td>₹ {rupeeCommaFormatter(item.monthlyEmi) || "-"}</td>
                </tr>
              ))}
          </tbody>
        </Table> */}
      </div>
    );
  }
}
export default AssetOwnership;
