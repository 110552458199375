import React from "react";
import { rupeeCommaFormatter, getDMY } from "../../../utils/utitlity";
const memberColumns = [
  {
    Header: "Member ID",
    accessor: "memberId",
    width:110
  },
  {
    Header: "Business Name",
    accessor: "businessName",
    width:160
  },
  {
    Header: "Owner Name",
    accessor: "ownerName",
    width:130
  },
  {
    Header: "State",
    accessor: "state",
    width:130
  },
  {
    Header: "Cluster",
    accessor: "clusterType",
  },
  {
    Header: "GST Number",
    accessor: "gstNumber",
    width:160
  },
  
  {
    Header: "Gating",
    accessor: "gatingStatus",
    width:110,
    Cell: ({ original }) => {
      let { gatingStatus } = original;
      return (
        <button
          style={{
            color:
              gatingStatus === "Approved" || gatingStatus === "Auto Approved" || gatingStatus === "Auto Approved"
                ? " #00CDBE"
                : gatingStatus === "Pending" || gatingStatus === "Service Down" || gatingStatus === "NTC"
                ? " #969696"
                : gatingStatus === "Rejected" || gatingStatus === "Auto Rejected" || gatingStatus === "Auto Rejected"
                ? "#ED5A5A"
                : gatingStatus === "Refer"
                ? "#E7B93C"
                : "transparent",
            backgroundColor:
              gatingStatus === "Approved" || gatingStatus === "Auto Approved" || gatingStatus === "Auto Approved"
                ? "#E5FAF8"
                : gatingStatus === "Pending" || gatingStatus === "Service Down" || gatingStatus === "NTC"
                ? " #E4E4E4"
                : gatingStatus === "Rejected" || gatingStatus === "Auto Rejected" || gatingStatus === "Auto Rejected"
                ? "#F9EAEA"
                : gatingStatus === "Refer"
                ? "#FDFAF1"
                : "transparent",
            borderRadius: "20px",
            height: "auto",
            outline: "none",
            width: "100px",
            borderStyle: "none",
            fontFamily: "Helvetica",
            lineHeight: "1.19",
            letterSpacing: "0",
            padding: " 7px 6px",
            fontSize: "13px",
          }}
        >
          {gatingStatus}
        </button>
      );
    },
  },
  {
    Header: "Mobile Number",
    accessor: "mobile",
    width:130
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
const dataTableHeaders = [
  {
    Header: "Date",
    accessor: "",
    Cell: (item) => {
      return <label>{getDMY(new Date(item.original.date))}</label>;
    },
  },
  {
    Header: "Case Id",
    accessor: "caseId",
    width:110
  },
  {
    Header: "Application Name",
    accessor: "applicationName",
    width:150
  },
  {
    Header: "Primary Industry",
    accessor: "primaryIndustry",
  },
  {
    Header: "PA (CIBIL Score)",
    accessor: "cibilScore",
  },
  {
    Header: "CC Score",
    accessor: "ccScore",
  },
  {
    Header: "Turnover",
    accessor: "turnOver",
    Cell: (props) => {
      return rupeeCommaFormatter(props.value);
    },
  },
  {
    Header: "Loan Amount",
    accessor: "loanAmount",
    Cell: (props) => {
      return rupeeCommaFormatter(props.value);
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: (row) => (
      <button
        style={{
          borderRadius: "20px",
          height: "auto",
          width: "106px",
          color: "#ffffff",
          borderStyle: "solid",
          fontFamily: "Helvetica",
          lineHeight: "1.19",
          letterSpacing: "0",
          padding: "7px 15px",
          display: "flex",
          alignItems: "center",
          backgroundColor:
            row.value === "Incomplete Application" ? "#001061" : row.value === "Submitted" ? "#00cdbe" : "#00cdbe",
        }}
      >
        {row.value === "Incomplete Application"
          ? "Incomplete"
          : row.value === "Submitted"
          ? `Submitted`
          : "Pending with credit"}
      </button>
    ),
  },
];

export { memberColumns, dataTableHeaders };
