import * as ACTION_TYPE from "./unitAnalysis.action.constant";
import { GET } from "../../../utils/webAPI.service";

import Axios from "axios";

const getPromoterDetails = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_PROMOTER_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getEmployeeDetails = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_EMPLOYEE_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getMonthlyExpenses = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_MONTHLY_EXPENSES, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getAddresses = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_ADDRESSES, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getAssetDetails = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_ASSET_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getBusinessMembers = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_BUSINESS_MEMBERS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getIncomeDetails = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_INCOME_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getInvestmentsAndFunds = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_INVESTMENTS_AND_FUNDS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getBusinessDetails = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_BUSINESS_DETAILS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getBankAccounts = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_BANK_ACCOUNTS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getDocumentsImages = (URL, docId, docType) => (dispatch) => {
  return Axios.get(URL(docId), {
    responseType: "arraybuffer",
  })
    .then((response) => {
      if (response) {
        dispatch({
          type: ACTION_TYPE[docType],
          data: Buffer.from(response.data, "binary").toString("base64"),
        });
      }
    })
    .catch((err) => {
      throw err;
    });
};
const getLocation = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_LOCATION, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getGatingStatus = (URL, customerId) => (dispatch) => {
  return GET(URL(customerId))
    .then(({ data }) => {
      if (data) {
        dispatch({ type: ACTION_TYPE.FETCH_GATING_STATUS, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export {
  getPromoterDetails,
  getEmployeeDetails,
  getMonthlyExpenses,
  getInvestmentsAndFunds,
  getIncomeDetails,
  getBusinessMembers,
  getAssetDetails,
  getAddresses,
  getBusinessDetails,
  getBankAccounts,
  getDocumentsImages,
  getLocation,
  getGatingStatus,
};
