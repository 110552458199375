const CHANGE_VALUE = Symbol("ChangeValue");
const UPDATE_FILTER_OPTIONS = Symbol("UpdateFilterOptions");
const ATTACH_FILTER_HANDLER = Symbol("AttachFilterHandler");
const FETCH_NOTIFICATION = Symbol("NotificationFetch");
const SET_INPUT_FIELD = "SET_INPUT_FIELD";
const CLEAR_HEADER_STATE = "CLEAR_HEADER_STATE";
export {
  CHANGE_VALUE,
  UPDATE_FILTER_OPTIONS,
  ATTACH_FILTER_HANDLER,
  FETCH_NOTIFICATION,
  SET_INPUT_FIELD,
  CLEAR_HEADER_STATE,
};
