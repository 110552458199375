import * as CPOPanelConstants from "./newCpoPanel.actions.constants";

const initialState = {
  cpoUserApplicationData: [],
  userExceptionData: [],
  searchBy: "",
  searchValue: "",
  pageNumber: 1,
};

export const CPOUserReducer = (state = initialState, action) => {
  let { data, type, name, value, index, fileType, fileName } = action;
  switch (type) {
    case CPOPanelConstants.GET_CPO_USER_APPLICATION:
      return {
        ...state,
        cpoUserApplicationData: data,
      };
    case CPOPanelConstants.GET_EXCEPTION_DATA:
      return {
        ...state,
        userExceptionData: data,
      };
    case "EDIT_CIBIL_EXCEPTION_DATA":
      return {
        ...state,
        userExceptionData: {
          ...state.userExceptionData,
          cibilExceptionData: state.userExceptionData.cibilExceptionData.map(
            (el, i) => {
              if (index === i) {
                return {
                  profileDetails: { ...el.profileDetails, [name]: value },
                };
              } else {
                return el;
              }
            }
          ),
        },
      };
    case "EDIT_GST_EXCEPTION_DATA":
      return {
        ...state,
        userExceptionData: {
          ...state.userExceptionData,
          gstExceptionData: state.userExceptionData.gstExceptionData.map(
            (el, i) => {
              if (index === i) {
                return { ...el, [name]: value };
              } else {
                return el;
              }
            }
          ),
        },
      };
    case "ADD_ACCOUNT_EXCEPTION_DATA":
      return {
        ...state,
        userExceptionData: {
          ...state.userExceptionData,
          bankingExceptionData: [
            ...(state.userExceptionData?.bankingExceptionData || []),
            data,
          ],
        },
      };
    case CPOPanelConstants.SET_SEARCH_CRITERIA:
      return {
        ...state,
        searchBy: action.payload.searchBy,
        searchValue: action.payload.searchValue,
      };
    case CPOPanelConstants.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
      };
    case "UPLOAD_BANKING_FILES":
      return {
        ...state,
        userExceptionData: {
          ...state.userExceptionData,
          bankingExceptionData:
            state.userExceptionData.bankingExceptionData.map(
              (el, testIndex) => {
                if (testIndex === index) {
                  return {
                    ...state.userExceptionData.bankingExceptionData[index],
                    [fileType]: state.userExceptionData.bankingExceptionData[
                      index
                    ][fileType]
                      ? [
                          ...state.userExceptionData.bankingExceptionData[
                            index
                          ][fileType],
                          fileName,
                        ]
                      : [fileName],
                  };
                } else {
                  return el;
                }
              }
            ),
        },
      };
    default:
      return state;
  }
};
