const GET_ALL_BALANCE_SHEET = Symbol("BalanceSheetAllData");
const GET_ALL_PROFIT_AND_LOSS_ACCOUNT = Symbol("ProfitAndLossAccountData");
const ASSESSEE_DETAILS = Symbol("getAssesseeDetailsAndOtherAnalytics"),
  RATIOS = Symbol("getRatios");

export {
  GET_ALL_BALANCE_SHEET,
  GET_ALL_PROFIT_AND_LOSS_ACCOUNT,
  ASSESSEE_DETAILS,
  RATIOS,
};
