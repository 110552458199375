import React, { Component } from 'react';
import { InputLabelAnimation } from '../../../components';
import Icon from 'react-icons-kit';
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever';
import {
  API_GET_TAXPAYER_DATA,
  API_GST_OTP_REQUEST,
  API_VALIDATE_GST_OTP,
  API_GENERATE_VALIDATE_OTP_LINK,
  API_GST_USERNAME_LINK,
} from '../../../utils/APIUrls';
import { mapValues } from 'lodash';
import { POST } from '../../../utils/webAPI.service';
import { checkCircle } from 'react-icons-kit/fa/checkCircle';
import CredoModal from '../../../components/CredoModal/CredoModal';
export default class GSTDetailsRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenVisible: {
        renderUserInput: true,
        renderGstValidate: false,
        renderOtpInput: false,
        renderGSTVerified: false,
      },
      isModalOpen: false,
    };
  }

  validateGSTHandler = async () => {
    let { match, actions, gstUsername, gstin, rowIndex, gstFlowHandler } =
        this.props,
      appNo = match.params.appNo;
    if (!gstin) {
      return alert(`Please Enter GST number for ${rowIndex + 1} row`);
    }
    let data = {
      data: {
        applicationNumber: appNo,
        gstUsername,
        gstin: gstin.split(' - ').join(''),
      },
    };
    actions.loaderModal(true);
    await POST(API_GET_TAXPAYER_DATA, data)
      .then(({ data }) => {
        if (data.status === 'success') {
          gstFlowHandler();
          this.setState((prevState) => ({
            ...prevState,
            screenVisible: {
              ...mapValues(prevState.screenVisible, () => false),
              renderGstValidate: true,
            },
            tradeName: data.data,
          }));
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  otpGenerateHandler = async () => {
    let { match, actions, gstUsername, gstin, rowIndex, gstFlowHandler } =
        this.props,
      appNo = match.params.appNo;
    if (!gstin) {
      return alert(`Please Enter GST number for ${rowIndex + 1} row`);
    }
    if (!gstUsername) {
      return alert(`Please Enter GST User name for ${rowIndex + 1} row`);
    }
    let data = {
      data: {
        applicationNumber: appNo,
        gstUsername,
        gstin: gstin.split(' - ').join(''),
      },
    };
    actions.loaderModal(true);
    await POST(API_GST_OTP_REQUEST, data)
      .then(({ data }) => {
        if (data.status === 'success') {
          gstFlowHandler();
          this.setState((prevState) => ({
            ...prevState,
            screenVisible: {
              ...mapValues(prevState.screenVisible, () => false),
              renderOtpInput: true,
            },
          }));
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };

  shareLinkHandler = async (e) => {
  
    e.preventDefault();
    let { screenVisible, mobileNumber, email } = this.state,
      { match, actions, gstUsername, gstin } = this.props,
      applicationNumber = match.params.appNo;
    if (!gstin) {
      return alert('Please Enter GST Number');
    }
    if (!mobileNumber || !email) {
      return alert('Please Enter Mobile Number or Email');
    }
    if (screenVisible.renderUserInput) {
      // USER NAME AND OTP CALL
      let data = {
        mobileNumber,
        email,
        applicationNumber,
        gstin: gstin.split(' - ').join(''),
      };
      actions.loaderModal(true);
      POST(API_GST_USERNAME_LINK, { data })
        .then(({ data }) => {
          if (data.data) {
            this.modalToggle();
          } else {
            alert(data.message);
          }
          actions.loaderModal(false);
        })
        .catch((err) => {
          actions.loaderModal(false);
          throw err;
        });
    } else {
      // ONLY OTP INPUT FIELD
      if (!gstUsername) {
        return alert('Please Enter GST Username');
      }
      let data = {
        mobileNumber,
        email,
        applicationNumber,
        gstUsername,
        gstin: gstin.split(' - ').join(''),
      };
      actions.loaderModal(true);
      POST(API_GENERATE_VALIDATE_OTP_LINK, { data })
        .then(({ data }) => {
          if (data.data) {
            this.modalToggle();
          } else {
            alert(data.message);
            this.modalToggle();
          }
          
          actions.loaderModal(false);
        })
        .catch((err) => {
          actions.loaderModal(false);
          throw err;
        });
    }
  };
  submitOTPHandler = async () => {
    let { actions, match, gstin } = this.props,
      applicationNumber = match.params.appNo,
      { otp } = this.state,
      data = { applicationNumber, gstin: gstin.split(' - ').join(''), otp };
    if (!otp) {
      return alert('Please Enter OTP Sent to registered mobile number');
    }
    actions.loaderModal(true);
    POST(API_VALIDATE_GST_OTP, { data })
      .then(({ data }) => {
        if (data.status === 'success') {
          this.setState((prevState) => ({
            ...prevState,
            screenVisible: {
              ...mapValues(prevState.screenVisible, () => false),
              renderGSTVerified: true,
            },
          }));
        } else {
          alert('Please Enter correct OTP ');
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  modalToggle = () => {
    this.setState((prevState) => ({
      ...prevState,
      isModalOpen: !prevState.isModalOpen,
    }));
  };
  render() {
    
    let { screenVisible, isModalOpen } = this.state;
    return (
      <div className='gst-details-row__wrapper' key={this.props.rowIndex}>
        {Object.keys(screenVisible).map((screen, index) =>
          screenVisible[screen] ? this[screen](index) : null
        )}
        <CredoModal
          isOpen={isModalOpen}
          className={'edit-ratio-modal'}
          props={this.props}
          styles={{
            content: {
              width: '400px',
            },
          }}
          title={'Share link to trigger OTP'}
          closeAction={this.modalToggle}
          RenderingComponent={this.renderModal}
        />
      </div>
    );
  }
  renderUserInput = (index) => {
    let { rowIndex, gstin, gstUsername } = this.props;
    return (
      <div className='card__wrapper' key={`gst-row-${index}`}>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <InputLabelAnimation
              labelText={'Enter GST Number'}
              required={true}
              name={'gstin'}
              onChange={(e) => this.props.inputHandler(e, rowIndex)}
              mask={'** - ********** - ***'}
              inputValue={gstin}
            />
          </div>
          <div className='col-md-6 col-sm-12'>
            <InputLabelAnimation
              labelText={'Enter Username'}
              required={true}
              name={'gstUsername'}
              onChange={(e) => this.props.inputHandler(e, rowIndex)}
              inputValue={gstUsername}
            />
          </div>
        </div>
        <div>
          {rowIndex > 0 ? (
            <span
              onClick={() => this.props.deleteRow(rowIndex)}
              className={'remove-icon'}
            >
              <Icon icon={ic_delete_forever} size={28} />
            </span>
          ) : null}
        </div>
        <div className='md:d-flex sm:d-block'>
          <button
            className='button__generic-blue'
            onClick={this.validateGSTHandler}
          >
            Validate GST Number
          </button>
          <button
            className='button__generic-blue'
            onClick={this.otpGenerateHandler}
          >
            Generate OTP to fetch data
          </button>
          <button className='button__generic-blue' onClick={this.modalToggle}>
            Share link to trigger OTP
          </button>
        </div>
      </div>
    );
  };
  renderGstValidate = (index) => {
    let { gstin, gstUsername, rowIndex } = this.props,
      { tradeName } = this.state;
    return (
      <div
        className='gst-validate__wrapper card__wrapper'
        key={`gst-row-${index}`}
      >
        <div className='row'>
          <div className='col-md-3 col-sm-12'>
            <div className='label'>GST Number</div>
            <div className='value mt-2 d-flex justify-center'>
              {gstin}
              <div className='success-message-m ml-2'>
                <Icon icon={checkCircle} size={14} />
              </div>
            </div>
          </div>

          <div className='col-md-4 col-sm-12 username-input'>
            <InputLabelAnimation
              labelText={'Enter Username'}
              required={true}
              name={'gstUsername'}
              onChange={(e) => this.props.inputHandler(e, rowIndex)}
              inputValue={gstUsername}
            />
          </div>

          <div className='col-md-3 col-sm-12'>
            <div className='label'>Trade Name</div>
            <div className='value mt-2'>{tradeName}</div>
          </div>
          <div className='success-message col-md-2'>
            <Icon icon={checkCircle} size={22} /> GST Validated
          </div>
        </div>
        <div className='md:d-flex hidden-sm'>
          <button
            className='button__generic-blue'
            onClick={this.otpGenerateHandler}
          >
            Generate OTP to fetch data
          </button>
          <button className='button__generic-blue' onClick={this.modalToggle}>
            Share link to trigger OTP
          </button>
          <div>
            <span
              onClick={() => this.props.deleteRow(rowIndex)}
              className={'remove-icon'}
            >
              <Icon icon={ic_delete_forever} size={28} />
            </span>
          </div>
        </div>
      </div>
    );
  };
  renderOtpInput = (index) => {
    let { rowIndex, gstin, gstUsername } = this.props,
      { tradeName, otp } = this.state;
    return (
      <div className='card__wrapper p-3 mt-3' key={`gst-row-${index}`}>
        <div className='gst-details d-flex'>
          <div>
            <div className='label'>GST Number</div>
            <div className='value'>{gstin}</div>
          </div>
          <div>
            <div className='label'>GST Username</div>
            <div className='value'>{gstUsername}</div>
          </div>
          <div>
            <div className='label'>Trade Name</div>
            <div className='value'>{tradeName}</div>
          </div>
          <div>
            <span
              onClick={() => this.props.deleteRow(rowIndex)}
              className={'remove-icon mt-2'}
            >
              <Icon icon={ic_delete_forever} size={28} />
            </span>
          </div>
        </div>
        <div className='action-button'>
          <input
            id='otp'
            type='text'
            placeholder='Enter OTP'
            maxLength={6}
            name={'otp'}
            onChange={(e) => this.setState({ otp: e.target.value })}
            inputValue={otp}
          />
          <div>
            <button
              className='button button__blue'
              onClick={this.submitOTPHandler}
            >
              Submit OTP
            </button>
            <button
              className='button button__transparent'
              onClick={this.modalToggle}
            >
              Share Link
            </button>
          </div>
          <div>
            <div className='resend__link' onClick={this.otpGenerateHandler}>
              Click here to resend OTP
            </div>
          </div>
        </div>
        <section className='notification__section'>
          <div className='left-part'>Note:</div>
          <div className='right-part'>
            <p>Thank you for initiating request for GST based Loan with us.</p>
            <p>
              GST OTP is only used to assess your loan eligibility and utmost
              confidentiality is maintained.
            </p>
            <p>
              If you’re not getting the OTP, please enable “API access”. See how
            </p>
          </div>
        </section>
      </div>
    );
  };
  renderGSTVerified = (index) => {
    let { gstin, gstUsername } = this.props,
      { tradeName } = this.props;
    return (
      <div className='otp-verified__wrapper' key={`gst-row-${index}`}>
        <div className='card__wrapper'>
          <div className='d-flex child-space-between'>
            <div>
              <div className='label'>GST Number</div>
              <div className='value'>{gstin}</div>
            </div>
            <div>
              <div className='label'>Username</div>
              <div className='value'>{gstUsername}</div>
            </div>
            <div>
              <div className='label'>Trade Name</div>
              <div className='value'>{tradeName}</div>
            </div>
            <div className='success-message'>
              <Icon icon={checkCircle} size={22} /> OTP Submitted
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderModal = (index) => {
    let { mobileNumber, email } = this.state;
   
    return (
      <form
        className='share-otp-modal__wrapper'
        onSubmit={this.shareLinkHandler}
        key={`gst-row-${index}`}
      >
        <div className='mt-3'>
          <InputLabelAnimation
            labelText={'Enter Mobile Number'}
            required={true}
            name={'mobileNumber'}
            onChange={(e) => this.setState({ mobileNumber: e.target.value })}
            inputValue={mobileNumber}
          />
        </div>
        <div className='mt-3'>
          <InputLabelAnimation
            labelText={'Enter Email'}
            name={'email'}
            onChange={(e) => this.setState({ email: e.target.value })}
            inputValue={email}
          />
        </div>
        <div className='text-center mt-3'>
          <button
            type='submit'
            className='save-btn'
            onClick={this.shareLinkHandler}
          >
            Share Link
          </button>
        </div>
      </form>
    );
  };
}
