import { get, set } from "lodash";

const _getValue = (state, name) => {
  return get(state, `sourcingDetails.${name}`);
};

const _updateFormValues = (state, value, name) => {
  const _preValue = _getValue(state, name);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state));
    let _changedValue = get(
      set(_changedState, `sourcingDetails.${name}`, value),
      "sourcingDetails"
    );
    return { ..._changedState, sourcingDetails: _changedValue };
  }
  return state;
};

export { _updateFormValues };
