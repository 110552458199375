import { get, set } from "lodash";

const _getValue = (state, name, index) => {
  return get(state, `bankingDetails[${index}].${name}`);
};

const _getFileInputValue = (state, name, formIndex, fileIndex) => {
  return get(state, `bankingDetails[${formIndex}].files[${fileIndex}].${name}`);
};

const _updateFormValues = (state, name, value, index) => {
  const _preValue = _getValue(state, name, index);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(set(_changedState, `bankingDetails[${index}].${name}`, value), "bankingDetails");
    return { ..._changedState, bankingDetails: [..._changedValue] };
  }
  return state;
};

const _updateFileValues = (state, name, value, formIndex, fileIndex) => {
  const _preValue = _getFileInputValue(state, name, formIndex, fileIndex);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(
        set(_changedState, `bankingDetails[${formIndex}].files[${fileIndex}].${name}`, value),
        "bankingDetails"
      );
    return { ..._changedState, bankingDetails: [..._changedValue] };
  }
  return state;
};

const _deleteFormBox = (state, index) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _rowsList = get(_changedState, "bankingDetails", []);
  _rowsList.splice(index, 1);
  return _changedState;
};

const _addFormBox = (state) => {
  let _changedState = JSON.parse(JSON.stringify(state));
  set(_changedState, "bankingDetails", [
    ..._changedState.bankingDetails,
    {
      files: [
        {
          isUploaded: false,
          fileName: "",
        },
      ],
    },
  ]);
  return _changedState;
};

const _deleteFileRow = (state, formIndex, fileIndex) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _rowsList = get(_changedState, `bankingDetails[${formIndex}].files`);
  _rowsList.splice(fileIndex, 1);
  return _changedState;
};

const _addFileRow = (state, formIndex) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _rowList = get(_changedState, `bankingDetails[${formIndex}].files`);
  set(_changedState, `bankingDetails[${formIndex}].files`, [..._rowList, { isUploaded: false, fileName: "" }]);
  return _changedState;
};

export { _updateFormValues, _updateFileValues, _deleteFormBox, _addFormBox, _deleteFileRow, _addFileRow };
