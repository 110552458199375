import React from "react";
import CrossIcon from "../../assets/images/crossIcon.svg";
import CheckIcon from "../../assets/images/checkIcon.svg";

const unAssignedHeader = [
  {
    Header: "Login time",
    accessor: "createdDate",
    width: 200,

    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
  {
    Header: "Agent Details",
    id: "agentDetails",
    accessor: (d) => d.agentDetails,
    Cell: ({ original }) => {
      let { agentDetails } = original;
      if (agentDetails) {
        let {
          middleName = "",
          firstName = "",
          lastName = "",
          mobile = "",
          email = "",
        } = agentDetails;
        firstName = firstName || "";
        middleName = middleName || "";
        lastName = lastName || "";
        mobile = mobile || "";
        email = email || "";
        return (
          <div>
            <div>{`${firstName} ${middleName} ${lastName}`}</div>
            <div>{`${mobile}`}</div>
            <div style={{ overflowWrap: "break-word" }}>{`${email}`}</div>
          </div>
        );
      } else {
        return "No Data Found";
      }
    },
  },
  {
    Header: "User Id",
    accessor: "customerId",
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
    width: 160,
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },
  {
    Header: "Presented to Credit",
    accessor: "isCreditPresented",
    width: 180,
    Cell: ({ original }) => {
      let { isCreditPresented } = original;
      return (
        <span>
          {isCreditPresented ? (
            <span style={{ color: "green" }}>
              {
                <img
                  src={CheckIcon}
                  alt="Cross Icon"
                  style={{ height: "16px" }}
                />
              }
            </span>
          ) : (
            <span disabled={true} style={{ height: "16px" }}>
              {
                <img
                  src={CrossIcon}
                  alt="Cross Icon"
                  style={{ height: "16px" }}
                />
              }
            </span>
          )}
        </span>
      );
    },
    style: { overflow: "wrap" },
  },
  {
    Header: "Exception",
    accessor: "exception",
    Cell: ({ row }) => {
      let { _original, _index } = row;
      return (
        <span key={_index}>
          {_original.exception !== null ? _original.exception.join(", ") : ""}
        </span>
      );
    },
  },
];

const assignedHeader = [
  {
    Header: "Login time",
    accessor: "createdDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
  {
    Header: "Agent Details",
    id: "agentDetails",
    accessor: (d) => d.agentDetails,
    Cell: ({ original }) => {
      let { agentDetails } = original;
      if (agentDetails) {
        let {
          middleName = "",
          firstName = "",
          lastName = "",
          mobile = "",
          email = "",
        } = agentDetails;
        firstName = firstName || "";
        middleName = middleName || "";
        lastName = lastName || "";
        mobile = mobile || "";
        email = email || "";
        return (
          <div>
            <div>{`${firstName} ${middleName} ${lastName}`}</div>
            <div>{`${mobile}`}</div>
            <div style={{ overflowWrap: "break-word" }}>{`${email}`}</div>
          </div>
        );
      } else {
        return "No Data Found";
      }
    },
  },
  {
    Header: "TAT CPO Start",
    accessor: "tatDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
  {
    Header: "User Id",
    accessor: "customerId",
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },
  {
    Header: () => (
      <>
        {" "}
        <div>Presented to</div>
        <div>Credit</div>
      </>
    ),

    accessor: "isCreditPresented",

    Cell: ({ original }) => {
      let { isCreditPresented } = original;
      return (
        <span>
          {isCreditPresented ? (
            <span style={{ color: "green" }}>
              <img
                src={CheckIcon}
                alt="Cross Icon"
                style={{ height: "16px" }}
              />
            </span>
          ) : (
            <span disabled={true} style={{ height: "16px" }}>
              <img
                src={CrossIcon}
                alt="Cross Icon"
                style={{ height: "16px" }}
              />
            </span>
          )}
        </span>
      );
    },
  },
  {
    Header: "Exception",
    id: "exception",
    accessor: (d) => d.exception,
    Cell: ({ row }) => {
      let { _original, _index } = row;
      return (
        <span key={_index}>
          {_original.exception && _original.exception.length !== 0
            ? _original.exception.join(", ")
            : ""}
        </span>
      );
    },
  },

  {
    Header: "Assigned",
    accessor: "opsUser",
  },
];
const closedHeader = [
  {
    Header: "Login time",
    accessor: "createdDate",
    width: 100,
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
  {
    Header: "Agent Details",
    id: "agentDetails",
    accessor: (d) => d.agentDetails,
    Cell: ({ original }) => {
      let { agentDetails } = original;
      if (agentDetails) {
        let {
          middleName = "",
          firstName = "",
          lastName = "",
          mobile = "",
          email = "",
        } = agentDetails;
        firstName = firstName || "";
        middleName = middleName || "";
        lastName = lastName || "";
        mobile = mobile || "";
        email = email || "";
        return (
          <div>
            <div>{`${firstName} ${middleName} ${lastName}`}</div>
            <div>{`${mobile}`}</div>
            <div style={{ overflowWrap: "break-word" }}>{`${email}`}</div>
          </div>
        );
      } else {
        return "No Data Found";
      }
    },
  },
  {
    Header: "TAT CPO Closed",
    accessor: "tatDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
  {
    Header: "User Id",
    accessor: "customerId",
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },
  {
    Header: "Agent Details",
    id: "agentDetails",
    accessor: (d) => d.agentDetails,
    Cell: ({ original }) => {
      let { agentDetails } = original;
      if (agentDetails) {
        let {
          middleName = "",
          firstName = "",
          lastName = "",
          mobile = "",
          email = "",
        } = agentDetails;
        firstName = firstName || "";
        middleName = middleName || "";
        lastName = lastName || "";
        mobile = mobile || "";
        email = email || "";
        return (
          <div>
            <div>{`${firstName} ${middleName} ${lastName}`}</div>
            <div>{`${mobile}`}</div>
            <div style={{ overflowWrap: "break-word" }}>{`${email}`}</div>
          </div>
        );
      } else {
        return "No Data Found";
      }
    },
  },
  {
    Header: () => (
      <>
        {" "}
        <div>Presented to</div>
        <div>Credit</div>
      </>
    ),
    accessor: "isCreditPresented",

    Cell: ({ original }) => {
      let { isCreditPresented } = original;
      return (
        <span>
          {isCreditPresented ? (
            <span style={{ color: "green" }}>
              <img
                src={CheckIcon}
                alt="Cross Icon"
                style={{ height: "16px" }}
              />
            </span>
          ) : (
            <span disabled={true} style={{ height: "16px" }}>
              <img
                src={CrossIcon}
                alt="Cross Icon"
                style={{ height: "16px" }}
              />
            </span>
          )}
        </span>
      );
    },
  },
  {
    Header: () => (
      <>
        <div>Resolved</div>
        <div>Exception</div>
      </>
    ),
    accessor: "exception",
  },
  {
    Header: "Preview",
    accessor: "assignedto",
    width: 100,
  },
];

const searchByOptions = [
  {
    label: "Case Id",
    value: "case_id",
  },
];

export { unAssignedHeader, closedHeader, assignedHeader, searchByOptions };
