import { POST, GET } from "../../utils/webAPI.service";
import {
  API_GET_CPO_USER_APPLICATION,
  API_EXCEPTION_DATA,
  API_RE_TRIGGER_CIBIL,
  API_FETCH_FORM_FILLED_BY,
  API_CPO_SEARCH,
} from "../../utils/APIUrls";
import * as CPOPanelConstants from "./newCpoPanel.actions.constants";
const getOPSUserApplication = (data) => (dispatch) => {
  return POST(API_GET_CPO_USER_APPLICATION, data)
    .then(({ data, status }) => {
      if (status === 200) {
        dispatch({
          type: CPOPanelConstants.GET_CPO_USER_APPLICATION,
          data: data?.data,
        });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getOPSUserFilteredApplication = (data) => (dispatch) => {
  return POST(API_CPO_SEARCH, data)
    .then(({ data, status }) => {
      console.log(data, "data");
      if (status === 200) {
        dispatch({
          type: CPOPanelConstants.GET_CPO_USER_APPLICATION,
          data: data,
        });
      }
      if (data?.data === null) {
        alert("No result found");
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getExceptionData = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_EXCEPTION_DATA(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: CPOPanelConstants.GET_EXCEPTION_DATA,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const editCibilExceptionData = (name, value, index) => {
  return {
    type: "EDIT_CIBIL_EXCEPTION_DATA",
    name,
    value,
    index,
  };
};
const editGSTExceptionData = (name, value, index) => {
  return {
    type: "EDIT_GST_EXCEPTION_DATA",
    index,
    name,
    value,
  };
};
const uploadBankingFiles = (fileName, fileType, index) => {
  return {
    type: "UPLOAD_BANKING_FILES",
    index,
    fileName,
    fileType,
  };
};

const setSearchCriteria = (searchBy, searchValue) => {
  return {
    type: CPOPanelConstants.SET_SEARCH_CRITERIA,
    payload: { searchBy, searchValue },
  };
};
const triggerCibil = (appNo, pan) => (dispatch) => {
  return GET(API_RE_TRIGGER_CIBIL(appNo, pan))
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({ type: CPOPanelConstants.TRIGGER_CIBIL, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const addAccountBankException = (data) => {
  return { type: "ADD_ACCOUNT_EXCEPTION_DATA", data: data };
};

const setPageNumber = (pageNumber) => {
  return {
    type: CPOPanelConstants.SET_PAGE_NUMBER,
    payload: { pageNumber },
  };
};

export {
  getOPSUserApplication,
  getExceptionData,
  editCibilExceptionData,
  addAccountBankException,
  triggerCibil,
  editGSTExceptionData,
  uploadBankingFiles,
  getOPSUserFilteredApplication,
  setSearchCriteria,
  setPageNumber,
};
