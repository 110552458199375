import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_GET_BUSINESS_COST,
  API_POST_BUSINESS_COST,
} from "../../../utils/APIUrls";
import { set } from "lodash";
import { sewingPartsArray } from "./bicycle.config";
import { Header, LoaderModal } from "../../../components";
export default class SewingMachineBusinessCost extends Component {
  state = {
    sewingMachineConstAnalysis: {},
    isLoading: false,
  };
  inputHandler = (e) => {
    let { name, value } = e.target,
      { sewingMachineConstAnalysis } = this.state;
    this.setState({
      sewingMachineConstAnalysis: set(sewingMachineConstAnalysis, name, value),
    });
  };

  bicyclePartsHandler = (name, value, label, index) => {
    let { sewingMachineConstAnalysis } = this.state,
      inputValue = !value ? " " : null;
    this.setState({
      sewingMachineConstAnalysis: {
        ...sewingMachineConstAnalysis,
        manufacturingDetails: {
          ...sewingMachineConstAnalysis?.manufacturingDetails,
          [name]: inputValue,
        },
        productionAndSalesNumbers: {
          machineDetail: [
            ...(sewingMachineConstAnalysis?.productionAndSalesNumbers
              ?.machineDetail || []),
            {
              machineType: label,
            },
          ],
        },
      },
    });
  };

  addWorkHandler = () => {
    let { sewingMachineConstAnalysis } = this.state;
    this.setState({
      sewingMachineConstAnalysis: {
        ...sewingMachineConstAnalysis,
        manufacturingDetails: {
          ...sewingMachineConstAnalysis?.manufacturingDetails,

          otherWorks: [
            ...(sewingMachineConstAnalysis?.manufacturingDetails?.otherWorks ||
              []),
            {},
          ],
        },
        productionAndSalesNumbers: {
          machineDetail: [
            ...(sewingMachineConstAnalysis?.productionAndSalesNumbers
              ?.machineDetail || []),
            {},
          ],
        },
      },
    });
  };

  addCustomerHandler = () => {
    let { sewingMachineConstAnalysis } = this.state;
    this.setState({
      sewingMachineConstAnalysis: {
        ...sewingMachineConstAnalysis,
        customerAndSupplyLinkage: {
          ...sewingMachineConstAnalysis?.customerAndSupplyLinkage,

          otherCustomers: [
            ...(sewingMachineConstAnalysis?.customerAndSupplyLinkage
              ?.otherCustomers || []),
            {},
          ],
        },
      },
    });
  };

  addSewingMachineCost = async (actionType) => {
    let { sewingMachineConstAnalysis } = this.state,
      { match, history } = this.props;
    let dataToSend = {
      data: {
        sewingMachineConstAnalysis,
        customerId: match.params.custId,
      },
    };
    this.setState({ isLoading: true });
    await POST(API_POST_BUSINESS_COST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/Sales/${match.params.custId}/post_login`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else {
          return alert(data.message);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        throw err;
      });
  };
  render() {
    let { match } = this.props,
      { sewingMachineConstAnalysis, isLoading } = this.state,
      {
        manufacturingDetails = [],
        businessDetails,
        customerAndSupplyLinkage,
        productionAndSalesNumbers,
      } = sewingMachineConstAnalysis;

    return (
      <>
        <Header {...this.props} />
        <div className="business-cost">
          <LoaderModal status={isLoading} />
          <div className="container">
            <Link
              to={`/cluster/${match.params.custId}/photo/Ludhiana-Sewing-Machine-Cluster`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Business Analysis</h1>
            <form>
              <div className="container m-top--main mt-2">
                <div className=" flex-box">
                  <h2 className="basic-heading">Sewing Machine Cluster</h2>
                  <button
                    className="btn-add"
                    type="button"
                    onClick={this.addWorkHandler}
                  >
                    + Add work
                  </button>
                </div>

                <h5>Type of Manufacturing your entity does</h5>
                {sewingPartsArray.map((bicyclePart, index) => {
                  const { name, label } = bicyclePart;
                  return (
                    <div className="row" key={name}>
                      <div
                        className="col-xs-9 col-md-9 "
                        onClick={() =>
                          this.bicyclePartsHandler(
                            name,
                            manufacturingDetails[name],
                            label,
                            index
                          )
                        }
                      >
                        <div
                          className={
                            manufacturingDetails[name]
                              ? "active bicycle-part-box"
                              : "bicycle-part-box"
                          }
                        >
                          <img
                            src={require(`../../../assets/images/Sewing_Machine/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Margin %"}
                          onChange={this.inputHandler}
                          name={`manufacturingDetails.${name}`}
                          inputValue={manufacturingDetails[name] || ""}
                          disabled={!manufacturingDetails[name]}
                          type="number"
                        />
                      </div>
                    </div>
                  );
                })}
                {manufacturingDetails?.otherWorks?.map(
                  (sewingMachine, index) => {
                    const { work, minNetMargin } = sewingMachine;
                    return (
                      <div className="row" key={`${index}`}>
                        <div className="col-xs-9 col-md-9 ">
                          <InputLabelAnimation
                            labelText={`Other Parts ${index + 1}`}
                            onChange={(e) => {
                              let { value } = e.target,
                                { sewingMachineConstAnalysis } = this.state;

                              this.setState({
                                sewingMachineConstAnalysis: set(
                                  sewingMachineConstAnalysis,
                                  `productionAndSalesNumbers.machineDetail.[${
                                    productionAndSalesNumbers.machineDetail
                                      .length - 1
                                  }].machineType`,
                                  value
                                ),
                              });
                              this.inputHandler(e);
                            }}
                            name={`manufacturingDetails.otherWorks.[${index}].work`}
                            inputValue={work || ""}
                            required={true}
                          />
                        </div>
                        <div className="col-xs-3 col-md-3 pl-2">
                          <InputLabelAnimation
                            labelText={"Margin %"}
                            onChange={this.inputHandler}
                            name={`manufacturingDetails.otherWorks.[${index}].minNetMargin`}
                            inputValue={minNetMargin || ""}
                            required={true}
                            type="number"
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              <div className="container m-top--main mt-2">
                <div className="flex-box">
                  <div className="basic-heading">
                    Customer and Supply linkage
                  </div>
                  <div className="btn-add " onClick={this.addCustomerHandler}>
                    {" "}
                    + Add customer
                  </div>
                </div>
                <h5>What percentage of your sales is directed towards:</h5>
                <div className="row mb-2">
                  <div className="col-sm-6 mt-0">
                    <InputLabelAnimation
                      labelText={" Directly Supply to Singer"}
                      onChange={this.inputHandler}
                      required={true}
                      type="number"
                      name={"customerAndSupplyLinkage.directSupplyToSinger"}
                      inputValue={
                        customerAndSupplyLinkage?.directSupplyToSinger
                      }
                    />
                  </div>
                  <div className="col-sm-6 mt-0 pl-1">
                    <InputLabelAnimation
                      labelText={" Direct supply to BMS"}
                      onChange={this.inputHandler}
                      required={true}
                      type="number"
                      name={"customerAndSupplyLinkage.directSupplyToBMS"}
                      inputValue={customerAndSupplyLinkage?.directSupplyToBMS}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-6 mt-0">
                    <InputLabelAnimation
                      labelText={" Direct Supply to Usha"}
                      onChange={this.inputHandler}
                      required={true}
                      type="number"
                      name={"customerAndSupplyLinkage.directSupplyToUsha"}
                      inputValue={customerAndSupplyLinkage?.directSupplyToUsha}
                    />
                  </div>
                  <div className="col-sm-6 mt-0 pl-1">
                    <InputLabelAnimation
                      labelText={
                        " Supply to Vendors of Singer, Usha & other OEMs"
                      }
                      onChange={this.inputHandler}
                      required={true}
                      type="number"
                      name={
                        "customerAndSupplyLinkage.supplyToVendersOfSingerUshaAndOthers"
                      }
                      inputValue={
                        customerAndSupplyLinkage?.supplyToVendersOfSingerUshaAndOthers
                      }
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-6 mt-0">
                    <InputLabelAnimation
                      labelText={"Replacement (Spares) Market"}
                      onChange={this.inputHandler}
                      required={true}
                      type="number"
                      name={"customerAndSupplyLinkage.replacementOrSpareMarket"}
                      inputValue={
                        customerAndSupplyLinkage?.replacementOrSpareMarket
                      }
                    />
                  </div>
                 
                </div>
                <div className="border-grey">
                  {customerAndSupplyLinkage?.otherCustomers?.map(
                    (person, index) => {
                      const { customer, salesPercentage } = person;
                      return (
                        <div className="row" key={`machine-and-tools-${index}`}>
                          <div className="col-xs-12 col-md-6 ">
                            <InputLabelAnimation
                              labelText={`Other customer `}
                              required={true}
                              onChange={this.inputHandler}
                              name={`customerAndSupplyLinkage.otherCustomers.[${index}].customer`}
                              inputValue={customer || ""}
                            />
                          </div>
                          <div className="col-xs-12 col-md-6 pl-1">
                            <InputLabelAnimation
                              labelText={"Type sales percentage "}
                              onChange={this.inputHandler}
                              required={true}
                              type="number"
                              name={`customerAndSupplyLinkage.otherCustomers.[${index}].salesPercentage`}
                              inputValue={salesPercentage || ""}
                            />
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">
                  Production level and Sales number
                </h2>

                {productionAndSalesNumbers?.machineDetail?.map(
                  (Part, index) => {
                    const {
                      monthlyProducedOrSoldUnit,
                      averageSalePerUnit,
                      machineType,
                    } = Part;
                    return (
                      <div className="container" key={machineType}>
                        <div className="blue-label">{machineType}</div>
                        <div className="row">
                          <div className="col-sm-6">
                            <InputLabelAnimation
                              labelText={"Monthly sales / production (units)"}
                              onChange={this.inputHandler}
                              name={`productionAndSalesNumbers.machineDetail.[${index}].monthlyProducedOrSoldUnit`}
                              inputValue={monthlyProducedOrSoldUnit}
                              required={true}
                              type="number"
                            />
                          </div>
                          <div className="col-sm-6 pl-1">
                            <InputLabelAnimation
                              labelText={"Average sales price per unit (in ₹)"}
                              onChange={this.inputHandler}
                              name={`productionAndSalesNumbers.machineDetail.[${index}].averageSalePerUnit`}
                              inputValue={averageSalePerUnit}
                              required={true}
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>

              <div className="container m-top--main mt-2">
                <h2 className="sub-heading">Business details</h2>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Manufacturing Unit </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 mt-0">
                      <InputLabelAnimation
                        labelText={" Size of Manufacturing Unit (in sq. Yard)"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.sizeOfManufacturingUnit"}
                        inputValue={businessDetails?.sizeOfManufacturingUnit}
                      />
                    </div>
                  </div>
                </div>
                <div className="container m-top--main mt-2">
                  <h4 className="blue-label"> Labour </h4>
                  <div className="row mb-2">
                    <div className="col-sm-6 mt-0">
                      <InputLabelAnimation
                        labelText={"Number of Skilled workers"}
                        onChange={this.inputHandler}
                        required={true}
                        type="number"
                        name={"businessDetails.numberOfSkilledWorkers"}
                        inputValue={businessDetails?.numberOfSkilledWorkers}
                      />
                    </div>
                    <div className="col-sm-6 pl-1 mt-0">
                      <InputLabelAnimation
                        labelText={"Number of helpers "}
                        required={true}
                        type="number"
                        onChange={this.inputHandler}
                        name={"businessDetails.numberOfHelpers"}
                        inputValue={businessDetails?.numberOfHelpers}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box">
                <div
                  className="btn-draft "
                  onClick={() => this.addSewingMachineCost("draft")}
                >
                  Save for later
                </div>
                <div
                  className="btn-save"
                  onClick={() => this.addSewingMachineCost("save")}
                >
                  Save and Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    const { match } = this.props;
    this.setState({ isLoading: true });
    GET(API_GET_BUSINESS_COST(match.params.custId)).then(({ data }) => {
      if (data?.data?.sewingMachineConstAnalysis) {
        let { sewingMachineConstAnalysis } = data.data;
        this.setState({ sewingMachineConstAnalysis });
      }
      this.setState({ isLoading: false });
    });
  };
}
