import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_cloud_download } from "react-icons-kit/md/ic_cloud_download";
import { plus } from "react-icons-kit/fa/plus";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";
import { trashO } from "react-icons-kit/fa/trashO";
import pdf from "../../../assets/images/pdf.png";
import Excel from "../../../assets/images/excel.png";
import AddNewBankAccount from "../MainViews/addNewBankAccount";
import DeleteAccount from "../MainViews/deleteAccount";
import ShareExcelTemplate from "./shareExcelTemplate";
import { CredoModal } from "../../../components";
import { getCookie } from "../../../utils/utitlity";
import { POST, GET } from "../../../utils/webAPI.service";
import { CheckBox } from "../../../components";
import {
  API_SAVE_BANK_TRANSACTION_FROM_EXCEL,
  API_UPLOAD_BANKING,
  API_REFRESH_EXCEPTION,
  API_POST_FORCE_CLOSE_APPLICATION,
  API_CLOSE_APPLICATION,
  API_UPDATE_PRIMARY_ACCOUNT,
} from "../../../utils/APIUrls";
import { get } from "lodash";
export default class bankingExceptions extends Component {
  state = {
    modalOpen: false,
    modalType: "",
    addAccountModalOpen: false,
    bankAccountDetails: [{}],
  };
  toggleModal = (modalType, accountNumber, index) => {
    const modalState = !this.state.modalOpen;
    if (modalType === "deleteAccount") {
      if (accountNumber) {
        this.setState({
          modalOpen: !this.state.modalOpen,
          modalType: modalState ? modalType : "",
          accountNumber,
        });
      } else {
        let { bankingExceptionData } = this.props.userExceptionData;
        let updatebankingExceptionDataCopy = bankingExceptionData.splice(
          index,
          1
        );
        this.setState({
          bankingExceptionData: updatebankingExceptionDataCopy,
        });
      }
    } else {
      this.setState({
        modalOpen: !this.state.modalOpen,
        modalType: modalState ? modalType : "",
        accountNumber,
      });
    }
  };
  uploadFiles = (
    e,
    index,
    bankName,
    fileType,
    accountType,
    sanctionedLimit,
    ifscManual,
    pdfPassword
  ) => {
    let { bankAccountDetails } = this.state;
    let { match, actions } = this.props,
      { appNo } = match.params,
      { loaderModal, uploadBankingFiles, getExceptionData } = actions;
    var files = e.target.files;
    let accountTypeNew;
    let token = getCookie("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: token,
    };
    let formData = new FormData();
    loaderModal(true);
    if (fileType === "pdf") {
      for (let file in files) {
        if (files.hasOwnProperty(file)) {
          formData.append("files", files[file]);
        } else {
          break;
        }
      }
      if (accountType === "Savings Account") {
        accountTypeNew = "SAVING";
      } else if (accountType === "Current Account") {
        accountTypeNew = "CURRENT";
      } else {
        accountTypeNew = "CREDIT_CARD";
      }
      POST(
        API_UPLOAD_BANKING(
          appNo,
          bankName,
          accountTypeNew,
          sanctionedLimit || 0,
          ifscManual,
          bankAccountDetails?.[index]?.["isScannedPDF"],
          pdfPassword,
          bankAccountDetails?.[index]?.["isPrimaryBankAccount"]
        ),
        formData,
        { headers }
      )
        .then(({ data }) => {
          let { status, message } = data;
          loaderModal(false);
          window.alert(message);
          if (status === "success") {
            for (let file in files) {
              if (files.hasOwnProperty(file)) {
                uploadBankingFiles(files[file].name, fileType, index);
                // getExceptionData()
              } else {
                break;
              }
            }
          }
        })
        .catch((err) => {
          window.alert("Something went wrong please try again later.");
        });
    } else {
      for (let file in files) {
        if (files.hasOwnProperty(file)) {
          formData.append("file", files[file]);
        } else {
          break;
        }
      }
      POST(API_SAVE_BANK_TRANSACTION_FROM_EXCEL(appNo, bankName), formData, {
        headers,
      })
        .then(({ data }) => {
          let { status, message } = data;
          loaderModal(false);
          window.alert(message);
          if (status === "success") {
            for (let file in files) {
              if (files.hasOwnProperty(file)) {
                uploadBankingFiles(files[file].name, fileType, index);
                // getExceptionData()
              } else {
                break;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          loaderModal(false);
          window.alert("Something went wrong please try again later.");
        });
    }
  };

  updatePrimaryBankAccount = (accountNumber) => {
    let { match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    POST(API_UPDATE_PRIMARY_ACCOUNT(applicationNumber, accountNumber))
      .then(({ data, status }) => {
        if (data?.status === "success") {
          alert("Primary Bank Account Updated Successfully");
        } else {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  inputBankHandler = (index, key) => {
    let { bankAccountDetails } = this.state,
      bankAccountDetailsCopy = [...bankAccountDetails];
    bankAccountDetailsCopy[index] = {
      ...bankAccountDetailsCopy?.[index],
      [key]: !bankAccountDetailsCopy?.[index]?.[key],
    };
    this.setState({ bankAccountDetails: bankAccountDetailsCopy });
  };

  refreshBankingException = async () => {
    let { actions, match } = this.props,
      { loaderModal, getExceptionData } = actions,
      { appNo } = match.params;
    loaderModal(true);
    await GET(API_REFRESH_EXCEPTION(appNo))
      .then(({ data }) => {
        if (data.status === "success") {
        } else {
          window.alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
    await getExceptionData(appNo);
    loaderModal(false);
  };
  forceClose = async () => {
    let { actions, match } = this.props,
      { loaderModal, getOPSUserApplication } = actions,
      { appNo } = match.params,
      dataToSend = {
        data: {
          pageNumber: 1,
          statusFilter: "new",
        },
      };
    loaderModal(true);
    await POST(API_POST_FORCE_CLOSE_APPLICATION(appNo)).then(({ data }) => {
      getOPSUserApplication(dataToSend);
      if (data.status === "success") {
        window.close();
        getOPSUserApplication(dataToSend);
      } else {
        alert(window.alert);
      }
    });
    loaderModal(false);
  };
  closeCase = async () => {
    const { actions, match } = this.props,
      { loaderModal } = actions,
      { appNo } = match.params;
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    await POST(API_CLOSE_APPLICATION(appNo), { data: "" }, { headers })
      .then(({ data }) => {
        let { status, message } = data;
        if (status === "success") {
          window.close();
        } else {
          window.alert(message);
        }
      })
      .catch((err) => {
        throw err;
      });
    loaderModal(false);
  };
  render() {
    let { modalOpen, modalType, bankAccountDetails } = this.state,
      { bankingExceptionData } = this.props.userExceptionData;
    let { userExceptionData, match } = this.props;
    let { userType } = userExceptionData;
    return (
      <Fragment>
        {bankingExceptionData ? (
          <div className="card__wrapper mt-3">
            <div className="row">
              <div className="banking-exceptions heading">
                <div className="headings bold-heading">Banking Exception</div>
                <div className="options">
                  <div
                    onClick={() => this.toggleModal("addNewBankAccount")}
                    className="set-cursor-pointer"
                  >
                    <Icon className="icon-color mr-1" icon={plus} size={20} />
                    Add Account
                  </div>
                  <div className="set-cursor-pointer">
                    <a
                      download
                      href={`/Backend/downloadBanking/${this.props.match.params.appNo}`}
                    >
                      <Icon
                        icon={ic_cloud_download}
                        className="icon-color mr-1"
                        size={20}
                      />
                      Download All Statement
                    </a>
                  </div>
                  <div
                    className="set-cursor-pointer"
                    onClick={() => this.toggleModal("ShareExcelTemplate")}
                  >
                    <Icon
                      className="icon-color mr-1"
                      icon={ic_cloud_download}
                      size={20}
                    />
                    Excel Template
                  </div>
                  <div className="set-cursor-pointer">
                    <div onClick={this.refreshBankingException}>
                      <Icon
                        className="icon-color mr-1"
                        icon={ic_refresh}
                        size={20}
                      />
                      Refresh
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {bankingExceptionData &&
                  bankingExceptionData.map((el, index) => {
                    return (
                      <div className="col-md-6 col-sm-12 mb-3" key={index}>
                        <div className="card__wrapper  details mt-3 p-0">
                          <div className="inner-heading">
                            <div className="client-name-style ml-3">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  this.updatePrimaryBankAccount(
                                    el?.accountNumber
                                  )
                                }
                              >
                                Update primary bank account
                              </button>
                            </div>
                            <div
                              onClick={() =>
                                this.toggleModal(
                                  "deleteAccount",
                                  el.accountNumber,
                                  index
                                )
                              }
                              className="set-cursor-pointer"
                            >
                              <div className="delete-text mr-3">
                                <Icon
                                  className="delete-icon mr-1"
                                  icon={trashO}
                                  size={20}
                                />
                                Delete
                              </div>
                            </div>
                          </div>
                          <div className="inner-data__wrapper">
                            <div className="row">
                              <div className="col-md-6">
                                <label className="inner-data-labels">
                                  Account Number
                                </label>
                                <div className="inner-data-values">
                                  {el.accountNumber}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label className="inner-data-labels">
                                  Bank Name
                                </label>
                                <div className="inner-data-values">
                                  {el.bankName}{" "}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mt-3">
                                <label className="inner-data-labels">
                                  Months available
                                </label>
                                <div className="inner-data-values">
                                  {el.monthAvailable &&
                                    el.monthAvailable.join()}
                                </div>
                              </div>
                              <div className="col-md-6 mt-3">
                                <label className="inner-data-labels">
                                  Exception
                                </label>
                                <div className="inner-data-values">
                                  {el.reason}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mt-3">
                                <div className="add-pdf__wrapper">
                                  <div className="add-pdf">
                                    <div className="add-pdf-text">
                                      <img
                                        className="mr-1"
                                        src={pdf}
                                        alt={"PDF"}
                                      />
                                      Add PDF
                                    </div>
                                    <button className="btn btn-danger">
                                      + Add
                                      <input
                                        type="file"
                                        multiple
                                        onChange={(e) =>
                                          this.uploadFiles(
                                            e,
                                            index,
                                            el.bankCode,
                                            "pdf",
                                            el.accountType,
                                            el.sanctionLimit,
                                            el.ifscManual,
                                            el.pdfPassword
                                          )
                                        }
                                      />
                                    </button>
                                  </div>
                                  {el.pdf?.length !== 0 ? (
                                    <div id="uploadedPdfList">
                                      <ul>
                                        {el.pdf?.map((fileName) => {
                                          return (
                                            <li className="uploadedFileList">
                                              <div className="ml-2">
                                                <img
                                                  className="ml-2 mr-2"
                                                  src={pdf}
                                                  alt={"pdfSign"}
                                                />
                                                {fileName &&
                                                  fileName.slice(0, 15)}
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6 mt-3">
                                <div className="add-pdf__wrapper">
                                  <div className="add-pdf">
                                    <div className="add-pdf-text">
                                      <img
                                        className="mr-1"
                                        src={Excel}
                                        alt={"Excel"}
                                      />
                                      Add Excel
                                    </div>
                                    <button className="btn btn-danger">
                                      + Add
                                      <input
                                        type="file"
                                        multiple
                                        onChange={(e) =>
                                          this.uploadFiles(
                                            e,
                                            index,
                                            el.bankCode,
                                            "excel"
                                          )
                                        }
                                      />
                                    </button>
                                  </div>
                                  {el.excel?.length !== 0 ? (
                                    <div id="uploadedPdfList">
                                      <ul>
                                        {el.excel?.map((fileName) => {
                                          return (
                                            <li className="uploadedFileList">
                                              <div className="ml-2">
                                                <img
                                                  className="ml-2 mr-2"
                                                  src={Excel}
                                                  alt={"Excel Sign"}
                                                />
                                                {fileName &&
                                                  fileName.slice(0, 15)}
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className=" row  ">
                              <div className={" col-md-6 mt-5"}>
                                <ul>
                                  <CheckBox
                                    labelColor={"#000000"}
                                    checkboxColor={"#000000"}
                                    htmlFor={"scan pdf"}
                                    label={"Is this pdf file scanned ?"}
                                    checked={
                                      bankAccountDetails?.[index]?.isScannedPDF
                                    }
                                    onChange={() =>
                                      this.inputBankHandler(
                                        index,
                                        "isScannedPDF"
                                      )
                                    }
                                  />
                                </ul>
                              </div>
                              <div className={" col-md-6 mt-5"}>
                                <ul>
                                  <CheckBox
                                    labelColor={"#000000"}
                                    checkboxColor={"#000000"}
                                    htmlFor={"PrimaryBankAccount"}
                                    label={"Is primary bank account ?"}
                                    checked={
                                      bankAccountDetails?.[index]
                                        ?.isPrimaryBankAccount
                                    }
                                    onChange={() =>
                                      this.inputBankHandler(
                                        index,
                                        "isPrimaryBankAccount"
                                      )
                                    }
                                  />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        ) : null}
        <div className="close-btns">
          <button
            className="button_Generic red-btn mr-3 bolder-text"
            onClick={this.forceClose}
          >
            Force Close
          </button>
          <button
            className="button_Generic blue-btn ml-3 bolder-text"
            onClick={this.closeCase}
          >
            Close Case
          </button>
        </div>
        <CredoModal
          isOpen={modalOpen}
          accountNumber={this.state.accountNumber}
          {...this.props}
          styles={{
            content: {
              width: modalType === "deleteAccount" ? "400px" : "600px",
              height: modalType === "ShareExcelTemplate" ? "40rem" : "",
            },
          }}
          title={
            modalType === "deleteAccount"
              ? "Delete"
              : modalType === "ShareExcelTemplate"
              ? "Share Excel Template"
              : "Add New Account"
          }
          closeAction={() => this.toggleModal("")}
          RenderingComponent={
            modalType === "deleteAccount"
              ? DeleteAccount
              : modalType === "ShareExcelTemplate"
              ? ShareExcelTemplate
              : AddNewBankAccount
          }
        />
      </Fragment>
    );
  }
}
