import * as DocumentsConstants from "./Document.actions.constant";
const initialState = {
  documentList: [],
  documentName: [],
  documentRelation:[],
  documentException:[]
};
export const cpoDocumentsReducers = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case DocumentsConstants.SET_DOCUMENT_DATA:
      return { ...state, documentList: data };
    case DocumentsConstants.RESET_REDUX_STATE:
      return { ...state, documentList: [] };
    case DocumentsConstants.FETCH_DOCUMENT_LIST:
      return { ...state, documentName: data };
    case DocumentsConstants.CO_APPLICANT_RELATION:
      return {...state,documentRelation : data};
      case DocumentsConstants.DOCUMENT_EXCEPTION_LIST:
        return {...state, documentException : data};
    default:
      return state;
  }
};
