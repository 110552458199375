import * as GSTDetailsConstants from "./GSTNDetails.actions.constants";
const {
  API_GET_IS_PAN_FLOW,
  API_GET_GST_DATA,
  API_USER_OTP_VERIFY,
  API_OTP_VERIFY,
} = require("../../../utils/APIUrls");
const { GET } = require("../../../utils/webAPI.service");

const getPanData = (applicationNumber) => (dispatch) => {
  return GET(API_GET_IS_PAN_FLOW(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GSTDetailsConstants.GET_PAN_DATA, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const getGSTData = (applicationNumber) => (dispatch) => {
  return GET(API_GET_GST_DATA(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GSTDetailsConstants.GET_GST_DATA, data: data.data });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};
const resetGstnDetailsState = () => ({
  type: GSTDetailsConstants.RESET_REDUX_STATE,
});

const getVerifyOTP = (tokenId) => {
  return () => {
    if (tokenId) {
      return GET(API_USER_OTP_VERIFY(tokenId))
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          throw err;
        });
    }
  };
};

const sendOTPVerify = (uuid, otp) => {
  return () => {
    if (uuid || otp) {
      return GET(API_OTP_VERIFY(uuid, otp));
    }
  };
};

export { getPanData, getGSTData, resetGstnDetailsState, sendOTPVerify, getVerifyOTP };
