import {get, set} from "lodash";

const _getInputValue = (state, name) => {
    return get(state, `form.${name}`);
};

const _updateFormValues = (state, name, value) => {
    const _preValue = _getInputValue(state, name);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state));
        let _changedValue = get(set(_changedState, `form.${name}`, value), 'form');
        return {..._changedState, form: {..._changedValue}};
    }
    return state;
};

const _getModalInputValue = (state, name) => {
    return get(state, `formModal.${name}`);
};

const _updateModalFormValues = (state, name, value) => {
    const _preValue = _getModalInputValue(state, name);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state));
        let _changedValue = get(set(_changedState, `formModal.${name}`, value), 'formModal');
        return {..._changedState, formModal: {..._changedValue}};
    }
    return state;
};

const _selectAccountEMI = (state, index) => {
    let _changedState = JSON.parse(JSON.stringify(state));
    let {activeAccount} = _changedState;
    let value = get(_changedState, `emiDetails[${activeAccount}].emiDetails[${index}].selected`);
    let _changedValue = get(set(_changedState, `emiDetails[${activeAccount}].emiDetails[${index}].selected`, !value), 'emiDetails');
    return {..._changedState, emiDetails: [..._changedValue]}
};

const _selectObligationData = (state, index, name) => {
    let _changedState = JSON.parse(JSON.stringify(state));
    let value = get(_changedState, `filteredObligationsData[${index}].${name}`);
    let _filteredDataChanged = get(set(_changedState, `filteredObligationsData[${index}].${name}`, !value), 'filteredObligationsData');
    let _mainDataChanged = get(set(_changedState, `mainObligationsData[${index}].${name}`, !value), 'mainObligationsData');
    return {..._changedState, filteredObligationsData: [..._filteredDataChanged], mainObligationsData: _mainDataChanged}

};

const _setFilteredObligations = (state, filters) => {
    let _changedState = JSON.parse(JSON.stringify(state));
    let {mainObligationsData} = _changedState;
    let filtersList = [];
    for (let item in filters) {
        if (filters.hasOwnProperty(item)) {
            if (filters[item] && item === '') {
                filtersList.push('closed');
            } else if (filters[item] && item === 'closedCases') {

                filtersList.push('closed');
            } else if (filters[item] && item === 'creditCards') {

                filtersList.push('creditCards');
            } else if (filters[item] && item === 'duplicateCases') {

                filtersList.push('duplicateCases');
            }
        }
    }
    if (filtersList.length) {
        let data = mainObligationsData.filter(item => {
            return !filtersList.includes(item?.status?.toLowerCase())
        });
        if (filtersList.includes('creditCards')) {
            data = data.filter(item => {
                return item.type !== "Credit Card"
            })
        }
        if (filtersList.includes('duplicateCases')) {
            data = data.filter(item => {
                return !item.isDuplicate
            })
        }
        return {..._changedState, filteredObligationsData: data}
    } else {
        return {..._changedState, filteredObligationsData: mainObligationsData}
    }
};

const _setCreditCapacityDetails = (state, data) => {
    let _changedState = JSON.parse(JSON.stringify(state));
    return {
        ..._changedState,
        form: {
            ..._changedState.form,
            sales: data.salesConsideredForEligibility,
            salesRemark: data.salesRemark,
            margin: data.marginConsideredForEligibility,
            marginRemark: data.marginRemark,
        }
    };
};

export {
    _updateFormValues,
    _updateModalFormValues,
    _selectAccountEMI,
    _selectObligationData,
    _setFilteredObligations,
    _setCreditCapacityDetails
}
