import React, { PureComponent } from "react";

export default class SectionD extends PureComponent {
  render() {
    return (
      <div className="box-wrapper">
        <div className="box-heading">Other Supporting Documents</div>
        <hr />
        <div style={{ padding: "0px 15px" }}>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 1:</span> All business
              verifications and PD reports
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 2:</span> Primary applicant resi
              verification report
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 3:</span> Co applicants
              verification report
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 4:</span> CIBIL of all applicants
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 5:</span> Commercial Cibil which
              was uploaded manually
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row">
            <div>
              <span className="bold">Annexure 6:</span> Documents uploaded on
              system
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
          <div className="annexure-row" style={{borderBottom:"0px"}}>
            <div>
              <span className="bold">Annexure 7:</span> Bank Statements and PD
              reports
            </div>
            <input type="checkbox" className="checkBox" defaultChecked />
          </div>
        </div>
      </div>
    );
  }
}
