import { mapValues, keyBy } from "lodash";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
import React from "react";
const capitalize = (text) => {
  if (text) {
    return text[0].toUpperCase() + text.slice(1);
  }
  return ""
};
export const aggregateData = (data, type) => {
  let data1 = [],
    dataCredits = [],
    dataDebits = [],
    totalDebits = 0,
    totalCredits = 0,
    avgCredits = 0,
    avgDebits = 0,
    totalCreditTxnCount = 0,
    totalDebitTxnCount = 0,
    avgCreditTxnCount = 0,
    avgDebitTxnCount = 0;
  let checkedTransactions = Object.keys(type).filter((el) => {
    return type[el];
  });
  let checkedTransactionsArraysCredit = checkedTransactions.map((checkedTransaction) => {
    return data[`${checkedTransaction}Credit`];
  });
  let checkedTransactionsArraysDebit = checkedTransactions.map((checkedTransaction) => {
    return data[`${checkedTransaction}Debit`];
  });
  checkedTransactionsArraysCredit.forEach((checkedTransactionsArray) => {
    checkedTransactionsArray &&
      checkedTransactionsArray.forEach((el, index) => {
        let elCopy = { ...el };
        if (dataCredits[index]) {
          dataCredits[index].txnCount = parseInt(dataCredits[index].txnCount) + parseInt(elCopy.txnCount);
          dataCredits[index].value = parseInt(dataCredits[index].value) + parseInt(elCopy.value);
        } else {
          dataCredits.push(elCopy);
        }
      });
  });
  checkedTransactionsArraysDebit.forEach((checkedTransactionsArray) => {
    checkedTransactionsArray &&
      checkedTransactionsArray.forEach((el, index) => {
        let elCopy = { ...el };
        if (dataDebits[index]) {
          dataDebits[index].txnCount = parseInt(dataDebits[index].txnCount) + parseInt(elCopy.txnCount);
          dataDebits[index].value = parseInt(dataDebits[index].value) + parseInt(elCopy.value);
        } else {
          dataDebits.push(elCopy);
        }
      });
  });
  checkedTransactions.forEach((checkedTransaction) => {
    totalCredits = totalCredits + parseInt(data[`total${capitalize(checkedTransaction)}Credit`]);
    totalDebits = totalDebits + parseInt(data[`total${capitalize(checkedTransaction)}Debit`]);
    avgCredits = avgCredits + parseInt(data[`avg${capitalize(checkedTransaction)}Credit`]);
    avgDebits = avgDebits + parseInt(data[`avg${capitalize(checkedTransaction)}Debit`]);
    totalCreditTxnCount = totalCreditTxnCount + parseInt(data[`total${capitalize(checkedTransaction)}CreditTxnCount`]);
    totalDebitTxnCount = totalDebitTxnCount + parseInt(data[`total${capitalize(checkedTransaction)}DebitTxnCount`]);
    avgCreditTxnCount = avgCreditTxnCount + parseInt(data[`avg${capitalize(checkedTransaction)}CreditTxnCount`]);
    avgDebitTxnCount = avgDebitTxnCount + parseInt(data[`avg${capitalize(checkedTransaction)}DebitTxnCount`]);
  });
  if (data && data.isLimitSanctionedPresent && data.limitUtilizationAccountwise) {
    data1 = data.limitUtilizationAccountwise
      .filter((el) => {
        if (el.limitUtilizationInPercentage) {
          return el.limitUtilizationInPercentage.length !== 0;
        }
        return null;
      })
      .map((el, key) => {
        return {
          identifier: `Limit Utilization ${el.accountNumber}`,
          ...mapValues(keyBy(el.limitUtilizationInPercentage, "month"), (commaFormatter) =>
            commaFormatter.value && commaFormatter.value !== 0 && commaFormatter.value !== "0"
              ? commaFormatter.value.toFixed(2) + "%"
              : "0%"
          ),
          total: "-",
          average:
            el.averageLimitUtilizationInPercentage &&
            el.averageLimitUtilizationInPercentage !== 0 &&
            el.averageLimitUtilizationInPercentage !== "0" &&
            el.averageLimitUtilizationInPercentage !== "Infinity"
              ? el.averageLimitUtilizationInPercentage.toFixed(2) + "%"
              : "0%",
        };
      });
  }
  return [
    {
      identifier: "Total Credits",
      subIdentifier: "Transactions Count",
      ...mapValues(
        keyBy(dataCredits, "month"),
        (commaFormatter) =>
          rupeeCommaFormatter(commaFormatter.value) +
          " - " +
          Math.round(commaFormatter.txnCount ? commaFormatter.txnCount : 0)
      ),
      total: rupeeCommaFormatter(totalCredits) + "-" + Math.round(totalCreditTxnCount ? totalCreditTxnCount : 0),
      average: rupeeCommaFormatter(avgCredits) + "-" + Math.round(avgCreditTxnCount ? avgCreditTxnCount : 0),
    },
    {
      identifier: "Total Debits",
      subIdentifier: "Transactions Count",
      ...mapValues(
        keyBy(dataDebits, "month"),
        (commaFormatter) =>
          rupeeCommaFormatter(commaFormatter.value) +
          "-" +
          Math.round(commaFormatter.txnCount ? commaFormatter.txnCount : 0)
      ),
      total: rupeeCommaFormatter(totalDebits) + "-" + Math.round(totalDebitTxnCount ? totalDebitTxnCount : 0),
      average: rupeeCommaFormatter(avgDebits) + "-" + Math.round(avgDebitTxnCount ? avgDebitTxnCount : 0),
    },
    {
      identifier: "Merged ABB (With limit)",
      ...mapValues(keyBy(data.monthlyABB, "month"), (commaFormatter) => rupeeCommaFormatter(commaFormatter.value)),
      total: "-",
      average: rupeeCommaFormatter(data.averageABB),
    },
    {
      identifier: "Merged ABB (Without limit)",
      ...mapValues(keyBy(data.withoutLimitMonthlyABB, "month"), (commaFormatter) => rupeeCommaFormatter(commaFormatter.value)),
      total: "-",
      average: rupeeCommaFormatter(data.withoutLimitAverageABB),
    },

  ];
};
export const monthsColumn = (data) => {
  let columns = [],
    list = [];

  if (data) {
    if (typeof data !== "undefined" && data.length > 0) {
      data.map((item) => {
        if (typeof item.month !== "undefined" && item.month != null && item.month.length > 0)
          if (!list.includes(item.month)) {
            list.push(item.month);
            columns.push({
              Header: item.month,
              accessor: item.month,
              Cell: (props) => {
                return (
                  <div>
                    <div>{props && props.value ? props.value.split("-")[0] : "NA"}</div>
                    <div className="mt-1">{props && props.value && props.value.split("-")[1]}</div>
                  </div>
                );
              },
            });
          }
          return null
      });
    }
  }
  return columns;
};
