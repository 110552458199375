import React, { Component } from "react";
import SearchIcon from "../../../src/assets/images/SearchIcon.svg";
import { getCookie } from "../../utils/utitlity";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export class SearchAdminFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter: "new",
      pageNumber: 1,
      totalPages: 1,
    };
  }
  searchSelectHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onSearchHandler = () => {
    let { searchBy, searchValue } = this.state,
      { currentTab, actions } = this.props;
    let { getCPOAdminData } = actions;
    if (!searchBy) {
      alert("Please select search type");
      return;
    } else if (!searchValue) {
      alert("Please enter search value");
      return;
    }
    let dataToSend = {
      pageNumber: 1,
      dashboard: "CPO_ADMIN",
      searchValue: searchValue?.trim(),
      status: currentTab,
      searchType: searchBy,
    };
    this.props.actions.setAdminPageNumber(1);
    this.props.actions.setAdminSearchCriteria(searchBy, searchValue);
    getCPOAdminData(dataToSend);
  };
  render() {
    return (
      <>
        <div className="search-wrapper">
          <div className="search-border">
            <div className="search-form">
              <select
                className="select text-secondary"
                name="searchBy"
                required
                onChange={this.searchSelectHandler}
              >
                <option disabled selected>
                  Search By
                </option>
                <option value="Application Number">Case Id</option>
                <option value="Customer Id">User Id</option>
                <option value="Entity Name">Entity Name</option>
              </select>
              <input
                required={true}
                placeholder="Search"
                name="searchValue"
                className="search"
                onChange={this.searchSelectHandler}
              />
              <button
                type="submit"
                className="submit"
                onClick={this.onSearchHandler}
              >
                <img src={SearchIcon} alt="icon" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ data, cpoAdminReducers }) => ({
  userData: cpoAdminReducers.userData,
  loaderModal: data.loaderModal,
  searchBy: cpoAdminReducers.searchBy,
  searchValue: cpoAdminReducers.searchValue,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchAdminFilter);
