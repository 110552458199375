import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changeInputValue, addRow, deleteRow } from "./references.actions";
import { InputLabelAnimation } from "../../../components";
import { get } from "lodash";
import { GET } from "../../../utils/webAPI.service";
import Select from "react-select";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import { loaderModal, updateErrorMessage } from "../../../store/actions";

class ReferencesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinCodes: [],
      pinCodeLoader: false,
    };
    this.searchPinCode = null;
  }

  setPinCode = (e) => {
    let { actions, index } = this.props,
      { changeInputValue } = actions;
    changeInputValue(e.value, "address.pinCode", index);
    changeInputValue(e.district, "address.district", index);
    changeInputValue(e.city, "address.city", index);
    changeInputValue(e.state, "address.state", index);
  };

  setInputPinCode = (e) => {
    clearTimeout(this.searchPinCode);
    this.searchPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            pinCodeLoader: true,
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  pinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    pinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      district: list[i].district,
                      state: list[i].state,
                    });
                  }
                }
                this.setState({
                  pinCodes,
                  pinCodeLoader: false,
                });
              } else {
                this.setState({ pinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };

  deleteRow = async () => {
    let { actions, index, referencesList } = this.props,
      { deleteRow, loaderModal } = actions,
      id = get(referencesList, `[${index}].id`, "");
    if (id) {
      loaderModal(true);
      await deleteRow(index, id).catch((err) => {
        window.alert("Something went wrong please try again later.");
      });
      loaderModal(false);
    } else {
      deleteRow(index);
    }
  };

  inputHandler = (e) => {
    e.preventDefault();
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      value = e.target.value,
      name = e.target.name;
    changeInputValue(value, name, index);
    updateErrorMessage("");
  };

  inputNumberHandler = (e) => {
    e.preventDefault();
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      value = e.target.value,
      name = e.target.name,
      reg = /^[0-9]*$/;
    if (reg.test(value)) {
      changeInputValue(value, name, index);
    }
    updateErrorMessage("");
  };

  selectHandler = (e, name) => {
    let { actions, index } = this.props,
      { changeInputValue, updateErrorMessage } = actions,
      value = "";
    switch (name) {
      case "relation":
        value = e.target.value;
        break;
      default:
        value = "";
    }
    changeInputValue(value, name, index);
    updateErrorMessage("");
  };

  componentWillMount() {
    const { referencesList, index } = this.props,
      pinCode = get(referencesList, `[${index}].address.pinCode`);
    pinCode && this.setInputPinCode(pinCode);
  }

  render() {
    const { referencesList, index, referenceRelations, actions } = this.props,
      { addRow } = actions,
      { pinCodeLoader, pinCodes } = this.state,
      pinCode = get(referencesList, `[${index}].address.pinCode`);

    return (
      <form className={"form-box"}>
        <div className={"box-header"}>
          <h4>Reference {index + 1}</h4>
          {index > 1 ? (
            <span onClick={this.deleteRow} className={"remove-icon"}>
              <Icon icon={ic_delete_forever} size={26} />
            </span>
          ) : null}
        </div>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <InputLabelAnimation
              labelText={"Reference Name"}
              name={"name"}
              required={true}
              onChange={this.inputHandler}
              tabIndex={index * 8 + 1}
              inputValue={get(referencesList, `[${index}].name`, "")}
            />
          </div>
          <div className={"col-md-6"}>
            <InputLabelAnimation
              labelText={"Mobile Number"}
              name={"mobile"}
              required={true}
              onChange={this.inputNumberHandler}
              tabIndex={index * 8 + 2}
              maxLength={10}
              inputValue={get(referencesList, `[${index}].mobile`, "")}
            />
          </div>
          <div className={"col-md-6"}>
            <InputLabelAnimation
              labelText={"Email"}
              name={"email"}
              required={true}
              type={"email"}
              onChange={this.inputHandler}
              tabIndex={index * 8 + 3}
              inputValue={get(referencesList, `[${index}].email`, "")}
            />
          </div>
          <div className={"col-md-6"}>
            <select
              className={"select-box1"}
              id={"relation-select"}
              value={get(referencesList, `[${index}].relation`, "Select Relationship")}
              onChange={(e) => this.selectHandler(e, "relation")}
            >
              {referenceRelations.map((x, y) => (
                <option key={y + 1}>{x}</option>
              ))}
            </select>
          </div>
        </div>
        <div className={"address-box"}>
          <label className={"input-label"}>Address</label>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <InputLabelAnimation
                labelText={"Enter Floor/Building Number"}
                tabIndex={index * 8 + 5}
                required={true}
                name={"address.line1"}
                onChange={this.inputHandler}
                inputValue={get(referencesList, `[${index}].address.line1`) || ""}
              />
            </div>
            <div className={"col-md-6"}>
              <InputLabelAnimation
                labelText={"Enter Street/Landmark"}
                name={"address.line2"}
                onChange={this.inputHandler}
                tabIndex={index * 8 + 6}
                required={true}
                inputValue={get(referencesList, `[${index}].address.line2`) || ""}
              />
            </div>
            <div className={"col-md-3"}>
              <Select
                tabIndex={index * 8 + 8}
                value={pinCodes.find((option) => option.value === pinCode)}
                title={"Pin Code"}
                placeholder={"Pin Code"}
                isSearchable={true}
                name="pin_code"
                options={pinCodes}
                className="pin-code-select-search"
                classNamePrefix="select"
                onInputChange={this.setInputPinCode}
                onChange={this.setPinCode}
                isLoading={pinCodeLoader}
              />
            </div>
            <div className={"col-md-3"}>
              <InputLabelAnimation
                labelText={"District"}
                name={"address.district"}
                inputValue={get(referencesList, `[${index}].address.district`, "")}
                disabled={true}
                required={true}
              />
            </div>
            <div className={"col-md-3"}>
              <InputLabelAnimation
                labelText={"City"}
                name={"address.city"}
                inputValue={get(referencesList, `[${index}].address.city`) || ""}
                disabled={true}
                required={true}
              />
            </div>
            <div className={"col-md-3"}>
              <InputLabelAnimation
                labelText={"State"}
                name={"address.state"}
                inputValue={get(referencesList, `[${index}].address.state`) || ""}
                disabled={true}
                required={true}
              />
            </div>
          </div>
        </div>
        {index === referencesList.length - 1 ? (
          <div className={"text-right"}>
            <button type={"button"} onClick={addRow} className={"add-btn"}>
              <span>
                <Icon icon={ic_add} size={22} />
              </span>
              <span>Add More Reference</span>
            </button>
          </div>
        ) : null}
      </form>
    );
  }
}

const mapStateToProps = ({ referencesReducers, listData }) => ({
  referencesList: referencesReducers.referencesList,
  referenceRelations: listData.referenceRelations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeInputValue,
      addRow,
      deleteRow,
      loaderModal,
      updateErrorMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferencesForm);
