import React from "react";
import { rupeeCommaFormatter } from "../../../../utils/utitlity";
import { get } from "lodash";
import OkaySign from "../../../../assets/images/okaySign.svg";
import CrossSign from "../../../../assets/images/crossIcon.svg";
const approvalDetails = [
  {
    Header: "Role",
    accessor: ""
  },
  {
    Header: "Name",
    accessor: ""
  },
  {
    Header: "Employee Code",
    accessor: ""
  },
  {
    Header: "Email ID",
    accessor: ""
  }
];
const obligationsColumns = [
  {
    Cell: ({ original, index }) => {
      return <label>{index + 1}</label>;
    },
    Header: "S.No.",
    accessor: ""
  },
  {
    Header: "A/c Holder's Name",
    accessor: "accountHolderName",
    Cell: ({ original }) => {
      let { accountHolderName, ownershipType } = original;
      return (
        <span title={accountHolderName}>
          {accountHolderName}
          <br />
          <span title={ownershipType}>{ownershipType}</span>
        </span>
      );
    }
  },
  {
    Header: "Type",
    accessor: "type"
  },
  {
    Header: "Lender",
    accessor: "lender"
  },
  {
    Header: "Loan Amount",
    accessor: "loanAmount",
    Cell: ({ original }) => {
      let { loanAmount } = original;
      return <span title={loanAmount}>{rupeeCommaFormatter(loanAmount)}</span>;
    }
  },
  {
    Header: "Current Balance",
    accessor: "currentBalance",
    Cell: ({ original }) => {
      let { currentBalance } = original;
      return <span title={currentBalance}>{rupeeCommaFormatter(currentBalance)}</span>;
    },
    width: 90
  },
  {
    Header: "EMI Amount",
    accessor: "emiAmount",
    Cell: ({ original }) => {
      let { emiAmount } = original;
      return <span title={emiAmount}>{rupeeCommaFormatter(emiAmount)}</span>;
    }
  },
  {
    Header: "Opening Date",
    accessor: "openingDate"
  },
  {
    Header: "Closing Date",
    accessor: "closingDate"
  },
  {
    Header: "Reporting Date",
    accessor: "reportingDate"
  },
  {
    Header: "Month On Book",
    accessor: "monthOnBook"
  },
  {
    Header: "Comment",
    accessor: "comment"
  },
  {
    Header: "Remarks",
    accessor: "remarks"
  },
  {
    Header: "EMI Considered",
    accessor: "",
    Cell: ({ original }) => {
      let obligatedEMI = get(original, "obligatedEMI", "");
      return <span title={obligatedEMI}>{rupeeCommaFormatter(obligatedEMI)}</span>;
    }
  },
  {
    Header: "Bank Details",
    accessor: "",
    Cell: ({ original }) => {
      let accountHolderName = get(original, "accountHolderName", "");
      let accountNumber = get(original, "accountNumber", "");
      return accountHolderName && accountNumber ? (
        <span>
          {accountHolderName} - {accountNumber}
        </span>
      ) : (
        "NA"
      );
    }
  },
  {
    Header: "Duplicate",
    accessor: "",
    Cell: ({ original }) => {
      let isDuplicate = get(original, "isDuplicate", "");
      return isDuplicate ? <span>True</span> : <span>False</span>;
    }
  },
  {
    Header: "Closing in 6 Months",
    accessor: "isClosingInSixMonths",
    Cell: props => {
      if (props.value) {
        return <img src={OkaySign} alt={"true"} className="closingSign" />;
      } else {
        return <img src={CrossSign} alt="" className="closingSign" />;
      }
    },
    sortable: false,
    width: 80
  },
  {
    Header: "EMI Obligated",
    accessor: "isEmiObligated",
    Cell: props => {
      if (props.value) {
        return <img src={OkaySign} alt={"true"} className="closingSign" />;
      } else {
        return <img src={CrossSign} alt="" className="closingSign" />;
      }
    },
    sortable: false,
    width: 80
  }
];
const decisionData = [
  {
    Header: "Decision Code",
    accessor: "code"
  },
  {
    Header: "Decision Comment",
    accessor: "description"
  },
  {
    Header: "Created By",
    accessor: "createdBy"
  },
  {
    Header: "Created Date",
    accessor: "date"
  }
];
const cibilColumns = [
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Case Role",
    accessor: "caseRole",
    width: 125
  },
  {
    Header: "Age",
    accessor: "age",
    width: 75
  },
  {
    Header: "CIBIL Score",
    accessor: "cibilScore",
    width: 100,
    Cell: ({ original }) => {
      let { cibilScore } = original;
      let style = { fontFamily: "Helvetica Bold" };
      return (
        <span
          style={{
            color: cibilScore > 600 ? "#8bd591" : "#ed5a5a",
            ...style
          }}
        >
          {cibilScore}
        </span>
      );
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Total Loans</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "liveLoans",
    width: 100,
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 5) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[5].live}</span>
            <span>{original.loanCount[5].closed}</span>
          </div>
        );
      } else {
        return null;
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Business Loans</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "unSecuredLoans",
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 0) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[0].live}</span>
            <span>{original.loanCount[0].closed}</span>
          </div>
        );
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Property Loans</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "securedLoans",
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 1) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[1].live}</span>
            <span>{original.loanCount[1].closed}</span>
          </div>
        );
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Vehicle Loans</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "individualLoans",
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 4) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[4].live}</span>
            <span>{original.loanCount[4].closed}</span>
          </div>
        );
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Other</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "businessLoans",
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 2) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[2].live}</span>
            <span>{original.loanCount[2].closed}</span>
          </div>
        );
      }
    }
  },
  {
    Header: () => (
      <div>
        <div className="heading">Non-funded Facilities</div>
        <div className="subheading">
          <span>Live</span>
          <span>Closed</span>
        </div>
      </div>
    ),
    accessor: "businessLoans",
    Cell: ({ original }) => {
      if (original.loanCount && original.loanCount.length > 3) {
        return (
          <div className="subheading">
            <span className="liveLoans">{original.loanCount[3].live}</span>
            <span>{original.loanCount[3].closed}</span>
          </div>
        );
      }
    }
  }
];
const riskBasedData = [
  {
    Header: "RBA Code & Description",
    Cell: ({ original }) => {
      return <div>{original.category + " - " + original.reason}</div>;
    }
  },
  {
    Header: "Mitigant",
    accessor: "mitigation"
  },
  {
    Header: "Level",
    accessor: "",
    Cell: ({ original }) => {
      let { level1, level2, level3 } = original;
      var arr = [];
      if (level1 === true) {
        arr.push("L1");
      }
      if (level2 === true) {
        arr.push("L2");
      }
      if (level3 === true) {
        arr.push("L3");
      }
      return (
        <div>
          <span style={{ color: "green" }}>{arr.join(",")}</span>
        </div>
      );
    }
  },
  {
    Header: "Date & Time",
    accessor: "createdDate"
  }
];
const consolidateData = [
  {
    Header: "S no.",
    accessor: ""
  },
  {
    Header: "Loan Details",
    accessor: ""
  },
  {
    Header: "Loan Amount (in ₹)",
    accessor: ""
  },
  {
    Header: "Current Balance (in ₹)",
    accessor: ""
  },
  {
    Header: "EMI Amount (in ₹)",
    accessor: ""
  },
  {
    Header: "Dates",
    accessor: ""
  },
  {
    Header: "Imputed EMI (in ₹)",
    accessor: ""
  }
];
export { approvalDetails, decisionData, riskBasedData, consolidateData, obligationsColumns, cibilColumns };
