import React, { Component } from "react";
import EntityForm from "./EntityForm";
import { ProgressBar } from "../../../components";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { cross } from "react-icons-kit/metrize/cross";
import { connect } from "react-redux";
import {
  getShareholdingDetails,
  getEntityName,
  getConstitutionRelations,
  setInitialResponse,
} from "./applicantGuide.actions";
import { loaderModal, updateErrorMessage } from "../../../store/actions";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { POST } from "../../../utils/webAPI.service";
import { API_ADD_SHAREHOLDING_DETAILS } from "../../../utils/APIUrls";
import ContentLoader from "react-content-loader";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { Helmet } from "react-helmet";

class ApplicantGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relationIndex: 1,
      types: [],
      typeIndex: 1,
      modalVisible: false,
    };
  }

  toggleModal = (status) => {
    this.setState({ modalVisible: status });
  };

  selectRelation = (i) => {
    let { relationIndex } = this.state;
    if (relationIndex !== i) {
      this.setState({ relationIndex: i });
    }
  };

  submitForm = () => {
    let { shareholdingDetails, history, actions, match } = this.props,
      { loaderModal, updateErrorMessage } = actions,
      { shareholdingList } = shareholdingDetails,
      applicationNumber = get(match, "params.appNo", ""),
      formBox = document.querySelector(".applicant-guide-wrapper .form-wrapper .rows-wrapper").children,
      totalShareholdings = 0;
    for (let i = 0; i < formBox.length; i++) {
      if (!get(shareholdingList, `[${i}].firstName`, "")) {
        formBox[i].querySelector('input[name="firstName"]').focus();
        updateErrorMessage("Please Enter First Name");
        return false;
      }
      if (!get(shareholdingList, `[${i}].lastName`, "")) {
        formBox[i].querySelector('input[name="lastName"]').focus();
        updateErrorMessage("Please Enter Last Name");
        return false;
      }
      if (get(shareholdingList, `[${i}].relation`, "Select Relation") === "Select Relation") {
        formBox[i].querySelector('button[name="relation"]').scrollIntoView();
        formBox[i].querySelector('button[name="relation"]').click();
        updateErrorMessage("Please Select Relation");
        return false;
      }
      if (!get(shareholdingList, `[${i}].shareholding`, "")) {
        formBox[i].querySelector('input[name="shareholding"]').focus();
        updateErrorMessage("Please Enter Shareholding Percentage ");
        return false;
      }
      let shareholding = get(shareholdingList, `[${i}].shareholding`);
      totalShareholdings = totalShareholdings + Number(shareholding);
    }
    if (totalShareholdings <= 100 && totalShareholdings >= 51) {
      let list = shareholdingList.map((item, index) => ({
        ...item,
        shareholding: Number(item.shareholding),
        id: get(shareholdingList, `[${index}].id`, ""),
        profileType: index ? "CO-APPLICANT" : "APPLICANT",
        middleName: get(shareholdingList, `[${index}].middleName`, ""),
      }));
      let data = {
        applicationNumber,
        shareholdingList: list,
      };
      const headers = { "Content-Type": "application/json" };
      loaderModal(true);
      POST(API_ADD_SHAREHOLDING_DETAILS, { data }, { headers })
        .then(({ data }) => {
          loaderModal(false);
          if (data.status === "success") {
            history.push(`/Sales/${applicationNumber}/applicants`);
          } else {
            updateErrorMessage(data.message);
          }
        })
        .catch((err) => {
          updateErrorMessage("Something went wrong please try again later.");
          loaderModal(false);
          console.log(err);
        });
    } else {
      updateErrorMessage("Total shareholding can't be greater than 100% or less than 51%.");
    }
  };

  fetchDetails = async () => {
    let { match, actions, history } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      { loaderModal, getShareholdingDetails, getEntityName, getConstitutionRelations } = actions,
      err = false;
    if (applicationNumber) {
      loaderModal(true);
      getEntityName(applicationNumber).then(({ data, status, message }) => {
        if (status === "success") {
          getConstitutionRelations(data.businessConstitution);
        } else {
          let status = message.search("Business details not found for the application number.");
          if (!status) {
            err = true;
            history.push(`/Sales/${applicationNumber}/gstnaddress`);
          }
        }
      });
      !err && (await getShareholdingDetails(applicationNumber));
      !err && loaderModal(false);
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { history, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    if (!applicationNumber) {
      history.push("/Sales/gstn");
    }
  }

  componentDidMount() {
    this.fetchDetails();
  }

  componentWillUnmount() {
    let { setInitialResponse } = this.props.actions;
    setInitialResponse(false);
  }

  render() {
    const { modalVisible, relationIndex } = this.state,
      { constitutionRelation, initialResponse, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    return (
      <div className={"applicant-guide-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Applicant Guide</title>
        </Helmet>
        <Modal
          parentSelector={() => document.querySelector(".applicant-guide-wrapper")}
          isOpen={modalVisible}
          onRequestClose={null}
          className={"applicant-guide-modal"}
          overlayClassName={"content-modal-overlay"}
        >
          <span onClick={() => this.toggleModal(false)} className={"cross-icon"}>
            <Icon icon={cross} size={26} />
          </span>
          <h3>Add Entity as Co-applicant</h3>
          <form onSubmit={() => this.toggleModal(false)} className={"form-box"}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"Enter Name"} />
              </div>
              {/*<div className={'col-md-6'}>*/}
              {/*<DropdownButton*/}
              {/*onSelect={this.selectType}*/}
              {/*title={types[typeIndex - 1]}*/}
              {/*id={'type-select'}*/}
              {/*className={'select-box'}*/}
              {/*>*/}
              {/*{*/}
              {/*types.map((item, index) => {*/}
              {/*return (*/}
              {/*<MenuItem title={item} key={`type-${index + 1}`}*/}
              {/*eventKey={index + 1}>{item}</MenuItem>*/}
              {/*)*/}
              {/*})*/}
              {/*}*/}
              {/*</DropdownButton>*/}
              {/*</div>*/}
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"Enter PAN"} />
              </div>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"% Shareholding"} />
              </div>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"Enter Floor/Building Number"} />
              </div>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"Enter Street/Landmark"} />
              </div>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"City"} />
              </div>
              <div className={"col-md-6"}>
                <input type={"text"} className={"input-field"} placeholder={"Pin Code"} />
              </div>
              <div className={"col-xs-12"}>
               
                <select
                  className={"select-box1"}
                  id={"relation-select"}
                  value={constitutionRelation[relationIndex - 1] || "Relation"}
                  onChange={this.selectRelation}
                >
                  {constitutionRelation.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
              <div className={"text-center"}>
                <button className={"submit-btn"} type={"submit"}>
                  Save & Continue
                </button>
              </div>
            </div>
          </form>
        </Modal>
        <ProgressBar active={3} {...this.props} />
        <div className={"content-box"}>
          <Link to={`/Sales/${applicationNumber}/gstnaddress`} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>Identify Applicants & Co-applicants</h4>
          <div className={"declaration-container"}>
            <div className={"declaration-box"}>
              <div className={"statement"}>
                <label htmlFor={"dec1"} className={"input-label"}>
                  Who is an applicant?
                </label>
                <ul className={"list-unstyled"}>
                  <li>
                    <p>
                      1. Primary Applicant would be the proprietor in case of proprietorship, any partner preferably
                      having the major share in partnership Firm/LLP and managing director or major shareholder in case
                      of Company.
                    </p>
                  </li>
                  <li>
                    <p>2. In case of a Partnership Firm, all Partners would be considered as Applicants.</p>
                  </li>
                  <li>
                    <p>
                      3. In case of an LLP/Incorporated Company, shareholders with at least 50% share would be
                      considered as Applicants.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"declaration-box"}>
              <div className={"statement"}>
                <label htmlFor={"dec1"} className={"input-label"}>
                  Who can be co-applicant?
                </label>
                <ul className={"list-unstyled"}>
                  <li>
                    <p>
                      1. All Partners in case of partnership firm and shareholders and directors in case of a company.
                    </p>
                  </li>
                  <li>
                    <p>2. An immediate Family member (explained below)</p>
                    <p>* Would be an immediate family member in case of Proprietorship.</p>
                    <p>* Would be an immediate family member of partners in case of a partnership.</p>
                    <p>
                      * Would be an immediate family Member of any director/shareholder in company in case of Company.
                    </p>
                  </li>
                  <li>
                    <b>Immediate Family Member:</b>
                    <p>Spouse/Father/Mother/Son/Daughter</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={"form-wrapper"}>
            {initialResponse ? (
              <EntityForm {...this.props} />
            ) : (
              <div style={{ marginTop: "25px" }}>
                <ContentLoader height={150} type={"facebook"} />
              </div>
            )}
          </div>
          <div className={"btns-box"}>
            {/* <button
              disabled
              onClick={() => this.toggleModal(true)}
              type={"button"}
            >
              <span>
                <Icon icon={ic_add} size={22} />
              </span>
              Add Entity as Co-applicant
            </button> */}
            {this.props.errorMessage ? <ErrorMessage>{this.props.errorMessage}</ErrorMessage> : null}
            <button onClick={this.submitForm} className={"save-btn"}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ applicantGuideReducers, data }) => ({
  initialResponse: applicantGuideReducers.initialResponse,
  shareholdingDetails: applicantGuideReducers.shareholdingDetails,
  constitutionRelation: applicantGuideReducers.constitutionRelations,
  errorMessage: data.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getShareholdingDetails,
      getEntityName,
      getConstitutionRelations,
      setInitialResponse,
      updateErrorMessage,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantGuide);
