import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_GET_BUSINESS_COST,
  API_POST_BUSINESS_COST,
} from "../../../utils/APIUrls";
import { set } from "lodash";
import { bicyclePartsArray } from "./bicycle.config";
import { Header, LoaderModal } from "../../../components";
export default class BicycleBusinessCost extends Component {
  state = {
    bicycleCostAnalysis: {},
    isLoading: false,
  };
  inputHandler = (e) => {
    let { name, value } = e.target,
      { bicycleCostAnalysis } = this.state;
    this.setState({
      bicycleCostAnalysis: set(bicycleCostAnalysis, name, value),
    });
  };
  bicyclePartsHandler = (name, value) => {
    let { bicycleCostAnalysis } = this.state,
      inputValue = !value ? " " : null;
    this.setState({
      bicycleCostAnalysis: {
        ...bicycleCostAnalysis,
        bicycleParts: {
          ...bicycleCostAnalysis.bicycleParts,
          [name]: inputValue,
        },
        monthlyProduction: {
          ...bicycleCostAnalysis.monthlyProduction,
          [name]: inputValue,
        },
        materialProportion: {
          ...bicycleCostAnalysis.materialProportion,
          [name]: inputValue,
        },
      },
    });
  };
  addBicycleCost = async (actionType) => {
    let { bicycleCostAnalysis } = this.state,
      { match ,history } = this.props,
      dataToSend = {
        data: {
          bicycleCostAnalysis,
          customerId: match.params.custId,
          questionAnswers: null,
        },
      };
    this.setState({ isLoading: true });
    await POST(API_POST_BUSINESS_COST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(`/Sales/${match.params.custId}/post_login`);
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else {
          alert(data.message);
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        throw err;
      });
  };
  render() {
    let { match } = this.props,
      { bicycleCostAnalysis, isLoading } = this.state,
      {
        bicycleParts = [],
        customerAndSupplyLinkage,
        manufacturingUnit,
        materialProportion,
        monthlyProduction = [],
      } = bicycleCostAnalysis;
    return (
      <>
        <Header {...this.props} />
        <div className="business-cost">
          <LoaderModal status={isLoading} />
          <div className="container">
            <Link to={`/cluster/${match.params.custId}/photo/Ludhiana-Bicycle-and-Parts-cluster`} className={"back-btn-link"}>
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Business Cost Analysis</h1>
            <form>
              <div className="container m-top--main mt-2">
                <div className="sub-heading">
                  <h2>Bicycle parts</h2>
                </div>
                <h5>Name of Bicycle parts manufactured</h5>
                {bicyclePartsArray.map((bicyclePart) => {
                  const { name, label } = bicyclePart;
                  return (
                    <div className="row" key={name}>
                      <div
                        className="col-xs-9 col-md-9 "
                        onClick={() =>
                          this.bicyclePartsHandler(name, bicycleParts[name])
                        }
                      >
                        <div
                          className={
                            bicycleParts[name]
                              ? "active bicycle-part-box"
                              : "bicycle-part-box"
                          }
                        >
                          <img
                            src={require(`../../../assets/images/Bicycle/${label}.png`)}
                            alt={label}
                            className="bicycle-part-image"
                          />
                          {bicyclePart.label}
                        </div>
                      </div>
                      <div className="col-xs-3 col-md-3 pl-2">
                        <InputLabelAnimation
                          labelText={"Margin %"}
                          onChange={this.inputHandler}
                          name={`bicycleParts.${name}`}
                          type="number"
                          inputValue={bicycleParts[name] || ""}
                          disabled={!bicycleParts[name]}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="container m-top--main mt-2">
                <div className="sub-heading">
                  <h2>Customer and Supply linkage</h2>
                </div>
                <h5>What percentage of your sales is directed towards:</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <InputLabelAnimation
                      labelText={"Directly supply to Hero"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"customerAndSupplyLinkage.directlySupplyToHero"}
                      inputValue={
                        customerAndSupplyLinkage?.directlySupplyToHero
                      }
                    />
                  </div>
                  <div className="col-sm-6  pl-1">
                    <InputLabelAnimation
                      labelText={"Direct supply to Avon"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"customerAndSupplyLinkage.directlySupplyToAvon"}
                      inputValue={
                        customerAndSupplyLinkage?.directlySupplyToAvon
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputLabelAnimation
                      labelText={"Supply to vendors of Hero, Avon & OEMs"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"customerAndSupplyLinkage.supplyToVendors"}
                      inputValue={customerAndSupplyLinkage?.supplyToVendors}
                    />
                  </div>
                  <div className="col-sm-6 pl-1">
                    <InputLabelAnimation
                      labelText={"Replacement (spares) market"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"customerAndSupplyLinkage.replacementMarket"}
                      inputValue={customerAndSupplyLinkage?.replacementMarket}
                    />
                  </div>

                  <div className="col-sm-6">
                    <InputLabelAnimation
                      labelText={"Other"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"customerAndSupplyLinkage.other"}
                      inputValue={customerAndSupplyLinkage?.other}
                    />
                  </div>
                </div>
              </div>
              <div className="container m-top--main mt-2">
                <div className="sub-heading">
                  <h2>Monthly Production</h2>
                </div>
                {bicyclePartsArray.map((bicyclePart) => {
                  const { name, label } = bicyclePart;
                  return bicycleParts[name] ? (
                    <div className="container">
                      <div className="blue-label">{label}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputLabelAnimation
                            labelText={"Monthly sales / production (units)"}
                            onChange={this.inputHandler}
                            type="number"
                            required={true}
                            name={`monthlyProduction.${name}.monthlySales`}
                            inputValue={monthlyProduction[name]?.monthlySales}
                          />
                        </div>
                        <div className="col-sm-6 pl-1">
                          <InputLabelAnimation
                            labelText={"Average sales price per unit (in ₹)"}
                            onChange={this.inputHandler}
                            type="number"
                            required={true}
                            name={`monthlyProduction.${name}.averageSales`}
                            inputValue={monthlyProduction[name]?.averageSales}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
              <div className="container m-top--main mt-2">
                <div className="sub-heading">
                  <h2>
                    Proportion of material in manufacturing of single unit
                  </h2>
                </div>
                {bicyclePartsArray.map((bicyclePart) => {
                  const { name, label } = bicyclePart;
                  return bicycleParts[name] ? (
                    <div className="container">
                      <div className="blue-label">{label}</div>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputLabelAnimation
                            labelText={"Steel purchased (in kg)"}
                            onChange={this.inputHandler}
                            type="number"
                            required={true}
                            name={`materialProportion.${name}.steelPurchased`}
                            inputValue={
                              materialProportion[name]?.steelPurchased
                            }
                          />
                        </div>
                        <div className="col-sm-6 pl-1">
                          <InputLabelAnimation
                            labelText={"Average purchase price per unit (in ₹)"}
                            onChange={this.inputHandler}
                            type="number"
                            required={true}
                            name={`materialProportion.${name}.averagePurchase`}
                            inputValue={
                              materialProportion[name]?.averagePurchase
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
                <h5>What is your Total monthly purchase (in kg)</h5>
                <div className="row">
                  <div className="col-sm-6">
                    <InputLabelAnimation
                      labelText={"Total monthly Steel purchase (in kg)"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"materialProportion.totalMonthlySteelPurchase"}
                      inputValue={materialProportion?.totalMonthlySteelPurchase}
                    />
                  </div>
                  <div className="col-sm-6 pl-1">
                    <InputLabelAnimation
                      labelText={"Total monthly Rubber purchase (in kg)"}
                      onChange={this.inputHandler}
                      type="number"
                      required={true}
                      name={"materialProportion.totalMonthlyRubberPurchase"}
                      inputValue={
                        materialProportion?.totalMonthlyRubberPurchase
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="container m-top--main mt-2">
                <div className="sub-heading">
                  <h2>Manufacturing Unit</h2>
                </div>
                <InputLabelAnimation
                  labelText={"Size of Manufacturing Unit (in sq. Yard)"}
                  onChange={this.inputHandler}
                  type="number"
                  required={true}
                  name={"manufacturingUnit.sizeOfManufacturingUnit"}
                  inputValue={manufacturingUnit?.sizeOfManufacturingUnit}
                />
              </div>
              <div className="btn-box">
                <div
                  className="btn-draft "
                  onClick={() => this.addBicycleCost("draft")}
                >
                  Save for later
                </div>
                <div
                  className="btn-save"
                  onClick={() => this.addBicycleCost("save")}
                >
                  Save and Next
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    const { match } = this.props;
    this.setState({ isLoading: true });
    GET(API_GET_BUSINESS_COST(match.params.custId)).then(({ data }) => {
      if (data.data) {
        let { bicycleCostAnalysis } = data.data;
        this.setState({ bicycleCostAnalysis });
      }
      this.setState({ isLoading: false });
    });
  };
}
