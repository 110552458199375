import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { getOPSUserApplication } from "../newCpoPanel.actions";
import { bindActionCreators } from "redux";
import { LoaderModal } from "../../../components";
import { Tabs, Tab } from "react-bootstrap";
import ReactTable from "react-table";
import Header from "../../../components/Header/Header";
import * as DashBoardConfig from "./CpoDashboard.config";
import { _closedCases } from "./CpoDashboard.config";
import { loaderModal } from "../../../store/actions";
import { SelectComponent } from "./SelectComponent";
import {
  getOPSUserFilteredApplication,
  setSearchCriteria,
  setPageNumber,
} from "../newCpoPanel.actions";
import { get } from "lodash";

class CpoDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStatus: "New",
      totalPages: 1,
    };
  }

  getPageData = (type) => {
    const { pageNumber } = this.props; // Use pageNumber from props instead of local state
    const { totalPages } = this.props.cpoUserApplicationData;

    let newPageNumber = pageNumber; // Create a variable for the new page number

    if (type === "next" && pageNumber < totalPages) {
      newPageNumber++; // Increment page number for next
    } else if (type === "prev" && pageNumber > 1) {
      newPageNumber--; // Decrement page number for previous
    }

    // Only update if newPageNumber is different from the current pageNumber
    if (newPageNumber !== pageNumber) {
      this.props.actions.setPageNumber(newPageNumber); // Dispatch the action to update the page number in Redux
      this.fetchDetails(newPageNumber, this.state.selectedStatus); // Fetch details with the new page number
    }
  };

  fetchDetails = async (pageNumber, statusFilter) => {
    let { getOPSUserFilteredApplication, loaderModal } = this.props.actions;
    let dataToSend = {
      pageNumber: pageNumber || 1,
      dashboard: "CPO_USER",
      searchValue: this.props.searchValue?.trim(),
      status: statusFilter,
      searchType: this.props.searchBy,
    };
    loaderModal(true);
    await getOPSUserFilteredApplication(dataToSend);
    loaderModal(false);
  };

  selectedTab = (key, index) => {
    this.props.history.push(
      this.props.history.location.pathname + "?activetab=" + key
    );
    this.props.actions.setPageNumber(1);
    // Setting the page number to 1
    this.props.actions.setSearchCriteria("", "");
    this.setState({ selectedStatus: key }, () => {
      if (this.fetchDetails) {
        this.fetchDetails(1, this.state.selectedStatus);
      }
    });
  };

  rowClicked = (state, rowInfo, column, instance) => {
    if (column) {
      return {
        onClick: () => {
          var win = window.open(
            `${rowInfo.original.applicationNumber}/User/Exception`,
            "_blank"
          );
          win.focus();
        },
      };
    }
    return false;
  };

  render() {
    let {
        loaderModal,
        cpoUserApplicationData,
        documentException = [],
        pageNumber,
        location: { search },
      } = this.props || {},
      { selectedStatus } = this.state;
    let { totalPages } = cpoUserApplicationData;
    const queryPart = search.split("?")[1];
    const currentTab = queryPart?.split("=")[1];
    let closedCases = [
      ..._closedCases,
      {
        Header: "Exception",
        width: 200,
        Cell: (props) => {
          let {
            original: { applicationNumber },
          } = props;
          return (
            <div className={" col-md-12"}>
              <SelectComponent
                applicationNumber={applicationNumber}
                fetchDetails={() =>
                  this.fetchDetails(pageNumber, selectedStatus)
                }
                currentTab={currentTab}
              />
            </div>
          );
        },
      },
    ];

    return (
      <>
        <Header {...this.props} />
        <div className="cpo-dashboard__wrapper container">
          <LoaderModal status={loaderModal} />
          <Tabs
            defaultActiveKey={"New"}
            id="adminTabs"
            onSelect={this.selectedTab}
          >
            <Tab eventKey={"New"} title={"New Cases"}>
              <ReactTable
                columns={DashBoardConfig.newCases}
                data={cpoUserApplicationData?.list}
                resizable={false}
                showPagination={false}
                minRows={0}
                getTdProps={this.rowClicked}
              />
              <ul className={"datatable-pagination"}>
                <li>
                  <button
                    disabled={!(pageNumber > 1)}
                    className={"prev-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("prev")}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {pageNumber} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > pageNumber)}
                    className={"next-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("next")}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </Tab>
            <Tab eventKey={"Pending"} title={"Pending Cases"}>
              <ReactTable
                columns={DashBoardConfig.pendingCases}
                data={cpoUserApplicationData?.list}
                resizable={false}
                showPagination={false}
                minRows={0}
                getTdProps={this.rowClicked}
              />
              <ul className={"datatable-pagination"}>
                <li>
                  <button
                    disabled={!(pageNumber > 1)}
                    className={"prev-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("prev")}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {pageNumber} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > pageNumber)}
                    className={"next-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("next")}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </Tab>
            <Tab eventKey={"Closed"} title={"Closed Cases"}>
              <ReactTable
                className="React-table"
                columns={closedCases}
                data={cpoUserApplicationData?.list}
                resizable={false}
                showPagination={false}
                minRows={0}
              />
              <ul className={"datatable-pagination"}>
                <li>
                  <button
                    disabled={!(pageNumber > 1)}
                    className={"prev-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("prev")}
                  >
                    Previous
                  </button>
                </li>
                <li>
                  <span>
                    Page No. {pageNumber} of {totalPages}
                  </span>
                </li>
                <li>
                  <button
                    disabled={!(totalPages > pageNumber)}
                    className={"next-btn"}
                    type={"button"}
                    onClick={() => this.getPageData("next")}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
  componentDidMount = () => {
    let { pageNumber, selectedStatus } = this.state;
    this.fetchDetails(pageNumber, selectedStatus);
  };
}

const mapStateToProps = ({ data, CPOUserReducer, cpoDocumentsReducers }) => ({
  cpoUserApplicationData: CPOUserReducer.cpoUserApplicationData,
  loaderModal: data.loaderModal,
  documentException: cpoDocumentsReducers.documentException,
  searchBy: CPOUserReducer.searchBy,
  searchValue: CPOUserReducer.searchValue,
  pageNumber: CPOUserReducer.pageNumber,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getOPSUserApplication,
      getOPSUserFilteredApplication,
      setSearchCriteria,
      loaderModal,
      setPageNumber,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CpoDashboard);
