import * as GstnConstants from "./GstnAddress.actions.constants";
import { GET } from "../../../utils/webAPI.service";
import { API_GET_BUSINESS, API_GET_RELATED_PARTY_DETAILS } from "../../../utils/APIUrls";

const getBusiness = (applicationNumber) => (dispatch) => {
  return GET(API_GET_BUSINESS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GstnConstants.GET_BUSINESS, data: data.data.businessDetails });
      }
    })
    .catch((err) => {
      throw err;
    });
};
const fetchPartyDetails = (applicationNumber) => (dispatch) => {
  return GET(API_GET_RELATED_PARTY_DETAILS(applicationNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: GstnConstants.GET_PARTY_DETAILS, data: data.data.relatedParty });
      }
    })
    .catch((err) => {
      throw err;
    });
};

const changeInputValue = (val, name, index) => ({
  type: GstnConstants.CHANGE_VALUE,
  val,
  name,
  index,
});
const addAddress = (index) => ({
  type: GstnConstants.ADD_ADDRESS,
  gstIndex: index,
});
const addPartyNewRow = () => ({
  type: GstnConstants.ADD_PARTY_ROW,
});
const deletePartyRow = (index) => ({
  type: GstnConstants.DELETE_PARTY_ROW,
  index,
});
const partyChangeHandler = (index, name, val) => ({
  type: GstnConstants.PARTY_CHANGE_HANDLER,
  index,
  name,
  val,
});
const deleteAddress = (gstIndex, addressIndex) => ({
  type: GstnConstants.DELETE_ADDRESS,
  gstIndex,
  addressIndex,
});
const updateAddress = (gstIndex, addressIndex, name, val) => ({
  type: GstnConstants.UPDATE_ADDRESS,
  gstIndex,
  addressIndex,
  name,
  val,
});
const resetGstnAddressState = () => ({
  type: GstnConstants.RESET_REDUX_STATE
});
export {
  getBusiness,
  changeInputValue,
  addAddress,
  updateAddress,
  deleteAddress,
  fetchPartyDetails,
  addPartyNewRow,
  deletePartyRow,
  partyChangeHandler,
  resetGstnAddressState
};
