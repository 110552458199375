import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ProgressBar } from "../../../components";
import { loaderModal, updateErrorMessage } from "../../../store/actions";
import { getList } from "../../../store/listData.actions";
import { get } from "lodash";
import { getSalesReferences, setInitialResponse } from "./references.actions";
import { Link } from "react-router-dom";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import ReferencesForm from "./ReferencesForm";
import ContentLoader from "react-content-loader";
import { API_GET_REFERENCE_RELATION, API_ADD_REFEREMCES } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import { validateEmail } from "../../../utils/utitlity";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { Helmet } from "react-helmet";

class References extends Component {
  validateForm = data => {
    let { updateErrorMessage } = this.props.actions;
    let referencesBox = this.referencesBox.children;
    for (let i = 0; i < data.length; i++) {
      let { address, email, mobile, name, relation } = data[i];
      if (!name) {
        updateErrorMessage("Please Enter Reference Name");
        referencesBox[i].querySelector('input[name="name"]').focus();
        return false;
      }
      if (!mobile) {
        updateErrorMessage("Please Enter Reference Mobile Number");
        referencesBox[i].querySelector('input[name="mobile"]').focus();
        return false;
      }
      if (!email || !validateEmail(email)) {
        updateErrorMessage("Please Enter Valid Reference Email-id");
        referencesBox[i].querySelector('input[name="email"]').focus();
        return false;
      }
      if (relation === "Select Relationship") {
        updateErrorMessage("Please Select Reference Relation");
        let elem = referencesBox[i].querySelector('select[id="relation-select"]');
        elem.scrollIntoView();
        elem.click();
        return false;
      }
      if (!address.line1) {
        updateErrorMessage("Please Enter Floor/Building Number");
        referencesBox[i].querySelector('input[name="address.line1"]').focus();
        return false;
      }
      if (!address.line2) {
        updateErrorMessage("Please Enter Street/Landmark");
        referencesBox[i].querySelector('input[name="address.line2"]').focus();
        return false;
      }
      if (!address.pinCode) {
        updateErrorMessage("Please Enter Pin Code");
        return false;
      }
    }
    return true;
  };

  fetchDetails = async () => {
    
    let { actions, match, history, referenceRelations } = this.props,
      { loaderModal, getSalesReferences, getList } = actions,
      applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      loaderModal(true);
      !referenceRelations.length &&
        (await getList("SET_REFERENCE_RELATIONSHIP_LIST", API_GET_REFERENCE_RELATION, "Select Relationship"));
      await getSalesReferences(applicationNumber);
      loaderModal(false);
    } else {
      history.push("/Sales/gstn");
    }
    
  };

  submitForm = () => {
    let { referencesList, actions, history, match } = this.props,
      { loaderModal, updateErrorMessage } = actions,
      applicationNumber = get(match, "params.appNo", "");
    const headers = { "Content-Type": "application/json" };
    let data = {
      applicationNumber,
      referenceList: referencesList.map(item => ({
        address: {
          addressType: get(item, "address.addressType", ""),
          city: get(item, "address.city", ""),
          district: get(item, "address.district", ""),
          line1: get(item, "address.line1", ""),
          line2: get(item, "address.line2", ""),
          ownership: get(item, "address.ownership", ""),
          pinCode: get(item, "address.pinCode", ""),
          state: get(item, "address.state", "")
        },
        email: get(item, "email", ""),
        id: get(item, "id", ""),
        isVerified: false,
        mobile: get(item, "mobile", ""),
        name: get(item, "name", ""),
        relation: get(item, "relation", "Select Relationship"),
        relationsFrom: "APPLICANT"
      }))
    };
    if (this.validateForm(data.referenceList)) {
      loaderModal(true);
      POST(API_ADD_REFEREMCES, { data }, { headers })
        .then(({ data }) => {
          loaderModal(false);
          if (data.status === "success") {
            history.push(`/Sales/${applicationNumber}/documents`);
          } else {
            updateErrorMessage(data.message);
          }
        })
        .catch(err => {
          updateErrorMessage("Something went wrong please try again later.");
          loaderModal(false);
          console.log(err);
        });
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.fetchDetails();
  }

  componentWillUnmount() {
    const { setInitialResponse } = this.props.actions;
    setInitialResponse(false);
  }
  skipHandler = () => {
    let { history, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    history.push(`/Sales/${applicationNumber}/documents`);
  };
  render() {
    const { referencesList, initialResponse, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    return (
      <div className={"references-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>References</title>
        </Helmet>
        <ProgressBar active={6} {...this.props} />
        <div className={"content-box"}>
          <Link to={`/Sales/${applicationNumber}/banking`} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>
            Add Trade References
            <span className="skipNow" onClick={this.skipHandler}>
              Skip For Now
            </span>
          </h4>

          <div ref={ref => (this.referencesBox = ref)} className={"references-box"}>
            {initialResponse ? (
              referencesList.map((item, index) => <ReferencesForm key={index} index={index} />)
            ) : (
              <div style={{ margin: "25px 0" }}>
                <ContentLoader height={200} type={"facebook"} />
              </div>
            )}
          </div>
          {this.props.errorMessage ? <ErrorMessage>{this.props.errorMessage}</ErrorMessage> : null}
          <div className={"btns-box"}>
            <button onClick={this.submitForm} type={"button"} className={"next-btn"}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ referencesReducers, listData, data }) => ({
  referencesList: referencesReducers.referencesList,
  initialResponse: referencesReducers.initialResponse,
  referenceRelations: listData.referenceRelations,
  errorMessage: data.errorMessage
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getSalesReferences,
      setInitialResponse,
      getList,
      updateErrorMessage,
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(References);
