import { API_GET_SOURCING_DETAILS } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as SourcingDetailsConstant from "./sourcingDetails.actions.constants";

const getSourcingDetails = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_GET_SOURCING_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          if (data.data) {
            dispatch({
              type: SourcingDetailsConstant.SET_SOURCING_DETAILS,
              data: data.data,
            });
          }
        }

        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const changeInputValue = (val, name) => ({
  type: SourcingDetailsConstant.CHANGE_VALUE,
  val,
  name,
});

const resetSourcingDetailsState = () => {
  return (dispatch) => {
    dispatch({ type: SourcingDetailsConstant.RESET_REDUX_STATE});
  };
};

export { getSourcingDetails, changeInputValue, resetSourcingDetailsState };
