import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import InputMask from "react-input-mask";

class InputLabelAnimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldActive: !!props.inputValue,
    };
  }

  activateField = () => {
    this.setState({
      fieldActive: true,
    });
  };

  disableField = (e) => {
    !e.target.value &&
      this.setState({
        fieldActive: false,
      });
  };

  updateInputValue = (e) => {
    const { onChange } = this.props;
    onChange(e);
    this.activateField(e);
  };

  componentWillReceiveProps(nextProps) {
    let { fieldActive } = this.state,
      isFocused = document.activeElement === ReactDOM.findDOMNode(this.inputField);
    nextProps.inputValue
      ? !fieldActive && this.setState({ fieldActive: true })
      : fieldActive && !isFocused && this.setState({ fieldActive: false });
  }

  render() {
    const { fieldActive } = this.state,
      {
        inputClass,
        type,
        labelText,
        required,
        name,
        inputValue,
        disabled,
        minLength,
        maxLength,
        min,
        max,
        boxClass,
        readOnly,
        tabIndex,
        title,
        placeholder,
        mask,
      } = this.props;
    return (
      <div className={disabled ? `input-box input-disabled ${boxClass}` : `input-box ${boxClass}`}>
        <label onClick={() => this.inputField.focus()} className={fieldActive ? "field-active" : ""}>
          {labelText}
          {required ? <span>&nbsp;*&nbsp;</span> : null}
        </label>
        <InputMask
          mask={mask}
          maskChar={null}
          placeholder={placeholder}
          inputRef={(ref) => (this.inputField = ref)}
          className={`floating-label ${inputClass}`}
          type={type}
          value={inputValue}
          onFocus={this.activateField}
          onBlur={this.disableField}
          onChange={this.updateInputValue}
          required={required}
          name={name}
          disabled={disabled}
          maxLength={maxLength ? maxLength : null}
          minLength={minLength ? minLength : null}
          max={max ? max : null}
          min={min ? min : null}
          readOnly={readOnly}
          tabIndex={tabIndex}
          title={title}
        />
      </div>
    );
  }
}

InputLabelAnimation.defaultProps = {
  inputClass: "input-field",
  type: "text",
  required: false,
  labelText: "",
  name: "",
  inputValue: "",
  onChange: () => {},
  disabled: false,
  readOnly: false,
  minLength: 0,
  maxLength: 0,
  min: 0,
  max: 0,
  boxClass: "",
  tabIndex: -1,
  title: "",
  placeholder: "",
  mask: "",
};

InputLabelAnimation.propTypes = {
  inputClass: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  // disabled: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  boxClass: PropTypes.string,
  readOnly: PropTypes.bool,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
};

export default InputLabelAnimation;
