import React, { Component, Fragment } from "react";
import { InputLabelAnimation } from "../../../components";
import { API_GENERATE_EXCEL_EMAIL } from "../../../utils/APIUrls";
import axios from "axios";

export default class ShareExcelTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to: "",
      cc: "",
    };
  }

  validate = () => {
    let { to, cc } = this.state;
    if (to === "") {
      alert("Enter correct email id");
      return false;
    } else if (!to.includes("@credochain.com" || "gmail.com")) {
      alert("Enter correct email id");
      return false;
    } else if (cc) {
      if (!cc.includes("@credochain.com" || "gmail.com")) {
        alert("Enter correct email id");
        return false;
      }
    }
    return true;
  };

  shareExcelTemplate = () => {
    if (this.validate()) {
      let {
        closeAction,
        actions: { loaderModal },
      } = this.props;
      let { to, cc } = this.state;
      let { match } = this.props;
      const data = {
        applicationNumber: match?.params?.appNo,
        cc,
        to,
      };
      loaderModal(true);
      axios
        .post(API_GENERATE_EXCEL_EMAIL, data, {
          auth: {
            username: "bank",
            password: "bank123",
          },
        })
        .then((response) => {
          if (response?.status === 200) {
            alert("Email send successfully");
            closeAction();
            loaderModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
          loaderModal(false);
        });
    }
  };

  inputHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <Fragment>
        <div className="co-applicant-wrapper share-excel">
          <div className="input-box share-excel mt-5 row">
            <div className="input-email col-md-12 mt-4">
              <InputLabelAnimation
                labelText={"Enter email id"}
                required={true}
                type={"email"}
                inputValue={this.state["to"]}
                onChange={this.inputHandler}
                name={"to"}
              />
            </div>

            <div className="input-email col-md-12 mt-4 ">
              <InputLabelAnimation
                labelText={"cc"}
                type={"email"}
                inputValue={this.state["cc"]}
                onChange={this.inputHandler}
                name={"cc"}
              />
            </div>
          </div>
          <div className="co-applicant-button row">
            <button
              className="button_Generic blue-btn mt-3 ml-3 bolder-text"
              type={"submit"}
              onClick={() => this.shareExcelTemplate()}
            >
              Continue
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}
