import React, { Component } from "react";

export default class DateDropdown extends Component {
  state = {
    day: "1",
    month: "Jan",
    year: "1980",
  };
  dateHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      let { day, month, year } = this.state,
        { onDateChange, componentName } = this.props;
      onDateChange(componentName, `${day}-${month}-${year}`);
    });
  };
  render() {
    let { day, month, year } = this.state;
    let dateSize = 31;
    let dayArray = Array.from(Array(dateSize).keys()),
      monthsList = ["Select Month", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      yearArray = Array.from(Array(101).keys()).map((year) => year + 1920);
    return (
      <div className="date-dropdown-wrapper">
        <select name="day" id="day" onChange={this.dateHandler} value={day} className="date-element r-left">
          {dayArray.map((day, index) => (
            <option value={day} key={`${day}-${index}`}>
              {day}
            </option>
          ))}
        </select>
        <select name="month" id="month" onChange={this.dateHandler} value={month} className="date-element">
          {monthsList.map((month, index) => (
            <option value={index} key={`${month}-${index}`}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" id="year" onChange={this.dateHandler} value={year} className="date-element r-right">
          {yearArray.map((year, index) => (
            <option value={year} key={`${year}-${index}`}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  }
  componentDidUpdate = (prevProps, prevState) => {
    let { value } = this.props,
      [day, month, year] = value.split("-"),
      [prevDay, prevMonth, prevYear] = prevProps.value.split("-");
    if (prevDay !== prevState.day && prevMonth !== prevState.month && prevYear !== prevState.year) {
      this.setState({ day, month, year });
    }
  };
}
DateDropdown.defaultProps = {
  onDateChange: () => {},
  componentName: "",
};
