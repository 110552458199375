import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { forward } from "react-icons-kit/entypo/forward";
import { arrowDownBoldRound } from "react-icons-kit/metrize/arrowDownBoldRound";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getRatios } from "./ITR.actions";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import Highcharts from "highcharts";
import { get } from "lodash";
import { loaderModal } from "../../../store/actions";

class FinancialAnalysisRatios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      ratiosData: {},
      GrowthAnalysis: false,
      ProfitabilityAnalysis: false,
      LeverageAnalysis: false,
      WorkingCapitalAnalysis: false,
    };
  }
  async componentDidMount() {
    let { actions, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    actions.loaderModal(true);
    await actions.getRatios(applicationNumber);
    actions.loaderModal(false);
  }
  componentDidUpdate(prevProps) {
    let { ratiosData } = this.props;

    if (prevProps.ratiosData !== ratiosData) {
      ratiosData &&
        ratiosData.map((item, index) => {
          item.cardData &&
            item.cardData.map((el, i) => {
              let formatvalue =
                item.mainHeading === "Working Capital Analysis"
                  ? "{value} days"
                  : "{value} %";
              let formatToolip =
                item.mainHeading === "Working Capital Analysis"
                  ? " days"
                  : " %";
              Highcharts.chart(el.cardHeading, {
                chart: {
                  type: "area",
                  backgroundColor: "transparent",
                  height: 140,
                },
                credits: {
                  enabled: false,
                },
                title: false,
                xAxis: {
                  categories: el.yearlyRatios.map((data) => data.year),
                  labels: {
                    style: {
                      color: "black",
                      fontSize: "9px",
                    },
                  },
                },
                yAxis: {
                  title: false,
                  labels: {
                    format: formatvalue,
                    style: {
                      color: "black",
                      fontSize: "9px",
                    },
                  },
                },
                legend: {
                  enabled: false,
                },
                tooltip: {
                  useHTML: true,
                  formatter: function () {
                    return (
                      "<p>" +
                      this.x +
                      "<br/>" +
                      this.series.name +
                      "<b>:  </b>" +
                      this.y +
                      formatToolip +
                      "</p>"
                    );
                  },
                },
                plotOptions: {
                  line: {
                    dataLabels: {
                      style: {
                        fontSize: "1em",
                      },
                    },
                    enableMouseTracking: false,
                  },
                },
                series: [
                  {
                    marker: {
                      radius: 0,
                    },
                    data: el.yearlyRatios
                      ? el.yearlyRatios.map((data) => data.value)
                      : [],
                    color: {
                      linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1,
                      },
                      stops: [
                        [0, "#4687f4"],
                        [1, "rgba(139, 213, 245, 0)"],
                      ],
                    },
                  },
                ],
              });
              return null;
            });

          return null;
        });
    }
  }

  render() {
    let { ratiosData } = this.props;

    let { assesseeDetailsData } = this.props;
    let key =
      assesseeDetailsData.assesseeDetails &&
      Object.keys(assesseeDetailsData.assesseeDetails)[0];
    let asseesseeName =
      assesseeDetailsData?.assesseeDetails?.[key]?.details?.Details
        ?.assesseeName;

    return (
      <Fragment>
        <div className="financial-analysis-ratio__wrapper">
          <div className="accounts-tabs">
            <div className="box-wrapper">
              <div className="trader-name">
                <label>{asseesseeName}</label>
                <div className="icons">
                  <Icon
                    className="icon-styles set-cursor-pointer"
                    icon={forward}
                    size={22}
                  />
                  <Icon
                    className="icon-styles set-cursor-pointer"
                    icon={ic_mode_edit}
                    size={22}
                  />
                </div>
              </div>
              {ratiosData.length !== 0 ? (
                ratiosData.map((item, i) => {
                  return (
                    <div className="box-wrapper inner-box" key={i}>
                      {item.mainHeading}
                      <Icon
                        onClick={() =>
                          this.setState((prevState) => ({
                            [item.mainHeading]: !prevState[item.mainHeading],
                          }))
                        }
                        icon={arrowDownBoldRound}
                        size={30}
                        className={
                          this.state[item.mainHeading]
                            ? "arrow rotate"
                            : "arrow"
                        }
                      />
                      <SlideDown closed={!this.state[item.mainHeading]}>
                        <div className="charts-outer__wrapper">
                          {item.cardData.map((card, i) => {
                            let sign = " (%)",
                              valueSign = "%";
                            if (
                              item.mainHeading === "Working Capital Analysis"
                            ) {
                              sign = " ";
                              valueSign = " ";
                            }
                            return (
                              <div className="chart__wrapper" key={i}>
                                <div className="child-space-between">
                                  <div className="charts-heading ">
                                    {card.cardHeading + sign}
                                  </div>
                                  <div className="charts-heading">
                                    Trend over time
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12 p-0">
                                  <div className="numerics">
                                    <div className="ratios-values">
                                      {card.yearlyRatios.map((ratio, i) => {
                                        if (ratio.value) {
                                          return (
                                            <div className="year" key={i}>
                                              <div className="labels">
                                                {ratio.year}
                                              </div>
                                              <div className="value">
                                                {ratio.value % 1 === 0
                                                  ? ratio.value + valueSign
                                                  : ratio.value?.toFixed(2) +
                                                    valueSign}
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return null;
                                        }
                                      })}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 col-sm-12 p-0">
                                  <div className="charts-values mt-2">
                                    <div
                                      id={card.cardHeading}
                                      className="col-xs-12"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </SlideDown>
                    </div>
                  );
                })
              ) : (
                <div className="no-data-div"> No Data Found</div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ itrReducer }) => ({
  ratiosData: itrReducer.ratiosData,
  assesseeDetailsData: itrReducer.assesseeDetailsData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getRatios,
      loaderModal,
    },
    dispatch
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialAnalysisRatios);
