import React, { Component } from "react";
import { ReactComponent as Expand } from "../../../assets/images/SVG/expand.svg";
import { ReactComponent as Bag } from "../../../assets/images/bagsvg.svg";
import { ReactComponent as Bank } from "../../../assets/images/bankSvg.svg";
import { ReactComponent as Location } from "../../../assets/images/locationSvg.svg";
import Gating_Override from "../../../assets/images/gating_override.svg";
import return_bureau from "../../../assets/images/return_bureau.svg";
import Map from "../../../assets/images/map.jpg";
import Group from "../../../assets/images/group.png";
import Highcharts from "highcharts";
import Icon from "react-icons-kit";
import { smallUp } from "react-icons-kit/entypo/smallUp";
import { smallDown } from "react-icons-kit/entypo/smallDown";
import CredoModal from "../../../components/CredoModal/CredoModal";
import BankAccountDetails from "./BankAccountDetails";
import AssetOwnership from "./AssetOwnership";
import GatingOverride from "../Dashboard/GatingOverride";
import RerunBureau from "../Dashboard/RerunBureau";
import BusinessAddress from "./BusinessAddress";
import OtherBusinessDetails from "./OtherBusinessDetails";
import { SlideDown } from "react-slidedown";
import { u1F311 } from "react-icons-kit/noto_emoji_regular/u1F311";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import { rupeeCommaFormatter, integerToWordFormatter } from "../../../utils/utitlity";
import MediaDocuments from "./MediaDocuments";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_OVERRIDE_GATING } from "../../../utils/APIUrls";
import { API_POST_COMMENT } from "../../../utils/APIUrls";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";

class UnitAnalysis extends Component {
  state = {
    modalOpen: false,
    modalType: AssetOwnership,
    businessModal: true,
    activeFigure: "cluster",
    custId: this.props.custId,
    gatingModalOpen: false,
    comment: null,
    isEditable: false,
  };

  toggleModal = (name) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
    }));
  };
  toggleGatingModal = (name) => {
    this.setState((prevState) => ({
      gatingModalOpen: !prevState.gatingModalOpen,
      modalType: name,
    }));
  };
  inputHandler = (e) => {
    this.setState({ isEditable: true });
  };
  saveCommentsHandler = (e) => {
    let { match } = this.props,
      data = {
        comment: this.state.comment,
        customerId: match.params.custId,
        commentedBy: "UW",
      };

    POST(API_POST_COMMENT, { data })
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ isEditable: false });
        } else if (data.status === "failure") {
          alert(data.message);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  componentDidUpdate(prevProps) {
    let { incomeDetails, monthlyExpenses, employeeDetails, investmentsAndFunds } = this.props;
    if (prevProps.incomeDetails !== incomeDetails) {
      (incomeDetails && this.highChartRenderer(incomeDetails)) ||
        (incomeDetails && this.ringHighChartRenderer(incomeDetails));
    }
    if (prevProps.monthlyExpenses !== monthlyExpenses) {
      monthlyExpenses && this.lineHighChartRenderer(monthlyExpenses);
    }
    if (prevProps.employeeDetails !== employeeDetails) {
      employeeDetails && this.roundHighChartRenderer(employeeDetails);
    }
    if (prevProps.investmentsAndFunds !== investmentsAndFunds) {
      investmentsAndFunds && this.mediumLeftRoundHighChartRenderer(investmentsAndFunds);
    }
    if (prevProps.investmentsAndFunds !== investmentsAndFunds) {
      investmentsAndFunds && this.mediumRightRoundHighChartRenderer(investmentsAndFunds);
    }
  }

  roundHighChartRenderer(employeeDetails) {
    Highcharts.chart("large-round-chart", {
      chart: {
        type: "pie",
        height: 200,
        backgroundColor: "#fff",
        borderColor: null,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
      },
      colors: ["#3671CC", " #00CDBE"],
      plotOptions: {
        series: {
          enableMouseTracking: true,
        },
      },

      series: [
        {
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          name: "",
          innerSize: 120,
          size: 180,
          data: [
            ["Fixed wage employees", employeeDetails.fixedWageEmployees],
            ["Daily wage employees", employeeDetails.dailyWageEmployees],
          ],
        },
      ],
    });
  }

  mediumLeftRoundHighChartRenderer(investmentsAndFunds) {
    Highcharts.chart("medium-round-chart-left", {
      chart: {
        type: "pie",
        height: 200,
        backgroundColor: "#fff",
        borderColor: null,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: " <b>{point.percentage:.1f}%</b>",
      },
      colors: ["#3671CC", " #D0D0D0"],
      plotOptions: {
        series: {
          enableMouseTracking: true,
        },
      },

      series: [
        {
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          name: "",
          innerSize: 120,
          size: 180,
          data: [
            ["", (investmentsAndFunds.purchaseCreditCycle / 360) * 100],
            ["", ((360 - investmentsAndFunds.purchaseCreditCycle) / 360) * 100],
          ],
        },
      ],
    });
  }

  mediumRightRoundHighChartRenderer(investmentsAndFunds) {
    Highcharts.chart("medium-round-chart-right", {
      chart: {
        type: "pie",
        height: 200,
        backgroundColor: "#fff",
        borderColor: null,
        margin: [0, 0, 0, 0],
        spacing: [0, 0, 0, 0],
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        pointFormat: " <b>{point.percentage:.1f}%</b>",
      },
      colors: ["#3671CC", " #D0D0D0"],
      plotOptions: {
        series: {
          enableMouseTracking: true,
        },
      },

      series: [
        {
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          name: "",
          innerSize: 120,
          size: 180,
          data: [
            ["", (investmentsAndFunds.receivableCreditCycle / 360) * 100],
            ["", ((360 - investmentsAndFunds.receivableCreditCycle) / 360) * 100],
          ],
        },
      ],
    });
  }

  lineHighChartRenderer(monthlyExpenses) {
    Highcharts.chart("single-line-chart", {
      chart: {
        type: "bar",
        height: "140",
      },
      title: {
        text: "",
      },
      xAxis: {
        visible: false,
        categories: " ",
      },
      yAxis: {
        visible: false,
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
      },

      plotOptions: {
        series: {
          stacking: "normal",
        },
      },
      tooltip: {
        formatter: function () {
          return "<p>" + this.x + "<br/>" + this.series.name + "<b>:  </b>" + integerToWordFormatter(this.y) + "</p>";
        },
      },

      colors: [" #00CDBE", " #A4A1FB", "#29A9E0"],
      series: [
        {
          name: "Total Asset EMI",
          data: [monthlyExpenses.assetsEmi],
        },
        {
          name: "Obligations towards friends and relatives",
          data: [monthlyExpenses.obligationsFamilyFriends],
        },
        {
          name: "Other obligations",
          data: [monthlyExpenses.otherObligations],
        },
      ],
    });
  }

  highChartRenderer(incomeDetails) {
    Highcharts.chart("bar-chart", {
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      yAxis: {
        visible: false,
      },

      xAxis: {
        type: "category",
        categories: ["Self-Declared", "As per cluster margin", "As per verified Production"],
      },

      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        itemMarginTop: 20,
        itemMarginBottom: 20,
        labelFormatter: function () {
          return this.name + " :-  ₹ " + integerToWordFormatter(this.yData);
        },
      },
      plotOptions: {
        series: {
          pointPadding: 0.2,
          grouping: false,
        },
        column: {
          minPointLength: 2,
        },
      },

      tooltip: {
        formatter: function () {
          return "<p> " + integerToWordFormatter(this.y) + "</p>";
        },
      },

      credits: {
        enabled: false,
      },
      colors: [" #00CDBE", " #A4A1FB", "#29A9E0"],
      series: [
        {
          name: "Self-Declared ",
          pointWidth: 80,
          data: [
            {
              name: "Self-Declared",
              y: incomeDetails?.monthlyIncomeDetails?.selfDeclared,
            },
          ],
        },
        {
          name: " As per cluster margin ",
          pointWidth: 80,
          data: [
            {
              name: "As per cluster margin",
              y: incomeDetails?.monthlyIncomeDetails?.asPerMargin,
            },
          ],
        },
        {
          name: "As per verified Production",
          pointWidth: 80,
          data: [
            {
              name: "As per verified Production",
              y: incomeDetails?.monthlyIncomeDetails?.asPerProduction,
            },
          ],
        },
      ],
    });
  }

  ringHighChartRenderer(incomeDetails) {
    Highcharts.chart("ring", {
      chart: {
        type: "pie",
        width: "30",
        height: "34",
        backgroundColor: "transparent",
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#00CDBE", "#D0D0D0"],
      plotOptions: {
        series: {
          enableMouseTracking: false,
        },
      },

      series: [
        {
          marker: {
            enabled: false,
          },
          dataLabels: {
            enabled: false,
          },
          innerSize: 20,
          size: 30,
          data: [[incomeDetails.margin], [100 - incomeDetails.margin]],
        },
      ],
    });
  }
  overrideGating = () => {
    let { memberId, closeAction } = this.props;
    let dataToSend = {
      data: {
        customerId: memberId,
        gatingStatus: "Approved",
      },
    };
    POST(API_POST_OVERRIDE_GATING, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          closeAction();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    let { modalOpen, modalType, businessModal, gatingModalOpen, isEditable } = this.state,
      {
        promoterDetails,
        employeeDetails,
        monthlyExpenses,
        businessDetails,
        incomeDetails,
        investmentsAndFunds,
        memberLocation,
        gatingStatus,
        match,
      } = this.props;

    return (
      <>
        <div className="lead-manager-overview">
          <div className="manager-wrapper">
            <div className="content">
              <div className="container">
                <div className="container--left">
                  <div className="promoter-details shadow-box">
                    <div className="sub-heading"> Entity </div>
                    <h4 className="sub-heading-basic">{businessDetails?.businessName || " - "}</h4>
                    <div className={"row "}>
                      <div className={"col-sm-3  block "}>
                        Member ID
                        <div> {promoterDetails?.memberId || " - "}</div>
                      </div>
                      <div className={"col-sm-3  block "}>
                        State
                        <div> {promoterDetails?.state || " - "}</div>
                      </div>
                      <div className={"col-sm-3  block "}>
                        Cluster
                        <div> {promoterDetails?.cluster || " - "}</div>
                      </div>
                      <div className={"col-sm-3  block "}>
                        Onboarding Timestamp
                        <div> {promoterDetails?.timeStamp || " - "}</div>
                      </div>
                    </div>
                  </div>
                  <div className="promoter-details shadow-box">
                    <div className="sub-heading">Promoter Details</div>
                    <div className={"row "}>
                      <div className={"col-sm-4  block "}>
                        Business Owner
                        <div>{promoterDetails?.businessOwner || " - "}</div>
                      </div>
                      <div className={"col-sm-4  block"}>
                        PAN<div>{promoterDetails?.pan || " - "}</div>{" "}
                      </div>
                      <div className={"col-sm-4  block"}>
                        Mobile Number
                        <div>
                          {promoterDetails?.mobile || " - "}

                          {promoterDetails?.isMobileValidated === true ? (
                            <Icon className="icon-tick" icon={ic_check_circle} size="20" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-4  block "}>
                        Gender
                        <div>{promoterDetails?.gender || " - "}</div>
                      </div>
                      <div className={"col-sm-4  block"}>
                        DoB <div>{promoterDetails?.dob || " - "}</div>{" "}
                      </div>
                      <div className={"col-sm-4  block"}>
                        Business started by Self
                        {promoterDetails?.isBusinessSelfStarted === true ? <div>Yes</div> : <div>No</div>}
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-4  block "}>
                        Father’s Name
                        <div>{promoterDetails?.fatherName || " - "}</div>
                      </div>
                    </div>
                  </div>
                  <div className="income-details shadow-box">
                    <h4 className="sub-heading">Income Details</h4>
                    <div className={"row "}>
                      <div className={"col-sm-4  block "}>
                        Average sales per month
                        <div>₹ {rupeeCommaFormatter(incomeDetails?.averageSalesPerMonth) || "-"}</div>
                      </div>
                      <div className={"col-sm-4  block "}>
                        Margin
                        <div className="ring-wrap ">
                          <div className={"col-sm-4"} id="margin-value">
                            {` ${incomeDetails?.margin}%` || "-"}
                          </div>
                          <div className={"col-sm-6 "} id="ring"></div>
                        </div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-4  block "}>
                        Daily Production
                        <div>{rupeeCommaFormatter(incomeDetails?.dailyProduction) || "-"} units</div>
                      </div>
                      <div className={"col-sm-4  block "}>
                        Average Selling Price
                        <div>₹ {rupeeCommaFormatter(incomeDetails?.averageSellingPrice) || "-"}</div>
                      </div>
                      <div className={"col-sm-4  block "}>
                        Total Daily Sales
                        <div>₹ {rupeeCommaFormatter(incomeDetails?.totalDailySales) || "-"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="monthly-business shadow-box">
                    <div className="monthly-business-heading">
                      <h4>Monthly business income</h4>
                      <span> in Rupee</span>
                    </div>
                    <div id="bar-chart"></div>
                  </div>
                  <div className={"row box shadow-box"}>
                    <div className={"col-sm-6  block "}>
                      Monthly income from other sources
                      <div>₹ {rupeeCommaFormatter(incomeDetails?.monthlyIncomeDetails?.otherSourceIncome) || "-"}</div>
                    </div>
                    <div className={"col-sm-6  block "}>
                      Other income contributing members
                      <div>{incomeDetails?.monthlyIncomeDetails?.otherEarningMembers || "-"}</div>
                    </div>
                  </div>
                  <div className={"row  box-sub shadow-box"}>
                    <div className={"col-sm-6  block "}>
                      Total Income (Business+Other)
                      <div>₹ {rupeeCommaFormatter(incomeDetails?.monthlyIncomeDetails?.totalIncome) || "-"}</div>
                    </div>
                    <div className={"col-sm-6  block "}>
                      Business to Total Income ratio
                      <div>{incomeDetails?.monthlyIncomeDetails?.businessToIncomeRatio || "-"}%</div>
                    </div>
                  </div>
                </div>
                <div className="container--right">
                  {match.params.custId && (
                    <div className="gating-check shadow-box">
                      <div className={"row "}>
                        <div className={"col-sm-6"}>
                          <div className={"row black"}>Gating Check</div>
                          <div
                            className={"row ring "}
                            style={{
                              color:
                                gatingStatus === "Approved" ||
                                gatingStatus === "Auto Approved" ||
                                gatingStatus === "Auto Approved"
                                  ? " #00CDBE"
                                  : gatingStatus === "Pending" ||
                                    gatingStatus === "Service Down" ||
                                    gatingStatus === "NTC"
                                  ? " #969696"
                                  : gatingStatus === "Rejected" ||
                                    gatingStatus === "Auto Rejected" ||
                                    gatingStatus === "Auto Rejected"
                                  ? "#ED5A5A"
                                  : gatingStatus === "Refer"
                                  ? "#E7B93C"
                                  : "transparent",
                              backgroundColor:
                                gatingStatus === "Approved" ||
                                gatingStatus === "Auto Approved" ||
                                gatingStatus === "Auto Approved"
                                  ? "#E5FAF8"
                                  : gatingStatus === "Pending" ||
                                    gatingStatus === "Service Down" ||
                                    gatingStatus === "NTC"
                                  ? " #E4E4E4"
                                  : gatingStatus === "Rejected" ||
                                    gatingStatus === "Auto Rejected" ||
                                    gatingStatus === "Auto Rejected"
                                  ? "#F9EAEA"
                                  : gatingStatus === "Refer"
                                  ? "#FDFAF1"
                                  : "transparent",
                            }}
                          >
                            {gatingStatus || ""}
                          </div>
                        </div>
                        <div className={"col-sm-6"}>
                          <div className={"col-sm-6 icon1"}>
                            {gatingStatus === "Refer" ? (
                              <img
                                src={Gating_Override}
                                alt="action button"
                                className="gating-status__logo"
                                onClick={() => this.toggleGatingModal(GatingOverride)}
                              />
                            ) : null}
                          </div>
                          <div className={"col-sm-6 icon2"}>
                            <img
                              src={return_bureau}
                              alt="action button"
                              className="gating-status__logo"
                              onClick={() => this.toggleGatingModal(RerunBureau)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="fund-requirement shadow-box">
                    <h4> Fund Requirement</h4>
                    <div className=" fund-requirement--value">
                      ₹ {rupeeCommaFormatter(businessDetails?.loanRequired) || " - "}{" "}
                    </div>
                  </div>
                  <div className="business-details shadow-box">
                    <div className="business-details--heading">
                      <div className="sub--heading "> Business Details </div>
                      <div className="business-details--heading--logo">
                        <Expand onClick={() => this.toggleModal(OtherBusinessDetails)} />
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block "}>
                        GST Number
                        <div>
                          {businessDetails?.gstIn || " - "}
                          {businessDetails?.isGstValidated === true ? (
                            <Icon className="icon-tick" icon={ic_check_circle} size="20" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block "}>
                        PAN of Entity
                        <div>{businessDetails?.businessPan || " - "}</div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block "}>
                        Udyog Aadhar No.
                        <div>{businessDetails?.udhyogAadhar || " - "}</div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block  "}>
                        Operating states
                        <div>{businessDetails?.operatingStates || " - "}</div>
                      </div>
                    </div>

                    <div className={"row "}>
                      <div className={"col-sm-12  block "}>
                        Constitution of Entity
                        <div>{businessDetails?.constitution || " - "}</div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block "}>
                        Nature of Business
                        <div>{businessDetails?.businessNature || " - "}</div>
                      </div>
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12  block  m-bottom"}>
                        Commencement year
                        <div>{businessDetails?.commencementYear || " - "}</div>
                      </div>
                    </div>
                    <SlideDown closed={this.state.businessModal}>
                      <div className={"row "}>
                        <div className={"col-sm-12  block"}>
                          Vintage
                          <div>{businessDetails?.vintage || " - "}</div>
                        </div>
                      </div>

                      <div className={"row "}>
                        <div className={"col-sm-12  block  "}>
                          Supply for
                          <div>{businessDetails?.market || " - "}</div>
                        </div>
                      </div>
                      <div className={"row "}>
                        <div className={"col-sm-12  block "}>
                          Bureau score
                          <div> - </div>
                        </div>
                      </div>

                      <div className={"row "}>
                        <div className={"col-sm-12  block  m-bottom"}>
                          Required to grow
                          <div>{businessDetails?.businessGrowthRequirement || " - "}</div>
                        </div>
                      </div>
                    </SlideDown>
                    <div
                      onClick={() =>
                        this.setState((prevState) => ({
                          businessModal: !prevState.businessModal,
                        }))
                      }
                      className="show-more"
                    >
                      {businessModal === false ? (
                        <div>
                          SHOW LESS <Icon icon={smallUp} />
                        </div>
                      ) : (
                        <div>
                          SHOW MORE <Icon icon={smallDown} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className=" media-docs shadow-box" onClick={() => this.toggleModal(MediaDocuments)}>
                    <h4> Media & Docs </h4>
                    <div>
                      <img src={Group} alt="map" />
                    </div>
                  </div>
                  <div className=" media-docs shadow-box">
                    <h4> Unit Location(s) </h4>
                    {memberLocation?.latitude !== undefined && memberLocation?.longitude !== undefined ? (
                      <a
                        href={`https://www.google.co.in/maps?q=${memberLocation?.latitude},${memberLocation?.longitude} `}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Map} alt="group" />
                      </a>
                    ) : (
                      <div>
                        <div className="error">
                          Location not available
                          <br /> on map
                        </div>
                      </div>
                    )}
                  </div>
                  <div className=" media-docs shadow-box">
                    <div className="icon-location">
                      <Location />
                      <span>Addresses</span>
                    </div>
                    <div className="media-docs--icon">
                      <Expand onClick={() => this.toggleModal(BusinessAddress)} />
                    </div>
                  </div>
                  <div className=" media-docs shadow-box">
                    <div className="icon-bank">
                      <Bank />
                      <span>Bank accounts </span>
                    </div>

                    <div className="media-docs--icon">
                      <Expand onClick={() => this.toggleModal(BankAccountDetails)} />
                    </div>
                  </div>
                  <div className=" assets shadow-box">
                    <div className=" media-docs m-0" style={{ margin: "0px" }}>
                      <div className="icon-bag">
                        <Bag />
                        <span>Asset Ownership</span>
                      </div>
                      <div className="media-docs--icon">
                        <Expand onClick={() => this.toggleModal(AssetOwnership)} />
                      </div>
                    </div>
                    <div className="assets-base">
                      <div className="sub-text">Investment in Plants & Machinery</div>
                      <div className="value">
                        ₹ {rupeeCommaFormatter(investmentsAndFunds?.plantAndMachineryInvestment) || "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" monthly-expense shadow-box">
                <div className="sub-heading">Employee Details</div>
                <div className={"row "}>
                  <div className={"col-sm-7   "}>
                    <div className={"col-sm-6  p-left "}>
                      <div className={"row block"}>
                        Average monthly wage
                        <div>₹ {rupeeCommaFormatter(employeeDetails?.averageMonthlyWage) || "-"}</div>
                      </div>
                      <div className={" row block"}>
                        Monthly working days
                        <div>{employeeDetails?.workingDays || "-"} days</div>
                      </div>
                    </div>
                    <div className={"col-sm-6  block "}>
                      Employee Count
                      <div className={"row "}>
                        <div className={"col-sm-12 icon"}>
                          <span className="icon-blue">
                            <Icon icon={u1F311} size={13} />
                          </span>
                          Fixed wage employees
                          <span className="text-bold">{rupeeCommaFormatter(employeeDetails?.fixedWageEmployees)}</span>
                        </div>
                      </div>
                      <div className={"row border"}>
                        <div className={"col-sm-12 icon"}>
                          <span className="icon-green">
                            <Icon icon={u1F311} size={13} />
                          </span>
                          Daily wage employees
                          <span className="text-bold">{rupeeCommaFormatter(employeeDetails?.dailyWageEmployees)}</span>
                        </div>
                      </div>
                      <div className={"row "}>
                        <div className={"col-sm-12 icon"}>
                          Total
                          <span className="text-bold-sub">{rupeeCommaFormatter(employeeDetails?.totalEmployees)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"col-sm-4  "}>
                    <div id="large-round-chart"></div>{" "}
                  </div>
                </div>
              </div>
              <div className="investment-funds">
                <div className="investment-funds-container shadow-box">
                  <div className="sub-heading"> Parcha issued by Agent</div>
                  <div className={"row "}>
                    <div className={"col-sm-6  block "}>
                      Frequency
                      <div>{investmentsAndFunds?.discountOnPurchaseFrequency || "-"}</div>
                    </div>
                    <div className={"col-sm-6  block"}>
                      Interest paid <div>{investmentsAndFunds?.interestPaid || "-"}</div>{" "}
                    </div>
                  </div>
                </div>
                <div className="investment-funds-container shadow-box">
                  <div className="sub-heading">Borrowed from friends and relatives</div>
                  <div className="value">
                    ₹ {rupeeCommaFormatter(investmentsAndFunds?.fundsBorrowedFromFamilyAndFriends) || "-"}{" "}
                  </div>
                </div>
              </div>
              <div className="monthly-expense shadow-box">
                <h4 className="sub-heading">Monthly Expenses</h4>
                <div className={"row "}>
                  <div className={"col-sm-3  block "}>
                    Business Expenses
                    <div> ₹ {rupeeCommaFormatter(monthlyExpenses?.businessExpenses) || "-"}</div>
                  </div>
                  <div className={"col-sm-3  block"}>
                    Household Expenses <div>₹ {rupeeCommaFormatter(monthlyExpenses?.householdExpenses) || "-"}</div>{" "}
                  </div>
                  <div className={"col-sm-3  block"}>
                    Total Expenses <div>₹ {rupeeCommaFormatter(monthlyExpenses?.totalExpenses) || "-"}</div>
                  </div>
                  <div className={"col-sm-3  block"}>
                    Total monthly obligations{" "}
                    <div>₹{rupeeCommaFormatter(monthlyExpenses?.totalMonthlyObligations) || "-"}</div>
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-12  block "}>
                    Monthly Obligation Breakdown
                    <div id="single-line-chart"></div>
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-12  block "}>
                    Monthly obligation and expenses to income ratio
                    <div>{monthlyExpenses?.obligationAndExpensesToIncomeRatio} %</div>
                  </div>
                </div>
              </div>
              <div className="investment-funds">
                <div className="investment-funds-container shadow-box">
                  <div className="credit-heading">
                    <div className="main-heading"> Purchase credit cycle</div>
                    <span> in Days</span>
                  </div>
                  <div className={"row "}>
                    <div className={"col-sm-6 bold  "}>{investmentsAndFunds?.purchaseCreditCycle || "NAN"} days</div>

                    <div className={"col-sm-6 "} id="medium-round-chart-left"></div>
                  </div>
                </div>
                <div className="investment-funds-container shadow-box">
                  <div className="credit-heading">
                    <div className="main-heading"> Receivable credit cycle</div>
                    <span> in Days</span>
                  </div>
                  <div className={"row "}>
                    <div className={"col-sm-6  bold "}>{investmentsAndFunds?.receivableCreditCycle || "NAN"} days</div>
                    <div className={"col-sm-6 "} id="medium-round-chart-right"></div>
                  </div>
                </div>
              </div>
              <div className="investment-funds">
                <div className="investment-funds-container shadow-box">
                  <div className="sub-heading">Comments </div>
                  <div className={"row "}>
                    <div className={"col-sm-12 mt-2"}>
                      {promoterDetails?.comments && promoterDetails.comments[0]?.comment}
                    </div>
                  </div>
                </div>
                {match.params.custId && (
                  <div className="investment-funds-container shadow-box">
                    <div className="credit-heading">
                      <div className="main-heading"> Add Credit comments</div>
                      {isEditable === false ? (
                        <div className="edit-comments" onClick={(e) => this.inputHandler(e)}>
                          <Icon icon={ic_mode_edit} size={22} />
                        </div>
                      ) : (
                        <span className="save-comments" onClick={(e) => this.saveCommentsHandler(e)}>
                          save
                        </span>
                      )}
                    </div>
                    <div className={"row "}>
                      <div className={"col-sm-12 mt-2"}>
                        <div
                          className="text-area"
                          textareaplaceholder="  Write here... "
                          cols="30"
                          rows="6"
                          name="comment"
                          contentEditable={isEditable}
                          onInput={(e) =>
                            this.setState({
                              comment: e.currentTarget.textContent,
                            })
                          }
                        >
                          {promoterDetails?.comments && promoterDetails.comments[1]?.comment}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="comments shadow-box ">
                <h4 className="sub-heading">Comments</h4>
                <p>{promoterDetails?.comments} </p>
              </div> */}
            </div>
          </div>
        </div>
        <CredoModal
          isOpen={modalOpen}
          className={"lead-manager-modal normal-curved-borders red-flag__wrapper "}
          styles={{
            content: {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
              width: "50vw",
            },
          }}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={modalType}
          PropsData={this.props}
          stateData={this.state}
        />
        <CredoModal
          isOpen={gatingModalOpen}
          className={"lead-manager-modal red-flag__wrapper normal-curved-borders"}
          styles={{
            content: {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
              width: "400px",
            },
          }}
          closeAction={()=>this.toggleGatingModal("")}
          RenderingComponent={modalType}
          memberId={this.state?.custId}
        />
      </>
    );
  }
}

export default UnitAnalysis;
