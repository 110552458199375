import React, { Component } from "react";
import { rupeeCommaFormatter } from "../../../utils/utitlity";

export default class SalesByParty extends Component {
  render() {
    let { gstinAnalysis } = this.props,
      { annualAnalysis = {} } = gstinAnalysis || {},
      { counterPartyDetails = [] } = annualAnalysis;

    return (
      <div className="sales_form_container">
        <div className=" box-wrapper">
          <div className="box-header">
            <h4>Sales by Party</h4>
          </div>
          {counterPartyDetails?.length ? (
            <div className={"table-box"}>
              <table>
                <thead>
                  <tr>
                    <th>S.no </th>
                    <th>Party </th>
                    <th>GST</th>
                    <th>Invoices</th>
                    <th>Avg. monthly fig</th>
                    {counterPartyDetails[0]?.monthwiseInvoiceDetails?.map(
                      (e) => {
                        return <th>{e?.month.replace("-", "  ")}</th>;
                      }
                    )}
                  </tr>
                </thead>
                <tbody>
                  {counterPartyDetails?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <td className=" sub_column">{index + 1} </td>

                          <td className=" sub_column">{` ${
                            item?.businessName || ""
                          }`}</td>
                          <td className=" sub_column">{item?.gstn}</td>
                          <td>
                            <div className="border_bottom sub_column">
                              <b>Amount</b>
                            </div>
                            <div className="sub_column">
                              <b>Count</b>
                            </div>
                          </td>
                          <td>
                            <div className="border_bottom sub_column">
                              ₹{" "}
                              {rupeeCommaFormatter(item?.avgInvoiceAmount) ||
                                "-"}
                            </div>
                            <div className="sub_column">
                              &nbsp;&nbsp;&nbsp;
                              {rupeeCommaFormatter(item?.avgInvoiceCount) ||
                                "-"}
                            </div>
                          </td>
                          {item?.monthwiseInvoiceDetails?.map((e) => {
                            return (
                              <>
                                <td>
                                  <div className="border_bottom sub_column">
                                    ₹{" "}
                                    {rupeeCommaFormatter(e?.invoiceAmount) ||
                                      "-"}
                                  </div>
                                  <div className="sub_column">
                                    &nbsp;&nbsp;&nbsp;
                                    {rupeeCommaFormatter(e?.invoiceCount) ||
                                      "-"}
                                  </div>
                                </td>
                              </>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            "No Data Available"
          )}
        </div>
      </div>
    );
  }
}
