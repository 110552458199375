import React, { Component } from "react";
import { GET } from "../../../utils/webAPI.service";
import { API_GET_REMOVE_ACCOUNT } from "../../../utils/APIUrls";

export default class deleteAccount extends Component {
  deleteAccountHandler = async (accountNumber, applicationNumber) => {
    let { actions } = this.props,
      { getExceptionData, loaderModal } = actions;
    loaderModal(true);
    await GET(API_GET_REMOVE_ACCOUNT(applicationNumber, accountNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          getExceptionData(applicationNumber);
          this.props.closeAction();
          window.location.reload();
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  render() {
    let { accountNumber, match } = this.props,
      { appNo } = match.params;
    return (
      <div>
        <div className="deleteStatement mt-3">
          Do you want to delete the Account no {accountNumber} and all documents related to it?
        </div>
        <div className="btn_delete mt-3 ">
          <button
            className="button_Generic blue-btn bolder-text"
            onClick={() => this.deleteAccountHandler(accountNumber, appNo)}
          >
            Yes, delete
          </button>
        </div>
      </div>
    );
  }
}
