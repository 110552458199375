const UPDATE_ROLE_STATUS = Symbol("updateRoleStatus");
const Add_USER_ADMIN_PANEL = Symbol("Add User Admin");
const FETCH_USER_DETAILS = Symbol("FetchUserDetails");
const SEND_MESSAGE = Symbol("SendMessage"),
  GET_ALL_THRESHOLDS = Symbol("getAllThresholds"),
  GET_ACTIVE_LENDER_ROLES = Symbol("getActiveLenderRoles"),
  GET_ALL_LENDERS_ROLES = Symbol("getAllLenderRoles"),
  GET_ROLE_STATUS = Symbol("getRoleStatus"),
  GET_HIERARCHY_LEVEL=Symbol("getHierarchyLevel"),
  GET_USER_BY_LEVEL=Symbol("getUserByLevel");
export {
  UPDATE_ROLE_STATUS,
  Add_USER_ADMIN_PANEL,
  FETCH_USER_DETAILS,
  SEND_MESSAGE,
  GET_ALL_THRESHOLDS,
  GET_ACTIVE_LENDER_ROLES,
  GET_ALL_LENDERS_ROLES,
  GET_ROLE_STATUS,
  GET_HIERARCHY_LEVEL,
  GET_USER_BY_LEVEL
};
