import { GET, POST } from "../utils/webAPI.service";
import {
  API_GET_USER_DETAILS, API_DASHBOARD_FETCH_DATA,
} from "../utils/APIUrls";

export function loaderModal(val) {
  return {
    type: "SET_LOADER_MODAL_STATUS",
    val
  };
}

export function setUserDetails(val) {
  return {
    type: "SET_USER_DETAILS",
    val
  };
}

export function setAuthenticationStatus(val) {
  return {
    type: "SET_AUTHENTICATION_STATUS",
    val
  };
}

export function setUserLoginStatus(val) {
  return {
    type: "SET_USER_LOGIN_STATUS",
    val
  };
}

export function setRedirectURL(val) {
  return {
    type: "SET_REDIRECT_URL",
    val
  };
}
export const updateErrorMessage = error => ({
  type: "SET_ERROR_MESSAGE",
  error
});
export const fetchDashBoardData = data => dispatch => {
  POST(API_DASHBOARD_FETCH_DATA, data )
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: data.data });
      } else {
        dispatch({ type: "DASHBOARD_DATA", dashboardData: [] });
      }
    })
    .catch(err => {
      throw err;
    });
};
export function checkUserLogin() {
  return dispatch => {
    return GET(API_GET_USER_DETAILS)
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch(setUserDetails(data.data));
          dispatch(setUserLoginStatus(true));
        } else {
          dispatch(setUserLoginStatus(false));
        }
        dispatch(setAuthenticationStatus(true));
        return data;
      })
      .catch(error => {
        return error;
      });
  };
}
