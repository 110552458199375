import React, { Component } from "react";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { circle_plus } from "react-icons-kit/ikons/circle_plus";
import { loaderModal } from "../../../store/actions";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { GET } from "../../../utils/webAPI.service";
import { API_GET_APPLICATION_NUMBER } from "../../../utils/APIUrls";
import { Link } from "react-router-dom";
class PostLogin extends Component {
  state = {
    error: "",
  };
  componentWillMount() {
    window.scroll(0, 0);
  }

  addNewApplication = () => {
    const { history, match } = this.props,
      custId = match.params.custId || null;
    GET(API_GET_APPLICATION_NUMBER(custId))
      .then(({ data }) => {
        if (data.status === "success") {
          document.cookie = `applicationNumber=${data.data}; path=/Sales`;
          history.push(`/Sales/${data.data}/sourcingDetails`);
        }
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
      });
  };

  render() {
    return (
      <div className={"post-login-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Post Login</title>
        </Helmet>
        <div className={"content-box"}>
           <Link to={"/Sales/dashboard"} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          {/* <div className={"back-btn-link"} onClick={() => this.props.history.goBack()}>
            <Icon icon={angleLeft} size={24} />
            Back
          </div> */}
          <div className={"btns-box"}>
            <button type={"button"} onClick={this.addNewApplication}>
              <span>
                <Icon icon={circle_plus} size={20} />
              </span>
              <span>Start New Application</span>
            </button>
          </div>
          <div className={"row videos-preview-box"}>
            <div className={"col-sm-12 col-md-6 col-sm-12  list-item"}>
              <label>Check if you’ve enabled APIs access in your GSTN</label>
              <div className={"video-box"}>
                <img src={require("../../../assets/images/video_preview.JPG")} alt={"video_preview"} />
              </div>
            </div>
            <div className={"col-sm-12 col-md-6 list-item"}>
              <label>Check if you’ve access to your GST OTP</label>
              <div className={"video-box"}>
                <img src={require("../../../assets/images/video_preview.JPG")} alt={"video_preview"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data }) => {
  return {
    loaderModalStatus: data.loaderModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loaderModal: (val) => dispatch(loaderModal(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostLogin);
