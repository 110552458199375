import React, { Component } from "react";
import CaseAgentDetail from "../NewCpoPanel/MainViews/caseAgentDetails";
import Header from "../../components/Header/Header";
import GSTException from "../NewCpoPanel/MainViews/gstException";
import CibilException from "../NewCpoPanel/MainViews/cibilException";
import BankingExceptions from "../NewCpoPanel/MainViews/bankingExceptions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getExceptionData,
  editGSTExceptionData,
  getOPSUserApplication,
  addAccountBankException,
  triggerCibil,
  editCibilExceptionData,
  uploadBankingFiles,
} from "./newCpoPanel.actions";
import { loaderModal } from "../../store/actions";
import { LoaderModal } from "../../components";
import Documents from "../NewCpoPanel/Documents/Documents";
import GSTNDetails from "./GSTNDetailsPage/GSTNDetails";

class NewCpoPanel extends Component {
  render() {
    return (
      <>
        <Header {...this.props} />
        <LoaderModal status={this.props.loaderModalStatus} />
        <div className="container">
          <CaseAgentDetail {...this.props} />
          <GSTNDetails {...this.props} />
          <Documents {...this.props} />
          <GSTException {...this.props} />
          <CibilException {...this.props} />
          <BankingExceptions {...this.props} />
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    let { actions, match } = this.props,
      { appNo } = match.params,
      { getExceptionData } = actions;

    loaderModal(true);
    await getExceptionData(appNo);
    loaderModal(false);
  };
}

const mapStateToProps = ({ CPOUserReducer, data }) => ({
  userExceptionData: CPOUserReducer.userExceptionData,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getExceptionData,
      loaderModal,
      editGSTExceptionData,
      editCibilExceptionData,
      getOPSUserApplication,
      addAccountBankException,
      triggerCibil,
      uploadBankingFiles,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCpoPanel);
