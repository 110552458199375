import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import { DropdownButton, MenuItem } from "react-bootstrap";
import PdfLogo from "../../../assets/images/pdf.svg";
import PlusLogo from "../../../assets/images/plusSign.svg";
import MinusSign from "../../../assets/images/minusSign.svg";
import { getCookie } from "../../../utils/utitlity";
import axios from "axios";
import { loaderModal } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE_URL } from "../../../utils/webAPI.service";

class DocumentBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      typeIndex: 1,
      fileName: "",
      test: [],
    };
  }

  showName = async (e, data) => {
    var i = this.state.typeIndex;
    let id = data.id;
    let isBusinessDoc = data.isBusinessDoc;
    let isProfileDocs = data.isProfileDocs;
    let docType = data.labelHead;
    let docName = data.type[i - 1];
    var files = e.target.files;
    let token = getCookie("token");
    let { loaderModal } = this.props.actions;
    let applicationNumber = this.props.match.params.appNo;
    const baseUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}`;

    const headers = {
      tokenId: token,
    };
    let formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }

    loaderModal(true);
    if (isBusinessDoc && files?.length > 0) {
      axios
        .post(
          `${baseUrl}/uploadBusinessDocument/${applicationNumber}/${docName}/${docType}/${id}`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          if (data.status === "success") {
            this.props.refreshPage();
          } else {
            alert(data.message);
          }
          loaderModal(false);
        })
        .catch((err) => {
          loaderModal(false);
          throw err;
        });
    } else if (isProfileDocs && files?.length > 0) {
      axios
        .post(
          `${baseUrl}/uploadProfileDocument/${applicationNumber}/${docName}/${docType}/${id}`,
          formData,
          {
            headers,
          }
        )
        .then(({ data }) => {
          if (data.status === "success") {
            this.props.refreshPage();
          } else {
            alert(data.message);
          }
          loaderModal(false);
        })
        .catch((err) => {
          loaderModal(false);
          throw err;
        });
    } else {
      alert("File not uploaded");
      loaderModal(false);
    }

    var fileName = document.getElementById(`form${this.props.uniqueId}`);
    for (var j = 0; j < fileName.files.length; ++j) {
      var name = fileName?.files?.item(j)?.name;
      await this.setState({ test: [...this.state.test, name] });
    }
    if (fileName?.files?.item(0)?.size) {
      this.setState({ fileName: fileName.files.item(0).name });
    }
  };
  selectType = (i) => {
    let index = this.state.typeIndex;
    if (index !== i) {
      this.setState({ typeIndex: i });
    }
  };
  deleteDocumentHandler = (docId) => {
    this.props.deleteDocument(docId);
  };
  render() {
    let { labelHead, type, documentName, uniqueId } = this.props,
      { typeIndex } = this.state,
      allDocuments = documentName;
    return (
      <div className={"document-box"} id={uniqueId}>
        <div className={"label1"}>
          <p>{labelHead}</p>
        </div>
        <DropdownButton
          onSelect={this.selectType}
          title={
            type[typeIndex - 1].length > 35
              ? type[typeIndex - 1].substr(0, 35) + "..."
              : type[typeIndex - 1]
          }
          id={"type-select"}
          className={"select-box"}
          style={{ whiteSpace: "unset", alignItems: "left" }}
        >
          {type.map((item, index) => {
            return (
              <MenuItem title={item} key={index + 1} eventKey={index + 1}>
                {item}
              </MenuItem>
            );
          })}
        </DropdownButton>
        <Fragment>
          <label
            htmlFor={`form${this.props.uniqueId}`}
            className="custom-file-upload btn upload-button"
          >
            <img
              src={PlusLogo}
              style={{ margin: "0% 6% 0% 5%" }}
              alt={"PlusLogo"}
            />
            Upload Document
          </label>
          <input
            id={`form${this.props.uniqueId}`}
            type="file"
            onChange={(e) => this.showName(e, this.props)}
            multiple
          />
          <div className="fileContainer">
            {allDocuments
              ?.filter((ele) => ele.docType === labelHead)
              .map((el, key) => {
                return (
                  <Fragment key={key}>
                    <div className="file-wrapper">
                      <img
                        src={PdfLogo}
                        alt="Logo"
                        style={{ width: "15%", margin: "0px 3px" }}
                      />
                      <div style={{ margin: "auto" }}>
                        {el.fileName.slice(0, 5)}
                      </div>
                      <img
                        src={MinusSign}
                        onClick={() => this.deleteDocumentHandler(el.docId)}
                        style={{
                          display: "inline-block",
                          height: "20px",
                          cursor: "pointer",
                        }}
                        alt={"MinusSign"}
                      />
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </Fragment>
      </div>
    );
  }
}

DocumentBox.propTypes = {
  name: PropTypes.string.isRequired,
  labelHead: PropTypes.string.isRequired,
  type: PropTypes.array.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(DocumentBox);
