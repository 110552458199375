import React, { Fragment, Component } from "react";
import { ic_picture_as_pdf } from "react-icons-kit/md/ic_picture_as_pdf";
import { upload } from "react-icons-kit/fa/upload";
import { download } from "react-icons-kit/fa/download";
import unArchiveImage from "../../../../assets/images/unArchive.svg";
import archiveImage from "../../../../assets/images/archive.svg";
import downloadIcon from "../../../../assets/images/downloadIcon.png";
import preview from "../../../../assets/images/preview.png";
import { ic_refresh } from "react-icons-kit/md/ic_refresh";
import Icon from "react-icons-kit";
import { Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { goTo, getCookie } from "../../../../utils/utitlity";
import { GET, POST } from "../../../../utils/webAPI.service";
import { loaderModal } from "../../../../store/actions";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { CredoModal } from "../../../../components";
import { getSalesDocumentList } from "../finalDecision.action";
import { get } from "lodash";
import { PreviewModal } from "../PreviewModal";
import GSTTurnover from "../../../Sales/GstnUpdatedAddress/GSTTurnover";
import fileDownload from "js-file-download";
import { API_DOC_PREVIEW } from "../../../../utils/APIUrls";
class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DocumentList: [],
      DocumentHeadings: [],
      DocumentsArray: [],
      coApplicantTabs: [],
      archivedDocumentList: [],
      ArchiveDocumentHeadings: [],
      modalOpen: false,
      modalType: null,
    };
  }

  fetchDetails = async () => {
    let appNo = this.props.props.match.params.appNo;
    await GET(`/fetchDocumentsList/${appNo}`).then(({ data, status }) => {
      if (data.data) {
        this.setState({
          businessDocuments: data.data.businessDocuments,
          profileDocuments: data.data.profileDocuments,
        });
      } else {
        this.setState({ businessDocuments: {}, profileDocuments: [] });
      }
    });
    await GET(`/getArchiveDocuments/${appNo}`).then(({ data, status }) => {
      if (data.data && data.data.documents?.length !== 0) {
        this.setState({ archivedDocumentList: data.data.documents });
      } else {
        this.setState({ archivedDocumentList: [] });
      }
    });
  };
  archiveDocs = (docId, status) => {
    let { actions } = this.props,
      { loaderModal } = actions;
    var data = {
      docId,
      status,
    };
    loaderModal(true);
    POST(`/archiveDocument`, { ...data }).then(({ data, status }) => {
      alert(data.message);
      loaderModal(false);
    });
  };
  uploadFiles = (e, docType, entity) => {
    let applicationNumber = this.props.props.match.params.appNo;
    var files = e.target.files;
    let token = getCookie("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: token,
    };
    let formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("files", files[file]);
      } else {
        break;
      }
    }
    let { actions } = this.props,
      { loaderModal } = actions;
    loaderModal(true);
    POST(
      `/uploadDocument/${applicationNumber}/null/${docType}/${entity}`,
      formData,
      {
        headers,
      }
    ).then(({ data, status }) => {
      alert(data.message);
      loaderModal(false);
    });
  };

  toggleModal = (name, docId, fileName) => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
      modalType: name,
      docId,
      fileName,
    }));
  };

  downloadFile = async (docId, fileName) => {
    let token = getCookie("token");
    const headers = {
      tokenId: token,
    };
    let { actions } = this.props,
      { loaderModal } = actions;
    loaderModal(true);

    axios({
      url: API_DOC_PREVIEW(docId),
      method: "GET",
      headers: headers,
      responseType: "blob", // Important
    }).then(async (res) => {
      let {
        config: { url },
      } = res;
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        fileDownload(blob, ` ${fileName}`);
        loaderModal(false);
      } catch (error) {
        console.log(error);
        loaderModal(false);
      }
    });
  };

  view = () => {
    let appNo = this.props.props.match.params.appNo;
    window.open(`/cam/${appNo}`);
  };
  render() {
    let {
      businessDocuments,
      profileDocuments = [],
      modalOpen,
      modalType,
      docId,
      fileName,
    } = this.state;
    let appNo = this.props.props.match.params.appNo;
    let { actions, history, props, salesDocument } = this.props;
    let { match } = props;
    let applicationNumber = get(match, "params.appNo", "");
    let businessSalesDocument = get(
      salesDocument,
      "businessDocuments.documentResponse",
      []
    );
    const decisionsColumns = [
      {
        Header: "Doc Type",
        accessor: "docName",
        width: 300,
      },
      {
        Header: "Files",
        accessor: "fileName",
      },
      {
        Header: "Download",
        accessor: "date",
        width: 100,
        Cell: ({ original }) => {
          let { docId, fileName } = original;
          return (
            <div onClick={() => this.downloadFile(docId, fileName)}>
              <img
                src={downloadIcon}
                style={{
                  height: "1.8rem",
                }}
              />
            </div>
          );
        },
      },
      {
        Header: "Preview",
        accessor: "date",
        width: 100,
        Cell: ({ original }) => {
          let { docId, fileName } = original;
          return (
            <div>
              <img
                style={{
                  height: "2.2rem",
                }}
                src={preview}
                onClick={() =>
                  this.toggleModal("PreviewModal", docId, fileName)
                }
              />
            </div>
          );
        },
      },
    ];

    return (
      <Fragment>
        <Tabs
          defaultActiveKey={"entityDoc"}
          id="adminTabs"
          onSelect={this.selectedTab}
        >
          <button onClick={() => this.fetchDetails()} className="refresh-btn">
            <span>
              <Icon icon={ic_refresh} />
            </span>
            Refresh
          </button>
          <Tab eventKey={"entityDoc"} title={"Entity Docs"}>
            <div className={"docsNameHeader"}>
              <div>{businessDocuments?.businessName}</div>
              <div></div>
            </div>
            <div>
              <ReactTable
                data={businessSalesDocument || []}
                columns={decisionsColumns}
                resizable={false}
                NoDataComponent={() => null}
                showPagination={false}
                minRows={0}
              />
            </div>
          </Tab>
          <Tab eventKey={"applicantDoc"} title={"Applicant Docs"}>
            {profileDocuments?.map((applicant, index) => {
              return (
                <>
                  <div className={"docsNameHeader"}>
                    <div>
                      {applicant?.profileType} : {applicant?.name}
                    </div>
                  </div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>Document Type</th>
                          <th style={{ minWidth: "300px" }}>Files</th>
                          <th>
                            Download
                          </th>
                          <th>
                            Preview
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {applicant?.documentResponse?.map(
                          (applicantDocument, i) => {
                            return (
                              <tr>
                                <td>
                                  {applicantDocument?.docType} :{" "}
                                  {applicantDocument?.docName}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div className="doc-wrapper">
                                    <span style={{ padding: "0.1em" }}>
                                      <Icon
                                        icon={ic_picture_as_pdf}
                                        className="pdf-icon"
                                      />
                                      {applicantDocument?.fileName}
                                    </span>
                                    <img
                                      src={archiveImage}
                                      alt="archive"
                                      className="archive-file"
                                      onClick={() => {
                                        this.archiveDocs(
                                          applicantDocument?.docId,
                                          true
                                        );
                                      }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div onClick={() => this.downloadFile(applicantDocument?.docId, applicantDocument?.fileName)}>
                                    <img
                                      src={downloadIcon}
                                      style={{
                                        height: "1.8rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <img
                                      style={{
                                        height: "2.2rem",
                                        cursor: "pointer",
                                      }}
                                      src={preview}
                                      onClick={() =>
                                        this.toggleModal("PreviewModal", applicantDocument?.docId, applicantDocument?.fileName)
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              );
            })}
          </Tab>

          {/* <Tab eventKey={"archivedDoc"} title={"Archived Docs"}>
            <Tabs
              defaultActiveKey={"archivedEntityDoc"}
              id="adminTabs"
              onSelect={this.selectedTab}
              style={{ marginTop: "-9px" }}
            >
              <Tab
                eventKey={"archivedEntityDoc"}
                title={"Archived Entity Docs"}
              >
                <div className={"docsNameHeader"}></div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th style={{ minWidth: "300px" }}>Files</th>
                        <th>
                          Download
                          <a
                            style={{
                              cursor: "pointer",
                              marginLeft: 10,
                            }}
                          >
                            <Icon icon={download} size={20} />
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="doc-wrapper">
                            <span style={{ padding: "0.1em" }}>
                              <Icon
                                icon={ic_picture_as_pdf}
                                className="pdf-icon"
                              />
                            </span>
                            <img
                              src={unArchiveImage}
                              alt="archive"
                              className="archive-file"
                            />
                          </div>
                        </td>
                        <td>
                          <a>
                            <Icon icon={download} size={20} />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab
                eventKey={"archivedApplicantDoc"}
                title={"Archived Applicant Docs"}
              >
                <div className={"docsNameHeader"}></div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th style={{ minWidth: "300px" }}>Files</th>
                        <th>
                          Download
                          <a
                            style={{
                              cursor: "pointer",
                              marginLeft: 10,
                            }}
                          >
                            <Icon icon={download} size={20} />
                          </a>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexWrap: "wrap",
                          }}
                        >
                          <div className="doc-wrapper">
                            <span style={{ padding: "0.1em" }}>
                              <Icon
                                icon={ic_picture_as_pdf}
                                className="pdf-icon"
                              />
                            </span>
                            <img
                              src={unArchiveImage}
                              alt="archive"
                              className="archive-file"
                            />
                          </div>
                        </td>
                        <td>
                          <a>
                            <Icon icon={download} size={20} />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Tab>
            </Tabs>
          </Tab> */}

          {/* <Tab eventKey={"archivedDoc"} title={"Archived Docs"}>
            <Tabs
              defaultActiveKey={"archivedEntityDoc"}
              id="adminTabs"
              onSelect={this.selectedTab}
              style={{ marginTop: "-9px" }}
            >
              <Tab
                eventKey={"archivedEntityDoc"}
                title={"Archived Entity Docs"}
              >
                <div className={"docsNameHeader"}>
                  <div>ArchiveDocumentHeadings</div>
                  <div></div>
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th style={{ minWidth: "300px" }}>Files</th>
                        <th>Download All</th>
                      </tr>
                    </thead>
                    <tbody>
                    
                              <tr>
                                <td>jnjnjnd</td>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap"
                                  }}
                                >
                                  
                                        <div className="doc-wrapper">
                                          <span style={{ padding: "0.1em" }}>
                                            <Icon
                                              icon={ic_picture_as_pdf}
                                              className="pdf-icon"
                                            />
                                            abc  
                                        </span>
                                          <img
                                            src={unArchiveImage}
                                            alt="archive"
                                            className="archive-file"
                                            // onClick={() => {
                                            //   this.archiveDocs(el.docId, false);
                                            // }}
                                          />
                                        </div>
                                   
                                </td>
                                <td>
                                  <a
                                    download
                                    // href={`/Backend/downloadMultipleDocuments/${
                                    //   ArchiveDocumentHeadings[0]
                                    // }/${appNo}/${el}/false`}
                                  >
                                    <Icon icon={download} size={20} />
                                  </a>
                                </td>
                              </tr>
                            
                    </tbody>
                  </table>
                </div>
              </Tab>
              <Tab
                eventKey={"archivedApplicantDoc"}
                title={"Archived Applicant Docs"}
              >
                <div className={"docsNameHeader"}>
                  ArchiveDocumentHeadings 1
                </div>
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Type</th>
                        <th style={{ minWidth: "300px" }}>Files</th>
                        <th>Download All</th>
                      </tr>
                    </thead>
                    <tbody>
                      
                            
                              <tr >
                                <td>njnd</td>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    flexWrap: "wrap"
                                  }}
                                >
                                
                                        <div className="doc-wrapper">
                                          <span style={{ padding: "0.1em" }}>
                                            <Icon
                                              icon={ic_picture_as_pdf}
                                              className="pdf-icon"
                                            />
                                            bcd 
                                         </span>
                                          <img
                                            src={unArchiveImage}
                                            alt="archive"
                                            className="archive-file"
                                            // onClick={() => {
                                            //   this.archiveDocs(el.docId, false);
                                            // }}
                                          />
                                        </div>
                                      
                                </td>
                                <td>
                                  <a
                                    download
                                    href={`/Backend/downloadMultipleDocuments/${
                                      ArchiveDocumentHeadings[1]
                                    }/${appNo}/${el}/false`}
                                  >
                                    <Icon icon={download} size={20} />
                                  </a>
                                </td>
                              </tr>
                       
                    </tbody>
                  </table>
                </div>
              </Tab>

            </Tabs>
          </Tab> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button onClick={this.view} className="refresh-btn mtl">
              VIEW CAM
            </button>
          </div>
        </Tabs>
        <CredoModal
          isOpen={modalOpen}
          styles={{
            content: {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
              height: "95vh",
              width: "80%",
            },
          }}
          docId={docId}
          fileName={fileName}
          closeAction={this.toggleModal}
          RenderingComponent={PreviewModal}
        />
      </Fragment>
    );
  }
  componentDidMount = async () => {
    await this.fetchDetails();
    let { actions, history, props, salesDocument } = this.props;
    let { match } = props;
    let applicationNumber = get(match, "params.appNo", "");
    let DocumentHeadings = Object.keys(this.state.DocumentList || {});
    DocumentHeadings.map((el, index) => {
      index >= 2 &&
        this.setState({ coApplicantTabs: [...this.state.coApplicantTabs, 1] });
      return null;
    });
    await actions.getSalesDocumentList(applicationNumber);
  };
}

const mapStateToProps = ({ finalDecision }) => ({
  salesDocument: finalDecision.salesDocument,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getSalesDocumentList,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Document);
