import { API_GET_ENTITY_DOCUMENTS, API_GET_DOCUMENTS } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as PhotoConstant from "./Photo.actions.constants";
const getEntityDocs = () => async (dispatch) => {
  try {
    const { data } = await GET(API_GET_ENTITY_DOCUMENTS);
    if (data.data) {
      dispatch({ type: PhotoConstant.FETCH_ENTITY_DOCUMENTS, data: data.data });
    }
  } catch (err) {
    throw err;
  }
};

const getDocuments = (custId) => (dispatch) => {
  return GET(API_GET_DOCUMENTS(custId))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: PhotoConstant.FETCH_BANK_ACCOUNTS, data: data.data });
      }
    })
    .catch((err) => {
      throw err;
    });
};

const fetchUserLocation = () => (dispatch) => {
  return navigator.geolocation.getCurrentPosition(function (position) {
    let lat = position.coords.latitude;
    let long = position.coords.longitude;
    if (lat && long) {
      dispatch({ type: PhotoConstant.USER_LOCATION, data: { lat, long } });
    }
  });
};
const resetPhotoState = () => ({ type: PhotoConstant.RESET_REDUX_STATE });
export { getEntityDocs, getDocuments, fetchUserLocation, resetPhotoState };
