import React, { Component, Fragment } from 'react';
import { API_SIGNUP } from '../../utils/APIUrls';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import axios from 'axios';
import { Header, InputLabelAnimation, LoaderModal } from '../../components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loaderModal } from '../../store/actions';
import { bindActionCreators } from 'redux';
import { alphaNumericRegex } from '../../utils/utitlity';
import Icon from 'react-icons-kit';
import { info } from 'react-icons-kit/icomoon/info';
import { ErrorMessage } from '../../components/ErrorMessage/ErrorMessage';
import { BASE_URL } from '../../utils/webAPI.service';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpAs: 1,
      signBy: [
        'Sign Up as Bank RM',
        'Sign Up as Borrower',
        'Sign Up as CA/Channel Partner',
      ],
      email: '',
      password: '',
      cPassword: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      pan: '',
    };
  }

  inputHandler = (e) => {
    let { value, name } = e.target,
      reg = /^[0-9]*$/;
    switch (name) {
      case 'pan':
        this.setState({ pan: value });
        break;
      case 'first_name':
        this.setState({ firstName: value });
        break;
      case 'middle_name':
        this.setState({ middleName: value });
        break;
      case 'last_name':
        this.setState({ lastName: value });
        break;
      case 'email':
        this.setState({ email: value });
        break;
      case 'password':
        this.setState({ password: value });
        break;
      case 'cPassword':
        this.setState({ cPassword: value });
        break;
      default:
        if (reg.test(value)) {
          this.setState({ phone: value });
        }
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    let { history, actions } = this.props,
      { loaderModal } = actions,
      { email, password, cPassword, phone, firstName, middleName, lastName } =
        this.state;
    const headers = { 'Content-Type': 'application/json' };
    if (password === cPassword) {
      if (password.length > 8) {
        if (alphaNumericRegex.test(password)) {
          loaderModal(true);
          let data = {
            email: email,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            mobile: phone,
            password: password,
            roleName: 'AGENT',
          };
          const baseUrl = `${process.env.REACT_APP_BASE_URL}${BASE_URL}`;
          axios
            .post(`${baseUrl}${API_SIGNUP}`, { data }, { headers })
            .then(({ data }) => {
              loaderModal(false);
              alert(data.message);
              if (data.status === 'success') {
                history.push('/login');
              }
            })
            .catch((err) => {
              loaderModal(false);
              alert('Something went wrong please try again later.');
            });
        } else {
          this.setState({
            errorMessage: 'Minimum 8 characters required for password.',
          });
        }
      } else {
        this.setState({
          errorMessage:
            'Password should be combination of at least one alphabet, number and special character',
        });
      }
    } else {
      this.setState({ errorMessage: 'Passwords does not match.' });
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { isLogin, history } = this.props;
    isLogin && history.push('/Sales/dashboard');
  }

  componentDidMount() {
    this.passwordPopover.click();
  }

  render() {
    const { loaderModalStatus } = this.props,
      { firstName, middleName, lastName, phone, email, password, cPassword } =
        this.state;
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <Header {...this.props} />
        <div className={'content-wrapper signup-wrapper'}>
          <div className={'content-box'}>
            <div className={'box-header'}>
              <h2 className={'heading'}>Sign Up as Agent</h2>
              <OverlayTrigger
                trigger='click'
                placement={'left'}
                rootClose
                overlay={
                  <Popover
                    id='password-popover'
                    positionLeft={200}
                    positionTop={50}
                    title='PASSWORD STRENGTH'
                  >
                    <ul className={'list-unstyled'}>
                      <li>1. At least 8 characters</li>
                      <li>2. Password always start with alphabet</li>
                      <li>3. At least 1 alphabet (a-z, A-Z)</li>
                      <li>4. At least 1 number (0-9)</li>
                      <li>5. At least 1 special character(@,#,$,%)</li>
                    </ul>
                    <b>Example:</b> <span>asd123@#</span>
                  </Popover>
                }
              >
                <span ref={(ref) => (this.passwordPopover = ref)}>
                  <Icon icon={info} size={20} />
                </span>
              </OverlayTrigger>
            </div>
            <form onSubmit={this.submitForm} className={'form-box'}>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <InputLabelAnimation
                    labelText={'First Name'}
                    name={'first_name'}
                    onChange={this.inputHandler}
                    tabIndex={1}
                    inputValue={firstName}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={'Middle Name'}
                    name={'middle_name'}
                    onChange={this.inputHandler}
                    tabIndex={2}
                    inputValue={middleName}
                  />
                  <InputLabelAnimation
                    labelText={'Last Name'}
                    name={'last_name'}
                    onChange={this.inputHandler}
                    tabIndex={3}
                    inputValue={lastName}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={'Mobile Number'}
                    name={'phone'}
                    tabIndex={4}
                    onChange={this.inputHandler}
                    minLength={10}
                    maxLength={10}
                    inputValue={phone}
                    required={true}
                  />
                </div>
                <div className={'col-md-6'}>
                  <InputLabelAnimation
                    labelText={'E-mail Address'}
                    name={'email'}
                    onChange={this.inputHandler}
                    type={'email'}
                    tabIndex={5}
                    inputValue={email}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={'Password'}
                    name={'password'}
                    tabIndex={6}
                    onChange={this.inputHandler}
                    type={'password'}
                    inputValue={password}
                    required={true}
                  />
                  <InputLabelAnimation
                    labelText={'Confirm Password'}
                    name={'cPassword'}
                    onChange={this.inputHandler}
                    type={'password'}
                    tabIndex={7}
                    inputValue={cPassword}
                    required={true}
                  />
                </div>
                <div className={'col-md-6 col-md-offset-6'}>
                  <div className={'actions-box'}>
                    {this.state.errorMessage ? (
                      <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
                    ) : null}
                    <button tabIndex={8} type={'submit'}>
                      Sign Up
                    </button>
                    <Link to={'/login'} className={'primary-link'}>
                      Already have an account? Login.
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className={'content-box'}><img src={require("../../assets/images/laptop.svg")} alt={"laptop_img"} /></div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  isLogin: data.isLogin,
  loaderModalStatus: data.loaderModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
