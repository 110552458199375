import React, { Component, Fragment } from "react";
import Select from "react-select";
import { GET } from "../../../utils/webAPI.service";
import { API_GET_BANK_LIST } from "../../../utils/APIUrls";
import { InputLabelAnimation } from "../../../components";

export default class addNewBankAccount extends Component {
  state = {
    bankList: [],
    ifscManual: "",
    pdfPassword:null
  };
  sanctionLimitHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;
    this.setState({ [name]: value });
  };
  setBank = (e) => {
    this.setState({ bankName: e.label, bankCode: e.value });
  };
  setAccountType = (e) => {
    this.setState({ accountType: e.value });
  };
  addAccountHandler = () => {
    let { bankName, accountType, sanctionLimit, bankCode, ifscManual,pdfPassword } =
      this.state;
    var bankObject = {
      bankName,
      bankCode,
      accountType,
      sanctionLimit,
      ifscManual,
      pdfPassword
    };
    this.props.actions.addAccountBankException(bankObject);
    this.props.closeAction();
  };
  render() {
    let { bankList } = this.state;
    var accountTypeList = [
        { value: "Savings Account", label: "Savings Account" },
        { value: "Current Account", label: "Current Account" },
        { value: "CREDIT_CARD", label: "CC/OD Account" },
      ],
      bankDataList = bankList?.map((el) => {
        return { value: el.code, label: el.bankName };
      });
    return (
      <Fragment>
        <div className="container new-bank-account__wrapper m-0">
          <div className="fields mt-3">
            <label className="label-heading mb-1">Select the Bank</label>
            <Select
              placeholder={"Select Bank"}
              isSearchable={true}
              name={"bankName"}
              options={bankDataList}
              onChange={this.setBank}
            />
          </div>
          <div className="fields mt-3">
            <label className="label-heading mb-1">Select account type</label>
            <Select
              options={accountTypeList}
              placeholder={"Select Bank"}
              isSearchable={true}
              name={"bankName"}
              onChange={this.setAccountType}
            />
          </div>
          <br />
          {this.state.accountType === "CREDIT_CARD" ? (
            <InputLabelAnimation
              labelText={"Enter Sanction Limit"}
              name={"sanctionLimit"}
              title={"sanctionLimit"}
              onChange={this.sanctionLimitHandler}
              inputValue={this.state["sanctionLimit"]}
            />
          ) : null}

          <div className="fields mt-2">
            <InputLabelAnimation
              labelText={"Enter IFSC code"}
              name={"ifscManual"}
              onChange={this.sanctionLimitHandler}
              inputValue={this.state["ifscManual"]}
            />
          </div>
          <div className="fields mt-3">
            <InputLabelAnimation
              labelText={"Enter pdf password"}
              name={"pdfPassword"}
              onChange={this.sanctionLimitHandler}
              inputValue={this.state["pdfPassword"]}
            />
          </div>
          <div className="tc mt-3">
            <button
              className="button_Generic green-btn bold-btn"
              onClick={this.addAccountHandler}
              disabled={!this.state.accountType}
            >
              Add Account
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
  componentDidMount = () => {
    GET(API_GET_BANK_LIST)
      .then(({ data }) => {
        this.setState({ bankList: data.data });
      })
      .catch((err) => {
        throw err;
      });
  };
}
