const CHANGE_VALUE = Symbol('ChangeValue'),
    SET_DOCUMENT_DATA = Symbol('SetDocumentData'),
    RESET_REDUX_STATE = Symbol('ResetReduxState'),
    FETCH_DOCUMENT_LIST = Symbol('FetchDocumentList');
export {
    CHANGE_VALUE,
    SET_DOCUMENT_DATA,
    RESET_REDUX_STATE,
    FETCH_DOCUMENT_LIST
};
