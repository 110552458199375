import React, { Component } from "react";
import { API_UN_ASSIGN_CPO } from "../../utils/APIUrls";
import { POST } from "../../utils/webAPI.service";

class RemoveUser extends Component {
  state = {
    chequeBounces: 0,
    totalChequesIssued: 0,
    status: "Inward",
    bounceRatio: 0
  };

  submit = async e => {
    e.preventDefault();
    let { applicationNumber, props } = this.props;
    let { loaderModal } = props.actions;
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    await POST(API_UN_ASSIGN_CPO(applicationNumber), { data: "" }, { headers })
      .then(({ data }) => {})
      .catch(err => {
        loaderModal(false);
        console.log(err);
        window.alert("Something went wrong please try again later.");
      });
    loaderModal(false);
    this.props.closeAction();
  };

  render() {
    return (
      <form onSubmit={this.submit} className="edit-ratio-form">
        <div>
          <span className="span mt-2 mb-2">Do you want to remove the user in this case</span>
        </div>
        <button className="submit1 " type="submit">
          Remove User
        </button>
      </form>
    );
  }
}

export default RemoveUser;
