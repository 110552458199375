import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component, Fragment } from "react";
import Header from "../../components/Header/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import {
  loaderModal,
  setUserDetails,
  setUserLoginStatus,
  updateErrorMessage
} from "../../store/actions";
import { API_GENERATE_TOKEN, API_LOGIN } from "../../utils/APIUrls";
import { bindActionCreators } from "redux";
import { validateEmail } from "../../utils/utitlity";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";


export default class passwordReset extends Component {
    render() {
        return (
        
            
            <div className="password-reset-modal">
          <div className="password-reset-box">
            <div className="row p-20 center">
                <div className="padding-bottom-50">
                    <div className="col-md-6 text-align-center">
                        <label className="center"> Old password</label>
                    </div>
                    <div className="col-md-5  ">
                        <input className="input-w-93" type="password" placeholder="Old Password"/>
                    </div>
                </div>

                
                <div className="w-100"/>
                <span className="p-b-30"/>

                <div className="padding-bottom-50">
                    <div className="col-md-6  text-align-center">
                        <label> New password</label>
                    </div>
                    <div className="col-md-5 ">
                        <input className="input-w-93" type="password" placeholder="New Password"/>
                    </div>
                </div>
                <div className="w-100"/>
                
                <span className="clearfix"/>
                <span className="p-b-30 "/>
                <div className="padding-bottom-50">
                    <div className="col-md-6 text-align-center">
                      <label> Re-enter New password</label>
                    </div>
                    <div className="col-md-5 ">
                    <input  className="input-w-93" type="password" placeholder="Re-enter New Password"/>
                    
                    </div>
                </div>
                
                <div className="w-100"/>
                
                
                
                
                <div className="row">
                    <div className="btn-box d-flex justify-content-center"><span className="clearfix"/>
                        <div className="btnn blue-btn right mr-3">
                            <a href="#">Clear</a>
                        </div>
                        

              
                        <div className="btnn green-btn right mr-3">
                        <a href="#">Confirm</a>
                        </div>
                    </div>
                </div>
               


                </div>
        </div> 
    </div>
    
        );
    }
}
