import React, { Component } from "react";
import {
  API_SUBMIT_BOUNCE_RATIO_ACC,
  API_SUBMIT_BOUNCE_RATIO
} from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import get from "lodash/get";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
class EditRatioForm extends Component {
  state = {
    chequeBounces: 0,
    totalChequesIssued: 0,
    status: "Inward",
    bounceRatio: 0,
    error: ""
  };
  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };
  bounceRatioFun = br => {
    this.setState({
      bounceRatio: br
    });
    this.props.sendData(br);
  };
  submit = event => {
    event.preventDefault();
    this.props.closeAction();
  };
  submit = async event => {
    event.preventDefault();
    const { props } = this.props,
      { status, chequeBounces, totalChequesIssued } = this.state,
      applicationNumber = get(props.match, "params.appNo", ""),
      accountNumber = props.analysisData.accountNumber,
      denominator = parseInt(totalChequesIssued),
      numerator = parseInt(chequeBounces);
    if (
      applicationNumber &&
      status &&
      numerator.toString() &&
      denominator.toString() &&
      (accountNumber==="ALL" || accountNumber===null || accountNumber==="")
    ) {
      const data = { denominator, numerator },
        headers = { "Content-Type": "application/json" };
      await POST(API_SUBMIT_BOUNCE_RATIO(applicationNumber, status), data, {
        headers
      })
        .then(({ data }) => {
          let { status, message } = data;
          if (status === "success") {
            let br = get(data, "data.bounceRatio");
            this.bounceRatioFun(br);
            this.props.closeAction();
          } else {
            this.state({ error: message });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({error:"Something went wrong please try again later."})
        });
    } else if (
      applicationNumber &&
      status &&
      numerator.toString() &&
      denominator.toString() &&
      (accountNumber !=="ALL" && accountNumber!==null && accountNumber!=="")
    ) {
      let data = { denominator, numerator };
      const headers = { "Content-Type": "application/json" };
      await POST(
        API_SUBMIT_BOUNCE_RATIO_ACC(applicationNumber, status, accountNumber),
        data,
        { headers }
      )
        .then(({ data }) => {
          let { status, message } = data;
          if (status === "success") {
            let br = get(data, "data.bounceRatio");
            this.bounceRatioFun(br);
            this.props.closeAction();
          } else {
            this.setState({ error: message });
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({
            error: "Something went wrong please try again later."
          });
        });
    } else {
      this.setState({ error: "please enter Numerator and Denominator." });
    }
  };

  async componentDidMount() {
    let { props } = this.props,
      { status } = this.state,
      applicationNumber = get(props.match, "params.appNo", "");
    let accountNumber = get(props.analysisData, "accountNumber", "");

    if (applicationNumber && status && accountNumber === "ALL") {
      await props.actions
        .getEditRatio(applicationNumber, status)
        .then(({ data }) => {
          data && this.setState({
            chequeBounces: data.numerator,
            totalChequesIssued: data.denominator
          });
        });
    } else if (applicationNumber && status && accountNumber !== "ALL") {
      await props.actions
        .getEditRatioAcc(applicationNumber, status, accountNumber)
        .then(({ data }) => {
          data && this.setState({
            chequeBounces: data.numerator,
            totalChequesIssued: data.denominator
          });
        });
    } else {
      this.setState({ error: "something wrong went" });
    }
  }

  render() {
    const { totalChequesIssued, chequeBounces } = this.state;
    return (
      <form onSubmit={this.submit} className="edit-ratio-form">
        <div className="input-container">
          <input
            className="input credit-input"
            name="chequeBounces"
            value={chequeBounces}
            placeholder="Cheques Bounces"
            type="number"
            onChange={this.handleChange}
          />
          <span className="">/</span>
          <input
            className="input debit-input"
            name="totalChequesIssued"
            placeholder="Total Cheques issued"
            value={totalChequesIssued}
            type="number"
            onChange={this.handleChange}
          />
        </div>
        <div className="row">
          {this.state.error ? (
            <ErrorMessage>{this.state.error}</ErrorMessage>
          ) : null}
          <button className="submit" type="submit">
            Submit
          </button>
        </div>
      </form>
    );
  }
}

export default EditRatioForm;
