import { get, set } from "lodash";

const _getValue = (state, name, index) => {
  return get(state, `referencesList[${index}].${name}`);
};

const _updateFormValues = (state, name, value = "", index) => {
  const _preValue = _getValue(state, name, index);
  if (_preValue !== value) {
    let _changedState = JSON.parse(JSON.stringify(state)),
      _changedValue = get(
        set(_changedState, `referencesList[${index}].${name}`, value),
        "referencesList"
      );

    return { ..._changedState, referencesList: _changedValue };
  }
  return state;
};

const _addRow = (state) => {
  let _changedState = JSON.parse(JSON.stringify(state));
  return {
    ..._changedState,
    referencesList: [..._changedState.referencesList, {}],
  };
};

const _deleteRow = (state, index) => {
  let _changedState = JSON.parse(JSON.stringify(state)),
    _rowsList = get(_changedState, "referencesList");
  _rowsList.splice(index, 1);
  return { ..._changedState, referencesList: _rowsList };
};

export { _updateFormValues, _addRow, _deleteRow };
