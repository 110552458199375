import React, { PureComponent, Fragment } from "react";
import Highcharts from "highcharts";
import { map } from "lodash";
import {
  getCreditCapacityDetails,
  getBankDetails,
  getBankingChequeAnalysis,
  getBankingConcentrationAnalysis,
  getConcentrationRiskDetails,
  getGstTrendDetails,
  getGSTAndBankingDetails,
  getTurnoverAndPurchaseDetails,
  getAggregateTrendAnalysis,
  getTurnoverDetails,
  getMarginData
} from "../cam.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import { rupeeCommaFormatter, integerToWordFormatter, integerToWordFormatterLabels } from "../../../../utils/utitlity";
class SectionB extends PureComponent {
  componentDidMount = () => {
    this.fetchDetails();
  };
  fetchDetails = async () => {
    let { actions, match } = this.props,
      {
        getCreditCapacityDetails,
        getBankDetails,
        getBankingChequeAnalysis,
        getBankingConcentrationAnalysis,
        getConcentrationRiskDetails,
        getGstTrendDetails,
        getGSTAndBankingDetails,
        getTurnoverAndPurchaseDetails,
        getAggregateTrendAnalysis,
        getTurnoverDetails,
        getMarginData
      } = actions,
      applicationNumber = get(match, "params.appNo", "");
    getCreditCapacityDetails(applicationNumber);
    getBankDetails(applicationNumber);
    getBankingChequeAnalysis(applicationNumber);
    getBankingConcentrationAnalysis(applicationNumber);
    getConcentrationRiskDetails(applicationNumber);
    getGstTrendDetails(applicationNumber);
    getGSTAndBankingDetails(applicationNumber);
    getTurnoverAndPurchaseDetails(applicationNumber);
    getTurnoverDetails(applicationNumber);
    getMarginData(applicationNumber);
    await getAggregateTrendAnalysis(applicationNumber);
  };
  componentDidUpdate = prevProps => {
    let { turnoverAndPurchasesData, gstTrendAnalysisData, gstAndBankingDetailsData } = this.props;
    Highcharts.chart("GstTurnOver", {
      chart: {
        type: "line"
      },
      title: false,
      xAxis: {
        categories: gstTrendAnalysisData.monthWiseTurnover
          ? gstTrendAnalysisData.monthWiseTurnover.map(el => el.month)
          : null
      },
      yAxis: {
        title: false,
        labels: {
          formatter: function() {
            return integerToWordFormatterLabels(this.value);
          }
        }
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1em"
            },
            formatter: function() {
              return (
                "<p>" + this.x + "<br/>" + this.series.name + "<b>:  </b>" + integerToWordFormatter(this.y) + "</p>"
              );
            }
          },
          enableMouseTracking: false
        }
      },
      series: [
        {
          showInLegend: false,
          name: "GST Turnover",
          data: gstTrendAnalysisData.monthWiseTurnover
            ? gstTrendAnalysisData.monthWiseTurnover.map(el => el.value)
            : null
        }
      ]
    });
    Highcharts.chart("gstMonthlyWise", {
      chart: {
        type: "column"
      },
      title: false,
      xAxis: {
        categories: map(turnoverAndPurchasesData && turnoverAndPurchasesData.monthwiseGstPurchaseTurnover, v =>
          v.month.replace(" ", "<br/>")
        ),
        crosshair: true
      },
      yAxis: [
        {
          labels: {
            formatter: function() {
              return integerToWordFormatterLabels(this.value);
            }
          },
          title: {
            text: "Value"
          }
        },
        {
          gridLineWidth: 0,
          title: {
            text: "Ratio"
          },
          labels: {
            format: "{value} %"
          },
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1em"
            },
            formatter: function() {
              
              return integerToWordFormatter(this.y);
            }
          },
          enableMouseTracking: false
        },
        spline: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1.5em"
            },
            formatter: function() {
              return this.y.toFixed(2) + "%";
            }
          },
          enableMouseTracking: false
        }
      },
      series: [
        {
          type: "spline",
          name: "Ratio",
          yAxis: 1,
          data: map(turnoverAndPurchasesData && turnoverAndPurchasesData.ratioTurnoverOverPurchases, "value"),
          tooltip: {
            valueSuffix: " %"
          }
        },
        {
          name: "Turnover",
          data: map(turnoverAndPurchasesData && turnoverAndPurchasesData.monthwiseGstPurchaseTurnover, "gst"),
          color: "#56d9fe",
          type: "column"
        },
        {
          name: "Purchase",
          data: map(turnoverAndPurchasesData && turnoverAndPurchasesData.monthwiseGstPurchaseTurnover, "purchase"),
          color: "#3670cc",
          type: "column"
        }
      ]
    });
    Highcharts.chart("turnoverComparison", {
      chart: {
        type: "column"
      },
      title: false,
      xAxis: {
        categories: map(gstAndBankingDetailsData && gstAndBankingDetailsData.monthWiseGSTBankingTurnover, v =>
          v.month.replace(" ", "<br/>")
        ),
        crosshair: true
      },
      yAxis: [
        {
          labels: {
            formatter: function() {
              return integerToWordFormatterLabels(this.value);
            }
          }
        }
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "1em"
            },
            formatter: function() {
              return integerToWordFormatter(this.y);
            }
          },
          enableMouseTracking: false
        }
      },
      series: [
        {
          name: "GST",
          data: map(gstAndBankingDetailsData && gstAndBankingDetailsData.monthWiseGSTBankingTurnover, "gstValue"),
          color: "#56d9fe",
          type: "column"
        },
        {
          name: "Banking",
          data: map(gstAndBankingDetailsData && gstAndBankingDetailsData.monthWiseGSTBankingTurnover, "bankingValue"),
          color: "#3670cc",
          type: "column"
        }
      ]
    });
  };
  render() {
    let {
      bankDetailsData,
      creditCapacityData,
      bankingConcentrationData,
      businessConcentrationData,
      gstTrendAnalysisData,
      gstAndBankingDetailsData,
      chequeBounceData,
      turnoverAndPurchasesData,
      aggregateTendData,
      marginData,
      turnoverDetailsData
    } = this.props;
   
    return (
      <Fragment>
        {creditCapacityData && marginData && turnoverDetailsData ? (
          <div className="box-wrapper">
            <div className="box-heading">Loan Eligibility Summary</div>
            <hr />
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Sales As Per GST</div>
                <div>{rupeeCommaFormatter(turnoverDetailsData.imputedGSTTurnover)}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Sales As Per Banking(in ₹)</div>
                <div>{rupeeCommaFormatter(turnoverDetailsData.totalBankingTurnover)}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Sales Considered for Eligibility(in ₹)</div>
                <div>{rupeeCommaFormatter(creditCapacityData.salesConsideredForEligibility)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Weighted Average Margin(in ₹)</div>
                <div>{marginData.weightedAverageMargin}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Operating Margin</div>
                <div>{marginData.marginValue} %</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Margin Considered for Eligibility(in ₹)</div>
                <div>{rupeeCommaFormatter(creditCapacityData.marginConsideredForEligibility)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Annual Income</div>
                <div>{rupeeCommaFormatter(creditCapacityData.annualMargin)}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Monthly Income</div>
                <div>{rupeeCommaFormatter(creditCapacityData.monthlyMargin)}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Total Monthly Obligation</div>
                <div>{creditCapacityData.existingObligations}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Pre FOIR</div>
                <div>{creditCapacityData.preFoir >= 0 ? creditCapacityData.preFoir + "%" : "NA"}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Eligible Loan Amount</div>
                <div>{creditCapacityData.eligibleLoanAmount}</div>
              </div>
              {/* <div className="col-sm-4 col-xs-4">
              <div className="box-subheading">Pre Loan Fixed Obligation</div>
              <div>{camData.loanEligibiltySummary.postFoir}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 col-xs-4">
              <div className="box-subheading">Post Loan Fixed Obligation</div>
              <div>{camData.loanEligibiltySummary.preFoir}</div>
            </div> */}
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Post FOIR</div>
                <div>{creditCapacityData.revisedPostFoir >= 0 ? creditCapacityData.revisedPostFoir + "%" : "NA"}</div>
              </div>
            </div>
          </div>
        ) : null}

        {gstTrendAnalysisData ? (
          <div className="box-wrapper">
            <div className="box-heading">Turnover Analysis as per GST</div>
            <hr />
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Turnover in last 12 months (in ₹)</div>
                <div>{rupeeCommaFormatter(gstTrendAnalysisData.imputedTotalTurnoverInLast12Months)}</div>
              </div>
              {/* <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Avg. quarterly turnover (in ₹)</div>
                <div>{gstTrendAnalysisData.avgQuaterlyTurnover}</div>
              </div> */}
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Avg. Monthly turnover (in ₹)</div>
                <div>{rupeeCommaFormatter(gstTrendAnalysisData.averageMonthlyTurnover)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Turnover growth in the past 12 months</div>
                <div>{gstTrendAnalysisData.turnoverGrowth ? Number(gstTrendAnalysisData.turnoverGrowth).toFixed(2) : "0"}%</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Turnover till date from March 2019 (in ₹)</div>
                <div>{rupeeCommaFormatter(gstTrendAnalysisData.turnoverTillDate)}</div>
              </div>
            </div>
            <div className="box-heading mtl">GST Turnover Trend - Overall Growth In Past 12 Months</div>
            <hr />
            <div className="row">
              <div id="GstTurnOver" className="col-xs-12"></div>
            </div>
          </div>
        ) : null}
        <div className="box-wrapper">
          <div className="box-heading">GST Turnover & Purchases Analysis ( Last 12 Months)</div>
          <hr />
          {turnoverAndPurchasesData ? (
            <div className="row">
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Total Turnover / Purchase Ratio</div>
                <div>{turnoverAndPurchasesData.purchaseToTurnover}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Average Monthly TO (in ₹)</div>
                <div>{turnoverAndPurchasesData.averageMonthlyPurchase}</div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="box-subheading">Average Monthly Purchase (in ₹)</div>
                <div>{turnoverAndPurchasesData.averageMonthlyTurnover}</div>
              </div>
            </div>
          ) : null}
          <div className="box-heading mtl">GST Turnover & Purchases ( Last 12 Months)</div>
          <hr />
          <div id="gstMonthlyWise"></div>
        </div>

        <div className="box-wrapper">
          <div className="box-heading">Business Concentration Analysis (Your top 5s)</div>
          <hr />
          <div className="row">
            {businessConcentrationData.contributionByTop10Customers &&
            businessConcentrationData.contributionByTop10Customers.length !== 0 ? (
              <div className="col-sm-4 col-xs-4">
                <div className="headingBusiness">Customers</div>
                <div className="businessAnalysisWrapper">
                  <table>
                    <tbody>
                      {businessConcentrationData.contributionByTop10Customers.slice(0, 5).map((el, index) => {
                        return (
                          <tr key={index}>
                            <td className="table-category" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.category}
                            </td>
                            <td className="table-value" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.value}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {businessConcentrationData.contributionByTop10Vendors &&
            businessConcentrationData.contributionByTop10Vendors.length !== 0 ? (
              <div className="col-sm-4 col-xs-4">
                <div className="headingBusiness">Vendors</div>
                <div className="businessAnalysisWrapper">
                  <table>
                    <tbody>
                      {businessConcentrationData.contributionByTop10Vendors.slice(0, 5).map((el, index) => {
                        return (
                          <tr key={index}>
                            <td className="table-category" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.category}
                            </td>
                            <td className="table-value" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.value}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {businessConcentrationData.contributionByTop10Products &&
            businessConcentrationData.contributionByTop10Products.length !== 0 ? (
              <div className="col-sm-4 col-xs-4">
                <div className="headingBusiness">Products</div>
                <div className="businessAnalysisWrapper">
                  <table>
                    <tbody>
                      {businessConcentrationData.contributionByTop10Products.slice(0, 5).map((el, index) => {
                        return (
                          <tr key={index}>
                            <td className="table-category" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.hsn_sc}
                            </td>
                            <td className="table-value" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.txval}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Banking Concentration Analysis (Your top 5s)</div>
          <hr />
          <div className="row">
            {bankingConcentrationData.customerConcentration &&
            bankingConcentrationData.customerConcentration.length !== 0 ? (
              <div className="col-sm-4 col-xs-4">
                <div className="headingBusiness">Customers</div>
                <div className="businessAnalysisWrapper">
                  <table>
                    <tbody>
                      {bankingConcentrationData.customerConcentration.slice(0, 5).map((el, index) => {
                        return (
                          <tr key={index}>
                            <td className="table-category" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.description}
                            </td>
                            <td className="table-value" style={index === 4 ? { borderBottom: "none" } : null}>
                              {Number(el.percentageOfSales).toFixed(2)}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {bankingConcentrationData.vendorConcentration &&
            bankingConcentrationData.vendorConcentration.length !== 0 ? (
              <div className="col-sm-4 col-xs-4">
                <div className="headingBusiness">Vendors</div>
                <div className="businessAnalysisWrapper">
                  <table>
                    <tbody>
                      {bankingConcentrationData.vendorConcentration.slice(0, 5).map((el, index) => {
                        return (
                          <tr key={index}>
                            <td className="table-category" style={index === 4 ? { borderBottom: "none" } : null}>
                              {el.description}
                            </td>
                            <td className="table-value" style={index === 4 ? { borderBottom: "none" } : null}>
                              {Number(el.percentageOfSales).toFixed(2)}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          {bankDetailsData
            ? bankDetailsData.map((el, index) => {
                return (
                  <div className="col-sm-6" key={index}>
                    <div className="box-wrapper">
                      <div className="box-heading">Bank Account {index + 1}</div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Account Number</div>
                          <div> {el.accountNumber}</div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Type of Account</div>
                          <div> {el.accountType}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Bank Name</div>
                          <div> {el.bankName}</div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Account Holder's Name</div>
                          <div> {el.accountHolderName}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Sanction Limit (in ₹)</div>
                          <div> {el.sanctionLimit}</div>
                        </div>
                        <div className="col-sm-6 col-xs-6">
                          <div className="box-subheading">Months Available</div>
                          <div className={"months-list"}>
                            {typeof el.monthAvailable !== "undefined" && el.monthAvailable != null ? (
                              el.monthAvailable.map((month, index) => (
                                <li key={index}>
                                  {month.split("-")[0]}
                                  <br />
                                  {month.split("-")[1]}
                                </li>
                              ))
                            ) : (
                              <li>NA</li>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="box-wrapper">
          <div className="box-heading">Aggregates Banking Trend Analysis</div>
          <hr />
          <table>
            <tr>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <div className="aggregate-table__heading">Months</div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregateTendData &&
                      aggregateTendData.credits &&
                      aggregateTendData.credits.values &&
                      aggregateTendData.credits.values.map((el, index) => {
                        return (
                          <tr>
                            <td className="aggregate-table__months">{el.month}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <div className="aggregate-table__heading">Total Credits (in ₹)</div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregateTendData &&
                      aggregateTendData.credits &&
                      aggregateTendData.credits.values &&
                      aggregateTendData.credits.values.map((el, index) => {
                        return (
                          <tr>
                            <td>{rupeeCommaFormatter(el.value)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <div className="aggregate-table__heading">Total Debits (in ₹)</div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregateTendData &&
                      aggregateTendData.debits &&
                      aggregateTendData.debits.values &&
                      aggregateTendData.debits.values.map((el, index) => {
                        return (
                          <tr>
                            <td>{rupeeCommaFormatter(el.value)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <div className="aggregate-table__heading">Monthly ABB(in ₹)</div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregateTendData &&
                      aggregateTendData.monthlyABB &&
                      aggregateTendData.monthlyABB.map((el, index) => {
                        return (
                          <tr>
                            <td>{rupeeCommaFormatter(el.value)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </td>
              {aggregateTendData &&
                aggregateTendData.limitUtilizationAccountwise &&
                aggregateTendData.limitUtilizationAccountwise.map((el, index) => {
                  return (
                    <td key={index}>
                      <table>
                        <thead>
                          <tr>
                            <td>
                              <div className="aggregate-table__heading">Limit Utilization {index + 1}</div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {el.limitUtilization &&
                            el.limitUtilization.map((el, index) => {
                              return (
                                <tr>
                                  <td>{rupeeCommaFormatter(el.value)}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </td>
                  );
                })}
            </tr>
          </table>
        </div>
        {chequeBounceData ? (
          <div className="box-wrapper">
            <div className="box-heading">Cheque Bounce Ratio</div>
            <hr />
            <div className="row">
              <div className="col-sm-6 col-xs-6">
                <div className="box-subheading">Inward Cheque Bounce Ratio</div>
                <div>{chequeBounceData.inwardChqBounceRatio}</div>
              </div>
              <div className="col-sm-6 col-xs-6">
                <div className="box-subheading">Outward Cheque Bounce Ratio</div>
                <div>{chequeBounceData.outwardChqBounceRatio}</div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="box-wrapper">
          <div className="box-heading">Turnover Comparison (GST and Banking)</div>
          <hr />
          {gstAndBankingDetailsData ? (
            <div className="row">
              <div className="col-sm-12 col-xs-12">
                <span className="box-subheading">Deviation - GST over banking</span>
                <span>(Negative indicates GST TO lower)</span>
                <div>{gstAndBankingDetailsData.deviationGstOverBanking}</div>
              </div>
            </div>
          ) : null}
          <hr />
          <div id="turnoverComparison"></div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ CamResponse }) => ({
  bankDetailsData: CamResponse.bankDetailsData,
  creditCapacityData: CamResponse.creditCapacityData,
  applicantSummaryData: CamResponse.applicantSummaryData,
  bankingConcentrationData: CamResponse.bankingConcentrationData,
  businessConcentrationData: CamResponse.businessConcentrationData,
  gstTrendAnalysisData: CamResponse.gstTrendAnalysisData,
  gstAndBankingDetailsData: CamResponse.gstAndBankingDetailsData,
  chequeBounceData: CamResponse.chequeBounceData,
  turnoverAndPurchasesData: CamResponse.turnoverAndPurchasesData,
  aggregateTendData: CamResponse.aggregateTendData,
  turnoverDetailsData: CamResponse.turnoverDetailsData,
  marginData: CamResponse.marginData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCreditCapacityDetails,
      getBankDetails,
      getBankingChequeAnalysis,
      getBankingConcentrationAnalysis,
      getConcentrationRiskDetails,
      getGstTrendDetails,
      getGSTAndBankingDetails,
      getTurnoverAndPurchaseDetails,
      getAggregateTrendAnalysis,
      getTurnoverDetails,
      getMarginData
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionB);
