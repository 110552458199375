import * as ReferencesConstants from './references.actions.constant';
import * as ReferencesSelectors from "./references.selectors";

const initialState = {
    initialResponse: false,
    referencesList: []
};

export const referencesReducers = (state = initialState, action) => {
    const {data, type, val, name, index, status} = action;
    switch (type) {
        case ReferencesConstants.SET_REFERENCES:
            return {...state, referencesList: data};
        case ReferencesConstants.RESET_REDUX_STATE:
            return {...state, referencesList: []};
        case ReferencesConstants.SET_INITIAL_RESPONSE:
            return {...state, initialResponse: status};
        case ReferencesConstants.CHANGE_VALUE:
            return ReferencesSelectors._updateFormValues(state, name, val, index);
        case ReferencesConstants.ADD_ROW:
            return ReferencesSelectors._addRow(state);
        case ReferencesConstants.DELETE_ROW:
            return ReferencesSelectors._deleteRow(state, index);
        default:
            return state;
    }
};
