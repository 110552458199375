import React, { Component } from "react";
export default class ToggleButton extends Component {
  state = {
    dataChecked: "Yes",
    dataUnchecked: "No",
  };
  toggleHandler = () => {
    let { toggleHandler, name } = this.props;
    toggleHandler(name);
  };
  render() {
    let { dataChecked, dataUnchecked } = this.state;
    return (
      <div>
        <div className="can-toggle demo-rebrand-1">
          <input
            id="ds"
            type="checkbox"
            ref={(ref) => (this.checkboxRef = ref)}
          />
          <label htmlFor="d" onClick={() => this.checkboxRef.click()}>
            <div
              className="can-toggle__switch"
              data-checked={dataChecked}
              data-unchecked={dataUnchecked}
              onClick={this.toggleHandler}
            ></div>
          </label>
        </div>
      </div>
    );
  }
}
