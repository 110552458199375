import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { InputLabelAnimation } from '../../../components';
import { ic_delete_forever } from 'react-icons-kit/md/ic_delete_forever';
import { GET } from '../../../utils/webAPI.service';
import Select from 'react-select';

class Address extends Component {
  state = {
    currentPinCodes: [],
    currentPinCodeLoader: false,
  };
  setCurrentInputPinCode = (e) => {
    clearTimeout(this.searchCurrentPinCode);
    this.searchCurrentPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            currentPinCodeLoader: true,
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === 'success') {
                let list = data.data,
                  currentPinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    currentPinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      district: list[i].district,
                      state: list[i].state,
                    });
                  }
                }
                this.setState({
                  currentPinCodes,
                  currentPinCodeLoader: false,
                });
              } else {
                this.setState({ currentPinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };
  setCurrentPinCode = (e) => {
    let { value, city, state } = e,
      { changeHandler } = this.props;
    changeHandler('pinCode', value);
    setTimeout(() => {
      changeHandler('city', city);
    }, 50);
    setTimeout(() => {
      changeHandler('state', state);
    }, 50);
  };
  render() {
    let {
        addressObject,
        addressTypeList,
        index,
        changeHandler,
        deleteAddress,
      } = this.props,
      ownershipList = ['Self-Owned', 'Rented'],
      { currentPinCodes, currentPinCodeLoader } = this.state;
    return (
      <div className='address'>
        <span className='address-inner'>Address {index + 1}</span>
        {index > 0 ? (
          <span
            className={'remove-icon'}
            title={'Delete'}
            onClick={deleteAddress}
          >
            <Icon icon={ic_delete_forever} size={26} />
          </span>
        ) : null}
        <div className='row'>
          <div className={'col-xs-12 col-md-4'}>
            <select
              className={'select-box_main mt-2'}
              id={'address-type'}
              name={'addressType'}
              onChange={(e) => changeHandler(e.target.name, e.target.value)}
              value={addressObject.addressType || ''}
            >
              {addressTypeList.map((x, y) => (
                <option key={y + 1}>{x}</option>
              ))}
            </select>
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <InputLabelAnimation
              labelText={'Floor/Building number'}
              required={true}
              name={'line1'}
              onChange={(e) => changeHandler(e.target.name, e.target.value)}
              inputValue={addressObject.line1 || ''}
            />
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <InputLabelAnimation
              labelText={'Street/Landmark '}
              required={true}
              type={'text'}
              name={'line2'}
              onChange={(e) => changeHandler(e.target.name, e.target.value)}
              inputValue={addressObject.line2 || ''}
            />
          </div>
          <div className={'col-xs-12 col-md-4 mt-2'}>
            <Select
              value={
                currentPinCodes.find(
                  (option) => option.value === addressObject.pinCode
                ) ||
                (addressObject.pinCode && {
                  value: addressObject.pinCode,
                  label: addressObject.pinCode,
                })
              }
              placeholder={'Pin Code'}
              isSearchable={true}
              name='current_pin_code'
              options={currentPinCodes}
              className='pin-code-select-search'
              classNamePrefix='select'
              onInputChange={this.setCurrentInputPinCode}
              onChange={this.setCurrentPinCode}
              isLoading={currentPinCodeLoader}
            />
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <InputLabelAnimation
              labelText={' City'}
              required={true}
              type={'text'}
              name={'city'}
              disabled
              inputValue={addressObject.city || ''}
            />
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <InputLabelAnimation
              labelText={'State'}
              required={true}
              type={'text'}
              name={'state'}
              disabled
              inputValue={addressObject.state || ''}
            />
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <select
              className={'select-box_main mt-2'}
              id={'address-type'}
              name={'ownership'}
              onChange={(e) => changeHandler(e.target.name, e.target.value)}
              value={addressObject.ownership || ''}
            >
              <option>Select Ownership Type</option>
              {ownershipList.map((x, y) => (
                <option key={y + 1}>{x}</option>
              ))}
            </select>
          </div>
          <div className={'col-xs-12 col-md-4'}>
            <InputLabelAnimation
              labelText={' Operating since '}
              required={true}
              name={'durationOfStay'}
              inputValue={addressObject.durationOfStay || ''}
              onChange={(e) => changeHandler(e.target.name, e.target.value)}
            />
          </div>
      
        </div>
      </div>
    );
  }
}
export default Address;
