import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { API_GET_BUSINESS_COST_ANALYSIS,API_GET_BUSINESS_DETAILS } from "../../../../../utils/APIUrls";
import { GET } from "../../../../../utils/webAPI.service";
import { MAHTArray, machineryArray } from "../businessCostAnalysis.config";

export default class MachineAndHandToolsBusinessCostAnalysis extends Component {
  state = {};
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_DETAILS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.businessName) {
        let { businessName } = data.data;
        this.setState({ businessName });
      }
    });
    GET(API_GET_BUSINESS_COST_ANALYSIS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.machineAndHandToolsCostAnalysis) {
        let { machineAndHandToolsCostAnalysis } = data.data;
        this.setState({ machineAndHandToolsCostAnalysis });
      }
    });
  };
  render() {
    let { machineAndHandToolsCostAnalysis = {} ,businessName} = this.state,
      {
        businessDetails = {},
        machineAndHandTools,
        machinery = {},
        productionAndSales = {},
      } = machineAndHandToolsCostAnalysis,
      {
        forgingWork,
        machineAndHandToolList,
        manufacturingOfHandTools,
        manufacturingOfMotherMachine,
      } = productionAndSales,
      { manufacturingUnit } = businessDetails,
      { others } = machinery,
      { screenRenderHandler } = this.props;

    const machineryList = {},
      machineAndHandToolsList = {};
    Object.entries(machinery || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (machineryList[key] = value));

    Object.entries(machineAndHandTools || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (machineAndHandToolsList[key] = value));

    var machineAndHandToolsListItems = Object.keys(
      machineAndHandToolsList || {}
    ).map((key, index) => {
      return MAHTArray.map((part, i) => {
        if (key === part.name) {
          return (
            <div className={" box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0" key={i}>
                {part.label}
              </h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  <img
                    src={require(`../../../../../assets/images/MAHT/${part.label}.png`)}
                    alt={key}
                    className="bicycle-part-image"
                  />
                </div>

                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div> {machineAndHandToolsList[key]} %</div>
                </div>
              </div>
            </div>
          );
        }
      });
    });

    var machineryListItems = Object.keys(machineryList || {}).map(
      (key, index) => {
        return machineryArray.map((part, i) => {
          if (key === part.name) {
            return (
              <div className={" box-wrapper mt-20 w-50"}>
                <h4 className="box_sub_heading mt-0" key={i}>
                  {part.label}
                </h4>
                <div className={"row"}>
                  <div className={"col-sm-4 bold"}>
                    <img
                      src={require(`../../../../../assets/images/MAHT/${part.label}.png`)}
                      alt={key}
                      className="bicycle-part-image"
                    />
                  </div>
                  <div className={"col-sm-2 bold"}>
                    Number
                    <div>{machineryList[key].number} </div>
                  </div>
                  <div className={"col-sm-5 bold"}>
                    Tentative Cost
                    <div> ₹ {machineryList[key].tentativeCost} </div>
                  </div>
                </div>
              </div>
            );
          }
        });
      }
    );

    return (
      <div className={"bicycle-analysis-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Machine And Hand Tools Business Cost</title>
        </Helmet>
        <div className="container_top">
        <div
            className={"back-btn-link"}
            onClick={() => screenRenderHandler("overview-details")}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>
          <h3 className="container_heading">
            {businessName} - Operational Details
          </h3>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Machine and Hand tools manufactured
            </h4>
          </div>
          {machineAndHandToolsListItems}
          {machineAndHandTools?.machineAndHandToolList?.map((tool, index) => (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">{tool?.name}</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  <img />
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %<div>{tool?.profitMargin} %</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Machinery</h4>
          </div>
          {machineryListItems}
          {others?.map((machine, index) => (
            <div className={" box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">{machine.name}</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}></div>
                <div className={"col-sm-2 bold"}>
                  Number
                  <div>{machine.number} </div>
                </div>
                <div className={"col-sm-5 bold"}>
                  Tentative Cost
                  <div> ₹ {machine.tentativeCost} </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Production level and Sales number{" "}
            </h4>
          </div>

          {forgingWork ? (
            <div className={"box-wrapper mt-20"}>
              <h4 className="box_sub_heading mt-0"> Forging Work</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  Consumption of Metal
                  <div>{forgingWork?.metalConsumption || "NA"} kg</div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Monthly sales / production
                  <div>{forgingWork?.monthlySalesProduction || "NA"} units</div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Average sales price per unit
                  <div>₹ {forgingWork?.averageSalesPricePerUnit || "NA"}</div>
                </div>
              </div>
            </div>
          ) : null}
          {manufacturingOfMotherMachine ? (
            <div className={"box-wrapper mt-20"}>
              <h4 className="box_sub_heading mt-0">
                {" "}
                Manufacturing of Mother Machine
              </h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  Consumption of Metal
                  <div>
                    {manufacturingOfMotherMachine?.metalConsumption || "NA"} kg
                  </div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Monthly sales / production
                  <div>
                    {manufacturingOfMotherMachine?.monthlySalesProduction ||
                      "NA"}{" "}
                    units
                  </div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Average sales price per unit
                  <div>
                    ₹{" "}
                    {manufacturingOfMotherMachine?.averageSalesPricePerUnit ||
                      "NA"}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {manufacturingOfHandTools ? (
            <div className={"box-wrapper mt-20"}>
              <h4 className="box_sub_heading mt-0">
                {" "}
                Manufacturing of Hand Tools
              </h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  Consumption of Metal
                  <div>
                    {manufacturingOfHandTools?.metalConsumption || "NA"} kg
                  </div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Monthly sales / production
                  <div>
                    {manufacturingOfHandTools?.monthlySalesProduction || "NA"}{" "}
                    units
                  </div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Average sales price per unit
                  <div>
                    ₹{" "}
                    {manufacturingOfHandTools?.averageSalesPricePerUnit || "NA"}
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {machineAndHandToolList?.map((tool, index) => (
            <div className={"box-wrapper mt-20"}>
              <h4 className="box_sub_heading mt-0"> {tool?.name}</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  Consumption of Metal<div> {tool?.metalConsumption} kg</div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Monthly sales / production
                  <div> {tool?.monthlySalesProduction} units</div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Average sales price per unit
                  <div> ₹ {tool?.averageSalesPricePerUnit}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Manufacturing Unit</h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Size of Manufacturing Unit (in sq. Yard)
              <div>
                {manufacturingUnit?.sizeOfManufacturingUnit || "NA"} sq. Yard
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
