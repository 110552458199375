const CHANGE_VALUE = Symbol('ChangeValue'),
    SET_INITIAL_RESPONSE = Symbol('SetInitialResponse'),
    SET_REFERENCES = Symbol('setReferences'),
    RESET_REDUX_STATE = Symbol('ResetReduxState'),
    ADD_ROW = Symbol('AddReferenceRow'),
    DELETE_ROW = Symbol('DeleteReferenceRow');

export {
    CHANGE_VALUE,
    SET_INITIAL_RESPONSE,
    SET_REFERENCES,
    RESET_REDUX_STATE,
    ADD_ROW,
    DELETE_ROW
};
