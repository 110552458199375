import React, { Component, Fragment } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ReactTable from "react-table";
import {
  unAssignedHeader,
  closedHeader,
  assignedHeader,
} from "./cpoPanel.config";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../store/actions";
import {
  getCPOAdminData,
  getUserRoleID,
  getOPSAdminFilteredApplication,
  setSearchCriteria,
  setPageNumber,
} from "./CPOAdmin.action";
import ContentLoader from "react-content-loader";
import { API_ASSIGN_APPLICATION_TO_CPO } from "../../utils/APIUrls";
import { POST } from "../../utils/webAPI.service";
import CredoModal from "../../components/CredoModal/CredoModal";
import RemoveUser from "./removeUser";
import Icon from "react-icons-kit";
import { userPlus } from "react-icons-kit/icomoon/userPlus";
import { userMinus } from "react-icons-kit/icomoon/userMinus";

class CPOPanelAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabApplicant: "All",
      currentPage: 1,
      applicationsData: [],
      prevBtn: false,
      nextBtn: true,
      assignedData: null,
      unassignedData: null,
      closedData: null,
      selectedIndex: "unassignedCases",
      selectedStatus: "Unassigned",
      selectedAssignee: "",
      obj: [],
      appNo: "",
      id: "",
      userId: "",
      index: "",
      expanded: {},
      modalIsOpen: false,
      rowData: {},
      coloumn: {},
    };
  }

  getPageData = (type) => {
    const { pageNumber, userData } = this.props;
    const { totalPages } = userData;
    let newPageNumber = pageNumber;
    if (type === "next" && pageNumber < totalPages) {
      newPageNumber++; // Increment page number for next
    } else if (type === "prev" && pageNumber > 1) {
      newPageNumber--; // Decrement page number for previous
    }
    if (newPageNumber !== pageNumber) {
      this.props.actions.setPageNumber(newPageNumber); // Dispatch the action to update the page number in Redux
      this.fetchDetails(newPageNumber, this.state.selectedStatus); // Fetch details with the new page number
    }
  };
  fetchDetails = async (pageNum, selectedStatus) => {
    let { actions } = this.props,
      { getCPOAdminData, loaderModal } = actions;
    let dataToSend = {
      pageNumber: pageNum || 1,
      dashboard: "CPO_ADMIN",
      searchValue: this.props.searchValue?.trim(),
      status: selectedStatus,
      searchType: this.props.searchBy,
    };
    loaderModal(true);
    await getCPOAdminData(dataToSend);
    loaderModal(false);
  };

  componentDidMount = async () => {
    this.fetchDetails(this.props.pageNumber, this.state.selectedStatus);
    this.props.actions.getUserRoleID("CPO_USER");
  };

  handleSelect = async (key, index) => {
    this.props.history.push(
      this.props.history.location.pathname + "?activetab=" + key
    );
    let { loaderModal } = this.props.actions;
    this.props.actions.setPageNumber(1);
    this.props.actions.setSearchCriteria("", "");
    if (key === "Unassigned") {
      this.setState({ selectedStatus: "Unassigned" }, async () => {
        if (this.fetchDetails) {
          loaderModal(true);
          await this.fetchDetails(
            this.props.pageNumber,
            this.state.selectedStatus
          );
          loaderModal(false);
        }
      });
    } else if (key === "Assigned") {
      this.setState({ selectedStatus: "Assigned" }, async () => {
        if (this.fetchDetails) {
          loaderModal(true);
          await this.fetchDetails(
            this.props.pageNumber,
            this.state.selectedStatus
          );
          loaderModal(false);
        }
      });
    } else if (key === "Closed") {
      this.setState({ selectedStatus: "Closed" }, async () => {
        if (this.fetchDetails) {
          loaderModal(true);
          await this.fetchDetails(
            this.props.pageNumber,
            this.state.selectedStatus
          );
          loaderModal(false);
        }
      });
    }
  };

  handleChange = async (e, values) => {
    let { roleId } = this.props;
    let { loaderModal } = this.props.actions;

    this.setState({
      selectedAssignee: e.target.value,
    });
    let applicationNumber = values.original.applicationNumber;
    let data = {
      applicationNumber,
      userId: roleId[e.target.selectedIndex - 1].userId,
    };
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    await POST(API_ASSIGN_APPLICATION_TO_CPO, { data }, { headers })
      .then(({ data }) => {
        let { status } = data;
        if (status === "success") {
          this.setState(
            {
              selectedAssignee: "",
              index2: "",
            },
            async () => {
              await this.fetchDetails(
                this.props.pageNumber,
                this.state.selectedStatus
              );
              loaderModal(false);
            }
          );
        }
      })
      .catch((err) => {
        loaderModal(false);
        console.log(err);
        window.alert("Something went wrong please try again later.");
      });
  };
  toggleModal = () => {
    this.fetchDetails(1, "Assigned");
    this.setState({ modalIsOpen: false });
  };
  modalHandler = (props) => {
    this.setState({ modalAppNumber: props.original.applicationNumber });
    this.setState({ modalIsOpen: true });
  };
  render() {
    let { roleId, userData, pageNumber } = this.props,
      { response } = userData;
    let { totalPages } = userData;
    var _unAssignedHeader = [
      ...unAssignedHeader,
      {
        Header: "Assign",
        accessor: "resolve",
        width: 130,
        Cell: (props) => {
          return (
            <>
              <Icon
                icon={userPlus}
                size={20}
                className={"user-plus__assign-user"}
              />
              <select
                className="select-box__assign-user"
                value={this.state.selectedAssignee || "select"}
                onChange={(e) => this.handleChange(e, props)}
              >
                <option>Select User</option>
                {nameArray.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
    ];
    var _assignedHeader = [
      ...assignedHeader,
      {
        Header: "Remove",
        accessor: "remove",
        Cell: (props) => (
          <span onClick={() => this.modalHandler(props)}>
            <Icon icon={userMinus} size={20} />
          </span>
        ),
        style: {
          color: "#E2574C",
        },
      },
      {
        Header: "Reassign",
        accessor: "Reassign",
        width: 130,
        Cell: (props) => {
          return (
            <>
              <Icon
                icon={userPlus}
                size={20}
                className={"user-plus__assign-user"}
              />
              <select
                className="select-box__assign-user"
                value={this.state.selectedAssignee || "select"}
                onChange={(e) => this.handleChange(e, props)}
              >
                <option>Select User</option>
                {nameArray.map((x, y) => (
                  <option key={y} value={x}>
                    {x}
                  </option>
                ))}
              </select>
            </>
          );
        },
      },
    ];
    var nameArray = roleId?.map(function (el) {
      return el.firstName;
    });
    return (
      <Fragment>
        {userData ? (
          <div className="cpo-dashboard__wrapper container">
            <Tabs
              defaultActiveKey={"Unassigned"}
              id="adminTabs"
              onSelect={this.handleSelect}
            >
              <Tab eventKey={"Unassigned"} title={"Unassigned Cases"}>
                <div className={"datatable-wrapper"}>
                  <ReactTable
                    columns={_unAssignedHeader}
                    data={userData?.list}
                    defaultPageSize={10}
                    showPagination={false}
                    resizable={false}
                    minRows={0}
                  />
                  <ul className={"datatable-pagination"}>
                    <li>
                      <button
                        disabled={!(pageNumber > 1)}
                        className={"prev-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("prev")}
                      >
                        Previous
                      </button>
                    </li>
                    <li>
                      <span>
                        Page No. {pageNumber} of {totalPages}
                      </span>
                    </li>
                    <li>
                      <button
                        disabled={!(totalPages > pageNumber)}
                        className={"next-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("next")}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab eventKey={"Assigned"} title={"Assigned Cases"}>
                <div className={"datatable-wrapper"}>
                  <ReactTable
                    columns={_assignedHeader}
                    data={userData?.list}
                    defaultPageSize={10}
                    showPagination={false}
                    resizable={false}
                    minRows={0}
                  />
                  <ul className={"datatable-pagination"}>
                    <li>
                      <button
                        disabled={!(pageNumber > 1)}
                        className={"prev-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("prev")}
                      >
                        Previous
                      </button>
                    </li>
                    <li>
                      <span>
                        Page No. {pageNumber} of {totalPages}
                      </span>
                    </li>
                    <li>
                      <button
                        disabled={!(totalPages > pageNumber)}
                        className={"next-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("next")}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </Tab>
              <Tab eventKey={"Closed"} title={"Closed Cases"}>
                <div className={"datatable-wrapper"}>
                  <ReactTable
                    columns={closedHeader}
                    defaultPageSize={10}
                    showPagination={false}
                    resizable={false}
                    minRows={0}
                    data={userData?.list}
                  />
                  <ul className={"datatable-pagination"}>
                    <li>
                      <button
                        disabled={!(pageNumber > 1)}
                        className={"prev-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("prev")}
                      >
                        Previous
                      </button>
                    </li>
                    <li>
                      <span>
                        Page No. {pageNumber} of {totalPages}
                      </span>
                    </li>
                    <li>
                      <button
                        disabled={!(totalPages > pageNumber)}
                        className={"next-btn"}
                        type={"button"}
                        onClick={() => this.getPageData("next")}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </Tab>
            </Tabs>
          </div>
        ) : (
          <ContentLoader height={150} type={"facebook"} />
        )}

        <CredoModal
          isOpen={this.state.modalIsOpen}
          className={"edit-ratio-modal"}
          props={this.props}
          applicationNumber={this.state.modalAppNumber}
          styles={{
            content: {
              width: "300px",
              borderRadius: "25px",
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
            },
          }}
          title={"Confirm User Removal"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={RemoveUser}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = ({ cpoAdminReducers }) => ({
  userData: cpoAdminReducers.userData,
  roleId: cpoAdminReducers.roleId,
  searchBy: cpoAdminReducers.searchBy,
  searchValue: cpoAdminReducers.searchValue,
  pageNumber: cpoAdminReducers.pageNumber,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getCPOAdminData,
      getUserRoleID,
      setPageNumber,
      setSearchCriteria,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(CPOPanelAdmin);
