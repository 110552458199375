import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import ToggleButton from "../../../components/ToogleButton/ToggleButton";
import DatePicker from "../../../components/LabelAnimation/DatePicker";
import CredoModal from "../../../components/CredoModal/CredoModal";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import Otp from "./Otp";
import { API_POST_CREATE_CUSTOMER } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { CheckBox } from "../../../components";
import {
  getCustomerDetails,
  getClusterTaxpayerData,
  getClusterStateList,
  resetOnboardingState,
} from "./onBoardingDetails.actions";
import { getDMY, numericInput } from "../../../utils/utitlity";
import { Header } from "../../../components";
import { Link } from "react-router-dom";
import {
  API_POST_CLUSTER_BY_STATE,
  API_POST_RE_TRIGGER_OTP,
} from "../../../utils/APIUrls";
import { get } from "lodash";
import { resetPhotoState } from "../Photo/Photo.actions";
import { resetClusterOverviewDetails } from "../ClusterPreview/clusterPreview.actions";
import DateDropdown from "../../../components/DateDropdown/DateDropdown";

class OnBoardingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      isBusinessGstRegistered: false,
      isGstValidated: false,
      businessCommencementDate: "",
      businessName: "",
      businessOwner: "",
      clusterName: "",
      clusterState: "",
      customerId: "",
      gstin: "",
      mobile: "",
      maxDate: new Date(),
      failureGstn: "",
      editMobileNumber: false,
      isMobileVerified: false,
      declaration: false,
    };
  }
  componentWillMount() {
    const {
        actions,
        customerDetails,
        taxpayerData,
        entityDocs,
        fetchedDocs,
        clusterOverviewDetails,
      } = this.props,
      {
        resetOnboardingState,
        resetPhotoState,
        resetClusterOverviewDetails,
      } = actions,
      newApplication = get(this, "props.location.state.newApplication", false);
    if (newApplication) {
      customerDetails && resetOnboardingState();
      taxpayerData.length && resetOnboardingState();
      entityDocs.length && resetPhotoState();
      fetchedDocs.length && resetPhotoState();
      clusterOverviewDetails.length && resetClusterOverviewDetails();
    }
    window.scroll(0, 0);
  }
  getClusterName = (state) => {
    var dataToSend = {
      data: {
        state,
      },
    };
    POST(API_POST_CLUSTER_BY_STATE, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({
            clusterByStateList: data.data.responseList,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  dateHandler = (e) => {
    this.setState({
      businessCommencementDate: getDMY(
        new Date(e?.getTime()).toLocaleDateString()
      ),
    });
  };
  testDateHandler = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  inputHandler = (e, id) => {
    let { name, value } = e.target;
    if (name === "clusterState") {
      this.getClusterName(value);
      this.setState({
        [name]: value,
      });
    } else if (name === "gstin") {
      this.setState({
        [name]: value.toUpperCase(),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  setDeclaration = (e) => this.setState({ declaration: e.target.checked });

  resendOtpHandler = (e, mobileNumber) => {
    e.preventDefault();
    let customerId = this.state.customerId;
    const data = { mobileNumber, customerId },
      headers = { "Content-Type": "application/json" };

    POST(API_POST_RE_TRIGGER_OTP, { data }, { headers })
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({
            mobile: mobileNumber,
            editMobileNumber: false,
          });
        } else if (data.status === "failure") {
          alert(data.message);
        }
        loaderModal(false);
      })
      .catch((err) => {
        loaderModal(false);
        window.alert("Something went wrong please try again later.");
      });
  };

  submitForm = (e) => {
    e.preventDefault();
    const { actions, match, history } = this.props,
      {
        isBusinessGstRegistered,
        clusterName,
        clusterState,
        mobile,
        businessOwner,
        businessCommencementDate,
        businessName,
        isGstValidated,
        isMobileVerified,
      } = this.state;
    let gstin = this.state.gstin.split(" - ").join("");
    let data = {
      gstin,
      isBusinessGstRegistered,
      clusterName,
      clusterState,
      customerId: match.params.custId,
      mobile,
      businessOwner,
      businessCommencementDate,
      businessName,
      isGstValidated,
    };
    actions.loaderModal(true);
    POST(API_POST_CREATE_CUSTOMER, { data })
      .then(({ data }) => {
        if (data.status === "success") {
          let custId = data.data && data.data.customerId;
          if (isMobileVerified && custId) {
            return history.push(
              `/cluster/${custId}/business-details/${clusterName
                .split(" ")
                .join("-")}`
            );
          }
          this.setState({
            modalOpen: true,
            customerId: custId,
            editMobileNumber: false,
          });
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
        actions.loaderModal(false);
        throw err;
      });
  };

  componentDidMount = async () => {
    const { actions, match } = this.props;
    actions.getClusterStateList().catch((err) => {
      window.alert("Something went wrong please try again later.");
    });
    if (match.params.custId) {
      actions
        .getCustomerDetails(match.params.custId)
        .then(({ data, status }) => {
          if (status === "success") {
            this.getClusterName(data.clusterState);
            this.setState({
              gstin: data.gstin,
              isBusinessGstRegistered: data.isBusinessGstRegistered,
              clusterName: data.clusterName,
              clusterState: data.clusterState,
              customerId: data.customerId,
              mobile: data.mobile,
              businessOwner: data.businessOwner,
              businessCommencementDate: data.businessCommencementDate,
              businessName: data.businessName,
              isMobileVerified: data.isMobileVerified,
            });
          }
        })
        .catch((err) => {
          window.alert("Something went wrong please try again later.");
        });
    }
  };

  fetchGstnData = () => {
    const { actions } = this.props;
    actions.loaderModal(true);
    if (!!this.state.gstin) {
      let gstin = this.state.gstin.split(" - ").join("");
      actions
        .getClusterTaxpayerData(gstin)
        .then(({ data, status }) => {
          actions.loaderModal(false);
          if (status === "success") {
            document.cookie = `gstin=${gstin}; path=/cluster`;
            this.setState({
              isGstValidated: true,
              businessName: data.businessTradeName,
              failureGstn: false,
            });
          } else {
            this.setState({
              failureGstn: true,
              isGstValidated: false,
            });
          }
        })
        .catch((err) => {
          actions.loaderModal(false);
          this.setState({
            failureGstn: true,
          });
          window.alert("Something went wrong please try again later.");
        });
    } else {
      alert("Oops! Please fill the details for GSTN");
      actions.loaderModal(false);
    }
  };

  editStateChange = () => {
    this.setState({
      editMobileNumber: true,
    });
  };

  toggleHandler = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  render() {
    let {
        modalOpen,
        clusterName,
        clusterState,
        isBusinessGstRegistered,
        gstin,
        isGstValidated,
        businessOwner,
        mobile,
        businessName,
        businessCommencementDate,
        clusterByStateList,
        declaration,
      } = this.state,
      { clusterStateList } = this.props;

    return (
      <>
        <Header {...this.props} />
        <div className="onBoarding-wrapper">
          <div className="container">
            <Link to={`/cluster/dashboard`} className={"back-btn-link"}>
              <Icon icon={angleLeft} size={24} /> Back
            </Link>
            <h1 className="container__heading">Onboarding Details</h1>
            <div className=" container cluster-details  p-top--sub m-top--main">
              <h2 className="sub-heading">Cluster Details </h2>
              <div className={"row"}>
                <div className={"col-sm-6 col-xs-12 m-top--main p-left"}>
                  <select
                    className={"select-box"}
                    name={"clusterState"}
                    onChange={this.inputHandler}
                    value={clusterState}
                  >
                    <option>Select State</option>
                    {clusterStateList?.map((item, index) => {
                      return (
                        <option
                          name={item}
                          value={item}
                          key={`cluster-state-${index}`}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className={"col-sm-6 col-xs-12 m-top--main "}>
                  <select
                    className={"select-box"}
                    name={"clusterName"}
                    onChange={this.inputHandler}
                    value={clusterName}
                  >
                    <option>Select Cluster </option>
                    {clusterByStateList?.map((item, index) => {
                      return (
                        <option
                          name={item}
                          value={item}
                          key={`cluster-by-state-${index}`}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="gst-registration container">
              <h2 className="sub-heading">GST registration</h2>
              <div className="container-sub">
                <div className={"row "}>
                  <div className={"col-sm-8 col-xs-6 p-left"}>
                    <h5 className="text-decor">
                      Is your business registered under GST:
                    </h5>
                  </div>
                  <div className={"col-sm-2 col-xs-6"}>
                    <div className="btn-toggle">
                      <ToggleButton
                        toggleHandler={(name) => this.toggleHandler(name)}
                        name="isBusinessGstRegistered"
                      />
                    </div>
                  </div>
                </div>
                {isBusinessGstRegistered && (
                  <div className={"row"}>
                    {isGstValidated ? (
                      <>
                        <div className={"col-sm-7  col-xs-8 p-left"}>
                          <label className="bold">GST Number</label>
                          <div className="value">{gstin}</div>
                        </div>
                        <div className={"col-sm-5 col-xs-4"}>
                          <div className="success-message">
                            GST Fetch Successful
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={"col-sm-4 col-xs-12 p-left"}>
                          <InputLabelAnimation
                            labelText={"GST Number"}
                            required={true}
                            type={"text"}
                            name={"gstin"}
                            className="gstn-input"
                            onChange={this.inputHandler}
                            inputValue={gstin}
                            mask={"** - ********** - ***"}
                          />
                        </div>
                        {this.state.failureGstn && (
                          <div className={"col-sm-2 col-xs-12 red p-left"}>
                            Failed, try again !!
                          </div>
                        )}

                        <div className={"col-sm-6 col-xs-12"}>
                          <button
                            onClick={this.fetchGstnData}
                            className="btn btn-decor"
                          >
                            Fetch GST Details
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="business-registration container">
              <h2 className="sub-heading">Business registration</h2>
              <div className={"row "}>
                <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                  <InputLabelAnimation
                    labelText={"Business Owner Name"}
                    required={true}
                    type={"text"}
                    name={"businessOwner"}
                    onChange={this.inputHandler}
                    inputValue={businessOwner}
                  />
                </div>
                <div className={"col-sm-6 m-top--main col-xs-12"}>
                  <InputLabelAnimation
                    labelText={"Mobile Number"}
                    required={true}
                    type={"text"}
                    name={"mobile"}
                    onChange={(e) =>
                      numericInput(e.target.value, (value) =>
                        this.setState({ mobile: value })
                      )
                    }
                    inputValue={mobile}
                    maxLength={10}
                  />
                </div>
              </div>
              <div className={"row "}>
                <div className={"col-sm-12 col-xs-12 p-left"}>
                  <InputLabelAnimation
                    labelText={"Business Name (Entity Name)"}
                    required={true}
                    type={"text"}
                    name={"businessName"}
                    onChange={this.inputHandler}
                    inputValue={businessName}
                  />
                </div>
              </div>
              <div className={"row "}>
                <div className={"col-sm-6 col-xs-12"}>Commencement date:</div>
                {/* <div className={"col-sm-6 m-top--main col-xs-12"}>
                  <DatePicker
                    autocomplete="off"
                    required={true}
                    name={"businessCommencementDate"}
                    labelText={"Business Commencement Date (DD/MM/YYYY)"}
                    selected={businessCommencementDate ? new Date(businessCommencementDate.split("-").reverse()) : null}
                    onChange={this.dateHandler}
                    maxDate={new Date(this.state.maxDate)}
                  />
                </div> */}
                <div className={"col-sm-6 m-top--main col-xs-12"}>
                  <DateDropdown
                    onDateChange={this.testDateHandler}
                    componentName="businessCommencementDate"
                    value={businessCommencementDate || ""}
                  />
                </div>
              </div>
            </div>
            <CredoModal
              isOpen={modalOpen}
              className={"red-flag__wrapper normal-curved-borders"}
              styles={{
                content: {
                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
                  border: "solid 1px #eeeeee",
                  backgroundColor: "#ffffff",
                },
              }}
              mobileNumber={mobile}
              closeAction={this.toggleModal}
              RenderingComponent={Otp}
              PropsData={this.props}
              stateData={this.state}
              resendOtpHandler={this.resendOtpHandler}
              editStateChange={this.editStateChange}
            />
            <div className="checkbox-container">
              <CheckBox
                labelColor={"#1d1d1d"}
                checkboxColor={"#36ddd1"}
                name={"declarations"}
                labelClass={"input-label"}
                htmlFor={"declarations"}
                checked={declaration}
                onChange={this.setDeclaration}
              />
              <div className="checkbox-container__text">
                By checking this box, you agree to allow Pemant to fetch
                your credit report from CRIF High Mark. You also agree to the{" "}
                <span
                  onClick={() => {
                    this.props.history.push("/cluster/terms-and-condition");
                  }}
                >
                  CRIF High Mark Credit Score Terms of Use.
                </span>
              </div>
            </div>

            <div className="text-center">
              {declaration ? (
                <div
                  className="btn btn-save"
                  onClick={this.submitForm}
                  type={"submit"}
                >
                  Save & Next
                </div>
              ) : (
                <div className="btn btn-disabled" >
                  Save & Next
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  onBoardingReducer,
  ClusterPhoto,
  clusterPreviewReducer,
}) => ({
  customerDetails: onBoardingReducer.customerDetails,
  taxpayerData: onBoardingReducer.taxpayerData,
  clusterStateList: onBoardingReducer.clusterStateList,
  clusterByStateList: onBoardingReducer.clusterByStateList,
  entityDocs: ClusterPhoto.entityDocs,
  fetchedDocs: ClusterPhoto.fetchedDocs,
  clusterOverviewDetails: clusterPreviewReducer.clusterOverviewDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCustomerDetails,
      getClusterTaxpayerData,
      getClusterStateList,
      loaderModal,
      resetPhotoState,
      resetClusterOverviewDetails,
      resetOnboardingState,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingDetails);
