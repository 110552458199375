const FETCH_CLUSTER_STATES = Symbol("FetchClusterStates"),
  FETCH_CUSTOMER_DETAILS = Symbol("FetchCustomerDetails"),
  FETCH_TAXPAYER_DATA = Symbol("FetchTaxPayerData"),
  FETCH_CLUSTER_BY_STATE = Symbol("FetchClusterbyState"),
  RESET_REDUX_STATE = Symbol("resetRedux");


export {
  FETCH_CLUSTER_STATES,
  FETCH_CUSTOMER_DETAILS,
  FETCH_TAXPAYER_DATA,
  FETCH_CLUSTER_BY_STATE,
  RESET_REDUX_STATE
};
