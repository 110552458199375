import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import {
  API_GET_BUSINESS_COST_ANALYSIS,
  API_GET_BUSINESS_DETAILS,
} from "../../../../../utils/APIUrls";
import { GET } from "../../../../../utils/webAPI.service";
import { hosieryPartsArray } from "../businessCostAnalysis.config";

export default class HosieryBusinessCostAnalysis extends Component {
  state = {
    hosieryCostAnalysis: {},
  };
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_DETAILS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.businessName) {
        let { businessName } = data.data;
        this.setState({ businessName });
      }
    });
    GET(API_GET_BUSINESS_COST_ANALYSIS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.hosieryCostAnalysis) {
        let { hosieryCostAnalysis } = data.data;
        this.setState({ hosieryCostAnalysis });
      }
    });
  };
  render() {
    let { hosieryCostAnalysis, businessName } = this.state,
      {
        businessDetails = {},
        hosieryAndReadymadeGarments = {},
        productionLevelAndSales = {},
      } = hosieryCostAnalysis,
      {
        knittingWoolenGarments,
        readymadeGarments,
      } = hosieryAndReadymadeGarments,
      { labour, machines, manufacturingUnit, purchase } = businessDetails,
      { screenRenderHandler } = this.props;

    const productionLevelAndSalesList = {},
      hosieryAndReadymadeGarmentsList = {};
    Object.entries(productionLevelAndSales || {})
      .filter(([, value]) => value !== null)
      .forEach(([key, value]) => (productionLevelAndSalesList[key] = value));

    Object.entries(hosieryAndReadymadeGarments || {})
      .filter(([, value]) => value !== null)
      .forEach(
        ([key, value]) => (hosieryAndReadymadeGarmentsList[key] = value)
      );

    var productionLevelAndSalesListItems = Object.keys(
      productionLevelAndSalesList || {}
    ).map((key) => {
      return (
        <div key={key}>
          {hosieryPartsArray.map((part, i) => {
            if (key === part.name) {
              return (
                <div className={"box-wrapper mt-20"}>
                  <h4 className="box_sub_heading mt-0" key={i}>
                    {part.label}
                  </h4>
                  <div className={"row"}>
                    <div className={"col-sm-4 bold"}>
                      Monthly sales / production
                      <div>
                        {productionLevelAndSalesList[key]?.monthlySalesUnits}{" "}
                        units
                      </div>
                    </div>
                    <div className={"col-sm-4 bold"}>
                      Average sales price per unit
                      <div>
                        {" "}
                        ₹{" "}
                        {productionLevelAndSalesList[key]?.averagePricePerUnit}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    });

    var hosieryAndReadymadeGarmentsListItems = Object.keys(
      hosieryAndReadymadeGarmentsList || {}
    ).map((key, index) => {
      return hosieryPartsArray.map((part, i) => {
        if (
          key === part.name &&
          key !== "knittingWoolenGarments" &&
          key !== "readymadeGarments"
        ) {
          return (
            <div className={" box-wrapper mt-20  w-50"} key={key}>
              <h4 className="box_sub_heading mt-0" key={i}>
                {part.label}
              </h4>
              <div className={"row"}>
                <div className={"col-sm-3 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Hosiery/${part.label}.png`)}
                    alt={key}
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>{hosieryAndReadymadeGarmentsList[key]} %</div>
                </div>
              </div>
            </div>
          );
        }
      });
    });

    return (
      <div className={"bicycle-analysis-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Hosiery Business Cost</title>
        </Helmet>
        <div className="container_top">
          <div
            className={"back-btn-link"}
            onClick={() => screenRenderHandler("overview-details")}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>

          <h3 className="container_heading">
            {businessName} - Operational Details
          </h3>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Hosiery and Readymade Garments manufactured
            </h4>
          </div>
          {hosieryAndReadymadeGarmentsListItems}
          {knittingWoolenGarments ? (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">
                Knitting Woollen Garments
              </h4>
              <div className={"row"}>
                <div className={"col-sm-3 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Hosiery/Knitting  Woollen Garments.png`)}
                    alt={"Knitting  Woollen Garments"}
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-4 bold"}>
                  Type
                  <div>
                    {knittingWoolenGarments?.category?.map((garment, index) => (
                      <span>{garment} </span>
                    ))}
                  </div>
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>{knittingWoolenGarments?.profitMargin} %</div>
                </div>
              </div>
            </div>
          ) : null}

          {readymadeGarments ? (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">Readymade Garments</h4>
              <div className={"row"}>
                <div className={"col-sm-3 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Hosiery/Readymade Garments.png`)}
                    alt={"Readymade Garments"}
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-4 bold"}>
                  Type
                  <div>
                    {readymadeGarments?.category?.map((garment, index) => (
                      <span>{garment} </span>
                    ))}
                  </div>
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>{readymadeGarments?.profitMargin} %</div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Production level and Sales number{" "}
            </h4>
          </div>
          {productionLevelAndSalesListItems}
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Machines </h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Number of Embroidery Machines{" "}
              <div>{machines?.embroideryMachines}</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Number of stitching machines{" "}
              <div>{machines?.stichingMachines}</div>
            </div>
          </div>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Purchase </h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Fabric purchased in month{" "}
              <div>{purchase?.fabricPurchasedMonthly} sq. Meter</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Average expense for fabric in a month{" "}
              <div> ₹ {purchase?.averageExpenseOnFabricMonthly}</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Monthly cost of accessories purchased{" "}
              <div> ₹ {purchase?.monthlyAccessoriesPurchasedCost}</div>
            </div>
          </div>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Manufacturing Unit </h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-6 bold"}>
              Size of Manufacturing Unit (in sq. Yard){" "}
              <div>{manufacturingUnit?.sizeOfManufacturingUnit} sq. Yard</div>
            </div>
          </div>
        </div>
        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading"> Labour </h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Number of labour deployed on machine{" "}
              <div>{labour?.labourDeployedOnMachine}</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Number of other helpers <div>{labour?.otherHelper}</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Total cost of labour per month{" "}
              <div> ₹ {labour?.totalCostPerMonth}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
