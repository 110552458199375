const GET_CPO_USER_APPLICATION = Symbol("getOPSUserApplication"),
  GET_EXCEPTION_DATA = Symbol("getExceptionData"),
  TRIGGER_CIBIL = Symbol("triggerCibil"),
  APPLICATION_FILLED_BY = Symbol("applicationFilledBy"),
  SET_SEARCH_CRITERIA = Symbol("setSearchCriteria"),
  SET_PAGE_NUMBER = "SET_PAGE_NUMBER";

export {
  GET_CPO_USER_APPLICATION,
  GET_EXCEPTION_DATA,
  TRIGGER_CIBIL,
  APPLICATION_FILLED_BY,
  SET_SEARCH_CRITERIA,
  SET_PAGE_NUMBER,
};
