import React, { Component } from "react";
import { Navbar, Nav, NavItem } from "react-bootstrap";

class LeadManagementNavs extends Component {
  clickHandle = (e, name) => {
    let { match, history } = this.props;
    const { custId } = match.params;
    if (e.ctrlKey) {
      if ((e.ctrlKey || e.metaKey) && name === "overview") {
        window.open(`/lead_manager/${custId}/overview`);
      } else if ((e.ctrlKey || e.metaKey) && name === "leadCreditBehaviour") {
        window.open(`/lead_manager/${custId}/leadCreditBehaviour`);
      }
    } else if (e.button === 0) {
      if (name === "overview") {
        history.push(`/lead_manager/${custId}/overview`);
      } else if (name === "leadCreditBehaviour") {
        history.push(`/lead_manager/${custId}/leadCreditBehaviour`);
      }
    }
  };

  render() {
    let { active } = this.props;
    return (
      <section className={"credit-navs-wrapper credit-navs-wrapper-lead-manager"}>
        <Navbar>
          <Navbar.Header>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav className="credit-navs" activeKey={active}>
              <NavItem
                eventKey="overview"
                onClick={(e) => this.clickHandle(e, "overview")}
              >
                Overview
              </NavItem>
              <NavItem
                eventKey="leadCreditBehaviour"
                onClick={(e) => this.clickHandle(e, "leadCreditBehaviour")}
              >
                Credit Behaviour
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </section>
    );
  }
}

LeadManagementNavs.defaultProps = {
  active: "",
};

export default LeadManagementNavs;
