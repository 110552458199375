import React, { Component } from "react";

class BankAccountDetails extends Component {
  render() {
    let { PropsData } = this.props;

    return (
      <div className="bank-details-wrapper">
        <h2> Bank Account Details</h2>
        {PropsData?.bankAccounts?.map((e, index) => {
          return (
            <div className={" shadow-box m-around"} key={`account-${index}`}>
              <span className="p-left">{`Account ${index + 1}`} </span>
              <div className={"row"}>
                <div className={"col-sm-4 block "}>
                  Bank
                  <div>{e.bankName || "-"}</div>
                </div>
                <div className={"col-sm-4 block "}>
                  Account type
                  <div>{e.accountType || "-"}</div>
                </div>
                <div className={"col-sm-4  block"}>
                  Account Number
                  <div>{e.accountNumber || "-"}</div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-sm-4 block "}>
                  Since
                  <div>{`${e.months} ${e.years}` || "-"}</div>
                </div>
                <div className={"col-sm-4 block "}>
                  Transactions kind
                  <div>{e.transactionType || "-"}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default BankAccountDetails;
