const SET_INITIAL_RESPONSE = Symbol('SetInitialResponse'),
    CHANGE_ROW_VALUES = Symbol('ChangeRowValues'),
    ADD_ROW = Symbol('AddRow'),
    DELETE_ROW = Symbol('DeleteRow'),
    SET_SHAREHOLDING_DETAILS = Symbol('SetShareholdingDetails'),
    SET_ENTITY_DETAILS = Symbol('SetEntityDetails'),
    SET_CONSTITUTION_RELATIONS = Symbol('SetConstitutionRelations'),
    RESET_REDUX_STATE = Symbol('ResetReduxState');

export {
    SET_INITIAL_RESPONSE,
    CHANGE_ROW_VALUES,
    ADD_ROW,
    DELETE_ROW,
    SET_ENTITY_DETAILS,
    SET_SHAREHOLDING_DETAILS,
    SET_CONSTITUTION_RELATIONS,
    RESET_REDUX_STATE
}
