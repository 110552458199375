import * as ACTION_CONSTANT from "./header.action.constant";
import { GET } from "../../utils/webAPI.service";
import { API_GET_NOTIFICATION_FETCH } from "../../utils/APIUrls";

/**
 * @param name: field Name
 * @param val: field Value
 * @returns {{type: string, data: *, name: string}}
 */
const changeValue = (name, val) => {
  return { type: ACTION_CONSTANT.CHANGE_VALUE, data: val, name: name };
};

export const setInputField = (value) => ({
  type: ACTION_CONSTANT.SET_INPUT_FIELD,
  data: value,
});

/**
 * Attach Handler for Filter in Input and select boxes
 * @param callback
 * @returns {{type, callback}}
 */
const updateFilterHandler = (callback) => {
  return { type: ACTION_CONSTANT.ATTACH_FILTER_HANDLER, callback };
};

export const clearHeaderState = () => ({
  type: ACTION_CONSTANT.CLEAR_HEADER_STATE,
});

const fetchNotifications = (userId) => (dispatch) => {
  GET(API_GET_NOTIFICATION_FETCH(userId))
    .then(({ data }) => {
      if (data.data) {
        dispatch({ type: ACTION_CONSTANT.FETCH_NOTIFICATION, data: data.data });
      }
    })
    .catch((err) => {
      throw err;
    });
};

export { changeValue, updateFilterHandler, fetchNotifications };
