import * as BankingConstants from './banking.actions.constant';
import * as BankingSelectors from "./banking.selector";

const initialState = {
    bankingDetails: [],
    initialResponse: false,
};

export const bankingReducers = (state = initialState, action) => {
    const {data, type, value, name, index, status, formIndex, fileIndex} = action;
    switch (type) {
        case BankingConstants.SET_BANKING_DETAILS:
            return {...state, bankingDetails: data};
        case BankingConstants.RESET_REDUX_STATE:
            return {...state, bankingDetails: []};
        case BankingConstants.SET_INITIAL_RESPONSE:
            return {...state, initialResponse: status};
        case BankingConstants.CHANGE_VALUE:
            return BankingSelectors._updateFormValues(state, name, value, index);
        case BankingConstants.CHANGE_FILE_VALUES:
            return BankingSelectors._updateFileValues(state, name, value, formIndex, fileIndex);
        case BankingConstants.DELETE_FORM_BOX:
            return BankingSelectors._deleteFormBox(state, index);
        case BankingConstants.ADD_FORM_BOX:
            return BankingSelectors._addFormBox(state);
        case BankingConstants.DELETE_FILE_ROW:
            return BankingSelectors._deleteFileRow(state, formIndex, fileIndex);
        case BankingConstants.ADD_FILE_ROW:
            return BankingSelectors._addFileRow(state, formIndex);
        default:
            return state;
    }
};
