import * as ACTION_CONSTANT from "./header.action.constant";
import * as Header_Selector from "./header.selector";

const initialState = {
  form: {
    values: {},
    errors: {},
    notificationData: [],
  },
  filterOptions: [],
  filterHandler: () => {},
  inputField: false,
};

const headerReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ACTION_CONSTANT.CHANGE_VALUE:
      return Header_Selector._updateFormValues(state, action.name, action.data);
    case ACTION_CONSTANT.UPDATE_FILTER_OPTIONS:
      return { ...state, filterOptions: action.data };
    case ACTION_CONSTANT.ATTACH_FILTER_HANDLER:
      return { ...state, filterHandler: action.callback };
    case ACTION_CONSTANT.FETCH_NOTIFICATION:
      return { ...state, notificationData: data };
    case ACTION_CONSTANT.SET_INPUT_FIELD:
      return { ...state, inputField: data };
    case ACTION_CONSTANT.CLEAR_HEADER_STATE:
      return initialState;
    default:
      return state;
  }
};

export { headerReducer };
