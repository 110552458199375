import axios from "axios";
import { getCookie } from "./utitlity";

export const BASE_URL = "/Backend";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const createHeader = (_URL, options = {}) => {
  let tokenId = getCookie("token");
  options = { ...options, headers: { tokenId } };
  return { URL: `${BASE_URL}${_URL}`, options: options };
};

const POST = (_URL, data = null, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.post(URL, data, options);
};

const GET = (_URL, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.get(URL, options);
};

const PUT = (_URL, data = null, _options) => {
  let { URL, options } = createHeader(_URL, _options);
  return axios.put(URL, data, options);
};

export { POST, GET, PUT };
