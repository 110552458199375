import * as ClusterPreviewConstants from "./clusterPreview.actions.constant";

const initialState = {
  clusterOverviewDetails: {},
};

export const clusterPreviewReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ClusterPreviewConstants.RESET_REDUX_STATE:
      return { ...state, clusterOverviewDetails: {} };
    case ClusterPreviewConstants.FETCH_CLUSTER_OVERVIEW:
      return { ...state, clusterOverviewDetails: data };
    default:
      return state;
  }
};
