import * as SourcingDetailsConstant from "./sourcingDetails.actions.constants";
import * as SourcingDetailSelectors from "./sourcingDetails.selectors";

const initialState = {
  sourcingDetails: {},
};

export const sourcingDetailsReducer = (state = initialState, action) => {
  const { data, type, val, name } = action;
  switch (type) {
    case SourcingDetailsConstant.RESET_REDUX_STATE:
      return { ...state, sourcingDetails:{}};
    case SourcingDetailsConstant.SET_SOURCING_DETAILS:
      return { ...state, sourcingDetails: data };
    case SourcingDetailsConstant.CHANGE_VALUE:
      return SourcingDetailSelectors._updateFormValues(state, val, name);
    
    default:
      return state;
  }
};
