import React, { Component } from 'react';
import { API_DOC_PREVIEW } from '../../../utils/APIUrls';
import axios from 'axios';
import { getCookie } from '../../../utils/utitlity';

export class PreviewModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      docId: '',
      loan: '',
      remarks: '',
      status: '',
      url: '',
      rotation: 0,
    };
  }

  
  // getCpoDocument = () => {
  //   let { docId } = this.props;
  //   let url = API_DOC_PREVIEW(docId);
  //   let token = getCookie("token");
  //   const headers = {
  //     tokenId: token,
  //   };
  //   axios({
  //     url: `${API_DOC_PREVIEW(docId)}`,
  //     method: 'GET',
  //     headers: headers,
  //     responseType: 'blob', // Important
  //   })
  //     .then((response) => response?.blob())
  //     .then((blob) => {
  //       // Create an object URL from the Blob
  //       const objectURL = URL.createObjectURL(blob);
  //       this.setState({
  //         image: objectURL,
  //       });
  //     });
  // };

  getCpoDocument = () => {
    let { docId } = this.props;
    let token = getCookie("token");
    const headers = {
      tokenId: token,
    };
  
    axios({
      url: `${API_DOC_PREVIEW(docId)}`,
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Important
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const objectURL = URL.createObjectURL(blob);
      this.setState({
        image: objectURL,
      });
    })
    .catch((error) => {
      console.error('Error fetching document:', error);
    });
  };
  

  componentDidMount = () => {
    this.getCpoDocument();
  };
  render() {
    let{fileName,docId}=this.props;
    return (
      <>
      <div className='row'>      
  {fileName?.includes('pdf') ? (
              <iframe
                title='pdfDoc'
                className='promoter_img width col-12'
                src={this.state.image}
              ></iframe>
            ) : (
              <img
                src={this.state.image}
                alt='user_profile'
                className='promoter_img  col-12'
              />
            )}
        </div>
      </>
    );
  }
}


