import {
  API_GET_ADMIN_USER_DATA,
  API_GET_USER_BY_ROLE_ID,
  API_CPO_SEARCH,
} from "../../utils/APIUrls";
import { GET, POST } from "../../utils/webAPI.service";
import * as CPOAdminConstant from "./CPOAdmin.action.constant";

const getCPOAdminData = (data) => (dispatch) => {
  return POST(API_CPO_SEARCH, data)
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        dispatch({
          type: CPOAdminConstant.SET_USER_ADMIN_DATA,
          data: response.data,
        });
      }
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
const setSearchCriteria = (searchBy, searchValue) => {
  return {
    type: CPOAdminConstant.SET_SEARCH_CRITERIA,
    payload: { searchBy, searchValue },
  };
};
const setPageNumber = (pageNumber) => {
  return {
    type: CPOAdminConstant.SET_PAGE_NUMBER,
    payload: { pageNumber },
  };
};

const getOPSAdminFilteredApplication = (data) => (dispatch) => {
  return POST(API_CPO_SEARCH, data)
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({
          type: CPOAdminConstant.SET_USER_ADMIN_DATA,
          data: data?.data,
        });
      } else {
        alert(data.message);
        dispatch({
          type: CPOAdminConstant.SET_USER_ADMIN_DATA,
          data: data?.data,
        });
      }
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

const getUserRoleID = (name) => {
  return (dispatch) => {
    return GET(API_GET_USER_BY_ROLE_ID(name))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: CPOAdminConstant.GET_USER_ROLE_ID,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const changeAddressBoxAddressInputValue = (val, name, formIndex, index) => ({
  type: CPOAdminConstant.CHANGE_ADDRESS_BOX_ADDRESS_VALUE,
  val,
  name,
  formIndex,
  index,
});

export {
  getCPOAdminData,
  getUserRoleID,
  changeAddressBoxAddressInputValue,
  getOPSAdminFilteredApplication,
  setSearchCriteria,
  setPageNumber,
};
