import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { API_POST_RE_TRIGGER_OTP, API_POST_VALIDATE_CUSTOMER_OTP } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";

class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: "",
      otp: null,
    };
  }

  componentDidMount() {
    this.setState({
      mobileNumber: this.props.mobileNumber,
    });
  }

  onChange = async (e) => {
    await this.setState({
      otp: e.target.value,
    });
    if (this.state.otp && this.state.otp.length === 6) {
      this.submitOtp(e);
    }
  };

  submitOtp = (e) => {
    e.preventDefault();
    loaderModal(true);
    let otp = this.state.otp,
      { customerId, clusterName } = this.props.stateData;

    const data = { otp, customerId },
      headers = { "Content-Type": "application/json" };

    POST(API_POST_VALIDATE_CUSTOMER_OTP, { data }, { headers })
      .then(({ data }) => {
        if (data.status === "success") {
          this.props.PropsData.history.push(`/cluster/${customerId}/business-details/${clusterName.split(" ").join("-")}`);
        } else {
          alert(data.message);
        }

        loaderModal(false);
      })
      .catch((err) => {
        loaderModal(false);
        window.alert("Something went wrong please try again later.");
      });
  };
  editStateChange = () => {
    this.setState({ otp: "" });
    this.props.editStateChange();
  };
  resendOtp = () => {
    let mobileNumber = this.state.mobileNumber,
      customerId = this.props.stateData && this.props.stateData.customerId;
    const data = { mobileNumber, customerId },
      headers = { "Content-Type": "application/json" };

    POST(API_POST_RE_TRIGGER_OTP, { data }, { headers })
      .then(({ data }) => {
        if (data.status === "success") {
        } else if (data.status === "failure") {
          alert(data.message);
        }
        this.setState({});
        loaderModal(false);
      })
      .catch((err) => {
        loaderModal(false);
        window.alert("Something went wrong please try again later.");
      });
  };
  inputHandler = (e) => {
    this.setState({
      mobileNumber: e.target.value,
    });
  };
  render() {
    return (
      <>
        {this.props.stateData.editMobileNumber ? (
          <div className="otp-wrapper">
            <div className="resend-otp">
              <div className={"row "}>
                <div className={"col-sm-12 "}>
                  <InputLabelAnimation
                    labelText={"Enter Mobile Number"}
                    required={true}
                    type={"text"}
                    name={"mobile"}
                    onChange={this.inputHandler}
                    inputValue={this.state.mobileNumber ? this.state.mobileNumber : ""}
                  />
                </div>
              </div>
              <div className={"row "}>
                <div style={{ textAlign: "center" }} className={"col-sm-12"}>
                  <button
                    className="otp-button"
                    onClick={(event) => this.props.resendOtpHandler(event, this.state.mobileNumber)}
                  >
                    Resend otp
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="otp-wrapper">
            <div className="otp-heading ">Enter OTP </div>
            <h5 style={{ marginBottom: "4px" }}>Mobile Number</h5>
            <span className="otp-num">{this.state.mobileNumber}</span>
            <span className="otp-edit ml-1" onClick={this.editStateChange}>
              <Icon icon={ic_edit} size={18} />
            </span>
            <div>
              <input
                id="otp"
                type="text"
                maxLength={6}
                value={this.state.otp ? this.state.otp : ""}
                name={"otp"}
                onChange={this.onChange}
              />
            </div>
            <div className="otp-edit otp-resend" onClick={this.resendOtp}>
              Resend Otp
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ onBoardingReducer }) => ({});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
