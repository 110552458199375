import * as BankingConstants from "./banking.actions.constant";

const setBankList = (list) => ({
    type: 'SET_BANK_NAMES_LIST',
    list
});

const setBankDetails = (data) => ({
    type: BankingConstants.SET_BANKING_DETAILS,
    data
});

const setInitialResponse = (status) => ({
    type: BankingConstants.SET_INITIAL_RESPONSE,
    status
});

const deleteFormBox = (index) => ({
    type: BankingConstants.DELETE_FORM_BOX,
    index
});

const addFormBox = () => ({
    type: BankingConstants.ADD_FORM_BOX
});

const deleteFileRow = (formIndex, fileIndex) => ({
    type: BankingConstants.DELETE_FILE_ROW,
    formIndex, fileIndex
});

const addFileRow = (formIndex) => ({
    type: BankingConstants.ADD_FILE_ROW,
    formIndex
});

const changeInputValue = (name, value, index) => ({
    type: BankingConstants.CHANGE_VALUE,
    name,
    value,
    index
});

const changeFileValues = (name, value, formIndex, fileIndex) => ({
    type: BankingConstants.CHANGE_FILE_VALUES,
    name, value, formIndex, fileIndex
});

const resetBankingState = () => ({type: BankingConstants.RESET_REDUX_STATE});

export {
    setBankList,
    setBankDetails,
    setInitialResponse,
    deleteFormBox,
    addFormBox,
    deleteFileRow,
    addFileRow,
    changeInputValue,
    changeFileValues,
    resetBankingState
}
