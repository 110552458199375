// Get List Data
const API_GET_PRIMARY_INDUSTRY = "/getPrimaryIndustry",
  API_GET_BRAND_NAMES = "/brandNames",
  API_GET_LOAN_TYPE = "/getLoanType",
  API_GET_REPAYMENT_TYPE = "/getRepaymentType",
  API_GET_LOAN_USE = "/getLoanUse",
  API_GET_BUSINESS_TYPE = "/getBusinessType",
  API_GET_SECONDARY_INDUSTRY = "/getSecondryIndustry",
  API_GET_BUSINESS_ADDRESS_TYPE = "/getBusinessAddressTypes",
  API_GET_CONSTITUTION = "/getConstitution",
  API_GET_CATEGORY = "/getCategory",
  API_GET_RELIGIONS = "/getReligions",
  API_GET_OWNERSHIP = "/getOwnership",
  // API_GET_RELATIONSHIP = '/getRelationship',
  API_GET_REFERENCE_RELATION = "/getReferenceRelation",
  API_GET_MARITAL_STATUS = "/getMaritalStatus",
  API_GET_QUALIFICATIONS = "/getQualifications",
  API_GET_GENDER = "/getGender",
  API_GET_TAXPAYER_TYPE = "/getTaxPayerType",
  API_GET_STATES = "/getStates",
  API_GET_RESIDENCE_STATUS = "/getResideneceStatus",
  API_GET_MEMBERS = "/getMembers",
  API_GET_BUSINESS_NATURE = "/getBusinessNature",
  API_GET_ACCOUNT_TYPE = "/getAccountType",
  API_GET_CREDIT_CYCLE = "/getCreditCycle",
  API_GET_PURCHASE_DISCOUNT = "/getPurchaseDiscount",
  API_GET_INTEREST_PAID = "/getInterestPaid",
  API_GET_GROWTH_REQUIREMENTS = "/getGrowthRequirements",
  API_GET_OWNERSHIP_YEARS = "/getOwnershipYears",
  API_GET_FETCH_AVERAGE_PRICE = "/fetch-average-price";
const API_FETCH_ALL_DOCUMENTS = (applicationNumber) =>
  `/fetchDocumentsList/${applicationNumber}`;
const API_DELETE_DOCUMENT = (docId) => `/deleteDocument/${docId}`;

// Generate Token
const API_GENERATE_TOKEN = "/generateToken";

// Login
const API_LOGIN = "/login";

// Forgot Password
const API_FORGOT_PASSWORD = "/forgotPassword";

// Sign Up
const API_SIGNUP = "/addUser";

// // Get User Details
const API_GET_USER_DETAILS = "/getUserDetails";

//Logout
const API_USER_LOGOUT = "/logout";

// SALES APIs START

// Dashboard
const API_GET_SALES_ALL_APPLICATIONS = "/getAllApplicationAgent";

// Loan Details
const API_CREATE_APPLICATION = "/createApplication",
  API_GET_LOAN_DETAILS = (appNo) => `/getLoanDetails/${appNo}`;

// GSTN Details
const API_GET_GST_DATA = (appNo) => `/getGstData/${appNo}`,
  API_GST_OTP_REQUEST = "/gstOtpRequest",
  API_VALIDATE_GST_OTP = "/validateGSTOtp",
  API_DELETE_GST_DATA = "/deleteGSTData",
  API_GENERATE_VALIDATE_OTP_LINK = "/generateValidateOTPLink",
  API_GST_USERNAME_LINK = "/gstUsernameLink",
  //API_DELETE_GST_DATA = (appNo, gstin) => `/deleteGSTData/${appNo}/${gstin}`,
  API_USER_OTP_VERIFY = (tokenID) => `/verifyLink/${tokenID}`,
  API_OTP_VERIFY = (uuid, otp) => `/validateGSTLinkOTP/${uuid}/${otp}`,
  API_GET_TAXPAYER_DATA = "/taxpayerData",
  API_POST_ADD_PAN_FLOW_BUSINESS = "/addPanFlowBusiness";
// GSTN Address
const API_ADD_BUSINESS_DETAILS = "/addBusiness",
  API_GET_BUSINESS = (appNo) => `/getBusiness/${appNo}`,
  API_POST_ADD_GST_TURNOVER_DATA = "/addGstTurnoverData",
  API_GET_GST_TURNOVER_DATA = (applicationNumber, gstin) =>
    `/getGstTurnoverData/${applicationNumber}/${gstin}`,
  API_GET_IS_PAN_FLOW = (applicationNumber) =>
    `/isPanFlow/${applicationNumber}`,
  API_POST_UPDATE_GST_DATA = "/updateGstData",
  API_POST_ADD_GST_MANUALLY = "/addGstManually",
  API_POST_ADD_RELATED_PARTY_DETAILS = "/addRelatedPartyDetails",
  API_GET_RELATED_PARTY_DETAILS = (applicationNumber) =>
    `/getRelatedPartyDetails/${applicationNumber}`;

// Applicant Guide
const API_ADD_SHAREHOLDING_DETAILS = "/addShareholdingDetails",
  API_GET_SHAREHOLDING_DETAILS = (appNo) => `/getShareHolding/${appNo}`,
  API_GET_ENTITY_NAME = (appNo) => `/getEntityName/${appNo}`,
  API_GET_CONSTITUTION_RELATIONS = (constitution) =>
    `/getRelation/${constitution}`,
  API_REMOVE_PROFILE = (id) => `/removeProfile/${id}`;

// Applicants
const API_ADD_PROFILE = "/addProfile",
  API_GET_PROFILE = (appNo) => `/getProfile/${appNo}`;

// Banking
const API_GET_BANK_LIST = "/getBankList",
  API_UPLOAD_BANKING = (
    appNo,
    name,
    type,
    limit,
    ifscManual,
    isScannedPDF = false,
    password = "",
    isPrimaryBankAccount = false
  ) => {
    if (ifscManual) {
      if (password) {
        return `/uploadBanking/${appNo}/${name}/${type}/${
          type === "CURRENT" ? 0 : limit
        }/${password}?ifscManual=${ifscManual}&isScannedPDF=${isScannedPDF}&isPrimary=${isPrimaryBankAccount}`;
      } else {
        return `/uploadBanking/${appNo}/${name}/${type}/${
          type === "CURRENT" ? 0 : limit
        }?ifscManual=${ifscManual}&isScannedPDF=${isScannedPDF}&isPrimary=${isPrimaryBankAccount}`;
      }
    } else if (password) {
      return `/uploadBanking/${appNo}/${name}/${type}/${
        type === "CURRENT" ? 0 : limit
      }/${password}?isScannedPDF=${isScannedPDF}&isPrimary=${isPrimaryBankAccount}`;
    } else {
      return `/uploadBanking/${appNo}/${name}/${type}/${
        type === "CURRENT" ? 0 : limit
      }?isScannedPDF=${isScannedPDF}&isPrimary=${isPrimaryBankAccount}`;
    }
  };
const API_BANKING_REMOVE_ACCOUNT = (appNo, accNo) =>
  `/removeAccount/${appNo}/${accNo}`;
// updatePrimaryBankAccount
const API_UPDATE_PRIMARY_ACCOUNT = (appNo, accNo) =>
  `/${appNo}/updatePrimaryBankAccount/${accNo}`;
//generateExcel
const API_GENERATE_EXCEL_EMAIL = "/BankIntegration/generateExcel";

// Documents
const API_UPLOAD_DOCUMENTS = (appNo, docName, docType, entity) =>
    `/uploadDocument/${appNo}/${docName}/${docType}/${entity}`,
  API_FETCH_DOCUMENTS_NAME = (appNo) => `/fetchDocumentMasterDetails/${appNo}`;

//Exception List
const API_CPO_REOPEN_CASE = (appNo, exceptionType) =>
  `/re-open/${appNo}/${exceptionType}`;
const API_EXCEPTION_LIST = (appNo) => `/exceptions?applicationNumber=${appNo}`;
//CpoSearchFilter
const API_CPO_SEARCH = "/filter-cpo-applications";

// References
const API_FETCH_REFERENCES = (appNo) => `/getReferenceDetails/${appNo}`,
  API_ADD_REFEREMCES = "/addReferences",
  API_REMOVE_REFERENCE = (id) => `/removeReference/${id}`;
const API_GET_CAM_DATA = (appNo) => `/generateCam/${appNo}`;

// CAM
const API_GET_BANKING_CHEQUE_ANALYSIS = (appNo) =>
    `/getBankingChequeAnalysis/${appNo}`,
  API_GET_BANKING_CONCENTRATION_ANALYSIS = (appNo) =>
    `/getBankingConcentrationAnalysis/${appNo}`,
  API_GET_CONCENTRATION_RISK_DETAILS = (appNo) =>
    `/getConcentrationRiskDetails/${appNo}`,
  API_GST_AND_BANKING_DETAILS = (appNo) => `/getGSTAndBankingDetails/${appNo}`,
  API_GET_GST_TREND_ANALYSIS = (appNo) => `/getGstTrendDetails/${appNo}`,
  API_GET_TURNOVER_AND_PURCHASES_DETAILS = (appNp) =>
    `/getTurnoverAndPurchaseDetails/${appNp}`,
  API_GET_AGGREGATE_TREND_ANALYSIS = (appNo) =>
    `/getAggregateTrendAnalysis/${appNo}`,
  API_GET_APPLICATION_LOGIN_DETAILS = (appNo) =>
    `/getApplicationLoginDetails/${appNo}`,
  API_GET_APPLICATION_STATUS = (appNo) => `/getApplicationStatus/${appNo}`,
  API_GET_BUSINESS_AND_PROFILE_SUMMARY = (appNo) =>
    `/getBusinessAndProfileSummary/${appNo}`;

// Submit
const API_ADD_AUTH_DECLARATION = "/addAuthDeclaration",
  API_GET_AUTH_DECLARATION = (appNo) => `/getAuthDeclaration/${appNo}`;

// Forms Preview
const API_PREVIEW_APPLICATION = (appNo) => `/previewApplication/${appNo}`,
  API_SUBMIT_APPLICATION = "/submitApplication";

// SALES APIs END

// CREDIT APIs START

// Dashboard
const API_GET_ALL_APPLICATIONS = "/getAllApplication",
  API_GET_SEARCHBY_FILTERS = "/getSearchBy",
  API_ASSIGN_APPLICATION_UW = "/assignApplicationToUnderWriter",
  API_DASHBOARD_FETCH_DATA = "/filterApplications";

// Overview
const API_GET_BUSINESS_DETAILS = (appNo) => `/getBusinessDetails/${appNo}`,
  API_GET_PROFILE_DETAIL = (appNo) => `/getProfile/${appNo}`,
  API_FETCH_ID_VERIFICATION_REPORT = (panNumber, applicationNumber) =>
    `/fetchIDVerificationReport/${panNumber}/${applicationNumber}`,
  API_FETCH_FORM_FILLED_BY = (applicationNumber) =>
    `/getApplicationFilleddBy/${applicationNumber}`,
  API_FETCH_TURNOVER_DETAILS = (applicationNumber) =>
    `/getTurnoverDetails/${applicationNumber}`;

// Cash Flow
const API_BANK_DETAILS = (appNo) => `/getBankDetails/${appNo}`,
  API_BANK_ANALYSIS = (appNo) => `/getBankingAnalysis/${appNo}`,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER = (appNo, accNo) =>
    `/getBankingAnalysis/${appNo}/${accNo}`,
  API_EDIT_BOUNCE_RATIO = (appNo, status) =>
    `/editBounceRatio/${appNo}/${status}`,
  API_EDIT_BOUNCE_RATIO_ACC = (appNo, status, accountNumber) =>
    `/editBounceRatio/${appNo}/${status}/${accountNumber}`,
  API_UPDATE_SANCTIONED_LIMIT = "/updateSanctionedLimit",
  API_SUBMIT_BOUNCE_RATIO = (applicationNumber, status) =>
    `/submitBounceRatio/${applicationNumber}/${status}`,
  API_SUBMIT_BOUNCE_RATIO_ACC = (applicationNumber, status, accountNumber) =>
    `/submitBounceRatio/${applicationNumber}/${status}/${accountNumber}`,
  API_GET_BANK_ACCOUNT_FLAGS = (applicationNumber, accountNumber) =>
    `/getBankAccFlags/${applicationNumber}/${accountNumber}`;

// Credit Business Risk
const API_FETCH_GST_ANNUAL_ANALYSIS = (appNo, gstin) =>
    `/fetchGstrAnnualAnalysis/${appNo}/${gstin}`,
  API_GET_GST_ANALYSIS = (applicationNumber) =>
    `/getGstAnalysis/${applicationNumber}`;

// Credit Behaviour
const API_FETCH_ANALYSIS = `/fetchAnalysis`,
  API_GET_APPLICANTS_SUMMARY = (appNo) => `/getApplicantsSummary/${appNo}`,
  API_POST_UPDATE_BUREAU_TRADE_LINE = (applicationNumber) =>
    `/updateBureauTradeLine/${applicationNumber}`;
const API_GENERATE_LOAN_HISTORY_EXCEL = (applicationNumber, panNumber) =>
  `/Backend/generateLoanHistoryExcel/${applicationNumber}/${panNumber}`;
//Cash flow

const API_BANK_STATEMENT_DOWNLOAD = (applicationNumber) =>
  `/Backend/downloadBanking/${applicationNumber}`;
const API_BANKING_EXCEL_DOWNLOAD = (applicationNumber) =>
  `/Backend/generateExcel/${applicationNumber}`;
// Credit Capacity
const API_CALCULATE_EMI = `/calculateEmi`,
  API_GET_EMI_DETAILS = (appNo) => `/getEMIDetails/${appNo}`,
  API_GET_CURRENT_OBLIGATIONS = (appNo) => `/getCurrentObligations/${appNo}`,
  API_GET_MARGIN_DATA = (appNo) => `/getMarginData/${appNo}`,
  API_UPDATE_CREDIT_CAPACITY_ANALYSIS = (appNo) =>
    `/updateCreditCapacityAnalysis/${appNo}`,
  API_GET_CREDIT_CAPACITY_DETAILS = (appNo) =>
    `/getCreditCapacityDetails/${appNo}`,
  API_GET_TURNOVER_DETAILS = (appNo) => `/getTurnoverDetails/${appNo}`,
  API_CALCULATE_PRE_FOIR = "/calculatePreFoir",
  API_ADD_TRADE_LINES = (pan) => `/addTradeLines/${pan}`,
  API_GET_LOAN_AMOUNT_TENURE_INTEREST = (appNo) =>
    `/getLoanAmountAndTenure/${appNo}`;

// Flag New API'S

const API_GET_BUSINESS_PROFILE_FLAGS = (applicationNumber) =>
    `/getBusinessProfileFlags/${applicationNumber}`,
  API_GET_BUSINESS_RISK_FLAGS = (applicationNumber) =>
    `/getBusinessRiskFlags/${applicationNumber}`,
  API_GET_CASH_FLOW_RISK_FLAGS = (applicationNumber) =>
    `/getCashFlowRiskFlag/${applicationNumber}`,
  API_GET_CREDIT_BEHAVIOUR_FLAGS = (applicationNumber) =>
    `/getCreditBehaviourFlag/${applicationNumber}`,
  API_GET_CAPACITY_FLAGS = (appNo) => `/getCapacityFlags/${appNo}`;

//Final Decision
const API_GET_FINAL_DECISION = (appNo) => `/getFinalDecisionDetails/${appNo}`;
const API_GET_ALL_APPLICANT_SUMMARY = (appNo) =>
  `/getBusinessAndProfileSummary/${appNo}`;
const API_GET_BANKING_DETAILS_FOR_FINAL_DECISION = (appNo) =>
  `/getBankingDetailsForFinalDecision/${appNo}`;
const API_GET_CASE_HIGHLIGHTS = (appNo) => `/getCaseHighlights/${appNo}`;
const API_GET_DECISION1 = "/getDecision";
const API_GET_DECISION = (appNo) => `/getDecision/${appNo}`;
// const API_GET_REPAYMENT_TYPE='/getRepaymentType';
const API_GET_ALL_DDS_CODES = "/getAllDdsCodes";
const API_ADD_DEVIATION = "/addDecisionSummary";
const API_GET_DEVIATION_MASTER = (appNo) => `/getDecisionSummary/${appNo}`;
const API_GET_RCU_DETAILS = (appNo) => `/getRcuDetails/${appNo}`;
const API_INITIATE_RCU_REQUEST = "/initiateRcuRequest";
const API_GET_RCU_DOCUMENT_TYPE = "/getRCUDocumentType";
const API_GET_RCU_AGENCY_MASTER = (pinCode) => `/getRCUAgencyMaster/${pinCode}`;
const API_GET_FI_AGENCY_MASTER = (pinCode) => `/getFiAgencyMaster/${pinCode}`;
const API_ADD_DECISION = "/addDecision";
const API_GET_DEVIATION_REASON_MASTER = "/getDeviationsReasonMaster";
const API_ADD_MANUAL_DEVIATION = (appNo) => `/addManualDeviations/${appNo}`;
const API_GET_DEVIATIONS = (appNo) => `/getDeviations/${appNo}`;
const API_GET_FIPD_DETAILS = "/getFipdDetails";
const API_INITITATE_FIPD_REQUEST = "/initiateFipdRequest";
const API_GET_SPOC_DETAILS = (appNo) => `/getSpocDetails/${appNo}`;
const API_SPOC_DETAILS = "/addSpocDetails";
const API_VERIFY_REFERENCE = (referenceId) => `/verifyReference/${referenceId}`;
const API_UPDATE_CMR = (applicationNumber, score) =>
  `/updateCMRScore/${applicationNumber}/${score}`;
const API_GET_LIMIT_UTILIZATION_ANALYSIS = (applicationNumber) =>
  `/getLimitUtilizationAnalysis/${applicationNumber}`;
const API_EDIT_MITIGATION = (applicationNumber) =>
  `/editMitigation/${applicationNumber}`;
const API_EXCEL_LOAN_DETAILS_EXCEL = (applicationNumber) =>
  `/Backend/generateLoanDetailsExcel/${applicationNumber}`;

// CREDIT APIs END

//Bank controller
const API_GENERATE_EXCEL = (appNo) => `/generateExcel/${appNo}`;

//CPO ADMIN PANEL

const API_GET_ADMIN_USER_DATA = () => `/getApplicationWithException`;
const API_GET_USER_BY_ROLE_ID = (name) => `/getUserByRoleId/${name}`;
const API_ASSIGN_APPLICATION_TO_CPO = "/assignApplicationToOPS";
const API_UN_ASSIGN_CPO = (applicationNumber) =>
  `/unAssignOPS/${applicationNumber}`;
//CPO USer

const API_GET_CPO_USER_APPLICATION = "/getOPSUserApplication",
  API_EXCEPTION_DATA = (appNo) => `/getExceptionData/${appNo}`,
  API_RE_TRIGGER_CIBIL = (applicationNumber, panCard) =>
    `/reTriggerCibil/${applicationNumber}/${panCard}`,
  API_SAVE_BANK_TRANSACTION_FROM_EXCEL = (applicationNumber, bankName) =>
    `/saveBankTransactionFromExcel/${applicationNumber}/${bankName}`,
  API_REFRESH_EXCEPTION = (applicationNumber) =>
    `/refreshException/${applicationNumber}`,
  API_CLOSE_APPLICATION = (applicationNumber) =>
    `/closeApplication/${applicationNumber}`,
  API_GET_UNLOCK_APPLICATION = (applicationNumber) =>
    `/unlockApplication/${applicationNumber}`,
  API_GET_REMOVE_ACCOUNT = (applicationNumber, accountNumber) =>
    `/removeAccount/${applicationNumber}/${accountNumber}`,
  API_POST_FORCE_CLOSE_APPLICATION = (applicationNumber) =>
    `/forceCloseApplication/${applicationNumber}`;

// Admin Panel
const API_UPDATE_ROLE_STATUS = () => `/updateRoleStatus`,
  API_ADD_USER_ADMIN_PANEL = `/addUserByAdminPanel`,
  // API_FETCH_ADMIN_USER_DETAILS = `/getAllUsersDetails`,
  API_FETCH_ADMIN_USER_DETAILS = `/getUserForLenderRole`,
  API_SEND_MESSAGE_ADMIN_PANEL = "/notifyUsers",
  API_UPDATE_ADMIN_USER_STATUS = "/updateUserStatus",
  API_GET_ALL_THRESHOLDS = "/getAllThresholds",
  API_GET_ACTIVE_LENDER_ROLES = "/getActiveLenderRoles",
  API_GET_ALL_LENDERS_ROLES = "/getAllLenderRoles",
  API_POST_RESET_PASSWORD = "/resetPassword",
  API_POST_UPDATE_THRESHOLD = "/updateThreshold",
  API_GET_ROLE_STATUS = "/getRoleStatus",
  API_GET_HIERARCHY_LEVEL = "/getHierarchyLevel",
  API_GET_USER_BY_LEVEL = (hierarchyLevel) =>
    `/getUserByLevel/${hierarchyLevel}`;

//Notification
const API_GET_NOTIFICATION_FETCH = (userId) => `/notificationFetch/${userId}`,
  API_GET_MARK_NOTIFICATION_READ = (userId) => `/markAllRead/${userId}`;

//ITR controlller
const API_FETCH_BALANCE_SHEET = (applicationNumber) =>
    `/fetchBalanceSheet/${applicationNumber}`,
  API_FETCH_PROFIT_AND_LOSS_ACCOUNT = (applicationNumber) =>
    `/fetchProfitAndLossAccount/${applicationNumber}`,
  API_UPLOAD_ITR = (applicationNumber, year, returnType) =>
    `/uploaditr/${applicationNumber}/${year}/${returnType}`;
const API_GET_ASSESSEE_DETAILS = (applicationNumber) =>
    `/fetchAdditionalItrAnalytics/${applicationNumber}`,
  API_GET_RATIOS = (applicationNumber) => `/fetchRatios/${applicationNumber}`;

//New Application
const API_GET_APPLICATION_NUMBER = (customerId) =>
  `/generateApplicationNumber/${customerId}`;

//Sourcing DEtails
const API_GET_SOURCING_DETAILS = (applicationNumber) =>
    `/getSourcingDetails/${applicationNumber}`,
  API_POST_ADD_SOURCING_DETAILS = "/addSourcingDetails";

//Gst Address details
const API_GET_GST_ADDRESS_DETAILS = (applicationNumber) =>
    `/getBusiness/${applicationNumber}`,
  API_POST_GST_ADDRESS_DETAILS = "/addBusiness";

// Cluster API's

const API_POST_FILTER_CUSTOMERS = "/filterCustomers",
  API_POST_ADD_CUSTOMER_BUSINESS = "/addCustomerBusiness",
  API_POST_ADD_BANK_ACCOUNTS = "/addBankAccounts",
  API_POST_ADD_BUSINESS = "/addBusinessSize";

//Cluster OnBoardingDetails

const API_POST_CREATE_CUSTOMER = "/createCustomer",
  API_GET_CUSTOMER_DETAILS = (customerId) =>
    `/customerDetailsFetch/${customerId}`,
  API_GET_CLUSTER_TAXPAYER_DATA = (gstin) => `/fetchTaxpayerData/${gstin}`,
  API_POST_VALIDATE_CUSTOMER_OTP = "/validateCustomerOtp",
  API_POST_RE_TRIGGER_OTP = "/reTriggerOtp",
  API_GET_CLUSTER_STATES = "/fetchClusterStates",
  API_POST_CLUSTER_BY_STATE = "/fetchClustersByState",
  //API_GET_CLUSTER_BY_STATE = (state) => `/fetchClustersByState/${state}`,
  API_GET_CLUSTER_QUESTIONS = (customerId) =>
    `/fetchClusterQuestions/${customerId}`,
  API_POST_BUSINESS_COST = "/addBusinessCost",
  API_GET_ENTITY_DOCUMENTS = "/fetchEntityDocuments",
  API_POST_CAPTURE = "/capture",
  API_POST_COMMENT = "/add-comment",
  API_GET_LOCATION = (customerId) => `/fetch-location/${customerId}`,
  API_GET_DOCUMENTS = (customerId) => `/fetchAll/${customerId}`,
  API_GET_DELETE_DOCUMENT = (customerId, docId) =>
    `/delete/${customerId}/${docId}`,
  API_GET_CUSTOMER_BUSINESS = (customerId) =>
    `/fetchCustomerBusiness/${customerId}`,
  API_GET_BUSINESS_COST = (customerId) => `/fetchBusinessCost/${customerId}`,
  API_GET_BUSINESS_SIZE = (customerId) => `/fetchBusinessSize/${customerId}`,
  API_GET_BANK_ACCOUNTS = (customerId) => `/fetchBankAccounts/${customerId}`;

//Cluster Preview
const API_GET_CLUSTER_OVERVIEW = (customerId) =>
    `/fetchClusterOverview/${customerId}`,
  API_GET_SUBMIT_PREVIEW = (customerId) => `/submit/${customerId}`,
  API_GET_BUREAU_STATUS = (customerId) => `/get-bureau-status/${customerId}`,
  API_POST_CRQ_QUESTION_RESPONSE = "/crq-question-response";

//Cluster unit Analysis
const API_GET_PROMOTER_DETAILS = (customerId) =>
    `/fetchPromoterDetails/${customerId}`,
  API_GET_EMPLOYEE_DETAILS = (customerId) =>
    `/fetchEmployeeDetails/${customerId}`,
  API_GET_MONTHLY_EXPENSES = (customerId) =>
    `/fetchMonthlyExpenses/${customerId}`,
  API_GET_ADDRESSES = (customerId) => `/fetchAddresses/${customerId}`,
  API_GET_ASSET_DETAILS = (customerId) => `/fetchAssetDetails/${customerId}`,
  API_GET_BUSINESS_MEMBERS = (customerId) =>
    `/fetchBusinessMembers/${customerId}`,
  API_GET_INCOME_DETAILS = (customerId) => `/fetchIncomeDetails/${customerId}`,
  API_GET_INVESTMENTS_AND_FUNDS = (customerId) =>
    `/fetchInvestmentsAndFunds/${customerId}`,
  API_BUSINESS_DETAILS = (customerId) => `/fetchBusinessDetails/${customerId}`,
  API_GET_FETCH_ALL = (customerId) => `/fetchAll/${customerId}`,
  API_GET_DOWNLOAD_DOCUMENT = (docId) => `/download/${docId}`;

//Overview unit analysis
const API_GET_BANK_ACCOUNTS_OVERVIEW = (applicationNumber) =>
    `/fetch-bank-accounts/${applicationNumber}`, //Leave
  API_GET_PROMOTER_DETAILS_OVERVIEW = (applicationNumber) =>
    `/fetch-promoter-details/${applicationNumber}`,
  API_GET_EMPLOYEE_DETAILS_OVERVIEW = (applicationNumber) =>
    `/fetch-employee-details/${applicationNumber}`,
  API_GET_MONTHLY_EXPENSES_OVERVIEW = (applicationNumber) =>
    `/fetch-monthly-expenses/${applicationNumber}`,
  API_GET_ADDRESSES_OVERVIEW = (applicationNumber) =>
    `/fetch-addresses/${applicationNumber}`,
  API_GET_ASSET_DETAILS_OVERVIEW = (applicationNumber) =>
    `/fetch-asset-details/${applicationNumber}`,
  API_GET_BUSINESS_MEMBERS_OVERVIEW = (applicationNumber) =>
    `/fetch-business-members/${applicationNumber}`,
  API_GET_INCOME_DETAILS_OVERVIEW = (applicationNumber) =>
    `/fetch-income-details/${applicationNumber}`,
  API_GET_INVESTMENTS_AND_FUNDS_OVERVIEW = (applicationNumber) =>
    `/fetch-investments-funds/${applicationNumber}`,
  API_BUSINESS_DETAILS_OVERVIEW = (applicationNumber) =>
    `/fetch-business-details/${applicationNumber}`,
  API_GET_FETCH_ALL_OVERVIEW = (applicationNumber) =>
    `/fetch-all-documents/${applicationNumber}`,
  API_GET_DOWNLOAD_DOCUMENT_OVERVIEW = (docId) => `/download/${docId}`,
  API_GET_LOCATION_OVERVIEW = (applicationNumber) =>
    `/fetch-unit-location/${applicationNumber}`;

// Lead Manager
const API_GET_APPLICANT_SUMMARY = (customerId) =>
    `/fetch-applicants-summary/${customerId}`,
  API_GET_GATING_STATUS = (customerId) => `/fetch-gating-status/${customerId}`,
  API_POST_RERUN_BUREAU = (customerId) => `/rerun-bureau/${customerId}`,
  API_POST_FILTER_MEMBERS = "/filter-members",
  API_POST_FETCH_ALL_MEMBERS = "/fetchAllMembers",
  API_POST_OVERRIDE_GATING = "/override-gating",
  API_POST_CO_APPLICANT = "/addShareholdingDetails",
  API_GET_RELATION = "/relation/pemant";

// Dashboard cluster analysis
const API_GET_BUSINESS_COST_ANALYSIS = (applicationNumber) =>
    `/fetch-business-cost/${applicationNumber}`,
  API_GET_CLUSTER = (applicationNumber) =>
    `/fetch-cluster/${applicationNumber}`;

const API_GET_DOCUMENTS_LIST = (applicationNumber) =>
  `/fetchDocumentsList/${applicationNumber}`;
const API_DOC_PREVIEW = (docId) => `/Backend/document/${docId}`;
export {
  API_UPDATE_PRIMARY_ACCOUNT,
  API_EXCEL_LOAN_DETAILS_EXCEL,
  API_BANKING_REMOVE_ACCOUNT,
  API_DOC_PREVIEW,
  API_GET_DOCUMENTS_LIST,
  API_BANK_STATEMENT_DOWNLOAD,
  API_BANKING_EXCEL_DOWNLOAD,
  API_GENERATE_LOAN_HISTORY_EXCEL,
  API_CPO_SEARCH,
  API_CPO_REOPEN_CASE,
  API_EXCEPTION_LIST,
  API_GENERATE_EXCEL_EMAIL,
  API_GET_RELATION,
  API_POST_CO_APPLICANT,
  API_GET_BUSINESS_TYPE,
  API_GET_LOAN_TYPE,
  API_GET_LOAN_USE,
  API_GET_REPAYMENT_TYPE,
  API_GET_PRIMARY_INDUSTRY,
  API_GET_SECONDARY_INDUSTRY,
  API_GET_CATEGORY,
  API_GET_GENDER,
  API_GET_MARITAL_STATUS,
  API_GET_REFERENCE_RELATION,
  API_GET_QUALIFICATIONS,
  API_GET_RELIGIONS,
  API_GET_BUSINESS_ADDRESS_TYPE,
  API_GET_CONSTITUTION,
  API_GET_TAXPAYER_TYPE,
  API_GET_STATES,
  API_GET_RESIDENCE_STATUS,
  API_GENERATE_TOKEN,
  API_LOGIN,
  API_FORGOT_PASSWORD,
  API_SIGNUP,
  API_GET_USER_DETAILS,
  API_USER_LOGOUT,
  API_GET_SALES_ALL_APPLICATIONS,
  API_CREATE_APPLICATION,
  API_GET_LOAN_DETAILS,
  API_GET_GST_DATA,
  API_GST_OTP_REQUEST,
  API_GENERATE_VALIDATE_OTP_LINK,
  API_VALIDATE_GST_OTP,
  API_DELETE_GST_DATA,
  API_ADD_BUSINESS_DETAILS,
  API_GET_BUSINESS,
  API_ADD_SHAREHOLDING_DETAILS,
  API_GET_SHAREHOLDING_DETAILS,
  API_GET_ENTITY_NAME,
  API_GET_CONSTITUTION_RELATIONS,
  API_REMOVE_PROFILE,
  API_ADD_PROFILE,
  API_GET_PROFILE,
  API_GET_BANK_LIST,
  API_UPLOAD_BANKING,
  API_UPLOAD_DOCUMENTS,
  API_ADD_REFEREMCES,
  API_REMOVE_REFERENCE,
  API_FETCH_REFERENCES,
  API_ADD_AUTH_DECLARATION,
  API_GET_AUTH_DECLARATION,
  API_PREVIEW_APPLICATION,
  API_SUBMIT_APPLICATION,
  API_BANK_DETAILS,
  API_BANK_ANALYSIS,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER,
  API_GET_ALL_APPLICATIONS,
  API_GET_SEARCHBY_FILTERS,
  API_GET_BUSINESS_DETAILS,
  API_GET_PROFILE_DETAIL,
  API_FETCH_ID_VERIFICATION_REPORT,
  API_FETCH_GST_ANNUAL_ANALYSIS,
  API_GET_APPLICANTS_SUMMARY,
  API_CALCULATE_EMI,
  API_GET_EMI_DETAILS,
  API_GET_CURRENT_OBLIGATIONS,
  API_GET_MARGIN_DATA,
  API_UPDATE_CREDIT_CAPACITY_ANALYSIS,
  API_GET_CREDIT_CAPACITY_DETAILS,
  API_GET_TURNOVER_DETAILS,
  API_CALCULATE_PRE_FOIR,
  API_ADD_TRADE_LINES,
  API_FETCH_ANALYSIS,
  API_FETCH_FORM_FILLED_BY,
  API_FETCH_TURNOVER_DETAILS,
  API_GET_FINAL_DECISION,
  API_GENERATE_EXCEL,
  API_FETCH_DOCUMENTS_NAME,
  API_GET_ADMIN_USER_DATA,
  API_EDIT_BOUNCE_RATIO,
  API_EDIT_BOUNCE_RATIO_ACC,
  API_UPDATE_SANCTIONED_LIMIT,
  API_SUBMIT_BOUNCE_RATIO,
  API_SUBMIT_BOUNCE_RATIO_ACC,
  API_GET_DECISION,
  API_ADD_DEVIATION,
  API_GET_DEVIATION_MASTER,
  API_GET_ALL_DDS_CODES,
  API_GET_RCU_DETAILS,
  API_INITIATE_RCU_REQUEST,
  API_GET_RCU_DOCUMENT_TYPE,
  API_GET_RCU_AGENCY_MASTER,
  API_GET_CAM_DATA,
  API_ADD_DECISION,
  API_GET_DEVIATION_REASON_MASTER,
  API_ADD_MANUAL_DEVIATION,
  API_GET_DEVIATIONS,
  API_GET_FIPD_DETAILS,
  API_INITITATE_FIPD_REQUEST,
  API_GET_DECISION1,
  API_GET_SPOC_DETAILS,
  API_SPOC_DETAILS,
  API_GET_USER_BY_ROLE_ID,
  API_ASSIGN_APPLICATION_TO_CPO,
  API_UN_ASSIGN_CPO,
  API_USER_OTP_VERIFY,
  API_OTP_VERIFY,
  API_GET_CPO_USER_APPLICATION,
  API_EXCEPTION_DATA,
  API_RE_TRIGGER_CIBIL,
  API_SAVE_BANK_TRANSACTION_FROM_EXCEL,
  API_REFRESH_EXCEPTION,
  API_CLOSE_APPLICATION,
  API_VERIFY_REFERENCE,
  API_UPDATE_CMR,
  API_FETCH_ALL_DOCUMENTS,
  API_DELETE_DOCUMENT,
  API_ADD_USER_ADMIN_PANEL,
  API_UPDATE_ROLE_STATUS,
  API_FETCH_ADMIN_USER_DETAILS,
  API_SEND_MESSAGE_ADMIN_PANEL,
  API_UPDATE_ADMIN_USER_STATUS,
  API_GET_CAPACITY_FLAGS,
  API_ASSIGN_APPLICATION_UW,
  API_DASHBOARD_FETCH_DATA,
  API_GET_BUSINESS_PROFILE_FLAGS,
  API_GET_BUSINESS_RISK_FLAGS,
  API_GET_CASH_FLOW_RISK_FLAGS,
  API_GET_CREDIT_BEHAVIOUR_FLAGS,
  API_POST_UPDATE_BUREAU_TRADE_LINE,
  API_GET_LOAN_AMOUNT_TENURE_INTEREST,
  API_GET_ALL_APPLICANT_SUMMARY,
  API_GET_CASE_HIGHLIGHTS,
  API_GET_BANKING_DETAILS_FOR_FINAL_DECISION,
  API_GET_LIMIT_UTILIZATION_ANALYSIS,
  API_EDIT_MITIGATION,
  API_GET_BANKING_CHEQUE_ANALYSIS,
  API_GET_BANKING_CONCENTRATION_ANALYSIS,
  API_GET_CONCENTRATION_RISK_DETAILS,
  API_GST_AND_BANKING_DETAILS,
  API_GET_GST_TREND_ANALYSIS,
  API_GET_TURNOVER_AND_PURCHASES_DETAILS,
  API_GET_AGGREGATE_TREND_ANALYSIS,
  API_GET_APPLICATION_LOGIN_DETAILS,
  API_GET_APPLICATION_STATUS,
  API_GET_BUSINESS_AND_PROFILE_SUMMARY,
  API_GET_UNLOCK_APPLICATION,
  API_GET_REMOVE_ACCOUNT,
  API_POST_FORCE_CLOSE_APPLICATION,
  API_GET_ALL_THRESHOLDS,
  API_GET_ACTIVE_LENDER_ROLES,
  API_GET_ALL_LENDERS_ROLES,
  API_POST_RESET_PASSWORD,
  API_POST_UPDATE_THRESHOLD,
  API_GET_ROLE_STATUS,
  API_GET_GST_ANALYSIS,
  API_GET_BANK_ACCOUNT_FLAGS,
  API_GET_FI_AGENCY_MASTER,
  API_GET_HIERARCHY_LEVEL,
  API_GET_USER_BY_LEVEL,
  API_GET_NOTIFICATION_FETCH,
  API_GET_MARK_NOTIFICATION_READ,
  API_FETCH_BALANCE_SHEET,
  API_FETCH_PROFIT_AND_LOSS_ACCOUNT,
  API_UPLOAD_ITR,
  API_GET_TAXPAYER_DATA,
  API_GST_USERNAME_LINK,
  API_GET_ASSESSEE_DETAILS,
  API_GET_RATIOS,
  API_GET_APPLICATION_NUMBER,
  API_GET_SOURCING_DETAILS,
  API_POST_ADD_SOURCING_DETAILS,
  API_GET_GST_ADDRESS_DETAILS,
  API_POST_GST_ADDRESS_DETAILS,
  API_GET_OWNERSHIP,
  API_POST_ADD_GST_TURNOVER_DATA,
  API_GET_GST_TURNOVER_DATA,
  API_POST_FILTER_CUSTOMERS,
  API_GET_MEMBERS,
  API_GET_BUSINESS_NATURE,
  API_POST_ADD_CUSTOMER_BUSINESS,
  API_GET_ACCOUNT_TYPE,
  API_POST_ADD_BANK_ACCOUNTS,
  API_GET_CREDIT_CYCLE,
  API_GET_PURCHASE_DISCOUNT,
  API_GET_INTEREST_PAID,
  API_GET_OWNERSHIP_YEARS,
  API_GET_GROWTH_REQUIREMENTS,
  API_POST_ADD_BUSINESS,
  API_GET_CLUSTER_STATES,
  API_POST_CREATE_CUSTOMER,
  API_GET_CUSTOMER_DETAILS,
  API_GET_CLUSTER_TAXPAYER_DATA,
  API_POST_VALIDATE_CUSTOMER_OTP,
  API_POST_CLUSTER_BY_STATE,
  API_POST_RE_TRIGGER_OTP,
  API_GET_CLUSTER_QUESTIONS,
  API_POST_BUSINESS_COST,
  API_GET_ENTITY_DOCUMENTS,
  API_POST_CAPTURE,
  API_GET_CLUSTER_OVERVIEW,
  API_GET_SUBMIT_PREVIEW,
  API_GET_DOCUMENTS,
  API_GET_DELETE_DOCUMENT,
  API_GET_CUSTOMER_BUSINESS,
  API_GET_BUSINESS_COST,
  API_GET_BUSINESS_SIZE,
  API_GET_BANK_ACCOUNTS,
  API_POST_UPDATE_GST_DATA,
  API_POST_ADD_GST_MANUALLY,
  API_POST_ADD_PAN_FLOW_BUSINESS,
  API_GET_PROMOTER_DETAILS,
  API_GET_IS_PAN_FLOW,
  API_GET_EMPLOYEE_DETAILS,
  API_GET_MONTHLY_EXPENSES,
  API_GET_ADDRESSES,
  API_GET_ASSET_DETAILS,
  API_GET_BUSINESS_MEMBERS,
  API_GET_INCOME_DETAILS,
  API_GET_INVESTMENTS_AND_FUNDS,
  API_POST_FETCH_ALL_MEMBERS,
  API_BUSINESS_DETAILS,
  API_POST_ADD_RELATED_PARTY_DETAILS,
  API_GET_RELATED_PARTY_DETAILS,
  API_GET_FETCH_ALL,
  API_GET_DOWNLOAD_DOCUMENT,
  API_POST_COMMENT,
  API_GET_LOCATION,
  API_GET_APPLICANT_SUMMARY,
  API_POST_FILTER_MEMBERS,
  API_GET_GATING_STATUS,
  API_POST_OVERRIDE_GATING,
  API_POST_RERUN_BUREAU,
  API_GET_FETCH_AVERAGE_PRICE,
  API_GET_BUREAU_STATUS,
  API_POST_CRQ_QUESTION_RESPONSE,
  API_GET_BUSINESS_COST_ANALYSIS,
  API_GET_CLUSTER,
  API_GET_BANK_ACCOUNTS_OVERVIEW,
  API_GET_PROMOTER_DETAILS_OVERVIEW,
  API_GET_EMPLOYEE_DETAILS_OVERVIEW,
  API_GET_MONTHLY_EXPENSES_OVERVIEW,
  API_GET_ADDRESSES_OVERVIEW,
  API_GET_ASSET_DETAILS_OVERVIEW,
  API_GET_BUSINESS_MEMBERS_OVERVIEW,
  API_GET_INCOME_DETAILS_OVERVIEW,
  API_GET_INVESTMENTS_AND_FUNDS_OVERVIEW,
  API_BUSINESS_DETAILS_OVERVIEW,
  API_GET_FETCH_ALL_OVERVIEW,
  API_GET_DOWNLOAD_DOCUMENT_OVERVIEW,
  API_GET_LOCATION_OVERVIEW,
  API_GET_BRAND_NAMES,
};
