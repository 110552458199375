import React from "react";
import CrossIcon from "../../../assets/images/crossIcon.svg";
import Correct from "../../../assets/images/correct.png";
import Pending from "../../../assets/images/newPendingIcon.svg";
const newCases = [
  {
    Header: "User type",
    accessor: "userType",
  },
  {
    Header: "User Id",
    accessor: "customerId",
    Cell: (props) => {
      let user = props.value;
      return <div>{user !== null ? <div>{user}</div> : "NA"}</div>;
    },
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
  },
  // {
  //   Header: "Presented to Credit",
  //   accessor: "isCreditPresented",
  //   width: 150,
  //   Cell: ({ original }) => {
  //     let { isCreditPresented } = original;
  //     return (
  //       <span>
  //         {isCreditPresented ? (
  //           <span style={{ color: "green" }}>
  //             {<img src={CheckIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         ) : (
  //           <span disabled={true} style={{ height: "16px" }}>
  //             {<img src={CrossIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         )}
  //       </span>
  //     );
  //   },
  // },
  {
    Header: "Gating status",
    accessor: "gatingStatus",
    width: 150,
    Cell: ({ original }) => {
      return (
        <span>
          {original?.gatingStatus === "success" ? (
            <span style={{ color: "green" }}>
              {
                <img
                  src={Correct}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "failure" ? (
            <span>
              {
                <img
                  src={CrossIcon}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "pending" ? (
            <span>
              {
                <img
                  src={Pending}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : (
            "NA"
          )}
        </span>
      );
    },
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },

  {
    Header: "Enhancement status",
    accessor: "exception",
    Cell: ({ row }) => {
      let { _original, _index } = row;
      return (
        <span key={_index}>
          {_original.exception !== null ? _original.exception.join(", ") : ""}
        </span>
      );
    },
  },
  {
    Header: "Login time",
    accessor: "tatDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
];

const pendingCases = [
  {
    Header: "User type",
    accessor: "userType",
  },
  {
    Header: "User Id",
    accessor: "customerId",
    Cell: (props) => {
      let user = props.value;
      return <div>{user !== null ? <div>{user}</div> : "NA"}</div>;
    },
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
  },
  // {
  //   Header: "Presented to Credit",
  //   accessor: "isCreditPresented",
  //   width: 150,
  //   Cell: ({ original }) => {
  //     let { isCreditPresented } = original;
  //     return (
  //       <span>
  //         {isCreditPresented ? (
  //           <span style={{ color: "green" }}>
  //             {<img src={CheckIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         ) : (
  //           <span disabled={true} style={{ height: "16px" }}>
  //             {<img src={CrossIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         )}
  //       </span>
  //     );
  //   },
  // },
  {
    Header: "Gating status",
    accessor: "gatingStatus",
    width: 150,
    Cell: ({ original }) => {
      return (
        <span>
          {original?.gatingStatus === "success" ? (
            <span style={{ color: "green" }}>
              {
                <img
                  src={Correct}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "failure" ? (
            <span>
              {
                <img
                  src={CrossIcon}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "pending" ? (
            <span>
              {
                <img
                  src={Pending}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : (
            "NA"
          )}
        </span>
      );
    },
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },
  {
    Header: "Enhancement status",
    accessor: "exception",
    Cell: ({ row }) => {
      let { _original, _index } = row;
      return (
        <span key={_index}>
          {_original.exception !== null ? _original.exception.join(", ") : ""}
        </span>
      );
    },
  },
  {
    Header: "Login time",
    accessor: "tatDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
];
const _closedCases = [
  {
    Header: "User type",
    accessor: "userType",
  },
  {
    Header: "User Id",
    accessor: "customerId",
    Cell: (props) => {
      let user = props.value;
      return <div>{user !== null ? <div>{user}</div> : "NA"}</div>;
    },
  },
  {
    Header: "Case Id",
    accessor: "applicationNumber",
  },
  // {
  //   Header: "Presented to Credit",
  //   accessor: "isCreditPresented",
  //   width: 150,
  //   Cell: ({ original }) => {
  //     let { isCreditPresented } = original;
  //     return (
  //       <span>
  //         {isCreditPresented ? (
  //           <span style={{ color: "green" }}>
  //             {<img src={CheckIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         ) : (
  //           <span disabled={true} style={{ height: "16px" }}>
  //             {<img src={CrossIcon} alt="Cross Icon" style={{ height: "16px" }} />}
  //           </span>
  //         )}
  //       </span>
  //     );
  //   },
  // },
  {
    Header: "Gating status",
    accessor: "gatingStatus",
    width: 150,
    Cell: ({ original }) => {
      return (
        <span>
          {original?.gatingStatus === "success" ? (
            <span style={{ color: "green" }}>
              {
                <img
                  src={Correct}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "failure" ? (
            <span>
              {
                <img
                  src={CrossIcon}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : original?.gatingStatus === "pending" ? (
            <span>
              {
                <img
                  src={Pending}
                  alt="Cross Icon"
                  style={{ height: "30px" }}
                />
              }
            </span>
          ) : (
            "NA"
          )}
        </span>
      );
    },
  },
  {
    Header: "Entity Name",
    accessor: "applicationName",
  },
  {
    Header: "Enhancement status",
    accessor: "exception",
    Cell: ({ row }) => {
      let { _original, _index } = row;
      return (
        <span key={_index}>
          {_original.exception !== null ? _original.exception.join(", ") : ""}
        </span>
      );
    },
  },
  {
    Header: "Login time",
    accessor: "tatDate",
    Cell: (props) => {
      if (props.value) {
        let date = props.value;
        return (
          <div>
            <div>{date.split(" ")[0]}</div>
            <div>{date.split(" ")[1]}</div>
          </div>
        );
      }
    },
  },
];
export { newCases, _closedCases, pendingCases };
