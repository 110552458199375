import * as GstnSelectors from "./GstnAddress.selector";
import * as GstnConstants from "./GstnAddress.actions.constants";

const initialState = {
  bankingDetails: {},
  businessDetailsData: {},
  partyDetailsData: [],
};

export const gstnAddressReducers = (state = initialState, action) => {
  const { type, data, val, name, gstIndex, addressIndex, index } = action;
  switch (type) {
    case GstnConstants.GET_BUSINESS:
      return {
        ...state,
        businessDetailsData: {
          ...data,
          gstDetails: data && [...data.gstDetails].map((el) => {
            if (!el.address.length) {
              return { ...el, address: [{}] };
            } else {
              return el;
            }
          }),
        },
      };
    case GstnConstants.GET_PARTY_DETAILS:
      return {
        ...state,
        partyDetailsData: data,
      };
    case GstnConstants.CHANGE_VALUE:
      return GstnSelectors._updateFormValues(state, val, name);
    case GstnConstants.ADD_ADDRESS:
      return GstnSelectors._addAddress(state, gstIndex);
    case GstnConstants.UPDATE_ADDRESS:
      return GstnSelectors._updateAddress(state, gstIndex, addressIndex, val, name);
    case GstnConstants.DELETE_ADDRESS:
      return GstnSelectors._deleteAddress(state, gstIndex, addressIndex);
    case GstnConstants.RESET_REDUX_STATE:
      return { ...state, businessDetailsData: {}, partyDetailsData: [] };
    case GstnConstants.ADD_PARTY_ROW:
      return {
        ...state,
        partyDetailsData: [...state.partyDetailsData, { relatedPartyName: "", relatedPartyRelation: "" }],
      };
    case GstnConstants.DELETE_PARTY_ROW:
      return {
        ...state,
        partyDetailsData: state.partyDetailsData.filter((_, partyIndex) => partyIndex !== index),
      };
    case GstnConstants.PARTY_CHANGE_HANDLER:
      return {
        ...state,
        partyDetailsData: state.partyDetailsData.map((el, partyIndex) => {
          if (partyIndex === index) {
            return { ...el, [name]: val };
          } else {
            return el;
          }
        }),
      };
    default:
      return state;
  }
};
