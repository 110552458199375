import * as CAMConstant from "./cam.actions.constant";

const initialState = {
  camData: [],
  creditCapacityData: [],
  referenceDetailsData: [],
  deviationData: [],
  applicantSummaryData: [],
  currentObligationData: [],
  chequeBounceData: [],
  bankingConcentrationData: [],
  businessConcentrationData: [],
  gstTrendAnalysisData: [],
  applicationStatusData:[],
  businessAndProfileData:[],
  marginData:[],
  turnoverDetailsData:[]
};

export const CamResponse = (state = initialState, action) => {
  const { data, type, creditCapacityData } = action;
  switch (type) {
    case CAMConstant.GET_CAM_DATA:
      return { ...state, camData: data };
    case CAMConstant.SET_CREDIT_CAPACITY_DETAILS:
      return { ...state, creditCapacityData: creditCapacityData };
    case CAMConstant.GET_SPOC_DETAILS:
      return { ...state, spocDetailsData: data };
    case CAMConstant.GET_BANK_DETAILS:
      return { ...state, bankDetailsData: data };
    case CAMConstant.GET_CASE_HIGHLIGHTS:
      return { ...state, caseHighlightsData: data };
    case CAMConstant.GET_DECISION_SUMMARY:
      return { ...state, decisionSummaryData: data };
    case CAMConstant.GET_CURRENT_OBLIGATIONS:
      return { ...state, currentObligationData: data };
    case CAMConstant.GET_REFERENCES_DETAILS:
      return { ...state, referenceDetailsData: data };
    case CAMConstant.GET_DEVIATIONS:
      return { ...state, deviationData: data };
    case CAMConstant.APPLICATION_FILLED_BY:
      return { ...state, applicationFilledBy: data };
    case CAMConstant.GET_APPLICANTS_SUMMARY:
      return { ...state, applicantSummaryData: data };
    case CAMConstant.GET_BANKING_CHEQUE_ANALYSIS:
      return { ...state, chequeBounceData: data };
    case CAMConstant.GET_BANKING_CONCENTRATION_ANALYSIS:
      return { ...state, bankingConcentrationData: data };
    case CAMConstant.GET_CONCENTRATION_RISK_DETAILS:
      return { ...state, businessConcentrationData: data };
    case CAMConstant.GET_GST_AND_BANKING_DETAILS:
      return { ...state, gstAndBankingDetailsData: data };
    case CAMConstant.GET_GST_TREND_ANALYSIS:
      return { ...state, gstTrendAnalysisData: data };
    case CAMConstant.GET_TURNOVER_AND_PURCHASES_DETAILS:
      return { ...state, turnoverAndPurchasesData: data };
    case CAMConstant.GET_AGGREGATE_TREND_ANALYSIS:
      return { ...state, aggregateTendData: data };
    case CAMConstant.GET_BUSINESS_DETAILS:
      return { ...state, businessDetailsData: data };
    case CAMConstant.GET_APPLICATION_LOGIN_DETAILS:
      return { ...state, applicationLoginData: data };
    case CAMConstant.GET_APPLICATION_STATUS:
      return { ...state, applicationStatusData: data };
    case CAMConstant.GET_BUSINESS_AND_PROFILE_SUMMARY:
      return { ...state, businessAndProfileData: data };
    case CAMConstant.GET_TURNOVER_DETAILS:
      return { ...state, turnoverDetailsData: data };
    case CAMConstant.GET_MARGIN_DATA:
      return { ...state, marginData: data };
    default:
      return state;
  }
};
