import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { get } from "lodash";
import { Header, CreditNavs, LoaderModal, Error } from "../../components";
import Dashboard from "./Dashboard/Dashboard";
import Overview from "./Overview/Overview";
import BusinessRisk from "./BusinessRisk/BusinessRisk";
import CashFlow from "./CashFlow/CashFlow";
import CreditBehaviour from "./CreditBehaviour/CreditBehaviour";
import CreditCapacity from "./CreditCapacity/CreditCapacity";
import Flag from "./Flag/Flag";
import FinalDecision from "./FinalDecision/FinalDecision";
import BicycleBusinessCostAnalysis from "./Overview/ClusterAnalysis/BicycleBusinessCostAnalysis/BicycleBusinessCostAnalysis";
import FootwearBusinessCostAnalysis from "./Overview/ClusterAnalysis/FootwearBusinessCostAnalysis/FootwearBusinessCostAnalysis";
import SewingMachineBusinessCostAnalysis from "./Overview/ClusterAnalysis/SewingMachineBusinessCostAnalysis/SewingMachineBusinessCostAnalysis";
import MachineAndHandToolsBusinessCostAnalysis from "./Overview/ClusterAnalysis/MachineAndHandToolsBusinessCostAnalysis/MachineAndHandToolsBusinessCostAnalysis";
import HosieryBusinessCostAnalysis from "./Overview/ClusterAnalysis/HosieryBusinessCostAnalysis/HosieryBusinessCostAnalysis";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../store/actions";

class CreditRoutes extends Component {
  componentWillMount() {
    let { userDetails, history } = this.props;
    userDetails.roleName === "AGENT" && history.push("/Sales/dashboard");
    userDetails.roleName === "CPO_USER" && history.push("/cpopanel");
    userDetails.roleName === "CPO_ADMIN" && history.push("/cpopanelAdmin");
    userDetails.roleName === "CPO_USER" && history.push("/Sales/dashboard");
  }

  render() {
    let { loaderModalStatus, userDetails, actions } = this.props,
      { loaderModal } = actions;
    const arrPath = get(this, "props.location.pathname", "").split("/");
    const activeTab = arrPath.length ? arrPath[arrPath.length - 1] : "overview";
    return (
      <Fragment>
        {activeTab !== "dashboard" ? (
          <Header
            {...this.props}
            searchByFiltersHandler={this.searchByFiltersHandler}
          />
        ) : null}
        {activeTab !== "dashboard" ? (
          <CreditNavs active={activeTab} {...this.props} />
        ) : null}

        <LoaderModal status={loaderModalStatus} />
        {userDetails.roleName === "UW" ||
        userDetails.roleName === "UW_ADMIN" ? (
          <Switch>
            {/*<Redirect from={'/Credit'} exact to={`/Credit/dashboard`}/>*/}
            <Route path={"/Credit/dashboard"} exact component={Dashboard} />
            <Route
              path={"/Credit/:appNo/overview"}
              exact
              component={Overview}
            />
            <Route
              path={"/Credit/:appNo/business_risk"}
              exact
              component={BusinessRisk}
            />
            <Route
              path={"/Credit/:appNo/cash_flow"}
              exact
              component={CashFlow}
            />
            <Route
              path={"/Credit/:appNo/credit_behaviour"}
              exact
              component={CreditBehaviour}
            />
            <Route path={"/Credit/:appNo/flag"} exact component={Flag} />
            <Route
              path={"/Credit/:appNo/credit_capacity"}
              exact
              component={CreditCapacity}
            />
            <Route
              path={"/Credit/:appNo/final_decision"}
              exact
              component={FinalDecision}
            />
            <Route
              path={"/Credit/:appNo/Bicycle_Business_Cost"}
              exact
              component={BicycleBusinessCostAnalysis}
            />
            <Route
              path={"/Credit/:appNo/Footwear_Business_Cost"}
              exact
              component={FootwearBusinessCostAnalysis}
            />
            <Route
              path={"/Credit/:appNo/Sewing_Machine_Business_Cost"}
              exact
              component={SewingMachineBusinessCostAnalysis}
            />
            <Route
              path={"/Credit/:appNo/Machine_Hand_Tools_Business_Cost"}
              exact
              component={MachineAndHandToolsBusinessCostAnalysis}
            />
            <Route
              path={"/Credit/:appNo/Hosiery_Business_Cost"}
              exact
              component={HosieryBusinessCostAnalysis}
            />
            <Route
              path={"/Credit/"}
              render={(props) => (
                <Error
                  loaderModal={(status) => loaderModal(status)}
                  {...props}
                />
              )}
            />
          </Switch>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ data }) => ({
  loaderModalStatus: data.loaderModal,
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditRoutes);
