import * as PhotoConstant from "./Photo.actions.constants";

const initialState = {
  entityDocs: [],
  fetchedDocs: [],
  userLocation: null,
};

export const ClusterPhoto = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case PhotoConstant.RESET_REDUX_STATE:
      return { ...state, fetchedDocs: [], entityDocs: [] ,userLocation:[] };
    case PhotoConstant.FETCH_ENTITY_DOCUMENTS:
      return {
        ...state,
        entityDocs: data,
      };
    case PhotoConstant.FETCH_BANK_ACCOUNTS:
      return {
        ...state,
        fetchedDocs: data,
      };
    case PhotoConstant.USER_LOCATION:
      return {
        ...state,
        userLocation: data,
      };
    default:
      return state;
  }
};
