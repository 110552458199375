import React, { Component } from "react";
import InputLabelAnimation from "../../../components/InputLabelAnimation/InputLabelAnimation";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import Select from "react-select";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getList } from "../../../store/listData.actions";
import {
  API_GET_CONSTITUTION,
  API_GET_MEMBERS,
  API_GET_STATES,
  API_GET_BUSINESS_NATURE,
  API_GET_GENDER,
  API_POST_ADD_CUSTOMER_BUSINESS,
  API_GET_BUSINESS_ADDRESS_TYPE,
  API_GET_CUSTOMER_BUSINESS,
} from "../../../utils/APIUrls";
import { loaderModal } from "../../../store/actions";
import { GET, POST } from "../../../utils/webAPI.service";
import { DatePicker } from "../../../components";
import { getCookie, getDMY, setMaxDate } from "../../../utils/utitlity";
import { Link } from "react-router-dom";
import { Header } from "../../../components";
import { getClusterTaxpayerData } from "../OnboardingDetails/onBoardingDetails.actions";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import DateDropdown from "../../../components/DateDropdown/DateDropdown";
class BusinessDetails extends Component {
  state = {
    activeButton: "footwear",
    memberOrganizations: [{}],
    otherMembers: [{}],
    businessAddresses: [{}],
    maxDate: setMaxDate("dob"),
    operatingSinceMax: setMaxDate("operatingSince"),
    businessNature: "Box Manufacturing",
    currentPinCodes: [],
    durationOfStay: null,
  };
  inputHandler = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };
  dateHandler = (e) => {
    let getYear = Math.floor(e?.getTime() / 31536000000);
    if (getYear < -50) {
      return alert("Please Enter date after 15/01/1920");
    } else {
      this.setState({
        dob: getDMY(new Date(e?.getTime()).toLocaleDateString()),
      });
    }
  };
  testDateHandler = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  operatingSinceDateHandler = (index, e) => {
    if (e !== null) {
      let value = getDMY(new Date(e.getTime()));
      let { businessAddresses } = this.state,
        businessAddressesCopy = [...businessAddresses];
      businessAddressesCopy[index] = {
        ...businessAddressesCopy[index],
        operatingSince: value,
      };
      businessAddresses = businessAddressesCopy;
      this.setState({ businessAddresses });
    }
  };
  organizationInputHandler = (index, e) => {
    let { name, value } = e.target;
    let { memberOrganizations } = this.state,
      organizationsCopy = [...memberOrganizations];
    organizationsCopy[index] = { ...organizationsCopy[index], [name]: value };
    memberOrganizations = organizationsCopy;
    this.setState({ memberOrganizations });
  };
  addressInputHandler = (index, e) => {
    let { name, value } = e.target;
    let { businessAddresses } = this.state,
      businessAddressesCopy = [...businessAddresses];
    businessAddressesCopy[index] = {
      ...businessAddressesCopy[index],
      [name]: value,
    };
    businessAddresses = businessAddressesCopy;
    this.setState({ businessAddresses });
  };
  otherMemberInputHandler = (index, e) => {
    let { name, value } = e.target;
    let { otherMembers } = this.state,
      otherMembersCopy = [...otherMembers];
    otherMembersCopy[index] = { ...otherMembersCopy[index], [name]: value };
    otherMembers = otherMembersCopy;
    this.setState({ otherMembers });
  };
  deleteAddressRow = (rowIndex) => {
    let { businessAddresses } = this.state,
      businessAddressesCopy = [...businessAddresses];
    businessAddressesCopy = businessAddressesCopy.filter(
      (_, index) => rowIndex !== index
    );
    this.setState({ businessAddresses: businessAddressesCopy });
  };

  deleteOrganisationRow = (rowIndex) => {
    let { memberOrganizations } = this.state,
      memberOrganizationsCopy = [...memberOrganizations];
    memberOrganizationsCopy = memberOrganizationsCopy.filter(
      (_, index) => rowIndex !== index
    );
    this.setState({ memberOrganizations: memberOrganizationsCopy });
  };
  deleteOtherMembersRow = (rowIndex) => {
    let { otherMembers } = this.state,
      otherMembersCopy = [...otherMembers];
    otherMembersCopy = otherMembersCopy.filter(
      (_, index) => rowIndex !== index
    );
    this.setState({ otherMembers: otherMembersCopy });
  };

  createAppHandler = (actionType) => {
    let { actions, match, history } = this.props,
      {
        businessNature,
        memberOrganizations,
        businessAadhar,
        constitution,
        businessPan,
        operatingStates,
        otherMembers,
        businessAddresses,
        name,
        fatherName,
        gender,
        dob,
        pan,
        businessNatureText,
      } = this.state;

    actions.loaderModal(true);
    let dataToSend = {
      data: {
        businessAadhar,
        businessAddresses,
        businessNature: businessNatureText
          ? businessNatureText
          : businessNature,
        businessPan,
        constitution,
        customerId: match.params.custId,
        mainOwnerDetails: {
          dob,
          fatherName,
          gender,
          name,
          pan,
        },
        memberOrganizations,
        operatingStates,
        otherMembers,
      },
    };
    POST(API_POST_ADD_CUSTOMER_BUSINESS, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          if (actionType === "save") {
            history.push(
              `/cluster/${match.params.custId}/business-account-details/${match.params.clusterType}`
            );
          } else if (actionType === "draft") {
            history.push(`/cluster/dashboard`);
          }
        } else if (data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  setCurrentInputPinCode = (e) => {
    clearTimeout(this.searchCurrentPinCode);
    this.searchCurrentPinCode = setTimeout(() => {
      if (e.trim().length > 1) {
        this.setState(
          {
            currentPinCodeLoader: true,
          },
          () => {
            GET(`/getPinCodes/${e}`).then(({ data }) => {
              if (data.status === "success") {
                let list = data.data,
                  currentPinCodes = [],
                  pinCodeList = [];
                for (let i = 0; i < list.length; i++) {
                  if (!pinCodeList.includes(list[i].pincode)) {
                    pinCodeList.push(list[i].pincode);
                    currentPinCodes.push({
                      label: list[i].pincode,
                      value: list[i].pincode,
                      city: list[i].city,
                      district: list[i].district,
                      state: list[i].state,
                    });
                  }
                }
                this.setState({
                  currentPinCodes,
                  currentPinCodeLoader: false,
                });
              } else {
                this.setState({ currentPinCodeLoader: false });
              }
            });
          }
        );
      }
    }, 500);
  };
  componentWillUnmount = () => {
    document.cookie = `gstin= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/cluster`;
  };
  setCurrentPinCode = (index, e) => {
    let { value, district, city, state } = e;
    let { businessAddresses } = this.state,
      businessAddressesCopy = [...businessAddresses];
    businessAddressesCopy[index] = {
      ...businessAddressesCopy[index],
      district,
      city,
      state,
      pinCode: value,
    };
    businessAddresses = businessAddressesCopy;
    this.setState({ businessAddresses });
  };
  render() {
    let {
        businessNature,
        memberOrganizations,
        businessAadhar,
        constitution,
        businessPan,
        otherMembers,
        dob,
        maxDate,
        name,
        fatherName,
        gender,
        pan,
        businessAddresses,
        operatingSinceMax,
        operatingStates,
        businessNatureText,
        currentPinCodes,
        currentPinCodeLoader,
      } = this.state,
      {
        businessConstitution,
        relationList,
        statesList,
        businessNatureList,
        genderList,
        addressTypeList,
        match,
      } = this.props;
    let _statesList = statesList.map((el) => ({
        label: el,
        value: el,
      })),
      { clusterType } = match.params;
    return (
      <>
        <Header {...this.props} />
        <div className="business-details__wrapper">
          <div className="container">
            <Link
              to={`/cluster/${match.params.custId}/onboarding`}
              className={"back-btn-link"}
            >
              <Icon icon={angleLeft} size={24} /> Back
            </Link>

            <h1 className="container__heading">Business Details</h1>
            <form autoComplete="off">
              <div className="container p-top--sub ">
                <h2 className="sub-heading">Business registration</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Udyog Aadhar Number"}
                      onChange={this.inputHandler}
                      name="businessAadhar"
                      inputValue={businessAadhar}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--main col-xs-12"}>
                    <select
                      className={"select-box"}
                      onChange={this.inputHandler}
                      name="constitution"
                      value={constitution || ""}
                    >
                      {businessConstitution.map((el, index) => {
                        return (
                          <option key={`business-const-${index}`}> {el}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"PAN of Entity"}
                      onChange={this.inputHandler}
                      name="businessPan"
                      inputValue={businessPan}
                      maxLength={10}
                    />
                  </div>
                </div>
              </div>
              <div className="container  p-top--sub">
                <div className="sub-heading-wrap">
                  <div className="flex-box   ">
                    <h2 className=" sub-heading_basic">
                      {" "}
                      Business member organizations
                    </h2>
                    <div
                      className="btn-add"
                      onClick={() =>
                        this.setState((prevState) => ({
                          memberOrganizations: [
                            ...prevState.memberOrganizations,
                            {},
                          ],
                        }))
                      }
                    >
                      +Add organization
                    </div>
                  </div>
                </div>
                {memberOrganizations.map((el, index) => {
                  return (
                    <div
                      className="container "
                      key={`member-organizations-${index}`}
                    >
                      <div className={"row "} key={`organization-${index}`}>
                        <div className={"col-sm-5 m-top--main col-xs-9 p-left"}>
                          <InputLabelAnimation
                            labelText={"Name"}
                            onChange={(e) =>
                              this.organizationInputHandler(index, e)
                            }
                            name="name"
                            inputValue={el.name}
                          />
                        </div>
                        <div className={"col-sm-5 m-top--main col-xs-9"}>
                          <InputLabelAnimation
                            labelText={"Registration Number"}
                            onChange={(e) =>
                              this.organizationInputHandler(index, e)
                            }
                            name="registerationNumber"
                            inputValue={el.registerationNumber}
                          />
                        </div>
                        {index > 0 ? (
                          <span
                            onClick={() => this.deleteOrganisationRow(index)}
                            className={"remove-icon"}
                          >
                            <Icon icon={ic_delete_forever} size={28} />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="container  p-top--sub">
                <div className="sub-heading-wrap ">
                  <div className="flex-box   ">
                    <h2 className=" sub-heading_basic">
                      {" "}
                      Other members involved in business
                    </h2>
                    <div
                      className="btn-add"
                      onClick={() =>
                        this.setState((prevState) => ({
                          otherMembers: [...prevState.otherMembers, {}],
                        }))
                      }
                    >
                      + Add member
                    </div>
                  </div>
                </div>
                {otherMembers.map((el, index) => {
                  return (
                    <div
                      className="container  p-top--sub"
                      key={`other-member-${index}`}
                    >
                      <div className={"row "} key={`other-members-${index}`}>
                        <div className={"col-sm-5 m-top--main col-xs-9 p-left"}>
                          <InputLabelAnimation
                            labelText={"Name"}
                            onChange={(e) =>
                              this.otherMemberInputHandler(index, e)
                            }
                            name="name"
                            inputValue={el.name}
                          />
                        </div>
                        <div className={"col-sm-5 m-top--main col-xs-9"}>
                          <select
                            className={"select-box"}
                            name="relation"
                            onChange={(e) =>
                              this.otherMemberInputHandler(index, e)
                            }
                            value={el.relation || ""}
                          >
                            <option value={null}>Select Relation</option>
                            {relationList.map((el, index) => {
                              return (
                                <option key={`relation-${index}`}> {el}</option>
                              );
                            })}
                          </select>
                        </div>
                        {index > 0 ? (
                          <span
                            onClick={() => this.deleteOtherMembersRow(index)}
                            className={"remove-icon"}
                          >
                            <Icon icon={ic_delete_forever} size={28} />
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="container  p-top--sub">
                <h2 className="sub-heading">Operating states</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={_statesList}
                      onChange={(e) =>
                        this.setState({
                          operatingStates: e.map((el) => el.value),
                        })
                      }
                      value={
                        operatingStates &&
                        operatingStates.map((el) =>
                          _statesList.find((option) => option.value === el)
                        )
                      }
                      defaultValue={operatingStates}
                      name="operatingStates"
                    />
                  </div>
                </div>
              </div>
              <div className="container p-top--sub">
                <div className="sub-heading-wrap   ">
                  <div className="flex-box ">
                    <h2 className=" sub-heading_basic">
                      Address of Business Premises
                    </h2>
                    <div
                      className="btn-add"
                      onClick={() =>
                        this.setState((prevState) => ({
                          businessAddresses: [
                            ...prevState.businessAddresses,
                            {},
                          ],
                        }))
                      }
                    >
                      + Add address
                    </div>
                  </div>
                </div>
                {businessAddresses?.map((el, index) => {
                  return (
                    <div
                      className="container m-top--main  p-top--sub"
                      key={`business-address-${index}`}
                    >
                      <div className="delete-container">
                        <div className="address">Address {index + 1}</div>
                        {index > 0 ? (
                          <span
                            onClick={() => this.deleteAddressRow(index)}
                            className={"remove-icon "}
                          >
                            <Icon icon={ic_delete_forever} size={28} />
                          </span>
                        ) : null}
                      </div>
                      <div className={"row "}>
                        <div
                          className={"col-sm-4 m-top--main col-xs-12 p-left"}
                        >
                          <select
                            className={"select-box"}
                            name={"addressType"}
                            value={el.addressType || ""}
                            onChange={(e) => this.addressInputHandler(index, e)}
                          >
                            {addressTypeList.map((el, index) => {
                              return (
                                <option key={`relation-${index}`}> {el}</option>
                              );
                            })}
                          </select>
                        </div>
                        <div className={"col-sm-4 m-top--main col-xs-12"}>
                          <InputLabelAnimation
                            labelText={" Floor/Building number"}
                            required={true}
                            onChange={(e) => this.addressInputHandler(index, e)}
                            name="line1"
                            inputValue={el.line1}
                          />
                        </div>
                        <div className={"col-sm-4 m-top--main col-xs-12"}>
                          <InputLabelAnimation
                            labelText={" Street/Landmark"}
                            required={true}
                            onChange={(e) => this.addressInputHandler(index, e)}
                            name="line2"
                            inputValue={el.line2}
                          />
                        </div>
                      </div>
                      <div className={"row"}>
                        <div
                          className={
                            "col-sm-4 m-top--main col-xs-12 col-xs-12 p-left"
                          }
                        >
                          <Select
                            value={
                              currentPinCodes.find(
                                (option) => option.value === el.pinCode
                              ) || el.pinCode
                                ? {
                                    value: el.pinCode,
                                    label: el.pinCode,
                                  }
                                : ""
                            }
                            placeholder={"Pin Code *"}
                            isSearchable={true}
                            name="current_pin_code"
                            options={currentPinCodes}
                            className="pin-code-select-search"
                            classNamePrefix="select"
                            onInputChange={this.setCurrentInputPinCode}
                            onChange={(e) => this.setCurrentPinCode(index, e)}
                            isLoading={currentPinCodeLoader}
                            required={true}
                          />
                        </div>
                        <div className={"col-sm-4 m-top--main col-xs-12"}>
                          <InputLabelAnimation
                            labelText={" City"}
                            required={true}
                            onChange={(e) => this.addressInputHandler(index, e)}
                            name="city"
                            inputValue={el.city}
                          />
                        </div>
                        <div className={"col-sm-4 m-top--main col-xs-12"}>
                          <select
                            className={"select-box"}
                            name={"state"}
                            value={el.state?.toUpperCase() || ""}
                            onChange={(e) => this.addressInputHandler(index, e)}
                          >
                            {statesList.map((el, index) => {
                              return (
                                
                                <option key={`state-${index}`}> {el}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className={"row "}>
                        <div
                          className={"col-sm-4 m-top--main col-xs-12 p-left"}
                        >
                          <select
                            className={"select-box"}
                            onChange={(e) => this.addressInputHandler(index, e)}
                            name="ownership"
                            value={el.ownership || ""}
                          >
                            {["Select Ownership", "Self-Owned", "Rented"].map(
                              (el, ownerIndex) => {
                                return (
                                  <option
                                    key={`business-address-${index}-${ownerIndex}`}
                                  >
                                    {el}{" "}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        <div className={"col-sm-4 m-top--main col-xs-12"}>
                          <DatePicker
                            required={true}
                            selected={
                              el.operatingSince
                                ? new Date(
                                    el.operatingSince.split("-").reverse()
                                  )
                                : null
                            }
                            onChange={(e) =>
                              this.operatingSinceDateHandler(index, e)
                            }
                            maxDate={new Date(operatingSinceMax)}
                            defaultValue={null}
                            name={"operatingSince"}
                            labelText={" Operating since"}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {clusterType === "Agra-Footwear-Cluster" ? (
                <div className="container  p-top--sub">
                  <h2 className="sub-heading">Nature of business</h2>
                  <div className={"row "}>
                    {businessNatureList.map((el, index) => {
                      return (
                        <div
                          className={"col-sm-4 p-left col-xs-12"}
                          key={`business-nature-${index}`}
                        >
                          <div
                            onClick={() => {
                              this.setState({ businessNature: el });
                            }}
                            className={
                              businessNature === el
                                ? "btn-active"
                                : "btn-normal"
                            }
                          >
                            {el}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {!businessNatureList
                    .filter((el) => el !== "Others")
                    .includes(businessNature) ? (
                    <textarea
                      className="text-area"
                      placeholder=" If other, please mention..."
                      cols="30"
                      rows="5"
                      onChange={(e) =>
                        this.setState({ businessNatureText: e.target.value })
                      }
                      value={businessNatureText}
                    ></textarea>
                  ) : null}
                </div>
              ) : null}

              <div className="container  p-top--sub">
                <h2 className="sub-heading">Main owner details</h2>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <InputLabelAnimation
                      labelText={"Name "}
                      required={true}
                      onChange={this.inputHandler}
                      name="name"
                      inputValue={name}
                    />
                  </div>
                  <div className={"col-sm-6 m-top--main col-xs-12"}>
                    <InputLabelAnimation
                      labelText={"  Father’s Name"}
                      required={true}
                      onChange={this.inputHandler}
                      name="fatherName"
                      inputValue={fatherName}
                    />
                  </div>
                </div>
                <div className={"row "}>
                  <div className={"col-sm-6 m-top--main col-xs-12 p-left"}>
                    <select
                      className={"select-box"}
                      onChange={this.inputHandler}
                      name="gender"
                      value={gender || ""}
                    >
                      {genderList.map((el, index) => {
                        return <option key={`gender-${index}`}> {el}</option>;
                      })}
                    </select>
                  </div>
                  <div className={"col-sm-6 m-top--main col-xs-12"}>
                    <InputLabelAnimation
                      labelText={" PAN of Main owner"}
                      required={true}
                      onChange={this.inputHandler}
                      name="pan"
                      inputValue={pan}
                      maxLength={10}
                    />

                    {/* <DatePicker
                      required={true}
                      selected={dob ? new Date(dob.split("-").reverse()) : null}
                      onChange={this.dateHandler}
                      defaultValue={null}
                      name={"dob"}
                      labelText={"Date of Birth (DD/MM/YYYY)"}
                      maxDate={new Date(dob)}
                    /> */}
                    {/* <DateDropdown onDateChange={this.testDateHandler} componentName="dob" value={dob || ""} /> */}
                  </div>
                  <div className={"row "}>
                    <div className={"col-sm-6 col-xs-12"}>Date Of Birth</div>

                    <div className={"col-sm-6 m-top--main col-xs-12"}>
                      <DateDropdown
                        onDateChange={this.testDateHandler}
                        componentName="dob"
                        value={dob || ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn-box">
                <span
                  onClick={() => this.createAppHandler("draft")}
                  className={"btn-draft"}
                >
                  Save draft
                </span>
                <span
                  onClick={() => this.createAppHandler("save")}
                  className={"btn-save"}
                >
                  Save & Next
                </span>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
  componentDidMount = async () => {
    let {
        actions,
        businessConstitution,
        relationList,
        statesList,
        businessNatureList,
        genderList,
        addressTypeList,
        taxpayerData,
        match,
      } = this.props,
      { getList, getClusterTaxpayerData, loaderModal } = actions;
    loaderModal(true);
    let gstin = getCookie("gstin");
    !businessConstitution.length &&
      getList(
        "SET_BUSINESS_CONSTITUTION_LIST",
        API_GET_CONSTITUTION,
        "Select Business Constitution"
      );
    !relationList.length && getList("SET_RELATION_LIST", API_GET_MEMBERS);
    !statesList.length &&
      getList("SET_STATES_LIST", API_GET_STATES, "Select State");
    !businessNatureList.length &&
      getList("SET_BUSINESS_NATURE", API_GET_BUSINESS_NATURE);
    !genderList.length &&
      getList("SET_GENDER_LIST", API_GET_GENDER, "Select Gender");
    !addressTypeList.length &&
      getList(
        "SET_ADDRESS_TYPE",
        API_GET_BUSINESS_ADDRESS_TYPE,
        "Select Address Type"
      );
    if (!Object.keys(taxpayerData).length && gstin) {
      getClusterTaxpayerData(gstin).then(({ data }) => {
        if (data) {
          let {
            constitution,
            businessAddress,
            businessPan,
            businessLegalName,
          } = data;
          this.setState({
            constitution,
            businessAddresses: businessAddress,
            businessPan,
            pan: constitution === "Proprietorship" ? businessPan : "",
            name: constitution === "Proprietorship" ? businessLegalName : "",
          });
        }
      });
    } else if (Object.keys(taxpayerData).length) {
      let {
        constitution,
        businessAddress,
        businessPan,
        businessLegalName,
      } = taxpayerData;
      this.setState({
        constitution,
        businessAddresses: businessAddress,
        businessPan,
        pan: constitution === "Proprietorship" ? businessPan : "",
        name: constitution === "Proprietorship" ? businessLegalName : "",
      });
    }
    await GET(API_GET_CUSTOMER_BUSINESS(match.params.custId)).then(
      ({ data }) => {
        if (data.data.businessAddresses) {
          let {
            otherMembers,
            businessNature,
            memberOrganizations,
            businessAadhar,
            constitution,
            businessPan,
            mainOwnerDetails,
            businessAddresses,
            operatingStates,
          } = data.data;
          this.setState({
            businessNature,
            memberOrganizations,
            businessAadhar,
            constitution,
            businessPan,
            otherMembers,
            businessAddresses,
            operatingStates,
            dob: mainOwnerDetails?.dob,
            fatherName: mainOwnerDetails?.fatherName,
            gender: mainOwnerDetails?.gender,
            name: mainOwnerDetails?.name,
            pan: mainOwnerDetails?.pan,
            businessNatureText: businessNature,
          });
        }
      }
    );
    loaderModal(false);
  };
}
const mapStateToProps = ({ listData, onBoardingReducer }) => ({
  businessConstitution: listData.businessConstitutionList,
  relationList: listData.relationList,
  statesList: listData.statesList,
  businessNatureList: listData.businessNatureList,
  genderList: listData.genderList,
  addressTypeList: listData.addressTypeList,
  taxpayerData: onBoardingReducer.taxpayerData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getList,
      loaderModal,
      getClusterTaxpayerData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessDetails);
