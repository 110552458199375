import React from 'react';
import Icon from "react-icons-kit";
import {ic_check} from 'react-icons-kit/md/ic_check';

const docsColumns = [{
    accessor: "",
    Cell: (item) => {
        return (
            <span>{item.index + 1}</span>
        );
    },
    Header: 'No.',
    sortable: false,
    width: 50,
}, {
    Header: 'Document Type',
    accessor: 'documentType',
    Cell: ({original}) => {
        return (
            <span title={original.documentType}>{original.documentType}</span>
        );
    },
}, {
    Header: 'Format',
    accessor: 'format',
    Cell: ({original}) => {
        return (
            <span title={original.format}>{original.format}</span>
        );
    },
}, {
    Header: 'Upload',
    accessor: 'uploadStatusFlag',
    Cell: ({original}) => {
        if (original.uploadStatusFlag) {
            return (
                <span className={'check-icon'}><Icon icon={ic_check} size={20}/></span>
            )
        } else {
            return null
        }
    },
    width: 80,
}];

export {
    docsColumns
}
