import React, { PureComponent, Fragment } from "react";
import SectionA from "./SectionA/sectionA";
import SectionB from "./SectionB/sectionB";
import SectionC from "./SectionC/sectionC";
import SectionD from "./sectionD";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCamData, getApplicationStatus, getApplicationLoginDetails } from "./cam.actions";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { loaderModal } from "../../../store/actions";
import { LoaderModal } from "../../../components";
class CamPage extends PureComponent {
  state = {};
  componentDidMount = async () => {
    const { actions, match } = this.props,
      applicationNumber = get(match, "params.appNo", ""),
      { getApplicationStatus, getApplicationLoginDetails, loaderModal } = actions;
    loaderModal(true);
    getApplicationLoginDetails(applicationNumber);
    await getApplicationStatus(applicationNumber);
    loaderModal(false);
  };
  printPage = () => {
    window.print();
  };
  render() {
    let { applicationLoginData, applicationStatusData, match, loaderModalStatus } = this.props,
      applicationNumber = get(match, "params.appNo", "");
  
    return (
      <Fragment>
        <LoaderModal status={loaderModalStatus} />
        <div className="camWrapper">
          <Helmet>
            <meta charSet="utf-8" />
            <title>CAM</title>
          </Helmet>
          <div className="heading">
            <span>CREDIT ASSESSMENT MEMO</span>
            <button onClick={this.printPage} className="printPage">
              Print this page
            </button>
          </div>

          <hr className="heading-hr" />
          <div className="flex-space-between">
            <div className="caseId">
              CaseId:
              <span className="caseId-Box">{applicationNumber}</span>
            </div>
            <div className="case-status">
              Case Status:
              <span className="case-status-box">{applicationStatusData.caseStatus}</span>
            </div>
          </div>
          {applicationLoginData ? (
            <div className="box-wrapper">
              <div className="box-heading">Case Details</div>
              <hr />
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Date Of Login</div>
                  <div>{applicationLoginData.dateOfLogin}</div>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Date Of Decision</div>
                  <div>{applicationLoginData.dateOfDecision}</div>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">CAM Generation Time</div>
                  <div>{applicationLoginData.generationTime}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Type of Loan Applied</div>
                  <div>{applicationLoginData.typeOfLoanApplied}</div>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Repayment Type</div>
                  <div>{applicationLoginData.repaymentType}</div>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Generated By</div>
                  <div>{applicationLoginData.generatedBy}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Loan Amount approved (in ₹)</div>
                  <div>{applicationLoginData.loanAmountApproved}</div>
                </div>
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">Tenure Approved</div>
                  <div>{applicationLoginData.tenureApproved}</div>
                </div>
                {/* <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="box-subheading">EMI (in ₹)</div>
                  <div>{applicationLoginData.emi}</div>
                </div> */}
              </div>
            </div>
          ) : null}

          <div className="section-heading">
            <div>
              SECTION
              <span className="alphabets">
                <span className="letter">
                  <span>A</span>
                </span>
                BCD
              </span>
            </div>
            <hr className="section-hr" />
            <div className="section-details">Entity Details</div>
          </div>
          <SectionA {...this.props} />
          <div className="section-heading">
            <div>
              SECTION
              <span className="alphabets">
                A
                <span className="letter">
                  <span>B</span>
                </span>
                CD
              </span>
            </div>
            <hr className="section-hr" />
            <div className="section-details">Business Summary</div>
          </div>
          <SectionB {...this.props} />
          <div className="section-heading">
            <div>
              SECTION
              <span className="alphabets">
                AB
                <span className="letter">
                  <span>C</span>
                </span>
                D
              </span>
            </div>
            <hr className="section-hr" />
            <div className="section-details">Decision Summary</div>
          </div>
          <SectionC {...this.props} />
          <div className="section-heading">
            <div>
              SECTION
              <span className="alphabets">
                ABC
                <span className="letter">
                  <span>D</span>
                </span>
              </span>
            </div>
            <hr className="section-hr" />
            <div className="section-details">Annexures</div>
          </div>
          <SectionD {...this.props} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ CamResponse, data }) => ({
  applicationLoginData: CamResponse.applicationLoginData,
  loaderModalStatus: data.loaderModal,
  applicationStatusData: CamResponse.applicationStatusData
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCamData,
      getApplicationStatus,
      getApplicationLoginDetails,
      loaderModal
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(CamPage);
