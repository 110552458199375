import * as ApplicantGuideSelectors from './applicantGuide.selector';
import * as ApplicantGuideConstatnt from './applicantGuide.actions.constant';

const initialState = {
    initialResponse: false,
    shareholdingDetails: {},
    entityDetails: {},
    constitutionRelations: [],
    btnStatus:false
};

export const applicantGuideReducers = (state = initialState, action) => {
    const {data, type, name, index, status} = action;
    switch (type) {
        case ApplicantGuideConstatnt.SET_INITIAL_RESPONSE:
            return {...state, initialResponse: status};
        case ApplicantGuideConstatnt.SET_SHAREHOLDING_DETAILS:
            return {...state, shareholdingDetails: data};
        case ApplicantGuideConstatnt.RESET_REDUX_STATE:
            return {...state, shareholdingDetails: []};
        case ApplicantGuideConstatnt.SET_ENTITY_DETAILS:
            return {...state, entityDetails: data};
        case ApplicantGuideConstatnt.SET_CONSTITUTION_RELATIONS:
            return {...state, constitutionRelations: data};
        case ApplicantGuideConstatnt.CHANGE_ROW_VALUES:
            return ApplicantGuideSelectors._updateRowValues(state, name, data, index);
        case ApplicantGuideConstatnt.DELETE_ROW:
            return ApplicantGuideSelectors._deleteRow(state, index);
        case ApplicantGuideConstatnt.ADD_ROW:
            return ApplicantGuideSelectors._addRow(state);
        default:
            return state;
    }
};
