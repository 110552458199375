import React, { Component } from "react";
import { InputLabelAnimation, DatePicker } from "../../../components";
import { get } from "lodash";
import { setMaxDate, getDMY } from "../../../utils/utitlity";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateModalInputValue } from "./creditCapacity.actions";
import { POST } from "../../../utils/webAPI.service";
import { API_ADD_TRADE_LINES, API_POST_UPDATE_BUREAU_TRADE_LINE } from "../../../utils/APIUrls";
import { getObligationsData, getCreditCapacityDetails } from "./creditCapacity.actions";
import { loaderModal } from "../../../store/actions";
class tradelineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxDate: setMaxDate(),
    };
  }
  modalInputHandler = (e) => {
    const name = e.target.name,
      value = e.target.value;

    this.setState({ [name]: value });
  };
  modalInputNumberHandler = (e) => {
    let value = e.target.value,
      name = e.target.name,
      reg = /^[0-9]*$/;
    // if (reg.test(value)) {
      this.setState({ [name]: value });
    // }
  };
  modalDateHandler = (e, name) => {
    if (e !== null) {
      let value = getDMY(new Date(e.getTime()).toLocaleDateString());
      this.setState({ [name]: value });
    }
  };
  componentDidMount() {
    let { formModalData } = this.props;
    if (formModalData) {
      for (var key in formModalData) {
        if (formModalData.hasOwnProperty(key)) {
          this.setState({ [key]: formModalData[key] });
        }
      }
    }
  }
  updateTradeLineHandler = async () => {
    let { match, actions, formModalData } = this.props;
    let applicationNumber = get(match, "params.appNo", ""),
      { loaderModal } = actions,
      { pancard, sno, isTradeLine } = formModalData;
    let {
        accountHolderName,
        bankName,
        closingDate,
        comment,
        currentBalance,
        emiAmount,
        obligatedEMI,
        isClosingInSixMonths,
        isDuplicate,
        isEmiObligated,
        lender,
        loanAmount,
        monthOnBook,
        openingDate,
        ownershipType,
        remarks,
        reportingDate,
        type,
      } = this.state,
      headers = { "Content-Type": "application/json" };
    const data = {
      accountHolderName,
      bankDetails: bankName,
      closingDate,
      comment,
      currentBalance: Number(currentBalance),
      emiAmount: Number(emiAmount),
      emiConsidered: Number(obligatedEMI),
      isClosingInSixMonths: Boolean(isClosingInSixMonths),
      isDuplicate: Boolean(isDuplicate),
      isEmiObligated: isEmiObligated === "true" ? true : false,
      isTradeLine: isTradeLine,
      lender,
      loanAmount: Number(loanAmount),
      monthOnBook: Number(monthOnBook),
      openingDate,
      ownershipType,
      pan: pancard,
      remarks,
      reportingDate,
      seqNo: sno,
      type,
    };
    loaderModal(true);
    await POST(API_POST_UPDATE_BUREAU_TRADE_LINE(applicationNumber), { data }, { headers })
      .then(({ data, status }) => {})
      .then(({ data }) => {
        this.setState({ modalVisible: false });
      })
      .catch((err) => {
        console.log(err);
        loaderModal(false);
        this.setState({ modalVisible: false });
      });
    this.props.closeModalHandler();
    await this.props.actions.getObligationsData(applicationNumber);
    this.props.getCreditCapacityDetails();

    loaderModal(false);
  };
  addTradeLineHandler = async () => {
    let { match, actions } = this.props;
    let applicationNumber = get(match, "params.appNo", ""),
      { loaderModal } = actions;
    let {
        accountHolderName,
        bankName,
        closingDate,
        comment,
        currentBalance,
        emiAmount,
        obligatedEMI,
        isClosingInSixMonths,
        isDuplicate,
        isEmiObligated,
        lender,
        loanAmount,
        monthOnBook,
        openingDate,
        ownershipType,
        remarks,
        reportingDate,
        type,
      } = this.state,
      headers = { "Content-Type": "application/json" };
    const data = {
      accountHolderName,
      bankDetails: bankName,
      closingDate,
      comment,
      currentBalance: Number(currentBalance),
      emiAmount: Number(emiAmount),
      emiConsidered: Number(obligatedEMI),
      isClosingInSixMonths: Boolean(isClosingInSixMonths),
      isDuplicate: Boolean(isDuplicate),
      isEmiObligated: isEmiObligated === "true" ? true : false,
      lender,
      loanAmount: Number(loanAmount),
      monthOnBook: Number(monthOnBook),
      openingDate,
      ownershipType,
      remarks,
      reportingDate,
      type,
    };
    loaderModal(true);
    await POST(API_ADD_TRADE_LINES(applicationNumber), { data }, { headers })
      .then(({ data }) => {
        this.setState({ modalVisible: false });
      })
      .catch((err) => {
        console.log(err);
        loaderModal(false);
        this.setState({ modalVisible: false });
      });
    this.props.closeModalHandler();
    actions.getCreditCapacityDetails(applicationNumber);
    await actions.getObligationsData(applicationNumber);
    loaderModal(false);
  };

  render() {
    let { maxDate } = this.state,
      { isEditable, isEdit, isObligate, formModalData } = this.props,
      {
        lenderName,
        emiObligated,
        accountHolderName,
        loanAmount,
        currentBalance,
        emiAmount,
        openingDate,
        closingDate,
        reportingDate,
        monthOnBook,
        remark,
        comment,
        emiConsidered,
        bankDetails,
        duplicate,
        closingIn6Months,
        ownershipType,
      } = isEditable,
      { isTradeLine } = formModalData ? formModalData : {};
    if (isTradeLine === undefined) {
      isTradeLine = true;
    }
    return (
      <div>
        <div className="row mt-2">
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Lender Name"}
              name={"lender"}
              title={"Lender"}
              inputValue={this.state["lender"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && lenderName}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Ownership Type"}
              name={"ownershipType"}
              title={"Ownership Type"}
              inputValue={this.state["ownershipType"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && ownershipType}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Account Holder Name"}
              name={"accountHolderName"}
              inputValue={this.state["accountHolderName"]}
              onChange={this.modalInputHandler}
              title={"Account Holder Name"}
              disabled={!isTradeLine && accountHolderName}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Loan Amount"}
              name={"loanAmount"}
              title={"Loan Amount"}
              inputValue={this.state["loanAmount"]}
              onChange={this.modalInputNumberHandler}
              disabled={!isTradeLine && loanAmount}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Current Balance"}
              name={"currentBalance"}
              title={"Current Balance"}
              inputValue={this.state["currentBalance"]}
              onChange={this.modalInputNumberHandler}
              disabled={!isTradeLine && currentBalance}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"EMI Amount"}
              name={"emiAmount"}
              title={"EMI Amount"}
              inputValue={this.state["emiAmount"]}
              onChange={this.modalInputNumberHandler}
              required={true}
              disabled={!isTradeLine && emiAmount}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <DatePicker
              selected={
                this.state["openingDate"]
                  ? this.state["openingDate"] !== "NA"
                    ? new Date(this.state["openingDate"].split("-").reverse())
                    : null
                  : null
              }
              onChange={(e) => this.modalDateHandler(e, "openingDate")}
              maxDate={new Date(maxDate)}
              name="openingDate"
              labelText={"Opening Date"}
              disabled={!isTradeLine && openingDate}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              selected={
                this.state["closingDate"]
                  ? this.state["closingDate"] !== "NA"
                    ? new Date(this.state["closingDate"].split("-").reverse())
                    : null
                  : null
              }
              onChange={(e) => this.modalDateHandler(e, "closingDate")}
              maxDate={new Date(maxDate)}
              name="closingDate"
              className={"date-field"}
              labelText={"Closing Date"}
              disabled={!isTradeLine && closingDate}
            />
          </div>
          <div className="col-md-2">
            <DatePicker
              selected={
                this.state["reportingDate"]
                  ? this.state["reportingDate"] !== "NA"
                    ? new Date(this.state["reportingDate"].split("-").reverse())
                    : null
                  : null
              }
              onChange={(e) => this.modalDateHandler(e, "reportingDate")}
              maxDate={new Date(maxDate)}
              name="reportingDate"
              className={"date-field"}
              labelText={"Reporting Date"}
              disabled={!isTradeLine && reportingDate}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Month On Book"}
              name={"monthOnBook"}
              title={"Month On Book"}
              inputValue={this.state["monthOnBook"]}
              onChange={this.modalInputNumberHandler}
              disabled={!isTradeLine && monthOnBook}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Remark"}
              name={"remarks"}
              title={"Remark"}
              inputValue={this.state["remarks"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && remark}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Comment"}
              name={"comment"}
              title={"Comment"}
              inputValue={this.state["comment"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && comment}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Emi Considered"}
              name={"obligatedEMI"}
              title={"Emi Considered"}
              inputValue={this.state["obligatedEMI"]}
              onChange={this.modalInputNumberHandler}
              disabled={!isTradeLine && emiConsidered}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Bank Details"}
              name={"bankName"}
              title={"Bank Details"}
              inputValue={this.state["bankName"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && bankDetails}
            />
          </div>
          <div className="col-md-2">
            <InputLabelAnimation
              labelText={"Loan Type"}
              name={"type"}
              title={"Loan Type"}
              inputValue={this.state["type"]}
              onChange={this.modalInputHandler}
              disabled={!isTradeLine && closingIn6Months}
            />
          </div>
          <div className="col-md-2">
            <div className="row">
              <label>Duplicate</label>
            </div>
            <div className="row">
              <select
                onChange={this.modalInputHandler}
                className={"select-box"}
                value={this.state["isDuplicate"] ? this.state["isDuplicate"] : false}
                name={"isDuplicate"}
                disabled={!isTradeLine && duplicate}
              >
                <option value={""}>Select Status</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
          </div>
          <div className="col-md-2">
            <div className="row">
              <label>Closing In 6 months</label>
            </div>
            <div className="row">
              <select
                onChange={this.modalInputHandler}
                className={"select-box"}
                value={this.state["isClosingInSixMonths"] ? this.state["isClosingInSixMonths"] : false}
                name={"isClosingInSixMonths"}
                disabled={!isTradeLine && closingIn6Months}
              >
                <option value={""}>Select Status</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
          </div>

          <div className="col-md-2">
            <div className="row">
              <label>Emi Obligated</label>
            </div>
            <div className="row">
              <select
                onChange={this.modalInputHandler}
                className={"select-box"}
                value={this.state["isEmiObligated"] ? this.state["isEmiObligated"] : false}
                name={"isEmiObligated"}
                disabled={!isTradeLine && emiObligated}
              >
                <option value={""}>Select Status</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row btns-box">
          {!isEdit && !isObligate ? (
            <button onClick={this.addTradeLineHandler}>Submit</button>
          ) : isObligate ? (
            <button onClick={this.addTradeLineHandler}>Obligate</button>
          ) : (
            <button onClick={this.updateTradeLineHandler}>Update</button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ creditCapacity }) => ({
  formModal: creditCapacity.formModal,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateModalInputValue,
      getObligationsData,
      loaderModal,
      getCreditCapacityDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(tradelineModal);
