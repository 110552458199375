import React, { Component } from "react";

export default class BusinessAddress extends Component {
  render() {
    let { PropsData } = this.props;
    return (
      <>
        <div className="bank-details-wrapper">
          <h2> Addresses of Business Premises</h2>
          {PropsData?.addresses?.map((e, index) => {
            return (
              <div className={" shadow-box m-around"} key={`address-${index}`}>
                <span className="p-left"> {`Address ${index + 1} `}</span>
                <div className={"row"}>
                  <div className={"col-sm-4 block "}>
                    Address type
                    <div>{e.addressType || "-"}</div>
                  </div>
                  <div className={"col-sm-4 block "}>
                    Floor/Building no.
                    <div>{e.line1 || "-"}</div>
                  </div>
                  <div className={"col-sm-4  block"}>
                    Street/Landmark
                    <div>{e.line2 || "-"}</div>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-sm-4 block "}>
                    PINCODE
                    <div>{e.pinCode || "-"}</div>
                  </div>
                  <div className={"col-sm-4 block "}>
                    City
                    <div>{e.city || "-"}</div>
                  </div>
                  <div className={"col-sm-4 block "}>
                    State
                    <div>{e.state || "-"}</div>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-sm-4 block "}>
                    Ownership
                    <div>{e.ownership || "-"}</div>
                  </div>
                  <div className={"col-sm-4 block "}>
                    Operating since
                    <div>{e.durationOfStay || "-"}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
