import React, { Component } from "react";
import { InputLabelAnimation } from "../../../components";
import get from "lodash/get";

class InHouseSales extends Component {
  render() {
    let { sourcingDetails , inputs ,inputHandler } = this.props,
     { rmName } = inputs;
    return (
      <>
        <div className="row">
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={" RM name"}
              required={true}
              type={"text"}
              inputValue={rmName}
              disabled={true}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"RM Code"}
              required={true}
              type={"text"}
              onChange={inputHandler}
              name={"rmCode"}
              inputValue={get(sourcingDetails, "rmCode", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Branch Name"}
              required={true}
              type={"text"}
              onChange={inputHandler}
              name={"branchName"}
              inputValue={get(sourcingDetails, "branchName", "")}
            />
          </div>
        </div>
        <div className="row">
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={" Branch ID "}
              required={true}
              type={"text"}
              onChange={inputHandler}
              name={"branchId"}
              inputValue={get(sourcingDetails, "branchId", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <InputLabelAnimation
              labelText={"Promo Code"}
              type={"text"}
              onChange={inputHandler}
              name={"promoCode"}
              inputValue={get(sourcingDetails, "promoCode", "")}
            />
          </div>
          <div className={"col-md-4"}>
            <select
              className={"select-box1"}
              tabIndex={3}
              name={"existingCustomer"}
              id={"loan-repayment-type-select"}
              value={get(sourcingDetails, "existingCustomer", "")}
              onChange={inputHandler}
            >
              <option value="">Existing Customer</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className={"col-md-4"}>
            {sourcingDetails.existingCustomer === true || sourcingDetails.existingCustomer === "true" ? (
              <InputLabelAnimation
                labelText={"Customer ID"}
                required={true}
                type={"number"}
                onChange={inputHandler}
                name={"customerId"}
                inputValue={get(sourcingDetails, "customerId", "")}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default InHouseSales;
