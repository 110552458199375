import React from "react";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
import Highcharts from "highcharts";
require("highcharts/highcharts-more")(Highcharts);

const ReactTableApiConvert = (data) => {
  let keyValueArray = Object.entries(data);
  let returnArray = keyValueArray.map((obj) => {
    let mainKey = Object.keys(obj[1]).filter((el) => el === obj[0])[0];
    let subKeyArray = Object.keys(obj[1]).filter((el) => el !== obj[0]);
    let mainRow = { ...obj[1][mainKey], Figures: mainKey };
    let subRows = subKeyArray.map((subKey) => ({
      ...obj[1][subKey],
      Figures: subKey,
    }));
    let object = {
      Constituents: mainRow.constituents,
      Figures: mainRow.Figures,
      yearlyDetails: mainRow.yearlyDetails,
      tagging: mainRow.tagging,
      subRows: subRows.map((subRow) => ({
        Constituents: subRow.constituents,
        Figures: subRow.Figures,
        yearlyDetails: subRow.yearlyDetails,
        tagging: subRow.tagging,
      })),
    };
    return object;
  });
  return returnArray;
};

const backToReactTableConvert = (data) => {
  let keyValueArray = Object.entries(data);
  let returnedObject = keyValueArray.map((el, index) => {
    let mainHeading = el[0];
    let cardData = Object.keys(el[1])?.map((cardHeading) => {
      let cardObject = {
        cardHeading,
        yearlyRatios: Object.keys(el[1][cardHeading]?.yearlyRatios).map(
          (year) => {
            let yearLabel = year?.split("-")[1] ? ` ${year}  ` : " " + year;
            let yearObject = {
              year: yearLabel,
              value: el[1][cardHeading]?.yearlyRatios[year],
            };
            return yearObject;
          }
        ),
      };
      return cardObject;
    });
    return {
      mainHeading,
      cardData,
    };
  });
  return returnedObject;
};
const backToReactTableConvertArray = (data) => {
  let cardData = Object.keys(data).map((cardHeading) => {
    let tableRows = Object.keys(data[cardHeading]).map((tableTitle) => {
      let tableRowObject = {
        title: tableTitle,
        yearlyDetails: data[cardHeading][tableTitle].yearlyDetails,
        constituents: data[cardHeading][tableTitle].constituents,
        tagging: data[cardHeading][tableTitle].tagging,
      };
      return tableRowObject;
    });
    let obj = {
      cardHeading,
      tableRows,
    };
    return obj;
  });
  return cardData;
};
//ProfitAndLoss main-table data

const ProfitLossColumns = (data) => {
  let ProfitLossColumns = [];
  if (data[0]) {
    ProfitLossColumns = [
      {
        Header: "S.No",
        accessor: "tagging",
        minWidth: 50,
        Cell: ({ original }) => (
          <label style={{ color: " #181818" }}>{original.tagging}</label>
        ),
      },
      {
        Header: "Constituents",
        accessor: "Constituents",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #3670CC", textAlign: "left" }}>
            {original.Constituents}
          </div>
        ),
      },
      {
        Header: "Figures",
        accessor: "Figures",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #181818", textAlign: "left" }}>
            {original.Figures}
          </div>
        ),
      },
      ...Object.keys(data[0]?.yearlyDetails)?.map((year) => ({
        Header: `F.Y. ${year - 1} - ${year.slice(2, 4)}`,
        accessor: "2018",
        headerStyle: { textAlign: "right" },
        Cell: ({ original }) => {
          return (
            <div style={{ color: " #181818", textAlign: "right" }}>
              {rupeeCommaFormatter(original.yearlyDetails[year])}
            </div>
          );
        },
      })),

      {
        Header: "Trends",
        accessor: "Trends",
        Cell: ({ original }) => <div id={`linechart${original.Figures}`}></div>,
      },
      { Header: " ", accessor: "blank", expander: true },
    ];
  }
  return ProfitLossColumns;
};

const ProfitLossSubColumns = (data) => {
  let ProfitLossSubColumns = [];
  if (data[0]) {
    ProfitLossSubColumns = [
      {
        Header: "S.No",
        accessor: "tagging",

        minWidth: 50,

        Cell: ({ original }) => (
          <div style={{ color: " #181818" }}>{original.tagging}</div>
        ),
      },
      {
        Header: "Constituents",
        accessor: "Constituents",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #3670CC", textAlign: "left" }}>
            {original.Constituents}
          </div>
        ),
      },
      {
        Header: "Figures",
        accessor: "Figures",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: "#808080", textAlign: "left" }}>
            {original.Figures}
          </div>
        ),
      },
      ...Object.keys(data[0]?.yearlyDetails)?.map((year) => ({
        Header: `F.Y. ${year - 1} - ${year.slice(2, 4)}`,
        accessor: "2018",
        headerStyle: { textAlign: "right" },
        Cell: ({ original }) => {
          return (
            <div style={{ color: " #808080", textAlign: "right" }}>
              {rupeeCommaFormatter(original.yearlyDetails[year])}
            </div>
          );
        },
      })),

      { Header: "Trends", accessor: "Trends" },
      { Header: " ", accessor: "blank", expander: true },
    ];
  }
  return ProfitLossSubColumns;
};
const BalanceSheetColumns = (data) => {
  let BalanceSheetColumns = [];
  if (data[0]) {
    BalanceSheetColumns = [
      {
        Header: "S.no",
        accessor: "tagging",
        minWidth: 50,
        Cell: ({ original }) => (
          <label style={{ color: "#3670CC" }}>{original.tagging}</label>
        ),
      },
      {
        Header: "Formula ",
        accessor: "Constituents",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #3670CC", textAlign: "left" }}>
            {original.Constituents}
          </div>
        ),
      },
      {
        Header: "Title",
        accessor: "Figures",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div
            className="text-bold"
            style={{ color: "#001061", textAlign: "left" }}
          >
            {original.Figures}
          </div>
        ),
      },
      ...Object.keys(data[0]?.yearlyDetails)?.map((year) => ({
        Header: `F.Y. ${year - 1} - ${year.slice(2, 4)}`,
        accessor: "2018",
        headerStyle: { textAlign: "right" },
        Cell: ({ original }) => {
          return (
            <div style={{ color: " #181818", textAlign: "right" }}>
              {rupeeCommaFormatter(original.yearlyDetails[year])}
            </div>
          );
        },
      })),
      {
        Header: "Trends",
        accessor: "Trends",
        Cell: ({ original }) => <div id={`linechart${original.Figures}`}></div>,
      },
      {
        Header: " ",
        accessor: "blank",
        expander: true,
      },
    ];
  }

  return BalanceSheetColumns;
};
const BalanceSheetSubColumns = (data) => {
  let BalanceSheetSubColumns = [];
  if (data[0]) {
    BalanceSheetSubColumns = [
      {
        Header: "S.No",
        accessor: "tagging",
        minWidth: 50,
        Cell: ({ original }) => (
          <label style={{ color: " #3670CC" }}>{original.tagging}</label>
        ),
      },
      {
        Header: "Formula",
        accessor: "Constituents",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #3670CC", textAlign: "left" }}>
            {original.Constituents}
          </div>
        ),
      },
      {
        Header: "Title",
        accessor: " Figures",
        headerStyle: { textAlign: "left" },
        Cell: ({ original }) => (
          <div style={{ color: " #181818", textAlign: "left" }}>
            {original.Figures}
          </div>
        ),
      },
      ...Object.keys(data[0]?.yearlyDetails)?.map((year) => ({
        Header: `F.Y. ${year - 1} - ${year.slice(2, 4)}`,
        accessor: "2018",
        headerStyle: { textAlign: "right" },
        Cell: ({ original }) => {
          return (
            <div style={{ color: " #181818", textAlign: "right" }}>
              {rupeeCommaFormatter(original.yearlyDetails[year])}
            </div>
          );
        },
      })),
      { Header: "Trends", accessor: "Trends" },
      { Header: " ", accessor: "blank", expander: true },
    ];
  }
  return BalanceSheetSubColumns;
};

const AssesseeDetailsHeaders = (data) => {
  let AssesseeDetailsHeaders = [];
  if (data[0]) {
    AssesseeDetailsHeaders = [
      {
        Header: "S.No.",
        accessor: "tagging",
        maxWidth: 50,
        Cell: ({ original }) => <div>{original.tagging}</div>,
      },
      {
        Header: "Title",
        accessor: "title",
        headerStyle: { textAlign: "left" },
        minWidth: 420,
        maxWidth:1000,
        Cell: ({ original }) => (
          <div style={{ textAlign: "left", paddingLeft: "18px" }}>
            {original.title}
          </div>
        ),
      },
      ...Object.keys(data[0]?.yearlyDetails)?.map((year) => ({
        Header: `F.Y. ${year - 1} - ${year.slice(2, 4)}`,
        accessor: "2018",
        headerStyle: { textAlign: "right" },
        Cell: ({ original }) => {
          return (
            <div
              style={{
                color: " #181818",
                textAlign: "right",
                marginRight: "16px",
              }}
            >
              {rupeeCommaFormatter(original.yearlyDetails[year])}
            </div>
          );
        },
      })),
    ];
  }
  return AssesseeDetailsHeaders;
};

export {
  ProfitLossColumns,
  ProfitLossSubColumns,
  BalanceSheetColumns,
  BalanceSheetSubColumns,
  ReactTableApiConvert,
  AssesseeDetailsHeaders,
  backToReactTableConvert,
  backToReactTableConvertArray,
};
