import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import {
  API_GET_BUSINESS_COST_ANALYSIS,
  API_GET_BUSINESS_DETAILS,
} from "../../../../../utils/APIUrls";
import { GET } from "../../../../../utils/webAPI.service";

export default class SewingMachineBusinessCostAnalysis extends Component {
  state = {
    sewingMachineConstAnalysis: {},
  };
  componentDidMount = () => {
    let { match } = this.props;
    GET(API_GET_BUSINESS_DETAILS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.businessName) {
        let { businessName } = data.data;
        this.setState({ businessName });
      }
    });
    GET(API_GET_BUSINESS_COST_ANALYSIS(match.params.appNo)).then(({ data }) => {
      if (data?.data?.sewingMachineConstAnalysis) {
        let { sewingMachineConstAnalysis } = data.data;
        this.setState({ sewingMachineConstAnalysis });
      }
    });
  };
  render() {
    let { sewingMachineConstAnalysis, businessName } = this.state,
      {
        businessDetails,
        customerAndSupplyLinkage = {},
        manufacturingDetails = {},
        productionAndSalesNumbers = {},
      } = sewingMachineConstAnalysis,
      { otherWorks } = manufacturingDetails,
      { machineDetail } = productionAndSalesNumbers,
      { otherCustomers } = customerAndSupplyLinkage,
      { screenRenderHandler } = this.props;
    return (
      <div className={"bicycle-analysis-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sewing Machine Business Cost</title>
        </Helmet>
        <div className="container_top">
          <div
            className={"back-btn-link"}
            onClick={() => screenRenderHandler("overview-details")}
          >
            <Icon icon={angleLeft} size={24} /> Back to Overview
          </div>
          <h3 className="container_heading">
            {businessName} - Operational Details
          </h3>
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Machine and Hand tools manufactured
            </h4>
          </div>
          {manufacturingDetails?.domesticSewingMachineMinNetMargin ? (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">Domestic sewing Machine</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Sewing_Machine/Domestic sewing Machine.png`)}
                    alt="Domestic sewing Machine"
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>
                    {manufacturingDetails?.domesticSewingMachineMinNetMargin} %
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {manufacturingDetails?.industrialSewingMachineMinNetMargin ? (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">
                Industrial Sewing Machine
              </h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Sewing_Machine/Industrial Sewing Machine.png`)}
                    alt="Industrial Sewing Machine"
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>
                    {manufacturingDetails?.industrialSewingMachineMinNetMargin}{" "}
                    %
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {manufacturingDetails?.machinePartsMinNetMargin ? (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">Machine Parts</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  <img
                    src={require(`../../../../../assets/images/Sewing_Machine/Machine Parts.png`)}
                    alt="Machine Parts"
                    className="bicycle-part-image"
                  />
                </div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %
                  <div>{manufacturingDetails?.machinePartsMinNetMargin} %</div>
                </div>
              </div>
            </div>
          ) : null}
          {otherWorks?.map((work, index) => (
            <div className={"box-wrapper mt-20 w-50"}>
              <h4 className="box_sub_heading mt-0">{work.work}</h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}></div>
                <div className={"col-sm-5 bold"}>
                  Min Net Profit Margin %<div>{work?.minNetMargin} % </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={"box-wrapper"}>
          <div className="box-header">
            <h4 className="box-header_heading">Customer and Supply linkage</h4>
          </div>
          <h4 className="box_sub_heading">
            Percentage of sales directed towards:
          </h4>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Directly supply to Singer{" "}
              <div>
                {customerAndSupplyLinkage?.directSupplyToSinger || "NA"} %
              </div>
            </div>

            <div className={"col-sm-4 bold"}>
              Directly supply to BMS
              <div>{customerAndSupplyLinkage?.directSupplyToBMS || "NA"} %</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Directly supply to Usha{" "}
              <div>
                {customerAndSupplyLinkage?.directSupplyToUsha || "NA"} %
              </div>
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Supply to Vendors of Singer, Usha & other OEMs{" "}
              <div>
                {customerAndSupplyLinkage?.supplyToVendersOfSingerUshaAndOthers ||
                  "NA"}{" "}
                %
              </div>
            </div>
            <div className={"col-sm-4 bold"}>
              Replacement (Spares) Market
              <div>
                {customerAndSupplyLinkage?.replacementOrSpareMarket || "NA"} %
              </div>
            </div>

            {otherCustomers?.map((person, index) => (
              <div className={"col-sm-4 bold"}>
                {person.customer}
                <div>{person.salesPercentage || "NA"} %</div>
              </div>
            ))}
          </div>
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">
              Production level and Sales number
            </h4>
          </div>
          {machineDetail?.map((machine, index) => (
            <div className={"box-wrapper mt-20"}>
              <h4 className="box_sub_heading mt-0">{machine?.machineType} </h4>
              <div className={"row"}>
                <div className={"col-sm-4 bold"}>
                  Monthly sales / production
                  <div>{machine?.monthlyProducedOrSoldUnit} units</div>
                </div>
                <div className={"col-sm-4 bold"}>
                  Average sales price per unit
                  <div> ₹ {machine?.averageSalePerUnit}</div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Manufacturing Unit </h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Size of Manufacturing Unit (in sq. Yard)
              <div>
                {businessDetails?.sizeOfManufacturingUnit || "NA"} sq. Yard
              </div>
            </div>
          </div>
        </div>

        <div className={"box-wrapper "}>
          <div className="box-header">
            <h4 className="box-header_heading">Labour</h4>
          </div>
          <div className={"row"}>
            <div className={"col-sm-4 bold"}>
              Number of Skilled workers{" "}
              <div>{businessDetails?.numberOfSkilledWorkers || "NA"}</div>
            </div>
            <div className={"col-sm-4 bold"}>
              Number of helpers{" "}
              <div>{businessDetails?.numberOfHelpers || "NA"}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
