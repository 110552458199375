import { combineReducers } from "redux";
import { listData } from "./listData.reducers";
import { salesDashboard } from "../views/Sales/Dashboard/dashboard.reducers";
import { loanTenure } from "../views/Sales/LoanTenure/loanTenure.reducers";
import { applicantsReducers } from "../views/Sales/Applicants/applicants.reducers";
import { applicantGuideReducers } from "../views/Sales/ApplicantGuide/applicantGuide.reducers";
import { referencesReducers } from "../views/Sales/References/references.reducers";
import { bankingReducers } from "../views/Sales/Banking/banking.reducers";
import { cashFlow } from "../views/Credit/CashFlow/cashflow.reducer";
import { headerReducer } from "../components/Header/header.reducer";
import { dashBoardReducer } from "../views/Credit/Dashboard/dashboard.reducer";
import { overView } from "../views/Credit/Overview/overview.reducer";
import { businessRisk } from "../views/Credit/BusinessRisk/businessRisk.reducer";
import { creditBehaviour } from "../views/Credit/CreditBehaviour/creditBehaviour.reducers";
import { creditCapacity } from "../views/Credit/CreditCapacity/creditCapacity.reducers";
import { finalDecision } from "../views/Credit/FinalDecision/finalDecision.reducer";
import { flagCCScore } from "../views/Credit/Flag/flag.reducer";
import { documentsReducers } from "../views/Sales/Documents/Document.reducer";
import { CamResponse } from "../views/Credit/CAM/cam.reducers";
import { CPOUserReducer } from "../views/NewCpoPanel/newCpoPanel.reducers";
import { cpoAdminReducers } from "../views/CPOPanel/CPOPanel.reducer";
import { AdminResponse } from "../views/Admin/admin.reducer";
import { itrReducer } from "../views/Credit/ITR/Itr.reducer";
import { sourcingDetailsReducer } from "../views/Sales/SourcingDetails/sourcingDetails.reducers";
import { gstnAddressReducers } from "../views/Sales/GstnUpdatedAddress/GstnAddress.reducers";
import { onBoardingReducer } from "../views/Cluster-Sales/OnboardingDetails/onBoardingDetails.reducers";
import { clusterPreviewReducer } from "../views/Cluster-Sales/ClusterPreview/clusterPreview.reducers";
import { ClusterPhoto } from "../views/Cluster-Sales/Photo/Photo.reducers";
import {gstnDetailsReducers} from '../views/Sales/GSTNDetailsPage/GSTNDetails.reducers';
import {leadCreditBehaviour} from "../views/LeadManagement/CreditBehaviour/creditBehaviour.reducers";
import {leadManagerReducer} from "../views/LeadManagement/Overview/unitAnalysis.reducer"
import {cpoDocumentsReducers} from "../views/NewCpoPanel/Documents/Document.reducer"
const initialState = {
  isAuthenticated: false,
  isLogin: false,
  loaderModal: false,
  userDetails: {},
  redirectURL: "",
  errorMessage: "",
};

function data(state = initialState, action) {
  let { error } = action;
  switch (action.type) {
    case "SET_AUTHENTICATION_STATUS":
      return {
        ...state,
        isAuthenticated: action.val,
      };
    case "SET_USER_LOGIN_STATUS":
      return {
        ...state,
        isLogin: action.val,
      };
    case "SET_LOADER_MODAL_STATUS":
      return {
        ...state,
        loaderModal: action.val,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.val,
      };
    case "SET_REDIRECT_URL":
      return {
        ...state,
        redirectURL: action.val,
      };
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errorMessage: error,
      };

    default:
      return state;
  }
}

const reducers = combineReducers({
  data,
  listData,
  salesDashboard,
  loanTenure,
  gstnDetailsReducers,
  applicantsReducers,
  applicantGuideReducers,
  bankingReducers,
  referencesReducers,
  cashFlow,
  header: headerReducer,
  creditDashboard: dashBoardReducer,
  creditOverview: overView,
  creditBusinessRisk: businessRisk,
  creditBehaviour,
  creditCapacity,
  finalDecision,
  flagCCScore,
  CamResponse,
  documentsReducers,
  cpoAdminReducers,
  AdminResponse,
  CPOUserReducer,
  itrReducer,
  sourcingDetailsReducer,
  gstnAddressReducers,
  onBoardingReducer,
  clusterPreviewReducer,
  ClusterPhoto,
  leadManagerReducer,
  leadCreditBehaviour,
 cpoDocumentsReducers

});

export default reducers;
