import * as ACTION_CONSTANTS from "./finalDecision.action.constant";
import * as finalDecisionSelectors from "./finalDecision.selectors";

const initialState = {
  finalData: {},
  decisionSummary: [],
  decisionCode: [],
  documentType: [],
  agencyMaster: [],
  deviationReasonMaster: [],
  deviations: [],
  decision: {},
  spoc: {},
  capacityDetails: {},
  summaryForAllApplicant:[],
  caseHighlights:{},
  bankingDetails:{},
  limitUtilizationAnalysis:[],
  fiAgencyMaster:[],
  salesDocument:{}
};

export const finalDecision = (state = initialState, action) => {
  const {
    data,
    type,
    name,
    index,
    val,
    formIndex,
  } = action;
  switch (type) {
    case ACTION_CONSTANTS.FINAL_DETAILS:
      return { ...state, finalData: data };
    case ACTION_CONSTANTS.SET_DECISION_DETAILS:
      return { ...state, decisionSummary: data };
      case ACTION_CONSTANTS.GET_SUMMARY_APPLICANT:
      return { ...state, summaryForAllApplicant: data };
      case ACTION_CONSTANTS.GET_BANKING_DETAILS_FOR_FINAL_DECISION:
      return { ...state, bankingDetails: data };
      case ACTION_CONSTANTS.GET_CASE_HIGHLIGHTS:
      return { ...state, caseHighlights: data };
    case ACTION_CONSTANTS.GET_CAPACITY_DETAILS:
      return { ...state, capacityDetails: data };
    case ACTION_CONSTANTS.GET_DECISION_CODE:
      return { ...state, decisionCode: data };
    case ACTION_CONSTANTS.GET_RCU_DOCUMENT_TYPE:
      return { ...state, documentType: data };
    case ACTION_CONSTANTS.GET_RCU_AGENCY_MASTER:
      return { ...state, agencyMaster: data };
    case ACTION_CONSTANTS.GET_FI_AGENCY_MASTER:
      return { ...state, fiAgencyMaster: data };
    case ACTION_CONSTANTS.GET_DEVIATION_REASON_MASTER:
      return { ...state, deviationReasonMaster: data };
    case ACTION_CONSTANTS.GET_DEVIATIONS:
      return { ...state, deviations: data };
    case ACTION_CONSTANTS.GET_DECISION:
      return { ...state, decision: data };
    case ACTION_CONSTANTS.GET_SPOC:
      return { ...state, spoc: data };
      case ACTION_CONSTANTS.GET_DOCUMENT_LIST:
        return { ...state, salesDocument: data };
      case ACTION_CONSTANTS.GET_LIMIT_UTILIZATION_ANALYSIS:
        return { ...state, limitUtilizationAnalysis: data };
    case ACTION_CONSTANTS.CHANGE_ADDRESS_BOX_INPUT_VALUE:
      return finalDecisionSelectors._updateAddressFormValues(
        state,
        val,
        name,
        formIndex,
        index
      );
    case ACTION_CONSTANTS.CHANGE_VALUE:
      return finalDecisionSelectors._updateFormValues(state, data, name);

    default:
      return state;
  }
};
