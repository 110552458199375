import React, { Component } from "react";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { API_GET_GATING_STATUS, API_GET_CUSTOMER_DETAILS } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";

export default class GatingCheck extends Component {
  state = {
    custDetails: {},
    gatingStatus: "Pending",
  };
  colorCode = {
    "Auto Rejected": "#ED5A5A",
    "Auto Approved": "#00CDBE",
    Refer: "#E7B93C",
    Pending: "#969696",
  };
  addApplication = () => {
    let { history, match } = this.props;
    history.push(`/cluster/${match.params.custId}/business-cost/${match.params.clusterType}`);
  };
  render() {
    let { custDetails, gatingStatus } = this.state,
      { match } = this.props,
      gatingColor = this.colorCode[gatingStatus];
    return (
      <div className="gating-check__wrapper">
        <div className="container card-wrapper">
          <Link to={`/cluster/dashboard`} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Home
          </Link>
          <h1 className="card-wrapper__heading"> Gating Check </h1>
          <div className="card-wrapper">
            <h2 className="sub-heading">Business Details </h2>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Date</div>
                <div className="mt-1">{custDetails.businessCommencementDate || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Member Id</div>
                <div className="mt-1">{match.params.custId  || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">GST No.</div>
                <div className="mt-1">{custDetails.gstin  || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Business Name</div>
                <div className="mt-1">{custDetails.businessName  || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Promoter Name</div>
                <div className="mt-1">{custDetails.businessOwner  || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Mobile No.</div>
                <div className="mt-1">{custDetails.mobile  || "NA"}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="label-heading">Gating Check</div>
                <div className="gating-status" style={{ color: gatingColor, background: gatingColor?.concat("16") }}>
                  {gatingStatus}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="save-btn" onClick={this.addApplication} disabled={gatingStatus === "Auto Rejected"}>
              Save & Next
            </button>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount = () => {
    let { custId } = this.props.match.params;
    if (custId) {
      GET(API_GET_GATING_STATUS(custId))
        .then(({ data }) => {
          if (data) {
            this.setState({ gatingStatus: data.data });
          }
        })
        .catch((err) => {
          throw err;
        });
      GET(API_GET_CUSTOMER_DETAILS(custId))
        .then(({ data }) => {
          if (data.status === "success") {
            this.setState({ custDetails: data.data });
          }
        })
        .catch((err) => {
          throw err;
        });
    }
  };
}
