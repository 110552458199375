import React, { Component } from "react";
import ReactTable from "react-table";
import * as config from "./Dashboard.config";
import { POST } from "../../../utils/webAPI.service";
import { API_POST_FILTER_CUSTOMERS } from "../../../utils/APIUrls";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loaderModal, checkUserLogin } from "../../../store/actions";

class Applicants extends Component {
  state = {
    currentPage: 1,
    nextBtn: false,
    prevBtn: false,
    assignedData: [],
  };
  getPageData = (type) => {
    let { currentPage, totalPage } = this.state,
      count = currentPage;
    if (type === "next" && currentPage < totalPage) {
      this.setState(
        {
          currentPage: ++count,
        },
        () => {
          this.fetchDetails(this.state.currentPage, this.state.selectedStatus);
        }
      );
    } else if (type === "prev" && currentPage > 1) {
      this.setState(
        {
          currentPage: --count,
        },
        () => {
          this.fetchDetails(this.state.currentPage, this.state.selectedStatus);
        }
      );
    }
  };
  fetchDetails = async (pageNum) => {
    let { actions, userDetails } = this.props,
      { loaderModal } = actions;
    const dataToSend = {
      data: {
        pageNumber: pageNum,
        userId: userDetails.userId,
      },
    };
    loaderModal(true);
    await POST(API_POST_FILTER_CUSTOMERS, dataToSend)
      .then(({ data, status }) => {
        if (data.data) {
          this.setState({
            assignedData: data.data,
          });
        }
      })
      .catch((err) => {
        window.alert("Something went wrong please try again later.");
      });
    let { totalPage } = this.state.assignedData;
    this.setState({ totalPage });
    loaderModal(false);
  };
  rowClicked = (state, rowInfo) => {
    let { history } = this.props;
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          const subStatus = rowInfo.original.subStatus;
          let { memberId, clusterType } = rowInfo.original;
          clusterType = clusterType.split(" ").join("-");
          if (subStatus === "Start") {
            history.push(`${memberId}/onboarding`);
          } else if (subStatus === "Business Details") {
            history.push(`${memberId}/business-details/${clusterType}`);
          } else if (subStatus === "Business Cost") {
            history.push(`${memberId}/business-cost/${clusterType}`);
          } else if (subStatus === "Business Size") {
            history.push(`${memberId}/business-size/${clusterType}`);
          } else if (subStatus === "Documents") {
            history.push(`${memberId}/photo/${clusterType}`);
          } else if (subStatus === "Bank Details") {
            history.push(`${memberId}/business-account-details/${clusterType}`);
          } else if (subStatus === "Loan Application") {
            history.push(`${memberId}/business-cost/${clusterType}`);
          } else if (subStatus === "Submit") {
            history.push(`${memberId}/cluster-preview/${clusterType}`);
          } else {
            history.push(`${memberId}/onboarding`);
          }
        },
      };
    }
    return false;
  };
  render() {
    let { totalPage, currentPage, assignedData } = this.state;
    return (
      <div className=" container mt-3">
        <div className={"datatable-wrapper"}>
          <ReactTable
            columns={config.memberColumns}
            data={assignedData.customers || []}
            defaultPageSize={10}
            showPagination={false}
            resizable={false}
            minRows={0}
            getTrProps={this.rowClicked}
          />
          <ul className={"datatable-pagination"}>
            <li>
              <button
                disabled={!(currentPage > 1)}
                className={"prev-btn"}
                type={"button"}
                onClick={() => this.getPageData("prev")}
              >
                Previous
              </button>
            </li>
            <li>
              <span>
                Page No. {currentPage} of {totalPage}
              </span>
            </li>
            <li>
              <button
                disabled={!(totalPage > currentPage)}
                className={"next-btn"}
                type={"button"}
                onClick={() => this.getPageData("next")}
              >
                Next
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.props.actions.checkUserLogin().then(({ data }) => {
      if (data) {
        this.fetchDetails(1, "ALL");
      }
    });
  }
}
const mapStateToProps = ({ data }) => ({
  userDetails: data.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      checkUserLogin,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
