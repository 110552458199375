import * as GSTDetailsConstants from "./GSTNDetails.actions.constants";

const initialState = {
  panData: {},
  gstnDetails: [],
};

export const gstnDetailsReducers = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GSTDetailsConstants.RESET_REDUX_STATE:
      return { ...state, panData: {}, gstnDetails: [] };
    case GSTDetailsConstants.GET_PAN_DATA:
      return {
        ...state,
        panData: data,
      };
    case GSTDetailsConstants.GET_GST_DATA:
      return {
        ...state,
        gstnDetails: data,
      };
    default:
      return state;
  }
};
