import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_mode_edit } from "react-icons-kit/md/ic_mode_edit";
import { forward } from "react-icons-kit/entypo/forward";
import { check } from "react-icons-kit/fa/check";
import ReactTable from "react-table";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AssesseeDetailsHeaders } from "./ITR.config.js";
import { getAssesseeDetailsAndOtherAnalytics } from "./ITR.actions";
import "react-table/react-table.css";
import { Tabs, Tab } from "react-bootstrap";
import { get } from "lodash";
import { loaderModal } from "../../../store/actions";
class AssesseeDetails extends Component {
  async componentDidMount() {
    let { actions, match, applicationNumber } = this.props;
    applicationNumber = get(match, "params.appNo", "");
    actions.loaderModal(true);
    await actions.getAssesseeDetailsAndOtherAnalytics(applicationNumber);
    actions.loaderModal(false);
  }

  render() {
    let { assesseeDetailsData, otherIncomesData } = this.props;
    let key =
      assesseeDetailsData.assesseeDetails &&
      Object.keys(assesseeDetailsData.assesseeDetails)[0];
    let asseesseeName =
      assesseeDetailsData?.assesseeDetails?.[key]?.details?.Details
        ?.assesseeName;

    return (
      <Fragment>
        <div className="financial-analysis-ratio__wrapper assessee-details__wrapper">
          <div className="accounts-tabs">
            <div className="box-wrapper">
              <div className="trader-name">
                <label>{asseesseeName}</label>
                <div className="icons">
                  <Icon
                    className="icon-styles set-cursor-pointer"
                    icon={forward}
                    size={22}
                  />
                  <Icon
                    className="icon-styles set-cursor-pointer"
                    icon={ic_mode_edit}
                    size={22}
                  />
                </div>
              </div>
              <Tabs defaultActiveKey={this.tabs} onSelect={this.handleSelect}>
                {assesseeDetailsData?.assesseeDetails &&
                  Object.keys(assesseeDetailsData.assesseeDetails).map(
                    (el, index) => {
                      let Data = assesseeDetailsData?.assesseeDetails[el];
                      let cardData = Object.values(Data.details);
                      let GstInData = Object.values(Data)[1];
                      let BankData = Object.values(Data)[2];

                      return (
                        <Tab
                          key={index}
                          eventKey={Object.keys(el[1])}
                          title={<div className="tabs-heading">{el}</div>}
                        >
                          <div className="proofs__cards">
                            <div className="details__card">
                              <div className="head">
                                <div className="heading">Details</div>
                                <div className="verified">
                                  <Icon
                                    className="icon-styles"
                                    icon={check}
                                    size={18}
                                  />
                                  <span>Verified</span>
                                </div>
                              </div>
                              <div className="body">
                                <div className="item">
                                  <div className="title">Assessee Name</div>
                                  <div className="value" id="assessee">
                                    {cardData[0].assesseeName}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="title">
                                    Legal name of entity
                                  </div>
                                  <div className="value">
                                    {cardData[0].legalNameOfEntity}
                                  </div>
                                </div>
                                <div className="item">
                                  <div className="title">Business PAN</div>
                                  <div className="value">
                                    {cardData[0].businessPan}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {GstInData ? (
                              <div className="details__card">
                                <div className="head">
                                  <div className="heading">GST</div>
                                  <div className="verified">
                                    <Icon
                                      className="icon-styles"
                                      icon={check}
                                      size={18}
                                    />
                                    <span>Verified</span>
                                  </div>
                                </div>
                                <div className="body">
                                  {GstInData &&
                                    Object.values(GstInData).map((item, i) => {
                                      let GSTs = Object.values(item);
                                      return (
                                        <div className="item" key={i}>
                                          <div className="title">
                                            {"GST" + (i + 1)}
                                          </div>
                                          <div className="value">{GSTs}</div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="proofs__cards">
                            {Object.values(BankData).map((item, i) => {
                              return (
                                <div className="details__card" key={i}>
                                  <div className="head">
                                    <div className="heading">
                                      {"Bank " + (i + 1)}
                                    </div>
                                    <div className="verified">
                                      <Icon
                                        className="icon-styles"
                                        icon={check}
                                        size={18}
                                      />
                                      <span>Verified</span>
                                    </div>
                                  </div>
                                  <div className="body">
                                    <div className="item">
                                      <div className="title">Name</div>
                                      <div className="value">
                                        {item.bankName}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="title">
                                        Account number
                                      </div>
                                      <div className="value">
                                        {item.bankAccountNo}
                                      </div>
                                    </div>
                                    <div className="item">
                                      <div className="title">IFSC Code</div>
                                      <div className="value">
                                        {item.ifscCode}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Tab>
                      );
                    }
                  )}
              </Tabs>
              {otherIncomesData &&
                otherIncomesData.map((item, index) => {
                  return (
                    <div className="box-wrapper" key={index}>
                      <div className="head">
                        <div className="heading">{item.cardHeading}</div>
                        <div className="verified">
                          <Icon
                            className="icon-styles"
                            icon={check}
                            size={18}
                          />
                          <span>Verified</span>
                        </div>
                      </div>
                      <div className="body">
                        <ReactTable
                          data={item.tableRows}
                          columns={
                            item.tableRows
                              ? AssesseeDetailsHeaders(item.tableRows)
                              : []
                          }
                          showPagination={false}
                          resizable={false}
                          minRows={0}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = ({ itrReducer }) => ({
  assesseeDetailsData: itrReducer.assesseeDetailsData,
  otherIncomesData: itrReducer.otherIncomesData,
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAssesseeDetailsAndOtherAnalytics,
      loaderModal,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssesseeDetails);
