import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { some, get } from "lodash";
import {
  integerToWordFormatterLabels,
  integerToWordFormatter,
} from "../../../utils/utitlity";
import { ContentLoader } from "../../../components";
import Highcharts from "highcharts";
import {
  getBusinessDetails,
  getProfileDetail,
  getFormFilledBy,
  getTurnOverDetails,
  getCluster,
} from "./overview.action";
import { loaderModal, checkUserLogin } from "../../../store/actions";
import ApplicantCard from "./ApplicantCard";
import ApplicationModal from "./ApplicantModal";
import { Helmet } from "react-helmet";
import { deleteCookie } from "../../../utils/utitlity";
import { setUserDetails, setUserLoginStatus } from "../../../store/actions";
class OverviewDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      applicantPan: null,
    };
    this.integerToWordFormatter = this.integerToWordFormatter.bind(this);
  }

  toggleModal = (status, pan) => {
    this.setState({
      modalVisible: status,
      applicantPan: pan,
    });
  };

  fetchDetails = async () => {
    let { actions, history, match } = this.props;
    let {
      getProfileDetail,
      getBusinessDetails,
      getFormFilledBy,
      loaderModal,
    } = actions;
    let applicationNumber = get(match, "params.appNo", "");
    if (applicationNumber) {
      loaderModal(true);
      getFormFilledBy(applicationNumber);
      getBusinessDetails(applicationNumber);
      this.turnoverChart(applicationNumber);
      await getProfileDetail(applicationNumber);
      loaderModal(false);
    } else {
      history.push("/Credit/dashboard");
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    let {
      setUserDetails,
      setUserLoginStatus,
      checkUserLogin,
    } = this.props.actions;
    checkUserLogin().then(({ status, data }) => {
      if (status === "failure") {
        let { history } = this.props;
        alert("Session Expired");
        deleteCookie("token");
        setUserLoginStatus(false);
        setUserDetails({});
        history.push("/");
      }
    });
  }

  componentDidMount() {
    const { actions, match } = this.props;
    this.fetchDetails();
    actions
      .getCluster(match.params.appNo)
      .then(({ data }) => {
        if (data && data.status === "success") {
          if (data) {
            this.setState({ clusterName: data });
          }
        } else if (data && data.status === "failure") {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  }
  integerToWordFormatter = (value) => {
    let intValue = value.toString;
    if (intValue.length >= 3) {
      return intValue.slice(0, 1) + "K";
    } else if (intValue.length >= 5) {
      return (intValue / 10000).slice(0, 4) + "Lakh";
    } else {
      return (intValue / 1000000).slice(0, 4) + "Crore";
    }
  };
  turnoverChart = async (appNo) => {
    await this.props.actions.getTurnOverDetails(appNo);
    const { turnoverDetails } = this.props;
    Highcharts.chart("turnover-chart", {
      chart: {
        type: "bar",
        height: 140,
        backgroundColor: "transparent",
      },
      title: false,
      credits: {
        enabled: false,
      },
      xAxis: {
        categories: ["Bank Statement", "GST"],
      },
      yAxis: {
        title: false,
        labels: {
          formatter: function () {
            return integerToWordFormatterLabels(this.value);
          },
        },
      },
      tooltip: {
        formatter: function () {
          return (
            "<p>" + this.x + "<br/>" + integerToWordFormatter(this.y) + "</p>"
          );
        },
      },
      legend: false,
      series: [
        {
          data: [
            turnoverDetails && turnoverDetails.totalBankingTurnover,
            turnoverDetails && turnoverDetails.imputedGSTTurnover,
          ],
          color: "#4687F4",
        },
      ],
    });
  };

  render() {
    let { modalVisible, applicantPan } = this.state;
    const {
      businessDetails,
      profileDetail,
      formFilledBy,
      screenRenderHandler,
      clusterName,
    } = this.props;

    return (
      <div className={"overviewDetails-wrapper container"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Overview</title>
        </Helmet>
        {modalVisible ? (
          <ApplicationModal
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
            panNumber={applicantPan}
            applicationNumber={profileDetail.applicationNumber}
          />
        ) : null}
        <div className={"row"}>
          <div className={"col-md-8"}>
            <div className={"content-wrapper"}>
              <h4>Entity</h4>
              <div className={"row"}>
                <ContentLoader
                  width={200}
                  height={10}
                  margin={"30px auto"}
                  renderContent={businessDetails !== null}
                >
                  {businessDetails ? (
                    <Fragment>
                      <div className={"col-md-9"}>
                        <h2>{businessDetails.businessName}</h2>
                        <span>
                          {businessDetails.businessConstitutionType}{" "}
                          {businessDetails.businessAge}
                        </span>
                        <div className={"row content-box"}>
                          {/* <div className={"col-md-4 content"}>
                            <label>Office</label>
                            <span>
                              <address className={"m-0"}>
                                {businessDetails.address}
                              </address>
                            </span>
                          </div> */}
                          <div className={"col-md-4 content"}>
                            <label>Primary Industry</label>
                            <span>{businessDetails.primaryBusinessNature}</span>
                          </div>
                          <div className={"col-md-4 content"}>
                            <label>Secondary Industry</label>
                            <span>
                              {businessDetails.secondryBusinessNature}
                            </span>
                          </div>
                          {/* <div className={"col-md-4 content"}>
                            <label>GSTIN</label>
                            <span>{businessDetails.gstin}</span>
                          </div> */}
                          <div className={"col-md-4 content"}>
                            <label>Business PAN </label>
                            <span>{businessDetails.pan}</span>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <span />
                  )}
                </ContentLoader>
              </div>
            </div>
          </div>
          <div className={"col-md-4"}>
            <div className={"content-wrapper"}>
              <h4>Turnover Details</h4>
              <div className={"chart-box"} id={"turnover-chart"} />
            </div>
          </div>
          <div className="col-md-8">
            <ApplicantCard
              profileDetail={profileDetail}
              toggleModal={this.toggleModal}
            />
          </div>
          <div className={"col-md-4"}>
            <div className={"content-wrapper"}>
              <h4>Form Filled By</h4>
              <h3>
                {get(formFilledBy, "firstName", "")}{" "}
                {get(formFilledBy, "middleName", "")}{" "}
                {get(formFilledBy, "lastName", "")}{" "}
              </h3>
              <div className={"row content-box"}>
                <div className={"col-xs-12 content"}>
                  {/* <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Address</label>
                    </div>
                    <span className={"col-md-8"}>
                      <address className={"m-0"}>
                        {get(formFilledBy, "address", "")}
                      </address>
                    </span>
                  </div> */}
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Phone</label>
                    </div>
                    <div className={"col-md-8"}>
                      <span>{get(formFilledBy, "mobile", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-4"}>
                      <label>Email</label>
                    </div>
                    <div className={"col-md-8"}>
                      <span>{get(formFilledBy, "email", "")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {clusterName ? (
              <div className={"content-wrapper"}>
                <h4>Unit Analysis</h4>
                <label>Cluster</label>
                <span>{clusterName}</span>
                <button
                  onClick={() => screenRenderHandler("analysis-a")}
                  className="details-button"
                >
                  View Details
                </button>
              </div>
            ) : null}
            {clusterName ? (
              <div className={"content-wrapper"}>
                <h4>Operational Details</h4>
                <label>Cluster</label>
                <span>{clusterName}</span>
                <button
                  onClick={() => screenRenderHandler(clusterName)}
                  className="details-button"
                >
                  View Details
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ creditOverview }) => ({
  loader: some(creditOverview.loader),
  businessDetails: creditOverview.businessDetails,
  profileDetail: creditOverview.profileDetail,
  formFilledBy: creditOverview.formFilledBy,
  turnoverDetails: creditOverview.turnoverDetails,
  clusterName: creditOverview.clusterName,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBusinessDetails,
      getProfileDetail,
      getFormFilledBy,
      loaderModal,
      getTurnOverDetails,
      checkUserLogin,
      setUserDetails,
      setUserLoginStatus,
      getCluster,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewDetails);
