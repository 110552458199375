const bicyclePartsArray = [
  {
    label: "Brakes and Pedal",
    name: "brakesAndPedal",
  },
  {
    label: "Chain, Wheelsets & Gear Set",
    name: "chainWheelsAndGearSet",
  },
  {
    label: "Complete Bicycle Assembly",
    name: "completeBicycleAssembly",
  },
  {
    label: "Fasteners",
    name: "fasteners",
  },
  {
    label: "Frame, Front Fork, Fork Fittings",
    name: "frameAndFork",
  },
  {
    label: "Handle Bar and Cranksets",
    name: "handleBar",
  },
  {
    label: "HUB",
    name: "hub",
  },
  {
    label: "Mudguard, Saddle & Accessories",
    name: "mudguardSaddleAccessories",
  },
  {
    label: "Rim",
    name: "rim",
  },
  {
    label: "Spokes and Axles",
    name: "spokesAndAxles",
  },
  {
    label: "Tyre and Tubes",
    name: "tyreAndTubes",
  },
];

const hosieryPartsArray = [
  {
    label: "Yarn Manufacturers",
    name: "yarnManufacturers",
  },
  {
    label: "Dyers and Spinners",
    name: "dyresAndSpinners",
  },
  {
    label: "Job Work- Stitching & Embroidery",
    name: "jobWork",
  },
  {
    label: "Processing Chemicals",
    name: "processingChemicals",
  },
  {
    label: "Accessories and Printing",
    name: "accessoriesAndPrinting",
  },
  {
    label: "Equipment & Machinery",
    name: "equipmentAndMachinery",
  },
];

const hosieryTopPartsArray = [
  {
    label: "Knitting  Woollen Garments",
    name: "knittingWoolenGarments",
    gender: ["Men", "Women", "kids"],
  },
  {
    label: "Readymade Garments",
    name: "readymadeGarments",
    gender: ["Men", "Women", "kids"],
  },
];

const sewingPartsArray = [
  {
    label: "Domestic sewing Machine",
    name: "domesticSewingMachineMinNetMargin",
  },
  {
    label: "Industrial Sewing Machine",
    name: "industrialSewingMachineMinNetMargin",
  },
  {
    label: "Machine Parts",
    name: "machinePartsMinNetMargin",
  },
];
export { bicyclePartsArray, hosieryPartsArray, sewingPartsArray, hosieryTopPartsArray };
