import React, { Component } from "react";
import { CheckBox } from "../../../components";

class EscalationGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkboxStatus: false,
    };
  }

  setCheckboxStatus = () => {
    let { checkboxStatus } = this.state;
    this.setState({
      checkboxStatus: !checkboxStatus,
    });
  };

  componentWillMount() {
    window.scroll(0, 0);
  }

  render() {
    let { checkboxStatus ,Level0 , Level2 , Level3 ,Level1 } = this.state;
    return (
      <div className={"content-box"}>
        <div className={"box-header"}>
          <h4>Define Escalation Grid</h4>
        </div>
        <div className={"box-content"}>
          <table>
            <thead>
              <tr>
                <th>Level 3</th>
                <th>Level 2</th>
                <th>Level 1</th>
                <th>Level 0</th>
              </tr>
            </thead>
          </table>
          <table>
            <thead>
              <tr>
                <th>Credit - Approver L3</th>
                <th>Credit - Approver L2</th>
                <th>Credit - Approver L1</th>
                <th>Credit - Approver L0</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                    {Level0?.map((el, index) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                  {/* <CheckBox
                    labelColor={"#1d1d1d"}
                    checkboxColor={"#36ddd1"}
                    name={"declarations"}
                    labelClass={"input-label"}
                    htmlFor={"user1"}
                    label={"Pankaj Khurana"}
                    checked={checkboxStatus}
                    onChange={this.setCheckboxStatus}
                  /> */}
                </td>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                    {Level1?.map((el, index) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                    {Level2?.map((el, index) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </td>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                    {Level3?.map((el, index) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </td>
              </tr>
              {/* <tr>
                <td />
                <td>
                  <CheckBox
                    labelColor={"#1d1d1d"}
                    checkboxColor={"#36ddd1"}
                    name={"declarations"}
                    labelClass={"input-label"}
                    htmlFor={"user1"}
                    label={"Rahul Sharma"}
                    checked={checkboxStatus}
                    onChange={this.setCheckboxStatus}
                  />
                </td>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                  </select>
                </td>
                <td />
              </tr> */}
              {/* <tr>
                <td />
                <td />
                <td>
                  <CheckBox
                    labelColor={"#1d1d1d"}
                    checkboxColor={"#36ddd1"}
                    name={"declarations"}
                    labelClass={"input-label"}
                    htmlFor={"user1"}
                    label={"Aditi Mittal"}
                    checked={checkboxStatus}
                    onChange={this.setCheckboxStatus}
                  />
                </td>
                <td>
                  <select className={"select-box"}>
                    <option value={"reportee"}>Reportee</option>
                  </select>
                </td>
              </tr> */}
              {/* <tr>
                <td />
                <td />
                <td />
                <td>
                  <CheckBox
                    labelColor={"#1d1d1d"}
                    checkboxColor={"#36ddd1"}
                    name={"declarations"}
                    labelClass={"input-label"}
                    htmlFor={"user1"}
                    label={"Raman Khurana"}
                    checked={checkboxStatus}
                    onChange={this.setCheckboxStatus}
                  />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  componentDidMount() {
    ["Level-0", "Level-1", "Level-2", "Level-3"].map((el, index) => {
      this.props.actions.getUserByLevel(el).then(({ data }) => {
        this.setState({ [el.split("-").join("")]: data });
      });
      return null;
    });
  }
}

export default EscalationGrid;
