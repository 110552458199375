import * as ApplicantsConstants from './applicants.actions.constant';
import * as ApplicantsSelectors from "./applicants.selectors";

const initialState = {
    profileDetails: []
};

export const applicantsReducers = (state = initialState, action) => {
    const {data, type, val, name, index} = action;
    switch (type) {
        case ApplicantsConstants.SET_APPLICANTS_PROFILES:
            return {...state, profileDetails: data};
        case ApplicantsConstants.RESET_REDUX_STATE:
            return {...state, profileDetails: []};
        case ApplicantsConstants.CHANGE_VALUE:
            return ApplicantsSelectors._updateFormValues(state, val, name, index);
        default:
            return state;
    }
};
