import * as OnBoardingDetailsConstants from "./onBoardingDetails.actions.constant";

const initialState = {
  clusterStateList: [],
  clusterByStateList: [],
  taxpayerData: {},
  customerDetails: {},
};

export const onBoardingReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case OnBoardingDetailsConstants.RESET_REDUX_STATE:
      return { ...state, customerDetails: {}, taxpayerData: {} };
    case OnBoardingDetailsConstants.FETCH_CUSTOMER_DETAILS:
      return { ...state, customerDetails: data };
    case OnBoardingDetailsConstants.FETCH_TAXPAYER_DATA:
      return { ...state, taxpayerData: data };
    case OnBoardingDetailsConstants.FETCH_CLUSTER_STATES:
      return { ...state, clusterStateList: data };
    case OnBoardingDetailsConstants.FETCH_CLUSTER_BY_STATE:
      return { ...state, clusterByStateList: data };
    default:
      return state;
  }
};
