import * as ACTION_CONSTANTS from "./overview.action.constant";
import { GET } from "../../../utils/webAPI.service";
import {
  API_FETCH_ID_VERIFICATION_REPORT,
  API_GET_BUSINESS_DETAILS,
  API_GET_PROFILE_DETAIL,
  API_FETCH_FORM_FILLED_BY,
  API_FETCH_TURNOVER_DETAILS,
  API_GET_BUSINESS,
  API_GET_CLUSTER,
} from "../../../utils/APIUrls";

const getBusinessDetails = (applicationNumber = 190600000001) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS_DETAILS_START });
  const API_URL = API_GET_BUSINESS_DETAILS(applicationNumber);
  try {
    const response = await GET(API_URL);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS_DETAILS, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS_DETAILS_FINISHED });
  }
};

const getProfileDetail = (applicationNumber = 190600000001) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.GET_PROFILE_DETAIL_START });
  const API_URL = API_GET_PROFILE_DETAIL(applicationNumber);
  try {
    const response = await GET(API_URL);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({ type: ACTION_CONSTANTS.GET_PROFILE_DETAIL, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.GET_PROFILE_DETAIL_FINISHED });
  }
};

const getIdVerificationReport = (panNumber, applicationNumber) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT_START });
  const API_URL = API_FETCH_ID_VERIFICATION_REPORT(panNumber, applicationNumber);
  try {
    const response = await GET(API_URL);
    dispatch({ type: ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT_FINISHED });
  }
};

const getFormFilledBy = (applicationNumber = 190600000001) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.FETCH_FORM_FILLED_BY_START });
  const API_URL = API_FETCH_FORM_FILLED_BY(applicationNumber);
  try {
    const response = await GET(API_URL);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({ type: ACTION_CONSTANTS.FETCH_FORM_FILLED_BY, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.FETCH_FORM_FILLED_BY_FINISHED });
  }
};

const getTurnOverDetails = (applicationNumber) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS_START });
  const API_URL = API_FETCH_TURNOVER_DETAILS(applicationNumber);
  try {
    const response = await GET(API_URL);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({ type: ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS_FINISHED });
  }
};

const getBusiness = (applicationNumber) => async (dispatch) => {
  dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS_START });
  const API_URL = API_GET_BUSINESS(applicationNumber);
  try {
    const response = await GET(API_URL);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS, data: response.data.data });
    return response.data.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({ type: ACTION_CONSTANTS.GET_BUSINESS_FINISHED });
  }
};

const getCluster = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_GET_CLUSTER(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.FETCH_CLUSTER_NAME,
            data: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

export {
  getBusinessDetails,
  getProfileDetail,
  getIdVerificationReport,
  getFormFilledBy,
  getTurnOverDetails,
  getBusiness,
  getCluster,
};
