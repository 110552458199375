import React, {Component} from 'react';
import {Header} from '../../components';
import {Icon} from 'react-icons-kit';
import {ic_phone} from 'react-icons-kit/md/ic_phone';
import {ic_mail} from 'react-icons-kit/md/ic_mail'

class Help extends Component {
    listrender = list => {
        return list.map((item, index) => {
            return (
                <li key={index}><span>{index + 1}.</span><span>{item}</span></li>
            )
        });
    };

    render() {
        const docList = ['GST Number/numbers for entity. Details of all GST numbers are required.', 'GSTN Username & OTP (Please ensure that user has enabled GSTN API access and has access to GSTN OTP).', 'Bank account statements in PDF format for past 12 months.', 'Passwords for bank account statements.', 'PAN number of Entity in case GST number is not available.', 'PAN number of all the applicants.', 'Aadhaar number of all the applicants.', 'Biographic details of all the applicants.'],
            faqList = ['How does CredoChain work?', 'How do you calculate the loan amount I’m eligible for?', 'What information does CredoChain analyse?', 'Who is CredoChain for?'];
        return (
            <div>
                {/*<Helmet>*/}
                    {/*<title>Credochain | Help</title>*/}
                {/*</Helmet>*/}
                <Header {...this.props}/>
                <div className={'help-wrapper'}>
                    <div className={'container'}>
                        <div className={'content-box'}>
                            <div className={'row'}>
                                <div className={'col-xs-12'}>
                                    <div className={'box support-box'}>
                                        <label className={'box-label'}>Support</label>
                                        <div className={'box-content'}>
                                            <ul className={'list-unstyled'}>
                                                <li><span><Icon icon={ic_phone}
                                                                size={22}/></span><span>91 11-41716192</span></li>
                                                <li><span><Icon icon={ic_mail}
                                                                size={22}/></span><span>support@credochain.com</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className={'box doc-box'}>
                                        <label className={'box-label'}>Please keep following documents handy</label>
                                        <div className={'box-content'}>
                                            <ul className={'list-unstyled'}>
                                                {
                                                    this.listrender(docList)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className={'box faq-box'}>
                                        <label className={'box-label'}>FAQs</label>
                                        <div className={'box-content'}>
                                            <ul className={'list-unstyled'}>
                                                {
                                                    this.listrender(faqList)
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Help;
