import {
  API_GET_CAPACITY_FLAGS,
  API_GET_BUSINESS_PROFILE_FLAGS,
  API_GET_BUSINESS_RISK_FLAGS,
  API_GET_CASH_FLOW_RISK_FLAGS,
  API_GET_CREDIT_BEHAVIOUR_FLAGS,
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as ACTION_CONSTANTS from "./flag.action.constant";

const getCreditFlag = applicationNumber => dispatch => {
  return GET(API_GET_CAPACITY_FLAGS(applicationNumber))
    .then(({ data }) => {
      if (data.status === "success") {
        dispatch({ type: `${ACTION_CONSTANTS.CREDIT_FLAG}`, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const businessProfileFlags = applicationNumber => dispatch => {
  return GET(API_GET_BUSINESS_PROFILE_FLAGS(applicationNumber))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({
          type: ACTION_CONSTANTS.BUSINESS_PROFILE_FLAGS,
          businessProfileFlagsData: data.data
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const businessRiskFlags = applicationNumber => dispatch => {
  return GET(API_GET_BUSINESS_RISK_FLAGS(applicationNumber))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({
          type: ACTION_CONSTANTS.BUSINESS_RISK_FLAGS,
          businessRiskFlagsData: data.data
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const cashFlowRisk = applicationNumber => dispatch => {
  return GET(API_GET_CASH_FLOW_RISK_FLAGS(applicationNumber))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({
          type: ACTION_CONSTANTS.CASH_FLOW_RISK_FLAGS,
          cashFlowRiskFlagsData: data.data
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};

const creditBehaviourFlags = applicationNumber => dispatch => {
  return GET(API_GET_CREDIT_BEHAVIOUR_FLAGS(applicationNumber))
    .then(({ data, status }) => {
      if (data.status === "success") {
        dispatch({
          type: ACTION_CONSTANTS.CREDIT_BEHAVIOUR_FLAGS,
          creditBehaviourFlagsData: data.data
        });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
export {
  getCreditFlag,
  businessProfileFlags,
  businessRiskFlags,
  cashFlowRisk,
  creditBehaviourFlags,
};
