import React, { Component } from "react";
import PropTypes from "prop-types";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { titleCase, rupeeCommaFormatter } from "../../utils/utitlity";
import Icon from "react-icons-kit";
import { rupee } from "react-icons-kit/fa/rupee";

class FunnelChart extends Component {
  render() {
    let { color, chartFor, title, data, circularData, total } = this.props;

    return (
      <div className={`funnel-chart-box ${chartFor}-chart`}>
        <h5 style={{ color }}>
          {title}
          <div className="mt-3" style={{ color: "#1D1D1D" }}>
            {chartFor !== "product" ? (
              <span>
                <Icon icon={rupee} size={16} />
                {rupeeCommaFormatter(total)}
              </span>
            ) : (
              <div style={{marginTop:"40px"}}></div>
            )}
          </div>
        </h5>
        <div className={"text-center"}>
          <CircularProgressbar
            percentage={circularData}
            text={`${circularData}%`}
            className={"circular-progressbar"}
            strokeWidth={3}
            styles={{
              path: { stroke: color },
              text: {
                fill: color,
                fontFamily: "Helvetica Bold",
                fontSize: "26px"
              }
            }}
          />
        </div>
        <div className={"funnel-chart"}>
          <ul className={"list-unstyled"}>
            {data.length ? (
              chartFor !== "product" ? (
                data.map((item, key) => {
                  return (
                    <li key={key}>
                      <label title={item.category}>
                        {titleCase(item.category)}
                      </label>
                      <span>
                        <span
                          style={{
                            width: item.value * 1,
                            height: "20px",
                            marginRight: "3px"
                          }}
                        >
                          {""}
                        </span>
                        <div style={{ display: "inline-block" }}>
                          {item.value.toFixed(2)}%
                        </div>
                      </span>
                    </li>
                  );
                })
              ) : (
                data.map((item, key) => {
                  return (
                    <li key={key}>
                      <label title={item.ccMapping ? item.ccMapping : ""}>
                        {item.hsn_sc}
                      </label>
                      <span>
                        <span
                          style={{
                            width: item.txval * 1,
                            height: "20px",
                            marginRight: "3px"
                          }}
                        ></span>
                        <div style={{ display: "inline-block" }}>
                          {item.txval.toFixed(2)}%
                        </div>
                      </span>
                    </li>
                  );
                })
              )
            ) : (
              <li style={{ textAlign: "center" }}>No Data Found</li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

FunnelChart.propTypes = {
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  chartFor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

FunnelChart.defaultProps = {
  percentage: 0,
  color: "",
  chartFor: "",
  title: ""
};

export default FunnelChart;
