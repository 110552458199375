import * as ACTION_CONSTANTS from './overview.action.constant';

const initialState = {
    loader: {
        businessDetails: false,
        profileDetail: false,
        fetchIdVerification: false,
        formFilledBy: false,
        turnoverDetails: false,
        businessDet: false
    },
    businessDetails: null,
    profileDetail: null,
    idVerificationReport: null,
    formFilledBy: null,
    turnoverDetails: null,
    businessDet: null
};

export const overView = (state = initialState, action) => {
    const { data, type } = action;
    switch (type) {
        case ACTION_CONSTANTS.GET_BUSINESS_DETAILS_START:
            return { ...state, loader: { ...state.loader, businessDetails: true }, businessDetails: null };
        case ACTION_CONSTANTS.GET_BUSINESS_DETAILS:
            return { ...state, businessDetails: data };
        case ACTION_CONSTANTS.GET_BUSINESS_DETAILS_FINISHED:
            return { ...state, loader: { ...state.loader, businessDetails: false } };

        case ACTION_CONSTANTS.GET_PROFILE_DETAIL_START:
            return { ...state, loader: { ...state.loader, profileDetail: true }, profileDetail: null };
        case ACTION_CONSTANTS.GET_PROFILE_DETAIL:
            return { ...state, profileDetail: data };
        case ACTION_CONSTANTS.GET_PROFILE_DETAIL_FINISHED:
            return { ...state, loader: { ...state.loader, profileDetail: false } };

        case ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT_START:
            return { ...state, loader: { ...state.loader, fetchIdVerification: true }, idVerificationReport: null };
        case ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT:
            return { ...state, idVerificationReport: data };
        case ACTION_CONSTANTS.FETCH_ID_VERIFICATION_REPORT_FINISHED:
            return { ...state, loader: { ...state.loader, fetchIdVerification: false } };

        case ACTION_CONSTANTS.FETCH_FORM_FILLED_BY_START:
            return { ...state, loader: { ...state.loader, formFilledBy: true }, formFilledBy: null };
        case ACTION_CONSTANTS.FETCH_FORM_FILLED_BY:
            return { ...state, formFilledBy: data };
        case ACTION_CONSTANTS.FETCH_FORM_FILLED_BY_FINISHED:
            return { ...state, loader: { ...state.loader, formFilledBy: false } };

        case ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS_START:
            return { ...state, loader: { ...state.loader, turnoverDetails: true }, turnoverDetails: null };
        case ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS:
            return { ...state, turnoverDetails: data };
        case ACTION_CONSTANTS.FETCH_TURNOVER_DETAILS_FINISHED:
            return { ...state, loader: { ...state.loader, turnoverDetails: false } };
            
        case ACTION_CONSTANTS.GET_BUSINESS_START:
            return { ...state, loader: { ...state.loader, business: true }, businessDet: null };
        case ACTION_CONSTANTS.GET_BUSINESS:
            return { ...state, businessDet: data };
        case ACTION_CONSTANTS.GET_BUSINESS_FINISHED:
            return { ...state, loader: { ...state.loader, businessDet: false } };
            case ACTION_CONSTANTS.FETCH_CLUSTER_NAME:
            return { ...state, clusterName: data };


        default:
            return state;
    }
};