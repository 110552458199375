const GET_CAM_DATA = Symbol("getCamData"),
  SET_CREDIT_CAPACITY_DETAILS = Symbol("getCreditCapacityDetails"),
  GET_SPOC_DETAILS = Symbol("getSpocDetails"),
  GET_BANK_DETAILS = Symbol("getBankDetails"),
  GET_CASE_HIGHLIGHTS = Symbol("getCaseHighlights"),
  GET_DECISION_SUMMARY = Symbol("getDecisionSummary"),
  GET_CURRENT_OBLIGATIONS = Symbol("getCurrentObligations"),
  GET_REFERENCES_DETAILS = Symbol("getReferenceDetails"),
  GET_DEVIATIONS = Symbol("getDeviations"),
  APPLICATION_FILLED_BY = Symbol("getApplicationFilledBy"),
  GET_APPLICANTS_SUMMARY = Symbol("getApplicationSummary"),
  GET_BANKING_CHEQUE_ANALYSIS = Symbol("getBankingChequeAnalysis"),
  GET_BANKING_CONCENTRATION_ANALYSIS = Symbol("getBankingConcentrationAnalysis"),
  GET_CONCENTRATION_RISK_DETAILS = Symbol("getConcentrationRiskDetails"),
  GET_GST_AND_BANKING_DETAILS = Symbol("getGSTAndBankingDetails"),
  GET_GST_TREND_ANALYSIS = Symbol("getGstTrendDetails"),
  GET_TURNOVER_AND_PURCHASES_DETAILS = Symbol("getTurnoverAndPurchaseDetails"),
  GET_AGGREGATE_TREND_ANALYSIS=Symbol("getAggregateTrendAnalysis"),
  GET_BUSINESS_DETAILS=Symbol("getBusinessDetails"),
  GET_APPLICATION_LOGIN_DETAILS=Symbol("getBusinessDetails"),
  GET_APPLICATION_STATUS=Symbol("getApplicationStatus"),
  GET_BUSINESS_AND_PROFILE_SUMMARY=Symbol("getBusinessAndProfileSummary"),
  GET_TURNOVER_DETAILS=Symbol("getTurnoverDetails"),
  GET_MARGIN_DATA=Symbol("getTurnoverDetails");
export {
  GET_CAM_DATA,
  SET_CREDIT_CAPACITY_DETAILS,
  GET_SPOC_DETAILS,
  GET_BANK_DETAILS,
  GET_CASE_HIGHLIGHTS,
  GET_DECISION_SUMMARY,
  GET_CURRENT_OBLIGATIONS,
  GET_REFERENCES_DETAILS,
  GET_DEVIATIONS,
  APPLICATION_FILLED_BY,
  GET_APPLICANTS_SUMMARY,
  GET_BANKING_CHEQUE_ANALYSIS,
  GET_BANKING_CONCENTRATION_ANALYSIS,
  GET_CONCENTRATION_RISK_DETAILS,
  GET_GST_AND_BANKING_DETAILS,
  GET_GST_TREND_ANALYSIS,
  GET_TURNOVER_AND_PURCHASES_DETAILS,
  GET_AGGREGATE_TREND_ANALYSIS,
  GET_BUSINESS_DETAILS,
  GET_APPLICATION_LOGIN_DETAILS,
  GET_APPLICATION_STATUS,
  GET_BUSINESS_AND_PROFILE_SUMMARY,
  GET_TURNOVER_DETAILS,
  GET_MARGIN_DATA
};
