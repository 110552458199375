import * as ACTION_CONSTANTS from "./unitAnalysis.action.constant";

const initialState = {
  addresses: [],
  assets: [],
  bankAccounts: [],
  memberImages: [],
  itemImages: [],
  entityImages: [],
};

export const leadManagerReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ACTION_CONSTANTS.FETCH_PROMOTER_DETAILS:
      return {
        ...state,
        promoterDetails: data,
      };
    case ACTION_CONSTANTS.FETCH_ADDRESSES:
      return {
        ...state,
        addresses: data?.addresses,
      };
    case ACTION_CONSTANTS.FETCH_EMPLOYEE_DETAILS:
      return {
        ...state,
        employeeDetails: data,
      };
    case ACTION_CONSTANTS.FETCH_MONTHLY_EXPENSES:
      return {
        ...state,
        monthlyExpenses: data,
      };
    case ACTION_CONSTANTS.FETCH_ASSET_DETAILS:
      return {
        ...state,
        assets: data?.assets,
      };
    case ACTION_CONSTANTS.FETCH_BUSINESS_MEMBERS:
      return {
        ...state,
        businessMembers: data,
      };
    case ACTION_CONSTANTS.FETCH_INCOME_DETAILS:
      return {
        ...state,
        incomeDetails: data,
      };
    case ACTION_CONSTANTS.FETCH_INVESTMENTS_AND_FUNDS:
      return {
        ...state,
        investmentsAndFunds: data,
      };
    case ACTION_CONSTANTS.FETCH_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetails: data,
      };
    case ACTION_CONSTANTS.FETCH_BANK_ACCOUNTS:
      return {
        ...state,
        bankAccounts: data?.bankAccounts,
      };

    case ACTION_CONSTANTS.FETCH_ALL_MEMBER_PHOTO:
      return {
        ...state,
        memberImages: data,
      };
    case ACTION_CONSTANTS.FETCH_ALL_ITEM_PHOTO:
      return {
        ...state,
        itemImages: [...state.itemImages, data],
      };
    case ACTION_CONSTANTS.FETCH_ALL_ENTITY_PHOTO:
      return {
        ...state,
        entityImages: [...state.entityImages, data],
      };
    case ACTION_CONSTANTS.FETCH_LOCATION:
      return {
        ...state,
        memberLocation: data,
      };
    case ACTION_CONSTANTS.FETCH_GATING_STATUS:
      return {
        ...state,
        gatingStatus: data,
      };
    default:
      return state;
  }
};
