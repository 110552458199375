import React, { Component } from "react";
import { get } from "lodash";

class ApplicantCard extends Component {
  render() {
    const profileList = get(this, "props.profileDetail.profileList", []);
    const { toggleModal } = this.props;
    return (
      <div className={"row"}>
        {profileList.map((profile, index) => (
          <div key={index} className={"col-md-6"}>
            <div
              // onClick={() => toggleModal(true, profile.pan)}
              className={"content-wrapper"}
            >
              <h4>{get(profile, "profileType", "")}</h4>
              <h3>{get(profile, "name", "")}</h3>
              <div className={"row content-box"}>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Age</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "age", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Education</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "qualification", "")}</span>
                    </div>
                  </div>
                </div>
                  {profile?.address?.map((addressItem , index)=> 
                      {return(
                        <div className={"col-xs-12 content"}>
                        <div className={"row"}>
                        <div className={"col-md-6"}>
                         <label>{addressItem?.addressType || ""}</label>
                        </div>
                        <span className={"col-md-6"}>
                        <address className={"m-0"}>
                         {addressItem?.line1 || ""}{', '}
                         {addressItem?.line2 || ""}
                        <br />
                         {addressItem?.city || ""}{', '}
                         {addressItem?.district || ""}           
                        <br />
                         {addressItem?.state || ""}{', '}
                         {addressItem?.pinCode || ""}   
                        <br />
                         {addressItem?.ownership} (Ownership)               
                        </address>
                        </span>
                        </div>
                        </div>
                      )})}

                {/* <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Permanent Address</label>
                    </div>
                    <span className={"col-md-6"}>
                      <address className={"m-0"}>
                        {get(profile, "permanentAddress.line1") || ""}{" "}
                        {get(profile, "permanentAddress.line2") || ""}
                        <br />
                        {get(profile, "permanentAddress.city") || ""}{" "}
                        {get(profile, "permanentAddress.district") || ""}
                        <br />
                        {get(profile, "permanentAddress.state") || ""} -{" "}
                        {get(profile, "permanentAddress.pinCode") || ""}
                      </address>
                    </span>
                  </div>
                </div> */}
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>DOB</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "dateOfBirth", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>CIBIL Score</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "cibilScore", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>PAN</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "pan", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Share Holding</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "shareHolding", "")}%</span>
                    </div>
                  </div>
                </div>
                {/* <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>House Ownership</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>
                        {profile.permanentAddress
                          ? profile.permanentAddress.ownership
                            ? profile.permanentAddress.ownership
                            : "NA"
                          : "NA"}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Phone</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "mobile", "")}</span>
                    </div>
                  </div>
                </div>
                <div className={"col-xs-12 content"}>
                  <div className={"row"}>
                    <div className={"col-md-6"}>
                      <label>Email</label>
                    </div>
                    <div className={"col-md-6"}>
                      <span>{get(profile, "email", "")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default ApplicantCard;
