import React, { Component } from "react";
import { InputLabelAnimation } from "../../../components";
import { API_POST_CRQ_QUESTION_RESPONSE } from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";

export default class CRQModal extends Component {
  state = {
    email: "",
    selectedTab: null,
    isWrongAns: false,
    isRightAns: false,
  };
  crqQuesHandler = () => {
    let { customerId, crqHandler, submitApplicationHandler } = this.props,
      { selectedTab } = this.state;
    let dataToSend = {
      data: {
        answer: selectedTab,
        customerId,
      },
    };
    POST(API_POST_CRQ_QUESTION_RESPONSE, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ isRightAns: true });
          submitApplicationHandler();
        } else {
          this.setState({ isWrongAns: true });
          crqHandler();
        }
      })
      .catch((err) => {
        throw err;
      });
  };
  render() {
    let { crqData } = this.props,
      { question, buttonBehavior, options } = crqData,
      { email, selectedTab, isWrongAns } = this.state;
    return (
      <div className="crq-modal__wrapper">
        {isWrongAns ? <div className="wrong-answer"> Wrong Answer, Try again</div> : null}

        <p className="mt-2">{question}</p>
        {buttonBehavior === "T" ? (
          <InputLabelAnimation
            labelText={" Email Id"}
            inputValue={email}
            name="email"
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        ) : (
          <div className="btn-wrapper">
            {options.map((option, index) => {
              return (
                <div
                  key={`option-value-${index}`}
                  onClick={() => this.setState({ selectedTab: option , isWrongAns: false })}
                  className={selectedTab === option ? "btn-active button" : "btn-normal button"}
                >
                  {option}
                </div>
              );
            })}
          </div>
        )}
        <div className="text-center">
          <button onClick={this.crqQuesHandler} className={"save-btn"}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}
