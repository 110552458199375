import React, { Component } from "react";
import { connect } from "react-redux";
class MediaDocuments extends Component {
  render() {
    let { entityImages, memberImages, itemImages } = this.props;
    
    return (
      <div className="media-document__wrapper">
        <h2>Media & Documents</h2>
        <div className="doc-heading">Member Photo</div>
        {memberImages?.length ? (
          <div className="row">
            <div className="col-md-4 p-0">
              <img src={"data:image/jpeg;base64," + memberImages} alt={"Member"} className="images" />
            </div>
          </div>
        ) : (
          <div>No Image Found</div>
        )}
        <div className="doc-heading">Item Photo</div>
        <div className="row">
          {itemImages?.length ? (
            itemImages.map((itemImage, index) => {
              return (
                <div className="col-md-4 p-0">
                  <img
                    src={"data:image/jpeg;base64," + itemImage}
                    alt={`Item-Docs-${index}`}
                    key={`Item-Docs-${index}`}
                    className="images"
                  />
                </div>
              );
            })
          ) : (
            <div>No Images Found</div>
          )}
        </div>
        <div className="doc-heading">Entity Photo</div>
        <div className="row">
          {entityImages?.length ? (
            entityImages.map((entityImage, index) => {
              return (
                <div className="col-md-4 p-0">
                  <img
                    src={"data:image/jpeg;base64," + entityImage}
                    alt={`Entity-Docs-${index}`}
                    key={`Entity-Docs-${index}`}
                    className="images"
                  />
                </div>
              );
            })
          ) : (
            <div>No Images Found</div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ leadManagerReducer }) => ({
  memberImages: leadManagerReducer.memberImages,
  itemImages: leadManagerReducer.itemImages,
  entityImages: leadManagerReducer.entityImages,
});

export default connect(mapStateToProps, null)(MediaDocuments);
