import React, {Component} from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

class Youtube extends Component {

    render() {
        const {
                videoId, height, width, id, className, containerClassName, opts, onReady, onPlay,
                onPause, onEnd, onError, onStateChange, onPlaybackRateChange, onPlaybackQualityChange
            } = this.props,
            options = {
                ...opts,
                height,
                width,
                playerVars: {
                    autoplay: 0,
                    modestbranding: 1
                }
            };

        return (
            <YouTube
                videoId={videoId}
                opts={options}
                onReady={onReady}
                id={id}
                className={className}
                containerClassName={containerClassName}
                onPlay={onPlay}
                onPause={onPause}
                onEnd={onEnd}
                onError={onError}
                onStateChange={onStateChange}
                onPlaybackRateChange={onPlaybackRateChange}
                onPlaybackQualityChange={onPlaybackQualityChange}
            />
        );
    }
}

Youtube.defaultProps = {
    height: 390,
    width: 640,
    id: '',
    className: 'youtube-wrapper',
    containerClassName: 'youtube-container',
    opts: {},
    onReady: () => {
    },
    onPlay: () => {
    },
    onPause: () => {
    },
    onEnd: () => {
    },
    onError: () => {
    },
    onStateChange: () => {
    },
    onPlaybackRateChange: () => {
    },
    onPlaybackQualityChange: () => {
    },
};

Youtube.propTypes = {
    videoId: PropTypes.string.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    opts: PropTypes.object,
    height: PropTypes.number,
    width: PropTypes.number,
    onReady: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnd: PropTypes.func,
    onError: PropTypes.func,
    onStateChange: PropTypes.func,
    onPlaybackRateChange: PropTypes.func,
    onPlaybackQualityChange: PropTypes.func,
};

export default Youtube;
