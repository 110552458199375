import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_phone } from "react-icons-kit/md/ic_phone";


export default class caseAgentDetails extends Component {
  render() {
    let {userExceptionData} = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-6 col-6 p-l-0">
            <div className="card__wrapper mt-2 mb-2">
              <div className="heading">Application Details</div>
              
              <div className="row caseDetail_contentBox">
                <div className="col-md-6 p-0  labelBox">
                  <div className="label_heading">Case ID</div>
                  <div className="value">{userExceptionData?.applicationNumber}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">Entity Name</div>
                  <div className="value">{userExceptionData?.applicationName}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">User ID</div>
                  <div className="value">{userExceptionData?.customerId}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">Login Timestamp</div>
                  <div className="value">{userExceptionData?.createdDate}</div>
                </div>
               
              </div>
            </div>
          </div>
          <div className="col-md-6 col-6 p-r-0">
          {userExceptionData?.borrowerDetails ? (
              <div className="card__wrapper mt-2 mb-2">
              <div className="heading div">
                <div className="heading">Borrower Details</div>
                <div className="rightside_content set-cursor-pointer">
                  <div className="circle">
                    <Icon className="icons" icon={ic_phone} />
                  </div>
                  <div>Contact</div>
                </div>
              </div>
              <div className="row agentDetail_contentBox">
                
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading ">Name</div>
                  <div className="value">{userExceptionData?.borrowerDetails?.name}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">PAN Number</div>
                  <div className="value">{userExceptionData?.borrowerDetails?.pan}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                <div className="label_heading">Mobile No</div>
                  <div className="value">{userExceptionData?.borrowerDetails?.mobileNumber}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">Email ID</div>
                  <div className="value">{userExceptionData?.borrowerDetails?.email}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">Aadhaar No</div>
                  <div className="value">{userExceptionData?.borrowerDetails?.aadhaar}</div>
                </div>
              </div>
            </div>
          ) : (
            <div className="card__wrapper mt-2 mb-2">
              <div className="heading div">
                <div className="heading">Agents Details</div>
                <div className="rightside_content set-cursor-pointer">
                  <div className="circle">
                    <Icon className="icons" icon={ic_phone} />
                  </div>
                  <div>Contact</div>
                </div>
              </div>
              <div className="row agentDetail_contentBox">
                <div className="col-md-12 p-0 mt-2 labelBox">
                  <div className="label_heading ">Name</div>
                  <div className="value">{userExceptionData?.agentDetails?.firstName}  {userExceptionData?.agentDetails?.lastName}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                <div className="label_heading">Mobile No</div>
                  <div className="value">{userExceptionData?.agentDetails?.mobile}</div>
                </div>
                <div className="col-md-6 p-0 labelBox">
                  <div className="label_heading">Email ID</div>
                  <div className="value">{userExceptionData?.agentDetails?.email}</div>
                </div>
              </div>
            </div>
          )}  
          </div>
        </div>
      </Fragment>
    );
  }
}
