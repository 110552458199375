const CHANGE_VALUE = Symbol('ChangeValue'),
    SET_INITIAL_RESPONSE = Symbol('SetInitialResponse'),
    SET_BANKING_DETAILS = Symbol('SetBankingDetails'),
    DELETE_FORM_BOX = Symbol('DeleteFormBox'),
    ADD_FORM_BOX = Symbol('AddFormBox'),
    DELETE_FILE_ROW = Symbol('DeleteFileRow'),
    ADD_FILE_ROW = Symbol('AddFileRow'),
    RESET_REDUX_STATE = Symbol('ResetReduxState'),
    CHANGE_FILE_VALUES = Symbol('ChangeFilesValue');

export {
    CHANGE_VALUE,
    SET_INITIAL_RESPONSE,
    SET_BANKING_DETAILS,
    DELETE_FORM_BOX,
    ADD_FORM_BOX,
    DELETE_FILE_ROW,
    ADD_FILE_ROW,
    RESET_REDUX_STATE,
    CHANGE_FILE_VALUES
}
