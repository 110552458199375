import React, { Component } from "react";
import UnitAnalysisA from "./Overview.analysis.A";
import UnitAnalysisB from "./Overview.analysis.B";
import OverviewDetails from "./Overview.details";
import BicycleBusinessCostAnalysis from "./ClusterAnalysis/BicycleBusinessCostAnalysis/BicycleBusinessCostAnalysis";
import HosieryBusinessCostAnalysis from "./ClusterAnalysis/HosieryBusinessCostAnalysis/HosieryBusinessCostAnalysis";
import FootwearBusinessCostAnalysis from "./ClusterAnalysis/FootwearBusinessCostAnalysis/FootwearBusinessCostAnalysis";
import MachineAndHandToolsBusinessCostAnalysis from "./ClusterAnalysis/MachineAndHandToolsBusinessCostAnalysis/MachineAndHandToolsBusinessCostAnalysis";
import SewingMachineBusinessCostAnalysis from "./ClusterAnalysis/SewingMachineBusinessCostAnalysis/SewingMachineBusinessCostAnalysis";
import { setHeaderFixed } from "../../../utils/utitlity";

export default class Overview extends Component {
  state = {
    screenToRender: "overview-details",
  };
  screenRenderHandler = (screenToRender) => {
    this.setState({ screenToRender });
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", setHeaderFixed);
  }
  componentDidMount() {
    window.addEventListener("scroll", setHeaderFixed);
  }
  render() {
    let { screenToRender } = this.state;
    return (
      <>
        {screenToRender === "overview-details" ? (
          <OverviewDetails
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender === "analysis-a" ? (
          <UnitAnalysisA
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender ===
          "Ludhiana Hosiery and Readymade Garment Cluster" ? (
          <HosieryBusinessCostAnalysis
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender === "Ludhiana Bicycle and Parts cluster" ? (
          <BicycleBusinessCostAnalysis
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender === "Agra Footwear Cluster" ? (
          <FootwearBusinessCostAnalysis
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender === "Ludhiana Machine And Hand Tools" ? (
          <MachineAndHandToolsBusinessCostAnalysis
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : screenToRender === "Ludhiana Sewing Machine Cluster" ? (
          <SewingMachineBusinessCostAnalysis
            {...this.props}
            screenRenderHandler={this.screenRenderHandler}
          />
        ) : null}
      </>
    );
  }
}
