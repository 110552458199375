import React, {Component} from 'react';
import PropTypes from "prop-types";
import {DropdownButton, MenuItem} from "react-bootstrap";

class DropdownLabelAnimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownActive: false
        }
    }

    componentWillReceiveProps(nextProps) {
        let {dropdownActive} = this.state,
            index = nextProps.arrData.indexOf(nextProps.dropdownValue);
        index > 0
            ? !dropdownActive && this.setState({dropdownActive: true})
            : dropdownActive && this.setState({dropdownActive: false})
    }

    render() {
        const {dropdownActive} = this.state,
            {labelText, required, disabled, boxClass, tabIndex, onSelect, dropdownValue, dropdownId, arrData, activeItem} = this.props,
            index = arrData.indexOf(dropdownValue);
        return (
            <div className={disabled ? `dropdown-box dropdown-disabled ${boxClass}` : `dropdown-box ${boxClass}`}>
                <label onClick={() => document.getElementById(dropdownId).click()}
                       className={dropdownActive ? "dropdown-active" : ""}>
                    {labelText}
                    {
                        required
                            ? <span>&nbsp;*&nbsp;</span>
                            : null
                    }
                </label>
                <DropdownButton
                    // ref={ref => this.dropdownRef = ref}
                    tabIndex={tabIndex}
                    onSelect={onSelect}
                    title={dropdownValue}
                    id={dropdownId}
                    className={`select-box ${!index ? 'opacity-0' : 'opacity-1'}`}
                    disabled={disabled}
                >
                    {
                        arrData.map((item, index) => <MenuItem
                            active={item === activeItem} title={item} key={index}
                            eventKey={index}>{item}</MenuItem>
                        )
                    }
                </DropdownButton>
            </div>
        )
    }
}

DropdownLabelAnimation.defaultProps = {
    labelText: '',
    required: false,
    disabled: false,
    boxClass: '',
    tabIndex: -1,
    onSelect: () => {
    },
    dropdownValue: '',
    dropdownId: '',
    arrData: [],
    activeItem: ''
};

DropdownLabelAnimation.propTypes = {
    labelText: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    boxClass: PropTypes.string,
    tabIndex: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    dropdownValue: PropTypes.string.isRequired,
    dropdownId: PropTypes.string.isRequired,
    arrData: PropTypes.array.isRequired,
    activeItem: PropTypes.string
};

export default DropdownLabelAnimation;
