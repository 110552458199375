import React, { Component } from "react";
import Applicants from "./Applicants";
import Members from "./Members";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import { Header } from "../../../components";
export default class ClusterDashboard extends Component {
  state = {
    activeTab: "members",
  };
  render() {
    let { activeTab } = this.state;
    return (
      <div className="cluster-dashboard__wrapper">
        <Header {...this.props} />
        <div className="navigation-bar">
          <div className="d-flex">
            <div
              onClick={() => this.setState({ activeTab: "members" })}
              className={activeTab === "members" ? "active nav" : "nav"}
            >
              Members
            </div>
            <div
              onClick={() => this.setState({ activeTab: "applicants" })}
              className={
                activeTab === "members" ? "ml-3 nav" : "active ml-3 nav"
              }
            >
              Applicants
            </div>
          </div>
          {activeTab === "members" ? (
            <button
              className="button__generic"
              onClick={() => this.props.history.push("/cluster/onboarding")}
            >
              <Icon icon={ic_add} size={22} />
              Add member
            </button>
          ) : (
            <button
              className="button__generic"
              onClick={() => this.props.history.push("/Sales/post_login")}
            >
              <Icon icon={ic_add} size={22} />
              Add Application
            </button>
          )}
        </div>
        {activeTab === "members" ? (
          <Applicants {...this.props} />
        ) : (
          <Members {...this.props} />
        )}
      </div>
    );
  }
}
