import React, { Component } from "react";
import { CheckBox } from "../../../components";
import {
  API_GET_AUTH_DECLARATION,
  API_ADD_AUTH_DECLARATION,
} from "../../../utils/APIUrls";
import { POST, GET } from "../../../utils/webAPI.service";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { connect } from "react-redux";
import { get } from "lodash";
import { Helmet } from "react-helmet";

class SubmitApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declaration: false,
    };
  }

  setDeclaration = (e) => {
    this.setState({
      declaration: e.target.checked,
    });
  };

  fetchDetails = async () => {
    let { match, actions } = this.props,
      { loaderModal } = actions,
      applicationNumber = get(match, "params.appNo", "");
    loaderModal(true);
    await GET(API_GET_AUTH_DECLARATION(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({
            declaration: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Something went wrong please try again later.");
      });
    loaderModal(false);
  };

  submitForm = (e) => {
    e.preventDefault();
    let { actions, history, match } = this.props,
      { loaderModal } = actions,
      { declaration } = this.state,
      applicationNumber = get(match, "params.appNo", ""),
      authDeclaration = document.querySelector(
        ".submit-wrapper .form-box .declaration-box .statement span"
      ).textContent;
    if (!declaration) {
      window.alert("Please Select Auth Declaration");
      return false;
    }
    let data = {
      applicationNumber,
      authDeclaration,
    };
    const headers = { "Content-Type": "application/json" };
    loaderModal(true);
    POST(API_ADD_AUTH_DECLARATION, { data }, { headers })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          history.push(`/Sales/${applicationNumber}/forms_preview`);
        }
      })
      .catch((err) => {
        console.log(err);
        loaderModal(false);
        window.alert("Something went wrong please try again later.");
      });
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { history, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    !applicationNumber && history.push("/Sales/gstn");
  }

  componentDidMount() {
    const { match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    applicationNumber && this.fetchDetails();
  }

  render() {
    let { declaration } = this.state;
    return (
      <div className={"submit-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Submit App</title>
        </Helmet>
        <div className={"content-box"}>
          <h4 className={"box-heading"}>Submit Application</h4>
          <form onSubmit={this.submitForm} className={"form-box"}>
            <div className={"declaration-box"}>
              <CheckBox
                labelColor={"#1d1d1d"}
                checkboxColor={"#4687f4"}
                name={"declarations"}
                labelClass={"input-label"}
                htmlFor={"declarations"}
                label={"I agree"}
                checked={declaration}
                onChange={this.setDeclaration}
              />
              <p className={"statement"}>
                <b>Declaration 1:</b>{" "}
                <span>
                  I hereby declare that the details furnished above are true and
                  correct to the best of my knowledge and belief and I undertake
                  to inform the lender of any charges therein, immediately.
                </span>
              </p>
            </div>
            <div className={"btns-box"}>
              <button type={"submit"} className={"submit-btn"}>
                Preview Application
              </button>
              {/*<button type={'button'} className={'edit-btn'}>*/}
              {/*Next Application*/}
              {/*</button>*/}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmitApp);
