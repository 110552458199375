const FETCH_PROMOTER_DETAILS = Symbol("fetchPromoterdetails"),
  FETCH_ADDRESSES = Symbol("fetchAddresses"),
  FETCH_EMPLOYEE_DETAILS = Symbol("fetchEmployeedetails"),
  FETCH_MONTHLY_EXPENSES = Symbol("fetchMonthlyExpenses"),
  FETCH_INVESTMENTS_AND_FUNDS = Symbol("fetchInvestmentsAndFunds"),
  FETCH_ASSET_DETAILS = Symbol("fetchAssetDetails"),
  FETCH_BUSINESS_MEMBERS = Symbol("fetchBusinessMembers"),
  FETCH_INCOME_DETAILS = Symbol("fetchIncomeDetails"),
  FETCH_BUSINESS_DETAILS = Symbol("fetchBusinessDetails"),
  FETCH_BANK_ACCOUNTS = Symbol("fetchBankAccounts"),
  FETCH_ALL_MEMBER_PHOTO=Symbol("fetchMemberPhoto"),
  FETCH_ALL_ITEM_PHOTO=Symbol("fetchUnitPhoto"),
  FETCH_ALL_ENTITY_PHOTO=Symbol("fetchEntityPhoto"),
  FETCH_GATING_STATUS=Symbol("fetchGatingStatus"),
  FETCH_LOCATION=Symbol("fetchLocation");


export {
  FETCH_PROMOTER_DETAILS,
  FETCH_ADDRESSES,
  FETCH_EMPLOYEE_DETAILS,
  FETCH_MONTHLY_EXPENSES,
  FETCH_INVESTMENTS_AND_FUNDS,
  FETCH_ASSET_DETAILS,
  FETCH_BUSINESS_MEMBERS,
  FETCH_INCOME_DETAILS,
  FETCH_BUSINESS_DETAILS,
  FETCH_BANK_ACCOUNTS,
  FETCH_ALL_MEMBER_PHOTO,
  FETCH_ALL_ITEM_PHOTO,
  FETCH_ALL_ENTITY_PHOTO,
  FETCH_LOCATION,
  FETCH_GATING_STATUS
};
