import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ProgressBar, CheckBox } from "../../../components";
import "react-rangeslider/lib/index.css";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET, POST } from "../../../utils/webAPI.service";
import { getList } from "../../../store/listData.actions";
import { loaderModal } from "../../../store/actions";
import { resetLoanTenureState } from "./loanTenure.actions";
import {
  API_GET_BUSINESS_TYPE,
  API_GET_LOAN_USE,
  API_GET_REPAYMENT_TYPE,
  API_GET_LOAN_TYPE,
  API_GET_PRIMARY_INDUSTRY,
  API_GET_SECONDARY_INDUSTRY,
  API_CREATE_APPLICATION,
  API_GET_BRAND_NAMES,
} from "../../../utils/APIUrls";
import { getLoanDetails, changeInputValue } from "./loanTenure.actions";
import { get } from "lodash";
import Select from "react-select";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { Helmet } from "react-helmet";

class LoanTenure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondaryIndustry: [],
      primaryIndustry: [],
      brandNamesList: [],
      declaration: false,
      error: "",
    };
  }

  setPrimaryIndustry = (businessType) => {
    let { actions, loanDetails } = this.props,
      { changeInputValue, loaderModal } = actions,
      primaryIndustry = get(loanDetails, "primaryIndustry", ""),
      secondaryIndustry = get(loanDetails, "secondaryIndustry", "");
    if (businessType !== "Select Business Type") {
      primaryIndustry && changeInputValue("", "primaryIndustry");
      secondaryIndustry && changeInputValue("", "secondaryIndustry");
      loaderModal(true);
      GET(`${API_GET_PRIMARY_INDUSTRY}/${businessType}`)
        .then(({ data }) => {
          if (data.status === "success") {
            const primaryIndustry = data.data.responseList.map((item) => ({
              value: item,
              label: item,
            }));
            this.setState({ primaryIndustry });
          }
          loaderModal(false);
        })
        .catch((error) => {
          loaderModal(false);
          console.log(error);
        });
    } else {
      primaryIndustry && changeInputValue("", "primaryIndustry");
      secondaryIndustry && changeInputValue("", "secondaryIndustry");
      this.setState({ primaryIndustry: [], secondaryIndustry: [] });
    }
  };

  setSecondaryIndustry = (primaryIndustry) => {
    let { actions, loanDetails } = this.props,
      { loaderModal, changeInputValue } = actions,
      businessType = get(loanDetails, "businessType");
    changeInputValue("", "secondaryIndustry");
    changeInputValue([], "brandNames");

    loaderModal(true);
    GET(`${API_GET_SECONDARY_INDUSTRY}/${primaryIndustry}/${businessType}`)
      .then(({ data }) => {
        if (data.status === "success") {
          const secondaryIndustry = data.data.responseList.map((item) => ({
            value: item,
            label: item,
          }));
          this.setState({ secondaryIndustry });
        }
        loaderModal(false);
      })
      .catch((error) => {
        loaderModal(false);
        console.log(error);
      });
  };

  setBrandNames = (secondaryIndustry) => {
    let { actions, loanDetails } = this.props,
      { loaderModal } = actions,
      primaryIndustry = get(loanDetails, "primaryIndustry", "");
    actions.changeInputValue([], "brandNames");
    this.setState({ brandNamesList: [] });
    loaderModal(true);
    GET(`${API_GET_BRAND_NAMES}/${secondaryIndustry}/${primaryIndustry}`)
      .then(({ data }) => {
        if (data.status === "success") {
          const brandNamesList = data.data.responseList.map((item) => ({
            value: item,
            label: item,
          }));
          this.setState({ brandNamesList });
        }
        loaderModal(false);
      })
      .catch((error) => {
        loaderModal(false);
        console.log(error);
      });
  };

  selectHandler = (i, name) => {
    this.setState({ error: "" });
    const { actions, loanDetails } = this.props,
      { changeInputValue } = actions;
    let value = "";
    switch (name) {
      case "programType":
        value = i.target.value;
        break;
      case "primaryIndustry":
        value = i.value;
        value !== get(loanDetails, "primaryIndustry", "") &&
          this.setSecondaryIndustry(value);
        break;
      case "secondaryIndustry":
        value = i.value;
        value !== get(loanDetails, "secondaryIndustry", "") &&
          this.setBrandNames(value);
        break;
      case "businessType":
        value = i.target.value;
        value !== get(loanDetails, "businessType", "") &&
          this.setPrimaryIndustry(value);
        break;
      case "programPurpose":
        value = i.target.value;
        break;
      case "userType":
        value = i.target.value;
        break;
      case "userSubType":
        value = i.target.value;
        break;
      default:
        value = "";
    }
    changeInputValue(value, name);
  };

  setDeclaration = (e) => this.setState({ declaration: e.target.checked });

  validateForm = (data, declaration) => {
    const {
      programPurpose,
      programType,
      businessType,
      primaryIndustry,
      secondaryIndustry,
      userType,
      userSubType,
    } = data;
    if (programType === "Select Program Type" || !programType) {
      this.setState({ error: "Please Select Program Type" });
      document.getElementById("program-type-select").click();
      return false;
    }
    if (programPurpose === "Select Program Purpose" || !programPurpose) {
      this.setState({ error: "Please Select Program Purpose" });
      document.getElementById("program-purpose-select").click();
      return false;
    }
    if (businessType === "Select Business Type" || !businessType) {
      this.setState({ error: "Please Select Business Type" });
      document.getElementById("business-type-select").click();
      return false;
    }
    if (!primaryIndustry) {
      this.setState({ error: "Please Select Primary Industry" });
      this.primaryIndustry.focus();
      return false;
    }
    if (!secondaryIndustry) {
      this.setState({ error: "Please Select Secondary Industry" });
      this.secondaryIndustry.focus();
      return false;
    }
    if (userType === "Select User Type" || !userType) {
      this.setState({ error: "Please Select User Type" });
      document.getElementById("user-type-select").click();
      return false;
    }
    if (userType === "COUNTER_PARTY" && !userSubType) {
      this.setState({ error: "Please Select Counter Party Type" });
      document.getElementById("user-counter-party-select").click();
      return false;
    }
    // if (!declaration) {
    //   this.setState({ error: "Please accept the Disclaimer Policy" });
    //   return false;
    // }
    return true;
  };

  submitForm = (e) => {
    e.preventDefault();
    const { loanDetails, actions, history, match } = this.props,
      programType = get(loanDetails, "programType", "Select Program Type"),
      businessType = get(loanDetails, "businessType", "Select Business Type"),
      userType = get(loanDetails, "userType", "Select User Type"),
      userSubType = get(
        loanDetails,
        "userSubType",
        "Select Counter Party Type"
      ),
      programPurpose = get(
        loanDetails,
        "programPurpose",
        "Select Program Purpose"
      ),
      primaryIndustry = get(loanDetails, "primaryIndustry", ""),
      secondaryIndustry = get(loanDetails, "secondaryIndustry", ""),
      brandNames = get(loanDetails, "brandNames", []),
      { loaderModal } = actions,
      { declaration } = this.state,
      applicationNumber = match.params.appNo;

    let data = {
      applicationNumber,
      businessType,

      programPurpose,
      programType,
      primaryIndustry,
      secondaryIndustry,
      recordId: get(loanDetails, "recordId", ""),
      userType,
      userSubType: userType === "ANCHOR" ? "" : userSubType,
      brandNames,
    };
    if (this.validateForm(data, declaration)) {
      loaderModal(true);
      const headers = { "Content-Type": "application/json" };
      POST(API_CREATE_APPLICATION, { data }, { headers })
        .then(({ data }) => {
          loaderModal(false);
          if (data.status === "success") {
            history.push(`/Sales/${applicationNumber}/gstndetails`);
          }
        })
        .catch((err) => {
          console.log(err);
          loaderModal(false);
          this.setState({
            error: "Something went wrong please try again later.",
          });
        });
    }
  };

  fetchDetails = async () => {
    const { loanType, repaymentType, loanUse, businessType, match, actions } =
        this.props,
      applicationNumber = get(match, "params.appNo", ""),
      { getList, loaderModal, getLoanDetails } = actions;
    loaderModal(true);
    !loanType.length &&
      getList("SET_LOAN_TYPE", API_GET_LOAN_TYPE, "Select Program Type");
    !repaymentType.length &&
      getList(
        "SET_REPAYMENT_TYPE",
        API_GET_REPAYMENT_TYPE,
        "Select Repayment Type"
      );
    !loanUse.length &&
      getList("SET_LOAN_USE", API_GET_LOAN_USE, "Select Program Purpose");
    !businessType.length &&
      (await getList(
        "SET_BUSINESS_TYPE",
        API_GET_BUSINESS_TYPE,
        "Select Business Type"
      ));
    applicationNumber &&
      (await getLoanDetails(applicationNumber).then(({ status, data }) => {
        if (status === "success") {
          data.businessType &&
            GET(`${API_GET_PRIMARY_INDUSTRY}/${data.businessType}`).then(
              (res) => {
                if (res.data.status === "success") {
                  const primaryIndustry = res.data.data.responseList
                    .sort()
                    .map((item) => ({
                      value: item,
                      label: item,
                    }));
                  this.setState({ primaryIndustry });
                }
              }
            );
          data.primaryIndustry &&
            GET(
              `${API_GET_SECONDARY_INDUSTRY}/${data.primaryIndustry}/${data.businessType}`
            ).then((res) => {
              if (res.data.status === "success") {
                const secondaryIndustry = res.data.data.responseList
                  .sort()
                  .map((item) => ({
                    value: item,
                    label: item,
                  }));
                this.setState({ secondaryIndustry });
              }
            });
          data.primaryIndustry &&
            data.secondaryIndustry &&
            GET(
              `${API_GET_BRAND_NAMES}/${data.secondaryIndustry}/${data.primaryIndustry}`
            )
              .then(({ data }) => {
                if (data.status === "success") {
                  const brandNamesList = data.data.responseList.map((item) => ({
                    value: item,
                    label: item,
                  }));
                  this.setState({ brandNamesList });
                }
                loaderModal(false);
              })
              .catch((error) => {
                loaderModal(false);
                console.log(error);
              });
        }
      }));
    loaderModal(false);
  };

  componentWillMount() {
    const { actions, loanDetails } = this.props,
      { resetLoanTenureState } = actions,
      newApplication = get(this, "props.location.state.newApplication", false);
    if (!newApplication) {
      resetLoanTenureState();
    }
  }

  componentDidMount() {
    this.fetchDetails();
  }

  render() {
    const { secondaryIndustry, primaryIndustry, declaration, brandNamesList } =
        this.state,
      { loanType, loanUse, businessType, loanDetails, match, actions } =
        this.props,
      _primaryIndustry = primaryIndustry.find(
        (opt) => opt.value === get(loanDetails, "primaryIndustry", "")
      ),
      _secondaryIndustry = secondaryIndustry.find(
        (opt) => opt.value === get(loanDetails, "secondaryIndustry", "")
      ),
      _brandNames = get(loanDetails, "brandNames", []);

    const userTypeList = [
      { value: "COUNTER_PARTY", text: "Counter Party" },
      { value: "ANCHOR", text: "Anchor" },
    ];
    const userSubTypeList = [
      { value: "VENDOR", text: "Vendor" },
      { value: "BUYER", text: "Buyer" },
    ];

    const formattedOptions = _brandNames?.map((option) => ({
      value: option,
      label: option,
    }));

    const userType = get(loanDetails, "userType", "Select User Type");
    return (
      <div className={"gstn-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Program Details</title>
        </Helmet>
        <ProgressBar active={1} {...this.props} />
        <div className={"content-box"} style={{ overflowY: "hidden" }}>
          <Link
            to={`/Sales/${match.params.appNo}/sourcingDetails`}
            className={"back-btn-link"}
          >
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>Enter Program Details</h4>
          <form onSubmit={this.submitForm} className={"gstn-form"}>
            <div className={"row"}>
              <div className="row">
                <div className={"col-md-4"}>
                  <div className="input-box">
                    <select
                      className={"select-box1"}
                      id={"program-type-select"}
                      tabIndex={1}
                      value={get(
                        loanDetails,
                        "programType",
                        "Select Program Type"
                      )}
                      onChange={(i) => this.selectHandler(i, "programType")}
                    >
                      {loanType.map((x, y) => (
                        <option key={y + 1}>{x}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={"col-md-4"}>
                  <div className="input-box">
                    <select
                      className={"select-box1"}
                      tabIndex={4}
                      id={"program-purpose-select"}
                      value={get(
                        loanDetails,
                        "programPurpose",
                        "Select Program Purpose"
                      )}
                      onChange={(i) => this.selectHandler(i, "programPurpose")}
                    >
                      {loanUse.map((x, y) => (
                        <option key={y + 1}>{x}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={"col-md-4"}>
                  <div className="input-box">
                    <select
                      className={"select-box1"}
                      tabIndex={4}
                      id={"business-type-select"}
                      value={get(
                        loanDetails,
                        "businessType",
                        "Select Business Type"
                      )}
                      onChange={(i) => this.selectHandler(i, "businessType")}
                    >
                      {businessType.map((x, y) => (
                        <option key={y + 1}>{x}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={"col-md-4"}>
                  <div className="input-box">
                    <Select
                      ref={(ref) => (this.primaryIndustry = ref)}
                      tabIndex={7}
                      value={_primaryIndustry ? _primaryIndustry : ""}
                      placeholder={"Select Primary Industry"}
                      isSearchable={true}
                      name={"primaryIndustry"}
                      isDisabled={
                        get(
                          loanDetails,
                          "businessType",
                          "Select Business Type"
                        ) === "Select Business Type"
                      }
                      options={primaryIndustry}
                      className="industry-select-search primary-industry"
                      classNamePrefix="select"
                      onChange={(i) => this.selectHandler(i, "primaryIndustry")}
                    />
                  </div>
                </div>
                <div className={"col-md-4"}>
                  <div className="input-box">
                    <Select
                      ref={(ref) => (this.secondaryIndustry = ref)}
                      tabIndex={8}
                      value={_secondaryIndustry ? _secondaryIndustry : ""}
                      placeholder={"Select Secondary Industry"}
                      isSearchable={true}
                      isDisabled={!get(loanDetails, "primaryIndustry", "")}
                      name={"secondaryIndustry"}
                      options={secondaryIndustry}
                      className="industry-select-search secondary-industry"
                      classNamePrefix="select"
                      onChange={(i) =>
                        this.selectHandler(i, "secondaryIndustry")
                      }
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={"col-md-8"}>
                    <div className="input-box">
                      <Select
                        ref={(ref) => (this.brandNames = ref)}
                        tabIndex={9}
                        placeholder={"Select Brand Name"}
                        isSearchable={true}
                        isDisabled={!get(loanDetails, "secondaryIndustry", "")}
                        name={"brandNames"}
                        options={brandNamesList}
                        defaultValue={formattedOptions}
                        className="industry-select-search secondary-industry"
                        classNamePrefix="select"
                        closeMenuOnSelect={false}
                        onChange={(e) => {
                          let value = e.map((el) => el.value);
                          actions.changeInputValue(value, "brandNames");
                        }}
                        isMulti={true}
                        value={_brandNames?.map((el) =>
                          brandNamesList?.find((option) => option.value === el)
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className={"col-md-4"}>
                  <div className="input-box">
                    <select
                      className={"select-box1"}
                      id={"user-type-select"}
                      value={get(loanDetails, "userType", "Select User Type")}
                      onChange={(i) => this.selectHandler(i, "userType")}
                    >
                      <option>Select User Type</option>
                      {userTypeList.map(({ value, text }, y) => {
                        return (
                          <option key={y + 1} value={value}>
                            {text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                {userType === "COUNTER_PARTY" ? (
                  <div className={"col-md-4"}>
                    <div className="input-box">
                      <select
                        className={"select-box1"}
                        id={"user-counter-party-select"}
                        value={get(
                          loanDetails,
                          "userSubType",
                          "Select Counter Party Type"
                        )}
                        onChange={(i) => this.selectHandler(i, "userSubType")}
                      >
                        <option>Select Counter Party Type</option>
                        {userSubTypeList.map(({ value, text }, y) => {
                          return (
                            <option key={y + 1} value={value}>
                              {text}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className={"declaration-box"}></div>
              </div>
            </div>

            {this.state.error ? (
              <ErrorMessage>{this.state.error}</ErrorMessage>
            ) : null}
            <div className={"text-center"}>
              <button tabIndex={9} className={"save-btn"} type={"submit"}>
                Save & Next
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ listData, loanTenure }) => ({
  loanType: listData.loanTypeList,
  repaymentType: listData.repaymentTypeList,
  loanUse: listData.loanUseList,
  businessType: listData.businessType,
  primaryIndustry: listData.primaryIndustryList,
  secondaryIndustryList: listData.secondaryIndustryList,
  loanDetails: loanTenure.loanDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setPrimaryIndustries: (list) =>
    dispatch({ type: "SET_PRIMARY_INDUSTRIES", list }),
  setSecondaryIndustries: (list) =>
    dispatch({ type: "SET_SECONDARY_INDUSTRIES", list }),
  actions: bindActionCreators(
    {
      getList,
      loaderModal,
      getLoanDetails,
      changeInputValue,
      resetLoanTenureState,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoanTenure);
