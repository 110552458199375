import React, { Fragment } from 'react';
import Icon from 'react-icons-kit';
import { ic_check } from 'react-icons-kit/md/ic_check';
import get from 'lodash/get';

const GSTDetails = ({ data, index }) => {
  return (
    <Fragment>
      {index ? <hr className={'horizontal-divider'} /> : null}
      <div className={'row'}>
        <div className={'col-xs-6 col-md-2 box-details'}>
          <div>
            <label>GSTN {index + 1}</label>
            <span>{get(data, 'gstin', '')}</span>
          </div>
        </div>
        <div className={'col-xs-6 col-md-3 box-details'}>
          <div>
            <label>Registered Entity</label>
            <span>{get(data, 'registeredEntity', '')}</span>
          </div>
        </div>
        <div className={'col-xs-6 col-md-3 box-details'}>
          <div>
            <label>Address</label>
            <span>{get(data, 'address', '')}</span>
          </div>
        </div>
        <div className={'col-xs-6 col-md-2 box-details'}>
          <div>
            <label>GSTN Fetch</label>
            {get(data, 'gstnFetchFlag', false) ? (
              <span className={'check-icon'}>
                <Icon icon={ic_check} size={20} />
              </span>
            ) : null}
          </div>
        </div>
        <div className={'col-xs-6 col-md-2 box-details'}>
          <div>
            <label>DOR</label>
            <span>{get(data, 'dateOfRegistration', '')}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GSTDetails;
