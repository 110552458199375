const CHANGE_VALUE = Symbol('ChangeValue'),
    SET_DOCUMENT_DATA = Symbol('SetDocumentData'),
    RESET_REDUX_STATE = Symbol('ResetReduxState'),
    FETCH_DOCUMENT_LIST = Symbol('FetchDocumentList'),
    CO_APPLICANT_RELATION=Symbol('FetchRelationList'),
    DOCUMENT_EXCEPTION_LIST=Symbol('DocumentException');
export {
    DOCUMENT_EXCEPTION_LIST,
    CO_APPLICANT_RELATION,
    CHANGE_VALUE,
    SET_DOCUMENT_DATA,
    RESET_REDUX_STATE,
    FETCH_DOCUMENT_LIST
};
