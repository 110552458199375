import * as ITRConstant from "./ITR.actions.constant";
import {
  backToReactTableConvert,
  backToReactTableConvertArray,
  ReactTableApiConvert,
} from "./ITR.config";
const initialState = {
  balanceSheetData: [],
  profitAndLossData: [],
  assesseeDetailsData: {},
  otherIncomesData: [],
  ratiosData: [],
};

export const itrReducer = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case ITRConstant.GET_ALL_BALANCE_SHEET:
      return { ...state, balanceSheetData: ReactTableApiConvert(data.all) };
    case ITRConstant.GET_ALL_PROFIT_AND_LOSS_ACCOUNT:
      return { ...state, profitAndLossData: ReactTableApiConvert(data.all) };
    case ITRConstant.ASSESSEE_DETAILS:
      return {
        ...state,
        assesseeDetailsData: data,
        otherIncomesData: backToReactTableConvertArray(data.otherIncomes),
      };
    case ITRConstant.RATIOS:
      return { ...state, ratiosData: backToReactTableConvert(data) };
    default:
      return state;
  }
};
