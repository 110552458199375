import { hist as history } from "../App";
import { set, get } from "lodash";

const alphaNumericRegex =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{7,}$/;

const getCookie = (name) => {
  let cookies = document.cookie.split(";");
  let cookieValue = "";
  cookies.forEach((item) => {
    if (item.search(name) !== -1) {
      cookieValue = item.split("=")[1];
    }
  });
  return cookieValue;
};

const deleteCookie = (...names) => {
  let arrPath = get(history, "location.pathname", "").split("/");
  names.forEach((item) => {
    switch (item) {
      case "token":
        document.cookie = `${item}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        break;
      case "applicationNumber":
        if (getCookie(item)) {
          document.cookie = `${item}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/${arrPath[1]}`;
        }
        break;
      case "credochain.token":
      case "credochain.credit.appNo":
      case "credochain.sales.appNo":
        if (getCookie(item)) {
          window.localStorage.removeItem(item);
        }
        break;
      default:
        console.log("COOKIES_DELETED");
    }
  });
};

const goTo = (route) => {
  history.push(route);
};

const setMaxDate = (type = "") => {
  let dateArr = new Date().toLocaleDateString().split("/");
  let date = dateArr[1];
  let month = dateArr[0];
  let year = dateArr[2];
  if (type === "dob") {
    year = Number(year) - 18;
  } else {
    date = Number(date) - 1;
  }
  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  return `${year}-${month}-${date}`;
};

const getDMY = (dateStr) => {
  let dateObj = new Date(dateStr);
  let date = dateObj.getDate();
  let month = dateObj.getMonth() + 1;
  let year = dateObj.getFullYear();
  return `${date}-${month}-${year}`;
};

const getYMD = (dateStr) => {
  let [dateArr, year, date, month] = "";
  let browserObj = checkBrowser();
  if (browserObj.isIE) {
    dateArr = dateStr.split("-");
    date = dateArr[1];
    month = dateArr[0];
    year = dateArr[2];
  } else {
    dateArr = dateStr.split("/");
    date = dateArr[1];
    month = dateArr[0];
    year = dateArr[2];
  }
  return `${year}-${month}-${date}`;
};

const getScrollPosition = (selector) => {
  let elem = document.getElementById(selector);
  if (elem) {
    let coords = elem.getBoundingClientRect();
    let windowHeight = document.documentElement.clientHeight;
    return coords.bottom > 0 && coords.bottom < windowHeight;
  }
  return false;
};

// const getUrlVars = (id) => {
//     let vars = [], hash;
//     let hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
//     for (let i = 0; i < hashes.length; i++) {
//         hash = hashes[i].split('=');
//         vars.push(hash[0]);
//         vars[hash[0]] = hash[1];
//     }
//     return vars[id];
// };

const setHeaderFixed = () => {
  let header = document.getElementsByClassName("credit-navs-wrapper")[0];
  let navbar = header.children[0];
  let sticky = navbar.offsetTop;
  if (window.pageYOffset > sticky) {
    navbar.classList.add("navbar-fixed-top");
  } else {
    navbar.classList.remove("navbar-fixed-top");
  }
};

const checkBrowser = () => {
  // Opera 8.0+
  // let isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

  // Firefox 1.0+
  let isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  // let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
  //     return p.toString() === "[object SafariRemoteNotification]";
  // })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

  // Internet Explorer 6-11
  // let isIE = /*@cc_on!@*/false || !!document.documentMode;
  let isIE = !!document.documentMode;

  // Edge 20+
  let isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 71
  let isChrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Blink engine detection
  // let isBlink = (isChrome || isOpera) && !!window.CSS;

  return {
    // isOpera,
    isFirefox,
    // isSafari,
    isIE,
    isEdge,
    isChrome,
    // isBlink
  };
};

const convertNumToStr = (value) => {
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Cr";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " Lac";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + " K";
  }
  return val;
};

const validateEmail = (email) => {
  // let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let emailRegex =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

/**
 *
 * @param state: Redux State
 * @param name: name of Field to be fetch
 * @returns {*}
 * @private
 */
const _getValue = (state, name) => {
  return get(state, [name]);
};

/**
 * @param state: state Values
 * @param name: name of field to be updated
 * @param value: new value
 * @returns {{form: *}}
 * @private
 */

const _updateFormValues = (state, name, value = "") => {
  const _preValue = _getValue(state, name);
  if (_preValue !== value) {
    return get(set(state, `form.values.${name}`, value), "form");
  }
  return false;
};

/**
 * Format date for calendars
 * @param date
 * @returns {string}
 */

const getDateString = (date) => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dateObj = new Date(date);
  let currentDate = dateObj.getDate();
  let currentMonth = months[dateObj.getMonth()];
  let currentYear = dateObj.getFullYear();
  return `${currentMonth} ${currentDate}, ${currentYear}`;
};

const rupeeCommaFormatter = (value) => {
  let val = value && Math.round(value).toString();
  if (!val) {
    return "0";
  }
  if (val.length > 3) {
    let lastThree = val.substring(val.length - 3);
    let otherNumbers = val.substring(0, val.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  } else {
    return val;
  }
};

const integerToWordFormatter = (value) => {
  let intValue = Math.round(value).toString();
  if (intValue === "0") {
    return "0";
  }
  if (intValue.length >= 1 && intValue.length < 5) {
    return rupeeCommaFormatter(intValue);
  }
  if (intValue.length >= 5 && intValue.length <= 7) {
    return parseFloat(intValue / 100000).toFixed(2) + " Lakh";
  }
  if (intValue.length >= 7) {
    return parseFloat(intValue / 10000000).toFixed(2) + " Crore";
  }
};
const integerToWordFormatterLabels = (value) => {
  let intValue = value.toString();
  if (intValue === "0") {
    return "0";
  }
  if (intValue.length >= 3 && intValue.length < 5) {
    return intValue.slice(0, 1) + "K";
  }
  if (intValue.length >= 5 && intValue.length <= 7) {
    return parseFloat(intValue / 100000) + " Lakh";
  }
  if (intValue.length > 7) {
    return parseFloat(intValue / 10000000) + " Crore";
  }
};
const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

const emiCalculator = (loanAmount, interestRate, tenure) => {
  var numerator =
    ((loanAmount * interestRate) / 1200) *
    Math.pow(1 + interestRate / 1200, tenure);
  var denominator = Math.pow(1 + interestRate / 1200, tenure) - 1;
  return Math.floor(numerator / denominator)
    ? Math.floor(numerator / denominator)
    : 0;
};

const postFoirCalculator = (preFoir, emi, monthlyIncome) => {
  monthlyIncome = monthlyIncome ? monthlyIncome : 1;
  var value = (Number(preFoir) + Number(emi / monthlyIncome) * 100).toFixed(2);
  return value;
};

const numericInput = (value, callback) => {
  let reg = /^[0-9]*$/;
  if (reg.test(value)) {
    callback(value);
  }
};
export {
  alphaNumericRegex,
  getCookie,
  deleteCookie,
  goTo,
  setMaxDate,
  getDMY,
  getYMD,
  getScrollPosition,
  setHeaderFixed,
  checkBrowser,
  convertNumToStr,
  validateEmail,
  _updateFormValues,
  getDateString,
  rupeeCommaFormatter,
  integerToWordFormatter,
  integerToWordFormatterLabels,
  titleCase,
  emiCalculator,
  postFoirCalculator,
  numericInput,
};
