import React, { Component } from "react";
import { Link } from "react-router-dom";
import { InputLabelAnimation, DatePicker, CredoModal } from "../../../components";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import Address from "./Address";
import PartyDetails from "./PartyDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get } from "lodash";
import { getList } from "../../../store/listData.actions";
import {
  API_GET_CONSTITUTION,
  API_ADD_BUSINESS_DETAILS,
  API_GET_TAXPAYER_TYPE,
  API_GET_BUSINESS_ADDRESS_TYPE,
  API_POST_ADD_GST_MANUALLY,
} from "../../../utils/APIUrls";
import { POST } from "../../../utils/webAPI.service";
import { getDMY } from "../../../utils/utitlity";
import { loaderModal } from "../../../store/actions";
import GSTTurnover from "./GSTTurnover";
import { changeInputValue, addPartyNewRow, deletePartyRow, partyChangeHandler } from "./GstnAddress.actions";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import { postPartyDetails } from "./GSTAddress.config";

class GSTPanAddress extends Component {
  state = {
    addressDetailsRow: [{}],
    partyDetailsRow: [{}],
    maxDate: new Date(),
    gstin: "",
    addressList: [{}],
  };
  submitForm = (e) => {
    e.preventDefault();
    let { actions, history, match, businessDetailsData ,partyDetailsData } = this.props,
      { gstDetails, ...requiredData } = businessDetailsData;
    const applicationNumber = match.params.appNo;
    let dataToSend = {
      data: {
        ...requiredData,
        applicationNumber: match.params.appNo,
      },
    };
    actions.loaderModal(true);
    POST(API_ADD_BUSINESS_DETAILS, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          let partyDataToSend = {
            data: {
              applicationNumber: applicationNumber,
              relatedParty: partyDetailsData,
            },
          };
          postPartyDetails(partyDataToSend).then((data) => {
            if (data.status === "success") {
              history.push(`/Sales/${applicationNumber}/applicantGuide`);
            } else {
              alert(data.message);
            }
          });
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        this.setState({
          error: "Something went wrong please try again later.",
        });
        actions.loaderModal(false);
        throw err;
      });
    return null;
  };
  addAddress = () => {
    this.setState((prevState) => ({ addressList: [...prevState.addressList, {}] }));
  };
  deleteAddress = (addressIndex) => {
    this.setState((prevState) => ({ addressList: prevState.addressList.filter((_, index) => index !== addressIndex) }));
  };
  inputHandler = (e) => {
    let { actions } = this.props,
      { value, name } = e.target;
    actions.changeInputValue(value, name);
  };
  dateHandler = (name, value) => {
    let { actions } = this.props;
    actions.changeInputValue(value, name);
  };
  toggleModal = (type) => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen, modalType: type }));
  };
  editAddressHandler = (name, value, addressIndex) => {
    let { addressList } = this.state,
      addressListCopy = [...addressList];
    addressListCopy[addressIndex] = { ...addressListCopy[addressIndex], [name]: value };
    addressList = addressListCopy;
    this.setState({ addressList });
  };
  addGSTHandler = () => {
    let { addressList, gstin } = this.state,
      { actions, match } = this.props;
    let dataToSend = {
      data: {
        address: addressList,
        applicationNumber: match.params.appNo,
        gstId: null,
        gstin,
      },
    };
    actions.loaderModal(true);
    POST(API_POST_ADD_GST_MANUALLY, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ gstSubmit: true });
        } else {
          alert(data.message);
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);
        throw err;
      });
  };
  render() {
    let {
        businessConstitutionList,
        match,
        addressTypeList,
        businessDetailsData,
        actions,
        partyDetailsData,
      } = this.props,
      { maxDate, isModalOpen, gstin, addressList } = this.state,
      { addPartyNewRow, deletePartyRow, partyChangeHandler } = actions;
    return (
      <div className={"gstn-updated-wrapper"}>
        <div className={"content-box"}>
          <Link to={`/Sales/${match.params.appNo}/gstndetails`} className={"back-btn-link"}>
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <h4 className={"box-heading"}>Verify details and mark all addresses</h4>
          <form onSubmit={this.submitForm}>
            <div className="row">
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Legal Name"}
                  required={true}
                  type={"text"}
                  name={"legalName"}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.legalName}
                />
              </div>
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Trade Name"}
                  required={true}
                  type={"text"}
                  name={"businessName"}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.businessName}
                />
              </div>
              <div className={"col-md-4"}>
                <select
                  className={"select-box"}
                  id={"business-constitution-select"}
                  tabIndex={2}
                  required={true}
                  onChange={this.inputHandler}
                  value={businessDetailsData?.constitution}
                  name={"constitution"}
                >
                  {businessConstitutionList.map((x, y) => (
                    <option key={y + 1}>{x}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-4"}>
                <DatePicker
                  required={true}
                  name={"dateOfIncorporation"}
                  labelText={" Date of incorporation (DD/MM/YYYY)"}
                  defaultValue={null}
                  selected={
                    get(businessDetailsData, "dateOfIncorporation", "")
                      ? new Date(get(businessDetailsData, "dateOfIncorporation", "").split("-").reverse())
                      : null
                  }
                  onChange={(e) =>
                    this.dateHandler("dateOfIncorporation", getDMY(new Date(e.getTime()).toLocaleDateString()))
                  }
                />
              </div>
              <div className={"col-md-4"}>
                <DatePicker
                  required={true}
                  name={"businessCommencementDate"}
                  labelText={"Business Commencement Date (DD/MM/YYYY)"}
                  maxDate={new Date(maxDate)}
                  defaultValue={null}
                  selected={
                    get(businessDetailsData, "businessCommencement", "")
                      ? new Date(get(businessDetailsData, "businessCommencement", "").split("-").reverse())
                      : null
                  }
                  onChange={(e) =>
                    this.dateHandler("businessCommencement", getDMY(new Date(e)))
                  }
                />
              </div>
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Contact No."}
                  required={true}
                  maxLength={10}
                  name={"contactNumber"}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.contactNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className={"col-md-4"}>
                <InputLabelAnimation
                  labelText={"Email Id "}
                  required={true}
                  name={"email"}
                  onChange={this.inputHandler}
                  inputValue={businessDetailsData?.email}
                />
              </div>
            </div>

            <div>
              <div className={"content-box-inner"}>
                <div className={"address-box"}>
                  <div className={"row box-header"}>
                    <div className={"col-md-4"}>
                      <InputLabelAnimation
                        labelText={"Enter GST number "}
                        name={"gstin"}
                        onChange={(e) => this.setState({ gstin: e.target.value })}
                        inputValue={gstin}
                        mask={"** - ********** - ***"}
                      />
                    </div>
                    <div className={"col-md-4 "} onClick={() => this.toggleModal("Monthly")}>
                      <button className="GstButton f-12" type="button" disabled={gstin.length !== 21}>
                        Add GST Turnover - Monthly
                      </button>
                    </div>
                    <div className={"col-md-4"} onClick={() => this.toggleModal("Quarterly")}>
                      <button className="GstButton f-12" type="button" disabled={gstin.length !== 21}>
                        Add GST Turnover - Quarterly
                      </button>
                    </div>
                    <button type={"button"} className={"add-btn f-12"} onClick={this.addAddress}>
                      <Icon icon={ic_add} size={22} /> Add Address
                    </button>
                  </div>
                </div>
                {addressList?.map((addressObj, addressIndex) => (
                  <Address
                    addressTypeList={addressTypeList}
                    addressObject={addressObj}
                    changeHandler={(name, value) => this.editAddressHandler(name, value, addressIndex)}
                    index={addressIndex}
                    deleteAddress={() => this.deleteAddress(addressIndex)}
                  />
                ))}

                <div className="text-center mt-2">
                  <button onClick={this.addGSTHandler} className="save-btn mb-3" type="button">
                    Submit
                  </button>
                  {this.state.gstSubmit ? (
                    <div className="success-message-gst">
                      <Icon icon={ic_check_circle} size={22} className="mr-1" />
                      Submitted Successfully
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="content-box-inner ">
              <div className="address-box box-border">
                <div className={"box-header box-flex"}>
                  <span className="address-inner box-heading ">
                    Related Party Details
                    <span className="option">(optional)</span>
                  </span>

                  <button type={"button"} className={"btn-width add-btn "} onClick={addPartyNewRow}>
                    <Icon icon={ic_add} size={22} /> Add related party
                  </button>
                </div>
              </div>
              {partyDetailsData.map((partyDetail, index) => {
                return (
                  <PartyDetails
                    key={`party-details-${index}`}
                    partyDetail={partyDetail}
                    delete={this.clickOnPartyDelete}
                    rowIndex={index}
                    deletePartyRow={deletePartyRow}
                    changeHandler={(name, value) => partyChangeHandler(index, name, value)}
                  />
                );
              })}
            </div>
            <div className={"text-center"}>
              <button className={"save-btn"} type={"submit"}>
                Save & Next
              </button>
            </div>
          </form>
        </div>
        <CredoModal
          isOpen={isModalOpen}
          className={"red-flag__wrapper normal-curved-borders"}
          {...this.props}
          {...this.state}
          styles={{
            content: {
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
              border: "solid 1px #eeeeee",
              backgroundColor: "#ffffff",
              height: "95vh",
              width: "50%",
            },
          }}
          closeAction={this.toggleModal}
          RenderingComponent={GSTTurnover}
        />
      </div>
    );
  }
  componentDidMount() {
    let { actions } = this.props,
      { getList } = actions;
    getList("SET_BUSINESS_CONSTITUTION_LIST", API_GET_CONSTITUTION, "Select Business Constitution");
    getList("SET_TAXPAYER_TYPE_LIST", API_GET_TAXPAYER_TYPE, "Select Taxpayer Type");
    getList("SET_ADDRESS_TYPE", API_GET_BUSINESS_ADDRESS_TYPE, "Select Address Type");
  }
}

const mapStateToProps = ({ gstnAddressReducers, listData }) => ({
  businessConstitutionList: listData.businessConstitutionList,
  taxPayerTypeList: listData.taxPayerTypeList,
  addressTypeList: listData.addressTypeList,
  businessDetailsData: gstnAddressReducers.businessDetailsData,
  partyDetailsData: gstnAddressReducers.partyDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { getList, loaderModal, changeInputValue, addPartyNewRow, deletePartyRow, partyChangeHandler },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTPanAddress);
