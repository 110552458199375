import React, { Component } from "react";
import { connect } from "react-redux";
import BankingForm from "./BankingForm";
import { ProgressBar } from "../../../components";
import Icon from "react-icons-kit";
import { angleLeft } from "react-icons-kit/fa/angleLeft";
import { Link } from "react-router-dom";
import { loaderModal } from "../../../store/actions";
import { deleteFormBox } from "./banking.actions";
import {
  setBankList,
  setBankDetails,
  setInitialResponse,
  changeFileValues,
} from "./banking.actions";
import { bindActionCreators } from "redux";
import { GET, POST } from "../../../utils/webAPI.service";
import {
  API_UPLOAD_BANKING,
  API_GET_BANK_LIST,
  API_BANKING_REMOVE_ACCOUNT,
} from "../../../utils/APIUrls";
import { getBankDetails } from "../../Credit/CashFlow/cashflow.action";
import ContentLoader from "react-content-loader";
import { get } from "lodash";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { updateErrorMessage } from "../../../store/actions";
import { Helmet } from "react-helmet";

class Banking extends Component {
  formSubmit = async () => {
    const { history, actions, bankingDetails, match, bankNames } = this.props,
      { loaderModal, changeFileValues, updateErrorMessage } = actions,
      applicationNumber = get(match, "params.appNo", ""),
      headers = { "Content-Type": "multipart/form-data" },
      formBox = this.formBox.children;
    let errStatus = false;
    for (let i = 0; i < bankingDetails.length; i++) {
      const bankName = get(bankingDetails, `[${i}].bankName`, ""),
        ifscManual = get(bankingDetails, `[${i}].ifscManual`, ""),
        sanctionedLimit = get(bankingDetails, `[${i}].sanctionLimit`, 0),
        accountType = get(bankingDetails, `[${i}].accountType`, ""), // Write Current for default current account in quotation
        files = get(bankingDetails, `[${i}].files`, []);

      if (!bankName) {
        updateErrorMessage(
          "Please Select Bank for Account No." + (Number(i) + 1)
        );
        let element = formBox[i].querySelector(".bank-select-search");
        element.scrollIntoView();
        element.click();
        return false;
      }
      if (!accountType) {
        updateErrorMessage(
          "Please Select Account Type for Account No." + (Number(i) + 1)
        );
        let element = formBox[i].querySelector(".bank-select-search");
        element.scrollIntoView();
        element.click();
        return false;
      }
      if (
        files
          .map((el) => {
            return Boolean(el.fileName.fileName);
          })
          .includes(false)
      ) {
        updateErrorMessage(
          "Please Select All the files for Account No." + (Number(i) + 1)
        );
        return false;
      }
      let updatedAccountType = "";
      if (accountType === "Savings Account" || accountType === "SAVING") {
        updatedAccountType = "SAVING";
      }
      if (accountType === "Current Account" || accountType === "CURRENT") {
        updatedAccountType = "CURRENT";
      }
      if (accountType === "CC/OD Account" || accountType === "CREDIT_CARD") {
        updatedAccountType = "CREDIT_CARD";
      }
      if (!Number(sanctionedLimit) && accountType === "CC/OD Account") {
        updateErrorMessage("Please Enter Credit Sanctioned Limit.");
        formBox[i].querySelector('input[name="sanctionLimit"]').focus();
        return false;
      }
      let rows = formBox[i].querySelector(".files-wrapper .files-box").children;
      if (files.length) {
        for (let a = 0; a < files.length; a++) {
          let formData = new FormData(),
            file = rows[a].querySelector('input[type="file"]').files,
            isUploaded = get(files, `[${a}].isUploaded`, false);

          let isScannedPDF = get(files, `[${a}].isScannedPDF`, false);
          let isPrimaryBankAccount = get(
            files,
            `[${a}].isPrimaryBankAccount`,
            false
          );
          if (!isUploaded) {
            if (file.length) {
              for (let item in file) {
                if (file.hasOwnProperty(item)) {
                  formData.append("files", file[item]);
                } else {
                  break;
                }
              }
              loaderModal(true);
              await POST(
                API_UPLOAD_BANKING(
                  applicationNumber,
                  bankNames.find((opt) => opt.label === bankName).value,
                  updatedAccountType,
                  sanctionedLimit,
                  ifscManual,
                  isScannedPDF,
                  files[a].password,
                  isPrimaryBankAccount
                ),
                formData,
                { headers }
              )
                .then(({ data }) => {
                  loaderModal(false);
                  if (data.status === "success") {
                    changeFileValues("isUploaded", true, i, a);
                  } else {
                    updateErrorMessage(data.message);
                    errStatus = true;
                    return false;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  errStatus = true;
                  updateErrorMessage("Something went wrong on uploading files");
                  loaderModal(false);
                });
            } else {
              updateErrorMessage("Please Choose Files");
              return false;
            }
          }
        }
      } else {
        updateErrorMessage("Please Select Files First");
        return false;
      }
      if (bankingDetails.length === i) {
        errStatus = false;
      }
    }
    if (!errStatus) {
      history.push(`/Sales/${applicationNumber}/references`);
    }
  };

  fetchDetails = async () => {
    const { bankNames, history, actions, match } = this.props,
      {
        loaderModal,
        setBankList,
        getBankDetails,
        setBankDetails,
        setInitialResponse,
        updateErrorMessage,
      } = actions,
      applicationNumber = match.params.appNo;
    if (applicationNumber) {
      loaderModal(true);
      !bankNames.length &&
        GET(API_GET_BANK_LIST)
          .then(({ data }) => {
            if (data.status === "success") {
              let list = data.data.map((item) => ({
                value: item.code,
                label: item.bankName,
              }));
              setBankList(list);
            }
          })
          .catch((err) => {
            console.log(err);
            updateErrorMessage("Something went wrong please try again later.");
          });
      await getBankDetails(applicationNumber)
        .then(({ data, status }) => {
          if (status === "success") {
            let _data = data.map((item) => ({
              ...item,
              files: item.files
                ? item.files.map((item) => ({
                    isUploaded: true,
                    fileName: item,
                  }))
                : [
                    {
                      isUploaded: true,
                      fileName: {
                        documentId: null,
                        fileName: "No Preview present",
                      },
                    },
                  ],
            }));
            setBankDetails(_data);
          } else {
            setBankDetails([{ files: [{ isUploaded: false, fileName: "" }] }]);
          }
          setInitialResponse(true);
        })
        .catch((err) => {
          console.log(err);
          updateErrorMessage(
            "In bank Something went wrong please try again later."
          );
        });
      loaderModal(false);
    } else {
      history.push("/Sales/gstn");
    }
  };

  deleteBox = (i, accountNumber) => {
    let { deleteFormBox } = this.props.actions;
    let applicationNumber = this.props.match.params.appNo;
    if (accountNumber) {
      GET(API_BANKING_REMOVE_ACCOUNT(applicationNumber, accountNumber))
        .then(({ data }) => {
          if (data.status === "success") {
            this.fetchDetails();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      deleteFormBox(i);
    }
  };

  componentWillMount() {
    window.scroll(0, 0);
    this.props.actions.updateErrorMessage("");
  }

  componentDidMount() {
    this.fetchDetails();
  }

  componentWillUnmount() {
    const { initialResponse, actions } = this.props,
      { setInitialResponse } = actions;
    !initialResponse && setInitialResponse(false);
  }

  render() {
    let { initialResponse, bankingDetails, match } = this.props,
      applicationNumber = get(match, "params.appNo", "");
    return (
      <div className={"banking-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Banking</title>
        </Helmet>
        <ProgressBar active={4} {...this.props} />
        <div className={"content-box"}>
          <h4 className={"box-heading"}>
            Upload Statements in PDF Format for All Bank Accounts
          </h4>
          <Link
            to={`/Sales/${applicationNumber}/applicants`}
            className={"back-btn-link"}
          >
            <Icon icon={angleLeft} size={24} /> Back
          </Link>
          <div
            ref={(ref) => (this.formBox = ref)}
            className={"forms-box-wrapper"}
          >
            {initialResponse ? (
              bankingDetails.map((item, index) => (
                <BankingForm
                  key={index}
                  deleteBox={this.deleteBox}
                  index={index}
                />
              ))
            ) : (
              <div style={{ marginTop: "25px" }}>
                <ContentLoader height={150} type={"facebook"} />
              </div>
            )}
            {this.props.errorMessage ? (
              <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
            ) : null}
            <div className={"btns-box"}>
              <button
                onClick={this.formSubmit}
                type={"button"}
                className={"next-btn"}
              >
                Save & Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ listData, bankingReducers, data }) => ({
  initialResponse: bankingReducers.initialResponse,
  bankingDetails: bankingReducers.bankingDetails,
  bankNames: listData.bankNamesList,
  errorMessage: data.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setBankList,
      loaderModal,
      getBankDetails,
      setBankDetails,
      setInitialResponse,
      changeFileValues,
      updateErrorMessage,
      deleteFormBox,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Banking);
