import React, { Component } from "react";
import Icon from "react-icons-kit";
import { Tabs, Tab } from "react-bootstrap";
import BankAccountDetails from "./BankAccountDetails";
import axios from 'axios';
import { cloudUpload } from "react-icons-kit/icomoon/cloudUpload";
import { get } from "lodash";
import { filePdfO } from "react-icons-kit/fa/filePdfO";
import { fileExcel } from "react-icons-kit/icomoon/fileExcel";
import { getCookie } from "../../../utils/utitlity";
import { POST } from "../../../utils/webAPI.service";
import { LoaderModal } from "../../../components";
import fileDownload from "js-file-download";
import { API_BANK_STATEMENT_DOWNLOAD,API_BANKING_EXCEL_DOWNLOAD, } from "../../../utils/APIUrls";
class CashFlowAccountTabs extends Component {
  state = {
    loaderStatus: false,
  };
  handleTabChange = (accountNumber) => {
    const { actions, match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    if (accountNumber) {
      actions.getAccountBankingAnalysis(applicationNumber, accountNumber);
    } else {
      actions.getBankingAnalysis(applicationNumber);
    }
  };

  downloadBankingDocument =  (url, type) => {
    const { match } = this.props;
    const applicationNumber = get(match, "params.appNo", "");
    let token = getCookie("token");
    const headers = {
      tokenId: token,
    };
    axios({
      url: `${url(applicationNumber)}`,
      method: 'GET',
      headers: headers,
      responseType: 'blob', // Important
    }).then((res) => {
      type === 'bankingPdf'
        ? fileDownload(res.data, `Bank_Statement_${applicationNumber}.zip`)
        : fileDownload(
            res.data,
            `Bank_Transactions_${applicationNumber}.xlsx`
          );
    }).catch((err) => {
      throw err;
    });
  };

  uploadFiles = async (e) => {
    const {  match } = this.props;
    let applicationNumber = get(match, "params.appNo", "");
    var files = e.target.files;
    let token = getCookie("token");
    const headers = {
      "Content-Type": "multipart/form-data",
      tokenId: token,
    };
    let formData = new FormData();
    for (let file in files) {
      if (files.hasOwnProperty(file)) {
        formData.append("file", files[file]);
      } else {
        break;
      }
    }

    this.setState({ loaderStatus: true });
    await POST(`/updateCategorization/${applicationNumber}`, formData, {
      headers,
    })
      .then(({ data, status }) => {
        alert(data.message);
      })
      .catch((err) => {
        throw err;
      });
    this.setState({ loaderStatus: false });
  };
  render() {
    const mergedData = this.props.bankDetails;
    const { match } = this.props;
    const applicationNumber = get(match, "params.appNo", "");
    if (!mergedData) {
      return null;
    }
    return (
      <div className={"col-md-12"}>
        <LoaderModal status={this.state.loaderStatus} />
        <Tabs defaultActiveKey="merged" id="accounts-tabs">
          <Tab eventKey="merged" title={<div onClick={() => this.handleTabChange("")}>Merged Accounts</div>}>
            <div className={"row"}>
              <div className="child-space-between">
                <div className="entityName">{this.props.entityDetails ? this.props.entityDetails.entityName : ""}</div>
                <div className="actions-box">
                  <span className="icon-download-pdf" title="PDF Download">
                    <a 
                     onClick={() =>
                      this.downloadBankingDocument(
                        API_BANK_STATEMENT_DOWNLOAD,
                        'bankingPdf'       
                      )
                    }
                     download>
                      <Icon icon={filePdfO} size={22} />
                    </a>
                  </span>
                  <span className="icon-download" title="Excel Download">
                    <a 
                     onClick={() =>
                      this.downloadBankingDocument(
                        API_BANKING_EXCEL_DOWNLOAD,
                        'bankingExcel'
                      )
                    }
                     download
                    >
                      <Icon icon={fileExcel} size={22} />
                    </a>
                  </span>
                  <span className="icon-upload" title="Upload Excel">
                    <input type={"file"} style={{ zIndex: 0 }} multiple onChange={(e) => this.uploadFiles(e)} />
                    <Icon icon={cloudUpload} size={24} />
                  </span>
                </div>
              </div>

              {mergedData.map((bankData, index) => (
                <BankAccountDetails
                  download={false}
                  key={index}
                  data={bankData}
                  index={index + 1}
                  applicationNumber={applicationNumber}
                  style={{ margin: "10px" }}
                />
              ))}
            </div>
          </Tab>
          {mergedData.map((bankData, index) => {
            return (
              <Tab
                key={index}
                eventKey={bankData.accountNumber}
                title={
                  <div onClick={() => this.handleTabChange(bankData.accountNumber)}>
                    <label>{bankData.accountHolderName}</label>
                    <br />
                    <span>
                      {bankData.bankName} - {bankData.accountNumber}
                    </span>
                  </div>
                }
              >
                <div className={"row"}>
                  <BankAccountDetails data={bankData} className={"col-md-12"} {...this.props} />
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default CashFlowAccountTabs;
