import * as AdminConstant from "./admin.action.constant";
import { POST, GET } from "../../utils/webAPI.service";
import {
  API_UPDATE_ROLE_STATUS,
  API_ADD_USER_ADMIN_PANEL,
  API_FETCH_ADMIN_USER_DETAILS,
  API_SEND_MESSAGE_ADMIN_PANEL,
  API_UPDATE_ADMIN_USER_STATUS,
  API_GET_ALL_THRESHOLDS,
  API_GET_ACTIVE_LENDER_ROLES,
  API_GET_ALL_LENDERS_ROLES,
  API_GET_ROLE_STATUS,
  API_GET_HIERARCHY_LEVEL,
  API_GET_USER_BY_LEVEL
} from "../../utils/APIUrls";

const updateRoleStatus = (roleName, status) => dispatch => {
  var data = {
    roleName,
    status
  };
  return POST(API_UPDATE_ROLE_STATUS(), { data: data }).then(({ data, status }) => {
    if (data) {
      dispatch({
        type: AdminConstant.UPDATE_ROLE_STATUS,
        data: data.message
      });
      return data;
    } else {
      throw data;
    }
  });
};

const addUserDetails = data => dispatch => {
  return POST(API_ADD_USER_ADMIN_PANEL, data)
    .then(({ data, status }) => {
      if (data.data) {
        dispatch({ type: AdminConstant.Add_USER_ADMIN_PANEL, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const fetchUserDetails = pageNumber => dispatch => {
  let data = {
    data: {
      pageNumber: pageNumber
    }
  };
  return POST(API_FETCH_ADMIN_USER_DETAILS, data).then(({ data, status }) => {
    if (data.status === "success") {
      dispatch({ type: AdminConstant.FETCH_USER_DETAILS, data: data.data });
    } else {
      throw data;
    }
  });
};
const sendMessage = data => dispatch => {
  return POST(API_SEND_MESSAGE_ADMIN_PANEL, data).then(({ data, status }) => {
    if (data.status === "success") {
      dispatch({ type: AdminConstant.SEND_MESSAGE, data: data.data });
      return data;
    } else {
      return data;
    }
  });
};
const updateUserStatus = data => dispatch => {
  return POST(API_UPDATE_ADMIN_USER_STATUS, data).then(({ data, status }) => {
    if (data) {
      return data;
    }
  });
};
  
const getAllThresholds = () => dispatch => {
  return GET(API_GET_ALL_THRESHOLDS)
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_ALL_THRESHOLDS, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getActiveLenderRoles = () => dispatch => {
  return GET(API_GET_ACTIVE_LENDER_ROLES)
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_ACTIVE_LENDER_ROLES, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getAllLenderRoles = () => dispatch => {
  return GET(API_GET_ALL_LENDERS_ROLES)
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_ALL_LENDERS_ROLES, data: data.data });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getRoleStatus = () => dispatch => {
  return GET(API_GET_ROLE_STATUS)
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_ROLE_STATUS, data: data.data.responseList });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getHierarchyLevel  = () => dispatch => {
  return GET(API_GET_HIERARCHY_LEVEL)
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_HIERARCHY_LEVEL, data: data.data.responseList });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
const getUserByLevel   = (hierarchyLevel) => dispatch => {
  return GET(API_GET_USER_BY_LEVEL(hierarchyLevel))
    .then(({ data }) => {
      if (data.data) {
        return dispatch({ type: AdminConstant.GET_USER_BY_LEVEL, data: data.data.responseList });
      }
      return data;
    })
    .catch(err => {
      throw err;
    });
};
export {
  updateRoleStatus,
  addUserDetails,
  fetchUserDetails,
  sendMessage,
  updateUserStatus,
  getAllThresholds,
  getActiveLenderRoles,
  getAllLenderRoles,
  getRoleStatus,
  getHierarchyLevel ,
  getUserByLevel 
};
