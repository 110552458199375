import React, { Component, Fragment } from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import { ic_share } from "react-icons-kit/md/ic_share";
import { share } from "react-icons-kit/entypo/share";
import { ic_check_circle } from "react-icons-kit/md/ic_check_circle";
import { POST } from "../../../utils/webAPI.service";
import { API_GST_OTP_REQUEST, API_VALIDATE_GST_OTP } from "../../../utils/APIUrls";
import { CredoModal } from "../../../components";
import ShareOtpLink from "./shareOtpLink";

export default class gstException extends Component {
  state = {
    OtpStateText: "OTP has been sent.",
    exceptionText: "OTP not submitted, Username is missing"
  };
  inputHandler = (event, index) => {
    const name = event.target.name,
      value = event.target.value;
    this.setState({ [name]: value });
    this.props.actions.editGSTExceptionData(name, value, index);
  };
  generateOtp = async (gstin, gstUsername, index) => {
    const { actions, match } = this.props,
      { loaderModal } = actions,
      { appNo } = match.params,
      dataToSend = {
        data: {
          applicationNumber: appNo,
          gstUsername,
          gstin,
          isDataFetched: true
        }
      };
    loaderModal(true);
    await POST(API_GST_OTP_REQUEST, dataToSend)
      .then(({ data }) => {
        if (data.status === "success") {
          this.setState({ [index + "isOtpTriggered"]: true });
        } else {
          alert(data.message);
        }
      })
      .catch(err => {
        throw err;
      });
    loaderModal(false);
  };
  submitOtp = async gstNumber => {
    let { actions, match } = this.props,
      { loaderModal } = actions,
      { appNo } = match.params,
      { otp } = this.state;
    if (appNo && gstNumber && otp) {
      let data = { applicationNumber: appNo, gstin: gstNumber, otp };
      loaderModal(true);
      await POST(API_VALIDATE_GST_OTP, { data })
        .then(({ data }) => {
          if (data.status === "success") {
            this.setState({
              isOtpSubmitted: true
            });
          } else {
            alert(data.message);
          }
        })
        .catch(err => {
          throw err;
        });
      loaderModal(false);
    }
  };

  editGstDetailsHandler = index => {
    this.setState({ [index]: !this.state[index] });
  };
  toggleModal = (gstin, gstUsername) => {
    this.setState({ isModalOpen: !this.state.isModalOpen, gstin, gstUsername });
  };
  render() {
    let { OtpStateText, exceptionText, isModalOpen } = this.state,
      { gstExceptionData } = this.props.userExceptionData;
    return (
      <Fragment>
        {gstExceptionData ? (
          <div className="card__wrapper mt-2">
            <div className="heading">GST Exceptions</div>
            {gstExceptionData.map((el, index) => (
              <div className="card__wrapper mt-2" key={index}>
                <div className="Details_div">
                  <div>
                    <div className="label_heading">GST Number</div>
                    {this.state[index + "gst"] ? (
                      <input
                        placeholder="GST Number"
                        name="gstin"
                        onChange={e => this.inputHandler(e, index)}
                        type="text"
                        value={el.gstin}
                        className="value input-field"
                      />
                    ) : (
                      <div className="value">{el.gstin} </div>
                    )}
                  </div>
                  <div>
                    <div className="label_heading">GST Username</div>
                    {this.state[index + "gst"] ? (
                      <input
                        placeholder="GST Username"
                        name="usernmae"
                        onChange={e => this.inputHandler(e, index)}
                        type="text"
                        value={el.usernmae}
                        className="value input-field"
                      />
                    ) : (
                      <div className="value">{el.usernmae}</div>
                    )}
                  </div>
                  <div>
                    <div className="label_heading">Exception</div>
                    <div className="value">{exceptionText}</div>
                  </div>
                  <div className="btn_div">
                    {this.state.isOtpSubmitted || this.state.isOtpTriggered ? (
                      <div className="otp-sent mt-2 container">{OtpStateText}</div>
                    ) : (
                      <button
                        className={"button_Generic blue-btn bold-btn"}
                        onClick={() => this.generateOtp(el.gstin, el.usernmae, index)}
                        disabled={!(el.usernmae && !this.state[index + "gst"]) || this.state[index + "isOtpTriggered"]}
                      >
                        Trigger OTP
                      </button>
                    )}
                  </div>
                  <div className="circle">
                    <Icon className="icons" icon={ic_share} onClick={() => this.toggleModal(el.gstin, el.usernmae)} />
                  </div>
                  {this.state[index + "isOtpTriggered"] ? null : (
                    <div className="circle">
                      {this.state[index + "gst"] ? (
                        <Icon
                          onClick={e => this.editGstDetailsHandler(index + "gst")}
                          className="icons"
                          icon={ic_check_circle}
                        />
                      ) : (
                        <Icon
                          onClick={e => this.editGstDetailsHandler(index + "gst")}
                          className="icons"
                          icon={ic_edit}
                        />
                      )}
                    </div>
                  )}
                </div>
                {this.state[index + "isOtpTriggered"] ? (
                  <div className="otp-triggered__wrapper">
                    <div className="otp-triggered">
                      <div className="otp-triggered-heading">Enter your 6 digit OTP</div>
                      <div className="enter-otp mb-3">
                        <input
                          id="otp"
                          type="text"
                          placeholder="Enter OTP here"
                          maxLength={6}
                          name={"otp"}
                          onChange={this.inputHandler}
                        />
                      </div>
                      <div className="otp-not-received mb-3">
                        Didn’t get your OTP? <button onClick={this.generateOtp}> Click here to resend.</button>
                      </div>
                      <button
                        onClick={() => this.submitOtp(el.gstin)}
                        className="button_Generic blue-btn bold-btn mt-3 mb-3"
                        type="button"
                      >
                        Submit OTP
                      </button>
                      <div className="general-text mt-2 mb-3">Or</div>
                      <button className="button_Generic white-btn bold-btn mt-3 mb-3" type="button" onClick={() => this.toggleModal(el.gstin, el.usernmae)} >
                        <div className="circle ml-2">
                          <Icon className="icons green-icon" icon={share} />
                          Share OTP Link
                        </div>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
        <CredoModal
          isOpen={isModalOpen}
          {...this.props}
          gstUsername={this.state.gstUsername}
          gstin={this.state.gstin}
          styles={{
            content: {
              width: "700px"
            }
          }}
          title={"Share Link"}
          closeAction={() => this.toggleModal("")}
          RenderingComponent={ShareOtpLink}
        />
      </Fragment>
    );
  }
}
