import React, {Component} from 'react';
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import {goTo} from "../../utils/utitlity";

class CreditNavs extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    clickHandle=(e,name)=>{
        let { match} = this.props;
        const {appNo} = match.params;
        
        if(e.ctrlKey){
            if((e.ctrlKey || e.metaKey  )&& name==="overview" ){
                window.open(`/Credit/${appNo}/overview`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="business_risk" ){
                window.open(`/Credit/${appNo}/business_risk`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="cash_flow" ){
                window.open(`/Credit/${appNo}/cash_flow`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="credit_behaviour" ){
                window.open(`/Credit/${appNo}/credit_behaviour`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="flag" ){
                window.open(`/Credit/${appNo}/flag`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="credit_capacity" ){
                window.open(`/Credit/${appNo}/credit_capacity`)
    
            }
            else if((e.ctrlKey || e.metaKey )&& name==="final_decision" ){
                window.open(`/Credit/${appNo}/final_decision`)
    
            }

        }
        else if(e.button===0){
            if( name==="overview" ){
                goTo(`/Credit/${appNo}/overview`)
    
            }
            else if( name==="business_risk" ){
                goTo(`/Credit/${appNo}/business_risk`)
    
            }
            else if( name==="cash_flow" ){
                goTo(`/Credit/${appNo}/cash_flow`)
    
            }
            else if( name==="credit_behaviour" ){
                goTo(`/Credit/${appNo}/credit_behaviour`)
    
            }
            else if( name==="flag" ){
                goTo(`/Credit/${appNo}/flag`)
    
            }
            else if( name==="credit_capacity" ){
                goTo(`/Credit/${appNo}/credit_capacity`)
    
            }
            else if( name==="final_decision" ){
                goTo(`/Credit/${appNo}/final_decision`)
    
            }

        }
        

        
        

    }

    render() {
        let {active, match} = this.props;
        const {appNo} = match.params;
        return (
            <section className={'credit-navs-wrapper'}>
                <Navbar>
                    <Navbar.Header>
                        <Navbar.Toggle/>
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav className="credit-navs" activeKey={active}>
                            <NavItem eventKey="overview" onClick={(e) =>this.clickHandle(e,"overview")}>Overview</NavItem>
                            <NavItem eventKey="business_risk" onClick={(e) =>this.clickHandle(e,"business_risk")}>Business Risk</NavItem>
                            <NavItem eventKey="cash_flow" onClick={(e) => this.clickHandle(e,"cash_flow")}>Cash Flow</NavItem>
                            <NavItem eventKey="credit_behaviour" onClick={(e) => this.clickHandle(e,"credit_behaviour")}>Credit Behaviour</NavItem>
                            <NavItem eventKey="flag" onClick={(e) => this.clickHandle(e,"flag")}>Flag</NavItem>
                            <NavItem eventKey="credit_capacity" onClick={(e) => this.clickHandle(e,"credit_capacity")}>Credit Capacity</NavItem>
                            <NavItem eventKey="final_decision" onClick={(e) => this.clickHandle(e,"final_decision")}>Final Decision</NavItem>
                            
                             {/* <NavItem eventKey="business_risk" onClick={this.clickHandle}>Business Risk</NavItem> */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </section>
        );
    }
}

CreditNavs.defaultProps = {
    active: ''
};

export default CreditNavs;
