import React, { Component } from "react";
import ReactTable from "react-table";
import { ic_add } from "react-icons-kit/md/ic_add";
import Icon from "react-icons-kit";
import * as config from "./GSTAddress.config";
import { POST } from "../../../utils/webAPI.service";
import { InputLabelAnimation } from "../../../components";

import { API_POST_ADD_GST_TURNOVER_DATA } from "../../../utils/APIUrls";
import { rupeeCommaFormatter } from "../../../utils/utitlity";
class GSTTurnover extends Component {
  state = {
    monthlyData: [
      {
        month: this.props.modalType === "Quarterly" ? "Jan-Mar" : "Jan",
        year: "2015",
        value: "",
        category: this.props.modalType,
      },
    ],
  };
  addRow = () => {
    this.setState((prevState) => ({
      monthlyData: [
        ...prevState.monthlyData,
        {
          month: this.props.modalType === "Quarterly" ? "Jan-Mar" : "Jan",
          value: "",
          year: "2015",
          category: this.props.modalType,
        },
      ],
    }));
  };
  addGstData = () => {
    let { actions, match, gstin , closeAction } = this.props,
      applicationNumber = match.params.appNo,
      { monthlyData } = this.state;
    actions.loaderModal(true);
    let data = {
      data: {
        applicationNumber,
        gstin: gstin.split(" - ").join(""),
        monthlyData: [
          ...monthlyData.map((el) => {
            return {
              category: el.category,
              month: `${el.month.split("-")[1]}-${el.year}`,
              txnCount: 0,
              value: Number(el.value),
            };
          }),
        ],
      },
    };
    POST(API_POST_ADD_GST_TURNOVER_DATA, data)
      .then(({ data }) => {
        if (data.status==="success") {
          closeAction()
        }
        else{
          alert(data.message)
        }
        actions.loaderModal(false);
      })
      .catch((err) => {
        actions.loaderModal(false);

        throw err;
      });
  };
  inputHandler = (e, index) => {
    let name = e.target.name,
      value = e.target.value;
    let monthlyDataCopy = this.state.monthlyData.slice(),
      monthlyDataCopyIndex = monthlyDataCopy[index];
    monthlyDataCopyIndex[name] = value;
    monthlyDataCopy[index] = monthlyDataCopyIndex;
    if (name === "value") {
      let reg = /^[0-9]*$/;
      if (reg.test(value)) {
        this.setState({ monthlyData: monthlyDataCopy });
      }
    } else {
      this.setState({ monthlyData: monthlyDataCopy });
    }
  };
  _GSTTable = [
    {
      Header: "S.No",
      accessor: "SNo",
      width: 100,
      Cell: (props) => {
        return <div className="text-center">{props.index + 1}</div>;
      },
    },
    {
      Header: "Month",
      accessor: "Month",
      Cell: ({ index, original }) => (
        <select
          className={"select-box_sub"}
          onChange={(e) => this.inputHandler(e, index)}
          value={original.month}
          name="month"
        >
          {this.props.modalType === "Quarterly"
            ? config.quarterlyMonths.map((el, index) => <option key={index}>{el}</option>)
            : config.monthlyMonths.map((el, index) => <option key={index}>{el}</option>)}
        </select>
      ),
    },
    {
      Header: "Year",
      accessor: "Year",
      Cell: ({ index, original }) => (
        <select
          className={"select-box_sub"}
          onChange={(e) => this.inputHandler(e, index)}
          value={original.year}
          name="year"
        >
          {config.years.map((el, index) => (
            <option key={index}>{el}</option>
          ))}
        </select>
      ),
    },
    {
      Header: "Sales Reported (in ₹)",
      accessor: "SalesReported ",
      width: 300,
      Cell: ({ original, index }) => (
        <div className="input-box_sub">
          <InputLabelAnimation
            labelText={"Enter Sales"}
            required={true}
            name={"value"}
            inputValue={original.value}
            onChange={(e) => this.inputHandler(e, index)}
          />
        </div>
      ),
    },
  ];
  render() {
    let { monthlyData } = this.state;
    let sum = 0;
    monthlyData.forEach((el) => {
      sum = sum + Number(el.value);
    });
    return (
      <div className="manually_gst-wrapper">
        <h1 className="heading">Enter GST Turnover Manually</h1>
        <div>
          <div>
            <div className="btn-flex">
              <span className="span">{monthlyData[0].category}</span>
              <button type={"button"} className={"add-btn"} onClick={this.addRow}>
                <Icon icon={ic_add} size={22} /> Add {monthlyData[0].category === "Quarterly" ? "Quarter" : "Month"}
              </button>
            </div>
          </div>
        </div>
        <div className="monthly-table ">
          <ReactTable data={monthlyData} columns={this._GSTTable} minRows={0} showPagination={false} />
        </div>
        <div className="total">
          <span className="innerspan_main">Total</span>
          <span className="innerspan_sub">{rupeeCommaFormatter(sum)}</span>
        </div>

        <div className={"text-center"}>
          <button className={"save-btn"} type={"submit"} onClick={this.addGstData}>
            Save & Next
          </button>
        </div>
      </div>
    );
  }
}

export default GSTTurnover;
