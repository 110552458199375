const SET_USER_ADMIN_DATA = Symbol("SetDocumentData"),
  GET_USER_ROLE_ID = Symbol("GetUserRoleID"),
  CHANGE_ADDRESS_BOX_ADDRESS_VALUE = Symbol("ChangeAddressBoxAddressValue"),
  SET_SEARCH_CRITERIA = Symbol("setSearchCriteria"),
  SET_PAGE_NUMBER = "SET_PAGE_NUMBER",
  SET_CPO_ADMIN_DATA = "SET_PAGE_NUMBER",
  RESET_REDUX_STATE = Symbol("ResetReduxState");

export {
  SET_USER_ADMIN_DATA,
  SET_SEARCH_CRITERIA,
  SET_PAGE_NUMBER,
  SET_CPO_ADMIN_DATA,
  RESET_REDUX_STATE,
  GET_USER_ROLE_ID,
  CHANGE_ADDRESS_BOX_ADDRESS_VALUE,
};
