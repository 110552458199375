import React, { Component } from "react";
import Icon from "react-icons-kit";
import { ic_delete_forever } from "react-icons-kit/md/ic_delete_forever";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { changeRowValues } from "./applicantGuide.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";
import { InputLabelAnimation } from "../../../components";
import { updateErrorMessage } from "../../../store/actions";

class EntityRow extends Component {
  inputHandler = (e, type = "str") => {
    let { actions, index } = this.props,
      { changeRowValues, updateErrorMessage } = actions,
      value = e.target.value,
      name = e.target.name,
      reg = /^(\d+)?([.]?\d{0,2})?$/;
    switch (type) {
      case "num":
        if (reg.test(value)) {
          if (value <= 100) {
            changeRowValues(name, value, index);
          }
        }
        break;
      default:
        changeRowValues(name, value, index);
    }
    updateErrorMessage("");
  };

  selectHandler = i => {
    let { actions, constitutionRelation, index } = this.props,
      { changeRowValues, updateErrorMessage } = actions,
      value = constitutionRelation[i - 1];
    changeRowValues("relation", value, index);
    updateErrorMessage("");
  };

  render() {
    let { index, name, constitutionRelation, shareholdingDetails } = this.props;
    return (
      <div className={"inputs-row"}>
        <label>
          {name === "app"
            ? index > 1
              ? `Applicant ${index}`
              : "Primary Applicant"
            : `Co-applicant ${index}`}
        </label>
        <InputLabelAnimation
          labelText={"First Name"}
          name={"firstName"}
          onChange={this.inputHandler}
          tabIndex={index * 5 + 1}
          required={true}
          inputValue={get(
            shareholdingDetails,
            `shareholdingList[${index}].firstName`,
            ""
          )}
        />
        <InputLabelAnimation
          labelText={"Middle Name"}
          name={"middleName"}
          onChange={this.inputHandler}
          tabIndex={index * 5 + 2}
          inputValue={get(
            shareholdingDetails,
            `shareholdingList[${index}].middleName`,
            ""
          )}
        />
        <InputLabelAnimation
          labelText={"Last Name"}
          name={"lastName"}
          onChange={this.inputHandler}
          tabIndex={index * 5 + 3}
          required={true}
          inputValue={get(
            shareholdingDetails,
            `shareholdingList[${index}].lastName`,
            ""
          )}
        />
        <DropdownButton
          tabIndex={index * 5 + 4}
          onSelect={this.selectHandler}
          title={get(
            shareholdingDetails,
            `shareholdingList[${index}].relation`,
            "Select Relation"
          )}
          id={"relation-select"}
          className={"select-box"}
          name={"relation"}
        >
          {constitutionRelation.map((item, index) => {
            return (
              <MenuItem
                title={item}
                key={`relation-${index + 1}`}
                eventKey={index + 1}
              >
                {item}
              </MenuItem>
            );
          })}
        </DropdownButton>
        <InputLabelAnimation
          labelText={"Shareholding %"}
          name={"shareholding"}
          tabIndex={index * 5 + 5}
          onChange={e => this.inputHandler(e, "num")}
          required={true}
          inputValue={get(
            shareholdingDetails,
            `shareholdingList[${index}].shareholding`,
            ""
          )}
        />
        <div>
          {index > 0 ? (
            <span
              onClick={() => this.props.deleteRow(index)}
              className={"remove-icon"}
            >
              <Icon icon={ic_delete_forever} size={26} />
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ applicantGuideReducers }) => ({
  shareholdingDetails: applicantGuideReducers.shareholdingDetails,
  constitutionRelation: applicantGuideReducers.constitutionRelations
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      changeRowValues,
      updateErrorMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityRow);
