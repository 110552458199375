import * as AdminConstant from "./admin.action.constant";

const initialState = {
  updateRoleStatusData: [],
  userDetails: {},
  sendMessageResponse: [],
  allThresholdData: [],
  activeLenderRolesData: [],
  allLenderRolesData: [],
  roleStatusData:[],
  hierarchyData:[],
  userByLevelData:[]
};

export const AdminResponse = (state = initialState, action) => {
  const { data, type } = action;
  switch (type) {
    case AdminConstant.UPDATE_ROLE_STATUS:
      return { ...state, updateRoleStatusData: data };
    case AdminConstant.FETCH_USER_DETAILS:
      return { ...state, userDetails: data };
    case AdminConstant.SEND_MESSAGE:
      return { ...state, sendMessageResponse: data };
    case AdminConstant.GET_ALL_THRESHOLDS:
      return { ...state, allThresholdData: data };
    case AdminConstant.GET_ACTIVE_LENDER_ROLES:
      return { ...state, activeLenderRolesData: data };
    case AdminConstant.GET_ALL_LENDERS_ROLES:
      return { ...state, allLenderRolesData: data };
    case AdminConstant.GET_ROLE_STATUS:
      return { ...state, roleStatusData: data };
    case AdminConstant.GET_HIERARCHY_LEVEL:
      return { ...state, hierarchyData: data };
    case AdminConstant.GET_USER_BY_LEVEL:
      return { ...state, hierarchyData: data };
    default:
      return state;
  }
};
