const initialState = {
  loanTypeList: [],
  repaymentTypeList: [],
  loanUseList: [],
  businessType: [],
  primaryIndustryList: [],
  secondaryIndustryList: [],
  addressTypeList: [],
  genderList: [],
  ownershipList: [],
  religionList: [],
  qualificationList: [],
  maritalStatusList: [],
  categoryList: [],
  bankNamesList: [],
  businessConstitutionList: [],
  taxPayerTypeList: [],
  businessNatureList: [],
  statesList: [],
  residenceStatusList: [],
  relationshipList: [],
  referenceRelations: [],
  desicionList: [],
  relationList: [],
  accountTypeList: [],
  creditCycleList: [],
  purchaseDiscountList: [],
  interestPaidList: [],
  growthRequirementsList:[],
};

export const listData = (state = initialState, action) => {
  const { list, type } = action;
  switch (type) {
    case "SET_LOAN_TYPE":
      return {
        ...state,
        loanTypeList: list,
      };
    case "SET_REPAYMENT_TYPE":
      return {
        ...state,
        repaymentTypeList: list,
      };
    case "SET_LOAN_USE":
      return {
        ...state,
        loanUseList: list,
      };
    case "SET_BUSINESS_TYPE":
      return {
        ...state,
        businessType: list,
      };
    case "SET_PRIMARY_INDUSTRIES":
      return {
        ...state,
        primaryIndustryList: list,
      };
    case "SET_SECONDARY_INDUSTRIES":
      return {
        ...state,
        secondaryIndustryList: list,
      };
    case "SET_ADDRESS_TYPE":
      return {
        ...state,
        addressTypeList: list,
      };
    case "SET_GENDER_LIST":
      return {
        ...state,
        genderList: list,
      };
    case "SET_OWNERSHIP_LIST":
      return {
        ...state,
        ownershipList: list,
      };
    case "SET_MARITAL_STATUS":
      return {
        ...state,
        maritalStatusList: list,
      };
    case "SET_CATEGORY_LIST":
      return {
        ...state,
        categoryList: list,
      };
    case "SET_RELIGION_LIST":
      return {
        ...state,
        religionList: list,
      };
    case "SET_QUALIFICATION_LIST":
      return {
        ...state,
        qualificationList: list,
      };
    case "SET_BANK_NAMES_LIST":
      return {
        ...state,
        bankNamesList: list,
      };
    case "SET_BUSINESS_CONSTITUTION_LIST":
      return {
        ...state,
        businessConstitutionList: list,
      };
    case "SET_TAXPAYER_TYPE_LIST":
      return {
        ...state,
        taxPayerTypeList: list,
      };
    case "SET_BUSINESS_NATURE_LIST":
      return {
        ...state,
        businessNatureList: list,
      };
    case "SET_STATES_LIST":
      return {
        ...state,
        statesList: list,
      };
    case "SET_RESIDENCE_STATUS":
      return {
        ...state,
        residenceStatusList: list,
      };
    case "SET_RELATIONSHIP_LIST":
      return {
        ...state,
        relationshipList: list,
      };
    case "SET_REFERENCE_RELATIONSHIP_LIST":
      return {
        ...state,
        referenceRelations: list,
      };
    case "SET_FINAL_DECISION":
      return {
        ...state,
        desicionList: list,
      };
    case "SET_OWNERSHIP_TYPE":
      return {
        ...state,
        ownershipList: list,
      };
    case "SET_RELATION_LIST":
      return {
        ...state,
        relationList: list,
      };
    case "SET_BUSINESS_NATURE":
      return {
        ...state,
        businessNatureList: list,
      };
    case "SET_ACCOUNT_TYPE":
      return {
        ...state,
        accountTypeList: list,
      };
    case "SET_CREDIT_CYCLE_LIST":
      return {
        ...state,
        creditCycleList: list,
      };
    case "SET_PURCHASE_DISCOUNT_LIST":
      return {
        ...state,
        purchaseDiscountList: list,
      };
    case "SET_INTEREST_PAID_LIST":
      return {
        ...state,
        interestPaidList: list,
      };
    case "SET_GROWTH_REQUIREMENTS_LIST":
      return {
        ...state,
        growthRequirementsList: list,
      };

    default:
      return state;
  }
};
