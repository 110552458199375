import {API_GET_PROFILE} from '../../../utils/APIUrls';
import {GET} from "../../../utils/webAPI.service";
import * as ApplicantsConstants from "./applicants.actions.constant";

const getProfile = (applicationNumber) => {
    return (dispatch) => {
        return GET(API_GET_PROFILE(applicationNumber))
            .then(({data}) => {
                if (data.status === 'success') {
                    dispatch({type: ApplicantsConstants.SET_APPLICANTS_PROFILES, data: data.data.profileList})
                }
                return data;
            })
            .catch(err => {
                throw err
            })
    };
};

const changeInputValue = (val, name, index) => ({
    type: ApplicantsConstants.CHANGE_VALUE,
    val,
    name,
    index
});

const resetApplicantsState = () => ({type: ApplicantsConstants.RESET_REDUX_STATE});

export {
    getProfile,
    changeInputValue,
    resetApplicantsState
}
