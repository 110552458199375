import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getVerifyOTP, sendOTPVerify } from "./GSTNDetails.actions";
import ContentLoader from "react-content-loader";
import { loaderModal } from "../../../store/actions";
class validateOTP extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      gstin: null,
      inputValue: null,
      submitOTP: false
    };
  }
  componentDidMount = () => {
    this.fetchDetails();
  };
  updateInputValue = evt => {
    this.setState({
      inputValue: evt.target.value
    });
  };

  fetchDetails = async () => {
    const uuid = this.props.match.params.uuid;
    let { actions } = this.props,
      { getVerifyOTP } = actions;
    await getVerifyOTP(uuid)
      .then(({ data, status }) => {
        if (status === "success") {
          this.setState({
            gstin: data.gstin
          });
        } else {
        }
      })
      .catch(err => {
      });
  };

  submit = async e => {
    e.preventDefault();
    const uuid = this.props.match.params.uuid;
    let { actions } = this.props,
      { sendOTPVerify, loaderModal } = actions;
    loaderModal(true);
    await sendOTPVerify(uuid, this.state.inputValue)
      .then(({ data, status }) => {
        loaderModal(false);

        if (data.status === "success") {
          this.setState({ submitOTP: true, otpStatus: true });
        } else {
          this.setState({ submitOTP: true, otpStatus: false });
        }
      })
      .catch(err => {
        console.log(err);
       
      });
  };
  render() {
    var validateOTPJSX = null;
    if (this.state.submitOTP && this.state.otpStatus) {
      validateOTPJSX = (
        <div
          className="validateOTPWrapper container"
          style={{ fontSize: "2.5rem", color: "#4687f4" }}
        >
          Thanks , OTP has been Validated
        </div>
      );
    } else {
      validateOTPJSX = (
        <div
          className="validateOTPWrapper container cardWrapper"
          style={{ fontSize: "2.5rem", color: "#1d1d1d" }}
        >
          Sorry, Your OTP is incorrect
        </div>
      );
    }
    return (
      <Fragment>
        {this.state.gstin ? (
          this.state.submitOTP ? (
            validateOTPJSX       
          ) : (
            <Fragment>
              <form onSubmit={this.submit}>
                <div className="validateOTPWrapper">
                  <p className="title">Enter your 6 digit OTP</p>
                  <br />
                  <p>
                    Thank you for initiating request for GST based Loan with us.{" "}
                    <br />
                    Please note that GST OTP is only used to assess your loan
                    eligibility and utmost confidentiality is maintained.
                  </p>
                </div>
                <div className="cardWrapper">
                  <div className="boldHeading">GST Number</div>
                  <p className="subheading">{this.state.gstin}</p>
                  <input
                    type="text"
                    name={"otp"}
                    id="otp"
                    maxLength={6}
                    required={true}
                    onChange={this.updateInputValue}
                  />
                  <button className="btn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </Fragment>
          )
        ) : (
          <div className="validateOTPWrapper">
            <ContentLoader type={"facebook"} />
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = ({ gstnDetailsReducers }) => ({
  gstin: gstnDetailsReducers.gstin
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getVerifyOTP,
      sendOTPVerify,
      loaderModal
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(validateOTP);
