import React, { Component } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { NavLink } from "react-router-dom";
import Icon from "react-icons-kit";
import { calendar } from "react-icons-kit/fa/calendar";
import { caretDown } from "react-icons-kit/fa/caretDown";
import { ic_add } from "react-icons-kit/md/ic_add";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { POST } from "../../../utils/webAPI.service";
import { connect } from "react-redux";
import { getYMD, getDateString } from "../../../utils/utitlity";
import { bindActionCreators } from "redux";
import { loaderModal } from "../../../store/actions";
import { dataTableHeaders, mobileTableHeaders } from "./dashboard.config";
import { API_GET_SALES_ALL_APPLICATIONS } from "../../../utils/APIUrls";
import { setSearchFilters, setSearchFilterStatus } from "./dashboard.actions";
import { changeValue as headerChangeValue, updateFilterHandler } from "../../../components/Header/header.action";
import { get } from "lodash";
import { Helmet } from "react-helmet";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: getDateString(new Date()),
      endDate: getDateString(new Date()),
      activeTabApplicant: "All",
      currentPage: 1,
      totalPages: 1,
      applicationsData: [],
      prevBtn: true,
      nextBtn: true,
      allApplicationCount: 0,
      inCompletedApplicationCount: 0,
      pendingWithCreditApplicationCount: 0,
      closedApplicationsCount: 0,
      submittedApplicationCount: 0,
    };
  }

  handleEvent = (event, picker) => {
    let { setSearchFilterStatus } = this.props.actions,
      startdate = new Date(picker.startDate),
      enddate = new Date(picker.endDate);
    this.setState(
      {
        startDate: getDateString(startdate),
        endDate: getDateString(enddate),
      },
      async () => {
        await setSearchFilterStatus("byDate");
        this.fetchApplications();
      }
    );
  };

  applicantTabToggle = async (item) => {
    let { setSearchFilterStatus } = this.props.actions,
      { activeTabApplicant } = this.state;
    if (activeTabApplicant !== item) {
      await this.setState({
        currentPage: 1,
        activeTabApplicant: item,
      });
      await setSearchFilterStatus("byTabs");
      this.fetchApplications();
    }
  };
  searchByFiltersHandler = () => {
    let { searchFilter, searchInput, endDate, startDate, actions } = this.props;
    if (!this.state.isDateApplied) {
      endDate = startDate = null;
    }
    if (!searchInput) {
      searchInput = null;
    }
    var dataToSend = {
      data: {
        dashboard: "UW",
        pageNumber: 1,
        param: {
          key: searchInput,
          startDate: startDate,
          endDate: endDate,
        },
        statusFilter: this.state.statusFilter === "all" ? null : this.state.statusFilter || null,
        type: searchFilter.value || "ALL",
      },
    };
    actions.fetchDashBoardData({ ...dataToSend });
  };
  fetchApplications = () => {
    let { activeTabApplicant, currentPage } = this.state,
      { loaderModalStatus, headerSearch, actions } = this.props,
      { loaderModal, headerChangeValue } = actions;
    !loaderModalStatus && loaderModal(true);
    const searchBy = get(headerSearch, "searchFilter.value", ""),
      searchString = searchBy === "" ? "" : get(headerSearch, "searchInput", "");
    let startDate = getYMD(new Date(this.state.startDate).toLocaleDateString()),
      endDate = getYMD(new Date(this.state.endDate).toLocaleDateString());
    const requestPayload = { pageNumber: currentPage };
    let { searchByFilters } = this.props;
    if (searchString) {
      requestPayload.searchBy = searchBy;
      requestPayload.searchString = searchString;
      requestPayload.statusFilter = "";
      requestPayload.endDate = "";
      requestPayload.startDate = "";
    } else if (searchByFilters.byTabs) {
      requestPayload.statusFilter = activeTabApplicant;
      requestPayload.searchBy = "";
      requestPayload.searchString = "";
      requestPayload.endDate = "";
      requestPayload.startDate = "";
    } else if (searchByFilters.byDate) {
      requestPayload.endDate = endDate;
      requestPayload.startDate = startDate;
      requestPayload.searchBy = "";
      requestPayload.searchString = "";
      requestPayload.statusFilter = "";
    }
    POST(API_GET_SALES_ALL_APPLICATIONS, { data: requestPayload }, { "Content-Type": "application/json" })
      .then(({ data }) => {
        loaderModal(false);
        if (data.status === "success") {
          this.setState({
            totalPages: get(data, "data.totalPage") || 1,
            applicationsData: get(data, "data.searchResponse") || [],
            prevBtn: get(data, "data.previousFlag") || false,
            nextBtn: get(data, "data.nextFlag") || false,
            allApplicationCount: get(data, "data.allApplicationCount") || 0,
            inCompletedApplicationCount: get(data, "data.inCompletedApplicationCount") || 0,
            pendingWithCreditApplicationCount: get(data, "data.pendingWithCreditApplicationCount") || 0,
            closedApplicationsCount: get(data, "data.closedApplicationsCount") || 0,
            submittedApplicationCount: get(data, "data.submittedApplicationCount") || 0,
          });
        } else if (!data.message.trim().search("Application not found")) {
          this.setState({
            applicationsData: [],
            totalPages: 1,
            prevBtn: false,
            nextBtn: false,
            allApplicationCount: 0,
            inCompletedApplicationCount: 0,
            pendingWithCreditApplicationCount: 0,
            closedApplicationsCount: 0,
            submittedApplicationCount: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        window.alert("Something went wrong, Please try again later.");
        loaderModal(false);
      })
      .finally(() => {
        headerChangeValue("searchInput", "");
        headerChangeValue("searchFilter", "");
      });
  };

  getPageData = (type) => {
    let count = this.state.currentPage;
    if (type === "next") {
      this.setState(
        {
          currentPage: ++count,
        },
        () => {
          this.fetchApplications();
        }
      );
    } else if (type === "prev") {
      this.setState(
        {
          currentPage: --count,
        },
        () => {
          this.fetchApplications();
        }
      );
    }
  };

  rowClicked = (state, rowInfo) => {
    let { history } = this.props;
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          document.cookie = `applicationNumber=${rowInfo.original.caseId}; path=/Sales`;
          const subStatus = rowInfo.original.subStatus;
          if (subStatus === "Start") {
            history.push(`${rowInfo.original.caseId}/post_login`);
          } else if (subStatus === "Sourcing Details") {
            history.push(`${rowInfo.original.caseId}/sourcingDetails`);
          } else if (subStatus === "Loan Details") {
            history.push(`${rowInfo.original.caseId}/gstn`);
          } else if (subStatus === "GST Initiated") {
            history.push(`${rowInfo.original.caseId}/gstndetails`);
          } else if (subStatus === "GST Validated") {
            history.push(`${rowInfo.original.caseId}/gstndetails`);
          } else if (subStatus === "Business") {
            history.push(`${rowInfo.original.caseId}/gstnaddress`);
          } else if (subStatus === "Profile") {
            history.push(`${rowInfo.original.caseId}/applicantGuide`);
          } else if (subStatus === "Banking") {
            history.push(`${rowInfo.original.caseId}/banking`);
          } else if (subStatus === "Documents") {
            history.push(`${rowInfo.original.caseId}/documents`);
          } else if (subStatus === "Reference") {
            history.push(`${rowInfo.original.caseId}/references`);
          } else {
            history.push(`${rowInfo.original.caseId}/forms_preview`);
          }
        },
      };
    }
    return false;
  };

  componentWillMount() {
    window.scroll(0, 0);
    let { setSearchFilters, updateFilterHandler } = this.props.actions,
      filters = [{ label: "Case Id", value: "caseId" }];
    setSearchFilters(filters);
    updateFilterHandler(this.fetchApplications);
  }

  componentDidMount() {
    this.fetchApplications();
  }

  render() {
    let {
      activeTabApplicant,
      applicationsData,
      totalPages,
      currentPage,
      nextBtn,
      prevBtn,
      allApplicationCount,
      inCompletedApplicationCount,
      pendingWithCreditApplicationCount,
      closedApplicationsCount,
      submittedApplicationCount,
    } = this.state;
    return (
      <div className={"dashboard-wrapper"}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard</title>
        </Helmet>
        <div className={"daterange-bar-wrapper"}>
          <div className={"mobile-sub-header"}>
            <NavLink to={"/Sales/post_login"} className={"add-btn"}>
              <span>
                <Icon icon={ic_add} size={22} />
              </span>
              <span>Add New Application</span>
            </NavLink>
            <div className={"daterange-box"}>
              <DateRangePicker changeYear={true} onApply={this.handleEvent} showDropdowns={true}>
                <button>
                  <span className={"icons"}>
                    <Icon icon={calendar} size={16} />
                  </span>
                  <span className={"icons"}>
                    <Icon icon={caretDown} size={16} />
                  </span>
                </button>
              </DateRangePicker>
            </div>
          </div>
        </div>
        <div className={"container"}>
          <div className={"mobile-applicants-list-wrapper"}>
            <ul className={"list-unstyled"}>
              <li
                onClick={() => this.applicantTabToggle("All")}
                className={activeTabApplicant === "All" ? "active" : null}
              >
                <span>{allApplicationCount}</span>
                <span>All</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Submit")}
                className={activeTabApplicant === "Submit" ? "active" : null}
              >
                <span>{submittedApplicationCount}</span>
                <span>Submitted</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("In_Complete")}
                className={activeTabApplicant === "In_Complete" ? "active" : null}
              >
                <span>{inCompletedApplicationCount}</span>
                <span>Incompleted</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Pending_With_Credit")}
                className={activeTabApplicant === "Pending_With_Credit" ? "active" : null}
              >
                <span>{pendingWithCreditApplicationCount}</span>
                <span>Pending</span>
              </li>
              <li
                onClick={() => this.applicantTabToggle("Closed")}
                className={activeTabApplicant === "Closed" ? "active" : null}
              >
                <span>{closedApplicationsCount}</span>
                <span>Closed</span>
              </li>
            </ul>
          </div>
          <div className={"datatable-wrapper"}>
            <ReactTable
              className={!applicationsData.length ? "no-data no-pagination" : null}
              data={applicationsData}
              columns={mobileTableHeaders}
              defaultPageSize={10}
              showPagination={false}
              resizable={false}
              minRows={0}
              getTrProps={this.rowClicked}
            />
            <ul className={"datatable-pagination"}>
              <li>
                <button
                  disabled={!prevBtn}
                  className={"prev-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("prev")}
                >
                  Previous
                </button>
              </li>
              <li>
                <span>
                  Page No. {currentPage} of {totalPages}
                </span>
              </li>
              <li>
                <button
                  disabled={!nextBtn}
                  className={"next-btn"}
                  type={"button"}
                  onClick={() => this.getPageData("next")}
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ data, header, salesDashboard }) => ({
  loaderModalStatus: data.loaderModal,
  headerSearch: get(header, "form.values"),
  searchByFilters: salesDashboard.searchByFilters,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loaderModal,
      setSearchFilters,
      updateFilterHandler,
      headerChangeValue,
      setSearchFilterStatus,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
