import React, { Component } from "react";
import { Header, LoaderModal } from "../../components";
import EscalationGrid from "./EscalationGrid/EscalationGrid";
import Parameterization from "./Parameterization/Parameterization";
import RoleUser from "./RoleUser/RoleUser";
import { loaderModal } from "../../store/actions";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { getAllThresholds, getAllLenderRoles , getUserByLevel } from "./admin.action";
import { bindActionCreators } from "redux";
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    let { getAllThresholds, getAllLenderRoles } = this.props.actions;
    getAllThresholds();
    getAllLenderRoles();
  };
  render() {
    let { loaderModal, loaderModalStatus } = this.props;
    return (
      <div className={"admin-wrapper"}>
        <Header {...this.props} />
        <LoaderModal status={loaderModalStatus} />
        <Tabs defaultActiveKey={"rolesAndUsers"} id="adminTabs" onSelect={this.selectedTab}>
          <Tab eventKey={"rolesAndUsers"} title={"Roles and Users"}>
            <RoleUser loaderModal={status => loaderModal(status)} {...this.props} />
          </Tab>
          <Tab eventKey={"escalationGrid"} title={"Escalation Grid"}>
            <EscalationGrid loaderModal={status => loaderModal(status)} {...this.props} />
          </Tab>
          <Tab eventKey={"Parametrization"} title={"Parametrization"}>
            <Parameterization loaderModal={status => loaderModal(status)} {...this.props} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ data, AdminResponse }) => ({
  loaderModalStatus: data.loaderModal,
  allThresholdData: AdminResponse.allThresholdData,
  activeLenderRolesData: AdminResponse.activeLenderRolesData,
  allLenderRolesData: AdminResponse.allLenderRolesData,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loaderModal,
      getAllThresholds,
      getAllLenderRoles,
      getUserByLevel
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
