import { API_GET_LOAN_DETAILS } from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as LoanDetailsConstant from "./loanTenure.actions.constant";

const getLoanDetails = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_GET_LOAN_DETAILS(applicationNumber))
      .then(({ data }) => {
        if (data.data) {
          dispatch({ type: LoanDetailsConstant.SET_LOAN_DETAILS, data: data.data });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};

const changeInputValue = (val, name) => ({
  type: LoanDetailsConstant.CHANGE_VALUE,
  data: val,
  name: name,
});

const resetLoanTenureState = () => ({
  type: LoanDetailsConstant.RESET_REDUX_STATE,
});

export { getLoanDetails, changeInputValue, resetLoanTenureState };
