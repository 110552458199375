import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPartyDetails, getBusiness } from "./GstnAddress.actions";
import GstnUpdatedAddress from "./GstnUpdatedAddress";
import { deleteCookie } from "../../../utils/utitlity";
import GSTPanAddress from "./GSTPanAddress";
import { loaderModal } from "../../../store/actions";
class GSTNAddress extends Component {
  render() {
    let { businessDetailsData } = this.props;
    return (
      <div>
        {businessDetailsData ? (
          businessDetailsData.isPanBasedFlow ? (
            <GSTPanAddress {...this.props} />
          ) : (
            <GstnUpdatedAddress {...this.props} />
          )
        ) : (
          <GSTPanAddress {...this.props} />
        )}
      </div>
    );
  }
  componentDidMount = async () => {
    let { actions, match } = this.props,
      { appNo } = match.params;
    actions.loaderModal(true);
    actions.getBusiness(appNo);
    await actions.fetchPartyDetails(appNo);
    actions.loaderModal(false);
  };
  componentWillUnmount() {
    deleteCookie("businessPan");
  }
}

const mapStateToProps = ({ gstnAddressReducers, listData }) => ({
  businessDetailsData: gstnAddressReducers.businessDetailsData,
  referenceRelations: listData.referenceRelations,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getBusiness,
      loaderModal,
      fetchPartyDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GSTNAddress);
