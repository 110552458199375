import get from "lodash/get";
import set from "lodash/set";

const _getValue = (state, name) => {
    return get(state, `loanDetails.${name}`);
};

const _updateFormValues = (state, value = '', name) => {
    const _preValue = _getValue(state, name);
    if (_preValue !== value) {
        let _changedState = JSON.parse(JSON.stringify(state)),
            _changedValue = get(set(_changedState, `loanDetails.${name}`, value), 'loanDetails');
        return {...state, loanDetails: _changedValue};
    }
    return state;
};

export {
    _updateFormValues
}
