import React from "react";
import Icon from "react-icons-kit";
import { ic_edit } from "react-icons-kit/md/ic_edit";
import get from "lodash/get";
import { goTo } from "../../../utils/utitlity";

const BankDetails = ({ data, index, length, status, applicationNumber }) => {
  let monthAvailable = get(data, "monthAvailable") || [];
  return (
    <div className={length > 1 ? "col-md-6" : "col-md-12"}>
      <div className={"box-content"}>
        <div className={"box-header"}>
          <h3 className={"box-heading"}>
            Banking Details {index + 1}/{length}
          </h3>
          {/* {status !== "Submit" && status !== "Approve" ? (
            <span
              onClick={() => goTo(`/Sales/${applicationNumber}/banking`)}
              className={"edit-icon"}
              title={"Edit"}
            >
              <Icon icon={ic_edit} size={18} />
            </span>
          ) : null} */}
        </div>
        <ul className={"bank-details-list"}>
          <li>
            <label>Account Number</label>
            <span>{get(data, "accountNumber", "")}</span>
            <span>
              <span>{""}</span>
            </span>
          </li>
          <li>
            <label>Type of Account</label>
            <span>{get(data, "accountType", "")}</span>
            <span>{""}</span>
          </li>
          <li>
            <label>Bank Name</label>
            <span>{get(data, "bankName", "")}</span>
            <span>{""}</span>
          </li>
          <li>
            <label>Account Holder's Name</label>
            <span>{get(data, "accountHolderName", "")}</span>
            <span>{""}</span>
          </li>
          <li>
            <label>Sanction Limit</label>
            <span>{get(data, "sanctionLimit", "")}</span>
            <span>{""}</span>
          </li>
          <li>
            <label>Months Available</label>
            <ul className={"months-list"}>
              {monthAvailable.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <span>{""}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BankDetails;
