import {
  API_BANK_ANALYSIS,
  API_BANK_ANALYSIS_ACCOUNT_NUMBER,
  API_BANK_DETAILS,
  API_EDIT_BOUNCE_RATIO,
  API_EDIT_BOUNCE_RATIO_ACC,
  API_GET_ENTITY_NAME,
  API_GET_BANK_ACCOUNT_FLAGS,
} from "../../../utils/APIUrls";
import { GET } from "../../../utils/webAPI.service";
import * as ACTION_CONSTANTS from "./cashFlow.action.constant";

/**
 * @param name: field Name
 * @param val: field Value
 * @returns {{type: string, data: *, name: string}}
 */
const changeValue = (name, val) => {
  return { type: ACTION_CONSTANTS.CHANGE_VALUE, data: val, name: name };
};

const getBankDetails = (applicationNumber) => async (dispatch) => {
  const _API_BANK_DETAILS = API_BANK_DETAILS(applicationNumber);
  dispatch({
    type: `${ACTION_CONSTANTS.BANK_DEAILS}${ACTION_CONSTANTS.COMMON.START}`,
  });

  try {
    const response = await GET(_API_BANK_DETAILS);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_DEAILS}`,
      data: response.data.data,
    });
    return response.data;
  } catch (error) {
    return error;
  } finally {
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_DEAILS}${ACTION_CONSTANTS.COMMON.FINISHED}`,
    });
  }
};

/**
 * Merged Banking Account Analysis
 * @param applicationNumber
 * @returns {Function}
 */

const getBankingAnalysis = (applicationNumber = 190600000005) => async (dispatch) => {
  const _API_BANK_ANALYSIS = API_BANK_ANALYSIS(applicationNumber);
  dispatch({
    type: `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.START}`,
  });

  try {
    const response = await GET(_API_BANK_ANALYSIS);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_ANALYSIS}`,
      data: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.FINISHED}`,
    });
  }
};

const getEditRatio = (applicationNumber, status) => async (dispatch) => {
  const _API_EDIT_BOUNCE_RATIO = API_EDIT_BOUNCE_RATIO(applicationNumber, status);
  dispatch({
    type: `${ACTION_CONSTANTS.EDIT_RATIO}${ACTION_CONSTANTS.COMMON.START}`,
  });

  try {
    const response = await GET(_API_EDIT_BOUNCE_RATIO);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({
      type: `${ACTION_CONSTANTS.EDIT_RATIO}`,
      data: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: `${ACTION_CONSTANTS.EDIT_RATIO}${ACTION_CONSTANTS.COMMON.FINISHED}`,
    });
  }
};

const getEditRatioAcc = (applicationNumber, status, accountNumber) => async (dispatch) => {
  const _API_EDIT_BOUNCE_RATIO = API_EDIT_BOUNCE_RATIO_ACC(applicationNumber, status, accountNumber);
  dispatch({
    type: `${ACTION_CONSTANTS.EDIT_RATIO_ACC}${ACTION_CONSTANTS.COMMON.START}`,
  });

  try {
    const response = await GET(_API_EDIT_BOUNCE_RATIO);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({
      type: `${ACTION_CONSTANTS.EDIT_RATIO_ACC}`,
      data: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: `${ACTION_CONSTANTS.EDIT_RATIO_ACC}${ACTION_CONSTANTS.COMMON.FINISHED}`,
    });
  }
};

/**
 * Particular Bank A/C analysis Report
 * @param applicationNumber
 * @param accountNumber
 * @returns {Function}
 */
const getAccountBankingAnalysis = (applicationNumber = 190600000005, accountNumber = 1) => async (dispatch) => {
  const _API_BANK_ANALYSIS_ACCOUNT_NUMBER = API_BANK_ANALYSIS_ACCOUNT_NUMBER(applicationNumber, accountNumber);
  dispatch({
    type: `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.START}`,
  });

  try {
    const response = await GET(_API_BANK_ANALYSIS_ACCOUNT_NUMBER);
    if (response.data.status === "error") {
      throw response.data;
    }
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_ANALYSIS}`,
      data: response.data.data,
    });
    return response.data;
  } catch (error) {
    throw error;
  } finally {
    dispatch({
      type: `${ACTION_CONSTANTS.BANK_ANALYSIS}${ACTION_CONSTANTS.COMMON.FINISHED}`,
    });
  }
};
const getEntityName = (applicationNumber) => {
  return (dispatch) => {
    return GET(API_GET_ENTITY_NAME(applicationNumber))
      .then(({ data }) => {
        if (data.status === "success") {
          dispatch({
            type: ACTION_CONSTANTS.SET_ENTITY_DETAILS,
            entityDetails: data.data,
          });
        }
        return data;
      })
      .catch((err) => {
        throw err;
      });
  };
};
const getBankAccFlags = (applicationNumber, accountNumber) => (dispatch) => {
  return GET(API_GET_BANK_ACCOUNT_FLAGS(applicationNumber, accountNumber))
    .then(({ data }) => {
      if (data.data) {
        dispatch({
          type: ACTION_CONSTANTS.API_GET_BANK_ACCOUNT_FLAGS,
          data: data.data,
          accountNumber,
        });
      }

      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export {
  getBankDetails,
  getBankingAnalysis,
  changeValue,
  getAccountBankingAnalysis,
  getEditRatio,
  getEditRatioAcc,
  getEntityName,
  getBankAccFlags,
};
